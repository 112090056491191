export const actionTypes = {
	RECEIVE_OAUTH_TOKEN: 'RECEIVE_OAUTH_TOKEN',
	RECEIVE_CSRF_TOKEN: 'RECEIVE_CSRF_TOKEN'
};

export const set_oauth_token_info = (token_info) => {
	return {
		type: actionTypes.RECEIVE_OAUTH_TOKEN,
		data: token_info
	}
};

export const receive_csrf_token = token_data => {
	return {
		type: actionTypes.RECEIVE_CSRF_TOKEN,
		data: {
			scope: token_data.scope,
			token: token_data.token
		}
	}
};