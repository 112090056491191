import React from "react";
import {Button, Glyphicon, Image, Navbar, Table} from "react-bootstrap";
import BaseComponent from "../../base/base-component";
import Utils from "../../../utils/utils";

class EventsTab
	extends BaseComponent {

	constructor(props) {
		super(props);

		this.state = {
			selected: null,
			showModal: false,
		};
	}

	render() {
		return (
			<div className="events-tab h-100">
				<Navbar fluid={true} bsClass="c-navbar navbar-default">
					<div className="tab-buttons-group">
						{
							this.props.hasAddButton &&
							<Button bsClass="btn icon action-button" onClick={this.props.onAdd}>
								<Glyphicon glyph="plus" className="c-glyph-icon"/>
								<div className="label-wrapper">
									<label>Add</label>
								</div>
							</Button>
						}
						{
							this.props.hasDeleteButton &&
							<Button bsClass="btn icon action-button" onClick={this.props.onDelete}>
								<Glyphicon glyph="remove" className="c-glyph-icon"/>
								<div className="label-wrapper">
									<label>Delete</label>
								</div>
							</Button>
						}
					</div>
				</Navbar>
				<div className="table-wrapper">
					<Table>
						<thead>
						<tr>
							<th style={{
								width: '5%',
								maxWidth: '10%'
							}}>
								Flag
							</th>
							<th>Factor</th>
							<th>Title</th>
							<th>Description</th>
							<th>Author</th>
							<th style={{
								width: '10%',
								maxWidth: '10%'
							}}>
								Date
							</th>
							<th style={{
								width: '10%',
								maxWidth: '10%'
							}}>
								Probability
							</th>
							<th style={{
								width: '10%',
								maxWidth: '10%'
							}}>
								Impact
							</th>
						</tr>
						</thead>
						<tbody>
						{
							this.props.events.map(item => {
								return (
									<tr key={item.id}
									    onClick={() => this.handleOnItemClick(item)}
									    className={this.props.selectedItem && this.props.selectedItem.id === item.id ? 'selected' : ''}>
										<td>{item.flag ? <Image src={Utils.getResourceUri(item.flag.path)}/> : ""}</td>
										<td>{item.factor.title}</td>
										<td>
											<div className="event-title">{item.title}</div>
										</td>
										<td>
											<div className="event-description">{item.description ? Utils.getPlainTextFromHtml(item.description) : ""}</div>
										</td>
										<td>{Utils.userName(item.author)}</td>
										<td>{Utils.convertDatetime(item.date, "dd-mm-yyyy")}</td>
										<td>{item.average_probability}</td>
										<td>{item.average_impact}</td>
									</tr>
								);
							})
						}
						</tbody>
					</Table>
				</div>
			</div>
		);
	}

	handleOnItemClick(item) {
		this.props.onItemClick(item);
	}
}

export default EventsTab