import React from 'react';
import {Col, Grid, Row} from "react-bootstrap";
import ProcessOverlay from "../components/base/process-overlay";
import ProjectNewsOverlay from "../components/controls/overlays/project-news";

class ProjectView
	extends React.Component {

	constructor(props) {

		super(props);

		this.state = {}
	}

	componentDidMount() {

		if (this.props.match.params.projectId !== "0") {
			this.props.loadInitialData(this.props.match.params.projectId);
		} else {
			this.props.createEmptyProject();
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

	}

	render() {

		let rightSideBar = null;

		rightSideBar = (
			<div className="h-100 display-flex align-items-start right-sidebar"
			     ref={
				     (element) => {
					     this.overlayContainer = element;
				     }
			     }>
				<button className="btn"
				        onClick={
					        (e) => {
						        this.setState({
							                      projectNewsToggleBarOpened: !this.state.projectNewsToggleBarOpened
						                      });
					        }
				        }>
					<span className="title">News</span>
				</button>
				{
					<ProjectNewsOverlay show={this.props.project && this.state.projectNewsToggleBarOpened}
					                    projects={this.props.projects ? this.props.projects : null}
					                    project={this.props.project ? this.props.project : null}
					                    notifications={this.props.notifications}
					                    notificationsFilter={this.props.notificationsFilter}
					                    containerRef={this.overlayContainer}
					                    onFilter={(filter) => this.props.filterNotifications(filter)}
					                    itemsLoader={() => {
						                    this.props.loadNotifications(0, 20)
					                    }}
					                    itemsProvider={(page) => {
						                    this.props.appendNotifications(page, 50)
					                    }}
					/>
				}
			</div>
		);

		return (
			this.props.project ?
				<Grid fluid={true} className="project-view h-100">
					<Row className="h-100 right-toggle-bar">
						<Col md={12} className="h-100">
							{this.props.children}
						</Col>
						{rightSideBar}
					</Row>
				</Grid>
				:
				<ProcessOverlay className={"loading-overlay"}>Loading project...</ProcessOverlay>
		)
	}
}

export default ProjectView;