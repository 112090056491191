import {actionTypes} from "../actions/servers";

const initialState = {
	isPending: false,
	invalidated: true,
	token_info: null,
	csrf_tokens: null
};

function serversReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.RECEIVE_OAUTH_TOKEN:
			return {
				...state,
				token_info: action.data
			};
		case actionTypes.RECEIVE_CSRF_TOKEN:

			let csrf_tokens = state.csrf_tokens ? state.csrf_tokens : {};

			if (!csrf_tokens[action.data.scope]) {
				csrf_tokens[action.data.scope] = {};
			}

			if (action.data.token) {
				csrf_tokens[action.data.scope].headerName = action.data.token.headerName;
				csrf_tokens[action.data.scope].parameterName = action.data.token.parameterName;
				csrf_tokens[action.data.scope].token = action.data.token.token;
			}

			return {
				...state,
				csrf_tokens: {
					...
					csrf_tokens
				},
			};
		default:
			return state
	}
}

export default serversReducer