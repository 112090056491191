import {API} from "../api/api";
import clientFactory from "../factories/http_client";


class EventCategoryService {

	/** @return  Promise<AxiosResponse<T>> */
	getById = (id) => {
		return clientFactory.client(API.data.scope).request({
			                             method: API.data.event_categories.id.method,
			                             url: API.data.event_categories.id.url(id)
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	getAll = () => {
		return clientFactory.client(API.data.scope).request({
			                             method: API.data.event_categories.method,
			                             url: API.data.event_categories.url
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	create = (category) => {
		return clientFactory.client(API.data.scope).request({
			                                                    method: API.data.event_categories.create.method,
			                                                    url: API.data.event_categories.create.url,
			                                                    data: API.data.event_categories.create.build(category),
			                                                    headers: {
				                                                    'Content-Type': 'application/json',
			                                                    }
		                                                    });
	};

	/** @return  Promise<AxiosResponse<T>> */
	save = (category) => {
		return clientFactory.client(API.data.scope).request({
			                             method: API.data.event_categories.save.method,
			                             url: API.data.event_categories.save.url,
			                             data: API.data.event_categories.save.build(category),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	delete = (categoryId) => {
		return clientFactory.client(API.data.scope).request({
			                             method: API.data.event_categories.delete.method,
			                             url: API.data.event_categories.delete.url,
			                             data: API.data.event_categories.delete.build(categoryId)
		                             });
	};
}

export default EventCategoryService;