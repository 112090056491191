import React from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import Utils from "../../../utils/utils";

class HorizontalHeader extends React.PureComponent {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

        if (this.refs.container  && this.props.scrollPositionChangeHandler) {
            this.refs.container.addEventListener('scroll', this.props.scrollPositionChangeHandler);
        }

        if (this.props.containerRef) {
            this.props.containerRef(() => {
                return this.refs.container
            });
        }
    }

    componentWillUnmount() {
        if (this.refs.container && this.props.scrollPositionChangeHandler) {
            this.refs.container.removeEventListener('scroll', this.props.scrollPositionChangeHandler);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {

    }

    render() {

        const flattenFactors = Utils.getFlatFactors(this.props.items);

        const childQuantityMap = {};

        function childrenProvider(parent) {
            return parent.children;
        }

        function preOrderOperation(treeElement, context) {

            if (!childQuantityMap[treeElement.id]) {
                childQuantityMap[treeElement.id] = -1;
            }

            context.parents.forEach(function (parent) {
                childQuantityMap[parent.id] = childQuantityMap[parent.id] + 1;
            });

            return true;
        }

        this.props.items.forEach(
            function (element) {
                Utils.bypassTree(element, childrenProvider, preOrderOperation)
            }
        );

        const depths = flattenFactors.map(function (element) {
            return element.depth;
        });

        const maxLevel = Math.max(...depths);

        return (
            <div ref="container"
                 className="fm-horizontal-headers-scroll horizontal-header">
                <div className="content">
                    {
                        this.props.items.map(item => {
                            return this.columnHeaderFactory(item, childQuantityMap, maxLevel);
                        })
                    }
                </div>
            </div>
        )
    }

    columnHeaderFactory = (factor, childQuantityMap, maxLevel) => {

        const self = this;

        const headerHeight = 40;
        const headerWidth = this.props.columnWidth;

        const childConnectionPositions = [];

        let previousChildPosition = headerWidth;

        for (let i = 0 ; i < factor.children.length ; i++) {

            const child = factor.children[i];

            const childWidth = (childQuantityMap[child.id] + 1) * headerWidth;

            childConnectionPositions.push(previousChildPosition + childWidth / 2);

            previousChildPosition += childWidth;
        }

        return (
            <div id={`col-header-${factor.id}`}
                 key={factor.id}
                 className="horizontal-header-item"
                 style={{
                     position: 'relative',
                     border: 0,
                     width: (childQuantityMap[factor.id] + 1) * headerWidth,
                     height: factor.children.length === 0 ? ((maxLevel + 1 - factor.depth) * headerHeight) : undefined
                 }}>
                {
                    this.line(
                        headerHeight / 2,
                        headerWidth / 2,
                        1,
                        ((childQuantityMap[factor.id] + 1) * headerWidth - headerWidth) / 2
                    )
                }
                {
                    factor.children.length !== 0 &&
                    this.line(
                        headerHeight / 2,
                        ((childQuantityMap[factor.id] + 1) * headerWidth) / 2,
                        1,
                        ((childQuantityMap[factor.id] + 1) * headerWidth) / 2 - ((childQuantityMap[factor.children[factor.children.length - 1].id] + 1) * headerWidth) / 2
                    )
                }
                {
                    childConnectionPositions.map(position => {
                        return this.line(
                            headerHeight / 2,
                            position,
                            headerHeight / 2,
                            1
                        );
                    })
                }
                <div>
                    <label style={{
                        position: 'relative',
                        textAlign: 'center',
                        height: headerHeight + 'px',
                        width: headerWidth + 'px'
                    }}>
                        <OverlayTrigger key={`factor-${factor.id}`}
                                        placement="bottom"
                                        delayShow={200}
                                        overlay={<Tooltip id="factor-tooltip">{factor.title}</Tooltip>}
                        >
                            <label className={`title-label level-${factor.depth > 2 ? 3 : factor.depth}`}
                                   style={{
                                       lineHeight: (headerHeight - 18) + 'px',
                                       maxHeight: (headerHeight - 8) + 'px'
                                   }}>
                                {factor.title}
                            </label>
                        </OverlayTrigger>
                    </label>
                </div>
                {
                    this.line(headerHeight / 2, headerWidth / 2, headerHeight / 2, 1)
                }
                {
                    factor.depth !== maxLevel &&
                    <div className="sub">
                        <div className="column" style={
                            {
                                width: headerWidth + 'px',
                                height: (maxLevel - factor.depth) * headerHeight
                            }
                        }
                        >
                            {
                                this.line(0, headerWidth / 2, (maxLevel - factor.depth) * headerHeight, 1)
                            }
                        </div>
                        {
                            factor.children.map(child => {
                                return this.columnHeaderFactory(child, childQuantityMap, maxLevel);
                            })
                        }
                    </div>
                }
            </div>
        )
    };

    line = (top, left, height, width, color = '#8994a4') => {
        return (
            <div key={Math.random()}
                 className="connection"
                 style={
                     {
                         top: top + 'px',
                         left: left + 'px',
                         height: height + 'px',
                         width: width + 'px',
                         backgroundColor: color
                     }
                 }/>
        )
    };
}

export default HorizontalHeader;