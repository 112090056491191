import clientFactory from "../factories/http_client";
import {API} from "../api/api";

class FactorCommentService {

	/** @return  Promise<AxiosResponse<T>> */
	createComment = (projectId, futureMapId, factorId, comment) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.factors.id.comments.create.method,
			                             url: API.data.projects.id.event_maps.id.factors.id.comments.create.url(projectId, futureMapId, factorId),
			                             data: API.data.projects.id.event_maps.id.factors.id.comments.create.build(comment),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	saveComment = (projectId, futureMapId, factorId, comment) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.factors.id.comments.save.method,
			                             url: API.data.projects.id.event_maps.id.factors.id.comments.save.url(projectId, futureMapId, factorId),
			                             data: API.data.projects.id.event_maps.id.factors.id.comments.save.build(comment),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	deleteComment = (projectId, futureMapId, factorId, commentId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.factors.id.comments.delete.method,
			                             url: API.data.projects.id.event_maps.id.factors.id.comments.delete.url(projectId, futureMapId, factorId),
			                             data: API.data.projects.id.event_maps.id.factors.id.comments.delete.build(commentId),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};
}

export default FactorCommentService