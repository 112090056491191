import React from "react";

class ContextMenu extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.addEventListener('click', this.hideListener);
    }

    componentWillUnmount() {
        document.addEventListener('click', this.hideListener);
    }

    render() {
        return (
            <ul className="react-contextmenu"
                ref="container"
                role="menu"
                style={
                    {
                        display: "block",
                        position: 'fixed',
                        opacity: 'unset',
                        pointerEvents: 'auto',
                        listStyle: 'none',
                        left: this.props.position.x,
                        top: this.props.position.y
                    }
                }>
                {
                    this.props.addEventEnabled &&
                    <li className="react-contextmenu-item" onClick={
                        (e) => {
                            this.props.onAddEvent();
                        }
                    }>Add event</li>
                }
                {
                    this.props.editEventEnabled &&
                    <li className="react-contextmenu-item" onClick={
                        (e) => {
                            this.props.onEditEvent();
                        }
                    }>Edit event</li>
                }
                {
                    this.props.deleteEventEnabled &&
                    <li className="react-contextmenu-item" onClick={
                        (e) => {
                            this.props.onDeleteEvent();
                        }
                    }>Delete event</li>
                }
                {
                    this.props.copyEnabled &&
                    <li className="react-contextmenu-item">
                        Copy
                        <ul className="react-contextmenu-submenu">
                            <li className="react-contextmenu-item" onClick={
                                (e) => {
                                    this.props.onCopyWithoutEvents();
                                }
                            }
                            >without events
                            </li>
                            <li className="react-contextmenu-item" onClick={
                                (e) => {
                                    this.props.onCopyWithEvents();
                                }
                            }>with events
                            </li>
                        </ul>
                    </li>
                }
                {
                    this.props.insertEnabled &&
                    <li className="react-contextmenu-item" onClick={
                        (e) => {
                            this.props.onInsert();
                        }
                    }>Insert</li>
                }
                {
                    this.props.addTrendEnabled &&
                    <li className="react-contextmenu-item" onClick={
                        (e) => {
                            this.props.onAddTrend();
                        }
                    }>Add trend</li>
                }
	            {
		            (this.props.showStraightEventRelations || this.props.showEventRelations) &&
		            <li className="react-contextmenu-item">
                        Relations <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;></span>
			            <ul className="react-contextmenu-submenu">
				            {
				            	this.props.showStraightEventRelations &&
					            <li className="react-contextmenu-item" onClick={this.props.onShowStraightEventRelations}>straight relations</li>
				            }
				            {
				            	this.props.showEventRelations &&
					            <li className="react-contextmenu-item" onClick={this.props.onShowEventRelations}>relations</li>
				            }
			            </ul>
		            </li>
	            }
	            {
		            this.props.hideEventRelations &&
		            <li className="react-contextmenu-item" onClick={this.props.onHideEventRelations}>Hide relations</li>
	            }
            </ul>
        )
    }

    hideListener = (event) => {
        if (this.refs.container) {
            this.refs.container.style.display = 'none';
            if (this.props.onClose) {
                this.props.onClose();
            }
        }
    }
}

export default ContextMenu