import {API} from "../api/api";
import clientFactory from "../factories/http_client";

class ProjectService {

	/** @return  Promise<AxiosResponse<T>> */
	getByTitleAndPeriod = (data) => {
		if (data) {
			return clientFactory.client(API.data.scope)
			                    .request({
				                             method: API.data.projects.method,
				                             url: API.data.projects.url,
				                             params: API.data.projects.build(data.title, null, null)
			                             })
		} else {
			return clientFactory.client(API.data.scope)
			                    .request({
				                             method: API.data.projects.method,
				                             url: API.data.projects.url,
			                             })
		}
	};

	/** @return  Promise<AxiosResponse<T>> */
	get = (page_index, page_size) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.method,
			                             url: API.data.projects.url(page_index, page_size)
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	getById = (projectId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.method,
			                             url: API.data.projects.id.url(projectId)
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	save = (project) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.save.method,
			                             url: API.data.projects.save.url,
			                             data: API.data.projects.save.build(project),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	create = (project) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.create.method,
			                             url: API.data.projects.create.url,
			                             data: API.data.projects.create.build(project),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	remove = (projectId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.delete.method,
			                             url: API.data.projects.delete.url,
			                             data: API.data.projects.delete.build(projectId)
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	copy = (projectId, level) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.copy.method,
			                             url: API.data.projects.copy.url,
			                             data: API.data.projects.copy.build(projectId, level),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	getFilter = () => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.filter.method,
			                             url: API.data.projects.filter.url,
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             })
	}

	createFilter = (filter) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.filter.create.method,
			                             url: API.data.projects.filter.create.url,
			                             data: API.data.projects.filter.create.build(filter),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             })
	}

	/** @return  Promise<AxiosResponse<T>> */
	getScenarios = (projectId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.scenarios.method,
			                             url: API.data.projects.id.scenarios.url(projectId)
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	getEventCategories = (projectId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_categories.method,
			                             url: API.data.projects.id.event_categories.url(projectId)
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	addEventCategories = (projectId, eventCategories) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_categories.create.method,
			                             url: API.data.projects.id.event_categories.create.url(projectId),
			                             data: API.data.projects.id.event_categories.create.build(eventCategories)
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	addEventTypes = (projectId, eventTypes) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_types.create.method,
			                             url: API.data.projects.id.event_types.create.url(projectId),
			                             data: API.data.projects.id.event_types.create.build(eventTypes)
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	getEventSources = (projectId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_sources.method,
			                             url: API.data.projects.id.event_sources.url(projectId)
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	getEventTypes = (projectId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_types.method,
			                             url: API.data.projects.id.event_types.url(projectId)
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	addEventSources = (projectId, eventSources) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_sources.create.method,
			                             url: API.data.projects.id.event_sources.create.url(projectId),
			                             data: API.data.projects.id.event_sources.create.build(eventSources)
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	getAuthority = (projectId, authorityId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.roles.id.method,
			                             url: API.data.projects.id.roles.id.url(projectId, authorityId)
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	createAuthority = (projectId, authority) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.roles.create.method,
			                             url: API.data.projects.id.roles.create.url(projectId),
			                             data: API.data.projects.id.roles.create.build(authority),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	saveAuthority = (projectId, authority) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.roles.save.method,
			                             url: API.data.projects.id.roles.save.url(projectId),
			                             data: API.data.projects.id.roles.save.build(authority),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	deleteAuthority = (projectId, authorityId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.roles.delete.method,
			                             url: API.data.projects.id.roles.delete.url(projectId),
			                             data: API.data.projects.id.roles.delete.build(authorityId),
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	getJournalEntries = (projectId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.journal_entries.method,
			                             url: API.data.projects.id.journal_entries.url(projectId)
		                             });
	}
}

export default ProjectService;