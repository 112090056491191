import React from 'react';
import {Navbar, Table} from 'react-bootstrap';

class JournalTab extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="tab-content">
                <Navbar fluid={true} bsClass="c-navbar navbar-default">
                    <div className="tab-buttons-group">
                    </div>
                </Navbar>
                <div className="table-wrapper">
                    <Table>
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th>Field Name</th>
                            <th>Previous Value</th>
                            <th>New Value</th>
                            <th>User</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.items && this.props.items.map(item => {
                                let changeDate = null;
                                if (item.change_date) {
                                    let options = {year: 'numeric', month: 'long', day: 'numeric' };
                                    let date = new Date(item.change_date);
                                    changeDate  = date.toLocaleTimeString('ru', options);
                                } else {
                                    changeDate = "";
                                }
                                return (
                                    <tr key={this.props.items.indexOf(item)}
                                        onClick={() => this.handleOnItemClick(item)}
                                        className={this.props.selectedItem && this.isEqual(this.props.selectedItem, item) ? 'selected' : ''}>
                                        <td>{changeDate}</td>
                                        <td>{item.field_name}</td>
                                        <td>{item.field_old_value}</td>
                                        <td>{item.field_new_value}</td>
                                        <td>{item.user.user_name}</td>
                                    </tr>
                                );
                            })
                        }
                        </tbody>
                    </Table>
                </div>
            </div>
        );
    }

    handleOnItemClick = (item) => {
        this.props.onSelectionChange(item);
    };

    addButtonHandler = () => {
        this.props.onAdd();
    };

    editButtonHandler = () => {
        this.props.onEdit(this.props.selectedItem);
    };

    deleteButtonHandler = () => {
        this.props.onDelete(this.props.selectedItem);
    };

    isEqual = (firstItem, secondItem) => {
        return (firstItem.change_date + firstItem.field_old_value + firstItem.field_new_value) === (secondItem.change_date + secondItem.field_old_value + secondItem.field_new_value)
    }
}

export default JournalTab;