import React from 'react';
import {Button, Col, ControlLabel, Form, FormGroup, Glyphicon, Tab, Tabs} from 'react-bootstrap';
import {DateTimePicker} from 'react-widgets';
import {SketchPicker} from 'react-color';

import Utils from "../utils/utils";
import Summernote from '../components/base/summernote-base';
import TreeSelect, {TreeNode} from "rc-tree-select";
import EventsTab from "../components/common/tab/events-tab";
import SimpleSelectionForm from "../components/base/simple-selection-form";
import DateUtils from "../utils/date";
import {mixinFactory as OperationMixinFactory} from "../mixins/operation";
import {mixinFactory as ChangedStateMixinFactory} from "../mixins/changed-state";


class TrendView
	extends React.Component {

	constructor(props) {

		super(props);

		this.trendId = this.props.match.params.trendId;

		Object.assign(TrendView.prototype, OperationMixinFactory({
			                                                         operationPropName: 'trendOperation',
			                                                         operationStateName: 'operationStatus'
		                                                         }))

		Object.assign(TrendView.prototype, ChangedStateMixinFactory({
			                                                            scope: 'change_detector',
			                                                            observable: 'trend',
			                                                            excludedFields: [
				                                                            'activeTab',
				                                                            'operationStatus',
				                                                            'saveEnabled',
				                                                            'addEventEnabled',
				                                                            'deleteEventEnabled',
				                                                            'author',
				                                                            'factorSearchValue',
				                                                            'showColorPicker',
				                                                            'factorSelectOpened',
				                                                            'showEventModal'
			                                                            ],
			                                                            stateField: 'dirty'
		                                                            }))

		this.state = {
			factor: props.trend ? props.trend.factor : null,
			factorId: props.trend ? (props.trend.factor ? props.trend.factor.id : null) : null,
			name: props.trend ? props.trend.name : "",
			periodStart: props.trend && props.trend.start ? new Date(props.trend.start) : new Date(),
			periodEnd: props.trend && props.trend.end ? new Date(props.trend.end) : new Date(),
			color: ((props.trend && props.trend.color) ? Utils.rgbToHex(props.trend.color.red_addition,
			                                                            props.trend.color.green_addition,
			                                                            props.trend.color.blue_addition) : "#000000"),
			description: props.trend ? props.trend.description : "",
			showColorPicker: false,
			showEventModal: false,
			...this.setPermissions(props)
		};

		if (props.trend) {
			this.change_detector.setInitialState(this.state);
		}
	}

	componentDidMount() {

		if (this.props.project && this.props.match.params.trendId !== "0" && !this.props.trend) {
			this.props.loadTrend(this.props.project, this.props.match.params.trendId);
		}

		if (this.props.project && !this.props.factors) {
			this.props.loadFactors(this.props.project);
		}

		if (this.props.project && !this.props.events) {
			this.props.loadEvents(this.props.project);
		}

		this.operationState(null, null);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (prevProps.trend !== this.props.trend && this.props.trend) {
			this.setState({
				              factor: this.props.trend ? this.props.trend.factor : null,
				              factorId: this.props.trend ? (this.props.trend.factor ? this.props.trend.factor.id : null) : null,
				              name: this.props.trend ? this.props.trend.name : "",
				              periodStart: this.props.trend ? new Date(this.props.trend.start) : new Date(),
				              periodEnd: this.props.trend ? new Date(this.props.trend.end) : new Date(),
				              color: ((this.props.trend && this.props.trend.color) ? Utils.rgbToHex(
					              this.props.trend.color.red_addition, this.props.trend.color.green_addition,
					              this.props.trend.color.blue_addition) : "#000000"),
				              description: this.props.trend ? this.props.trend.description : "",
				              ...this.setPermissions(this.props)
			              },
			              () => {
				              this.change_detector.setInitialState(this.state);
			              });
		}

		this.operationState(prevProps, prevState);
		this.detectChanged(prevProps, prevState);
	}

	setPermissions = (props) => {
		if (props.project && props.isAdministrator(props.project)) {
			return {
				saveEnabled: true,
				addEventEnabled: true,
				deleteEventEnabled: true
			}
		} else if (props.project && props.isAnalyst(props.project)) {
			return {
				saveEnabled: true,
				addEventEnabled: true,
				deleteEventEnabled: true
			}
		} else if (props.project && props.isManager(props.project)) {
			return {
				saveEnabled: true,
				addEventEnabled: true,
				deleteEventEnabled: false
			}
		} else {
			return {
				saveEnabled: false,
				addEventEnabled: false
			}
		}
	};

	render() {

		const dateFormat = "DD.MM.YYYY";

		const factorToTreeNodeMapper = (element) => {
			return (
				<TreeNode key={element.id}
				          title={element.title}
				          value={element.id}
				          isLeaf={!element.children || element.children.length === 0
				          }
				          disabled={false}
				>
					{
						element.children.map(factorToTreeNodeMapper)
					}
				</TreeNode>
			);
		};

		let tabs = null;

		if (this.props.trend && this.props.trend.id) {
			tabs = this.renderTabs();
		}

		let modalEvents = [];

		if (this.state.showEventModal && this.props.trend) {
			modalEvents = this.props.events.filter(event => {
				return (
					event.date >= this.props.trend.start &&
					event.date <= this.props.trend.end && event.factor.id === this.props.trend.factor.id &&
					this.props.trend.event_ids.indexOf(event.id) === -1
				)
			});
		}

		let saveButtonClass = "btn action-button pull-right";

		if (this.state.operationStatus === "IN_PROGRESS") {
			saveButtonClass = saveButtonClass + " pending";
		} else {
			if (this.state.operationStatus && this.state.operationStatus !== "NOT_PENDING") {
				saveButtonClass = this.state.operationStatus === "SUCCESS" ? saveButtonClass + " btn-success" : saveButtonClass + " btn-danger"
			}
		}

		let changes_indicator;

		if (this.props.trend && this.props.trend.id && (this.state.dirty || this['change_detector'].initialization_counter > 0)) {
			changes_indicator = this.state.dirty ? <div className="dirty-indicator">Changes unsaved</div> : <div className="dirty-indicator success"><Glyphicon glyph={"ok"}/>Changes saved</div>;
		}

		return (
			<Form horizontal={true} className="h-100 trend-form">
				<FormGroup>
					<Col componentClass={ControlLabel} md={1}>
						Factor:
					</Col>
					<Col md={4}>
						{
							<TreeSelect style={{width: 400}}
							            transitionName="rc-tree-select-dropdown-slide-up"
							            choiceTransitionName="rc-tree-select-selection__choice-zoom"
							            dropdownStyle={{
								            maxHeight: 200,
								            overflow: 'auto'
							            }}
							            placeholder={"Select factor"}
							            searchPlaceholder="please search"
							            showSearch={true}
							            allowClear={true}
							            treeLine={true}
							            searchValue={this.state.factorSearchValue}
							            value={this.state.factorId}
							            treeNodeFilterProp="title"
							            filterTreeNode={false}
							            onSearch={(value) => {
								            this.setState({factorSearchValue: value});
							            }}
							            open={this.state.factorSelectOpened}
							            onChange={(value, ...args) => {
								            this.setState({factorId: value});
							            }}
							            onDropdownVisibleChange={(v, info) => {
								            this.setState({
									                          factorSelectOpened: v,
								                          });
								            return true;
							            }}
							>
								{
									this.props.factors && this.props.factors
									                          .filter((element) => {
										                          return !this.props.factor || element.id !== this.props.factor.id;
									                          })
									                          .map((element) => {
										                          return factorToTreeNodeMapper(element);
									                          })
								}
							</TreeSelect>
						}
					</Col>
				</FormGroup>
				<FormGroup>
					<Col componentClass={ControlLabel} md={1}>
						Name:
					</Col>
					<Col md={10}>
						<input className="form-control"
						       type="text"
						       onChange={this.setEventValue.bind(this, 'name')}
						       value={this.state.name}/>
					</Col>
				</FormGroup>
				<FormGroup>
					<Col componentClass={ControlLabel} md={1}>
						Period start:
					</Col>
					<Col md={2}>
						<DateTimePicker onChange={this.setValue.bind(this, 'periodStart')}
						                value={this.state.periodStart}
						                time={false}
						                format={dateFormat}
						                min={this.props.project ? new Date(
							                this.props.project.start_date) : new Date()}
						                max={this.props.project ? new Date(
							                this.props.project.end_date) : new Date()}
						/>
					</Col>
					<Col componentClass={ControlLabel} md={1}>
						Period end:
					</Col>
					<Col md={2}>
						<DateTimePicker onChange={this.setValue.bind(this, 'periodEnd')}
						                value={this.state.periodEnd}
						                time={false}
						                format={dateFormat}
						                min={this.props.project ? new Date(
							                this.props.project.start_date) : new Date()}
						                max={this.props.project ? new Date(
							                this.props.project.end_date) : new Date()}
						/>
					</Col>
				</FormGroup>
				<FormGroup>
					<Col componentClass={ControlLabel} md={1}>
						Color:
					</Col>
					<Col md={1}>
						<div className="trend-color-swatch" onClick={this.selectColorHandler}>
							<div className="trend-color-color" style={{backgroundColor: this.state.color}}/>
						</div>
						{
							this.state.showColorPicker ?
								<div className="trend-color-popover">
									<div className="trend-color-cover" onClick={this.cancelColorHandler}/>
									<SketchPicker color={this.state.color}
									              onChangeComplete={(color) => {
										              this.setState({color: color.hex})
									              }}
									              disableAlpha={true}
									/>
								</div>
								: null
						}
					</Col>
				</FormGroup>
				<FormGroup>
					<Col componentClass={ControlLabel} md={1}>
						Description:
					</Col>
					<Col md={11}>
						<Summernote value={this.state.description}
						            height={200}
						            onChange={this.setValue.bind(this, 'description')}
						/>
					</Col>
				</FormGroup>
				<FormGroup className="tabs-row">
					{tabs}
				</FormGroup>
				<FormGroup className="form-buttons-bar">
					<Col md={12}>
						<Button bsClass="btn action-button pull-right" onClick={this.cancelButtonHandler}>
							Cancel
						</Button>
						{
							this.state.saveEnabled &&
							<Button bsClass={saveButtonClass} onClick={this.state.operationStatus !== "IN_PROGRESS" ? this.saveButtonHandler : null}>
								{this.props.trend && this.props.trend.id ? "Save" : "Create"}
							</Button>
						}
						{changes_indicator}
					</Col>
				</FormGroup>
				{
					this.state.showEventModal &&
					(
						<SimpleSelectionForm show={this.state.showEventModal}
						                     onHide={() => this.setState({showEventModal: false})}
						                     items={
							                     modalEvents
						                     }
						                     default={modalEvents.length > 0 ? modalEvents[0] : null}
						                     field='title'
						                     onSave={this.handleAddEvent}
						/>
					)
				}
			</Form>
		)
	}

	renderTabs = () => {

		const self = this;

		let events = null;

		if (this.props.trend && this.props.trend.event_ids && this.props.events) {
			events = this.props.events.filter((event) => {
				return this.props.trend.event_ids.indexOf(event.id) !== -1;
			});
		}

		return (
			<Tabs activeKey={this.state.activeTab} onSelect={this.tabSelectionHandler} id="trend-tabs" className="h-100">
				<Tab eventKey={1} title="Events">
					<EventsTab events={events ? events : []}
					           allEvents={this.props.events}
					           selectedItem={this.props.selectedEvent}
					           onItemClick={this.handleSelectEvent}
					           hasAddButton={self.state.addEventEnabled}
					           hasDeleteButton={self.state.deleteEventEnabled}
					           onAdd={() => {
						           self.setState({
							                         showEventModal: true
						                         });
					           }}
					           onDelete={(event) => {
						           if (!self.props.selectedEvent) {
							           alert("You must select item from table");
						           } else {
							           if (confirm(`Are you sure you want to delete event: ${this.props.selectedEvent.title}?`)) {
								           self.props.removeEvent(self.props.project, self.props.trend, self.props.selectedEvent);
							           }
						           }
					           }}
					/>
				</Tab>
			</Tabs>
		)
	};

	handleAddEvent = (event) => {

		this.setState({
			              showEventModal: false
		              });
		this.props.addEvent(this.props.project, this.props.trend, event);
	};

	selectColorHandler = () => {
		this.setState({showColorPicker: !this.state.showColorPicker})
	};

	handleSelectEvent = (event) => {
		this.props.selectEvent(event);
	};

	cancelColorHandler = () => {
		this.setState({showColorPicker: false})
	};

	saveButtonHandler = () => {

		const trend = this.props.trend ? {...this.props.trend} : {};

		trend.future_map_id = this.props.project.eventsMap.id;
		trend.factor = {
			id: this.state.factorId
		};
		trend.name = this.state.name;
		trend.start = DateUtils.convertToISOUTCDatetime(DateUtils.startOfDay(this.state.periodStart));
		trend.end = DateUtils.convertToISOUTCDatetime(DateUtils.endOfDay(this.state.periodEnd));

		const rgb = Utils.hexToRgb(this.state.color);

		if (!trend.color) {
			trend.color = {}
		}

		trend.color["red_addition"] = rgb.r;
		trend.color["green_addition"] = rgb.g;
		trend.color["blue_addition"] = rgb.b;

		trend.description = this.state.description;

		if (new Date(trend.start) > new Date(trend.end)) {
			alert("End period must be after start period");
		}

		this.props.saveTrend(this.props.project, trend);
	};

	cancelButtonHandler = () => {
		this.props.history.goBack();
	};

	setEventValue = (field, event) => {
		//If the input fields were directly within this
		//this component, we could use this.refs.[FIELD].value
		//Instead, we want to save the data for when the form is submitted
		const object = {};
		object[field] = event.target.value;
		this.setState(object);
	};

	setEventChecked = (field, event) => {
		//If the input fields were directly within this
		//this component, we could use this.refs.[FIELD].value
		//Instead, we want to save the data for when the form is submitted
		const object = {};
		object[field] = event.target.checked;
		this.setState(object);
	};

	setValue = (field, value) => {
		//If the input fields were directly within this
		//this component, we could use this.refs.[FIELD].value
		//Instead, we want to save the data for when the form is submitted
		const object = {};
		object[field] = value;
		this.setState(object);
	};
}

export default TrendView