import clientFactory from "../factories/http_client";
import {API} from "../api/api";
import DateUtils from "../utils/date";

class EventService {

	/** @return  Promise<AxiosResponse<T>> */
	getEvent = (projectId, futureMapId, eventId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.events.id.method,
			                             url: API.data.projects.id.event_maps.id.events.id.url(projectId, futureMapId, eventId)
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	save = (projectId, futureMapId, event) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.events.save.method,
			                             url: API.data.projects.id.event_maps.id.events.save.url(projectId, futureMapId),
			                             data: API.data.projects.id.event_maps.id.events.save.build(event),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	create = (projectId, futureMapId, event) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.events.create.method,
			                             url: API.data.projects.id.event_maps.id.events.create.url(projectId, futureMapId),
			                             data: API.data.projects.id.event_maps.id.events.create.build(event),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	remove = (projectId, futureMapId, eventId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.events.delete.method,
			                             url: API.data.projects.id.event_maps.id.events.delete.url(projectId, futureMapId),
			                             data: API.data.projects.id.event_maps.id.events.delete.build(eventId),
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	copy = (projectId, futureMapId, event, factor, date) => {
		const copy = Object.assign({}, event);
		copy.factor = factor;
		copy.date = DateUtils.convertToISOUTCDatetime(date);
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.events.copy.method,
			                             url: API.data.projects.id.event_maps.id.events.copy.url(projectId, futureMapId),
			                             data: API.data.projects.id.event_maps.id.events.copy.build(copy),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	importEvents = (projectId, futureMapId, attachment) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.events.import.method,
			                             url: API.data.projects.id.event_maps.id.events.import.url(projectId, futureMapId),
			                             data: API.data.projects.id.event_maps.id.events.import.build(attachment),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	exportEvents = (projectId, futureMapId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.events.export.method,
			                             url: API.data.projects.id.event_maps.id.events.export.url(projectId, futureMapId),
			                             data: API.data.projects.id.event_maps.id.events.export.build({format: 'xls'}),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	}
}

export default EventService;