import {numericUnitService} from "../services";

export const actionTypes = {

	prefix: 'NUMERIC_UNIT',

	REQUEST_NUMERIC_UNITS: 'REQUEST_NUMERIC_UNITS',
	RECEIVE_NUMERIC_UNITS: 'RECEIVE_NUMERIC_UNITS',
	REQUEST_PROJECT_NUMERIC_UNITS: 'REQUEST_PROJECT_NUMERIC_UNITS',
	RECEIVE_PROJECT_NUMERIC_UNITS: 'RECEIVE_PROJECT_NUMERIC_UNITS',
	SELECT_NUMERIC_UNIT: 'SELECT_NUMERIC_UNIT',
	REQUEST_NUMERIC_UNIT: 'REQUEST_NUMERIC_UNIT',
	RECEIVE_NUMERIC_UNIT: 'RECEIVE_NUMERIC_UNIT',
	APPEND_ITEM: 'APPEND_ITEM',
	INSERT_ITEM: 'INSERT_ITEM',
	START_OPERATION: 'START_OPERATION',
	END_OPERATION: 'END_OPERATION',

	get: function (key) {
		return this.prefix + '_' + this[key];
	}
};

export const fetchNumericUnits = () => {
	return (dispatch, getState) => {
		if (!getState().numericUnitsReducer.isPending) {
			dispatch(requestNumericUnits());
			numericUnitService.getAll()
			                  .then(units_proxy => {
				                  dispatch(receiveNumericUnits(units_proxy.data));
			                  })
		}
	}
};

export const requestNumericUnits = () => {
	return {
		type: actionTypes.get(actionTypes.REQUEST_NUMERIC_UNITS)
	}
};

export const receiveNumericUnits = (response) => {
	return {
		type: actionTypes.get(actionTypes.RECEIVE_NUMERIC_UNITS),
		data: response
	}
};

export const selectNumericUnit = (numericUnit) => {
	return {
		type: actionTypes.get(actionTypes.SELECT_NUMERIC_UNIT),
		data: numericUnit
	}
};

export const fetchNumericUnit = (id) => {
	return (dispatch, getState) => {
		if (!getState().numericUnitsReducer.isPending) {
			dispatch(requestNumericUnit());
			numericUnitService.getById(id)
			                  .then(unit_proxy => {
				                  dispatch(receiveNumericUnit(unit_proxy.data));
			                  })
		}
	}
};

export const requestNumericUnit = () => {
	return {
		type: actionTypes.get(actionTypes.REQUEST_NUMERIC_UNIT)
	}
};

export const receiveNumericUnit = (response) => {
	return {
		type: actionTypes.get(actionTypes.RECEIVE_NUMERIC_UNIT),
		data: response
	}
};

export const appendNumericUnit = (data) => {
	return {
		type: actionTypes.get(actionTypes.APPEND_ITEM),
		data: data
	}
};

export const insertNumericUnit = (data) => {
	return {
		type: actionTypes.get(actionTypes.INSERT_ITEM),
		data: data
	}
};

export const createNumericUnit = (numericUnit) => {
	return (dispatch, getState) => {

		dispatch(receiveNumericUnit(null));

		numericUnitService.save(numericUnit)
		                  .then(unit_proxy => {
			                  dispatch(fetchNumericUnits());
		                  })
	}
};

export const saveNumericUnit = (numericUnit) => {
	return (dispatch, getState) => {

		dispatch(receiveNumericUnit(null));

		numericUnitService.save(numericUnit)
		                  .then(unit_proxy => {
			                  dispatch(fetchNumericUnits());
		                  })
	}
};

export const deleteNumericUnit = (id) => {
	return (dispatch, getState) => {
		numericUnitService.delete(id)
		                  .then(unit_proxy => {
			                  dispatch(fetchNumericUnits());
		                  })
	}
};

export const startOperation = (operation) => {
	return {
		type: actionTypes.get(actionTypes.START_OPERATION),
		data: operation
	}
};

export const endOperation = (operation) => {
	return {
		type: actionTypes.get(actionTypes.END_OPERATION),
		data: operation
	}
};