import React from "react";
import TrendItem from "../components/trend/trend-item";
import {Button, Col, Glyphicon, Grid, Nav, Navbar, NavItem, Row, Table} from "react-bootstrap";

class TrendsView
	extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			...this.setPermissions(props)
		};
	}

	componentDidMount() {
		if (this.props.project && !this.props.trends) {
			this.props.loadTrends(this.props.project);
		}
	}


	componentDidUpdate() {
		if (this.props.project && !this.props.trends) {
			this.props.loadTrends(this.props.project);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
		this.setState({
			              ...this.setPermissions(nextProps)
		              });
	}

	render() {
		return (
			<Grid fluid={true} className="h-100">
				<Row className="toolbar">
					<Col md={12}>
						<Navbar fluid={true} className="toolbar">
							<Nav>
								{
									this.state.addEnabled &&
									<NavItem>
										<Button bsClass="btn bar-button" onClick={this.handleAddButtonClick}>
											<Glyphicon glyph="plus" className="c-glyph-icon"/>
											ADD
										</Button>
									</NavItem>
								}
								{
									this.state.editEnabled &&
									<NavItem>
										<Button bsClass="btn bar-button" onClick={this.handleEditButtonClick}>
											<Glyphicon glyph="edit" className="c-glyph-icon"/>
											EDIT
										</Button>
									</NavItem>
								}
								{
									this.state.deleteEnabled &&
									<NavItem>
										<Button bsClass="btn bar-button" onClick={this.handleDeleteButtonClick}>
											<Glyphicon glyph="remove" className="c-glyph-icon"/>
											DELETE
										</Button>
									</NavItem>
								}
							</Nav>
						</Navbar>
					</Col>
				</Row>
				<Row className="content">
					<Col md={12} className="h-100 table-wrapper">
						<Table>
							<thead>
							<tr>
								<th>Factor name</th>
								<th>Trend Name</th>
								<th>Description</th>
								<th>Period Start</th>
								<th>Period End</th>
								<th>Color</th>
							</tr>
							</thead>
							<tbody>
							{
								this.props.trends && this.props.trends.map(item => {
									return <TrendItem key={item.id}
									                  item={item}
									                  onItemClick={this.handleOnItemClick}
									                  onItemDoubleClick={this.handleOnItemDoubleClick}
									                  isSelected={(item) => this.props.selectedTrend && this.props.selectedTrend.id === item.id}
									/>
								})
							}
							</tbody>
						</Table>
					</Col>
				</Row>
			</Grid>
		);
	}

	setPermissions = (props) => {
		if (props.isAdministrator(props.project)) {
			return {
				addEnabled: true,
				editEnabled: true,
				deleteEnabled: true
			}
		} else if (props.isAnalyst(props.project)) {
			return {
				addEnabled: true,
				editEnabled: true,
				deleteEnabled: true
			}
		} else if (props.isManager(props.project)) {
			return {
				addEnabled: true,
				editEnabled: true,
				deleteEnabled: true
			}
		} else {
			return {
				addEnabled: false,
				editEnabled: true,
				deleteEnabled: false
			}
		}
	};

	handleOnItemClick = (elem, item, ctrlKey) => {
		this.props.selectTrend(item);
	};

	handleOnItemDoubleClick = (elem, item, ctrlKey) => {
		this.props.editTrend(this.props.project, item);
	};

	handleDeleteButtonClick = () => {
		if (this.props.selectedTrend) {
			if (confirm(`Are you sure you want to delete trend: ${this.props.selectedTrend.name}?`)) {
				this.props.deleteTrend(this.props.project, this.props.selectedTrend);
			}
		} else {
			alert("You must select one of the trends");
		}
	};

	handleEditButtonClick = () => {
		if (this.props.selectedTrend) {
			this.props.editTrend(this.props.project, this.props.selectedTrend)
		} else {
			alert("You must select one of the trends");
		}
	};

	handleAddButtonClick = () => {
		this.props.addTrend(this.props.project);
	}
}


export default TrendsView