import clientFactory from "../factories/http_client";
import {API} from "../api/api";

class NumericUnitService {

	/** @return  Promise<AxiosResponse<T>> */
	getById = (id) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.numeric_units.id.method,
			                             url: API.data.numeric_units.id.url(id)
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	getAll = () => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.numeric_units.method,
			                             url: API.data.numeric_units.url
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	create = (unit) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.numeric_units.create.method,
			                             url: API.data.numeric_units.create.url,
			                             data: API.data.numeric_units.create.build(unit)
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	save = (unit) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.numeric_units.save.method,
			                             url: API.data.numeric_units.save.url,
			                             data: API.data.numeric_units.save.build(unit)
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	delete = (unit_id) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.numeric_units.delete.method,
			                             url: API.data.numeric_units.delete.url,
			                             data: API.data.numeric_units.delete.build(unit_id)
		                             });
	};
}

export default NumericUnitService