import React from 'react';
import {Button, Glyphicon, Navbar, Table} from 'react-bootstrap';

import BaseComponent from '../../../components/base/base-component';
import Utils from '../../../utils/utils';


const MODAL_MODE = {
    ADD: 0,
    EDIT: 1
};

class AttachmentsTab extends BaseComponent {

    constructor(props) {
        super(props);
    }

    render() {

        let key = 0;
        return(
            <div className="tab-content">
                <Navbar fluid={true} bsClass="c-navbar navbar-default">
                    <div className="tab-buttons-group">
                        {
                            this.props.hasAddButton &&
                            <Button bsClass="btn icon action-button" onClick={this.addButtonHandler}>
                                <Glyphicon glyph="plus" className="c-glyph-icon"/>
	                            <div className="label-wrapper">
		                            <label>Add</label>
	                            </div>
                            </Button>
                        }
                        {
                            this.props.hasEditButton &&
                            <Button bsClass="btn icon action-button" onClick={this.editButtonHandler}>
                                <Glyphicon glyph="edit" className="c-glyph-icon"/>
	                            <div className="label-wrapper">
		                            <label>Edit</label>
	                            </div>
                            </Button>
                        }
                        {
                            this.props.hasDeleteButton &&
                            <Button bsClass="btn icon action-button" onClick={this.deleteButtonHandler}>
                                <Glyphicon glyph="remove" className="c-glyph-icon"/>
	                            <div className="label-wrapper">
		                            <label>Delete</label>
	                            </div>
                            </Button>
                        }
                    </div>
                </Navbar>
                <div className="table-wrapper">
                    <Table>
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Upload date</th>
                            <th style={{width: '15%', maxWidth: '20%'}}>Size</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.items
                                .map(item => {
                                    return(
                                        <tr key={key++}
                                            onClick={() => this.itemClickHandler(item)}
                                            className={this.props.selectedItem && this.props.selectedItem.id === item.id ? 'selected' : ''}>
                                            <td>{item.name}</td>
                                            <td>{Utils.convertDatetime(item.uppload_date)}</td>
                                            <td>{item.size}</td>
                                            <td>{item.description}</td>
                                        </tr>
                                );
                            })
                        }
                        </tbody>
                    </Table>
                </div>
            </div>
        );
    }

    itemClickHandler = (item) => {
        this.props.onItemClick(item)
    };

    addButtonHandler = () => {
        this.props.onAdd();
    };

    editButtonHandler = () => {
        this.props.onEdit(this.props.selectedItem);
    };

    deleteButtonHandler = () => {
        this.props.onDelete(this.props.selectedItem);
    };
}

export default AttachmentsTab;