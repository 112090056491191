import BaseForm from "../base/base-form";
import React from "react";
import {Button, Col, Form, Grid, Modal, Row} from "react-bootstrap";

class OperationReportDialog extends BaseForm {

    constructor(props) {
        super(props);
    }

    render() {

        const self = this;

        let lines = [];

        if (this.props.data != null) {

            const errorsProvider = this.props.errorsProvider ? this.props.errorsProvider : this.defaultErrorsProvider;

            const errors = errorsProvider(this.props.data);

            errors.forEach(function (entry) {
               lines.push(<div className="error">{self.props.labelProvider(entry, 'error')}</div>);
            });

            const warningsProvider = this.props.warnignsProvider ? this.props.warnignsProvider : this.defaultWarningsProvider;

            const warnings = warningsProvider(this.props.data);
            warnings.forEach(function (entry) {
                lines.push(<div className="warning">{self.props.labelProvider(entry, 'warning')}</div>);
            });

            const successesProvider = this.props.successesProvider ? this.props.successesProvider : this.defaultSuccessesProvider;

            const successes = successesProvider(this.props.data);
            successes.forEach(function (entry) {
                lines.push(<div className="success">{self.props.labelProvider(entry, 'success')}</div>);
            });
        }

        return (
            <Modal show={this.props.show} onHide={this.handleCancelButton} className="operation-report-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Operation Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form horizontal={true}>
                        <Grid fluid={true}>
                            <Row>
                                <Col md={12}>
                                    <div className="content">
                                        {lines}
                                    </div>
                                </Col>
                            </Row>
                        </Grid>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button bsClass="btn pull-right action-button" onClick={this.handleCancelButton}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    handleCopyButton = () => {
        this.props.onCopy(this.state.level);
    };

    handleCancelButton = () => {
        this.props.onCancel();
    };

    defaultErrorsProvider = (data) => {
        return data.error_entries ? data.error_entries : [];
    };

    defaultWarningsProvider = (data) => {
        return data.warning_entries ? data.warning_entries : [];
    };

    defaultSuccessesProvider = (data) => {
        return data.success_entries ? data.success_entries : [];
    }
}

export default OperationReportDialog