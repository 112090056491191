import {actionTypes} from "../actions/flags";

const initialState = {
    isPending: false,
    invalidated: true,
    flags: null
};

function flagsReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.get(actionTypes.REQUEST_FLAGS):
            return {
                ...state,
                isPending: true
            };
        case actionTypes.get(actionTypes.RECEIVE_FLAGS):
            return {
                ...state,
                flags: action.data,
                isPending: false
            };
        default:
            return state
    }
}

export default flagsReducer