import {actionTypes} from "../actions/event-relations";

const initialState = {
	isPending: false,
	invalidated: false,
	eventsRelations: null,
	selectedEventRelation: null,
	operation: null
};

function eventRelationsReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.get(actionTypes.SELECT_EVENT_RELATION):
			return {
				...state,
				selectedEventRelation: action.data
			};
		case actionTypes.get(actionTypes.REQUEST_EVENT_RELATIONS):
			return {
				...state,
				isPending: true
			};
		case actionTypes.get(actionTypes.RECEIVE_EVENT_RELATIONS):
			return {
				...state,
				isPending: false,
				eventsRelations: action.data
			};
		case actionTypes.get(actionTypes.START_OPERATION):
			return {
				...state,
				operation: action.data
			};
		case actionTypes.get(actionTypes.END_OPERATION):
			return {
				...state,
				operation: action.data
			};
		default:
			return state
	}
}

export default eventRelationsReducer