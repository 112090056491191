import LinksTab from './links-tab';
import CommentsTab from './comments-tab';
import AttachmentsTab from './attachments-tab';

export {
    LinksTab,
    CommentsTab,
    AttachmentsTab
};

export default {
    LinksTab,
    CommentsTab,
    AttachmentsTab
};