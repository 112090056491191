import React from 'react';
import {Button, Col, ControlLabel, Form, FormGroup, Glyphicon, Grid, OverlayTrigger, Row, Tab, Tabs, Tooltip} from 'react-bootstrap';
import {withRouter} from 'react-router';

import BaseForm from '../../components/base/base-form';
import {FilterGroupPredicate} from './filter-group-predicate-form';
import {PredicateRelation as PR, PredicateValueType as PVT} from './filter-models';
import {eventCategoryService, eventDictionaryService as DictionaryService, eventsMapService, filterService, projectService} from '../../services';
import {MULTIPART_POST_CONSTANTS as MPC} from '../../utils/request-parameters-constants';
import {CLIENT_URI_CONSTANTS as URI} from "../../utils/uri-constants";
import Auth from '../../utils/auth';
import {FilterUtils as FilterService, FilterUtils} from '../../utils/local-storage';
import BaseComponent from '../../components/base/base-component';
import {connect} from "react-redux";


const TAB_KEYS = {
	SIMPLE: 1,
	EXTENDED: 2
};

const TAB_REFS = {
	SIMPLE: "simple-tab-ref",
	EXTENDED: "extended-tab-ref"
};

const EVENT_ENTITY_TYPE = "EVENT";


class SimpleFilterTab
	extends BaseComponent {

	constructor(props) {
		super(props);

		this.predicate = props.predicate;
		this.predicate.relation = PR.AND;
	}

	render() {
		return (
			<div>
				<FilterGroupPredicate predicate={this.predicate}
				                      extended={false}
				                      properties={this.props.properties}
				                      getDataByType={this.props.getDataByType}
				/>
			</div>
		)
	}

	getPredicate() {
		return this.predicate;
	}
}

class ExtendedFilterTab
	extends BaseComponent {

	constructor(props) {
		super(props);

		this.predicate = props.predicate;
	}

	render() {
		return (
			<div>
				{
					!this.predicate.relation && (
						<Grid bsClass="grid button-bar">
							<Row>
								<Col md={12}>
									<OverlayTrigger placement="bottom"
									                overlay={<Tooltip id="tooltip">Add And Group</Tooltip>}>
										<Button bsClass="btn bar-button" onClick={this.addAndGroupButtonHandler}>
											AND Group
										</Button>
									</OverlayTrigger>
									<OverlayTrigger placement="bottom"
									                overlay={<Tooltip id="tooltip">Add Or Group</Tooltip>}>
										<Button bsClass="btn bar-button" onClick={this.addOrGroupButtonHandler}>
											OR Group
										</Button>
									</OverlayTrigger>
								</Col>
							</Row>
						</Grid>
					)
				}
				{
					this.predicate.relation && (
						<div>
							<FilterGroupPredicate predicate={this.predicate}
							                      extended={true}
							                      properties={this.props.properties}
							                      getDataByType={this.props.getDataByType}
							/>
							<OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip">Delete</Tooltip>}>
								<Button bsClass="btn bar-button remove-btn pull-right"
								        onClick={this.removeButtonHandler}>
									<Glyphicon glyph="remove" className="c-glyph-icon"/>
								</Button>
							</OverlayTrigger>
						</div>
					)
				}
			</div>
		)
	}

	addAndGroupButtonHandler() {
		this.predicate.relation = PR.AND;
		this.forceUpdate();
	}

	addOrGroupButtonHandler() {
		this.predicate.relation = PR.OR;
		this.forceUpdate();
	}

	removeButtonHandler() {
		this.predicate.relation = null;
		this.predicate.children = [];
		this.forceUpdate();
	}

	getPredicate() {
		return this.predicate;
	}
}


class FilterForm
	extends BaseForm {

	constructor(props) {
		super(props);

		this.projectId = this.props.match.params.projectId;
		this.futureMapId = this.props.project.eventsMap.id;

		this.filterId = this.props.match.params.filterId;
		this.filter = null;

		this.state = {
			title: "",
			predicate: null,
			activeTab: TAB_KEYS.SIMPLE,

			properties: null,
			factors: null,
			eventSources: null,
			eventTypes: null,
			eventCategories: null
		};
	}

	componentDidMount() {
		eventsMapService.getCompactFactors(
			this.projectId,
			this.futureMapId,
			{
				[MPC.FACTOR_FIELDS]: [
					'title',
					'create_date',
					'depth',
					'parent',
					'children'
				]
			}
		)
		                .then(factors => {
			                filterService.getEntityProperties(EVENT_ENTITY_TYPE)
			                             .then(properties => {
				                             projectService.getEventSources(this.projectId)
				                                               .then(eventSources => {
					                                               DictionaryService.getEventTypes(this.projectId)
					                                                                .then(eventTypes => {
						                                                                eventCategoryService.getCategoriesByProject(
							                                                                this.projectId)
						                                                                                    .then(
							                                                                                    eventCategories => {
								                                                                                    if (this.filterId && this.filterId !== "0") {
									                                                                                    FilterService.getFilter(
										                                                                                    this.projectId,
										                                                                                    this.futureMapId,
										                                                                                    this.filterId)
									                                                                                                 .then(
										                                                                                                 filter => {
											                                                                                                 this.filter = filter;
											                                                                                                 this.setState(
												                                                                                                 {
													                                                                                                 title: filter.title,
													                                                                                                 predicate: filter.predicate,
													                                                                                                 activeTab: TAB_KEYS.EXTENDED,

													                                                                                                 properties: properties,
													                                                                                                 factors: factors,
													                                                                                                 eventSources: eventSources,
													                                                                                                 eventTypes: eventTypes,
													                                                                                                 eventCategories: eventCategories
												                                                                                                 });
										                                                                                                 })
								                                                                                    } else {
									                                                                                    this.setState(
										                                                                                    {
											                                                                                    properties: properties,
											                                                                                    factors: factors,
											                                                                                    eventSources: eventSources,
											                                                                                    eventTypes: eventTypes,
											                                                                                    eventCategories: eventCategories
										                                                                                    });
								                                                                                    }
							                                                                                    })
					                                                                })
				                                               })
			                             })
		                });
	}

	getCondition() {
		return this.state.properties;
	}

	conditionalRender() {
		return (
			<div id="filter-form">
				<Form horizontal={true}>
					<FormGroup>
						<Col componentClass={ControlLabel} md={2}>
							Filter name:
						</Col>
						<Col md={10}>
							<input className="form-control"
							       type="text"
							       onChange={this.setEventValue.bind(this, 'title')}
							       value={this.state.title ? this.state.title : ""}/>
						</Col>
					</FormGroup>
					<FormGroup>
						<Col md={12}>
							<Tabs activeKey={this.state.activeTab} onSelect={this.tabSelectionHandler}
							      id="filter-form-tabs">
								{
									!this.filter && (
										<Tab eventKey={TAB_KEYS.SIMPLE} title="Simple">
											<SimpleFilterTab ref={TAB_REFS.SIMPLE}
											                 predicate={{}}
											                 properties={this.state.properties}
											                 getDataByType={this.getDataByType}
											/>
										</Tab>
									)
								}
								<Tab eventKey={TAB_KEYS.EXTENDED} title="Extended">
									<ExtendedFilterTab ref={TAB_REFS.EXTENDED}
									                   predicate={this.state.predicate ? this.state.predicate : {}}
									                   properties={this.state.properties}
									                   getDataByType={this.getDataByType}
									/>
								</Tab>
							</Tabs>
						</Col>
					</FormGroup>
					<FormGroup>
						<Col md={12}>
							<Button bsClass="btn action-button pull-right"
							        onClick={this.cancelButtonHandler}>
								Cancel
							</Button>
							<Button bsClass="btn action-button pull-right"
							        onClick={this.saveButtonHandler}>
								Save and Open
							</Button>
							<Button bsClass="btn action-button pull-right"
							        onClick={this.filterButtonHandler}>
								Filter
							</Button>
						</Col>
					</FormGroup>
				</Form>
			</div>
		)
	}

	tabSelectionHandler(tabIndex) {
		this.setState({activeTab: tabIndex});
	}

	getDataByType(type) {
		switch (type) {
			case PVT.FACTOR: {
				return this.state.factors;
			}
			case PVT.EVENT_SOURCE: {
				return this.state.eventSources;
			}
			case PVT.EVENT_CATEGORY: {
				return this.state.eventCategories;
			}
			case PVT.EVENT_TYPE: {
				return this.state.eventTypes;
			}
			default:
				return [];
		}
	}

	_fillFilter(user) {
		if (!this.filter) {
			this.filter = {}
		}
		this.filter.entity_type = EVENT_ENTITY_TYPE;
		this.filter.title = this.state.title;

		this.filter.user = {
			id: user.id,
			user_name: user.name
		};

		switch (this.state.activeTab) {
			case TAB_KEYS.SIMPLE: {
				this.filter.predicate = this.refs[TAB_REFS.SIMPLE].getPredicate();
				break;
			}
			case TAB_KEYS.EXTENDED: {
				this.filter.predicate = this.refs[TAB_REFS.EXTENDED].getPredicate();
				break;
			}
		}
	}

	_openFilter(user) {
		const filterId = FilterUtils.addFilter(user.id, this.projectId, this.filter);
		this.props.history.push(
			`/${URI.PROJECT}/${this.projectId}/${this.futureMapId}/${URI.FILTERED_FUTURE_MAP}/${filterId}`);
	}

	filterButtonHandler() {
		const user = Auth.getUser();
		this._fillFilter(user);
		this._openFilter(user);
	}

	saveButtonHandler() {
		const user = Auth.getUser();
		this._fillFilter(user);

		if (this.filter.id) {
			FilterService.save(this.projectId, this.futureMapId, this.filter)
			             .then(filter => {
				             this.filter = filter;
				             this._openFilter(user);
			             })
		} else {
			FilterService.create(this.projectId, this.futureMapId, this.filter)
			             .then(filter => {
				             this.filter = filter;
				             this._openFilter(user);
			             })
		}
	}

	cancelButtonHandler() {
		this.props.history.goBack();
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		project: state.projectReducer.project,
		factors: state.factorReducer.factors,
		events: state.eventsReducer.events,
		trends: state.trendsReducer.trends,
		colorSchema: state.colorSchemaReducer.schema
	}
};

export default withRouter(connect(mapStateToProps, null)(FilterForm));