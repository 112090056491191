import React from "react";
import Utils from "../../utils/utils";

class ScenarioItem extends React.Component {

    constructor(props) {
        super(props);

        this.onItemClick = this.props.onItemClick;
        this.onItemDoubleClick = this.props.onItemDoubleClick;
        this.isSelected = this.props.isSelected;
    }

    render() {
        const item = this.props.item;
        return (
            <tr onClick={(param) => Utils.singleDoubleClick(
                param.target,
                item,
                this.onItemClick,
                this.onItemDoubleClick,
                param.ctrlKey,
                200)
            }
                className={`clickable ${this.isSelected(item) ? 'selected' : ''}`}
            >
                <td> {item.title ? item.title : "Unnamed"} </td>
            </tr>
        );
    }
}

export default ScenarioItem