

export const actionTypes = {

	prefix: 'ORPHAN',

	REQUEST_ITEMS: 'REQUEST_ITEMS',
	RECEIVE_ITEMS: 'RECEIVE_ITEMS',
	APPEND_ITEMS: 'APPEND_ITEMS',
	REQUEST_ITEM: 'REQUEST_ITEM',
	RECEIVE_ITEM: 'RECEIVE_ITEM',
	SELECT_ITEM: 'SELECT_ITEM',
	RECEIVE_FILTER: 'RECEIVE_FILTER',

	get: function (key) {
		return this.prefix + '_' + this[key];
	}
};

export const requestItems = () => {
	return {
		type: actionTypes.get(actionTypes.REQUEST_ITEMS)
	}
}

export const receiveItems = (data) => {
	return {
		type: actionTypes.get(actionTypes.RECEIVE_ITEMS),
		data: data
	}
}

export const appendItems = (data) => {
	return {
		type: actionTypes.get(actionTypes.APPEND_ITEMS),
		data: data
	}
}

export const requestItem = () => {
	return {
		type: actionTypes.get(actionTypes.REQUEST_ITEM)
	}
}

export const receiveItem = (data) => {
	return {
		type: actionTypes.get(actionTypes.RECEIVE_ITEM),
		data: data
	}
}

export const receiveFilter = (data) => {
	return {
		type: actionTypes.get(actionTypes.RECEIVE_FILTER),
		data: data
	}
}

export const selectItem = (item) => {
	return {
		type: actionTypes.get(actionTypes.SELECT_ITEM),
		data: item
	}
}