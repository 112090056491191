import React from 'react';

import BaseComponent from './base-component';

class BaseForm extends BaseComponent {

    constructor(props) {
        super(props);
    }

    setEventValue(field, event) {
        //If the input fields were directly within this
        //this component, we could use this.refs.[FIELD].value
        //Instead, we want to save the data for when the form is submitted
        const object = {};
        object[field] = event.target.value;
        this.setState(object);
    }

    setEventChecked(field, event) {
        //If the input fields were directly within this
        //this component, we could use this.refs.[FIELD].value
        //Instead, we want to save the data for when the form is submitted
        const object = {};
        object[field] = event.target.checked;
        this.setState(object);
    }

    setValue(field, value) {
        //If the input fields were directly within this
        //this component, we could use this.refs.[FIELD].value
        //Instead, we want to save the data for when the form is submitted
        const object = {};
        object[field] = value;
        this.setState(object);
    }
}

export default BaseForm;