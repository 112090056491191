import React from "react";
import {connect} from "react-redux";
import * as authentication_actions from "../actions/authentication";
import {authenticationService} from "../services";
import * as authorities_actions from "../actions/authorities";
import {withRouter} from "react-router";

class AuthenticationProvider
	extends React.Component {

	componentDidMount() {
		authenticationService.getAuthentication()
			.then((authentication_promise) => {
				this.props.set_authentication(authentication_promise.data);
			})
	}

	render() {
		if (this.props.authentication) {
			return this.props.children;
		} else {
			return <div>Authenticating...</div>;
		}
	}
}


const mapStateToProps = (state, ownProps) => {
	return {
		authentication: state.authenticationReducer.authentication,
	}
};

const mapDispatchToProps = dispatch => {
	return {
		set_authentication: (authentication) => {
			dispatch(authentication_actions.receiveAuthentication(authentication));
			dispatch(authorities_actions.receiveAuthorities([authentication.principal.role]))
		}
	}
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthenticationProvider));