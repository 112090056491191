import {actionTypes} from "../actions/dialogs";

const initialState = {
    copyLevelDialogOpened: false,
    restoreDialogOpened: false,
    retrospectionEntries: []
};

function dialogsReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SHOW_RESTORE_PROJECT_DIALOG:
            return {
                ...state,
                restoreDialogOpened: true,
                retrospectionEntries: action.data
            };
        case actionTypes.CLOSE_RESTORE_PROJECT_DIALOG:
            return {
                ...state,
                restoreDialogOpened: false,
                retrospectionEntries: []
            };
        case actionTypes.SHOW_COPY_LEVEL_DIALOG:
            return {
                ...state,
                copyLevelDialogOpened: true
            };
        case actionTypes.CLOSE_COPY_LEVEL_DIALOG:
            return {
                ...state,
                copyLevelDialogOpened: false
            };
        default:
            return state
    }
}

export default dialogsReducer