import {actionTypes} from "../actions/trends";

const initialState = {
    isPending: false,
    invalidated: true,
    trends: null,
    trend: null,
    selectedTrend: null,
    operation: null
};

function trendsReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.get(actionTypes.REQUEST_TRENDS):
            return {
                ...state,
                isPending: true,
                trends: action.data
            };
        case actionTypes.get(actionTypes.RECEIVE_TRENDS):
            return {
                ...state,
                isPending: false,
                trends: action.data
            };
        case actionTypes.get(actionTypes.SELECT_TREND):
            return {
                ...state,
                selectedTrend: action.data
            };
        case actionTypes.get(actionTypes.REQUEST_TREND):
            return {
                ...state,
                isPending: true
            };
        case actionTypes.get(actionTypes.RECEIVE_TREND):
            return {
                ...state,
                isPending: false,
                trend: action.data
            };
        case actionTypes.get(actionTypes.SELECT_EVENT):
            return {
                ...state,
                selectedEvent: action.data
            };
        case actionTypes.get(actionTypes.INSERT_TREND):

            let index = state.trends.findIndex((item) => {
                return item.id === action.data.id;
            });

            if (index !== -1) {
                state.trends.splice(index, 1, action.data)
            } else {
                state.trends.push(action.data);
            }

            return {
                ...state,
                isPending: false,
                trends: [...state.trends]
            };
        case actionTypes.get(actionTypes.START_OPERATION):
            return {
                ...state,
                operation: action.data
            };
        case actionTypes.get(actionTypes.END_OPERATION):
            return {
                ...state,
                operation: action.data
            };
        default:
            return state
    }
}

export default trendsReducer