const MULTIPART_POST_CONSTANTS = {
    DATA: "data",
    EVENT_FIELDS: "event_fields",
    FACTOR_FIELDS: "factor_fields"
};

const PROJECT_CONSTANTS = {
    PROJECT_ID: "id",
    USER_ID: "user_id",
    DEPTH: "depth",
    TITLE: "title",
    DICT: "dict",
    PERIOD_START_DATE: "period_start_date",
    PERIOD_END_DATE: "period_end_date",
    ATTACHMENT_ID: "attachment_id"
};

const FACTOR_CONSTANTS = {
    DEPTH: "depth",
    FUTURE_MAP_ID: "future_map_id",
    ID: "id",
    ORPHAN_REMOVAL: "orphan_removal",
    PARENT_ID: "parent_id"
};

const EVENT_RELATES_CONSTANTS = {
    SKIP_FIRST: "skip_first"
};

const FUTURE_MAP_CONSTANTS = {
    FUTURE_MAP_ID: "future_map_id",
    FACTOR_ID: "factor_id"
};

const FILTER_CONSTANTS = {
    ENTITY_TYPE: "entity_type",
    FUTURE_MAP_ID: "future_map_id"
};

const COPY_CONSTANTS = {
    WITH_EVENTS: "with_events",
    RECURSIVE: "recursive"
};

const LOGIN_ATTEMPT_CONSTANTS = {
    START: "start",
    END: "end"
};

export {
    MULTIPART_POST_CONSTANTS,
    COPY_CONSTANTS,
    EVENT_RELATES_CONSTANTS,
    FACTOR_CONSTANTS,
    FILTER_CONSTANTS,
    FUTURE_MAP_CONSTANTS,
    LOGIN_ATTEMPT_CONSTANTS,
    PROJECT_CONSTANTS
};