import {connect} from "react-redux";
import {withRouter} from "react-router";
import HomeView from "../views/home";
import * as projectsActions from "../actions/projects";
import * as dialogsActions from "../actions/dialogs";
import {authorities} from "../mixins/authorities";
import * as project_actions from "../actions/projects";
import {notificationService, projectService} from "../services";
import * as notifications_actions from "../actions/notifications";

const mapStateToProps = (state, ownProps) => {
	return {
		projects: state.projectReducer.projects,
		projectsFilter: state.projectReducer.filter,
		selectedProject: state.projectReducer.selectedProject,
		copyLevelDialogOpened: state.dialogsReducer.copyLevelDialogOpened,
		restoreDialogOpened: state.dialogsReducer.restoreDialogOpened,
		retrospectionEntries: state.dialogsReducer.retrospectionEntries,
		notifications: state.notifications.items,
		notificationsFilter: state.notifications.filter,
		...authorities(state.authenticationReducer.authentication)
	}
};

const mapDispatchToProps = (dispatch, getState) => {
	return {
		loadInitialData: () => {
			dispatch(loadInitialDataActionChain());
		},
		requestProjects: (filter) => {
			dispatch(projectsActions.fetchProjects(filter))
		},
		selectProject: (project) => {
			dispatch(projectsActions.selectProject(project))
		},
		createProject: () => {
			dispatch(projectsActions.addProject());
		},
		editProject: (project) => {
			dispatch(projectsActions.editProject(project));
		},
		openRestoreDialog: (project) => {
			dispatch(dialogsActions.openRestoreProjectDialog(project));
		},
		closeRestoreProjectDialog: () => {
			dispatch(dialogsActions.closeRestoreProjectDialog());
		},
		restoreProject: (retrospectionEntry) => {
			dispatch(dialogsActions.closeRestoreProjectDialog());
			dispatch(projectsActions.restoreProject(retrospectionEntry));
		},
		openCopyLevelDialog: () => {
			dispatch(dialogsActions.openCopyLevelDialog());
		},
		closeCopyLevelDialog: () => {
			dispatch(dialogsActions.closeCopyLevelDialog());
		},
		copyProject: (project, level) => {
			dispatch(dialogsActions.closeCopyLevelDialog());
			dispatch(projectsActions.copyProject(project, level));
		},
		deleteProject: (id) => {
			dispatch(deleteProjectActionChain(id));
		},
		filterProjects: (title) => {
			dispatch(filterProjects(title))
		},
		loadNotifications: (page_index, page_size) => {
			dispatch(loadNotifications(page_index, page_size))
		},
		appendNotifications: (page_index, page_size) => {
			dispatch(appendNotifications(page_index, page_size))
		},
		filterNotifications: (filter_data) => {
			dispatch(filterNotifications({
				                             project_ids: filter_data.projects.map((item) => {
					                             return item.id;
				                             }),
				                             period_code: filter_data.period.code
			                             }))
		}
	}
};

const deleteProjectActionChain = (id) => {
	return (dispatch, getState) => {
		projectService.remove(id)
			.then(project_id_proxy => {
				dispatch(loadDataActionChain(0, 100))
			});
	}
}

const loadDataActionChain = (page_index, page_size) => {
	return (dispatch, getState) => {
		projectService.get(page_index, page_size)
		              .then(projects_proxy => {

			              dispatch(project_actions.receiveProjects(projects_proxy.data));

			              let project_ids = getState()
				              .projectReducer
				              .projects
				              .map((item) => {
					              return item.id;
				              });

			              let notifications_filter = getState().notifications.filter;

			              if (notifications_filter && notifications_filter.projectIds) {
				              project_ids = project_ids.filter((item) => {
					              return notifications_filter.projectIds.indexOf(item) !== -1;
				              })
			              }

			              notificationService
				              .createFilter({
					                            projectIds: project_ids,
				                            })
				              .then(filter_proxy => {

					              dispatch(notifications_actions.receiveFilter(filter_proxy.data));

					              notificationService
						              .get(0, 20)
						              .then(notifications_proxy => {
							              dispatch(notifications_actions.receiveItems(notifications_proxy.data));
						              })
				              })
		              })
	}
}

const periodCodeToDates = (period_code) => {

	let now = new Date();

	let end_date = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999));

	let start_date;
	switch (period_code) {
		case 0:
			start_date = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
			break;
		case 1:
			now.setDate(now.getDate() - 3);
			start_date = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
			break;
		case 2:
			now.setDate(now.getDate() - 7);
			start_date = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
			break;
		case 3:
			now.setMonth(now.getMonth() - 1);
			start_date = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
			break;
		case 4:
			end_date = null
			start_date = null
			break;
	}

	return {
		start_date: start_date,
		end_date: end_date,
	}
}

const loadNotifications = (page_index, page_size) => {
	return (dispatch, getState) => {
		notificationService
			.get(page_index, page_size)
			.then(notifications_proxy => {
				dispatch(notifications_actions.receiveItems(notifications_proxy.data));
			})
	}
}

const appendNotifications = (page_index, page_size) => {
	return (dispatch, getState) => {
		notificationService
			.get(page_index, page_size)
			.then(notifications_proxy => {
				dispatch(notifications_actions.appendItems(notifications_proxy.data));
			})
	}
}

const filterNotifications = (filter) => {
	return (dispatch, getState) => {

		let start_date = null;
		let end_date = null;

		if (filter.period_code != null) {
			let dates = periodCodeToDates(filter.period_code);
			({
				start_date,
				end_date
			} = dates);
		}

		notificationService
			.createFilter({
				              projectIds: filter.project_ids,
				              periodStart: start_date,
				              periodEnd: end_date
			              })
			.then(filter_proxy => {

				dispatch(notifications_actions.receiveFilter({
					                                             ...filter_proxy.data,
					                                             period_code: filter.period_code
				                                             }));

				notificationService
					.get(0, 20)
					.then(notifications_proxy => {
						dispatch(notifications_actions.receiveItems(notifications_proxy.data));
					})
			})
	}
}


const filterProjects = (title) => {
	return (dispatch, getState) => {
		dispatch(project_actions.requestProjects(null));
		projectService
			.createFilter({
				              title: title
			              })
			.then(filter_proxy => {
				dispatch(project_actions.receiveFilter(filter_proxy.data));
				dispatch(loadDataActionChain(0, 100));
			})
	}
}


const loadInitialDataActionChain = () => {
	return (dispatch, getState) => {
		dispatch(project_actions.requestProjects(null));
		projectService
			.getFilter()
			.then(filter_proxy => {
				dispatch(project_actions.receiveFilter(filter_proxy.data));
				dispatch(loadDataActionChain(0, 100));
			})
	}
}

const HomeContainer = connect(mapStateToProps, mapDispatchToProps)(HomeView);

export default withRouter(HomeContainer)