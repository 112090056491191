import React from 'react';
import {Col, ControlLabel, Grid, Overlay, Popover, Row} from "react-bootstrap";
import {DateTimePicker} from "react-widgets";

class CurrentDateSlider
	extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			value: props.date ? props.date.getTime() : props.start.getTime(),
			dynamic: props.dynamic,
			showTooltip: false,
			showDateDialog: false,
		};
	}

	componentDidMount() {
		if (this.wrapperRef && this.sliderRef) {
			if (this.props.direction === "down-up" || this.props.direction === "up-down") {
				this.wrapperRef.style.width = this.thisRef.clientHeight + 'px';
			}
		}
	}

	componentDidUpdate() {
		if (this.wrapperRef && this.sliderRef) {
			if (this.props.direction === "down-up" || this.props.direction === "up-down") {
				this.wrapperRef.style.width = this.thisRef.clientHeight + 'px';
			}
		}
	}

	render() {

		const dateFormat = "DD.MM.YYYY";

		return (
			<div className="date-slider"
			     ref={
				     (element) => {
					     this.thisRef = element;
				     }
			     }
			>
				<div className={"wrapper " + this.props.direction}
				     ref={
					     (element) => {
						     this.wrapperRef = element;
					     }
				     }
				>
					<input type="range"
					       className="input"
					       ref={
						       (element) => {
							       this.sliderRef = element;
						       }
					       }
					       min={this.props.start.getTime()}
					       max={this.props.end.getTime()}
					       value={this.state.value}
					       onChange={this.handleValueChange}
					       onMouseDown={this.showTooltip}
					/>
					<Overlay target={this.sliderRef} show={this.state.showTooltip} placement="right" rootClose={true} onHide={this.handleHide} rootCloseEvent={'mousedown'}>
						<Popover title={"Current Date"} id={"current-date-slider-popover"}>
							<Grid fluid={true}>
								<Row>
									<Col md={2}>
										<ControlLabel htmlFor={"current-date"}>Date:</ControlLabel>
									</Col>
									<Col md={10}>
										<DateTimePicker id="current-date"
										                value={this.currentDate()}
										                onChange={this.handleDateValueChange}
										                time={false}
										                format={dateFormat}/>
									</Col>
								</Row>
								<Row>
									<Col md={4}>
										<ControlLabel htmlFor={"dynamic"}>Dynamic:</ControlLabel>
									</Col>
									<Col md={2}>
										<input id="dynamic" type="checkbox" checked={this.state.dynamic} onChange={this.handleDynamicChange}/>
									</Col>
								</Row>
							</Grid>
						</Popover>
					</Overlay>
				</div>
			</div>
		)
	}

	handleDateValueChange = (date) => {
		this.setState({
			              value: date.getTime(),
		              });
	};

	handleValueChange = (event) => {
		this.setState({
			              value: event.target.value,
		              });
	};

	handleDynamicChange = (event) => {
		this.setState({
			              dynamic: event.target.checked
		              });
	};

	showTooltip = (event) => {
		this.setState({
			              showTooltip: true,
		              });
	};

	handleHide = () => {
		this.setState({
			              showTooltip: false
		              },
		              () => {
			              if (this.props.onChange) {
				              this.props.onChange({
					                                  date: this.currentDate(),
					                                  dynamic: this.state.dynamic
				                                  })
			              }

		              });
	};

	currentDate = () => {

		const date = new Date();
		date.setTime(this.state.value);

		return date;
	};
}

export default CurrentDateSlider;