import {connect} from "react-redux";
import {withRouter} from "react-router";
import UserView from "../views/user";
import * as usersActions from "../actions/users";

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.usersReducer.user,
        userOperation: state.usersReducer.operation
    }
};

const mapDispatchToProps = (dispatch, state) => {
    return {
        loadUser: (id) => {
            dispatch(usersActions.fetchUser(id));
        },
        saveUser: (user) => {
            if (!user.id) {
                dispatch(usersActions.createUser(user))
            } else {
                dispatch(usersActions.saveUser(user));
            }
        },
        deleteUser: (user) => {
            dispatch(usersActions.deleteUser(user.id));
        },
        clearOperation: () => {
            dispatch(usersActions.startOperation(null));
        }
    }
};

const UserContainer = connect(mapStateToProps, mapDispatchToProps)(UserView);

export default withRouter(UserContainer)