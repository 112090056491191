import React from 'react';
import InfiniteContainer from "../../containers/infinite-container";

class VerticalHeader
	extends React.PureComponent {


	constructor(props) {

		super(props);

		this.state = {
			position: 0,
			maxItems: 15
		}

		this.cell_refs = {}
		this.centers_refs = {};
	}

	componentDidMount() {
		if (this.containerRef && this.props.scrollPositionChangeHandler) {
			this.containerRef.addEventListener('scroll', this.props.scrollPositionChangeHandler);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		const height = this.containerRef.clientHeight;

		const newMaxItems = Math.floor((height / this.props.rowHeight)) + 2;

		if (newMaxItems !== this.state.maxItems) {
			this.setState({
				              maxItems: newMaxItems
			              });
		}
	}

	componentWillUnmount() {
		if (this.containerRef && this.props.scrollPositionChangeHandler) {
			this.containerRef.removeEventListener('scroll', this.props.scrollPositionChangeHandler);
		}
	}

	render() {

		const rowFactory = this.props.rowFactory ? this.props.rowFactory : this.defaultRowFactory;

		return (
			<div id="fm-vertical-headers-scroll"
			     className="fm-vertical-headers-scroll vertical-header"
			     ref={this.containerRefCallback}
			>
				{
					this.props.items.length > 0 &&
					<InfiniteContainer lazy={true}
					                   itemFactory={rowFactory}
					                   itemsCount={this.props.items.length}
					                   itemHeight={this.props.rowHeight}
					                   maxItemsToRender={Math.floor(this.state.maxItems)}
					                   onRangeChange={this.handleRangeChange}
					                   containerRef={this.props.containerRef}
					/>
				}
				{this.props.children}
			</div>
		)
	}

	containerRefCallback = (element) => {

		this.containerRef = element;

		this.props.containerRef(element);
	};

	clearCenterCells = (scale) => {
		let old_elements = Object.values(this.centers_refs);
		if (old_elements) {
			old_elements.forEach(function (element) {
				element.classList.remove("center");
			});
			this.centers_refs = {};
		}
	}

	placeCenterCells = (start, end, scale) => {

		const containerBoundingRect = this.containerRef.getBoundingClientRect();

		const borders = [];

		for (let i = start; i <= end; i++) {
			if (this.props.items.length > (i + 1)) {
				if (scale === 'months') {
					if (this.props.items[i].start.getMonth() !== this.props.items[i + 1].start.getMonth() ||
						this.props.items[i].start.getFullYear() !== this.props.items[i + 1].start.getFullYear()) {
						borders.push(i);
					}
				} else if (scale === 'years') {
					if (this.props.items[i].start.getFullYear() !== this.props.items[i + 1].start.getFullYear()) {
						borders.push(i);
					}
				}
			}
		}

		if (borders.length !== 0 && this.props.items[borders.length - 1].start.getFullYear() !== this.props.items[end].start.getFullYear() && borders.indexOf(
			end) === -1) {
			borders.push(end);
		}

		let offset = containerBoundingRect.top;

		for (let j = 0; j < borders.length; j++) {

			const element = this.cell_refs[borders[j] + '-' + scale];

			const elementBoundingRect = element.getBoundingClientRect();

			const center = ((offset + elementBoundingRect.bottom) / 2) - 1;

			this.placeCenterCell(start, borders[j], center, scale);

			offset = elementBoundingRect.bottom;
		}

		this.placeCenterCell(borders[borders.length - 1], end, (offset + containerBoundingRect.bottom) / 2, 'months');
	}

	placeCenterCell = (start, end, center, scale) => {
		for (let i = start; i <= end; i++) {
			const element = this.cell_refs[i+'-'+scale];
			if (element) {
				const elementBoundingRect = element.getBoundingClientRect();
				if (elementBoundingRect.bottom > center && elementBoundingRect.top < center) {
					element.classList.add("center");
					this.centers_refs[i+'-'+scale] = element;
					break;
				}
			}
		}
	}

	handleRangeChange = (start, end) => {

		if (this.props.items.length === 0 || this.props.items.length < (end - 1)) {
			return;
		}

		if (this.props.timeScale === 'days') {

			if (this.props.items[start].start.getMonth() !== this.props.items[end].start.getMonth()) {

				this.clearCenterCells('months');

				this.placeCenterCells(start, end, 'months')
			} else {

				this.clearCenterCells('months');

				const containerBoundingRect = this.containerRef.getBoundingClientRect();
				const center = (containerBoundingRect.top + containerBoundingRect.bottom) / 2;

				this.placeCenterCell(start, end, center, 'months');
			}

			if (this.props.items[start].start.getFullYear() !== this.props.items[end].start.getFullYear()) {

				this.clearCenterCells('years');

				this.placeCenterCells(start, end, 'years')
			} else {

				this.clearCenterCells('years');

				const containerBoundingRect = this.containerRef.getBoundingClientRect();
				const center = (containerBoundingRect.top + containerBoundingRect.bottom) / 2;

				this.placeCenterCell(start, end, center, 'years');
			}
		} else if (this.props.timeScale === 'months') {
			if (this.props.items[start].start.getFullYear() !== this.props.items[end].start.getFullYear()) {

				this.clearCenterCells('years');

				this.placeCenterCells(start, end, 'years')
			} else {

				this.clearCenterCells('years');

				const containerBoundingRect = this.containerRef.getBoundingClientRect();
				const center = (containerBoundingRect.top + containerBoundingRect.bottom) / 2;

				this.placeCenterCell(start, end, center, 'years');
			}
		}
	};

	defaultRowFactory = (rowIndex) => {

		const self = this;

		const cellFactory = this.props.cellFactory ? this.props.cellFactory : this.defaultCellFactory;

		let timeScales = null;

		if (this.props.timeScale === 'years') {
			timeScales = ['years'];
		} else if (this.props.timeScale === 'months') {
			timeScales = ['years', 'months'];
		} else {
			timeScales = ['years', 'months', 'days'];
		}

		const cells = [];

		for (let j = 0; j < timeScales.length; j++) {
			cells.push(cellFactory(rowIndex, j, timeScales[j], this.props.items[rowIndex]));
		}

		return (
			<div key={rowIndex}
			     className="row"
			     style={
				     {
					     height: self.props.rowHeight,
				     }
			     }>
				{cells}
			</div>
		)
	};

	defaultCellFactory = (rowIndex, columnIndex, timeScale, data) => {

		const self = this;

		let text = "";

		let additionalClass = "";

		if (timeScale === 'years') {
			if (data) {

				text = data.start.getFullYear();

				if (rowIndex + 1 < self.props.items.length &&
					self.props.items[rowIndex + 1].start.getFullYear() !== data.start.getFullYear()) {
					additionalClass = " start";
				}
			}
		} else if (timeScale === 'months') {
			if (data) {

				let options = {
					month: 'short'
				};

				text = data.start.toLocaleDateString('en-US', options);

				if (rowIndex + 1 < self.props.items.length &&
					(
						self.props.items[rowIndex + 1].start.getMonth() !== data.start.getMonth() ||
						self.props.items[rowIndex + 1].start.getFullYear() !== data.start.getFullYear()
					)
				) {
					additionalClass = " start";
				}

				if (data.start.getDate() === 1) {
					additionalClass = " start";
				}
			}
		} else {
			if (data) {
				text = data.start.getDate();
				additionalClass = " start";
			}
		}

		if (this.props.timeScale === timeScale) {
			additionalClass += " active";
		}

		let key = rowIndex + "-" + timeScale;

		let className = "cell " + timeScale + additionalClass;

		this.cell_refs = {};

		return (
			<div key={key}
			     data-id={key}
			     ref={(element) => {
			     	if (element) {
			     		this.cell_refs[key] = element;
			        }
			     }}
			     className={className}
			>
				<div className={"cell-content"}>
					<label>
						{text}
					</label>
				</div>
			</div>
		)
	};
}

export default VerticalHeader;