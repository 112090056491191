import React from 'react';
import {Col, Glyphicon, Grid, Image, Modal, Row, Table} from 'react-bootstrap';
import HtmlParser from 'html-react-parser';

import Utils from '../../utils/utils';
import BaseComponent from '../../components/base/base-component';


function toText(field, fieldProp) {
    if (field) {
        if (fieldProp) {
            if (field[fieldProp]) {
                return field[fieldProp];
            }
        } else {
            return field;
        }
    }
    return "-";
}

class EventPreview extends BaseComponent {

    constructor(props) {

        super(props);

        this.state = {
            event: props.event,
            typeColor: "rgb(122, 186, 232)"
        };
    }

    render() {

        const self = this;

        const event = this.props.event;

        if (event) {

            const BODY_RE = /<body[^>]*>(.*)<\/body>/g;
            let description = "";
            if (event.description) {
                const matches = BODY_RE.exec(event.description);
                if (matches) {
                    description = matches[1];
                } else {
                    description = event.description;
                }
                description = `<div style="background-color: #eaf5fb;">${description}</div>`;
            }
            return (
                <Modal show={this.props.show} onHide={this.props.onHide} id="preview-modal"
                       className="preview-modal">
                    <Modal.Body id="preview-modal-body" className="body">
                        <div id="preview-modal-body-content" className="content">
                            <Grid bsClass="container-fluid c-grid">
                                <Row>
                                    <Col md={1}>
                                        <div className="color-label"
                                             style={{
                                                 backgroundColor: this.state.typeColor
                                             }}/>
                                    </Col>
                                    <Col md={11}>
                                        <label className="title-label">{toText(event.title)}</label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <div className="horizontal-separator"/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={5}>
                                        <Row bsClass="row c-row caption">
                                            <Col md={6}>
                                                <label className="caption-text">Factor:</label>
                                            </Col>
                                            <Col md={6}>
                                                <label className="caption-text">Key:</label>
                                            </Col>
                                        </Row>
                                        <Row bsClass="row c-row">
                                            <Col md={6}>
                                                <label className="value-text">{toText(event.factor, 'title')}</label>
                                            </Col>
                                            <Col md={6}>
                                                <label className="value-text">{toText(event.key)}</label>
                                            </Col>
                                        </Row>
                                        <Row bsClass="row c-row caption">
                                            <Col md={6}>
                                                <label className="caption-text">Category:</label>
                                            </Col>
                                            <Col md={6}>
                                                <label className="caption-text">Avg. probability:</label>
                                            </Col>
                                        </Row>
                                        <Row bsClass="row c-row">
                                            <Col md={6}>
                                                <label className="value-text">{toText(event.category, 'title')}</label>
                                            </Col>
                                            <Col md={6}>
                                                <label
                                                    className="value-text">{toText(event.average_probability)}</label>
                                            </Col>
                                        </Row>
                                        <Row bsClass="row c-row caption">
                                            <Col md={6}>
                                                <label className="caption-text">Quality (%):</label>
                                            </Col>
                                            <Col md={6}>
                                                <label className="caption-text">Impact:</label>
                                            </Col>
                                        </Row>
                                        <Row bsClass="row c-row">
                                            <Col md={6}>
                                                <label className="value-text">{toText(event.source_quality)}</label>
                                            </Col>
                                            <Col md={6}>
                                                <label className="value-text">{toText(event.average_impact)}</label>
                                            </Col>
                                        </Row>
                                        <Row bsClass="row c-row caption">
                                            <Col md={6}>
                                                <label className="caption-text">Source:</label>
                                            </Col>
                                            <Col md={6}>
                                                <label className="caption-text">Flag:</label>
                                            </Col>
                                        </Row>
                                        <Row bsClass="row c-row">
                                            <Col md={6}>
                                                <label className="value-text">{toText(event.source, 'title')}</label>
                                            </Col>
                                            <Col md={6}>
                                                {
                                                    event.flag && (
                                                        <Image src={Utils.getResourceUri(event.flag.path)} responsive/>
                                                    )
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={1}>
                                        <div className="vertical-separator"/>
                                    </Col>
                                    <Col md={5}>
                                        <Row bsClass="row c-row caption">
                                            <Col md={6}>
                                                <label className="caption-text">Author:</label>
                                            </Col>
                                        </Row>
                                        <Row bsClass="row c-row">
                                            <Col md={6}>
                                                <label
                                                    className="value-text">{toText(event.author, 'user_name')}</label>
                                            </Col>
                                        </Row>
                                        <Row bsClass="row c-row caption">
                                            <Col md={6}>
                                                <label className="caption-text">Date:</label>
                                            </Col>
                                        </Row>
                                        <Row bsClass="row c-row">
                                            <Col md={6}>
                                                <label
                                                    className="value-text">{Utils.convertDatetime(event.date, "d mmmm yyyy")}</label>
                                            </Col>
                                        </Row>
                                        <Row bsClass="row c-row caption">
                                            <Col md={6}>
                                                <label className="caption-text">Type:</label>
                                            </Col>
                                        </Row>
                                        <Row bsClass="row c-row">
                                            <Col md={6}>
                                                <label className="value-text">{toText(event.type, 'title')}</label>
                                            </Col>
                                        </Row>
                                        <Row bsClass="row c-row caption">
                                            <Col md={6}>
                                                <label className="caption-text">Keywords:</label>
                                            </Col>
                                        </Row>
                                        <Row bsClass="row c-row">
                                            <Col md={6}>
                                                <label className="value-text">{toText(event.keywords)}</label>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        {
                                            HtmlParser(description)
                                        }
                                    </Col>
                                </Row>
                                {
                                    event.items && event.items[0] && (
                                        <Row>
                                            <Col md={12}>
                                                <div className="horizontal-separator"/>
                                            </Col>
                                        </Row>
                                    )
                                }
                                {
                                    event.items && event.items[0] && (
                                        <Row>
                                            <Col md={12}>
                                                <Table>
                                                    <thead>
                                                    <tr>
                                                        <th>Link name</th>
                                                        <th>Link description</th>
                                                        <th>Link address</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        event.items.map(link => {
                                                            return (
                                                                <tr key={link.id}>
                                                                    <td>{link.name}</td>
                                                                    <td>{link.description}</td>
                                                                    <td>{link.url}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    )
                                }
                                {
                                    event.comments && event.comments[0] && (
                                        <Row>
                                            <Col md={12}>
                                                <div id="comments-glyph">
                                                    <Glyphicon glyph="envelope">{event.comments.length}</Glyphicon>
                                                </div>
                                                <div className="horizontal-separator comments"/>
                                            </Col>
                                        </Row>
                                    )
                                }
                                {
                                    event.comments && event.comments[0] && (
                                        <Row>
                                            <Col md={12}>
                                                <Table>
                                                    <thead>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>Comment</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        event.comments.map(comment => {
                                                            return (
                                                                <tr key={comment.id}>
                                                                    <td>{Utils.convertDatetime(comment.create_date, "d mmm yyyy")}</td>
                                                                    <td>{comment.text}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    )
                                }
                            </Grid>
                            <Grid className="trends" fluid={true}>
                                <Row className="h-100">
                                    <Col md={12} className="h-100">
                                        {
                                            this.props.trends && this.props.trends.map(function (element, index) {
                                                return (
                                                    <div className="trend"
                                                         key={element.id}
                                                         style={
                                                             {
                                                                 top: 0,
                                                                 right: (28 + index * 15) + 'px',   // 1 - ширина border
                                                                 backgroundColor: self.getTrendColor(element.color)
                                                             }
                                                         }>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Col>
                                </Row>
                            </Grid>
                        </div>
                    </Modal.Body>
                </Modal>
            )
        } else {
            return null;
        }
    }

    getTrendColor = (color) => {
        return `rgba(${color.red_addition}, ${color.green_addition}, ${color.blue_addition}, 0.4)`;
    };

}

export default EventPreview;
