import SockJS from 'sockjs-client';
import Stomp from 'stompjs';

class EventBusConnector {

	constructor() {
		this.connected = false;
		this.connecting = false;
	}

	connect = (headers, access_token, connectCallback, errorCallback) => {
		if (!this.connected && !this.connecting) {
			this.connecting = true;

			const socket = SockJS('/ws?access_token=' + access_token);
			this.stompClient = Stomp.over(socket);

			const connectHandler = () => {

				this.connected = true;
				this.connecting = false;

				if (connectCallback) {
					connectCallback();
				}
			};

			const errorHandler = (error) => {

				this.connected = false;
				this.connecting = false;

				console.error(error);

				if (errorCallback) {
					errorCallback(error);
				}
			};

			this.stompClient.connect(headers, connectHandler, errorHandler);
		}
	};

	subscribe = (topic, callback, headers = {}) => {
		if (this.connected) {
			this.stompClient.subscribe(topic, callback, headers);
		}
	}
}

export default EventBusConnector;