import React from "react";
import InfiniteContainer from "../../containers/infinite-container";
import GridCell from "./grid-cell";
import GridRow from "./grid-row";

class Grid
	extends React.PureComponent {

	constructor(props) {

		super(props);

		this.state = {
			cells: {},
			maxItems: 15
		};
	}

	componentDidMount() {

		const height = this.containerRef.clientHeight;

		const newMaxItems = Math.floor((height / this.props.rowHeight)) + 2;

		if (newMaxItems !== this.state.maxItems) {
			this.setState({
				              maxItems: Math.floor((height / this.props.rowHeight)) + 2
			              });
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		const height = this.containerRef.clientHeight;

		if (prevProps.rowHeight !== this.props.rowHeight) {

			const newMaxItems = Math.floor((height / this.props.rowHeight)) + 2;

			if (newMaxItems !== this.state.maxItems) {
				this.setState({
					              maxItems: Math.floor((height / this.props.rowHeight)) + 2
				              });
			}
		}

		if (prevProps.cellsState !== this.props.cellsState) {
			this.setState({
				              cells: this.props.cellsState
			              })
		}
	}

	render() {

		const rowFactory = this.props.rowFactory ? this.props.rowFactory : this.defaultRowFactory;

		if (!this.props.rowHeight || !this.props.columnWidth) {
			return <div className="fm-grid-scroll" ref={this.containerRefCallback}/>;
		}

		return (
			<div className="fm-grid-scroll"
			     ref={this.containerRefCallback}
			>
				{
					this.props.rowsCount > 0 &&
					<InfiniteContainer lazy={true}
					                   itemFactory={rowFactory}
					                   itemsCount={this.props.rowsCount}
					                   itemHeight={this.props.rowHeight}
					                   maxItemsToRender={this.state.maxItems}
					                   containerRef={this.props.containerRef}
					/>
				}
			</div>
		)

	}

	containerRefCallback = (element) => {

		this.containerRef = element;

		this.props.containerRef(element);
	};

	defaultRowFactory = (rowIndex) => {

		const self = this;

		const cells = [];

		const cellFactory = this.props.cellFactory ? this.props.cellFactory : this.defaultCellFactory;

		for (let j = 0; j < this.props.columnsCount; j++) {
			cells.push(cellFactory(rowIndex, j, this.props.itemDisposer(rowIndex, j), {
				width: self.props.columnWidth
			}))
		}

		return (
			<GridRow key={rowIndex}
			         rowIndex={rowIndex}
			         height={self.props.rowHeight}>
				{cells}
			</GridRow>
		)
	};

	defaultCellFactory = (rowIndex, columnIndex, item, options) => {
		return (
			<GridCell key={rowIndex + "-" + columnIndex}
			          width={options.width}
			          selected={this.isCellSelected(rowIndex, columnIndex)}
			          highlighted={this.isCellHighlighted(rowIndex, columnIndex)}
			          item={item}
			          rowIndex={rowIndex}
			          columnIndex={columnIndex}
			          onClick={this.handleCellClick}
			          onContextMenu={this.handleContextMenu}
			          onDoubleClick={this.handleCellDoubleClick}
			          controlFactory={this.props.cellControlFactory}
			          onDrop={this.props.onCellDrop}
			/>
		)
	};

	static cellKey = (rowIndex, columnIndex) => {
		return rowIndex + '-' + columnIndex;
	};

	static parseCellKey = (key) => {

		const coordinates = key.split('-');

		return {
			rowIndex: parseInt(coordinates[0]),
			columnIndex: parseInt(coordinates[1])
		}
	};

	isCellSelected = (rowIndex, columnIndex) => {
		return this.state.cells[Grid.cellKey(rowIndex, columnIndex)] ? this.state.cells[Grid.cellKey(rowIndex,
		                                                                                             columnIndex)].selected : false;
	};

	isCellHighlighted = (rowIndex, columnIndex) => {
		return this.state.cells[Grid.cellKey(rowIndex, columnIndex)] ? this.state.cells[Grid.cellKey(rowIndex,
		                                                                                             columnIndex)].highlighted : false;
	};

	handleContextMenu = (event) => {

		const rowIndex = parseInt(event.currentTarget.getAttribute('data-row-index'));

		const columnIndex = parseInt(event.currentTarget.getAttribute('data-column-index'));

		if (isNaN(rowIndex) || isNaN(columnIndex)) {
			return;
		}

		if (!this.isCellSelected(rowIndex, columnIndex)) {
			this.handleCellClick(event);
		}

		this.props.onContextMenu(event);
	};

	handleCellClick = (event) => {

		const self = this;

		const rowIndex = parseInt(event.currentTarget.getAttribute('data-row-index'));

		const columnIndex = parseInt(event.currentTarget.getAttribute('data-column-index'));

		if (isNaN(rowIndex) || isNaN(columnIndex)) {
			return;
		}

		const newCellsState = {
			...this.state.cells
		};

		const cellKey = Grid.cellKey(rowIndex, columnIndex);

		/*Object.keys(newCellsState)
		      .forEach(function (key) {
			      if (newCellsState[key] !== undefined && newCellsState[key].highlighted) {
				      newCellsState[key].highlighted = false;
				      self.rowInvalidationMap[Grid.parseCellKey(key).rowIndex] = false;
			      }
		      });*/

		if (event.ctrlKey || event.metaKey) {

			if (!newCellsState[cellKey] || newCellsState[cellKey] === undefined) {
				newCellsState[cellKey] = {};
			}

			newCellsState[cellKey].selected = true;
		} else if (event.shiftKey) {
			if (this.lastCellKey) {

				this.clearSelection(newCellsState);

				const lastCellCoordinate = Grid.parseCellKey(this.lastCellKey);

				const lastCellRowIndex = lastCellCoordinate.rowIndex;

				const lastCellColumnIndex = lastCellCoordinate.columnIndex;

				const rowFromIndex = lastCellRowIndex > rowIndex ? rowIndex : lastCellRowIndex;

				const rowToIndex = lastCellRowIndex > rowIndex ? lastCellRowIndex : rowIndex;

				const columnFromIndex = lastCellColumnIndex > columnIndex ? columnIndex : lastCellColumnIndex;

				const columnToIndex = lastCellColumnIndex > columnIndex ? lastCellColumnIndex : columnIndex;

				for (let i = rowFromIndex; i <= rowToIndex; i++) {
					for (let j = columnFromIndex; j <= columnToIndex; j++) {

						let key = Grid.cellKey(i, j);

						if (!newCellsState[key]) {
							newCellsState[key] = {};
						}

						newCellsState[Grid.cellKey(i, j)].selected = true;
					}
				}
			}
		} else {

			this.clearSelection(newCellsState);

			if (!newCellsState[cellKey] || newCellsState[cellKey] === undefined) {
				newCellsState[cellKey] = {};
			}

			newCellsState[cellKey].selected = true;

			this.lastCellKey = cellKey;
		}

		this.setState({
			              cells: {
				              ...newCellsState
			              }
		              },
		              () => {
			              if (this.props.onSelectionChange) {
				              this.props.onSelectionChange(this.state.cells);
			              }
		              });
	};

	clearSelection = (newCellsState) => {

		const self = this;

		Object.keys(newCellsState)
		      .forEach(function (key) {
			      if (newCellsState[key] !== undefined) {
				      delete newCellsState[key];
			      }
		      });
	};

	handleCellDoubleClick = (event) => {
		if (this.props.onDoubleClick) {

			const rowIndex = parseInt(event.currentTarget.getAttribute('data-row-index'));

			const columnIndex = parseInt(event.currentTarget.getAttribute('data-column-index'));

			if (isNaN(rowIndex) || isNaN(columnIndex)) {
				return;
			}

			this.props.onDoubleClick({
				                         rowIndex: rowIndex,
				                         columnIndex: columnIndex
			                         });
		}
	};
}

export default Grid