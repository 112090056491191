import React from 'react';
import {Combobox} from 'react-widgets';
import {Button, Col, ControlLabel, Form, FormGroup, Modal} from 'react-bootstrap';

import BaseForm from './base-form';


class SimpleSelectionForm
	extends BaseForm {

	constructor(props) {

		super(props);

		this.state = {
			items: this.props.items ? this.props.items : [],
			item: this.props.default ? this.props.default : null,
			field: this.props.field ? this.props.field : null
		};
	}

	render() {
		return (
			<Modal show={this.props.show} onHide={this.props.onHide}>
				<Modal.Header closeButton>
					<Modal.Title>Choose item</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form horizontal={true}>
						<FormGroup>
							<Col componentClass={ControlLabel} sm={2}>
								Select
							</Col>
							<Col sm={10}>
								<Combobox onChange={this.setValue.bind(this, 'item')}
								          defaultValue={this.state.item ? this.state.item : ''}
								          data={this.state.items}
								          caseSensitive={false}
								          textField={item => {
									          if (item) {
										          if (this.state.field) {
											          return item[this.state.field];
										          } else {
											          return typeof item === 'string' ? item : item.toString();
										          }
									          } else {
										          return "";
									          }
								          }}
								          value={this.state.item}/>
							</Col>
						</FormGroup>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button bsClass="btn action-button semi pull-right" onClick={this.props.onHide}>
						Cancel
					</Button>
					<Button bsClass="btn action-button semi pull-right" onClick={
						() => {
							this.props.onSave(this.state.item);
						}
					}>
						Save
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default SimpleSelectionForm;