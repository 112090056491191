const SERVER_URI_CONSTANTS = {
    WEB_AUTHENTICATE_URI: "web-authenticate",
    AUTHENTICATE: "authenticate",
    PROJECTS: "projects",
    FACTORS: "factors",
    PROJECT_CATEGORIES: "project-categories",
    FUTURE_MAPS: "future-maps",
    EVENTS: "events",
    EVENTS_RELATIONS: "event-relations",
    EVENT_CATEGORIES: "event-categories",
    EVENT_SOURCES: "event-sources",
    EVENT_TYPES: "event-types",
    NUMERIC_UNITS: "numeric-unit",
    USERS: "users",
    COLOR_SCHEMES: "color-scheme",
    FLAGS: "flags",
    ATTACHMENTS: "attachments",
    SAVE: "save",
    CREATE: "create",
    DOWNLOAD: "download",
    DELETE: "delete",
    LINKS: "links",
    COMMENTS: "comments",
    ENTITY_PROPERTIES: "entity-properties",
    FILTERS: "filters",
    FUTURE_MAP_FILTERS: "events-map-filters",
    SCENARIOS: "scenarios",
    EVENT_BUS_DATA: "event-bus-data",
    SERVER_TIME: "server-time",
    INCREMENT_VIEWS: "event-views-count-inc",
    RETROSPECTION: "retrospection",
    LOAD: "load",
    COPY: "copy",
    LOGIN_ATTEMPTS: "login_attempts",
    JOURNAL_ENTRIES: "journal-entries",
    TRENDS: "trends",
    UPDATE_INDEX: "update-index",
    CHANGE_PASSWORD: "change-password",
    ROLE: "roles",
    SET_ROLE: "set-role",
    IMAGES: "images",
    ROLES: "roles",
    LOCK: "lock",
    UNLOCK: "unlock",
    ENTITY_ROLES: "entity-roles",
    ADD: "add",
    REMOVE: "remove",
    NOTIFICATIONS: "notifications",
    AMQP_SETTINGS: "amqp_settings",
    COMPACT: "compact",
    UPDATE: "update",
    UPDATE_AFTER_CHANGE: "update_after_change",
    CHECK: "check",
    CALCULATE: "calculate",
    RECOVERY_PASSWORD: "recovery_password",
    RECOVERY_PASSWORD_ACCEPT: "recovery_password_accept",
    CHAIN: "chain",
    NOTIFICATION: "notification",
    IMPORT: "import",
    EXPORT: "export",
};

const CLIENT_URI_CONSTANTS = {
    LOGIN: "login",
    HOME: "home",
    LOGOUT: "log_out",
    PROJECT: "project",
    INFO: "info",
    FACTORS_MAP: "factors-map",
    FUTURE_MAP: "events-map",
    FACTOR: "factor",
    ADD: "add",
    EDIT: "edit",
    USER: "user",
    USERS: "users",
    EVENT: "event",
    FILTER: "filter",
    FILTERS: "filters",
    FILTERED_FUTURE_MAP: "filtered-events-map",
    REVIEW: "review",
    ROLES: 'roles',
    SCENARIO: "scenario",
    SCENARIOS: "scenarios",
    SCENARIO_MAP: "scenario-map",
    TREND: "trend",
    TRENDS: "trends",
    RELATIONS_MAP: "relations-map",
    RELATION: "relation",
    EVENT_SOURCES: "event-sources",
    EVENT_CATEGORIES: "event-categories",
    EVENT_TYPES: "event-types",
    NUMERIC_UNITS: "numeric-units",
    PROJECT_CATEGORIES: "project-categories",
    CHANGE_PASSWORD: "change-password",
    COLOR_SCHEME: "color-scheme"
};

export { SERVER_URI_CONSTANTS, CLIENT_URI_CONSTANTS };