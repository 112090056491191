import React from "react";
import Utils from "../../utils/utils";

const DATE_FORMAT = "yyyy-mm-dd";

class TrendItem extends React.Component {

    constructor(props) {
        super(props);

        this.onItemClick = this.props.onItemClick;
        this.onItemDoubleClick = this.props.onItemDoubleClick;
        this.isSelected = this.props.isSelected;
    }

    render() {
        const item = this.props.item;
        const cRed = item.color.red_addition;
        const cGreen = item.color.green_addition;
        const cBlue = item.color.blue_addition;
        return (
            <tr onClick={(param) => Utils.singleDoubleClick(
                param.target,
                item,
                this.onItemClick,
                this.onItemDoubleClick,
                param.ctrlKey,
                200)
            }
                className={`clickable ${this.isSelected(item) ? 'selected' : ''}`}
            >
                <td> {item.factor.title} </td>
                <td> {item.name} </td>
                <td> {item.description ? Utils.getPlainTextFromHtml(item.description) : ""} </td>
                <td> {Utils.convertDatetime(item.start, DATE_FORMAT)} </td>
                <td> {Utils.convertDatetime(item.end, DATE_FORMAT)} </td>
                <td>
                    <div>
                        <label style={{
                            borderWidth: "1px",
                            borderColor: "black",
                            backgroundColor: `rgb(${cRed}, ${cGreen}, ${cBlue})`,
                            height: "30px",
                            width: "30px",
                            opacity: "0.85",
                            float: "left",
                            marginLeft: "30px"
                        }}/>
                        <div style={{
                            float: "left",
                            marginLeft: "10px"
                        }}>
                            {Utils.rgbToHex(cRed, cGreen, cBlue)}
                        </div>
                    </div>
                </td>
            </tr>
        );
    }
}

export default TrendItem;