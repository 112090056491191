import React from 'react';
import ReactSummernote from 'react-summernote';

import 'react-summernote/lang/summernote-ru-RU'; // you can import any other locale
import BaseForm from "./base-form";


class Summernote extends BaseForm {

    render() {
        return (
            <ReactSummernote
                value={this.props.value}
                options={{
                    lang: 'ru-RU',
                    height: this.props.height ? this.props.height : 250,
                    dialogsInBody: true,
                    toolbar: [
                        ['undoredo', ['undo', 'redo']],
                        ['style', ['style']],
                        ['font', ['bold', 'underline', 'clear']],
                        ['fontname', ['fontname', 'fontsize']],
                        ['color', ['color']],
                        ['para', ['ul', 'ol', 'paragraph']],
                        ['table', ['table']],
                        ['insert', ['link', 'picture', 'video']],
                        ['view', ['fullscreen']]
                    ]
                }}
                onChange={this.props.onChange}
            />
        )
    }
}

export default Summernote;