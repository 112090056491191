import {projectColorSchemeService} from "../services";

export const actionTypes = {
	REQUEST_SCHEMA: 'REQUEST_SCHEMA',
	RECEIVE_SCHEMA: 'RECEIVE_SCHEMA',
};

export const fetchColorSchema = (projectId) => {
	return (dispatch, getState) => {
		if (!getState().colorSchemaReducer.isPending) {
			dispatch(requestColorSchema());
			projectColorSchemeService.getColorSchemeByProject(projectId)
			                         .then(color_scheme_proxy => {
				                         dispatch(receiveColorSchema(color_scheme_proxy.data));
			                         })
		}
	}
};

export const requestColorSchema = () => {
	return {
		type: actionTypes.REQUEST_SCHEMA
	}
};

export const receiveColorSchema = (response) => {
	return {
		type: actionTypes.RECEIVE_SCHEMA,
		data: response
	}
};

export function saveColorScheme(projectId, scheme) {
	return (dispatch, getState) => {
		projectColorSchemeService.saveColorScheme(projectId, scheme)
		                         .then(color_scheme_proxy => {
			                         dispatch(receiveColorSchema(color_scheme_proxy.data));
		                         });
	}
}