export const actionTypes = {

    prefix: 'OPERATION',

    RECEIVE_REPORT: 'RECEIVE_REPORT',

    get: function (key) {
        return this.prefix + '_' + this[key];
    }
};

export const receiveReport = (report) => {
    return {
        type: actionTypes.get(actionTypes.RECEIVE_REPORT),
        data: report
    }
};