import {connect} from "react-redux";
import {withRouter} from "react-router";
import * as projectsActions from "../actions/projects";
import {receiveProject, requestProject} from "../actions/projects";
import ProjectInfoView from "../views/project-info";
import * as usersActions from "../actions/users";
import * as eventSourcesActions from "../actions/event-sources";
import * as eventCategoriesActions from "../actions/event-categories";
import * as eventTypesActions from "../actions/event-types";
import {authorities} from "../mixins/authorities";
import {projectService} from "../services";
import {browserHistory} from "../app";
import {CLIENT_URI_CONSTANTS as URI} from "../utils/uri-constants";
import * as orphans_actions from "../actions/orphans";

const mapStateToProps = (state, ownProps) => {
	return {
		project: state.projectReducer.project,
		authentication: state.authenticationReducer.authentication,
		authorities: state.authoritiesReducer.authorities,
		selectedLink: state.projectReducer.project ? state.projectReducer.project.selectedLink : null,
		link: state.projectReducer.project ? state.projectReducer.project.link : null,
		selectedAttachment: state.projectReducer.project ? state.projectReducer.project.selectedAttachment : null,
		attachment: state.projectReducer.project ? state.projectReducer.project.attachment : null,
		selectedUserAuthority: state.projectReducer.project ? state.projectReducer.project.selectedUserAuthority : null,
		userAuthority: state.projectReducer.project ? state.projectReducer.project.userAuthority : null,
		users: state.usersReducer.users,
		journalEntries: state.projectReducer.project ? (state.projectReducer.project.journal_entries ? state.projectReducer.project.journal_entries : null) : null,
		selectedJournalEntry: state.projectReducer.project ? (state.projectReducer.project.selected_journal_entry ? state.projectReducer.project.selected_journal_entry : null) : null,
		projectEventTypes: state.eventTypesReducer.projectEventTypes,
		eventTypes: state.eventTypesReducer.eventTypes,
		projectEventCategories: state.eventCategoriesReducer.projectEventCategories,
		eventCategories: state.eventCategoriesReducer.eventCategories,
		projectEventSources: state.eventSourcesReducer.projectEventSources,
		eventSources: state.eventSourcesReducer.eventSources,
		orphans: state.orphans.items,
		selectedOrphan: state.orphans.selectedItem,
		projectOperation: state.projectReducer.operation,
		...authorities(state.authenticationReducer.authentication)
	}
};

function createEventCategoriesActionChain(event_categories) {
	return (dispatch, getState) => {

		let project = getState().projectReducer.project;

		projectService.addEventCategories(project.id, event_categories)
		              .then(result => {
			              dispatch(orphans_actions.receiveItems(null));
			              dispatch(eventCategoriesActions.receiveProjectEventCategories(result.data))
		              })
		              .catch(error => {
			              if (error.response.status === 409) {
				              dispatch(orphans_actions.receiveItems(error.response.data));
			              }
		              });
	};
}

function createEventTypesActionChain(event_types) {
	return (dispatch, getState) => {

		let project = getState().projectReducer.project;

		projectService.addEventTypes(project.id, event_types)
		              .then(result => {
			              dispatch(orphans_actions.receiveItems(null));
			              dispatch(eventTypesActions.receiveProjectEventTypes(result.data))
		              })
		              .catch(error => {
			              if (error.response.status === 409) {
				              dispatch(orphans_actions.receiveItems(error.response.data));
			              }
		              });
	};
}

function createEventSourcesActionChain(event_sources) {
	return (dispatch, getState) => {

		let project = getState().projectReducer.project;

		projectService.addEventSources(project.id, event_sources)
		              .then(result => {
			              dispatch(orphans_actions.receiveItems(null));
			              dispatch(eventSourcesActions.receiveProjectEventSources(result.data))
		              })
		              .catch(error => {
			              if (error.response.status === 409) {
				              dispatch(orphans_actions.receiveItems(error.response.data));
			              }
		              });
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		loadInitialData: () => {
			dispatch(loadInitialData())

		},
		createProject: (project) => {
			dispatch(requestProject());
			projectService.create(project)
			              .then(project_proxy => {
				              dispatch(receiveProject(project_proxy.data));
				              dispatch(projectsActions.endOperation({
					                                                    name: 'CREATION',
					                                                    status: "SUCCESS"
				                                                    }));
				              browserHistory.push(`/${URI.PROJECT}/${project_proxy.data.id}/${URI.INFO}`)
			              })
			              .catch((error) => {
				              dispatch(projectsActions.endOperation({
					                                                    name: 'CREATION',
					                                                    status: "ERROR"
				                                                    }));
			              });
		},
		saveProject: (project) => {
			if (project.id) {
				dispatch(projectsActions.saveProject(project));
			}
		},
		loadUsers: () => {
			dispatch(usersActions.fetchUsers());
		},
		loadAuthorities: () => {
			// dispatch(authoritiesActions.fetchAuthorities());
		},
		selectLink: (link) => {
			dispatch(projectsActions.selectLink(link));
		},
		editLink: (project, link) => {
			dispatch(projectsActions.fetchLink(project.id, link.id));
		},
		saveLink: (project, link) => {
			if (link.id) {
				dispatch(projectsActions.saveLink(project.id, link));
			} else {
				link.project = {
					id: project.id,
					retrospectionId: project.retrospectionId
				};
				dispatch(projectsActions.createLink(project.id, link));
			}
		},
		deleteLink: (project, link) => {
			dispatch(projectsActions.deleteLink(project.id, link.id));
		},
		clearLink: () => {
			dispatch(projectsActions.receiveLink(null));
		},
		selectAttachment: (attachment) => {
			dispatch(projectsActions.selectAttachment(attachment));
		},
		editAttachment: (project, attachment) => {
			dispatch(projectsActions.fetchAttachment(project.id, attachment.id));
		},
		saveAttachment: (project, attachment) => {
			if (attachment.id) {
				dispatch(projectsActions.saveAttachment(project.id, attachment));
			} else {
				attachment.project = {
					id: project.id,
					retrospectionId: project.retrospectionId
				};
				dispatch(projectsActions.createAttachment(project.id, attachment));
			}
		},
		deleteAttachment: (project, attachment) => {
			dispatch(projectsActions.deleteAttachment(project.id, attachment.id));
		},
		clearAttachment: () => {
			dispatch(projectsActions.receiveAttachment(null));
		},
		selectUserAuthority: (authority) => {
			dispatch(projectsActions.selectUserAuthority(authority));
		},
		editUserAuthority: (project, authority) => {
			dispatch(projectsActions.editUserAuthority(project.id, authority.id));
		},
		clearAuthority: () => {
			dispatch(projectsActions.receiveUserAuthority(null));
		},
		saveAuthority: (project, authority) => {
			if (authority.id) {
				dispatch(projectsActions.saveUserAuthority(project.id, authority));
			} else {
				authority.entity_id = project.id;
				authority.entity_type = 'ru.fenix.futuremap.server.model.project.Project';
				dispatch(projectsActions.createUserAuthority(project.id, authority));
			}
		},
		deleteAuthority: (project, authority) => {
			dispatch(projectsActions.deleteUserAuthority(project.id, authority.id));
		},
		loadJournalEntries: (project) => {
			dispatch(projectsActions.fetchJournalEntries(project.id));
		},
		selectJournalEntry: (entry) => {
			dispatch(projectsActions.selectJournalEntry(entry));
		},
		loadEventSources: () => {
			dispatch(eventSourcesActions.fetchEventSources());
		},
		createEventSources: (event_sources) => {
			dispatch(createEventSourcesActionChain(event_sources));
		},
		loadEventTypes: () => {
			dispatch(eventTypesActions.fetchEventTypes());
		},
		createEventTypes: (event_types) => {
			dispatch(createEventTypesActionChain(event_types));
		},
		loadEventCategories: () => {
			dispatch(eventCategoriesActions.fetchEventCategories());
		},
		createEventCategories: (event_categories) => {
			dispatch(createEventCategoriesActionChain(event_categories));
		},
		selectOrphan: (item) => {
			dispatch(orphans_actions.selectItem(item))
		},
		clearOrphans: () => {
			dispatch(orphans_actions.receiveItems(null));
		},
		loadOrphanItemInfo: (type, path) => {

		},
		clearOperation: () => {
			dispatch(projectsActions.startOperation(null));
		},
	}
};

const loadInitialData = () => {
	return (dispatch, getState) => {

		let project = getState().projectReducer.project;

		dispatch(projectsActions.fetchJournalEntries(project.id));
	}
}

const ProjectInfoContainer = connect(mapStateToProps, mapDispatchToProps)(ProjectInfoView);

export default withRouter(ProjectInfoContainer)