import {connect} from "react-redux";
import {withRouter} from "react-router";
import * as usersActions from "../actions/users";
import UserChangePasswordView from "../views/user-change-password";

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.usersReducer.user
    }
};

const mapDispatchToProps = (dispatch, state) => {
    return {
        loadUser: (id) => {
            dispatch(usersActions.fetchUser(id));
        },
        changePassword: (user, password) => {
            dispatch(usersActions.changePassword(user.id, password));
        }
    }
};

const UserChangePasswordContainer = connect(mapStateToProps, mapDispatchToProps)(UserChangePasswordView);

export default withRouter(UserChangePasswordContainer)