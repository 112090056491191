import clientFactory from "../factories/http_client";
import {API} from "../api/api";

class EventAttachmentService {

	/** @return  Promise<AxiosResponse<T>> */
	create = (projectId, futureMapId, eventId, attachment) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.events.id.attachments.create.method,
			                             url: API.data.projects.id.event_maps.id.events.id.attachments.create.url(projectId, futureMapId, eventId),
			                             data: API.data.projects.id.event_maps.id.events.id.attachments.create.build(attachment),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	save = (projectId, futureMapId, eventId, attachment) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.events.id.attachments.save.method,
			                             url: API.data.projects.id.event_maps.id.events.id.attachments.save.url(projectId, futureMapId, eventId),
			                             data: API.data.projects.id.event_maps.id.events.id.attachments.save.build(attachment),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	delete = (projectId, futureMapId, eventId, attachmentId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.events.id.attachments.delete.method,
			                             url: API.data.projects.id.event_maps.id.events.id.attachments.delete.url(projectId, futureMapId, eventId),
			                             data: API.data.projects.id.event_maps.id.events.id.attachments.delete.build(attachmentId),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	get = (projectId, futureMapId, eventId, attachmentId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.events.id.attachments.id.method,
			                             url: API.data.projects.id.event_maps.id.events.id.attachments.id.url(projectId, futureMapId, eventId, attachmentId),
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	createImage = (projectId, futureMapId, eventId, imageAttachment) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.events.id.images.create.method,
			                             url: API.data.projects.id.event_maps.id.events.id.images.create.url(projectId, futureMapId, eventId),
			                             data: API.data.projects.id.event_maps.id.events.id.images.create.build(imageAttachment),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	deleteImage = (projectId, futureMapId, eventId, imageAttachmentId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.events.id.images.delete.method,
			                             url: API.data.projects.id.event_maps.id.events.id.images.delete.url(projectId, futureMapId, eventId),
			                             data: API.data.projects.id.event_maps.id.events.id.images.delete.build(imageAttachmentId),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};
}

export default EventAttachmentService;