import {actionTypes} from "../actions/projects";

const initialState = {
	isPending: false,
	invalidated: true,
	projects: null,
	filter: {},
	selectedProject: null,
	project: null,
	operation: null
};

function projectReducer(state = initialState, action) {

	let newState = null;

	switch (action.type) {
		case actionTypes.get(actionTypes.REQUEST_PROJECTS):
			return {
				...state,
				filter: action.data,
				isPending: true
			};
		case actionTypes.get(actionTypes.RECEIVE_PROJECTS):
			return {
				...state,
				projects: action.data,
				project: null,
				isPending: false
			};
		case actionTypes.get(actionTypes.SELECT_PROJECT):
			return {
				...state,
				selectedProject: action.data
			};
		case actionTypes.get(actionTypes.REQUEST_PROJECT):
			return {
				...state,
				isPending: true
			};
		case actionTypes.get(actionTypes.RECEIVE_PROJECT):
			if (action.data) {
				return {
					...state,
					project: {
						...action.data,
						eventsMap: action.data.future_maps && action.data.future_maps.length !== 0 ? action.data.future_maps[0] : {},
						future_maps: undefined,
						start_date: new Date(action.data.start_date),
						end_date: new Date(action.data.end_date)
					},
					isPending: false
				};
			} else {
				return {
					...state,
					project: null,
					isPending: false
				}
			}
		case actionTypes.get(actionTypes.RECEIVE_LINK):

			newState = {
				...state
			};
			newState.project.link = action.data;

			return newState;
		case actionTypes.get(actionTypes.SELECT_LINK):

			newState = {
				...state
			};
			newState.project.selectedLink = action.data;

			return newState;
		case actionTypes.get(actionTypes.RECEIVE_ATTACHMENT):

			newState = {
				...state
			};
			newState.project.attachment = action.data;

			return newState;
		case actionTypes.get(actionTypes.SELECT_ATTACHMENT):

			newState = {
				...state
			};
			newState.project.selectedAttachment = action.data;

			return newState;
		case actionTypes.get(actionTypes.RECEIVE_USER_AUTHORITY):

			newState = {
				...state
			};
			newState.project.userAuthority = action.data;

			return newState;
		case actionTypes.get(actionTypes.SELECT_USER_AUTHORITY):

			newState = {
				...state
			};
			newState.project.selectedUserAuthority = action.data;

			return newState;
		case actionTypes.get(actionTypes.REQUEST_JOURNAL_ENTRIES):
			return {
				...state,
				isJournalEntriesPending: true
			};
		case actionTypes.get(actionTypes.RECEIVE_JOURNAL_ENTRIES):

			newState = {
				...state,
				isJournalEntriesPending: false
			};

			newState.project.journal_entries = action.data;

			return newState;
		case actionTypes.get(actionTypes.SELECT_JOURNAL_ENTRY):

			newState = {
				...state
			};

			newState.project.selected_journal_entry = action.data;

			return newState;
		case actionTypes.get(actionTypes.RECEIVE_FILTER):

			newState = {
				...state,
				filter: action.data
			};

			return newState;
		case actionTypes.get(actionTypes.START_OPERATION):
			return {
				...state,
				operation: action.data
			};
		case actionTypes.get(actionTypes.END_OPERATION):
			return {
				...state,
				operation: action.data
			};
		default:
			return state
	}
}

export default projectReducer