import {eventCategoryService, projectService} from "../services";

export const actionTypes = {

	prefix: 'EVENT_CATEGORY',

	REQUEST_EVENT_CATEGORIES: 'REQUEST_EVENT_CATEGORIES',
	RECEIVE_EVENT_CATEGORIES: 'RECEIVE_EVENT_CATEGORIES',
	REQUEST_PROJECT_EVENT_CATEGORIES: 'REQUEST_PROJECT_EVENT_CATEGORIES',
	RECEIVE_PROJECT_EVENT_CATEGORIES: 'RECEIVE_PROJECT_EVENT_CATEGORIES',
	SELECT_EVENT_CATEGORY: 'SELECT_EVENT_CATEGORY',
	REQUEST_EVENT_CATEGORY: 'REQUEST_EVENT_CATEGORY',
	RECEIVE_EVENT_CATEGORY: 'RECEIVE_EVENT_CATEGORY',
	APPEND_ITEM: 'APPEND_ITEM',
	INSERT_ITEM: 'INSERT_ITEM',
	START_OPERATION: 'START_OPERATION',
	END_OPERATION: 'END_OPERATION',

	get: function (key) {
		return this.prefix + '_' + this[key];
	}
};

export const fetchEventCategories = () => {
	return (dispatch, getState) => {
		if (!getState().eventCategoriesReducer.isPending) {
			dispatch(requestEventCategories());
			eventCategoryService.getAll()
			                    .then(categories_proxy => {
				                    dispatch(receiveEventCategories(categories_proxy.data));
			                    })
		}
	}
};

export const requestEventCategories = () => {
	return {
		type: actionTypes.get(actionTypes.REQUEST_EVENT_CATEGORIES)
	}
};

export const receiveEventCategories = (response) => {
	return {
		type: actionTypes.get(actionTypes.RECEIVE_EVENT_CATEGORIES),
		data: response
	}
};

export const fetchProjectEventCategories = (projectId) => {
	return (dispatch, getState) => {
		dispatch(requestProjectEventCategories());
		projectService.getEventCategories(projectId)
		              .then(categories_proxy => {
			              dispatch(receiveProjectEventCategories(categories_proxy.data));
		              })
	}
};

export const requestProjectEventCategories = () => {
	return {
		type: actionTypes.get(actionTypes.REQUEST_PROJECT_EVENT_CATEGORIES)
	}
};

export const receiveProjectEventCategories = (response) => {
	return {
		type: actionTypes.get(actionTypes.RECEIVE_PROJECT_EVENT_CATEGORIES),
		data: response
	}
};

export const selectEventCategory = (eventCategory) => {
	return {
		type: actionTypes.get(actionTypes.SELECT_EVENT_CATEGORY),
		data: eventCategory
	}
};

export const fetchEventCategory = (id) => {
	return (dispatch, getState) => {
		if (!getState().eventCategoriesReducer.isPending) {
			dispatch(requestEventCategory());
			eventCategoryService.getById(id)
			                    .then(category_proxy => {
				                    dispatch(receiveEventCategory(category_proxy.data));
			                    })
		}
	}
};

export const requestEventCategory = () => {
	return {
		type: actionTypes.get(actionTypes.REQUEST_EVENT_CATEGORY)
	}
};

export const receiveEventCategory = (response) => {
	return {
		type: actionTypes.get(actionTypes.RECEIVE_EVENT_CATEGORY),
		data: response
	}
};

export const appendEventCategory = (data) => {
	return {
		type: actionTypes.get(actionTypes.APPEND_ITEM),
		data: data
	}
};

export const insertEventCategory = (data) => {
	return {
		type: actionTypes.get(actionTypes.INSERT_ITEM),
		data: data
	}
};

export const deleteEventCategory = (id) => {
	return (dispatch, getState) => {
		eventCategoryService.delete(id)
		                    .then(category_proxy => {
			                    dispatch(fetchEventCategories());
		                    })
	}
};

export const startOperation = (operation) => {
	return {
		type: actionTypes.get(actionTypes.START_OPERATION),
		data: operation
	}
};

export const endOperation = (operation) => {
	return {
		type: actionTypes.get(actionTypes.END_OPERATION),
		data: operation
	}
};