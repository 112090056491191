import {connect} from "react-redux";
import {withRouter} from "react-router";
import {browserHistory} from '../app';
import * as factorsActions from "../actions/factors";
import {CLIENT_URI_CONSTANTS as URI} from '../utils/uri-constants';
import FactorsMapView from "../views/project-factors-map";
import {authorities} from "../mixins/authorities";
import * as clipboardActions from "../actions/clipboard";
import * as flagsActions from "../actions/flags";

const mapStateToProps = (state, ownProps) => {
    return {
        project: state.projectReducer.project,
        factors: state.factorReducer.factors,
        selectedFactor: state.factorReducer.selectedFactor,
        clipboardContent: state.clipboardReducer.data ? (state.clipboardReducer.data.type === 'factor' ? state.clipboardReducer.data : null) : null,
        factor: state.factorReducer.factor,
        selectedLink: state.factorReducer.factor ? state.factorReducer.factor.selectedLink : null,
        link: state.factorReducer.factor ? state.factorReducer.factor.link : null,
        selectedAttachment: state.factorReducer.factor ? state.factorReducer.factor.selectedAttachment : null,
        attachment: state.factorReducer.factor ? state.factorReducer.factor.attachment : null,
        flags: state.flagsReducer.flags,
        journalEntries: state.factorReducer.factor ? (state.factorReducer.factor.journal_entries ? state.factorReducer.factor.journal_entries : null) : null,
        selectedJournalEntry: state.factorReducer.factor ? (state.factorReducer.factor.selected_journal_entry ? state.factorReducer.factor.selected_journal_entry : null) : null,
        factorOperation: state.factorReducer.operation,
        ...authorities(state.authenticationReducer.authentication)
    }
};

function loadInitialDataActionChain() {
    return (dispatch, getState) => {

        let project = getState().projectReducer.project;

        dispatch(factorsActions.fetchFactors(project.id, project.eventsMap.id));
    };
}

const mapDispatchToProps = (dispatch, state) => {
    return {
        loadInitialData: () => {
          dispatch(loadInitialDataActionChain())
        },
        selectFactor: (factor) => {
            dispatch(factorsActions.selectFactor(factor));
        },
        deleteFactor: (project, factor) => {
            dispatch(factorsActions.deleteFactor(project.id, project.eventsMap.id, factor.id));
        },
        addFactor: (project) => {
            dispatch(factorsActions.addFactor(project));
        },
        editFactor: (project, factor) => {
            dispatch(factorsActions.receiveFactor(null));
            browserHistory.push(`/${URI.PROJECT}/${project.id}/factors-map/?id=${factor.id}#edit-factor`);
        },
        copyFactor: (project, factor, withEvents) => {
            dispatch(clipboardActions.store({
                type: 'factor',
                projectId: project.id,
                eventsMapId: project.eventsMap.id,
                factorId: factor.id,
                withEvents: withEvents
            }));
        },
        insertFactor: (clipboardContent, parentFactor) => {
            dispatch(factorsActions.copyFactor(clipboardContent.projectId, clipboardContent.eventsMapId, clipboardContent.factorId, clipboardContent.withEvents, (parentFactor ? parentFactor.id : null)));
        },
        loadFactor: (project, id) => {
            dispatch(factorsActions.fetchFactor(project.id, project.eventsMap.id, id))
        },
        saveFactor: (project, factor) => {
            if (!factor.id) {
                factor.future_map = project.eventsMap;
                dispatch(factorsActions.createFactor(project.id, project.eventsMap.id, factor));
            } else {
                factor.future_map = project.eventsMap;
                dispatch(factorsActions.saveFactor(project.id, project.eventsMap.id, factor));
            }
        },
        selectLink: (link) => {
            dispatch(factorsActions.selectLink(link));
        },
        editLink: (project, factor, link) => {
            dispatch(factorsActions.fetchLink(project.id, project.eventsMap.id, factor.id, link.id));
        },
        saveLink: (project, factor, link) => {
            if (link.id) {
                dispatch(factorsActions.saveLink(project.id, project.eventsMap.id, factor.id, link));
            } else {
                link.factor = {
                    id: factor.id,
                    retrospectionId: factor.retrospectionId
                };
                dispatch(factorsActions.createLink(project.id, project.eventsMap.id, factor.id, link));
            }
        },
        deleteLink: (project, factor, link) => {
            dispatch(factorsActions.deleteLink(project.id, project.eventsMap.id, factor.id, link.id));
        },
        clearLink: () => {
            dispatch(factorsActions.receiveLink(null));
        },
        selectAttachment: (attachment) => {
            dispatch(factorsActions.selectAttachment(attachment));
        },
        editAttachment: (project, factor, attachment) => {
            dispatch(factorsActions.fetchAttachment(project.id, project.eventsMap.id, factor.id, attachment.id));
        },
        saveAttachment: (project, factor, attachment) => {
            if (attachment.id) {
                dispatch(factorsActions.saveAttachment(project.id, project.eventsMap.id, factor.id, attachment));
            } else {
                attachment.factor = {
                    id: factor.id,
                    retrospectionId: factor.retrospectionId
                };
                dispatch(factorsActions.createAttachment(project.id, project.eventsMap.id, factor.id, attachment));
            }
        },
        deleteAttachment: (project, factor, attachment) => {
            dispatch(factorsActions.deleteAttachment(project.id, project.eventsMap.id, factor.id, attachment.id));
        },
        clearAttachment: () => {
            dispatch(factorsActions.receiveAttachment(null));
        },
        loadFlags: () => {
            dispatch(flagsActions.fetchFlags());
        },
        loadJournalEntries: (project, factor) => {
            dispatch(factorsActions.fetchJournalEntries(project.id, project.eventsMap.id, factor.id));
        },
        selectJournalEntry: (entry) => {
            dispatch(factorsActions.selectJournalEntry(entry));
        },
        clearOperation: () => {
            dispatch(factorsActions.startOperation(null));
        }
    }
};

const FactorsMapContainer = connect(mapStateToProps, mapDispatchToProps)(FactorsMapView);

export default withRouter(FactorsMapContainer)