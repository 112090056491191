import {actionTypes} from "../actions/event-categories";

const initialState = {
	isPending: false,
	invalidated: false,
	eventCategories: null,
	eventCategory: null,
	selectedEventCategory: null,
	projectEventCategories: null,
	projectEventCategory: null,
	projectSelectedEventCategory: null,
	operation: null
};

function eventCategoriesReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.get(actionTypes.REQUEST_EVENT_CATEGORIES):
			return {
				...state,
				isPending: true
			};
		case actionTypes.get(actionTypes.REQUEST_PROJECT_EVENT_CATEGORIES):
			return {
				...state,
				isPending: true
			};
		case actionTypes.get(actionTypes.RECEIVE_EVENT_CATEGORIES):
			return {
				...state,
				isPending: false,
				eventCategories: action.data
			};
		case actionTypes.get(actionTypes.RECEIVE_PROJECT_EVENT_CATEGORIES):
			return {
				...state,
				isPending: false,
				projectEventCategories: action.data
			};
		case actionTypes.get(actionTypes.SELECT_EVENT_CATEGORY):
			return {
				...state,
				selectedEventCategory: action.data,
			};
		case actionTypes.get(actionTypes.REQUEST_EVENT_CATEGORY):
			return {
				...state,
				isPending: true
			};
		case actionTypes.get(actionTypes.RECEIVE_EVENT_CATEGORY):
			return {
				...state,
				isPending: false,
				eventCategory: action.data
			};
		case actionTypes.get(actionTypes.APPEND_ITEM):

			let eventCategories = state.eventCategories;

			return {
				...state,
				isPending: false,
				eventCategories: eventCategories && Array.isArray(eventCategories) ? eventCategories.concat([action.data]) : [].concat([action.data])
			};
		case actionTypes.get(actionTypes.INSERT_ITEM):

			let index = state.eventCategories.findIndex((item) => {
				return item.id === action.data.id;
			});

			if (index !== -1) {
				state.eventCategories.splice(index, 1, action.data)
			} else {
				state.eventCategories.push(action.data);
			}

			return {
				...state,
				isPending: false,
				eventCategories: [...state.eventCategories]
			};
		case actionTypes.get(actionTypes.START_OPERATION):
			return {
				...state,
				operation: action.data
			};
		case actionTypes.get(actionTypes.END_OPERATION):
			return {
				...state,
				operation: action.data
			};
		default:
			return state
	}
}

export default eventCategoriesReducer