const BOUNDS = {
    COL_HEADER_HEIGHT: 40,
    CELL_WIDTH: 180,
    CELL_HEIGHT: 90,
    VERTICAL_HEADER_WIDTH: 50,
    PAGE_HEIGHT: 800,
    PAGE_WIDTH: 1600,
    SCROLL_BAR_WIDTH: 17,
    VERTICAL_HEADER_SWITCHER_HEIGHT: 130,
    VERTICAL_HEADER_SWITCHER_WIDTH: 150,
    ITEMS_COUNT_WIDTH: 20
};

const REFS = {
    CONTEXT_MENU_ID: "grid-cell-context-menu",
    EVENT_PREVIEW_REF: "event-preview-ref",
    EVENTS_LIST_MODAL_REF: "events-list-modal-ref",
    LAZY_GRID_REF: "compact-grid-ref",
    GRID_SCROLL_PANE_REF: "grid-scroll-pane",
    FUTURE_MAP_REF: "events-map-ref",
    FILTER_FORM_REF: "filter-form-ref",
    EVENTS_RELATION_MODAL_REF: "events-relation-modal-ref",
    SEARCH_BAR_REF: "search-bar-ref"
};

const TRIGGERS = {
    SCROLL_END: "scroll-end"
};

class DataCoordinate {

    constructor(rowIndex, colIndex) {
        this.rowIndex = rowIndex;
        this.colIndex = colIndex;
    }

    equals(coord) {
        return this.rowIndex === coord.rowIndex && this.colIndex === coord.colIndex;
    }

    toString() {
        return `${this.rowIndex}:${this.colIndex}`;
    }
}

class CellItem {

    constructor(events, trends) {
        this.events = events ? events : [];
        this.trends = trends ? trends : [];
    }

    getEvents() {
        return this.events;
    }

    getTrends() {
        return this.trends;
    }

    addEvent(event) {
        this.events.push(event);
    }

    addTrend(trend) {
        this.trends.push(trend);
    }

    removeEvent(event) {
        const i = this.events.indexOf(event);
        if (i !== -1) {
            this.events.splice(i, 1);
        }
    }

    removeTrend(trend) {
        const i = this.trends.indexOf(trend);
        if (i !== -1) {
            this.trends.splice(i, 1);
        }
    }
}

class ItemSelectionModel {

    constructor() {
        this.items = [];

        this.isSelected = this.isSelected.bind(this);
    }

    select(item) {
        let include = false;
        for (let i = 0; i < this.items.length; i++) {
            if (this.items[i].id === item.id) {
                include = true;
                break;
            }
        }
        if (!include) {
            this.items.push(item);
        }
    }

    clear(item) {
        let index = -1;
        for (let i = 0; i < this.items.length; i++) {
            if (this.items[i].id === item.id) {
                index = i;
                break;
            }
        }
        if (index !== -1) {
            this.items.splice(index, 1);
        }
    }

    clearAndSelect(item) {
        this.clearAll();
        this.select(item);
    }

    clearAll() {
        this.items = [];
    }

    isSelected(item) {
        let include = false;
        for (let i = 0; i < this.items.length; i++) {
            if (this.items[i].id === item.id) {
                include = true;
                break;
            }
        }
        return include;
    }

    getSelected() {
        return this.items;
    }
}


export {
    BOUNDS,
    CellItem,
    ItemSelectionModel,
    DataCoordinate,
    REFS,
    TRIGGERS
};