import React from 'react';
import {Button, Col, ControlLabel, Form, FormControl, FormGroup} from 'react-bootstrap';

class UserChangePasswordView
	extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			password: props.user ? props.user.password : "",
		};
	}

	componentDidMount() {
		if (!this.props.user && this.props.match.params.userId !== "0") {
			this.props.loadUser(this.props.match.params.userId);
		}
	}

	componentDidUpdate() {
		if (!this.props.user && this.props.match.params.userId !== "0") {
			this.props.loadUser(this.props.match.params.userId);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
		this.setState({
			              password: nextProps.user ? nextProps.user.password : "",
		              });
	}

	render() {

		if (!this.props.user) {
			return (
				<div>User not exists</div>
			)
		}

		return (
			<Form horizontal className="user-form">
				<FormGroup>
					<Col componentClass={ControlLabel} md={1}>
						<ControlLabel>Password:</ControlLabel>
					</Col>
					<Col md={11}>
						<FormControl type="password"
						             onChange={this.handlePasswordChange}
						             value={this.state.password}/>
					</Col>
				</FormGroup>
				<FormGroup>
					<Col md={12}>
						<Button bsClass="btn action-button pull-right" onClick={this.cancelButtonHandler}>
							Cancel
						</Button>
						<Button bsClass="btn action-button pull-right" onClick={this.saveButtonHandler}>
							Save
						</Button>
					</Col>
				</FormGroup>
			</Form>
		);
	}

	handlePasswordChange = (event) => {
		this.setState({
			              password: event.target.value
		              });
	};

	saveButtonHandler = () => {
		this.props.changePassword(this.props.user, this.state.password);
	};

	cancelButtonHandler = () => {
		this.props.history.goBack();
	};
}

export default UserChangePasswordView;