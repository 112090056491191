import clientFactory from "../factories/http_client";
import {API} from "../api/api";

class EventRelationsService {

	/** @return  Promise<AxiosResponse<T>> */
	create = (projectId, futureMapId, eventRelation) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.event_relations.create.method,
			                             url: API.data.projects.id.event_maps.id.event_relations.create.url(projectId, futureMapId),
			                             data: API.data.projects.id.event_maps.id.event_relations.create.build(eventRelation),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	save = (projectId, futureMapId, eventId, eventRelation) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.event_relations.save.method,
			                             url: API.data.projects.id.event_maps.id.event_relations.save.url(projectId, futureMapId),
			                             data: API.data.projects.id.event_maps.id.event_relations.save.build(eventRelation),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	getRelationsByEvent = (projectId, futureMapId, eventId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.event_relations.method,
			                             url: API.data.projects.id.event_maps.id.event_relations.url(projectId, futureMapId),
			                             params: API.data.projects.id.event_maps.id.event_relations.build(eventId)
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	getRelations = (projectId, futureMapId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.event_relations.method,
			                             url: API.data.projects.id.event_maps.id.event_relations.url(projectId, futureMapId),
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	remove = (projectId, futureMapId, eventRelation) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.event_relations.delete.method,
			                             url: API.data.projects.id.event_maps.id.event_relations.delete.url(projectId, futureMapId),
			                             data: API.data.projects.id.event_maps.id.event_relations.delete.build(eventRelation),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};
}

export default EventRelationsService;