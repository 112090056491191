import {projectAttachmentService, projectLinkService, projectService} from '../services';
import {browserHistory} from '../app';
import {CLIENT_URI_CONSTANTS as URI} from '../utils/uri-constants';
import * as factorsActions from "./factors";
import * as eventsActions from "./events";
import * as scenariosActions from "./scenarios";
import * as trendsActions from "./trends";
import * as colorSchemeAction from "./color-schema";
import * as newsActions from "./news";

export const actionTypes = {

	prefix: 'PROJECT',

	REQUEST_PROJECTS: 'REQUEST_PROJECTS',
	RECEIVE_PROJECTS: 'RECEIVE_PROJECTS',
	REQUEST_PROJECT: 'REQUEST_PROJECT',
	RECEIVE_PROJECT: 'RECEIVE_PROJECT',
	SELECT_PROJECT: 'SELECT_PROJECT',
	ADD_PROJECT: 'ADD_PROJECT',
	EDIT_PROJECT: 'EDIT_PROJECT',
	COPY_PROJECT: 'COPY_PROJECT',
	RESTORE_PROJECT: 'RESTORE_PROJECT',
	SAVE_PROJECT: 'SAVE_PROJECT',
	DELETE_PROJECT: 'DELETE_PROJECT',
	SELECT_LINK: 'SELECT_LINK',
	RECEIVE_LINK: 'RECEIVE_LINK',
	DELETE_LINK: 'DELETE_LINK',
	SELECT_COMMENT: 'SELECT_COMMENT',
	RECEIVE_COMMENT: 'RECEIVE_COMMENT',
	DELETE_COMMENT: 'DELETE_COMMENT',
	SELECT_ATTACHMENT: 'SELECT_ATTACHMENT',
	RECEIVE_ATTACHMENT: 'RECEIVE_ATTACHMENT',
	DELETE_ATTACHMENT: 'DELETE_ATTACHMENT',
	SELECT_USER_AUTHORITY: 'SELECT_USER_AUTHORITY',
	RECEIVE_USER_AUTHORITY: 'RECEIVE_USER_AUTHORITY',
	DELETE_USER_AUTHORITY: 'DELETE_USER_AUTHORITY',
	REQUEST_JOURNAL_ENTRIES: 'REQUEST_JOURNAL_ENTRIES',
	RECEIVE_JOURNAL_ENTRIES: 'RECEIVE_JOURNAL_ENTRIES',
	SELECT_JOURNAL_ENTRY: 'SELECT_JOURNAL_ENTRY',
	RECEIVE_FILTER: 'RECEIVE_FILTER',
	START_OPERATION: 'START_OPERATION',
	END_OPERATION: 'END_OPERATION',

	get: function (key) {
		return this.prefix + '_' + this[key];
	}
};

export const fetchProjects = filter => {
	return (dispatch, getState) => {
		if (!getState().projectReducer.isPending) {
			dispatch(requestProjects(filter));
			projectService.getByTitleAndPeriod((filter && filter !== "") ? {title: filter} : {})
			              .then(projects_proxy => {
				              dispatch(receiveProjects(projects_proxy.data));
			              });
		}
	}
};

export function requestProjects(filter) {
	return {
		type: actionTypes.get(actionTypes.REQUEST_PROJECTS),
		data: filter
	}
}

export function receiveProjects(response) {
	return {
		type: actionTypes.get(actionTypes.RECEIVE_PROJECTS),
		data: response
	}
}

export function fetchProject(id) {
	return (dispatch, getState) => {
		if (!getState().projectReducer.isPending) {
			dispatch(requestProject(id));
			projectService.getById(id)
			              .then(project_proxy => {
				              dispatch(receiveProject(project_proxy.data));
			              });
		}
	}
}

export function requestProject(id) {
	return {
		type: actionTypes.get(actionTypes.REQUEST_PROJECT)
	}
}

export function receiveProject(response) {
	return {
		type: actionTypes.get(actionTypes.RECEIVE_PROJECT),
		data: response
	}
}

export function selectProject(project) {
	return {
		type: actionTypes.get(actionTypes.SELECT_PROJECT),
		data: project
	}
}

export function addProject() {
	return (dispatch) => {
		dispatch(factorsActions.receiveFactors(null));
		browserHistory.push(`/${URI.PROJECT}/0/${URI.INFO}`);
	}
}

export function editProject(project) {
	return (dispatch) => {
		dispatch(receiveProject(null));
		dispatch(factorsActions.receiveFactors(null));
		dispatch(eventsActions.receiveEvents(null));
		dispatch(scenariosActions.receiveScenarios(null));
		dispatch(trendsActions.receiveTrends(null));
		dispatch(eventsActions.selectEvents(null));
		dispatch(colorSchemeAction.receiveColorSchema(null));
		dispatch(newsActions.receiveProjectNews(null));
		browserHistory.push(`/${URI.PROJECT}/${project.id}/${URI.INFO}`);
	}
}

export function copyProject(project, level) {
	return (dispatch, getState) => {
		projectService.copy(project.id, level)
		              .then(project_proxy => {
			              dispatch(fetchProjects(getState().projectReducer.filter));
		              });
	}
}

export function restoreProject(retrospectionEntry) {
	return (dispatch, getState) => {
		projectService.load(retrospectionEntry)
		              .then(() => {
			              dispatch(fetchProjects(getState().projectReducer.filter));
		              });
	}
}

export function createProject(project) {
	return (dispatch, getState) => {
		projectService.create(project)
		              .then(project_proxy => {
			              dispatch(receiveProject(project_proxy.data));
			              dispatch(endOperation({
				                                    name: 'CREATION',
				                                    status: "SUCCESS"
			                                    }));
			              browserHistory.push(`/${URI.PROJECT}/${project_proxy.data.id}/${URI.INFO}`)
		              })
		              .catch((error) => {
			              dispatch(endOperation({
				                                    name: 'CREATION',
				                                    status: "ERROR"
			                                    }));
		              });
	};
}

export function saveProject(project) {
	return (dispatch, getState) => {
		dispatch(requestProject());
		projectService.save(project)
		              .then(project_proxy => {
			              dispatch(receiveProject(project_proxy.data));
			              dispatch(endOperation({
				                                    name: 'SAVING',
				                                    status: "SUCCESS"
			                                    }));
		              })
		              .catch((error) => {
			              dispatch(endOperation({
				                                    name: 'SAVING',
				                                    status: "ERROR"
			                                    }));
		              });
	}
}

export function deleteProject(id) {
	return (dispatch, getState) => {
		projectService.remove(id)
		              .then(projectId => {
			              dispatch(fetchProjects(getState().projectReducer.filter));
		              });
	}
}

export function receiveFilter(filter) {
	return {
		type: actionTypes.get(actionTypes.RECEIVE_FILTER),
		data: filter
	}
}

export function fetchLink(projectId, linkId) {
	return (dispatch, getState) => {
		projectLinkService.get(projectId, linkId)
		                  .then(link_proxy => {
			                  dispatch(receiveLink(link_proxy.data))
		                  });
	}
}

export function receiveLink(link) {
	return {
		type: actionTypes.get(actionTypes.RECEIVE_LINK),
		data: link
	}
}

export const selectLink = (link) => {
	return {
		type: actionTypes.get(actionTypes.SELECT_LINK),
		data: link
	}
};

export const createLink = (projectId, link) => {
	return (dispatch, getState) => {
		projectLinkService.create(projectId, link)
		                  .then(link_proxy => {
			                  dispatch(fetchProject(projectId));
		                  });
	}
};

export const saveLink = (projectId, link) => {
	return (dispatch, getState) => {
		projectLinkService.save(projectId, link)
		                  .then(link_proxy => {
			                  dispatch(fetchProject(projectId));
		                  });
	}
};

export const deleteLink = (projectId, linkId) => {
	return (dispatch, getState) => {
		projectLinkService.delete(projectId, linkId)
		                  .then(() => {
			                  dispatch(fetchProject(projectId));
		                  });
	}
};

export function fetchAttachment(projectId, attachmentId) {
	return (dispatch, getState) => {
		projectAttachmentService.get(projectId, attachmentId)
		                        .then(attachment_proxy => {
			                        dispatch(receiveAttachment(attachment_proxy.data))
		                        });
	}
}

export function receiveAttachment(attachment) {
	return {
		type: actionTypes.get(actionTypes.RECEIVE_ATTACHMENT),
		data: attachment
	}
}

export const selectAttachment = (attachment) => {
	return {
		type: actionTypes.get(actionTypes.SELECT_ATTACHMENT),
		data: attachment
	}
};

export const createAttachment = (projectId, attachment) => {
	return (dispatch, getState) => {
		projectAttachmentService.create(projectId, attachment)
		                        .then(attachment_proxy => {
			                        dispatch(fetchProject(projectId));
		                        });
	}
};

export const saveAttachment = (projectId, attachment) => {
	return (dispatch, getState) => {
		projectAttachmentService.save(projectId, attachment)
		                        .then(attachment_proxy => {
			                        dispatch(fetchProject(projectId));
		                        });
	}
};

export const deleteAttachment = (projectId, attachmentId) => {
	return (dispatch, getState) => {
		projectAttachmentService.delete(projectId, attachmentId)
		                        .then(() => {
			                        dispatch(fetchProject(projectId));
		                        });
	}
};

export const selectUserAuthority = (authority) => {
	return {
		type: actionTypes.get(actionTypes.SELECT_USER_AUTHORITY),
		data: authority
	}
};

export const createUserAuthority = (projectId, authority) => {
	return (dispatch, getState) => {
		projectService.createAuthority(projectId, authority)
		              .then(authority_proxy => {
			              dispatch(fetchProject(projectId));
		              });
	}
};

export const saveUserAuthority = (projectId, authority) => {
	return (dispatch, getState) => {
		projectService.saveAuthority(projectId, authority)
		              .then(authority_proxy => {
			              dispatch(fetchProject(projectId));
		              });
	}
};

export function editUserAuthority(projectId, userAuthorityId) {
	return (dispatch, getState) => {
		projectService.getAuthority(projectId, userAuthorityId)
		              .then(authority_proxy => {
			              dispatch(receiveUserAuthority(authority_proxy.data))
		              });
	}
}

export function receiveUserAuthority(userAuthority) {
	return {
		type: actionTypes.get(actionTypes.RECEIVE_USER_AUTHORITY),
		data: userAuthority
	}
}

export const deleteUserAuthority = (projectId, authorityId) => {
	return (dispatch, getState) => {
		projectService.deleteAuthority(projectId, authorityId)
		              .then(() => {
			              dispatch(fetchProject(projectId));
		              });
	}
};

export const requestJournalEntries = () => {
	return {
		type: actionTypes.get(actionTypes.REQUEST_JOURNAL_ENTRIES)
	}
};

export const receiveJournalEntries = (response) => {
	return {
		type: actionTypes.get(actionTypes.RECEIVE_JOURNAL_ENTRIES),
		data: response
	}
};

export const fetchJournalEntries = (projectId) => {
	return (dispatch, getState) => {
		if (!getState().projectReducer.isJournalEntriesPending) {
			dispatch(requestJournalEntries(projectId));
			projectService.getJournalEntries(projectId)
			              .then(entries_proxy => {
				              dispatch(receiveJournalEntries(entries_proxy.data));
			              });
		}
	}
};

export const selectJournalEntry = (entry) => {
	return {
		type: actionTypes.get(actionTypes.SELECT_JOURNAL_ENTRY),
		data: entry
	};
};

export const startOperation = (operation) => {
	return {
		type: actionTypes.get(actionTypes.START_OPERATION),
		data: operation
	}
};

export const endOperation = (operation) => {
	return {
		type: actionTypes.get(actionTypes.END_OPERATION),
		data: operation
	}
};