import {connect} from "react-redux";
import {withRouter} from "react-router";
import * as usersActions from "../actions/users";
import UsersView from "../views/users";
import {authorities} from "../mixins/authorities";

const mapStateToProps = (state, ownProps) => {
	return {
		selectedUser: state.usersReducer.selectedUser,
		users: state.usersReducer.users,
		...authorities(state.authenticationReducer.authentication)
	}
};

const mapDispatchToProps = (dispatch, state) => {
	return {
		loadUsers: () => {
			dispatch(usersActions.fetchUsers());
		},
		selectUser: (user) => {
			dispatch(usersActions.selectUser(user));
		},
		addUser: () => {
			dispatch(usersActions.addUser());
		},
		editUser: (user) => {
			dispatch(usersActions.editUser(user));
		},
		deleteUser: (user) => {
			dispatch(usersActions.deleteUser(user));
		}
	}
};

const UsersContainer = connect(mapStateToProps, mapDispatchToProps)(UsersView);

export default withRouter(UsersContainer)