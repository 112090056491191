import {connect} from "react-redux";
import {withRouter} from "react-router";
import * as authenticationActions from "../actions/authentication";
import * as newsActions from "../actions/news";
import AppView from "../views/app";

const mapStateToProps = (state, ownProps) => {
	return {
		project: state.projectReducer.project,
		authentication: state.authenticationReducer.authentication,
		pendingData: Object.keys(state)
		                   .reduce(function (accumulator, currentValue) {
			                   return accumulator || (('isPending' in state[currentValue]) ? state[currentValue].isPending : false);
		                   }, false)
	}
};

const mapDispatchToProps = dispatch => {
	return {
		logout: () => {
			dispatch(authenticationActions.logout());
		}
	}
};

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(AppView);

export default withRouter(AppContainer)