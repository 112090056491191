import {commonService} from "../services";

export const actionTypes = {

    prefix: 'FLAG',

    REQUEST_FLAGS: 'REQUEST_FLAGS',
    RECEIVE_FLAGS: 'RECEIVE_FLAGS',

    get: function (key) {
        return this.prefix + '_' + this[key];
    }
};

export const fetchFlags = () => {
    return (dispatch, getState) => {
        if (!getState().flagsReducer.isPending) {
            dispatch(requestFlags());
            commonService.getFlags().then(flags_proxy => {
                dispatch(receiveFlags(flags_proxy.data));
            });
        }
    }
};

export const requestFlags = () => {
    return {
        type: actionTypes.get(actionTypes.REQUEST_FLAGS)
    }
};

export const receiveFlags = (response) => {
    return {
        type: actionTypes.get(actionTypes.RECEIVE_FLAGS),
        data: response
    }
};