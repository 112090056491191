import {actionTypes} from "../actions/event-types";

const initialState = {
	isPending: false,
	invalidated: false,
	eventTypes: null,
	eventType: null,
	selectedEventType: null,
	projectEventTypes: null,
	projectEventType: null,
	projectSelectedEventType: null,
	operation: null
};

function eventTypesReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.get(actionTypes.REQUEST_EVENT_TYPES):
			return {
				...state,
				isPending: true
			};
		case actionTypes.get(actionTypes.REQUEST_PROJECT_EVENT_TYPES):
			return {
				...state,
				isPending: true
			};
		case actionTypes.get(actionTypes.RECEIVE_EVENT_TYPES):
			return {
				...state,
				isPending: false,
				eventTypes: action.data
			};
		case actionTypes.get(actionTypes.RECEIVE_PROJECT_EVENT_TYPES):
			return {
				...state,
				isPending: false,
				projectEventTypes: action.data
			};
		case actionTypes.get(actionTypes.SELECT_EVENT_TYPE):
			return {
				...state,
				selectedEventType: action.data,
			};
		case actionTypes.get(actionTypes.REQUEST_EVENT_TYPE):
			return {
				...state,
				isPending: true
			};
		case actionTypes.get(actionTypes.RECEIVE_EVENT_TYPE):
			return {
				...state,
				isPending: false,
				eventType: action.data
			};
		case actionTypes.get(actionTypes.APPEND_ITEM):

			let eventTypes = state.eventTypes;

			return {
				...state,
				isPending: false,
				eventTypes: eventTypes && Array.isArray(eventTypes) ? eventTypes.concat([action.data]) : [].concat([action.data])
			};
		case actionTypes.get(actionTypes.INSERT_ITEM):

			let index = state.eventTypes.findIndex((item) => {
				return item.id === action.data.id;
			});

			if (index !== -1) {
				state.eventTypes.splice(index, 1, action.data)
			} else {
				state.eventTypes.push(action.data);
			}

			return {
				...state,
				isPending: false,
				eventTypes: [...state.eventTypes]
			};
		case actionTypes.get(actionTypes.START_OPERATION):
			return {
				...state,
				operation: action.data
			};
		case actionTypes.get(actionTypes.END_OPERATION):
			return {
				...state,
				operation: action.data
			};
		default:
			return state
	}
}

export default eventTypesReducer