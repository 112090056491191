import React from "react";
import {connect} from "react-redux";
import {Route, withRouter} from "react-router";
import {CLIENT_URI_CONSTANTS} from "../utils/uri-constants";

class InitialRouteProvider
	extends React.Component {

	componentDidMount() {
		if (this.props.location.pathname !== this.props.path) {
			window.sessionStorage.setItem('wc.path', this.props.location.pathname);
		}
	}

	render() {
		return this.props.children;
	}
}


const mapStateToProps = (state, ownProps) => {
	return {
	}
};

const mapDispatchToProps = dispatch => {
	return {
	}
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InitialRouteProvider));