import clientFactory from "../factories/http_client";
import {API} from "../api/api";

class EventCommentService {

	/** @return  Promise<AxiosResponse<T>> */
	create = (projectId, futureMapId, eventId, comment) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.events.id.comments.create.method,
			                             url: API.data.projects.id.event_maps.id.events.id.comments.create.url(projectId, futureMapId, eventId),
			                             data: API.data.projects.id.event_maps.id.events.id.comments.create.build(comment),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	save = (projectId, futureMapId, eventId, comment) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.events.id.comments.save.method,
			                             url: API.data.projects.id.event_maps.id.events.id.comments.save.url(projectId, futureMapId, eventId),
			                             data: API.data.projects.id.event_maps.id.events.id.comments.save.build(comment),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	delete = (projectId, futureMapId, eventId, commentId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.events.id.comments.delete.method,
			                             url: API.data.projects.id.event_maps.id.events.id.comments.delete.url(projectId, futureMapId, eventId),
			                             data: API.data.projects.id.event_maps.id.events.id.comments.delete.build(commentId),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	get = (projectId, futureMapId, eventId, commentId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.events.id.comments.id.method,
			                             url: API.data.projects.id.event_maps.id.events.id.comments.id.url(projectId, futureMapId, eventId, commentId),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};
}

export default EventCommentService