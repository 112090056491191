import {Enum} from 'enumify';

class LevelOfCopy extends Enum {

    static fromCode(code) {
        for (let i = 0; i < LevelOfCopy.enumValues.length; i++) {
            if (LevelOfCopy.enumValues[i].code === code) {
                return LevelOfCopy.enumValues[i];
            }
        }
    }
}

LevelOfCopy.initEnum({
    FULL: {
        code: 0,
        text: "Full"
    },
    WE: {
        code: 1,
        text: "Without events"
    },
    WFAE: {
        code: 2,
        text: "Without factors and events"
    }
});

export {
    LevelOfCopy
}