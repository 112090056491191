import {authenticationService} from "../services";

export const actionTypes = {
	REQUEST_AUTHORITIES: 'REQUEST_AUTHORITIES',
	RECEIVE_AUTHORITIES: 'RECEIVE_AUTHORITIES'
};

export const fetchAuthorities = () => {
	return (dispatch, getState) => {
		if (!getState().authoritiesReducer.isPending) {
			dispatch(requestAuthorities());
			authenticationService.getAuthorities()
			                     .then(authorities_proxy => {
				                     dispatch(receiveAuthorities(authorities_proxy.data.authorities));
			                     });
		}
	}
};

export const requestAuthorities = () => {
	return {
		type: actionTypes.REQUEST_AUTHORITIES
	}
};

export const receiveAuthorities = (response) => {
	return {
		type: actionTypes.RECEIVE_AUTHORITIES,
		data: response
	}
};