import clientFactory from "../factories/http_client";
import {API} from "../api/api";

class UserService {

	/** @return  Promise<AxiosResponse<T>> */
	getUsers = () => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.users.method,
			                             url: API.data.users.url
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	getUser = (id) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.users.id.method,
			                             url: API.data.users.id.url(id)
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	create = (user) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.users.create.method,
			                             url: API.data.users.create.url,
			                             data: API.data.users.create.build(user),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	setRole = (user, role) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.users.id.set_role.method,
			                             url: API.data.users.id.set_role.url(user.id),
			                             data: API.data.users.id.set_role.build(role)
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	save = (user) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.users.create.method,
			                             url: API.data.users.create.url,
			                             data: API.data.users.create.build(user),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	remove = (user) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.users.delete.method,
			                             url: API.data.users.delete.url,
			                             data: API.data.users.delete.build(user.id),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	changePassword = (userId, password) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.users.id.set_password.method,
			                             url: API.data.users.id.set_password.url(userId),
			                             data: API.data.users.id.set_password.build(password),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	}
}

export default UserService;