import React from 'react';
import {Button, Modal, ToggleButton, ToggleButtonGroup,} from 'react-bootstrap';

class EventSourcesSelectForm
	extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			selectedEventSources: props.selectedEventSources === undefined ? [] : props.selectedEventSources
		};
	}

	handleSaveButton = () => {
		this.props.onSave(this.state.selectedEventSources);
	};

	handleChange = (item) => {

		const newSelectedElements = this.state.selectedEventSources.filter(function (element) {
			return element.id !== item.id;
		});

		if (this.state.selectedEventSources.length !== newSelectedElements.length) {
			this.setState({
				              selectedEventSources: newSelectedElements
			              });
		} else {
			newSelectedElements.push(item);
			this.setState({
				              selectedEventSources: newSelectedElements
			              });
		}
	};

	render() {
		return (
			<Modal bsSize="sm" show={this.props.show} onHide={this.props.onHide}>
				<Modal.Header closeButton>
					<Modal.Title>Event Sources Form</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<ToggleButtonGroup vertical
					                   type="checkbox"
					                   style={
						                   {
							                   width: "100%"
						                   }
					                   }
					                   onChange={(e) => {}}
					                   value={
						                   this.state.selectedEventSources.map(function (element) {
							                   return element.id;
						                   })
					                   }
					>
						{
							this.props.availableEventSources && this.props.availableEventSources.map(
								(currentValue, index, array) => {
									return <ToggleButton value={currentValue.id}
									                     key={currentValue.id}
									                     onClick={
										                     (event) => {
											                     this.handleChange(currentValue)
										                     }
									                     }
									>
										{currentValue.title}
									</ToggleButton>
								})
						}
					</ToggleButtonGroup>
				</Modal.Body>
				<Modal.Footer>
					<Button bsClass="btn action-button" onClick={this.handleSaveButton}>Save</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default EventSourcesSelectForm;