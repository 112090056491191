import {actionTypes} from "../actions/event-sources";

const initialState = {
	isPending: false,
	invalidated: false,
	eventSources: null,
	eventSource: null,
	selectedEventSource: null,
	projectEventSources: null,
	projectEventSource: null,
	projectSelectedEventSource: null,
	operation: null
};

function eventSourcesReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.get(actionTypes.REQUEST_EVENT_SOURCES):
			return {
				...state,
				isPending: true
			};
		case actionTypes.get(actionTypes.REQUEST_PROJECT_EVENT_SOURCES):
			return {
				...state,
				isPending: true
			};
		case actionTypes.get(actionTypes.RECEIVE_EVENT_SOURCES):
			return {
				...state,
				isPending: false,
				eventSources: action.data
			};
		case actionTypes.get(actionTypes.RECEIVE_PROJECT_EVENT_SOURCES):
			return {
				...state,
				isPending: false,
				projectEventSources: action.data
			};
		case actionTypes.get(actionTypes.SELECT_EVENT_SOURCE):
			return {
				...state,
				selectedEventSource: action.data,
			};
		case actionTypes.get(actionTypes.REQUEST_EVENT_SOURCE):
			return {
				...state,
				isPending: true
			};
		case actionTypes.get(actionTypes.RECEIVE_EVENT_SOURCE):
			return {
				...state,
				isPending: false,
				eventSource: action.data
			};
		case actionTypes.get(actionTypes.APPEND_ITEM):

			let eventSources = state.eventSources;

			return {
				...state,
				isPending: false,
				eventSources: eventSources && Array.isArray(eventSources) ? eventSources.concat([action.data]) : [].concat([action.data])
			};
		case actionTypes.get(actionTypes.INSERT_ITEM):

			let index = state.eventSources.findIndex((item) => {
				return item.id === action.data.id;
			});

			if (index !== -1) {
				state.eventSources.splice(index, 1, action.data)
			} else {
				state.eventSources.push(action.data);
			}

			return {
				...state,
				isPending: false,
				eventSources: [...state.eventSources]
			};
		case actionTypes.get(actionTypes.START_OPERATION):
			return {
				...state,
				operation: action.data
			};
		case actionTypes.get(actionTypes.END_OPERATION):
			return {
				...state,
				operation: action.data
			};
		default:
			return state
	}
}

export default eventSourcesReducer