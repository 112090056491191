import {browserHistory} from '../app';
import {CLIENT_URI_CONSTANTS as URI} from '../utils/uri-constants';
import {projectService, scenarioAttachmentService, scenarioLinkService, scenarioService} from "../services";

export const actionTypes = {

	prefix: 'SCENARIO',

	REQUEST_SCENARIOS: 'REQUEST_SCENARIOS',
	RECEIVE_SCENARIOS: 'RECEIVE_SCENARIOS',
	REQUEST_SCENARIO: 'REQUEST_SCENARIO',
	RECEIVE_SCENARIO: 'RECEIVE_SCENARIO',
	SELECT_SCENARIO: 'SELECT_SCENARIO',
	ADD_SCENARIO: 'ADD_SCENARIO',
	EDIT_SCENARIO: 'EDIT_SCENARIO',
	SAVE_SCENARIO: 'SAVE_SCENARIO',
	DELETE_SCENARIO: 'DELETE_SCENARIO',
	SELECT_LINK: 'SELECT_LINK',
	RECEIVE_LINK: 'RECEIVE_LINK',
	DELETE_LINK: 'DELETE_LINK',
	SELECT_COMMENT: 'SELECT_COMMENT',
	RECEIVE_COMMENT: 'RECEIVE_COMMENT',
	DELETE_COMMENT: 'DELETE_COMMENT',
	SELECT_ATTACHMENT: 'SELECT_ATTACHMENT',
	RECEIVE_ATTACHMENT: 'RECEIVE_ATTACHMENT',
	DELETE_ATTACHMENT: 'DELETE_ATTACHMENT',
	SELECT_EVENT: 'SELECT_EVENT',
	START_OPERATION: 'START_OPERATION',
	END_OPERATION: 'END_OPERATION',

	get: function (key) {
		return this.prefix + '_' + this[key];
	}

};

export const fetchScenarios = (projectId) => {
	return (dispatch, getState) => {
		if (!getState().scenariosReducer.isPending) {
			dispatch(requestScenarios());
			projectService.getScenarios(projectId)
			              .then(scenarios_proxy => {
				              dispatch(receiveScenarios(scenarios_proxy.data));
			              });
		}
	}
};

export const requestScenarios = () => {
	return {
		type: actionTypes.get('REQUEST_SCENARIOS')
	}
};

export const fetchScenario = (projectId, scenarioId) => {
	return (dispatch, getState) => {
		if (!getState().scenariosReducer.isPending) {
			dispatch(requestScenario());
			scenarioService.get(projectId, scenarioId)
			               .then(scenario_proxy => {
				               dispatch(receiveScenario(scenario_proxy.data));
			               });
		}
	}
};

export const requestScenario = (projectId, futureMapId, scenarioId) => {
	return {
		type: actionTypes.get('REQUEST_SCENARIO')
	}
};

export const receiveScenario = (response) => {
	return {
		type: actionTypes.get('RECEIVE_SCENARIO'),
		data: response
	}
};

export const receiveScenarios = (response) => {
	return {
		type: actionTypes.get('RECEIVE_SCENARIOS'),
		data: response
	}
};

export const selectScenario = (scenario) => {
	return {
		type: actionTypes.get('SELECT_SCENARIO'),
		data: scenario
	}
};

export const addScenario = (project) => {
	return (dispatch, getState) => {
		dispatch(receiveScenario(null));
		browserHistory.push(`/${URI.PROJECT}/${project.id}/${URI.SCENARIOS}/0`);
	};
};

export const editScenario = (project, scenario) => {
	return (dispatch, getState) => {
		dispatch(receiveScenario(null));
		browserHistory.push(`/${URI.PROJECT}/${project.id}/${URI.SCENARIOS}/${scenario.id}`);
	}
};

export const createScenario = (projectId, futureMapId, scenario) => {
	return (dispatch, getState) => {
		scenarioService.create(projectId, futureMapId, scenario)
		               .then(scenario_proxy => {
			               dispatch(receiveScenarios(null));
			               dispatch(receiveScenario(scenario_proxy.data));
			               dispatch(endOperation({
				                                     name: 'CREATION',
				                                     status: "SUCCESS"
			                                     }));
			               browserHistory.push(`/${URI.PROJECT}/${projectId}/${URI.SCENARIOS}/${scenario_proxy.data.id}`)
		               })
		               .catch((error) => {
			               dispatch(endOperation({
				                                     name: 'CREATION',
				                                     status: "ERROR"
			                                     }));
		               });
	};
};

export const saveScenario = (projectId, scenario) => {
	return (dispatch, getState) => {
		scenarioService.save(projectId, scenario)
		               .then(scenario_proxy => {
			               dispatch(receiveScenarios(null));
			               dispatch(receiveScenario(scenario_proxy.data));
			               dispatch(endOperation({
				                                     name: 'SAVING',
				                                     status: "SUCCESS"
			                                     }));
		               })
		               .catch((error) => {
			               dispatch(endOperation({
				                                     name: 'SAVING',
				                                     status: "ERROR"
			                                     }));
		               });
	}
};

export const deleteScenario = (projectId, scenarioId) => {
	return (dispatch, getState) => {
		scenarioService.remove(projectId, scenarioId)
		               .then(scenarioId => {
			               dispatch(fetchScenarios(projectId));
		               });
	}
};

export function fetchLink(projectId, eventsMapId, factorId, linkId) {
	return (dispatch, getState) => {
		scenarioLinkService.get(projectId, eventsMapId, factorId, linkId)
		                   .then(link_proxy => {
			                   dispatch(receiveLink(link_proxy.data))
		                   });
	}
}

export function receiveLink(link) {
	return {
		type: actionTypes.get('RECEIVE_LINK'),
		data: link
	}
}

export const selectLink = (link) => {
	return {
		type: actionTypes.get('SELECT_LINK'),
		data: link
	}
};

export const createLink = (projectId, scenarioId, link) => {
	return (dispatch, getState) => {
		scenarioLinkService.create(projectId, scenarioId, link)
		                   .then(link_proxy => {
			                   dispatch(fetchScenario(projectId, scenarioId));
		                   });
	}
};

export const saveLink = (projectId, scenarioId, link) => {
	return (dispatch, getState) => {
		scenarioLinkService.save(projectId, scenarioId, link)
		                   .then(link_proxy => {
			                   dispatch(fetchScenario(projectId, scenarioId));
		                   });
	}
};

export const deleteLink = (projectId, scenarioId, linkId) => {
	return (dispatch, getState) => {
		scenarioLinkService.delete(projectId, scenarioId, linkId)
		                   .then(() => {
			                   dispatch(fetchScenario(projectId, scenarioId));
		                   });
	}
};

export function fetchAttachment(projectId, scenarioId, attachmentId) {
	return (dispatch, getState) => {
		scenarioAttachmentService.get(projectId, scenarioId, attachmentId)
		                         .then(attachment_proxy => {
			                         dispatch(receiveAttachment(attachment_proxy.data))
		                         });
	}
}

export function receiveAttachment(attachment) {
	return {
		type: actionTypes.get('RECEIVE_ATTACHMENT'),
		data: attachment
	}
}

export const selectAttachment = (attachment) => {
	return {
		type: actionTypes.get('SELECT_ATTACHMENT'),
		data: attachment
	}
};

export const createAttachment = (projectId, scenarioId, attachment) => {
	return (dispatch, getState) => {
		scenarioAttachmentService.create(projectId, scenarioId, attachment)
		                         .then(attachment_proxy => {
			                         dispatch(fetchScenario(projectId, scenarioId));
		                         });
	}
};

export const saveAttachment = (projectId, scenarioId, attachment) => {
	return (dispatch, getState) => {
		scenarioAttachmentService.save(projectId, scenarioId, attachment)
		                         .then(attachment_proxy => {
			                         dispatch(fetchScenario(projectId, scenarioId));
		                         });
	}
};

export const deleteAttachment = (projectId, scenarioId, attachmentId) => {
	return (dispatch, getState) => {
		scenarioAttachmentService.delete(projectId, scenarioId, attachmentId)
		                         .then(() => {
			                         dispatch(fetchScenario(projectId, scenarioId));
		                         });
	}
};

export const selectEvent = (event) => {
	return {
		type: actionTypes.get('SELECT_EVENT'),
		data: event
	}
};

export const startOperation = (operation) => {
	return {
		type: actionTypes.get(actionTypes.START_OPERATION),
		data: operation
	}
};

export const endOperation = (operation) => {
	return {
		type: actionTypes.get(actionTypes.END_OPERATION),
		data: operation
	}
};