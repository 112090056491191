import {eventTypeService, projectService} from "../services";

export const actionTypes = {

	prefix: 'EVENT_TYPE',

	REQUEST_EVENT_TYPES: 'REQUEST_EVENT_TYPES',
	RECEIVE_EVENT_TYPES: 'RECEIVE_EVENT_TYPES',
	REQUEST_PROJECT_EVENT_TYPES: 'REQUEST_PROJECT_EVENT_TYPES',
	RECEIVE_PROJECT_EVENT_TYPES: 'RECEIVE_PROJECT_EVENT_TYPES',
	SELECT_EVENT_TYPE: 'SELECT_EVENT_TYPE',
	REQUEST_EVENT_TYPE: 'REQUEST_EVENT_TYPE',
	RECEIVE_EVENT_TYPE: 'RECEIVE_EVENT_TYPE',
	APPEND_ITEM: 'APPEND_ITEM',
	INSERT_ITEM: 'INSERT_ITEM',
	START_OPERATION: 'START_OPERATION',
	END_OPERATION: 'END_OPERATION',

	get: function (key) {
		return this.prefix + '_' + this[key];
	}
};

export const fetchEventTypes = () => {
	return (dispatch, getState) => {
		if (!getState().eventTypesReducer.isPending) {
			dispatch(requestEventTypes());
			eventTypeService.getAll()
			                .then(types_proxy => {
				                dispatch(receiveEventTypes(types_proxy.data));
			                })
		}
	}
};

export const requestEventTypes = () => {
	return {
		type: actionTypes.get(actionTypes.REQUEST_EVENT_TYPES)
	}
};

export const receiveEventTypes = (response) => {
	return {
		type: actionTypes.get(actionTypes.RECEIVE_EVENT_TYPES),
		data: response
	}
};

export const fetchProjectEventTypes = (projectId) => {
	return (dispatch, getState) => {
		dispatch(requestProjectEventTypes());
		projectService.getEventTypes(projectId)
		              .then(types_proxy => {
			              dispatch(receiveProjectEventTypes(types_proxy.data));
		              })
	}
};

export const requestProjectEventTypes = () => {
	return {
		type: actionTypes.get(actionTypes.REQUEST_PROJECT_EVENT_TYPES)
	}
};

export const receiveProjectEventTypes = (response) => {
	return {
		type: actionTypes.get(actionTypes.RECEIVE_PROJECT_EVENT_TYPES),
		data: response
	}
};

export const selectEventType = (eventType) => {
	return {
		type: actionTypes.get(actionTypes.SELECT_EVENT_TYPE),
		data: eventType
	}
};

export const fetchEventType = (id) => {
	return (dispatch, getState) => {
		if (!getState().eventTypesReducer.isPending) {
			dispatch(requestEventType());
			eventTypeService.getById(id)
			                .then(type_proxy => {
				                dispatch(receiveEventType(type_proxy.data));
			                })
		}
	}
};

export const requestEventType = () => {
	return {
		type: actionTypes.get(actionTypes.REQUEST_EVENT_TYPE)
	}
};

export const receiveEventType = (response) => {
	return {
		type: actionTypes.get(actionTypes.RECEIVE_EVENT_TYPE),
		data: response
	}
};

export const appendEventType = (data) => {
	return {
		type: actionTypes.get(actionTypes.APPEND_ITEM),
		data: data
	}
};

export const insertEventType = (data) => {
	return {
		type: actionTypes.get(actionTypes.INSERT_ITEM),
		data: data
	}
};

export const createEventType = (eventType) => {
	return (dispatch, getState) => {

		dispatch(receiveEventType(null));

		eventTypeService.save(eventType)
		                .then(type_proxy => {
			                dispatch(fetchEventTypes());
		                })
	}
};

export const saveEventType = (eventType) => {
	return (dispatch, getState) => {

		dispatch(receiveEventType(null));

		eventTypeService.save(eventType)
		                .then(type_proxy => {
			                dispatch(fetchEventTypes());
		                })
	}
};

export const deleteEventType = (id) => {
	return (dispatch, getState) => {
		eventTypeService.delete(id)
		                .then(type_proxy => {
			                dispatch(fetchEventTypes());
		                })
	}
};

export const startOperation = (operation) => {
	return {
		type: actionTypes.get(actionTypes.START_OPERATION),
		data: operation
	}
};

export const endOperation = (operation) => {
	return {
		type: actionTypes.get(actionTypes.END_OPERATION),
		data: operation
	}
};