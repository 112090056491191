import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import * as servers_actions from "../actions/servers";
import {API} from "../api/api";
import clientFactory from "../factories/http_client";
import {tokenService} from "../services"

export class CsrfTokenProvider
	extends React.Component {

	componentDidMount() {
		Object.keys(API).forEach((key)=> {
			if (API[key].csrf) {
				tokenService.getCsrfToken(key)
				            .then((token_promise) => {

					            clientFactory.remove_client_from_cache(API[key].scope);
					            clientFactory.set_csrf_token(token_promise.data, API[key].scope);

					            this.props.set_csrf_token(key, token_promise.data);
				            })
			}
		});
	}

	render() {
		if (this.props.csrf_tokens &&  Object.keys(this.props.csrf_tokens).length === Object.keys(API).reduce((accumulator, currentValue) => {
			if (API[currentValue].csrf) {
				return accumulator + 1;
			}
			return accumulator;
		}, 0)) {
			return this.props.children;
		} else {
			return <div>Receiving CSRF Token...</div>;
		}
	}
}


const mapStateToProps = (state, ownProps) => {
	return {
		csrf_tokens: state.servers.csrf_tokens
	}
};

const mapDispatchToProps = dispatch => {
	return {
		set_csrf_token: (realm, token) => {
			dispatch(servers_actions.receive_csrf_token({scope: realm, token: token}))
		}
	}
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CsrfTokenProvider));