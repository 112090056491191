import {connect} from "react-redux";
import {withRouter} from "react-router";
import * as event_actions from "../actions/events";
import {appendEvent, endOperation, insertEvent, receiveEvent, requestEvent} from "../actions/events";
import * as flagsActions from "../actions/flags";
import * as factorsActions from "../actions/factors";
import * as eventRelationsActions from "../actions/event-relations";
import * as eventCategoriesAction from "../actions/event-categories";
import * as eventTypesAction from "../actions/event-types";
import * as eventSourcesAction from "../actions/event-sources";
import {authorities} from "../mixins/authorities";
import {eventRelationsService, eventService, projectService} from "../services";
import * as numericUnitsActions from "../actions/numeric-units";
import {browserHistory} from "../app";
import {CLIENT_URI_CONSTANTS as URI} from "../utils/uri-constants";
import EventDialog from "../components/dialogs/event-dialog";
import * as orphans_actions from "../actions/orphans";

const mapStateToProps = (state, ownProps) => {
	return {
		project: state.projectReducer.project,
		event: state.eventsReducer.event,
		selectedLink: state.eventsReducer.event ? state.eventsReducer.event.selectedLink : null,
		link: state.eventsReducer.event ? state.eventsReducer.event.link : null,
		selectedAttachment: state.eventsReducer.event ? state.eventsReducer.event.selectedAttachment : null,
		attachment: state.eventsReducer.event ? state.eventsReducer.event.attachment : null,
		events: state.eventsReducer.events,
		factors: state.factorReducer.factors,
		flags: state.flagsReducer.flags,
		projectEventTypes: state.eventTypesReducer.projectEventTypes,
		eventTypes: state.eventTypesReducer.eventTypes,
		projectEventCategories: state.eventCategoriesReducer.projectEventCategories,
		eventCategories: state.eventCategoriesReducer.eventCategories,
		projectEventSources: state.eventSourcesReducer.projectEventSources,
		eventSources: state.eventSourcesReducer.eventSources,
		numericUnits: state.numericUnitsReducer.numericUnits,
		selectedRelation: state.eventRelationsReducer.selectedEventRelation,
		eventOperation: state.eventsReducer.operation,
		orphans: state.orphans.items,
		selectedOrphan: state.orphans.selectedItem,
		...authorities(state.authenticationReducer.authentication)
	}
};

const mapDispatchToProps = (dispatch, state) => {
	return {
		loadInitialData: (id) => {
			dispatch(loadInitialData(id));
		},
		loadDictionaries: () => {
			dispatch(loadDictionariesActionChain())
		},
		loadEvents: (project) => {
			dispatch(event_actions.fetchEvents(project.id, project.eventsMap.id))
		},
		loadEvent: (project, id) => {
			dispatch(event_actions.fetchEvent(project.id, project.eventsMap.id, id))
		},
		selectLink: (link) => {
			dispatch(event_actions.selectLink(link));
		},
		editLink: (project, event, link) => {
			dispatch(event_actions.fetchLink(project.id, project.eventsMap.id, event.id, link.id));
		},
		saveLink: (project, event, link) => {
			if (link.id) {
				dispatch(event_actions.saveLink(project.id, project.eventsMap.id, event.id, link));
			} else {
				link.event = {
					id: event.id,
					retrospectionId: event.retrospectionId
				};
				dispatch(event_actions.createLink(project.id, project.eventsMap.id, event.id, link));
			}
		},
		deleteLink: (project, event, link) => {
			dispatch(event_actions.deleteLink(project.id, project.eventsMap.id, event.id, link.id));
		},
		clearLink: () => {
			dispatch(event_actions.receiveLink(null));
		},
		selectAttachment: (attachment) => {
			dispatch(event_actions.selectAttachment(attachment));
		},
		editAttachment: (project, event, attachment) => {
			dispatch(event_actions.fetchAttachment(project.id, project.eventsMap.id, event.id, attachment.id));
		},
		saveAttachment: (project, event, attachment) => {
			if (attachment.id) {
				dispatch(event_actions.saveAttachment(project.id, project.eventsMap.id, event.id, attachment));
			} else {
				attachment.event = {
					id: event.id,
					retrospectionId: event.retrospectionId
				};
				dispatch(event_actions.createAttachment(project.id, project.eventsMap.id, event.id, attachment));
			}
		},
		deleteAttachment: (project, event, attachment) => {
			dispatch(event_actions.deleteAttachment(project.id, project.eventsMap.id, event.id, attachment.id));
		},
		clearAttachment: () => {
			dispatch(event_actions.receiveAttachment(null));
		},
		loadFactors: (project) => {
			dispatch(factorsActions.fetchFactors(project.id, project.eventsMap.id))
		},
		loadFlags: () => {
			dispatch(flagsActions.fetchFlags());
		},
		loadEventCategories: (project) => {
			dispatch(eventCategoriesAction.fetchProjectEventCategories(project.id));
		},
		loadEventTypes: (project) => {
			dispatch(eventTypesAction.fetchProjectEventTypes(project.id));
		},
		loadEventSources: (project) => {
			dispatch(eventSourcesAction.fetchProjectEventSources(project.id));
		},
		loadNumericUnits: () => {
			dispatch(numericUnitsActions.fetchNumericUnits());
		},
		createEvent: (event) => {
			let createEvent = (event) => {
				return (dispatch, getState) => {
					dispatch(event_actions.requestEvent())

					let project = getState().projectReducer.project;

					event.future_map_id = project.eventsMap.id;
					event.author = null;

					eventService.create(project.id, project.eventsMap.id, event)
					            .then(event_proxy => {
						            dispatch(receiveEvent(event_proxy.data));
						            dispatch(appendEvent(event_proxy.data));
						            dispatch(event_actions.endOperation({
							                                                name: 'CREATION',
							                                                status: "SUCCESS"
						                                                }));
						            browserHistory.push(`/${URI.PROJECT}/${project.id}/events-map/?event-id=${event_proxy.data.id}#edit`);
					            })
					            .catch(error => {
						            dispatch(event_actions.endOperation({
							                                                name: 'CREATION',
							                                                status: "ERROR"
						                                                }));
					            });
				}
			};
			dispatch(createEvent(event));
		},
		saveEvent: (event) => {
			let saveEvent = (event) => {
				return (dispatch, getState) => {
					dispatch(event_actions.requestEvent())
					if (event.id) {

						let project = getState().projectReducer.project;

						eventService.save(project.id, project.eventsMap.id, event)
						            .then(event_proxy => {
							            dispatch(receiveEvent(event_proxy.data));
							            dispatch(insertEvent(event_proxy.data));
							            dispatch(endOperation({
								                                  name: 'SAVING',
								                                  status: "SUCCESS"
							                                  }));
						            })
						            .catch(error => {
							            dispatch(endOperation({
								                                  name: 'SAVING',
								                                  status: "ERROR"
							                                  }));
						            });
					}
				}
			};
			dispatch(saveEvent(event))

		},
		saveRelation: (project, relation) => {
			eventRelationsService.create(project.id, project.eventsMap.id, relation)
			                     .then(upRelation_proxy => {
				                     dispatch(event_actions.fetchEvent(project.id, project.eventsMap.id, relation.left_event.id));
			                     });
		},
		selectEventRelation: (relation) => {
			dispatch(eventRelationsActions.selectEventRelation(relation));
		},
		deleteEventRelation: (project, relation) => {
			dispatch(eventRelationsActions.deleteEventRelation(project.id, project.eventsMap.id, relation));
		},
		saveImage: (project, event, image) => {
			image.event = {
				id: event.id,
				retrospectionId: event.retrospectionId
			};
			dispatch(event_actions.createImage(project.id, project.eventsMap.id, event.id, image));
		},
		deleteImage: (project, event, image) => {
			dispatch(event_actions.deleteImage(project.id, project.eventsMap.id, event.id, image.id));
		},
		addProjectEventCategories: (event_categories) => {
			dispatch(addProjectEventCategoriesActionChain(event_categories))
		},
		addProjectEventSources: (event_sources) => {
			dispatch(addProjectEventSourcesActionChain(event_sources))
		},
		addProjectEventTypes: (event_types) => {
			dispatch(addProjectEventTypesActionChain(event_types))
		},
		selectOrphan: (item) => {
			dispatch(orphans_actions.selectItem(item))
		},
		clearOrphans: () => {
			dispatch(orphans_actions.receiveItems(null));
		},
		loadOrphanItemInfo: (type, path) => {

		},
		clearOperation: () => {
			dispatch(event_actions.startOperation(null));
		},
	}
};

function addProjectEventCategoriesActionChain(event_categories) {
	return (dispatch, getState) => {

		let project = getState().projectReducer.project;

		projectService
			.addEventCategories(project.id, event_categories)
			.then(event_categories_proxy => {
				dispatch(eventCategoriesAction.receiveProjectEventCategories(event_categories_proxy.data))
			})
			.catch(error => {
				if (error.response.status === 409) {
					dispatch(orphans_actions.receiveItems(error.response.data));
				}
			});
	};
}

function addProjectEventSourcesActionChain(event_sources) {
	return (dispatch, getState) => {

		let project = getState().projectReducer.project;

		projectService
			.addEventSources(project.id, event_sources)
			.then(event_sources_proxy => {
				dispatch(eventSourcesAction.receiveProjectEventSources(event_sources_proxy.data))
			})
			.catch(error => {
				if (error.response.status === 409) {
					dispatch(orphans_actions.receiveItems(error.response.data));
				}
			});
	};
}

function addProjectEventTypesActionChain(event_types) {
	return (dispatch, getState) => {

		let project = getState().projectReducer.project;

		projectService
			.addEventTypes(project.id, event_types)
			.then(event_types => {
				dispatch(eventTypesAction.receiveProjectEventTypes(event_types.data))
			})
			.catch(error => {
				if (error.response.status === 409) {
					dispatch(orphans_actions.receiveItems(error.response.data));
				}
			});
	};
}

function loadInitialData(id) {
	return (dispatch, getState) => {
		if (!getState().eventsReducer.isPending) {

			dispatch(requestEvent());

			let project = getState().projectReducer.project;

			eventService.getEvent(project.id, project.eventsMap.id, id)
			            .then(event_proxy => {
				            dispatch(receiveEvent(event_proxy.data));
				            dispatch(loadDictionariesActionChain());
			            });
		}
	}
}

function loadDictionariesActionChain() {
	return (dispatch, getState) => {

		let project = getState().projectReducer.project;

		dispatch(event_actions.fetchEvents(project.id, project.eventsMap.id))
		dispatch(factorsActions.fetchFactors(project.id, project.eventsMap.id))
		dispatch(flagsActions.fetchFlags());
		dispatch(eventCategoriesAction.fetchEventCategories());
		dispatch(eventTypesAction.fetchEventTypes());
		dispatch(eventSourcesAction.fetchEventSources());
		dispatch(numericUnitsActions.fetchNumericUnits());
	}
}

const EventDialogConnector = connect(mapStateToProps, mapDispatchToProps)(EventDialog);

export default withRouter(EventDialogConnector)