import {authorities} from "../mixins/authorities";
import {withRouter} from "react-router";
import ColorSchemeView from "../views/color-scheme";
import * as colorSchemaActions from "../actions/color-schema";
import {connect} from "react-redux";

const mapStateToProps = (state, ownProps) => {
	return {
		project: state.projectReducer.project,
		colorScheme: state.colorSchemaReducer.schema,
		...authorities(state.authenticationReducer.authentication)
	}
};

const mapDispatchToProps = (dispatch, state) => {
	return {
		loadColorScheme: (project) => {
			dispatch(colorSchemaActions.fetchColorSchema(project.id));
		},
		saveColorScheme: (project, scheme) => {
			dispatch(colorSchemaActions.saveColorScheme(project.id, scheme));
		}
	}
};

const ColorSchemeContainer = connect(mapStateToProps, mapDispatchToProps)(ColorSchemeView);

export default withRouter(ColorSchemeContainer);