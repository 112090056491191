import {projectCategoryService} from "../services";

export const actionTypes = {

	prefix: 'PROJECT_CATEGORY',

	REQUEST_PROJECT_CATEGORIES: 'REQUEST_PROJECT_CATEGORIES',
	RECEIVE_PROJECT_CATEGORIES: 'RECEIVE_PROJECT_CATEGORIES',
	REQUEST_PROJECT_PROJECT_CATEGORIES: 'REQUEST_PROJECT_PROJECT_CATEGORIES',
	RECEIVE_PROJECT_PROJECT_CATEGORIES: 'RECEIVE_PROJECT_PROJECT_CATEGORIES',
	SELECT_PROJECT_CATEGORY: 'SELECT_PROJECT_CATEGORY',
	REQUEST_PROJECT_CATEGORY: 'REQUEST_PROJECT_CATEGORY',
	RECEIVE_PROJECT_CATEGORY: 'RECEIVE_PROJECT_CATEGORY',
	APPEND_ITEM: 'APPEND_ITEM',
	INSERT_ITEM: 'INSERT_ITEM',
	START_OPERATION: 'START_OPERATION',
	END_OPERATION: 'END_OPERATION',

	get: function (key) {
		return this.prefix + '_' + this[key];
	}
};

export const fetchProjectCategories = () => {
	return (dispatch, getState) => {
		if (!getState().projectCategoriesReducer.isPending) {
			dispatch(requestProjectCategories());
			projectCategoryService.getAll()
			                    .then(categories_proxy => {
				                    dispatch(receiveProjectCategories(categories_proxy.data));
			                    })
		}
	}
};

export const requestProjectCategories = () => {
	return {
		type: actionTypes.get(actionTypes.REQUEST_PROJECT_CATEGORIES)
	}
};

export const receiveProjectCategories = (response) => {
	return {
		type: actionTypes.get(actionTypes.RECEIVE_PROJECT_CATEGORIES),
		data: response
	}
};

export const fetchProjectProjectCategories = (projectId) => {
	return (dispatch, getState) => {
		if (!getState().projectCategoriesReducer.isPending) {
			dispatch(requestProjectProjectCategories());
			projectCategoryService.getCategoriesByProject(projectId)
			                    .then(categories_proxy => {
				                    dispatch(receiveProjectProjectCategories(categories_proxy.data));
			                    })
		}
	}
};

export const requestProjectProjectCategories = () => {
	return {
		type: actionTypes.get(actionTypes.REQUEST_PROJECT_PROJECT_CATEGORIES)
	}
};

export const receiveProjectProjectCategories = (response) => {
	return {
		type: actionTypes.get(actionTypes.RECEIVE_PROJECT_PROJECT_CATEGORIES),
		data: response
	}
};

export const selectProjectCategory = (projectCategory) => {
	return {
		type: actionTypes.get(actionTypes.SELECT_PROJECT_CATEGORY),
		data: projectCategory
	}
};

export const fetchProjectCategory = (id) => {
	return (dispatch, getState) => {
		if (!getState().projectCategoriesReducer.isPending) {
			dispatch(requestProjectCategory());
			projectCategoryService.get(id)
			                    .then(category_proxy => {
				                    dispatch(receiveProjectCategory(category_proxy.data));
			                    })
		}
	}
};

export const requestProjectCategory = () => {
	return {
		type: actionTypes.get(actionTypes.REQUEST_PROJECT_CATEGORY)
	}
};

export const receiveProjectCategory = (response) => {
	return {
		type: actionTypes.get(actionTypes.RECEIVE_PROJECT_CATEGORY),
		data: response
	}
};

export const appendProjectCategory = (data) => {
	return {
		type: actionTypes.get(actionTypes.APPEND_ITEM),
		data: data
	}
};

export const insertProjectCategory = (data) => {
	return {
		type: actionTypes.get(actionTypes.INSERT_ITEM),
		data: data
	}
};

export const createProjectCategory = (projectCategory) => {
	return (dispatch, getState) => {

		dispatch(receiveProjectCategory(null));

		projectCategoryService.save(projectCategory)
		                    .then(projectCategory => {
			                    dispatch(fetchProjectCategories());
		                    })
	}
};

export const saveProjectCategory = (projectCategory) => {
	return (dispatch, getState) => {

		dispatch(receiveProjectCategory(null));
		
		projectCategoryService.save(projectCategory)
		                    .then(projectCategory => {
			                    dispatch(fetchProjectCategories());
		                    })
	}
};

export const deleteProjectCategory = (id) => {
	return (dispatch, getState) => {
		projectCategoryService.delete(id)
		                    .then(projectCategory => {
			                    dispatch(fetchProjectCategories());
		                    })
	}
};

export const startOperation = (operation) => {
	return {
		type: actionTypes.get(actionTypes.START_OPERATION),
		data: operation
	}
};

export const endOperation = (operation) => {
	return {
		type: actionTypes.get(actionTypes.END_OPERATION),
		data: operation
	}
};