import {browserHistory} from '../app';
import {CLIENT_URI_CONSTANTS as URI} from '../utils/uri-constants';
import {eventsMapService, factorAttachmentService, factorLinkService, factorService} from "../services";


export const actionTypes = {

	prefix: 'FACTOR',

	REQUEST_FACTORS: 'REQUEST_FACTORS',
	RECEIVE_FACTORS: 'RECEIVE_FACTORS',
	REQUEST_FACTOR: 'REQUEST_FACTOR',
	RECEIVE_FACTOR: 'RECEIVE_FACTOR',
	SELECT_FACTOR: 'SELECT_FACTOR',
	ADD_FACTOR: 'ADD_FACTOR',
	EDIT_FACTOR: 'EDIT_FACTOR',
	SAVE_FACTOR: 'SAVE_FACTOR',
	DELETE_FACTOR: 'DELETE_FACTOR',
	SELECT_LINK: 'SELECT_LINK',
	RECEIVE_LINK: 'RECEIVE_LINK',
	DELETE_LINK: 'DELETE_LINK',
	SELECT_COMMENT: 'SELECT_COMMENT',
	RECEIVE_COMMENT: 'RECEIVE_COMMENT',
	DELETE_COMMENT: 'DELETE_COMMENT',
	SELECT_ATTACHMENT: 'SELECT_ATTACHMENT',
	RECEIVE_ATTACHMENT: 'RECEIVE_ATTACHMENT',
	DELETE_ATTACHMENT: 'DELETE_ATTACHMENT',
	REQUEST_JOURNAL_ENTRIES: 'REQUEST_JOURNAL_ENTRIES',
	RECEIVE_JOURNAL_ENTRIES: 'RECEIVE_JOURNAL_ENTRIES',
	SELECT_JOURNAL_ENTRY: 'SELECT_JOURNAL_ENTRY',
	START_OPERATION: 'START_OPERATION',
	END_OPERATION: 'END_OPERATION',

	get: function (key) {
		return this.prefix + '_' + this[key];
	}
};

export const startOperation = (operation) => {
	return {
		type: actionTypes.get(actionTypes.START_OPERATION),
		data: operation
	}
};

export const endOperation = (operation) => {
	return {
		type: actionTypes.get(actionTypes.END_OPERATION),
		data: operation
	}
};

export const fetchFactors = (projectId, futureMapId) => {
	return (dispatch, getState) => {
		if (!getState().factorReducer.isPending) {
			dispatch(requestFactors());
			eventsMapService.getFactors(projectId, futureMapId)
			                .then(factors_proxy => {
				                dispatch(receiveFactors(factors_proxy.data));
			                });
		}
	}
};

export const requestFactors = () => {
	return {
		type: actionTypes.get('REQUEST_FACTORS')
	}
};

export const fetchFactor = (projectId, futureMapId, factorId) => {
	return (dispatch, getState) => {
		if (!getState().factorReducer.isPending) {
			dispatch(requestFactor());
			factorService.get(projectId, futureMapId, factorId)
			             .then(factor_proxy => {
				             dispatch(receiveFactor(factor_proxy.data));
			             });
		}
	}
};

export const requestFactor = (projectId, futureMapId, factorId) => {
	return {
		type: actionTypes.get('REQUEST_FACTOR')
	}
};

export const receiveFactor = (response) => {
	return {
		type: actionTypes.get('RECEIVE_FACTOR'),
		data: response
	}
};

export const receiveFactors = (response) => {
	return {
		type: actionTypes.get('RECEIVE_FACTORS'),
		data: response
	}
};

export const selectFactor = (factor) => {
	return {
		type: actionTypes.get('SELECT_FACTOR'),
		data: factor
	}
};

export const addFactor = (project) => {
	return (dispatch, getState) => {
		dispatch(receiveFactor(null));
		browserHistory.push(`/${URI.PROJECT}/${project.id}/${URI.FACTOR}/0`);
	};
};

export const editFactor = (factor) => {
	return {
		type: actionTypes.get('EDIT_FACTOR'),
		data: factor
	}
};

export const createFactor = (projectId, futureMapId, factor) => {
	return (dispatch, getState) => {
		dispatch(startOperation({name: 'CREATION'}));
		factorService.create(projectId, futureMapId, factor)
		             .then(factor_proxy => {
			             dispatch(receiveFactor(factor_proxy.data));
			             dispatch(fetchFactors(projectId, futureMapId));
			             dispatch(endOperation({
				                                   name: 'CREATION',
				                                   status: "SUCCESS"
			                                   }));
		             })
		             .catch(function (error) {
			             dispatch(endOperation({
				                                   name: 'CREATION',
				                                   status: "ERROR"
			                                   }));
		             });
	};
};

export const saveFactor = (projectId, futureMapId, factor) => {
	return (dispatch, getState) => {
		dispatch(startOperation({name: 'SAVING'}));
		factorService.save(projectId, futureMapId, factor)
		             .then(factor_proxy => {
			             dispatch(receiveFactor(factor_proxy.data));
			             dispatch(fetchFactors(projectId, futureMapId));
			             dispatch(endOperation({
				                                   name: 'SAVING',
				                                   status: "SUCCESS"
			                                   }));
		             })
		             .catch(function (error) {
			             dispatch(endOperation({
				                                   name: 'SAVING',
				                                   status: "ERROR"
			                                   }));
		             });
	}
};

export const deleteFactor = (projectId, futureMapId, factorId) => {
	return (dispatch, getState) => {
		factorService.remove(projectId, futureMapId, factorId)
		             .then(factorId => {
			             dispatch(fetchFactors(projectId, futureMapId));
		             });
	}
};

export const copyFactor = (projectId, futureMapId, factorId, withEvents, parentFactorId) => {
	return (dispatch, getState) => {
		factorService.copy(projectId, futureMapId, factorId, withEvents, parentFactorId)
		             .then(factor_proxy => {
			             dispatch(fetchFactors(projectId, futureMapId));
		             });
	}
};

export function fetchLink(projectId, eventsMapId, factorId, linkId) {
	return (dispatch, getState) => {
		factorLinkService.get(projectId, eventsMapId, factorId, linkId)
		                 .then(link_proxy => {
			                 dispatch(receiveLink(link_proxy.data))
		                 });
	}
}

export function receiveLink(link) {
	return {
		type: actionTypes.get('RECEIVE_LINK'),
		data: link
	}
}

export const selectLink = (link) => {
	return {
		type: actionTypes.get('SELECT_LINK'),
		data: link
	}
};

export const createLink = (projectId, eventsMapId, factorId, link) => {
	return (dispatch, getState) => {
		factorLinkService.create(projectId, eventsMapId, factorId, link)
		                 .then(link_proxy => {
			                 dispatch(fetchFactor(projectId, eventsMapId, factorId));
		                 });
	}
};

export const saveLink = (projectId, eventsMapId, factorId, link) => {
	return (dispatch, getState) => {
		factorLinkService.save(projectId, eventsMapId, factorId, link)
		                 .then(link_proxy => {
			                 dispatch(fetchFactor(projectId, eventsMapId, factorId));
		                 });
	}
};

export const deleteLink = (projectId, eventsMapId, factorId, linkId) => {
	return (dispatch, getState) => {
		factorLinkService.delete(projectId, eventsMapId, factorId, linkId)
		                 .then(() => {
			                 dispatch(fetchFactor(projectId, eventsMapId, factorId));
		                 });
	}
};

export function fetchAttachment(projectId, eventsMapId, factorId, attachmentId) {
	return (dispatch, getState) => {
		factorAttachmentService.get(projectId, eventsMapId, factorId, attachmentId)
		                       .then(attachment_proxy => {
			                       dispatch(receiveAttachment(attachment_proxy.data))
		                       });
	}
}

export function receiveAttachment(attachment) {
	return {
		type: actionTypes.get('RECEIVE_ATTACHMENT'),
		data: attachment
	}
}

export const selectAttachment = (attachment) => {
	return {
		type: actionTypes.get('SELECT_ATTACHMENT'),
		data: attachment
	}
};

export const createAttachment = (projectId, eventsMapId, factorId, attachment) => {
	return (dispatch, getState) => {
		factorAttachmentService.create(projectId, eventsMapId, factorId, attachment)
		                       .then(attachment_proxy => {
			                       dispatch(fetchFactor(projectId, eventsMapId, factorId));
		                       });
	}
};

export const saveAttachment = (projectId, eventsMapId, factorId, attachment) => {
	return (dispatch, getState) => {
		factorAttachmentService.save(projectId, eventsMapId, factorId, attachment)
		                       .then(attachment_proxy => {
			                       dispatch(fetchFactor(projectId, eventsMapId, factorId));
		                       });
	}
};

export const deleteAttachment = (projectId, eventsMapId, factorId, attachmentId) => {
	return (dispatch, getState) => {
		factorAttachmentService.delete(projectId, eventsMapId, factorId, attachmentId)
		                       .then(() => {
			                       dispatch(fetchFactor(projectId, eventsMapId, factorId));
		                       });
	}
};

export const requestJournalEntries = () => {
	return {
		type: actionTypes.get('REQUEST_JOURNAL_ENTRIES'),
	}
};

export const receiveJournalEntries = (response) => {
	return {
		type: actionTypes.get('RECEIVE_JOURNAL_ENTRIES'),
		data: response
	}
};

export const fetchJournalEntries = (projectId, futureMapId, factorId) => {
	return (dispatch, getState) => {
		if (!getState().factorReducer.isJournalEntriesPending) {
			dispatch(requestJournalEntries(projectId, futureMapId, factorId));
			factorService.getJournalEntries(projectId, futureMapId, factorId)
			             .then(entries_proxy => {
				             dispatch(receiveJournalEntries(entries_proxy.data));
			             });
		}
	}
};

export const selectJournalEntry = (entry) => {
	return {
		type: actionTypes.get('SELECT_JOURNAL_ENTRY'),
		data: entry
	};
};