import {LevelOfCopy} from "../project/project-models";
import BaseForm from "../base/base-form";
import Utils from "../../utils/utils";
import React from "react";
import {Button, Col, ControlLabel, Form, Grid, Modal, Row} from "react-bootstrap";
import {Combobox} from "react-widgets";

class ProjectCopyLevelDialog extends BaseForm {

    constructor(props) {
        super(props);

        this.state = {
            level: LevelOfCopy.FULL
        };
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.handleCancelButton}>
                <Modal.Header closeButton>
                    <Modal.Title>Level Of Copy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form horizontal={true}>
                        <Grid fluid={true}>
                            <Row>
                                <Col md={2}>
                                    <ControlLabel>Level:</ControlLabel>
                                </Col>
                                <Col md={10}>
                                    <Combobox onChange={this.setValue.bind(this, 'level')}
                                              defaultValue={this.state.level ? this.state.level : ''}
                                              data={LevelOfCopy.enumValues}
                                              caseSensitive={false}
                                              textField='text'
                                              value={this.state.level}
                                    />
                                </Col>
                            </Row>
                        </Grid>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button bsClass="btn pull-right action-button" onClick={this.handleCancelButton}>Cancel</Button>
                    <Button bsClass="btn pull-right action-button" onClick={this.handleCopyButton}>Copy</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    handleCopyButton() {
        this.props.onCopy(this.state.level);
    }

    handleCancelButton() {
        this.props.onCancel();
    }
}

export default ProjectCopyLevelDialog