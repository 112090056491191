import React from 'react';
import {Button, Glyphicon} from "react-bootstrap";

class MiniMap
	extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			svgWidth: 1,
			svgHeight: 1,
			viewportX: 0,
			viewportY: 0,
			max_length: 1,
			coordinates_map: {},
		}
	}

	componentDidMount() {

		if (this.svgRef) {
			this.setState({
				              svgWidth: this.svgRef.clientWidth,
				              svgHeight: this.svgRef.clientHeight
			              });
		}

		let map = null;
		let maxLength = 1;

		const __ret = this.calculateCoordinates();

		map = __ret.map;
		maxLength = __ret.maxLength;

		this.setState({
			              coordinates_map: map,
			              max_length: maxLength,
			              viewportX: this.props.viewportX != null ? this.props.viewportX : this.state.viewportX,
			              viewportY: this.props.viewportY != null ? this.props.viewportY : this.state.viewportY,
		              })
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (prevProps.nodes !== this.props.nodes) {

			let map = null;
			let maxLength = 1;

			const __ret = this.calculateCoordinates();

			map = __ret.map;
			maxLength = __ret.maxLength;

			this.setState({
				              coordinates_map: map,
				              max_length: maxLength
			              })
		}

		if (prevProps.viewportX !== this.props.viewportX || prevProps.viewportY !== this.props.viewportY) {
			this.setState({
				              viewportX: this.props.viewportX != null ? this.props.viewportX : this.state.viewportX,
				              viewportY: this.props.viewportY != null ? this.props.viewportY : this.state.viewportY,
			              });
		}
	}

	calculateCoordinates() {

		let map = this.props.nodes.reduce((accumulator, element) => {

			if (element) {

				const x = element.x;

				const y = element.y;

				if (accumulator[this.mapKeyFromCoordinates(x, y)]) {
					accumulator[x + "-" + y].push(element);
				} else {
					accumulator[x + "-" + y] = [element];
				}
			}

			return accumulator;
		}, {});

		let maxLength = Object.keys(map)
		                      .reduce((accumulator, key) => {
			                      return map[key].length > accumulator ? map[key].length : accumulator
		                      }, 1);
		return {
			map,
			maxLength
		};
	}

	render() {
		return (
			<div className="mini-map">
				{
					this.props.onHide &&
					<Button className="toggle-mini-map" onClick={this.props.onHide}>
						<Glyphicon glyph="collapse-down"/>
					</Button>
				}
				<svg ref={
					(element) => {
						this.svgRef = element;
					}
				}
				     onClick={this.handleCanvasClick}
				     onMouseUp={
					     (e) => {
						     this.mouseDown = false;
					     }
				     }
				     onMouseDown={
					     (e) => {
						     this.mouseDown = true;
					     }
				     }
				     onMouseMove={this.handleDrag}
				>
					<defs>
						{
							this.state.coordinates_map && Object.keys(this.state.coordinates_map)
							                                    .map((key) => {

								                                    const nodes = this.state.coordinates_map[key];

								                                    const maxImpactForCoordinate = nodes.reduce((accumultaror, node) => {
									                                    return node.impact > accumultaror ? node.impact : accumultaror
								                                    }, 1);

								                                    return (
									                                    <radialGradient id={key} key={key}>
										                                    <stop offset="0%" stopColor="red" stopOpacity={((maxImpactForCoordinate + 1) / 12)}/>
										                                    <stop offset="100%" stopColor="red" stopOpacity="0.05"/>
									                                    </radialGradient>
								                                    )
							                                    })
						}
					</defs>
					{
						this.state.coordinates_map && Object.keys(this.state.coordinates_map)
						                                    .map((key) => {

							                                    const coordinates = this.coordinatesFromMapKey(key);

							                                    return (
								                                    <circle id={key}
								                                            key={key}
								                                            fill={'url(#' + key + ')'}
								                                            cx={this.translateToInnerXCoordinate(coordinates.x)}
								                                            cy={this.translateToInnerYCoordinate(coordinates.y)}
								                                            r={(16 * this.state.coordinates_map[key].length / this.state.max_length) + 1}/>
							                                    )
						                                    })
					}
					<rect id='mini-map-viewport'
					      x={this.translateToInnerXCoordinate(this.state.viewportX)}
					      y={this.translateToInnerYCoordinate(this.state.viewportY)}
					      width={this.props.viewportWidth * this.state.svgWidth / this.props.width}
					      height={this.props.viewportHeight * this.state.svgHeight / this.props.height}
					      style={
						      {
							      fill: "rgb(0,0,255)",
							      fillOpacity: 0.1,
							      strokeWidth: 0.1,
							      stroke: "rgb(0,0,0)"
						      }
					      }
					/>
				</svg>
			</div>
		)
	}

	translateToInnerXCoordinate = x => Math.round(x * this.state.svgWidth / this.props.width);

	translateToInnerYCoordinate = y => Math.round(y * this.state.svgHeight / this.props.height);

	translateToOuterXCoordinate = x => x * this.props.width / this.state.svgWidth;

	translateToOuterYCoordinate = y => y * this.props.height / this.state.svgHeight;

	handleCanvasClick = (e) => {
		if (this.props.onMoveTo) {
			this.props.onMoveTo(Math.round(this.translateToOuterXCoordinate(e.nativeEvent.offsetX)), Math.round(this.translateToOuterYCoordinate(e.nativeEvent.offsetY)));
		} else {
			this.setState({
				              viewportX: e.nativeEvent.offsetX,
				              viewportY: e.nativeEvent.offsetY
			              });
		}
	};

	handleDrag = (e) => {
		if (this.mouseDown) {
			this.handleCanvasClick(e);
		}
	};

	mapKeyFromCoordinates = (x, y) => {
		return x + '-' + y;
	};

	coordinatesFromMapKey = (key) => {

		const coordinates = key.split('-');

		return {
			x: parseInt(coordinates[0]),
			y: parseInt(coordinates[1])
		};
	}
}

export default MiniMap;