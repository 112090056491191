import {API} from "../api/api";
import clientFactory from "../factories/http_client";

class TokenService {

	/** @return  Promise<AxiosResponse<T>> */
	getCsrfToken = (realm) => {
		return clientFactory.client(realm)
		                    .request({
			                             method: API[realm].csrf.type,
			                             url: API[realm].csrf.url
		                             })
	};

	/** @return  Promise<AxiosResponse<T>> */
	getOAuthToken = (client_id, client_secret, redirect_uri, code) => {
		return clientFactory.client(API.auth.scope)
		                    .request({
			                             method: API.auth.oauth2.token.method,
			                             url: API.auth.oauth2.token.url,
			                             data: API.auth.oauth2.token.data(client_id, redirect_uri, code),
			                             headers:
				                             {
					                             'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
					                             'Authorization': 'Basic ' + btoa(client_id + ":" + client_secret)
				                             }
		                             })
	};

	refreshOAuthToken = (client_id, client_secret, refresh_token) => {
		return clientFactory.client(API.auth.scope)
		                    .request({
			                             method: API.auth.oauth2.refresh.method,
			                             url: API.auth.oauth2.refresh.url,
			                             data: API.auth.oauth2.refresh.data(client_id, client_secret, refresh_token),
			                             headers:
				                             {
					                             'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
					                             'Authorization': 'Basic ' + btoa(client_id + ":" + client_secret)
				                             }
		                             })
	};
}

export default TokenService;