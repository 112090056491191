
export const actionTypes = {
    STORE: 'STORE',
    CLEAR: 'CLEAR'
};

export const store = (data) => {
    return {
        type: actionTypes.STORE,
        data: data
    }
};

export const clear = () => {
    return {
        type: actionTypes.CLEAR
    }
};