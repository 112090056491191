import {API} from "../api/api";
import clientFactory from "../factories/http_client";

class ProjectCommentService {

	/** @return  Promise<AxiosResponse<T>> */
	get = (projectId, commentId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.comments.id.method,
			                             url: API.data.projects.id.comments.id.url(projectId, commentId)
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	create = (projectId, comment) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.comments.create.method,
			                             url: API.data.projects.id.comments.create.url(projectId),
			                             data: API.data.projects.id.comments.create.build(comment),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	save = (projectId, comment) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.comments.save.method,
			                             url: API.data.projects.id.comments.save.url(projectId),
			                             data: API.data.projects.id.comments.save.build(comment),
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	delete = (projectId, commentId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.comments.delete.method,
			                             url: API.data.projects.id.comments.delete.url(projectId),
			                             data: API.data.projects.id.comments.delete.build(commentId),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};
}

export default ProjectCommentService