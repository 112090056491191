import clientFactory from "../factories/http_client";
import {API} from "../api/api";

class FactorLinkService {

	/** @return  Promise<AxiosResponse<T>> */
	create = (projectId, futureMapId, factorId, link) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.factors.id.links.create.method,
			                             url: API.data.projects.id.event_maps.id.factors.id.links.create.url(projectId, futureMapId, factorId),
			                             data: API.data.projects.id.event_maps.id.factors.id.links.create.build(link),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	save = (projectId, futureMapId, factorId, link) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.factors.id.links.save.method,
			                             url: API.data.projects.id.event_maps.id.factors.id.links.save.url(projectId, futureMapId, factorId),
			                             data: API.data.projects.id.event_maps.id.factors.id.links.save.build(link),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	delete = (projectId, futureMapId, factorId, linkId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.factors.id.links.delete.method,
			                             url: API.data.projects.id.event_maps.id.factors.id.links.delete.url(projectId, futureMapId, factorId),
			                             data: API.data.projects.id.event_maps.id.factors.id.links.delete.build(linkId),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	get = (projectId, futureMapId, factorId, linkId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.factors.id.links.id.method,
			                             url: API.data.projects.id.event_maps.id.factors.id.links.id.url(projectId, futureMapId, factorId),
		                             });
	};
}

export default FactorLinkService