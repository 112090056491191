import {actionTypes} from "../actions/numeric-units";

const initialState = {
	isPending: false,
	invalidated: false,
	numericUnits: null,
	numericUnit: null,
	selectedNumericUnit: null,
	projectNumericUnits: null,
	projectNumericUnit: null,
	projectSelectedNumericUnit: null,
	operation: null
};

function numericUnitsReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.get(actionTypes.REQUEST_NUMERIC_UNITS):
			return {
				...state,
				isPending: true
			};
		case actionTypes.get(actionTypes.REQUEST_PROJECT_NUMERIC_UNITS):
			return {
				...state,
				isPending: true
			};
		case actionTypes.get(actionTypes.RECEIVE_NUMERIC_UNITS):
			return {
				...state,
				isPending: false,
				numericUnits: action.data
			};
		case actionTypes.get(actionTypes.RECEIVE_PROJECT_NUMERIC_UNITS):
			return {
				...state,
				isPending: false,
				projectNumericUnits: action.data
			};
		case actionTypes.get(actionTypes.SELECT_NUMERIC_UNIT):
			return {
				...state,
				selectedNumericUnit: action.data,
			};
		case actionTypes.get(actionTypes.REQUEST_NUMERIC_UNIT):
			return {
				...state,
				isPending: true
			};
		case actionTypes.get(actionTypes.RECEIVE_NUMERIC_UNIT):
			return {
				...state,
				isPending: false,
				numericUnit: action.data
			};
		case actionTypes.get(actionTypes.APPEND_ITEM):

			let numericUnits = state.numericUnits;

			return {
				...state,
				isPending: false,
				numericUnits: numericUnits && Array.isArray(numericUnits) ? numericUnits.concat([action.data]) : [].concat([action.data])
			};
		case actionTypes.get(actionTypes.INSERT_ITEM):

			let index = state.numericUnits.findIndex((item) => {
				return item.id === action.data.id;
			});

			if (index !== -1) {
				state.numericUnits.splice(index, 1, action.data)
			} else {
				state.numericUnits.push(action.data);
			}

			return {
				...state,
				isPending: false,
				numericUnits: [...state.numericUnits]
			};
		case actionTypes.get(actionTypes.START_OPERATION):
			return {
				...state,
				operation: action.data
			};
		case actionTypes.get(actionTypes.END_OPERATION):
			return {
				...state,
				operation: action.data
			};
		default:
			return state
	}
}

export default numericUnitsReducer