import {actionTypes} from "../actions/project-categories";

const initialState = {
	isPending: false,
	invalidated: false,
	projectCategories: null,
	projectCategory: null,
	selectedProjectCategory: null,
	projectProjectCategories: null,
	projectProjectCategory: null,
	projectSelectedProjectCategory: null,
	operation: null
};

function projectCategoriesReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.get(actionTypes.REQUEST_PROJECT_CATEGORIES):
			return {
				...state,
				isPending: true
			};
		case actionTypes.get(actionTypes.REQUEST_PROJECT_PROJECT_CATEGORIES):
			return {
				...state,
				isPending: true
			};
		case actionTypes.get(actionTypes.RECEIVE_PROJECT_CATEGORIES):
			return {
				...state,
				isPending: false,
				projectCategories: action.data
			};
		case actionTypes.get(actionTypes.RECEIVE_PROJECT_PROJECT_CATEGORIES):
			return {
				...state,
				isPending: false,
				projectProjectCategories: action.data
			};
		case actionTypes.get(actionTypes.SELECT_PROJECT_CATEGORY):
			return {
				...state,
				selectedProjectCategory: action.data,
			};
		case actionTypes.get(actionTypes.REQUEST_PROJECT_CATEGORY):
			return {
				...state,
				isPending: true
			};
		case actionTypes.get(actionTypes.RECEIVE_PROJECT_CATEGORY):
			return {
				...state,
				isPending: false,
				projectCategory: action.data
			};
		case actionTypes.get(actionTypes.APPEND_ITEM):

			let projectCategories = state.projectCategories;

			return {
				...state,
				isPending: false,
				projectCategories: projectCategories && Array.isArray(projectCategories) ? projectCategories.concat([action.data]) : [].concat([action.data])
			};
		case actionTypes.get(actionTypes.INSERT_ITEM):

			let index = state.projectCategories.findIndex((item) => {
				return item.id === action.data.id;
			});

			if (index !== -1) {
				state.projectCategories.splice(index, 1, action.data)
			} else {
				state.projectCategories.push(action.data);
			}

			return {
				...state,
				isPending: false,
				projectCategories: [...state.projectCategories]
			};
		case actionTypes.get(actionTypes.START_OPERATION):
			return {
				...state,
				operation: action.data
			};
		case actionTypes.get(actionTypes.END_OPERATION):
			return {
				...state,
				operation: action.data
			};
		default:
			return state
	}
}

export default projectCategoriesReducer