import {API} from "../api/api";
import clientFactory from "../factories/http_client";


class FlagService {

	/** @return  Promise<AxiosResponse<T>> */
	getFlags = () => {
		return clientFactory.client(API.data.scope).request({
			                             method: API.data.flags.method,
			                             url: API.data.flags.url
		                             });
	};
}

export default FlagService;