import React from 'react';
import {Button, Col, FormGroup, Grid, Row} from "react-bootstrap";
import ReactScrollableList from "react-scrollable-list";
import BaseForm from "../../base/base-form";

class KeywordsTab extends BaseForm {

    constructor(props) {
        super(props);

        this.state = {
            keywords: this.props.keywords ? this.props.keywords : '',
        }
    }

    render() {
        return (
            <Grid id="keywords-tab-component" fluid={true} className="w-50 h-100 ml-0 tab-content keywords-tab">
                <Row bsClass="row c-row">
                    <Col md={12} className="c-col">
                        <label>List the keywords each on a new line</label>
                    </Col>
                </Row>
                <Row bsClass="row c-row">
                    <Col md={12} className="c-col">
                            <textarea className="form-control"
                                      rows="10"
                                      value={this.state.keywords ? this.state.keywords : ""}
                                      onChange={e => {
                                          this.setState({keywords: e.target.value},
                                              () => this.props.handleTextAreaChanged(e, this.state.keywords))
                                      }}/>
                    </Col>
                </Row>
            </Grid>
        );
    }
}

export default KeywordsTab;