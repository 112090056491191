import {browserHistory} from '../app';
import {CLIENT_URI_CONSTANTS as URI} from '../utils/uri-constants';
import {eventAttachmentService, eventLinkService, eventService, eventsMapService} from "../services";
import {MULTIPART_POST_CONSTANTS as MPC} from "../utils/request-parameters-constants";
import {receiveReport} from "./operations";

export const actionTypes = {

	prefix: 'EVENT',

	REQUEST_EVENTS: 'REQUEST_EVENTS',
	RECEIVE_EVENTS: 'RECEIVE_EVENTS',
	REQUEST_EVENT: 'REQUEST_EVENT',
	RECEIVE_EVENT: 'RECEIVE_EVENT',
	SELECT_EVENTS: 'SELECT_EVENTS',
	ADD_EVENT: 'ADD_EVENT',
	EDIT_EVENT: 'EDIT_EVENT',
	SAVE_EVENT: 'SAVE_EVENT',
	DELETE_EVENT: 'DELETE_EVENT',
	SELECT_LINK: 'SELECT_LINK',
	RECEIVE_LINK: 'RECEIVE_LINK',
	DELETE_LINK: 'DELETE_LINK',
	SELECT_COMMENT: 'SELECT_COMMENT',
	RECEIVE_COMMENT: 'RECEIVE_COMMENT',
	DELETE_COMMENT: 'DELETE_COMMENT',
	SELECT_ATTACHMENT: 'SELECT_ATTACHMENT',
	RECEIVE_ATTACHMENT: 'RECEIVE_ATTACHMENT',
	DELETE_ATTACHMENT: 'DELETE_ATTACHMENT',
	APPEND_EVENT: 'APPEND_EVENT',
	INSERT_EVENT: 'INSERT_EVENT',
	START_OPERATION: 'START_OPERATION',
	END_OPERATION: 'END_OPERATION',

	get: function (key) {
		return this.prefix + '_' + this[key];
	}
};

export const requestEvents = () => {
	return {
		type: actionTypes.get('REQUEST_EVENTS')
	}
};

export const fetchEvents = (projectId, futureMapId, filter) => {
	return (dispatch, getState) => {
		if (!getState().eventsReducer.isPending) {

			dispatch(requestEvents());

			eventsMapService.getCompactEvents(
				projectId,
				futureMapId,
				filter,
				{
					[MPC.EVENT_FIELDS]: [
						'title',
						'date',
						'future_map_id',
						'create_date',
						'factor',
						'average_impact',
						'average_probability',
						'comments',
						'flag',
						'source_relations',
						'target_relations',
						'type',
						'state',
						'desired',
						'blocked',
						'relative_probability'
					],
					[MPC.FACTOR_FIELDS]: [
						'title',
						'create_date'
					]
				}
			)
			                .then(events_proxy => {
				                dispatch(receiveEvents(events_proxy.data));
			                });
		}
	}
};

export const fetchEvent = (projectId, futureMapId, eventId) => {
	return (dispatch, getState) => {
		if (!getState().eventsReducer.isPending) {
			dispatch(requestEvent());
			eventService.getEvent(projectId, futureMapId, eventId)
			            .then(event_proxy => {
				            dispatch(receiveEvent(event_proxy.data));
			            });
		}
	}
};

export const requestEvent = () => {
	return {
		type: actionTypes.get('REQUEST_EVENT')
	}
};

export const receiveEvent = (response) => {
	return {
		type: actionTypes.get('RECEIVE_EVENT'),
		data: response
	}
};

export function insertEvent(event) {
	return {
		type: actionTypes.get(actionTypes.INSERT_EVENT),
		data: event
	};
}

export const appendEvent = (response) => {
	return {
		type: actionTypes.get('APPEND_EVENT'),
		data: response
	}
};

export const receiveEvents = (response) => {
	return {
		type: actionTypes.get('RECEIVE_EVENTS'),
		data: response
	}
};

export const selectEvents = (events) => {
	return {
		type: actionTypes.get('SELECT_EVENTS'),
		data: events
	}
};

export const addEvent = (project, eventTemplate) => {
	return (dispatch, getState) => {
		dispatch(receiveEvent(eventTemplate ? eventTemplate : null));
		browserHistory.push(`/${URI.PROJECT}/${project.id}/${URI.EVENT}/0`);
	};
};

export const editEvent = (project, event) => {
	return (dispatch, getState) => {
		dispatch(receiveEvent(null));
		browserHistory.push(`/${URI.PROJECT}/${project.id}/${URI.EVENT}/${event.id}`);
	};
};

export const createEvent = (projectId, futureMapId, event) => {
	return (dispatch, getState) => {
		eventService.create(projectId, futureMapId, event)
		            .then(event_proxy => {
			            dispatch(receiveEvents(null));
			            dispatch(receiveEvent(event_proxy.data));
			            dispatch(endOperation({
				                                  name: 'CREATION',
				                                  status: "SUCCESS"
			                                  }));
			            browserHistory.push(`/${URI.PROJECT}/${projectId}/${URI.EVENT}/${event_proxy.data.id}`)
		            })
		            .catch(error => {
			            dispatch(endOperation({
				                                  name: 'SAVING',
				                                  status: "ERROR"
			                                  }));
		            });
	};
};

export function saveEvent(projectId, futureMapId, event) {
	return (dispatch, getState) => {
		eventService.save(projectId, futureMapId, event)
		            .then(event_proxy => {
			            dispatch(receiveEvent(event_proxy.data));
			            dispatch(endOperation({
				                                  name: 'SAVING',
				                                  status: "SUCCESS"
			                                  }));
		            })
		            .catch(error => {
			            dispatch(endOperation({
				                                  name: 'SAVING',
				                                  status: "ERROR"
			                                  }));
		            });
	}
}

export function deleteEvent(projectId, futureMapId, eventId) {
	return (dispatch, getState) => {
		eventService.remove(projectId, futureMapId, eventId)
		            .then(response_proxy => {
			            dispatch(fetchEvents(projectId, futureMapId, null));
		            });
	}
}

export function fetchLink(projectId, eventsMapId, factorId, linkId) {
	return (dispatch, getState) => {
		eventLinkService.get(projectId, eventsMapId, factorId, linkId)
		                .then(link_proxy => {
			                dispatch(receiveLink(link_proxy.data))
		                });
	}
}

export function receiveLink(link) {
	return {
		type: actionTypes.get('RECEIVE_LINK'),
		data: link
	}
}

export const selectLink = (link) => {
	return {
		type: actionTypes.get('SELECT_LINK'),
		data: link
	}
};

export const createLink = (projectId, eventsMapId, eventId, link) => {
	return (dispatch, getState) => {
		eventLinkService.create(projectId, eventsMapId, eventId, link)
		                .then(link_proxy => {
			                dispatch(fetchEvent(projectId, eventsMapId, eventId));
		                });
	}
};

export const saveLink = (projectId, eventsMapId, eventId, link) => {
	return (dispatch, getState) => {
		eventLinkService.save(projectId, eventsMapId, eventId, link)
		                .then(link_proxy => {
			                dispatch(fetchEvent(projectId, eventsMapId, eventId));
		                });
	}
};

export const deleteLink = (projectId, eventsMapId, eventId, linkId) => {
	return (dispatch, getState) => {
		eventLinkService.delete(projectId, eventsMapId, eventId, linkId)
		                .then(() => {
			                dispatch(fetchEvent(projectId, eventsMapId, eventId));
		                });
	}
};

export function fetchAttachment(projectId, eventsMapId, eventId, attachmentId) {
	return (dispatch, getState) => {
		eventAttachmentService.get(projectId, eventsMapId, eventId, attachmentId)
		                      .then(attachment_proxy => {
			                      dispatch(receiveAttachment(attachment_proxy.data))
		                      });
	}
}

export function receiveAttachment(attachment) {
	return {
		type: actionTypes.get('RECEIVE_ATTACHMENT'),
		data: attachment
	}
}

export const selectAttachment = (attachment) => {
	return {
		type: actionTypes.get('SELECT_ATTACHMENT'),
		data: attachment
	}
};

export const createAttachment = (projectId, eventsMapId, eventId, attachment) => {
	return (dispatch, getState) => {
		eventAttachmentService.create(projectId, eventsMapId, eventId, attachment)
		                      .then(attachment_proxy => {
			                      dispatch(fetchEvent(projectId, eventsMapId, eventId));
		                      });
	}
};

export const saveAttachment = (projectId, eventsMapId, eventId, attachment) => {
	return (dispatch, getState) => {
		eventAttachmentService.save(projectId, eventsMapId, eventId, attachment)
		                      .then(attachment_proxy => {
			                      dispatch(fetchEvent(projectId, eventsMapId, eventId));
		                      });
	}
};

export const deleteAttachment = (projectId, eventsMapId, eventId, attachmentId) => {
	return (dispatch, getState) => {
		eventAttachmentService.delete(projectId, eventsMapId, eventId, attachmentId)
		                      .then(() => {
			                      dispatch(fetchEvent(projectId, eventsMapId, eventId));
		                      });
	}
};

export const importEvents = (projectId, eventsMapId, attachment) => {
	return (dispatch, getState) => {
		eventService.importEvents(projectId, eventsMapId, attachment)
		            .then((report_proxy) => {
			            dispatch(fetchEvents(projectId, eventsMapId, null));
			            dispatch(receiveReport(report_proxy.data));
		            });
	}
};

export const createImage = (projectId, eventsMapId, eventId, image) => {
	return (dispatch, getState) => {
		eventAttachmentService.createImage(projectId, eventsMapId, eventId, image)
		                      .then(image => {
			                      dispatch(fetchEvent(projectId, eventsMapId, eventId));
		                      });
	}
};

export const deleteImage = (projectId, eventsMapId, eventId, imageId) => {
	return (dispatch, getState) => {
		eventAttachmentService.deleteImage(projectId, eventsMapId, eventId, imageId)
		                      .then(() => {
			                      dispatch(fetchEvent(projectId, eventsMapId, eventId));
		                      });
	}
};

export const startOperation = (operation) => {
	return {
		type: actionTypes.get(actionTypes.START_OPERATION),
		data: operation
	}
};

export const endOperation = (operation) => {
	return {
		type: actionTypes.get(actionTypes.END_OPERATION),
		data: operation
	}
};