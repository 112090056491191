import {connect} from "react-redux";
import {withRouter} from "react-router";
import ProjectView from "../views/project";
import * as projectsActions from "../actions/projects";
import {authorities} from "../mixins/authorities";
import {notificationService, projectService} from "../services";
import * as project_actions from "../actions/projects";
import * as notifications_actions from "../actions/notifications";
import * as eventSourcesActions from "../actions/event-sources";
import * as eventTypesActions from "../actions/event-types";
import * as eventCategoriesActions from "../actions/event-categories";

const mapStateToProps = (state, ownProps) => {
	return {
		project: state.projectReducer.project,
		notifications: state.notifications.items,
		notificationsFilter: state.notifications.filter,
		...authorities(state.authenticationReducer.authentication)
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		loadInitialData: (id) => {
			dispatch(loadInitialData(id))
		},
		createEmptyProject: () => {
			dispatch(notifications_actions.receiveFilter({}));
			dispatch(notifications_actions.receiveItems([]));
			dispatch(projectsActions.receiveProject({}))
		},
		loadNotifications: (page_index, page_size) => {
			dispatch(loadNotifications(page_index, page_size))
		},
		appendNotifications: (page_index, page_size) => {
			dispatch(appendNotifications(page_index, page_size))
		},
		filterNotifications: (filter_data) => {
			dispatch(filterNotifications({
				                             period_code: filter_data.period.code
			                             }))
		}
	}
};

const periodCodeToDates = (period_code) => {

	let now = new Date();

	let end_date = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999));

	let start_date;
	switch (period_code) {
		case 0:
			start_date = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
			break;
		case 1:
			now.setDate(now.getDate() - 3);
			start_date = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
			break;
		case 2:
			now.setDate(now.getDate() - 7);
			start_date = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
			break;
		case 3:
			now.setMonth(now.getMonth() - 1);
			start_date = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
			break;
		case 4:
			end_date = null
			start_date = null
			break;
	}

	return {
		start_date: start_date,
		end_date: end_date,
	}
}

const loadInitialData = (id) => {
	return (dispatch, getState) => {
		dispatch(notifications_actions.receiveItems([]))
		projectService.getById(id)
		              .then(project_proxy => {

			              dispatch(project_actions.receiveProject(project_proxy.data));

			              let project = getState().projectReducer.project;

			              dispatch(eventSourcesActions.fetchEventSources(project.id));
			              dispatch(eventTypesActions.fetchEventTypes(project.id));
			              dispatch(eventCategoriesActions.fetchEventCategories(project.id));

			              dispatch(eventSourcesActions.fetchProjectEventSources(project.id));
			              dispatch(eventTypesActions.fetchProjectEventTypes(project.id));
			              dispatch(eventCategoriesActions.fetchProjectEventCategories(project.id));

			              notificationService
				              .createFilter({
					                            projectIds: [id],

				                            })
				              .then(filter_proxy => {

					              dispatch(notifications_actions.receiveFilter({...filter_proxy.data, period_code: 4}));

					              notificationService
						              .get(0, 20)
						              .then(notifications_proxy => {
							              dispatch(notifications_actions.receiveItems(notifications_proxy.data));
						              })
				              })
		              })
	}
}

const loadNotifications = (page_index, page_size) => {
	return (dispatch, getState) => {
		dispatch(notifications_actions.requestItems())
		notificationService
			.get(page_index, page_size)
			.then(notifications_proxy => {
				dispatch(notifications_actions.receiveItems(notifications_proxy.data));
			})
	}
}

const appendNotifications = (page_index, page_size) => {
	return (dispatch, getState) => {
		dispatch(notifications_actions.requestItems())
		notificationService
			.get(page_index, page_size)
			.then(notifications_proxy => {
				dispatch(notifications_actions.appendItems(notifications_proxy.data));
			})
	}
}

const filterNotifications = (filter) => {
	return (dispatch, getState) => {
		dispatch(notifications_actions.requestItems())
		let start_date = null;
		let end_date = null;

		if (filter.period_code != null) {
			let dates = periodCodeToDates(filter.period_code);
			({
				start_date,
				end_date
			} = dates);
		}

		notificationService
			.createFilter({
				              projectIds: [getState().projectReducer.project.id],
				              periodStart: start_date,
				              periodEnd: end_date
			              })
			.then(filter_proxy => {

				dispatch(notifications_actions.receiveFilter({
					                                             ...filter_proxy.data,
					                                             period_code: filter.period_code
				                                             }));

				notificationService
					.get(0, 20)
					.then(notifications_proxy => {
						dispatch(notifications_actions.receiveItems(notifications_proxy.data));
					})
			})
	}
}

const ProjectContainer = connect(mapStateToProps, mapDispatchToProps)(ProjectView);

export default withRouter(ProjectContainer)