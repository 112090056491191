import {actionTypes} from "../actions/color-schema";

const initialState = {
    isPending: false,
    schema: null
};

function colorSchemaReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.REQUEST_SCHEMA:
            return {
                ...state,
                isPending: true
            };
        case actionTypes.RECEIVE_SCHEMA:
            return {
                ...state,
                isPending: false,
                schema: action.data
            };
        default:
            return state
    }
}

export default colorSchemaReducer