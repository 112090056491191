import $ from 'jquery';
import {DATA_URL} from "../constants";

const BASE_URL = DATA_URL + '/';

function replacer(key, value) {
	if (value === null) return undefined;
	return value;
}

class Request {


	constructor(auth) {
		this.auth = auth;
	}

	/**
	 * serialize Object into a list of parameters
	 * ex. obj { token: 12345, action: login } => token=12345&action=login
	 */
	static getParams(o) {
		return Object.keys(o)
		             .map(key => `${key}=${encodeURIComponent(o[key])}`)
		             .join('&');
	}

	/**
	 * replace params from Object in passed string
	 * ex. str: "match/{id}", params: {id:5} => "match/5"
	 */
	static replaceParams(str, params = {}) {
		for (const prop of Object.keys(params)) {
			str = str.replace(`{${prop}}`, params[prop]);
		}
		return str;
	}

	/**
	 * get request to API with params
	 */
	request(url, params = false, data, {type}) {

		const paramsString = params ? Request.getParams(params) : '';

		const urlWithParams = `${url}?${paramsString}`;

		console.log(`api request to ${urlWithParams}`);

		data = data !== null ? JSON.stringify(data, replacer) : false;

		return new Promise((resolve, reject) => {
			$.ajax({
				       url: urlWithParams,
				       type,
				       data,
				       contentType: 'application/json',
				       success: data => {
					       resolve(data);
				       },
				       error: (xhr, status, err) => {
					       console.error(urlWithParams, status, err.toString());
					       reject(err);
				       }
			       })
		});
	}

	/**
	 * public method for get data from api
	 */
	get(resource, options = {}, data = null) {
		return new Promise((resolve, reject) => {
			// add api_token to request
			const params = data;
			const url = BASE_URL + Request.replaceParams(resource, options);

			this.request(url, params, false, {type: 'GET'})
			    .then(result => resolve(result))
			    .catch(error => reject(error));
		});
	}

	/**
	 * public method for get data from api
	 */
	post(resource, options = {}, data = null) {
		return new Promise((resolve, reject) => {
			// add api_token to request
			const params = {};
			const url = BASE_URL + Request.replaceParams(resource, options);

			this.request(url, params, data, {type: 'POST'})
			    .then(result => resolve(result))
			    .catch(error => reject(error));
		});
	}

	_requestUrlEnc(url, params, {type}) {
		const paramsString = params ? Request.getParams(params) : '';
		const urlWithParams = `${url}?${paramsString}`;

		const data = false;

		console.log(`api request to ${urlWithParams}`);

		return new Promise((resolve, reject) => {
			$.ajax({
				       url: urlWithParams,
				       type,
				       dataType: 'json',
				       data,
				       contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
				       success: data => {
					       resolve(data);
				       },
				       error: (xhr, status, err) => {
					       console.error(urlWithParams, status, err.toString());
					       reject(err);
				       }
			       })
		});
	}

	postUrlEnc(resource, options = {}, params = false) {
		return new Promise((resolve, reject) => {
			// add api_token to request
			const url = BASE_URL + Request.replaceParams(resource, options);

			Request._requestUrlEnc(url, params, {type: 'POST'})
			       .then(result => resolve(result))
			       .catch(error => reject(error));
		});
	}

	postMultipart(uri, formData) {

		const url = BASE_URL + uri;

		return new Promise((resolve, reject) => {
			$.ajax({
				       url: url,
				       type: 'POST',
				       data: formData,
				       contentType: false,
				       processData: false,
				       success: data => {
					       resolve(data);
				       },
				       error: (xhr, status, err) => {
					       console.error(url, status, err.toString());
					       reject(err);
				       }
			       })
		});
	}

}

export default Request;
export {Request};
