import React from 'react';
import vis from 'vis';
import moment from 'moment';

import BaseComponent from '../base/base-component';
import ProcessOverlay from "../base/process-overlay";


const NODE_LEVEl_COLORS = {
	'-1': 'red',
	0: 'green',
	1: 'orange',
	2: 'blue',
	3: 'red',
	4: 'violet',
	5: 'black'
};

class FactorsMap
	extends BaseComponent {

	constructor(props) {

		super(props);

		let collapse_state = this.loadCollapseState();

		this.state = {
			contextMenuOpened: false,
			collapse_store: collapse_state ? collapse_state : {}
		};

		this.nodes = new vis.DataSet([]);
		this.edges = new vis.DataSet([]);

		this.highlighted_map = {};
	}

	onClick = (params) => {
		if (this.state.contextMenuOpened) {
			this.setState({
				              contextMenuOpened: false
			              });
		}
	};

	onSelectNode = (params) => {
		if (params.nodes[0]) {
			this.props.onSelectionChange(params.nodes[0]);
		}
	};

	onDeselectNode = (params) => {
		this.props.onSelectionChange(null);
	};

	onDoubleClick = (params) => {
		if (params.nodes[0]) {
			this.props.onDoubleClick(params.nodes[0]);
		}
	};

	nodeFactory = (element) => {

		const events = element.events ? element.events : [];

		const lastDate = moment()
			.subtract(1, "days");

		const lastEvents = events.filter(event => {

			const createDate = Date.parse(event.create_date);

			return createDate >= lastDate;
		});

		let hidden = false;

		if (this.state.collapse_store[this.inheritance_map[element.id]]) {
			hidden = true
		} else if (this.nodes.get(this.inheritance_map[element.id]) && this.nodes.get(this.inheritance_map[element.id]).hidden) {
			hidden = true;
		}

		return {
			id: element.id,
			label: element.id !== 0 ? `${element.title}\n${lastEvents.length}/${events.length}` : element.title,
			title: element.title,
			shape: this.state.collapse_store[element.id] ? 'ellipse' : 'box',
			margin: 10,
			borderWidth: this.state.collapse_store[element.id] ? 2 : 1,
			hidden: hidden,
			font: {
				color: 'rgb(93, 97, 98)',
				bold: {
					mod: 'bold'
				},
				size: 18
			},
			fixed: element.id === 0 ? {
				x: true,
				y: true
			} : undefined,
			widthConstraint: {
				minimum: this.state.collapse_store[element.id] ? 130 : 130,
				maximum: this.state.collapse_store[element.id] ? 130 : 130,
			},
			heightConstraint: {
				minimum: this.state.collapse_store[element.id] ? 50 : 35
			},
			color: {
				background: this.highlighted_map[element.id] ? 'rgb(0, 199, 197)' : 'rgb(235, 244, 249)',
				border: NODE_LEVEl_COLORS[element.depth],
				highlight: {
					background: 'rgb(50, 200, 200)'
				},
				hover: {
					background: 'rgb(235, 244, 249)',
					border: NODE_LEVEl_COLORS[element.depth],
				}
			},
			x: element.id === 0 ? 0 : undefined,
			y: element.id === 0 ? 0 : undefined
		}
	};

	edgeFactory = (edge) => {
		return {
			id: edge.from + '/' + edge.to,
			from: edge.from,
			to: edge.to
		}
	};

	componentDidMount() {

		const self = this;

		const options = {
			interaction: {
				dragNodes: false,
				navigationButtons: true,
				hover: true
			},
			layout: {
				randomSeed: 7009,  // 258227
			},
			physics: {
				enabled: true,
				barnesHut: {
					centralGravity: 0.06,
					gravitationalConstant: -10000,
					springLength: 400,
					springConstant: 0.1,
					damping: 0.1,
					avoidOverlap: 1
				},
				repulsion: {
					centralGravity: 0.03,
					springLength: 400,
					springConstant: 0.2,
					nodeDistance: 150,
					damping: 0.01
				},
				solver: 'barnesHut',
				stabilization: {
					iterations: 10000
				}
			}
		};

		const network = new vis.Network(this.factorsMapAnchor, {
			nodes: this.nodes,
			edges: this.edges
		}, options);

		const networkCanvas = this.factorsMapAnchor.getElementsByTagName("canvas")[0];

		this.props.canvasRef(networkCanvas);

		function changeCursor(newCursorStyle) {
			networkCanvas.style.cursor = newCursorStyle;
		}

		network.on('click', this.onClick);
		network.on('selectNode', this.onSelectNode);
		network.on('deselectNode', this.onDeselectNode);
		network.on('doubleClick', this.onDoubleClick);
		network.on('hoverNode', function () {
			changeCursor('pointer');
		});
		network.on('blurNode', function () {
			changeCursor('auto');
		});
		network.on('oncontext', function (e) {
			e.event.preventDefault();
			self.setState({
				              contextMenuOpened: true,
				              contextMenuPosition: {
					              x: e.event.offsetX,
					              y: e.event.offsetY,
				              }
			              });
		});
		network.on('startStabilizing', function (e) {
			self.setState({
				              stabilizing: true
			              })
		});
		network.on('stabilizationProgress', function (e) {
			console.log(e);
		});
		network.on('stabilized', function (e) {

			self.setState({
				              stabilizing: false
			              });

			console.log("Stabilization iterations: " + e.iterations);

			self.savePositions();

			self.positionViewport();
		});
		network.on('dragging', function (e) {
			self.saveViewportState({position: self.network.getViewPosition()})
		});
		network.on('zoom', function (e) {
			self.saveViewportState({
				                       scale: e.scale,
				                       position: self.network.getViewPosition()
			                       })
		});

		this.network = network;

		if (this.props.factors) {

			this.buildFactorsMap(this.props.factors);

			this.buildInheritanceMap(this.props.factors);

			let node_positions = this.loadPositions();

			this.populateDataSets(node_positions);
		}

		if (this.props.highlightedFactors) {
			this.setHighlight(this.props.highlightedFactors);
		}

		if (this.state.collapse_store) {
			this.setCollapsing(this.props.factors);
		}

		if (this.props.selectedNode) {
			this.setSelection(this.props.selectedNode);
		}
	}

	savePositions() {
		if (typeof (Storage) !== "undefined") {

			let node_positions_map = this.network.getPositions(this.nodes.getIds());

			window.localStorage.setItem(this.props.storageKey + ".node-positions", JSON.stringify(node_positions_map));
		}
	}

	clearPositions() {
		if (typeof (Storage) !== "undefined") {
			window.localStorage.removeItem(this.props.storageKey + ".node-positions");
		}
	}

	loadPositions() {
		if (typeof (Storage) !== "undefined") {
			return JSON.parse(window.localStorage.getItem(this.props.storageKey + ".node-positions"));
		} else {
			return null;
		}
	}

	saveViewportState(data) {
		if (typeof (Storage) !== "undefined") {

			let viewport_state = this.loadViewportState();

			if (viewport_state) {
				window.localStorage.setItem(this.props.storageKey + ".viewport-position", JSON.stringify(Object.assign(viewport_state, data)));
			} else {
				window.localStorage.setItem(this.props.storageKey + ".viewport-position", JSON.stringify(data));
			}
		}
	}

	loadViewportState() {
		if (typeof (Storage) !== "undefined") {
			return JSON.parse(window.localStorage.getItem(this.props.storageKey + ".viewport-position"));
		} else {
			return null;
		}
	}

	positionViewport() {

		let viewport_state = this.loadViewportState();

		if (viewport_state) {
			this.network.moveTo({
				                    position: viewport_state.position,
				                    scale: viewport_state.scale ? viewport_state.scale : 1
			                    })
		} else {

			this.network.fit()

			this.saveViewportState({
				                       scale: this.network.getScale(),
				                       position: this.network.getViewPosition()
			                       })
		}
	}

	saveCollapseState(data) {
		if (typeof (Storage) !== "undefined") {
			window.localStorage.setItem(this.props.storageKey + ".collapse", JSON.stringify(data));
		}
	}

	loadCollapseState() {
		if (typeof (Storage) !== "undefined") {
			return JSON.parse(window.localStorage.getItem(this.props.storageKey + ".collapse"));
		} else {
			return null;
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (this.props.factors !== prevProps.factors) {

			this.buildFactorsMap(this.props.factors);

			this.buildInheritanceMap(this.props.factors);

			if (this.props.factors && this.props.factors.length !== 0) {

				let node_positions = this.loadPositions();

				if (node_positions && Object.keys(node_positions).length !== this.props.factors.length) {
					this.clearPositions();
				}

				this.populateDataSets(node_positions);
			}
		}

		if (prevProps.highlightedFactors !== this.props.highlightedFactors) {
			this.setHighlight(this.props.highlightedFactors);
		}

		if (prevState.collapse_store !== this.state.collapse_store || this.props.factors !== prevProps.factors) {
			this.setCollapsing(this.props.factors);
		}

		if ((prevProps.selectedNode !== this.props.selectedNode) ||
			(this.props.selectedNode && this.network.getSelectedNodes().length === 0)) {
			this.setSelection(this.props.selectedNode);
		}
	}

	setSelection(selected_node) {
		if (selected_node && this.nodes.get(selected_node.id)) {
			this.network.selectNodes([selected_node.id], true)
		} else {
			this.network.selectNodes([], true)
		}
	}

	setCollapsing(factors) {
		factors.forEach((element, index) => {
			this.nodes.update(this.nodeFactory(element));
		});
	}

	setHighlight(highlighted_factors) {
		if (highlighted_factors) {

			this.highlighted_map = this.props.highlightedFactors.reduce((aggregator, currentValue) => {
				aggregator[currentValue.id] = currentValue;
				return aggregator;
			}, {});

			this.nodes.update(this.props.highlightedFactors.map(this.nodeFactory));
		} else {

			this.highlighted_map = {};

			this.nodes.update(this.props.factors.map(this.nodeFactory));
		}
	}

	populateDataSets(node_positions) {

		let added_nodes = [];

		let removed_nodes = [];

		let added_edges = [];

		let removed_edges = [];

		this.nodes.getIds()
		    .forEach((value, index, array) => {
			    if (!this.factors_map[value]) {
				    removed_nodes.push(value);
			    }
		    });

		Object.keys(this.factors_map)
		      .forEach((value, index, array) => {
			      if (!this.nodes.get(value)) {
				      added_nodes.push(this.nodeFactory(this.factors_map[value]));
			      }
		      });

		Object.keys(this.inheritance_map)
		      .forEach((value, index) => {
			      if (!this.edges.get(this.inheritance_map[value] + '/' + value)) {
				      added_edges.push(this.edgeFactory({
					                                        from: this.inheritance_map[value],
					                                        to: value
				                                        }))
			      }
		      });

		this.edges.getIds()
		    .forEach((value, index) => {
			    let nodeIds = value.split('/');
			    if (this.inheritance_map[nodeIds[1]] == null || this.inheritance_map[nodeIds[1]] !== parseInt(nodeIds[0])) {
				    removed_edges.push(this.edges.get(value));
			    }
		    });

		added_nodes = this.initializePlaces(added_nodes, node_positions);

		this.nodes.add(added_nodes);
		this.nodes.remove(removed_nodes);

		this.edges.add(added_edges);
		this.edges.remove(removed_edges);

		this.nodes.update(this.props.factors.map(this.nodeFactory));
	}

	initializePlaces(added_nodes, nodes_positions_map) {
		if (nodes_positions_map) {
			added_nodes.forEach((item) => {
				if (nodes_positions_map[item.id]) {

					item.x = nodes_positions_map[item.id].x;
					item.y = nodes_positions_map[item.id].y;
				} else {

					let root_node_position = nodes_positions_map[0] ? nodes_positions_map[0] : {
						x: 0,
						y: 0
					};

					let parent_node = this.factors_map[this.inheritance_map[item.id]];

					if (parent_node && parent_node.id !== 0) {

						let parent_node_position = nodes_positions_map[parent_node.id];

						if (parent_node_position) {
							item.x = 400 * parent_node_position.x / Math.sqrt(Math.pow((parent_node_position.x - root_node_position.x), 2) - Math.pow((parent_node_position.y - root_node_position.y), 2)) + parent_node_position.x;
							item.y = 400 * parent_node_position.y / Math.sqrt(Math.pow((parent_node_position.x - root_node_position.x), 2) - Math.pow((parent_node_position.y - root_node_position.y), 2)) + parent_node_position.y;
						} else {
							this.setDefaultPosition(item, root_node_position);
						}
					} else {
						this.setDefaultPosition(item, root_node_position);
					}
				}
			})
		} else {

			let keys = this.props.factors.map((element) => {
				return element.id;
			});

			let step = 2 * Math.PI / keys.length;

			added_nodes = added_nodes.sort((a, b) => {
				return keys.indexOf(a.id) > keys.indexOf(b.id) ? 1 : -1;
			});

			if (!added_nodes) {
				return [];
			}

			for (let i = 0; i < keys.length; i++) {

				let depth = this.props.factors[i].depth !== undefined ? this.props.factors[i].depth + 1 : 1;

				if (added_nodes[i]) {
					added_nodes[i].x = Math.cos(step * (i + 1)) * 600 * depth;
					added_nodes[i].y = Math.sin(step * (i + 1)) * 600 * depth;
				}
			}
		}

		return added_nodes;
	}

	setDefaultPosition(item, root_node_position) {
		item.x = Math.floor(Math.random() * Math.floor(200)) - root_node_position.x;
		item.y = Math.floor(Math.random() * Math.floor(200)) - root_node_position.y;
	}

	buildInheritanceMap(factors) {
		this.inheritance_map = factors.reduce((aggregator, current_value) => {
			let child_inheritance_map = current_value.children.reduce((child_aggregator, child) => {
				child_aggregator[child.id] = current_value.id;
				return child_aggregator
			}, {});
			return Object.assign(aggregator, child_inheritance_map);
		}, {});
	}

	buildFactorsMap(factors) {
		this.factors_map = factors.reduce((aggregator, currentValue) => {
			aggregator[currentValue.id] = currentValue;
			return aggregator;
		}, {});
	}

	componentDidCatch(error, errorInfo) {
		this.setState({
			              error: true
		              })
	}

	render() {

		if (this.state.error) {
			return <div/>
		}

		let contextMenu = null;

		if (this.state.contextMenuOpened) {

			let menu_items = [];


			this.props.addEnabled &&
			menu_items.push((
				                <li className="react-contextmenu-item"
				                    key={"add_item"}
				                    onClick={
					                    (e) => {
						                    this.setState({
							                                  contextMenuOpened: false
						                                  });
						                    this.props.onAdd();
					                    }
				                    }>
					                Add
				                </li>
			                ));

			this.props.editEnabled &&
			menu_items.push((
				                <li className="react-contextmenu-item"
				                    key={"edit_item"}
				                    onClick={
					                    (e) => {
						                    this.setState({
							                                  contextMenuOpened: false
						                                  });
						                    this.props.onEdit();
					                    }
				                    }>
					                Edit
				                </li>
			                ));

			this.props.deleteEnabled &&
			menu_items.push((
				                <li className="react-contextmenu-item"
				                    key={"delete_item"}
				                    onClick={
					                    (e) => {
						                    this.setState({
							                                  contextMenuOpened: false
						                                  });
						                    this.props.onDelete();
					                    }
				                    }>Delete</li>
			                ));
			this.props.copyEnabled &&
			menu_items.push((
				                <li className="react-contextmenu-item" key={"copy_item"}>
					                Copy
					                <ul className="react-contextmenu-submenu">
						                <li className="react-contextmenu-item" onClick={
							                (e) => {
								                this.setState({
									                              contextMenuOpened: false
								                              });
								                this.props.onCopyWithoutEvents();
							                }
						                }
						                >without events
						                </li>
						                <li className="react-contextmenu-item" onClick={
							                (e) => {
								                this.setState({
									                              contextMenuOpened: false
								                              });
								                this.props.onCopyWithEvents();
							                }
						                }>with events
						                </li>
					                </ul>
				                </li>
			                ));

			this.props.insertEnabled &&
			menu_items.push((
				                <li className="react-contextmenu-item"
				                    key={"insert_item"}
				                    onClick={
					                    (e) => {
						                    this.setState({
							                                  contextMenuOpened: false
						                                  });
						                    this.props.onInsert();
					                    }
				                    }>Insert</li>
			                ));

			this.network.getSelectedNodes().length !== 0 && !this.state.collapse_store[this.network.getSelectedNodes()[0]] &&
			this.props.factors.filter(x => {
				return x.id === this.network.getSelectedNodes()[0]
			})[0].children.length !== 0 &&
			menu_items.push((
				                <li className="react-contextmenu-item"
				                    key={"collapse_item"}
				                    onClick={
					                    (e) => {
						                    this.state.collapse_store[this.network.getSelectedNodes()[0]] = true;
						                    this.saveCollapseState(this.state.collapse_store);
						                    this.setState({
							                                  contextMenuOpened: false,
							                                  collapse_store: {...this.state.collapse_store}
						                                  });
					                    }
				                    }>Collapse</li>
			                ));
			this.state.collapse_store[this.network.getSelectedNodes()[0]] &&
			menu_items.push((
				                <li className="react-contextmenu-item"
				                    key={"expand_item"}
				                    onClick={
					                    (e) => {
						                    this.state.collapse_store[this.network.getSelectedNodes()[0]] = false;
						                    this.saveCollapseState(this.state.collapse_store);
						                    this.setState({
							                                  contextMenuOpened: false,
							                                  collapse_store: {...this.state.collapse_store}
						                                  });
					                    }
				                    }>Expand</li>
			                ));

			let pointer_x = this.state.contextMenuPosition.x;

			let pointer_y = this.state.contextMenuPosition.y;

			let menu_x = 0;

			let menu_y = 0;

			let canvas_width = this.network.body.container.offsetWidth;

			let canvas_height = this.network.body.container.offsetHeight;

			if (pointer_x + 140 > canvas_width) {
				menu_x = pointer_x - (pointer_x + 140 - canvas_width);
			} else {
				menu_x = pointer_x;
			}

			if (pointer_y + 35 * menu_items.length > canvas_height) {
				menu_y = pointer_y - (35 * menu_items.length);
			} else {
				menu_y = pointer_y;
			}

			contextMenu = (
				<ul className="react-contextmenu" role="menu" style={{
					display: "block",
					position: 'absolute',
					opacity: 'unset',
					pointerEvents: 'auto',
					listStyle: 'none',
					left: menu_x,
					top: menu_y
				}}>
					{menu_items}
				</ul>
			)
		}

		return (
			<div id="factorsMapAnchor"
			     ref={this.factorsMapRef}
			     style={{backgroundColor: '#f4f4f4'}}
			     className="h-100">
				{
					this.state.stabilizing &&
					<ProcessOverlay className={"progress-overlay"}>Stabilizing...</ProcessOverlay>
				}
				{/*{collapseToggleOverlays}*/}
				{contextMenu}
			</div>
		)
	}

	factorsMapRef = (element) => {
		this.factorsMapAnchor = element;
	}
}

export default FactorsMap;