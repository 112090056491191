import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import * as eventTypesActions from "../actions/event-types";
import EventTypesView from "../views/event-types";
import {authorities} from "../mixins/authorities";
import {eventTypeService} from "../services";
import * as orphans_actions from "../actions/orphans";

const mapStateToProps = (state, ownProps) => {
	return {
		eventTypes: state.eventTypesReducer.eventTypes,
		selectedEventType: state.eventTypesReducer.selectedEventType ? state.eventTypesReducer.selectedEventType : null,
		eventType: state.eventTypesReducer.eventType ? state.eventTypesReducer.eventType : null,
		orphans: state.orphans.items,
		selectedOrphan: state.orphans.selectedItem,
		eventTypeOperation: state.eventTypesReducer.operation,
		...authorities(state.authenticationReducer.authentication)
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		loadInitialData: () => {
			dispatch(loadInitialDataActionChain());
		},
		selectItem: (item) => {
			dispatch(eventTypesActions.selectEventType(item));
		},
		createItem: (item) => {
			dispatch(createItemActionChain(item));
		},
		saveItem: (item) => {
			dispatch(saveItemActionChain(item));
		},
		add: () => {
			dispatch(eventTypesActions.receiveEventType({}));
		},
		edit: (item) => {
			dispatch(eventTypesActions.fetchEventType(item.id));
		},
		delete: (item) => {
			dispatch(deleteItemActionChain(item));
		},
		selectOrphan: (item) => {
			dispatch(orphans_actions.selectItem(item))
		},
		clearOrphans: () => {
			dispatch(orphans_actions.receiveItems(null));
		},
		loadOrphanItemInfo: (type, path) => {

		},
		clearOperation: () => {
			dispatch(eventTypesActions.startOperation(null));
		}
	}
};

const loadInitialDataActionChain = () => {
	return (dispatch, getState) => {
		if (!getState().eventTypesReducer.isPending) {
			dispatch(eventTypesActions.requestEventTypes());
			eventTypeService.getAll()
			                .then(sources_proxy => {
				                dispatch(eventTypesActions.receiveEventTypes(sources_proxy.data));
			                })
		}
	}
}

const createItemActionChain = (item) => {
	return (dispatch, getState) => {
		dispatch(eventTypesActions.requestEventTypes());
		eventTypeService.create(item)
		                .then(event_source_proxy => {
			                dispatch(eventTypesActions.appendEventType(event_source_proxy.data));
			                dispatch(eventTypesActions.endOperation({
				                                                        name: 'CREATION',
				                                                        status: "SUCCESS"
			                                                        }));
		                })
		                .catch((error) => {
			                dispatch(eventTypesActions.endOperation({
				                                                        name: 'CREATION',
				                                                        status: "ERROR"
			                                                        }));
		                })
	}
}

const saveItemActionChain = (item) => {
	return (dispatch, getState) => {
		dispatch(eventTypesActions.requestEventTypes());
		eventTypeService.save(item)
		                .then(sources_proxy => {
			                dispatch(eventTypesActions.insertEventType(sources_proxy.data));
			                dispatch(eventTypesActions.endOperation({
				                                                        name: 'SAVING',
				                                                        status: "SUCCESS"
			                                                        }));
		                })
		                .catch((error) => {
			                dispatch(eventTypesActions.endOperation({
				                                                        name: 'SAVING',
				                                                        status: "ERROR"
			                                                        }));
		                })
	}
}

const deleteItemActionChain = (item) => {
	return (dispatch, getState) => {
		eventTypeService.delete(item.id)
		                .then(item_proxy => {
			                dispatch(eventTypesActions.fetchEventTypes());
			                dispatch(orphans_actions.receiveItems(null));
		                })
		                .catch((error) => {
			                if (error.response.status === 409) {
				                dispatch(orphans_actions.receiveItems(error.response.data));
			                }
		                });
	}
}

const EventTypesContainer = connect(mapStateToProps, mapDispatchToProps)(EventTypesView);

export default withRouter(EventTypesContainer)