import clientFactory from "../factories/http_client";
import {API} from "../api/api";

class ScenarioLinkService {

	/** @return  Promise<AxiosResponse<T>> */
	get = (projectId, scenarioId, linkId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.scenarios.id.links.id.method,
			                             url: API.data.projects.id.scenarios.id.links.id.url(projectId, scenarioId, linkId)
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	create = (projectId, scenarioId, link) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.scenarios.id.links.create.method,
			                             url: API.data.projects.id.scenarios.id.links.create.url(projectId, scenarioId),
			                             data: API.data.projects.id.scenarios.id.links.create.build(link),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	save = (projectId, scenarioId, link) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.scenarios.id.links.save.method,
			                             url: API.data.projects.id.scenarios.id.links.save.url(projectId, scenarioId),
			                             data: API.data.projects.id.scenarios.id.links.save.build(link),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	delete = (projectId, scenarioId, linkId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.scenarios.id.links.delete.method,
			                             url: API.data.projects.id.scenarios.id.links.delete.url(projectId, scenarioId),
			                             data: API.data.projects.id.scenarios.id.links.delete.build(linkId),
		                             });
	};
}

export default ScenarioLinkService