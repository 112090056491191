const KEYS = {
    FILTERS: "filters",
    SCENARIOS: "scenarios"
};

function setItem(key, data) {
    try {
        window.localStorage.setItem(key, data);
    } catch(e) {
        console.log("localStorage error: " + e);
    }
}

function getFiltersKey(userId, projectId) {
    return `${KEYS.FILTERS}_${userId}_${projectId}`;
}

function getScenariosKey(userId, projectId) {
    return `${KEYS.SCENARIOS}_${userId}_${projectId}`;
}

class FilterUtils {

    static getFilters(userId, projectId) {
        const filters = window.localStorage.getItem(getFiltersKey(userId, projectId));
        return filters ? JSON.parse(filters) : null;
    }

    static addFilter(userId, projectId, filter) {
        const id = filter.id ? filter.id : -Math.random();
        const key = getFiltersKey(userId, projectId);
        let filters = window.localStorage.getItem(key);
        if (filters) {
            filters = JSON.parse(filters);
            if (!filters[id]) {
                filters[id] = filter;
                setItem(key, JSON.stringify(filters));
            }
        } else {
            filters = {[id]: filter};
            setItem(key, JSON.stringify(filters));
        }
        return id;
    }

    static removeFilter(userId, projectId, filterId) {
        const key = getFiltersKey(userId, projectId);
        let filters = window.localStorage.getItem(key);
        if (filters) {
            filters = JSON.parse(filters);
            if (filters[filterId]) {
                delete filters[filterId];
                setItem(key, JSON.stringify(filters));
            }
        }
    }

    static getFilter(userId, projectId, filterId) {
        let filters = window.localStorage.getItem(getFiltersKey(userId, projectId));
        if (filters) {
            filters = JSON.parse(filters);
            return filters[filterId];
        }
        return null;
    }
}

class ScenarioUtils {

    static getScenariosIds(userId, projectId) {
        const scenariosIds = window.localStorage.getItem(getScenariosKey(userId, projectId));
        return scenariosIds ? JSON.parse(scenariosIds) : null;
    }

    static addScenarioId(userId, projectId, scenarioId) {
        const key = getScenariosKey(userId, projectId);
        let scenariosIds = window.localStorage.getItem(key);
        if (scenariosIds) {
            scenariosIds = JSON.parse(scenariosIds);
            if (!scenariosIds.includes(scenarioId)) {
                scenariosIds.push(scenarioId);
                setItem(key, JSON.stringify(scenariosIds));
            }
        } else {
            scenariosIds = [scenarioId];
            setItem(key, JSON.stringify(scenariosIds));
        }
    }

    static removeScenarioId(userId, projectId, scenarioId) {
        const key = getScenariosKey(userId, projectId);
        let scenariosIds = window.localStorage.getItem(key);
        if (scenariosIds) {
            scenariosIds = JSON.parse(scenariosIds);
            if (scenariosIds.includes(scenarioId)) {
                delete scenariosIds[scenarioId];
                setItem(key, JSON.stringify(scenariosIds));
            }
        }
    }
}

export { FilterUtils, ScenarioUtils };