import * as React from "react";
import {Button, Col, Grid, Modal, Pagination, Row, Table} from "react-bootstrap";
import Utils from "../../utils/utils";

export default class DeleteConflictOrphansDialog
	extends React.Component {

	constructor(props, context) {

		super(props, context);

		this.state = {
			pages: [],
			page_index: 0,
			page_size: 10
		}
	}

	componentDidMount() {
		if (this.props.items) {
			this.setState({
				              pages_count: Math.floor(this.props.items.length / this.state.page_size),
				              page: this.getPage(this.props.items, this.state.page_index, this.state.page_size)
			              })
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.items !== this.props.items) {
			this.setState({
				              pages_count: Math.floor(this.props.items.length / this.state.page_size),
				              page: this.getPage(this.props.items, this.state.page_index, this.state.page_size)
			              })
		}

		if (prevState.page_index !== this.state.page_index) {
			this.setState({
				              page: this.getPage(this.props.items, this.state.page_index, this.state.page_size)
			              })
		}
	}

	componentDidCatch(error, errorInfo) {
		this.setState({
			              error: true
		              })
	}

	render() {

		let page_items = this.state.page ? this.state.page : [];

		let pagination;

		if (this.state.pages_count !== 0) {
			if (this.state.pages_count < 10) {
				pagination = <Pagination>
					<Pagination.First onClick={
						(e) => this.setState({
							                     page_index: 0
						                     })
					}/>
					<Pagination.Prev onClick={
						(e) => this.setState({
							                     page_index: this.state.page_index - 1 >= 0 ? this.state.page_index - 1 : this.state.page_index
						                     })
					}
					/>
					{
						new Array(this.state.pages_count).fill(0)
						                                 .map((page, index) => {
							                                 return (
								                                 <Pagination.Item active={index === this.state.page_index}
								                                                  onClick={
									                                                  (e) => this.setState({
										                                                                       page_index: index
									                                                                       })
								                                                  }>
									                                 {index + 1}
								                                 </Pagination.Item>
							                                 )
						                                 })
					}
					<Pagination.Next onClick={
						(e) => this.setState({
							                     page_index: this.state.page_index + 1 < this.state.pages_count ? this.state.page_index + 1 : this.state.page_index
						                     })
					}/>
					<Pagination.Last onClick={
						(e) => this.setState({
							                     page_index: this.state.pages_count - 1
						                     })
					}/>
				</Pagination>
			} else {

				let page_numbers;

				if (this.state.page_index < 2) {
					page_numbers = this.state.page_index === 0 ?
						[this.state.page_index, this.state.page_index + 1, this.state.page_index + 2]
						:
						[this.state.page_index - 1, this.state.page_index, this.state.page_index + 1, this.state.page_index + 2]
				} else if (this.state.pages_count - 1 - this.state.page_index < 2) {
					page_numbers = this.state.page_index === this.state.pages_count - 1 ?
						[this.state.page_index - 2, this.state.page_index - 1, this.state.page_index]
						:
						[this.state.page_index - 3, this.state.page_index - 2, this.state.page_index - 1, this.state.page_index]
				} else {
					page_numbers = [this.state.page_index - 2, this.state.page_index - 1, this.state.page_index, this.state.page_index + 1, this.state.page_index + 2]
				}

				pagination = <Pagination>
					<Pagination.First onClick={
						(e) => this.setState({
							                     page_index: 0
						                     })
					}/>
					<Pagination.Prev onClick={
						(e) => this.setState({
							                     page_index: this.state.page_index - 1 >= 0 ? this.state.page_index - 1 : this.state.page_index
						                     })
					}
					/>
					{
						(this.state.page_index > 2 ? <Pagination.Ellipsis/> : null)
					}
					{
						page_numbers
							.map((page) => {
								return (
									<Pagination.Item active={page === this.state.page_index}
									                 onClick={
										                 (e) => this.setState({
											                                      page_index: page
										                                      })
									                 }>
										{page + 1}
									</Pagination.Item>
								)
							})
					}
					{
						(this.state.pages_count - 1 - this.state.page_index > 2 ? <Pagination.Ellipsis/> : null)
					}
					<Pagination.Next onClick={
						(e) => this.setState({
							                     page_index: this.state.page_index + 1 < this.state.pages_count ? this.state.page_index + 1 : this.state.page_index
						                     })
					}/>
					<Pagination.Last onClick={
						(e) => this.setState({
							                     page_index: this.state.pages_count - 1
						                     })
					}/>
				</Pagination>
			}
		}


		return (
			<Modal show={this.props.show} onHide={this.props.onHide}>
				<Modal.Header closeButton>
					<Modal.Title>{this.props.title ? this.props.title : ""}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{
						this.state.error ? <div/> : <Grid fluid={true}>
							<Col>
								<Row>
									Item cannot be deleted due to existing objects:
								</Row>
								<Row>
									<Table>
										<thead>
										<tr>
											<th>Type</th>
											<th>ID</th>
											<th style={{
												width: '25%',
												maxWidth: '25%'
											}}>
												Link
											</th>
										</tr>
										</thead>
										<tbody>
										{
											page_items.map(item => {
												return (
													<tr key={item.path.join("-")}
													    onClick={
														    (event) => this.handleItemClick(item)
													    }
													    className={`clickable ${(this.props.selectedItem && this.props.selectedItem.path.join("-") === item.path.join("-") ? 'selected' : '')}`}>
														<td> {item.type} </td>
														<td> {
															item.type === "PROJECT" ? item.path[0] :
																item.type === "EVENT" ? item.path[2] : ""
														} </td>
														<td style={{textAlign: 'center'}}><a target="_blank" href={Utils.hrefFromPath(item.type, item.path)}>Link</a></td>
													</tr>
												)
											})
										}
										</tbody>
									</Table>
									{pagination}
								</Row>
							</Col>
						</Grid>
					}
				</Modal.Body>
				<Modal.Footer>
					<Button bsClass="btn pull-right action-button" onClick={this.props.onHide}>Cancel</Button>
					{this.props.onProceed &&  <Button bsClass="btn pull-right action-button" onClick={this.props.onProceed}>Proceed</Button>}
				</Modal.Footer>
			</Modal>
		)
	}

	paginateItems = (items) => {

		const pages = []

		let sorted_items = items.sort(Utils.sortDescendingById())
		                        .sort(Utils.sortDescendingByProperty("type"));

		let page_size = this.state.page_size;

		let pages_count = Math.floor(sorted_items.length / page_size);

		for (let i = 0; i <= pages_count; i++) {
			let slice = sorted_items.slice(i * page_size, (i + 1) * page_size);
			pages.push(slice);
		}

		return pages;
	}

	getPage = (items, page_index, page_size) => {

		let sorted_items = items.sort(Utils.sortDescendingById())
		                        .sort(Utils.sortDescendingByProperty("type"));

		let page = sorted_items.slice(page_index * page_size, (page_index + 1) * page_size);

		page.forEach((item) => {
			this.props.itemInfoProvider(item.type, item.path);
		})

		return page;
	}

	handleItemClick = (item) => {
		this.props.onItemClicked(item)
	}
}