import {actionTypes} from "../actions/notifications";

const initialState = {
	isPending: false,
	invalidated: true,
	items: null,
	item: null,
	selectedItem: null,
	filter: null
};

function notificationsReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.get(actionTypes.REQUEST_ITEMS):
			return {
				...state,
				isPending: true,
			};
		case actionTypes.get(actionTypes.RECEIVE_ITEMS):
			return {
				...state,
				isPending: false,
				items: action.data
			};
		case actionTypes.get(actionTypes.APPEND_ITEMS):
			return {
				...state,
				isPending: false,
				items: state.items && action.data && Array.isArray(action.data) ? action.data.concat(state.items) : [].concat(action.data)
			};
		case actionTypes.get(actionTypes.SELECT_ITEM):
			return {
				...state,
				selectedItem: action.data
			};
		case actionTypes.get(actionTypes.RECEIVE_FILTER):
			return {
				...state,
				filter: action.data,
			};
		default:
			return state
	}
}

export default notificationsReducer