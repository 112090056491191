import {API} from "../api/api";
import clientFactory from "../factories/http_client";

class NotificationService {

	/** @return  Promise<AxiosResponse<T>> */
	get = (page_index, page_size) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.notifications.method,
			                             url: API.data.notifications.url(page_index, page_size)
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	getById = (projectId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.notifications.id.method,
			                             url: API.data.notifications.id.url(projectId)
		                             });
	};


	getFilter = () => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.notifications.filter.method,
			                             url: API.data.notifications.filter.url,
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             })
	}

	createFilter = (filter) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.notifications.filter.create.method,
			                             url: API.data.notifications.filter.create.url,
			                             data: API.data.notifications.filter.create.build(filter),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             })
	}
}

export default NotificationService;