import * as React from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

export default class Trend
	extends React.PureComponent {


	constructor(props, context) {

		super(props, context);

		this.state = {};
	}

	componentDidMount() {
	}

	componentDidCatch(error, errorInfo) {
		this.setState({
			              error: true
		              })
	}

	render() {

		if (this.state.error) {
			return (<div className={"trend"}/>)
		}

		let boxShadow;

		if (this.props.selectedTrend && this.props.selectedTrend.id === this.props.trend.id) {
			boxShadow = `inset 0 1px 1px #6f7e95, 0 0 8px #66d1e9`;
		} else if (this.props.highlightedTrend && this.props.highlightedTrend.id === this.props.trend.id) {
			boxShadow = `inset 0 1px 1px #6f7e95, 0 0 8px ${this.props.trendColorProvider(this.props.trend.color)}`;
		}

		return (
			<OverlayTrigger key={'to-' + this.props.trend.id}
			                placement="right"
			                delayShow={200}
			                overlay={
				                <Tooltip id="tooltip">{this.props.trend.name}</Tooltip>
			                }
			>
				<div className={"trend"}
				     style={{
					     right: (28 * this.props.scaleX) + (this.props.trendIndexProvider(this.props.trend)) * (16 * this.props.scaleX) + 'px',   // 1 - ширина border
					     backgroundColor: !this.props.backgroundMode ? this.props.trendColorProvider(this.props.trend.color) : 'grey',
					     zIndex: this.props.layer,
					     boxShadow: boxShadow
				     }}
				     onClick={(e) => {
					     e.stopPropagation();
					     if (this.props.onTrendClick) {
						     this.props.onTrendClick(this.props.trend, {
							     ctrlKey: e.ctrlKey,
							     shiftKey: e.shiftKey
						     })
					     }
				     }}
				     onDoubleClick={(e) => {
					     e.stopPropagation()
					     if (this.props.onTrendDoubleClick) {
						     this.props.onTrendDoubleClick(this.props.trend, {
							     ctrlKey: e.ctrlKey,
							     shiftKey: e.shiftKey
						     })
					     }
				     }}
				     onMouseEnter={(e) => {
					     if (this.props.onTrendHover) {
						     this.props.onTrendHover(this.props.trend, {
							     ctrlKey: e.ctrlKey,
							     shiftKey: e.shiftKey
						     })
					     }
				     }}
				     onMouseLeave={(e) => {
					     if (this.props.onTrendHover) {
						     this.props.onTrendHover(null, {
							     ctrlKey: e.ctrlKey,
							     shiftKey: e.shiftKey
						     })
					     }
				     }}
				>
				</div>
			</OverlayTrigger>
		)
	}

}