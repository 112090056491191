import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import * as numeric_units_actions from "../actions/numeric-units";
import NumericUnitsView from "../views/numeric-units";
import {authorities} from "../mixins/authorities";
import {numericUnitService} from "../services";
import * as orphans_actions from "../actions/orphans";

const mapStateToProps = (state, ownProps) => {
	return {
		numericUnits: state.numericUnitsReducer.numericUnits,
		selectedNumericUnit: state.numericUnitsReducer.selectedNumericUnit ? state.numericUnitsReducer.selectedNumericUnit : null,
		numericUnit: state.numericUnitsReducer.numericUnit ? state.numericUnitsReducer.numericUnit : null,
		orphans: state.orphans.items,
		selectedOrphan: state.orphans.selectedItem,
		numericUnitOperation: state.numericUnitsReducer.operation,
		...authorities(state.authenticationReducer.authentication)
	}
};

const mapDispatchToProps = (dispatch, state) => {
	return {
		loadInitialData: () => {
			dispatch(loadInitialDataActionChain());
		},
		selectItem: (item) => {
			dispatch(numeric_units_actions.selectNumericUnit(item));
		},
		createItem: (item) => {
			dispatch(createItemActionChain(item));
		},
		saveItem: (item) => {
			dispatch(saveItemActionChain(item));
		},
		add: () => {
			dispatch(numeric_units_actions.receiveNumericUnit({}));
		},
		edit: (item) => {
			dispatch(numeric_units_actions.fetchNumericUnit(item.id));
		},
		delete: (item) => {
			dispatch(deleteItemActionChain(item));
		},
		selectOrphan: (item) => {
			dispatch(orphans_actions.selectItem(item))
		},
		clearOrphans: () => {
			dispatch(orphans_actions.receiveItems(null));
		},
		loadOrphanItemInfo: (type, path) => {

		},
		clearOperation: () => {
			dispatch(numeric_units_actions.startOperation(null));
		}
	}
};

const loadInitialDataActionChain = () => {
	return (dispatch, getState) => {
		if (!getState().eventCategoriesReducer.isPending) {
			dispatch(numeric_units_actions.requestNumericUnits());
			numericUnitService.getAll()
			                  .then(sources_proxy => {
				                  dispatch(numeric_units_actions.receiveNumericUnits(sources_proxy.data));
			                  })
		}
	}
}

const createItemActionChain = (item) => {
	return (dispatch, getState) => {
		dispatch(numeric_units_actions.requestNumericUnits());
		numericUnitService.create(item)
		                  .then(event_source_proxy => {
			                  dispatch(numeric_units_actions.appendNumericUnit(event_source_proxy.data));
			                  dispatch(numeric_units_actions.endOperation({
				                                                              name: 'CREATION',
				                                                              status: "SUCCESS"
			                                                              }));
		                  })
		                  .catch((error) => {
			                  dispatch(numeric_units_actions.endOperation({
				                                                              name: 'CREATION',
				                                                              status: "ERROR"
			                                                              }));
		                  })
	}
}

const saveItemActionChain = (item) => {
	return (dispatch, getState) => {
		dispatch(numeric_units_actions.requestNumericUnits());
		numericUnitService.save(item)
		                  .then(sources_proxy => {
			                  dispatch(numeric_units_actions.insertNumericUnit(sources_proxy.data));
			                  dispatch(numeric_units_actions.endOperation({
				                                                              name: 'SAVING',
				                                                              status: "SUCCESS"
			                                                              }));
		                  })
		                  .catch((error) => {
			                  dispatch(numeric_units_actions.endOperation({
				                                                              name: 'SAVING',
				                                                              status: "ERROR"
			                                                              }));
		                  })
	}
}

const deleteItemActionChain = (item) => {
	return (dispatch, getState) => {
		numericUnitService.delete(item.id)
		                  .then(item_proxy => {
			                  dispatch(numeric_units_actions.fetchNumericUnits());
			                  dispatch(orphans_actions.receiveItems(null));
		                  })
		                  .catch((error) => {
			                  if (error.response.status === 409) {
				                  dispatch(orphans_actions.receiveItems(error.response.data));
			                  }
		                  });
	}
}

const NumericUnitsContainer = connect(mapStateToProps, mapDispatchToProps)(NumericUnitsView);

export default withRouter(NumericUnitsContainer)