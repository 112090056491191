import React from 'react';
import {Button, Col, DropdownButton, Form, FormGroup, Glyphicon, Grid, MenuItem, Nav, Navbar, NavItem, OverlayTrigger, Popover, Row, Tooltip} from 'react-bootstrap';


import EventsMap from '../components/controls/events-map/map';
import {CLIENT_URI_CONSTANTS as URI} from "../utils/uri-constants";
import {REFS} from '../components/controls/events-map/future-map-models';
import SimpleSearchBar from '../components/base/simple-search-bar';
import EventsRelationDialog from '../components/dialogs/events-relation';
import Tree from 'rc-tree';
import Utils from "../utils/utils";
import OperationReportDialog from "../components/dialogs/operation-report-dialog";
import DateUtils from "../utils/date";
import EventDialog from "../containers/event-dialog-container";
import ProcessOverlay from "../components/base/process-overlay";
import {Link} from "react-router-dom";
import EventTrendSelectionDialog from "../components/dialogs/event-trend-selection";


class EventsMapView
	extends React.Component {

	constructor(props) {

		super(props);

		this.flat_factors = props.factors ? Utils.getFlatFactors(props.factors) : [];

		this.state = {
			timeScale: 'days',
			selection: {},
			centeredEvent: null,
			visibleFactorsIds: [],
			additionalVisibleFactorsIds: [],
			shownEventsRelations: [],
			visibleFactors: [],
			eventsRelationFormShown: false,
			...this.setPermissions(props)
		};
	}

	componentDidMount() {

		this.props.loadInitialData();

		let eventsMapPosition = null;

		if (window.sessionStorage && this.props.project) {
			let stringPosition = window.sessionStorage.getItem(`eventsMapPosition-${this.eventsMapStorageKey()}`);
			if (stringPosition) {
				this.setState({
					              eventsMapPosition: JSON.parse(stringPosition)
				              })

			}
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (prevProps.factors !== this.props.factors && this.props.factors) {

			this.flat_factors = this.props.factors ? Utils.getFlatFactors(this.props.factors) : [];

			if (this.state.visibleFactorsIds.length === 0) {
				this.setState({
					              visibleFactors: this.props.factors
					                                  .map(this.visibleFactorMapper),
					              visibleFactorsIds: this.flat_factors.map((item) => item.id)
				              })
			} else {
				this.setState({
					              visibleFactors: this.props.factors
					                                  .filter(this.visibleFactorFilter)
					                                  .map(this.visibleFactorMapper)
				              })
			}
		}

		if (prevState.visibleFactorsIds !== this.state.visibleFactorsIds) {
			this.setState({
				              visibleFactors: this.props.factors
				                                  .filter(this.visibleFactorFilter)
				                                  .map(this.visibleFactorMapper)
			              })
		}

		if (prevProps.selectedEvents !== this.props.selectedEvents) {
			const selection = {
				...this.state.selection
			};

			selection.events = this.props.selectedEvents;

			this.setState({
				              selection: selection
			              });
		}

		if (prevProps.eventsRelations !== this.props.eventsRelations && this.props.eventsRelations) {
			this.setState({
				              shownEventsRelations: this.props.eventsRelations.filter((item) => {
					              return this.state.shownEventsRelations.findIndex((shownItem) => {
						              return shownItem.id === item.id;
					              }) !== -1
				              })
			              })
		}
	}

	componentDidCatch(error, errorInfo) {
		this.setState({
			              error: true
		              })
	}


	setPermissions = (props) => {
		if (props.project && props.isAdministrator(props.project)) {
			return {
				addEventEnabled: true,
				addFilterEnabled: true,
				listFiltersEnabled: true,
				addScenarioEnabled: true,
				addTrendEnabled: true,
				listScenariosEnabled: true,
				colorPaletteEnabled: true,
				listTrendsEnabled: true,
				addRelationEnabled: true,
				relationsMapEnabled: true,
				diagramModeEnabled: true,
				newsEnabled: true,
				exportEnabled: true,
				importEnabled: true
			}
		} else if (props.project && props.isAnalyst(props.project)) {
			return {
				addEventEnabled: true,
				addFilterEnabled: true,
				listFiltersEnabled: true,
				addTrendEnabled: true,
				addScenarioEnabled: true,
				listScenariosEnabled: true,
				colorPaletteEnabled: true,
				listTrendsEnabled: true,
				addRelationEnabled: true,
				relationsMapEnabled: true,
				diagramModeEnabled: true,
				newsEnabled: true,
				exportEnabled: true,
				importEnabled: true
			}
		} else if (props.project && props.isManager(props.project)) {
			return {
				addEventEnabled: true,
				addFilterEnabled: true,
				listFiltersEnabled: true,
				addTrendEnabled: true,
				addScenarioEnabled: true,
				listScenariosEnabled: true,
				colorPaletteEnabled: true,
				listTrendsEnabled: true,
				addRelationEnabled: true,
				relationsMapEnabled: true,
				diagramModeEnabled: true,
				newsEnabled: true,
				exportEnabled: true,
				importEnabled: true
			}
		} else {
			return {
				addEventEnabled: false,
				addFilterEnabled: true,
				listFiltersEnabled: true,
				addTrendEnabled: false,
				addScenarioEnabled: false,
				listScenariosEnabled: true,
				colorPaletteEnabled: false,
				listTrendsEnabled: true,
				addRelationEnabled: false,
				relationsMapEnabled: true,
				diagramModeEnabled: true,
				newsEnabled: true,
				exportEnabled: true,
				importEnabled: true
			}
		}
	};

	render() {

		if (this.state.error) {
			return (
				<div>Error occurred...</div>
			)
		}

		const self = this;

		let eventTypeColors = [];

		if (this.props.colorSchema) {
			if (this.props.colorSchema.event_type_colors) {
				eventTypeColors = this.props.colorSchema.event_type_colors;
			}
		}

		const eventRelations = this.state.shownEventsRelations ? this.state.shownEventsRelations : [];

		let content = null;

		if (this.props.project && this.props.project.start_date && this.props.project.end_date && this.props.factors && this.props.factors.length > 0) {
			content = (
				<EventsMap start={this.props.project.start_date}
				           end={this.props.project.end_date}
				           currentDate={this.props.project && this.props.project.day_d ? new Date(this.props.project.day_d) : null}
				           currentDateChangeDate={this.props.project && this.props.project.real_day_d ? new Date(this.props.project.real_day_d) : null}
				           dynamicCurrentDate={this.props.project && this.props.project.autoincrement_day_d}
				           factors={this.state.visibleFactors}
				           events={this.props.events ? this.props.events : []}
				           trends={this.props.trends ? this.props.trends : []}
				           selectedEvents={this.props.selectedEvents}
				           selectedTrend={this.props.selectedTrend}
				           shownEventsRelations={eventRelations}
				           eventTypeColors={eventTypeColors}
				           position={this.state.eventsMapPosition}
				           centeredEvent={this.state.centeredEvent}
				           addEventEnabled={this.state.addEventEnabled}
				           editEventEnabled={(this.props.selectedEvents && this.props.selectedEvents.length !== 0)}
				           deleteEventEnabled={(this.props.selectedEvents && this.props.selectedEvents.length !== 0)}
				           addTrendEnabled={this.state.addTrendEnabled}
				           showStraightEventRelationsEnabled={(this.props.selectedEvents && this.props.selectedEvents.length !== 0)}
				           showEventRelationsEnabled={(this.props.selectedEvents && this.props.selectedEvents.length !== 0)}
				           onEventsSelectionChange={this.handleEventsSelectionChange}
				           onTrendSelectionChange={this.handleTrendSelectionChange}
				           onAddEvent={this.onAddEventHandler}
				           onEditEvent={this.onEditEventHandler}
				           onCopyEvent={this.onCopyEventHandler}
				           onDeleteEvent={this.onDeleteEventHandler}
				           onAddTrend={this.onAddTrendHandler}
				           onShowStraightEventRelations={this.handleShowStraightEventRelations}
				           onShowEventRelations={this.handleShowEventRelations}
				           onShowAllEventRelations={this.handleShowEventsRelations}
				           onHideEventsRelations={this.handleHideEventsRelations}
				           scrollPositionChangeHandler={this.handleScrollPositionChange}
				           onEventMove={this.handleEventMove}
				           onEventImpactChange={this.handleEventImpactChange}
				           onEventProbabilityChange={this.handleEventProbabilityChange}
				           onCurrentDayChange={this.handleCurrentDateChange}
				/>
			)
		} else if (this.props.factors && this.props.factors.length === 0) {
			content = (
				<ProcessOverlay>
					Event Map is empty because there are no factors. Please add factors first in the
					<Link to={`/project/${this.props.project ? this.props.project.id : ""}/factors-map`}> Factor Map.</Link>
				</ProcessOverlay>
			)
		} else {
			content = <ProcessOverlay>Loading...</ProcessOverlay>
		}


		return (
			<Grid fluid={true}
			      className="display-flex align-items-stretch flex-direction-column h-100">
				<Row className="toolbar">
					<Col md={12}>
						<Navbar className="toolbar" fluid={true}>
							<Nav>
								<NavItem>
									<OverlayTrigger placement="bottom"
									                overlay={<Tooltip id="tooltip">Refresh</Tooltip>}>
										<Button bsClass="btn bar-button" onClick={this.refreshButtonHandler}>
											<Glyphicon glyph="refresh"/>
										</Button>
									</OverlayTrigger>
								</NavItem>
								{
									this.state.addEventEnabled &&
									<NavItem>
										<OverlayTrigger placement="bottom"
										                overlay={<Tooltip id="tooltip">Add event</Tooltip>}>
											<Button bsClass="btn bar-button" onClick={
												(e) => {
													if (this.state.selection.cells && Object.keys(this.state.selection.cells).length !== 0) {

														const cellKey = Object.keys(this.state.selection.cells)[0];

														const cell = this.state.selection.cells[cellKey];

														this.onAddEventHandler(cell.factor, cell.date);
													} else {
														this.onAddEventHandler()
													}
												}
											}>
												<Glyphicon glyph="plus" className="c-glyph-icon"/>
												EVENT
											</Button>
										</OverlayTrigger>
									</NavItem>
								}
								{/*{
									(this.state.addFilterEnabled || this.state.listFiltersEnabled) &&
									<NavItem>
										<OverlayTrigger trigger="click" rootClose placement="bottom"
										                overlay={filterPopover}>
											<OverlayTrigger placement="bottom"
											                overlay={<Tooltip id="tooltip">Filters</Tooltip>}>
												<Button bsClass="btn bar-button">
													<Glyphicon glyph="filter" className="c-glyph-icon"/>
													FILTERS
												</Button>
											</OverlayTrigger>
										</OverlayTrigger>
									</NavItem>
								}*/}
								{
									(this.state.addScenarioEnabled || this.state.listScenariosEnabled) &&
									<NavItem>
										<OverlayTrigger trigger="click"
										                container={this}
										                rootClose
										                placement="bottom"
										                overlay={
											                <ScenariosPopover addEnabled={this.state.addScenarioEnabled}
											                                  listEnabled={this.state.listScenariosEnabled}
											                                  onCreate={this.createScenarioButtonHandler}
											                                  onList={this.scenariosListButtonHandler}
											                />
										                }>
											<OverlayTrigger placement="bottom"
											                overlay={<Tooltip id="tooltip">Scenarios</Tooltip>}>
												<Button bsClass="btn bar-button">
													<Glyphicon glyph="list-alt" className="c-glyph-icon"/>
													SCENARIOS
												</Button>
											</OverlayTrigger>
										</OverlayTrigger>
									</NavItem>
								}
								{
									this.state.colorPaletteEnabled &&
									<NavItem>
										<OverlayTrigger placement="bottom"
										                overlay={<Tooltip id="tooltip">Palette</Tooltip>}>
											<Button bsClass="btn bar-button" onClick={this.colorsButtonClickHandler}>
												<Glyphicon glyph="tint" className="c-glyph-icon"/>
												COLORS
											</Button>
										</OverlayTrigger>
									</NavItem>
								}
								<NavItem>
									<OverlayTrigger trigger="click"
									                rootClose
									                placement="bottom"
									                overlay={
										                <FactorsPopover factors={this.props.factors}
										                                visibleFactors={this.state.visibleFactorsIds}
										                                onCheck={this.onToggleFactorVisibility}
										                />
									                }
									>
										<OverlayTrigger placement="bottom"
										                overlay={<Tooltip id="tooltip">Factors filter</Tooltip>}>
											<Button bsClass="btn bar-button">
												<Glyphicon glyph="eye-close" className="c-glyph-icon"/>
												FACTORS
											</Button>
										</OverlayTrigger>
									</OverlayTrigger>
								</NavItem>
								<NavItem>
									<OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip">Trends</Tooltip>}>
										<Button bsClass="btn bar-button" onClick={this.trendsListButtonHandler}>
											<Glyphicon glyph="random" className="c-glyph-icon"/>
											TRENDS
										</Button>
									</OverlayTrigger>
								</NavItem>
								{
									(this.state.exportEnabled || this.state.importEnabled) &&
									<NavItem>
										<OverlayTrigger trigger="click" rootClose placement="bottom"
										                overlay={
											                <ImportExportPopover exportEnabled={this.state.exportEnabled}
											                                     importEnabled={this.state.importEnabled}
											                                     onImport={this.handleImportEventsClicked}
											                                     onExport={this.handleExportEventsClicked}
											                />
										                }
										>
											<OverlayTrigger placement="bottom"
											                overlay={<Tooltip id="tooltip">Export/Import</Tooltip>}>
												<Button bsClass="btn bar-button">
													<Glyphicon glyph="list-alt" className="c-glyph-icon"/>
													EXPORT/IMPORT
												</Button>
											</OverlayTrigger>
										</OverlayTrigger>
									</NavItem>
								}
								{
									this.state.addRelationEnabled &&
									<NavItem>
										<OverlayTrigger placement="bottom"
										                overlay={<Tooltip id="tooltip">Create relate</Tooltip>}>
											<Button bsClass="btn bar-button" onClick={this.createRelationHandler}>
												<Glyphicon glyph="link" className="c-glyph-icon"/>
												RELATE
											</Button>
										</OverlayTrigger>
									</NavItem>
								}
								{
									this.state.relationsMapEnabled &&
									<NavItem>
										<OverlayTrigger placement="bottom"
										                overlay={<Tooltip id="tooltip">Event relates map</Tooltip>}>
											<Button bsClass="btn bar-button" onClick={this.relationsMapButtonHandler}>
												<Glyphicon glyph="new-window" className="c-glyph-icon"/>
												RELATIONS MAP
											</Button>
										</OverlayTrigger>
									</NavItem>
								}
								{/*{
									this.state.diagramModeEnabled &&
									<NavItem>
										<OverlayTrigger placement="bottom"
										                overlay={<Tooltip id="tooltip">Diagrams mode</Tooltip>}>
											<Button bsClass="btn bar-button" onClick={this.deleteButtonHandler}>
												<Glyphicon glyph="stats" className="c-glyph-icon"/>
												DIAGRAMS
											</Button>
										</OverlayTrigger>
									</NavItem>
								}*/}
								{/*{
									this.state.newsEnabled &&
									<NavItem>
										<OverlayTrigger placement="bottom"
										                overlay={<Tooltip id="tooltip">Search notificaitons</Tooltip>}>
											<Button bsClass="btn bar-button" onClick={this.deleteButtonHandler}>
												<Glyphicon glyph="globe" className="c-glyph-icon"/>
												NEWS
											</Button>
										</OverlayTrigger>
									</NavItem>
								}*/}
								<DropdownButton id="events-map-nav-dropdown"
								                title=">>"
								                noCaret>
									{
										(this.state.exportEnabled) &&
										<MenuItem onClick={this.handleExportEventsClicked}>
											Export
										</MenuItem>
									}
									{
										(this.state.importEnabled) &&
										<MenuItem onClick={this.handleImportEventsClicked}>
											Import
										</MenuItem>
									}
									{
										this.state.addRelationEnabled &&
										<MenuItem onClick={this.createRelationHandler}>
											Relate
										</MenuItem>
									}
									{
										this.state.relationsMapEnabled &&
										<MenuItem onClick={this.relationsMapButtonHandler}>
											Relations Map
										</MenuItem>
									}
									{/*{
										this.state.diagramModeEnabled &&
										<MenuItem>
											Diagrams
										</MenuItem>
									}*/}
									{/*{
										this.state.newsEnabled &&
										<MenuItem>
											News
										</MenuItem>
									}*/}
								</DropdownButton>
							</Nav>
							<SimpleSearchBar ref={REFS.SEARCH_BAR_REF}
							                 items={this.props.events}
							                 field='title'
							                 className="search-form"
							                 handleSearch={this.searchButtonClickHandler}/>
						</Navbar>
					</Col>
				</Row>
				<Row className="content">
					<Col md={12} className="h-100">
						{content}
					</Col>
				</Row>
				<EventsRelationDialog ref={REFS.EVENTS_RELATION_MODAL_REF}
				                      show={this.state.eventsRelationFormShown}
				                      items={this.props.events}
				                      leftEvent={(this.props.selectedEvents && this.props.selectedEvents.length > 0) ? this.props.selectedEvents[0] : null}
				                      rightEvent={(this.props.selectedEvents && this.props.selectedEvents.length > 1) ? this.props.selectedEvents[1] : null}
				                      onSave={this.handleSaveRelation}
				                      onCancel={
					                    () => {
						                    self.setState({
							                                  eventsRelationFormShown: false
						                                  })
					                    }
				                    }
				/>
				<OperationReportDialog show={this.props.operationReport != null}
				                       data={this.props.operationReport}
				                       onCancel={
					                       () => {
						                       this.props.clearOperationReport()
					                       }
				                       }
				                       labelProvider={(entry, type) => {
					                       if (type === 'success') {
						                       return 'Event(id= ' + entry.object_id + ') successfully imported';
					                       } else if (type === 'warning') {

					                       } else if (type === 'error') {
						                       if (entry.code === 'FACTOR_NOT_FOUND') {
							                       return 'Factor not found for event(id=' + entry.object_id + ')';
						                       } else if (entry.code === 'PROBABILITY_OUT_OF_BOUNDS') {
							                       return 'Probability of event(id= ' + entry.object_id + ') is out of bounds';
						                       } else if (entry.code === 'IMPACT_OUT_OF_BOUNDS') {
							                       return 'Impact of event(id= ' + entry.object_id + ') is out of bounds';
						                       } else if (entry.code === 'DATE_OUT_OF_BOUNDS') {
							                       return 'Date of event(id= ' + entry.object_id + ') is out of bounds';
						                       }
					                       }
				                       }}
				/>
				{
					this.props.history.location.search.includes("event-id") &&
					<EventDialog />
				}
				{
					this.state.possible_event_trends &&
					<EventTrendSelectionDialog show={this.state.possible_event_trends && this.state.possible_event_trends.length > 0}
					                           items={this.state.possible_event_trends}
					                           onProceed={
						                           (trend) => {
							                           this.handleEventTrendSelection(this.state.trends_selection_event, trend)
						                           }
					                           }
					                           onHide={
						                           () => {
							                           this.setState({
								                                         trends_selection_event: null,
								                                         possible_event_trends: null
							                                         })
						                           }
					                           }
					/>
				}
			</Grid>
		);
	}

	handleCurrentDateChange = (data) => {
		this.props.saveCurrentDate(this.props.project, data.date, data.dynamic);
	};

	visibleFactorFilter = (element) => {
		return this.state.visibleFactorsIds.indexOf(element.id) !== -1 || this.state.additionalVisibleFactorsIds.indexOf(
			element.id) !== -1;
	};

	visibleFactorMapper = (element) => {
		return {
			...element,
			children: element.children
			                 .filter(this.visibleFactorFilter)
			                 .map(this.visibleFactorMapper)
		}
	};

	handleEventImpactChange = (id, impact) => {

		const events = this.props.events.filter((event) => {
			return event.id == id;
		});

		const event = events.length !== 0 ? events[0] : null;

		if (event) {

			event.average_impact = parseInt(impact);

			this.props.saveEvent(event);
		}
	};

	handleEventProbabilityChange = (id, probability) => {

		const events = this.props.events.filter((event) => {
			return event.id == id;
		});

		const event = events.length !== 0 ? events[0] : null;

		if (event) {

			event.average_probability = parseInt(probability);

			this.props.saveEvent(event);
		}
	};

	handleEventMove = (id, factor, period) => {

		const events = this.props.events.filter((event) => {
			return event.id == id;
		});

		const event = events.length !== 0 ? events[0] : null;

		if (event) {

			event.date = DateUtils.convertToISOUTCDatetime(period.start);

			event.factor = {
				id: factor.id,
			};

			this.props.moveEvent(this.props.project, event);
		}

		if (this.props.trends) {

			let possible_event_trends = this.props.trends.filter(item => {
				return item.factor.id === factor.id && new Date(item.start) <= period.start && period.start <= new Date(item.end);
			});

			if (possible_event_trends.length > 0) {
				this.setState({
					              trends_selection_event: event,
					              possible_event_trends: possible_event_trends
				              })
			}
		}
	};

	handleExportEventsClicked = (event) => {
		this.props.exportEvents(this.props.project);
	};

	handleImportEventsClicked = (event) => {

		const self = this;

		function handleFiles() {

			let fileList = this.files;

			fileList && fileList.length !== 0 && self.props.importEvents(self.props.project,
			                                                             fileList[0]);
		}

		this.refs.importEventsUpload.addEventListener("change",
		                                              handleFiles,
		                                              false);
		this.refs.importEventsUpload.click();
	};

	onToggleFactorVisibility = (keys, event) => {
		this.setState({
			              visibleFactorsIds: keys.map(x => parseInt(x)),
			              additionalVisibleFactorsIds: event.halfCheckedKeys.map(x => parseInt(x))
		              })
	};

	eventsMapStorageKey = () => {
		return this.props.project.id;
	};

	handleScrollPositionChange = (scrollTop, scrollLeft) => {

		clearTimeout(this.handler_id)

		this.handler_id = setTimeout((timestamp) => {
			if (window.sessionStorage) {
				window.sessionStorage.setItem(`eventsMapPosition-${this.eventsMapStorageKey()}`,
				                              JSON.stringify({
					                                             top: scrollTop,
					                                             left: scrollLeft
				                                             }));
			}
		}, 1000)
	};

	handleEventsSelectionChange = (events) => {
		this.props.selectEvents(events);
	};

	handleTrendSelectionChange = (events) => {
		this.props.selectTrend(events);
	};

	refreshButtonHandler = () => {

		this.props.loadFactors(this.props.project);

		this.props.loadEvents(this.props.project);

		this.props.loadTrends(this.props.project);

		this.props.loadColorSchema(this.props.project,
		                           this.props.principal());
	};

	searchButtonClickHandler = (event) => {
		if (event) {
			this.setState({
				              centeredEvent: event
			              });
		}
	};

	createFilterButtonHandler = () => {
		this.props.history.push(`/${URI.PROJECT}/${this.props.project.id}/${URI.FILTER}/0`);
	};

	filtersListButtonHandler = () => {
		this.props.history.push(`/${URI.PROJECT}/${this.props.project.id}/${URI.FILTERS}`);
	};

	createScenarioButtonHandler = () => {
		this.props.addScenario(this.props.project);
	};

	scenariosListButtonHandler = () => {
		this.props.history.push(`/${URI.PROJECT}/${this.props.project.id}/${URI.SCENARIOS}`);
	};

	trendsListButtonHandler = () => {
		this.props.history.push(`/${URI.PROJECT}/${this.props.project.id}/${URI.TRENDS}`);
	};

	createRelationHandler = () => {
		const selectedEvents = this.props.selectedEvents;
		if (selectedEvents && selectedEvents.length > 0) {
			this.setState({
				              eventsRelationFormShown: true
			              });
		}
	};

	colorsButtonClickHandler = (e) => {
		this.props.history.push(`/${URI.PROJECT}/${this.props.project.id}/${URI.COLOR_SCHEME}`);
	};

	relationsMapButtonHandler = () => {
		const selectedEvents = this.props.selectedEvents;
		if (selectedEvents && selectedEvents.length > 0) {
			this.setState({
				              eventsRelationFormShown: true
			              });
		}
		if (selectedEvents.length > 0) {
			this.props.history.push(
				`/${URI.PROJECT}/${this.props.project.id}/${URI.EVENT}/${selectedEvents[0].id}/${URI.RELATIONS_MAP}`);
		}
	};

	copyEvent = (copied, factor, date, cb) => {
		eventService.copy(this.props.project.id,
		                  this.futureMapId,
		                  copied,
		                  factor,
		                  date)
		            .then(event_proxy => {
			            if (cb)
				            cb(event_proxy.data);
		            })
	};

	handleSaveRelation = (eventsRelation) => {
		this.props.saveEventRelation(this.props.project,
		                             eventsRelation);
		this.setState({
			              eventsRelationFormShown: false
		              });
	};

	onAddEventHandler = (factor, date) => {
		this.props.addEvent(this.props.project, factor, date);
	};

	onEditEventHandler = (event) => {
		this.props.editEvent(this.props.project,
		                     this.props.selectedEvents[0]);
	};

	onCopyEventHandler = () => {

	};

	onDeleteEventHandler = (event) => {
		if (this.props.selectedEvents && this.props.selectedEvents.length !== 0 && confirm(`Are you sure you want to delete event: ${this.props.selectedEvents[0].title}?`)) {
			this.props.deleteEvent(this.props.project,
			                       this.props.selectedEvents[0].id)
		}
	};

	onAddTrendHandler = (factor, startDate, endDate) => {
		this.props.addTrend(this.props.project, factor, startDate, endDate);
	};


	onEditTrendHandler = (trend) => {
		this.props.editTrend(this.props.project, this.props.selectedTrend);
	};


	handleShowStraightEventRelations = () => {
		if (this.props.eventsRelations && this.props.eventsRelations.length > 0) {

			const event = this.props.selectedEvents && this.props.selectedEvents.length > 0 ? this.props.selectedEvents[0] : null;

			this.setState({
				              shownEventsRelations: this.collectStraightRelations(event)
			              });
		}
	};

	collectStraightRelations = (event) => {

		const straightRelationReducer = (collector, element) => {
			if (collector.findIndex((relation) => {
				return (relation.left_event.id === element.left_event.id && relation.right_event.id === element.right_event.id) ||
					relation.right_event.id === element.left_event.id && relation.right_event.id === element.left_event.id;
			}) !== -1) {
				return collector;
			} else {
				const straightRelations = this.props.eventsRelations.filter(function (filteredElement) {
					return filteredElement.left_event.id === element.right_event.id;
				});
				collector.push(...straightRelations);
				return straightRelations.reduce(straightRelationReducer, collector);
			}
		};

		const sourceRelations = this.props.eventsRelations
		                            .filter(function (element) {
			                            return element.left_event.id === event.id;
		                            });

		return sourceRelations.reduce(straightRelationReducer, [...sourceRelations]);
	};

	collectReverseRelations = (event) => {

		const reverseRelationsReducer = (collector, element) => {
			if (collector.findIndex((relation) => {
				return (relation.left_event.id === element.left_event.id && relation.right_event.id === element.right_event.id) ||
					relation.right_event.id === element.left_event.id && relation.right_event.id === element.left_event.id;
			}) !== -1) {
				return collector;
			} else {
				const reverseRelations = this.props.eventsRelations.filter(function (filteredElement) {
					return filteredElement.right_event.id === element.left_event.id;
				});
				collector.push(...reverseRelations);
				return reverseRelations.reduce(reverseRelationsReducer, collector);
			}
		};

		const reverseRelations = this.props.eventsRelations
		                             .filter(function (element) {
			                             return element.right_event.id === event.id;
		                             });

		return reverseRelations.reduce(reverseRelationsReducer, [...reverseRelations]);
	};

	handleShowEventRelations = () => {
		if (this.props.eventsRelations && this.props.eventsRelations.length > 0) {

			const event = this.props.selectedEvents && this.props.selectedEvents.length > 0 ? this.props.selectedEvents[0] : null;

			const reverseRelations = this.collectReverseRelations(event);

			const straightRelations = this.collectStraightRelations(event);

			straightRelations.push(...reverseRelations);

			this.setState({
				              shownEventsRelations: straightRelations
			              });
		}
	};

	handleShowEventsRelations = () => {
		this.setState({
			              shownEventsRelations: this.props.eventsRelations
		              });
	};

	handleHideEventsRelations = () => {
		this.setState({
			              shownEventsRelations: []
		              });
	}

	handleEventTrendSelection(event, trend) {

		this.setState({
			              trends_selection_event: null,
			              possible_event_trends: null
		              })
		
		this.props.addEventToTrend(event, trend);
	}
}

class FactorsPopover
	extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {

		const factorToTreeNodeMapper = (element) => {
			return (
				<Tree.TreeNode key={element.id}
				               title={element.title}
				               icon={
					               function (props) {
						               return null;
					               }
				               }>
					{
						element.children.map(factorToTreeNodeMapper)
					}
				</Tree.TreeNode>
			)
		};

		return (
			<Popover id="factors-tree-popover"
			         style={this.props.style}
			         className={this.props.className + " factors-tree-popover"}
			         placement={this.props.placement}
			         positionLeft={this.props.positionLeft}
			         positionTop={this.props.positionTop}
			         arrowOffsetLeft={this.props.arrowOffsetLeft}
			         arrowOffsetTop={this.props.arrowOffsetTop}>
				<Tree checkable={true}
				      multiple={true}
				      defaultExpandAll={true}
				      checkedKeys={this.props.visibleFactors}
				      onCheck={this.props.onCheck}>
					{
						this.props.factors && this.props.factors.map(function (element) {
							return factorToTreeNodeMapper(element);
						})
					}
				</Tree>
			</Popover>
		)
	}
}

class ScenariosPopover
	extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {

		return (
			<Popover id="scenario-popover"
			         style={this.props.style}
			         className={this.props.className}
			         placement={this.props.placement}
			         positionLeft={this.props.positionLeft}
			         positionTop={this.props.positionTop}
			         arrowOffsetLeft={this.props.arrowOffsetLeft}
			         arrowOffsetTop={this.props.arrowOffsetTop}
			>
				<Form>
					<FormGroup>
						<Col md={12}>
							{
								this.props.addEnabled &&
								<Button bsClass="btn btn-default popover-button"
								        onClick={this.props.onCreate}>New Scenario</Button>
							}
						</Col>
					</FormGroup>
					<FormGroup>
						<Col md={12}>
							{
								this.props.listEnabled &&
								<Button bsClass="btn btn-default popover-button"
								        onClick={this.props.onList}>Saved Scenarios</Button>
							}
						</Col>
					</FormGroup>
				</Form>
			</Popover>
		)
	}
}

class FiltersPopover
	extends React.Component {

	constructor(props, context) {
		super(props, context);
	}

	render() {
		return (
			<Popover id="filter-popover"
			         style={this.props.style}
			         className={this.props.className}
			         placement={this.props.placement}
			         positionLeft={this.props.positionLeft}
			         positionTop={this.props.positionTop}
			         arrowOffsetLeft={this.props.arrowOffsetLeft}
			         arrowOffsetTop={this.props.arrowOffsetTop}
			>
				<Form>
					<FormGroup>
						<Col md={12}>
							{
								this.state.addFilterEnabled &&
								<Button bsClass="btn btn-default popover-button"
								        onClick={this.createFilterButtonHandler}>New Filter</Button>
							}
						</Col>
					</FormGroup>
					<FormGroup>
						<Col md={12}>
							{
								this.state.listFiltersEnabled &&
								<Button bsClass="btn btn-default popover-button"
								        onClick={this.filtersListButtonHandler}>Saved Filters</Button>
							}
						</Col>
					</FormGroup>
				</Form>
			</Popover>
		)
	}
}

class ImportExportPopover
	extends React.Component {

	constructor(props, context) {
		super(props, context);
	}

	render() {
		return (
			<Popover id="export-import-popover"
			         style={this.props.style}
			         className={this.props.className + " export-import-popover"}
			         placement={this.props.placement}
			         positionLeft={this.props.positionLeft}
			         positionTop={this.props.positionTop}
			         arrowOffsetLeft={this.props.arrowOffsetLeft}
			         arrowOffsetTop={this.props.arrowOffsetTop}
			>
				<Form>
					<FormGroup>
						<Col md={12}>
							{
								this.props.exportEnabled &&
								<Button bsClass="btn btn-default popover-button"
								        onClick={this.props.onExport}>Export</Button>
							}
						</Col>
					</FormGroup>
					<FormGroup>
						<Col md={12}>
							<input ref="importEventsUpload" type="file" hidden style={{display: 'none'}}/>
							{
								this.props.importEnabled &&
								<Button bsClass="btn btn-default popover-button"
								        onClick={this.props.onImport}>Import</Button>
							}
						</Col>
					</FormGroup>
				</Form>
			</Popover>
		)
	}
}

export default EventsMapView;