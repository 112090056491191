import * as React from "react";
import {EventDesired, EventState} from "../event/event-models";
import {Button, Col, ControlLabel, Form, FormGroup, Glyphicon, Grid, Image, InputGroup, Modal, OverlayTrigger, Popover, Row, Tab, Tabs, Tooltip} from "react-bootstrap";
import Utils from "../../utils/utils";
import TreeSelect, {TreeNode} from "rc-tree-select";
import {Combobox, DateTimePicker, DropdownList, NumberPicker} from "react-widgets";
import SimpleSelectionForm from "../base/simple-selection-form";
import TreeSelectionForm from "../base/tree-selection-form";
import AttachmentForm from "../common/attachment-form";
import Summernote from "../base/summernote-base";
import DateUtils from "../../utils/date";
import mathjs from "mathjs";
import AvailableDictionaryItemsSelect from "./available-dictionary-items-select";
import {AttachmentsTab, CommentsTab, LinksTab} from "../common/tab";
import LinkForm from "../common/link-form";
import {eventCommentService} from "../../services";
import RelationsTab from "../event/relations-tab";
import EventRelationForm from "./events-relation";
import {mixinFactory as OperationMixinFactory} from "../../mixins/operation";
import {mixinFactory as ChangedStateMixinFactory} from "../../mixins/changed-state";

export default class EventDialog
	extends React.Component {

	constructor(props) {

		super(props);

		let state = null;

		Object.assign(EventDialog.prototype, OperationMixinFactory({
			                                                           operationPropName: 'eventOperation',
			                                                           operationStateName: 'operationStatus'
		                                                           }))

		Object.assign(EventDialog.prototype, ChangedStateMixinFactory({
			                                                              scope: 'change_detector',
			                                                              observable: 'event',
			                                                              excludedFields: [
				                                                              'activeTab',
				                                                              'operationStatus',
				                                                              'showAuthorityModal',
				                                                              'showLinkModal',
				                                                              'showAttachmentModal',
				                                                              'eventsRelationFormShown',
				                                                              'saveEnabled',
				                                                              'addLinkEnabled',
				                                                              'editLinkEnabled',
				                                                              'removeLinkEnabled',
				                                                              'addMessageEnabled',
				                                                              'editMessageEnabled',
				                                                              'deleteMessageEnabled',
				                                                              'addAttachmentEnabled',
				                                                              'editAttachmentEnabled',
				                                                              'deleteAttachmentEnabled',
				                                                              'addRelationEnabled',
				                                                              'deleteRelationEnabled',
				                                                              'manageEventCategoriesEnabled',
				                                                              'manageEventSourcesEnabled',
				                                                              'manageEventTypesEnabled',
				                                                              'showFactorModal',
				                                                              'showFormulaModal',
				                                                              'showImageModal',
				                                                              'showParentModal',
				                                                              'author'
			                                                              ],
			                                                              stateField: 'dirty'
		                                                              }))

		if (props.event && props.event.id) {
			state = EventState.fromCode(props.event.state);
		} else {
			if (props.event && props.event.date && props.project && props.project.day_d) {
				state = new Date(props.event.date) > new Date(props.project.day_d) ? EventState.WAITING : EventState.COMPLETED
			} else {
				state = EventState.WAITING
			}
		}

		this.state = {
			parent: props.event && props.event.id ? props.event.parent : null,
			parentId: props.event && props.event.id ? (props.event.parent ? props.event.parent.id : null) : null,
			factor: props.event ? props.event.factor : null,
			factorId: props.event ? (props.event.factor ? props.event.factor.id : null) : null,
			key: props.event && props.event.id ? props.event.key : "",
			date: props.event ? new Date(props.event.date) : null,
			deadline: props.event && props.event.id ? props.event.dead_line : null,
			deadlineActive: false,
			category: props.event && props.event.id ? props.event.category : null,
			source: props.event && props.event.id ? props.event.source : null,
			type: props.event && props.event.id ? props.event.type : null,
			quality: props.event && props.event.id ? props.event.source_quality : 0,
			title: props.event && props.event.id ? props.event.title : "",
			author: props.event && props.event.id ? props.event.author : "",
			averageProbability: props.event && props.event.id ? props.event.average_probability : 0,
			relativeProbability: props.event && props.event.id && props.event.relative_probability ? props.event.relative_probability : 100,
			averageImpact: props.event && props.event.id ? props.event.average_impact : 0,
			state: state,
			desired: props.event && props.event.id ? EventDesired.fromCode(props.event.desired) : EventDesired.WAITING,
			blocked: false,
			keywords: props.event && props.event.id ? props.event.keywords : "",
			formula: props.event && props.event.id ? props.event.formula : "",
			formulaResult: "",
			numericUnit: null,
			flag: props.event && props.event.id ? props.event.flag : null,
			description: props.event && props.event.id ? props.event.description : "",
			showParentModal: false,
			showFactorModal: false,
			showImageModal: false,
			showFormulaModal: false,
			activeTab: 1,
			showAuthorityModal: false,
			showLinkModal: false,
			showAttachmentModal: false,
			eventsRelationFormShown: false,
			...this.setPermissions(props)
		};

		if (props.event) {
			this.change_detector.setInitialState(this.state);
		}

		this.flagItem = ({item}) => {
			if (item) {
				return (
					<Image src={Utils.getResourceUri(item.path)}/>
				)
			} else {
				return null;
			}
		};

		this.formulaHelpPopover = (
			<Popover id="formula-help-popover" title="Information">
				To select an object to insert, press CTRL + Space.
			</Popover>
		);

		this.manage_addon = {
			id: -1,
			title: "Manage..."
		}
	}

	componentDidMount() {

		let matches = this.props.location.search.match(/event-id=(\d*)/i);

		if (matches && matches[1] != 0) {
			this.props.loadInitialData(matches[1])
		} else {
			this.props.loadDictionaries()
		}

		this.operationState(null, null);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.event && this.props.event !== prevProps.event) {

			let state;

			if (this.props.event && this.props.event.id) {
				state = EventState.fromCode(this.props.event.state);
			} else {
				if (this.props.event && this.props.event.date && this.props.project && this.props.project.day_d) {
					state = new Date(this.props.event.date) > new Date(this.props.project.day_d) ? EventState.WAITING : EventState.COMPLETED
				} else {
					state = EventState.WAITING
				}
			}

			this.setState({
				              parent: this.props.event ? this.props.event.parent : null,
				              parentId: this.props.event ? (this.props.event.parent ? this.props.event.parent.id : null) : null,
				              factor: this.props.event ? this.props.event.factor : null,
				              factorId: this.props.event ? (this.props.event.factor ? this.props.event.factor.id : null) : null,
				              key: this.props.event ? this.props.event.key : "",
				              date: this.props.event ? new Date(this.props.event.date) : null,
				              deadline: this.props.event ? this.props.event.dead_line : null,
				              deadlineActive: false,
				              category: this.props.event ? this.props.event.category : null,
				              source: this.props.event ? this.props.event.source : null,
				              type: this.props.event ? this.props.event.type : null,
				              quality: this.props.event ? this.props.event.source_quality : 0,
				              title: this.props.event ? this.props.event.title : "",
				              author: this.props.event ? this.props.event.author : "",
				              averageProbability: this.props.event ? this.props.event.average_probability : 0,
				              relativeProbability: this.props.event ? this.props.event.relative_probability : 100,
				              averageImpact: this.props.event ? this.props.event.average_impact : 0,
				              state: state,
				              desired: this.props.event ? EventDesired.fromCode(this.props.event.desired) : EventDesired.WAITING,
				              blocked: false,
				              keywords: this.props.event ? this.props.event.keywords : "",
				              formula: this.props.event ? this.props.event.formula : "",
				              formulaResult: "",
				              numericUnit: null,
				              flag: this.props.event ? this.props.event.flag : null,
				              description: this.props.event ? this.props.event.description : "",
				              showAuthorityModal: !!this.props.userAuthority,
				              showLinkModal: !!this.props.link,
				              showAttachmentModal: !!this.props.attachment
			              },
			              () => {
				              this.change_detector.setInitialState(this.state);
			              });
		}

		if (this.state.category === this.manage_addon && prevState.category !== this.manage_addon) {
			this.setState({
				              categorySelectionDialogVisible: true
			              })
		}

		if (this.state.source === this.manage_addon && prevState.source !== this.manage_addon) {
			this.setState({
				              sourceSelectionDialogVisible: true
			              })
		}

		if (this.state.type === this.manage_addon && prevState.type !== this.manage_addon) {
			this.setState({
				              typeSelectionDialogVisible: true
			              })
		}

		this.operationState(prevProps, prevState);

		if (prevProps.attachment !== this.props.attachment && this.props.attachment != null) {
			this.setState({
				              showAttachmentModal: true
			              })
		}


		if (prevProps.link !== this.props.link && this.props.link != null) {
			this.setState({
				              showLinkModal: true
			              })
		}

		this.detectChanged(prevProps, prevState);
	}

	setPermissions = (props) => {
		if (props.project && props.isAdministrator(props.project)) {
			return {
				saveEnabled: true,
				addLinkEnabled: true,
				editLinkEnabled: true,
				removeLinkEnabled: true,
				addMessageEnabled: true,
				editMessageEnabled: true,
				deleteMessageEnabled: true,
				addAttachmentEnabled: true,
				editAttachmentEnabled: true,
				deleteAttachmentEnabled: true,
				addRelationEnabled: true,
				deleteRelationEnabled: true,
				manageEventCategoriesEnabled: true,
				manageEventSourcesEnabled: true,
				manageEventTypesEnabled: true
			}
		} else if (props.project && props.isAnalyst(props.project)) {
			return {
				saveEnabled: true,
				addLinkEnabled: true,
				editLinkEnabled: true,
				removeLinkEnabled: true,
				addMessageEnabled: true,
				editMessageEnabled: false,
				deleteMessageEnabled: false,
				addAttachmentEnabled: true,
				editAttachmentEnabled: true,
				deleteAttachmentEnabled: true,
				addRelationEnabled: true,
				deleteRelationEnabled: true,
				manageEventCategoriesEnabled: true,
				manageEventSourcesEnabled: true,
				manageEventTypesEnabled: true
			}
		} else if (props.project && props.isManager(props.project)) {
			return {
				saveEnabled: false,
				addLinkEnabled: true,
				editLinkEnabled: true,
				removeLinkEnabled: true,
				addMessageEnabled: true,
				editMessageEnabled: false,
				deleteMessageEnabled: false,
				addAttachmentEnabled: true,
				editAttachmentEnabled: true,
				deleteAttachmentEnabled: true,
				addRelationEnabled: true,
				deleteRelationEnabled: true,
				manageEventCategoriesEnabled: true,
				manageEventSourcesEnabled: true,
				manageEventTypesEnabled: true
			}
		} else {
			return {
				saveEnabled: false,
				addLinkEnabled: false,
				editLinkEnabled: false,
				removeLinkEnabled: false,
				addMessageEnabled: true,
				editMessageEnabled: false,
				deleteMessageEnabled: false,
				addAttachmentEnabled: false,
				editAttachmentEnabled: false,
				deleteAttachmentEnabled: false,
				addRelationEnabled: false,
				deleteRelationEnabled: false,
				manageEventCategoriesEnabled: false,
				manageEventSourcesEnabled: false,
				manageEventTypesEnabled: false
			}
		}
	};


	render() {
		const dateFormat = "DD.MM.YYYY";

		let tabs = null;

		let saveButtonClass = "btn action-button pull-right";

		if (this.state.operationStatus === "IN_PROGRESS") {
			saveButtonClass = saveButtonClass + " pending";
		} else {
			if (this.state.operationStatus && this.state.operationStatus !== "NOT_PENDING") {
				saveButtonClass = this.state.operationStatus === "SUCCESS" ? saveButtonClass + " btn-success" : saveButtonClass + " btn-danger"
			}
		}

		let changes_indicator;

		if (this.props.event && this.props.event.id && (this.state.dirty || this['change_detector'].initialization_counter > 0)) {
			changes_indicator = this.state.dirty ? <div className="dirty-indicator">Changes unsaved</div> : <div className="dirty-indicator success"><Glyphicon glyph={"ok"}/>Changes saved</div>;
		}

		tabs = this.renderTabs();

		let image_control;

		if (this.props.event && this.props.event.id) {
			if (this.props.event.image && this.props.event.image.length > 0) {
				image_control = (
					<InputGroup>
						<input className="form-control"
						       type="text"
						       disabled={true}
						       value={this.props.event.image[0].name}
						/>
						<InputGroup.Button>
							<OverlayTrigger placement="bottom"
							                overlay={
								                <Tooltip id="image-tooltip">Delete image</Tooltip>
							                }>
								<Button onClick={this.handleDeleteImage}>
									<Glyphicon glyph="remove"/>
								</Button>
							</OverlayTrigger>
						</InputGroup.Button>
					</InputGroup>
				)
			} else {
				image_control = (
					<Button bsClass="btn action-button" className="m-0" onClick={this.addImageHandler}>Attach</Button>
				)
			}
		}

		const eventToTreeNodeMapper = (element) => {
			return (!this.props.factor || element.id !== this.props.factor.id) ? (
				<TreeNode key={element.id}
				          title={element.title}
				          value={element.id}
				          isLeaf={!element.children || element.children
				                                              .filter((element) => {
					                                              return !this.props.event || element.id !== this.props.event.id;
				                                              }).length === 0
				          }
				          disabled={false}
				>
					{
						element.children
						       .filter((element) => {
							       return !this.props.event || element.id !== this.props.event.id;
						       })
						       .map(factorToTreeNodeMapper)
					}
				</TreeNode>
			) : null;
		};

		const factorToTreeNodeMapper = (element) => {
			return (
				<TreeNode key={element.id}
				          title={element.title}
				          value={element.id}
				          isLeaf={!element.children || element.children.length === 0
				          }
				          disabled={false}
				>
					{
						element.children.map(factorToTreeNodeMapper)
					}
				</TreeNode>
			);
		};

		return (
			<Modal.Dialog bsSize="large" className="event-dialog">
				<Modal.Header>
					<Modal.Title>{this.state.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form horizontal={true} className="h-100 event-form">
						<FormGroup>
							<Col componentClass={ControlLabel} md={1}>
								Parent:
							</Col>
							<Col md={4}>
								{
									<TreeSelect style={{width: '100%'}}
									            transitionName="rc-tree-select-dropdown-slide-up"
									            choiceTransitionName="rc-tree-select-selection__choice-zoom"
									            dropdownStyle={{
										            maxHeight: 200,
										            overflow: 'auto'
									            }}
									            placeholder={"Select parent"}
									            searchPlaceholder="please search"
									            showSearch={true}
									            allowClear={true}
									            treeLine={true}
									            searchValue={this.state.parentSearchValue}
									            value={this.state.parentId}
									            treeNodeFilterProp="title"
									            filterTreeNode={false}
									            onSearch={(value) => {
										            this.setState({parentSearchValue: value});
									            }}
									            open={this.state.eventSelectOpened}
									            onChange={(value, ...args) => {
										            this.setState({parentId: value});
									            }}
									            onDropdownVisibleChange={(v, info) => {
										            this.setState({
											                          eventSelectOpened: v,
										                          });
										            return true;
									            }}
									>
										{
											this.props.events && this.props.events
											                         .filter((element) => {
												                         return !this.props.event || element.id !== this.props.event.id;
											                         })
											                         .map((element) => {
												                         return eventToTreeNodeMapper(element);
											                         })
										}
									</TreeSelect>
								}
							</Col>
							<Col componentClass={ControlLabel} md={2}>
								Date:
							</Col>
							<Col md={2}>
								<DateTimePicker onChange={this.dateChangeHandler}
								                value={this.state.date}
								                time={false}
								                format={dateFormat}
								                min={this.props.project ? new Date(this.props.project.start_date) : null}
								                max={this.props.project ? new Date(this.props.project.end_date) : null}
								/>
							</Col>
						</FormGroup>
						<FormGroup>
							<Col componentClass={ControlLabel} md={1}>
								Factor:
							</Col>
							<Col md={4}>
								{
									<TreeSelect style={{width: '100%'}}
									            transitionName="rc-tree-select-dropdown-slide-up"
									            choiceTransitionName="rc-tree-select-selection__choice-zoom"
									            dropdownStyle={{
										            maxHeight: 200,
										            overflow: 'auto'
									            }}
									            placeholder={"Select factor"}
									            searchPlaceholder="please search"
									            showSearch={true}
									            allowClear={true}
									            treeLine={true}
									            searchValue={this.state.factorSearchValue}
									            value={this.state.factorId}
									            treeNodeFilterProp="title"
									            filterTreeNode={false}
									            onSearch={(value) => {
										            this.setState({factorSearchValue: value});
									            }}
									            open={this.state.factorSelectOpened}
									            onChange={(value, ...args) => {
										            this.setState({factorId: value});
									            }}
									            onDropdownVisibleChange={(v, info) => {
										            this.setState({
											                          factorSelectOpened: v,
										                          });
										            return true;
									            }}
									>
										{
											this.props.factors && this.props.factors
											                          .filter((element) => {
												                          return !this.props.factor || element.id !== this.props.factor.id;
											                          })
											                          .map((element) => {
												                          return factorToTreeNodeMapper(element);
											                          })
										}
									</TreeSelect>
								}
							</Col>
							<Col componentClass={ControlLabel} md={2}>
								Deadline:
							</Col>
							<Col md={3}>
								<Grid fluid={true} className="p-0">
									<Row>
										<Col md={8}>
											<DateTimePicker onChange={this.setValue.bind(this, 'deadline')}
											                value={this.state.deadline}
											                disabled={!this.state.deadlineActive}
											                time={false}
											                format={dateFormat}
											                min={this.state.date}
											                max={this.props.project ? new Date(
												                this.props.project.end_date) : null}
											/>
										</Col>
										<Col md={1} className="p-0">
											<input type="checkbox" value={this.state.deadlineActive} onChange={this.deadlineActiveChangeHandler}
											/>
										</Col>
									</Row>
								</Grid>
							</Col>
						</FormGroup>
						<FormGroup>
							<Col componentClass={ControlLabel} md={1}>
								Category:
							</Col>
							<Col md={4}>
								<Combobox onChange={this.setValue.bind(this, 'category')}
								          defaultValue={this.state.category ? this.state.category : ''}
								          data={this.props.projectEventCategories ? [...this.props.projectEventCategories, this.manage_addon] : [this.manage_addon]}
								          caseSensitive={false}
								          textField='title'
								          value={this.state.category}/>
							</Col>
							<Col componentClass={ControlLabel} md={2}>
								Type:
							</Col>
							<Col md={4}>
								<Combobox onChange={this.setValue.bind(this, 'type')}
								          defaultValue={this.state.type ? this.state.type : ''}
								          data={this.props.projectEventTypes ? [...this.props.projectEventTypes, this.manage_addon] : [this.manage_addon]}
								          caseSensitive={false}
								          textField='title'
								          value={this.state.type}/>
							</Col>
						</FormGroup>
						<FormGroup>
							<Col componentClass={ControlLabel} md={1}>
								Source:
							</Col>
							<Col md={2}>
								<Combobox onChange={this.setValue.bind(this, 'source')}
								          defaultValue={this.state.source ? this.state.source : ''}
								          data={this.props.projectEventSources ? [...this.props.projectEventSources, this.manage_addon] : [this.manage_addon]}
								          caseSensitive={false}
								          textField='title'
								          value={this.state.source}/>
							</Col>
							<Col componentClass={ControlLabel} md={1}>
								Quality:
							</Col>
							<Col md={1}>
								<NumberPicker onChange={this.setValue.bind(this, 'quality')}
								              defaultValue={this.state.quality ? this.state.quality : 0}
								              min={0}
								              max={100}
								              value={this.state.quality}/>
							</Col>
							<Col componentClass={ControlLabel} md={2}>
								Flag:
							</Col>
							<Col md={2}>
								<DropdownList data={this.props.flags ? this.props.flags : []}
								              onChange={this.setValue.bind(this, 'flag')}
								              value={this.state.flag}
								              itemComponent={this.flagItem}
								              valueComponent={this.flagItem}
								/>
							</Col>
						</FormGroup>
						<FormGroup>
							<Col componentClass={ControlLabel} md={1}>
								Title:
							</Col>
							<Col md={4}>
								<input className="form-control"
								       type="text"
								       onChange={this.setEventValue.bind(this, 'title')}
								       value={this.state.title}/>
							</Col>
							<Col componentClass={ControlLabel} md={2}>
								Avg. probability:
							</Col>
							<Col md={4} style={{padding: 0}}>
								<Col md={5}>
									<InputGroup>
										<input className="form-control"
										       type="text"
										       onChange={(e) => {
											       if (e.target.value === "" || parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 100) {
												       this.setState({
													                     averageProbability: e.target.value
												                     })
											       }
										       }}
										       value={this.state.averageProbability}/>
										<InputGroup.Addon style={{
											paddingLeft: '3px',
											paddingRight: '3px'
										}}>
											{this.state.relativeProbability ? `${this.state.relativeProbability}%` : '%'}
										</InputGroup.Addon>
									</InputGroup>
								</Col>
								<Col componentClass={ControlLabel} md={3}>
									Completion:
								</Col>
								<Col md={4}>
									<Combobox onChange={this.setValue.bind(this, 'state')}
									          defaultValue={this.state.state ? this.state.state : ''}
									          data={EventState.enumValues}
									          caseSensitive={false}
									          textField='text'
									          value={this.state.state}
									          disabled={this.state.blocked}
									/>
								</Col>
							</Col>
						</FormGroup>
						<FormGroup>
							<Col componentClass={ControlLabel} md={1}>
								Author:
							</Col>
							<Col md={4}>
								<input className="form-control"
								       type="text"
								       readOnly={true}
								       value={Utils.userName(this.state.author)}/>
							</Col>
							<Col componentClass={ControlLabel} md={2}>
								Avg. impact:
							</Col>
							<Col md={4} style={{padding: 0}}>
								<Col md={5}>
									<input className="form-control"
									       type="text"
									       onChange={this.setEventValue.bind(this, 'averageImpact')}
									       value={this.state.averageImpact}/>
								</Col>
								<Col componentClass={ControlLabel} md={3}>
									Desired:
								</Col>
								<Col md={4}>
									<Combobox onChange={this.setValue.bind(this, 'desired')}
									          defaultValue={this.state.desired ? this.state.desired : ''}
									          data={EventDesired.enumValues}
									          caseSensitive={false}
									          textField='text'
									          value={this.state.desired}/>
								</Col>
							</Col>
						</FormGroup>
						<FormGroup>
							<Col componentClass={ControlLabel} md={1}>
								Keywords:
							</Col>
							<Col md={4}>
								<input className="form-control"
								       type="text"
								       onChange={this.setEventValue.bind(this, 'keywords')}
								       value={this.state.keywords}/>
							</Col>
							{
								this.props.event && this.props.event.id &&
								<Col componentClass={ControlLabel} md={2}>
									Image:
								</Col>
							}
							<Col md={4}>
								{image_control}
							</Col>
						</FormGroup>
						<FormGroup>
							<Col componentClass={ControlLabel} md={1}>
								Formula:
							</Col>
							<Col md={4}>
								<InputGroup>
									<input className="form-control"
									       type="text"
									       onChange={this.formulaChangeHandler}
									       onKeyDown={this.selectFormulaRelation}
									       value={this.state.formula}/>
									<InputGroup.Button>
										<OverlayTrigger trigger="click" placement="right" rootClose
										                overlay={this.formulaHelpPopover}>
											<Button>
												<Glyphicon glyph="question-sign"/>
											</Button>
										</OverlayTrigger>
									</InputGroup.Button>
								</InputGroup>
							</Col>
							<Col componentClass={ControlLabel} md={2}>
								Numeric unit:
							</Col>
							<Col md={4} style={{padding: 0}}>
								<Col md={5}>
									<Combobox onChange={this.setValue.bind(this, 'numericUnit')}
									          defaultValue={this.state.numericUnit ? this.state.numericUnit : ''}
									          data={this.props.numericUnits ? this.props.numericUnits : []}
									          caseSensitive={false}
									          textField='title'
									          value={this.state.numericUnit}/>
								</Col>
							</Col>
						</FormGroup>
						<FormGroup>
							<Col componentClass={ControlLabel} md={1}>
								Calc. result:
							</Col>
							<Col componentClass={ControlLabel} md={2} style={{textAlign: "left"}}>
								{this.state.formulaResult}
							</Col>
						</FormGroup>
						<FormGroup className="tabs-row">
							{tabs}
						</FormGroup>
						<FormGroup className="form-buttons-bar">
							<Col md={12}>
								<Button bsClass="btn action-button pull-right" onClick={this.cancelButtonHandler}>
									Close
								</Button>
								{
									this.state.saveEnabled &&
									<Button bsClass={saveButtonClass}
									        onClick={this.state.operationStatus !== "IN_PROGRESS" ? this.saveButtonHandler : null}>
										{this.props.event && this.props.event.id ? "Save" : "Create"}
									</Button>
								}
								{changes_indicator}
							</Col>
						</FormGroup>
						{
							this.state.showParentModal && (
								<SimpleSelectionForm show={this.state.showParentModal}
								                     onHide={() => this.setState({showParentModal: false})}
								                     items={this.props.events}
								                     default={this.state.parent}
								                     field='title'
								                     onSave={this.acceptParentModal}
								                     onCancel={this.cancelParentModal}/>
							)
						}
						{
							this.state.showFactorModal && (
								<TreeSelectionForm show={this.state.showFactorModal}
								                   onHide={() => this.setState({showFactorModal: false})}
								                   items={this.props.factors}
								                   default={this.state.factor}
								                   field='title'
								                   onSave={this.acceptFactorModal}
								                   onCancel={this.cancelFactorModal}/>
							)
						}
						{
							this.state.showImageModal && (
								<AttachmentForm show={this.state.showImageModal}
								                onHide={() => this.setState({showImageModal: false})}
								                editable={true}
								                onSave={this.handleSaveImage}
								/>
							)
						}
						{
							this.state.showFormulaModal && (
								<SimpleSelectionForm show={this.state.showFormulaModal}
								                     onHide={() => this.setState({showFormulaModal: false})}
								                     items={this.props.events}
								                     field='title'
								                     onSave={this.acceptFormulaModal}
								                     onCancel={this.cancelFormulaModal}/>
							)
						}
						{
							this.state.categorySelectionDialogVisible &&
							this.state.manageEventCategoriesEnabled &&
							<AvailableDictionaryItemsSelect show={this.state.categorySelectionDialogVisible}
							                                onHide={() => {
								                                this.setState({
									                                              categorySelectionDialogVisible: false,
									                                              category: this.props.event ? this.props.event.category : null
								                                              })
							                                }}
							                                items={this.props.eventCategories}
							                                selectedItems={this.props.projectEventCategories}
							                                onSave={
								                                (eventCategories) => {

									                                this.props.addProjectEventCategories(eventCategories);

									                                this.setState({
										                                              categorySelectionDialogVisible: false,
										                                              category: this.props.event ? this.props.event.category : null
									                                              })
								                                }
							                                }
							/>
						}
						{
							this.state.sourceSelectionDialogVisible &&
							this.state.manageEventSourcesEnabled &&
							<AvailableDictionaryItemsSelect show={this.state.sourceSelectionDialogVisible}
							                                onHide={() => {
								                                this.setState({
									                                              sourceSelectionDialogVisible: false,
									                                              source: this.props.event ? this.props.event.source : null
								                                              })
							                                }}
							                                items={this.props.eventSources}
							                                selectedItems={this.props.projectEventSources}
							                                onSave={
								                                (event_sources) => {

									                                this.props.addProjectEventSources(event_sources);

									                                this.setState({
										                                              sourceSelectionDialogVisible: false,
										                                              source: this.props.event ? this.props.event.source : null
									                                              })
								                                }
							                                }
							/>
						}
						{
							this.state.typeSelectionDialogVisible &&
							this.state.manageEventTypesEnabled &&
							<AvailableDictionaryItemsSelect show={this.state.typeSelectionDialogVisible}
							                                onHide={() => {
								                                this.setState({
									                                              typeSelectionDialogVisible: false,
									                                              type: this.props.event ? this.props.event.type : null
								                                              })
							                                }}
							                                items={this.props.eventTypes}
							                                selectedItems={this.props.projectEventTypes}
							                                onSave={
								                                (event_sources) => {

									                                this.props.addProjectEventTypes(event_sources);

									                                this.setState({
										                                              typeSelectionDialogVisible: false,
										                                              type: this.props.event ? this.props.event.type : null
									                                              })
								                                }
							                                }
							/>
						}
					</Form>
				</Modal.Body>
			</Modal.Dialog>

		);
	}

	renderTabs = () => {
		let self = this;
		return (
			<Tabs activeKey={this.state.activeTab} onSelect={this.tabSelectionHandler} id="event-edit-tabs" className="h-100">
				<Tab eventKey={1} title="Description">
					<div className="description-wrapper">
						<Summernote value={this.state.description}
						            height={190}
						            onChange={this.setValue.bind(this, 'description')}
						/>
					</div>
				</Tab>
				{
					this.props.event && this.props.event.id &&
					<Tab eventKey={2} title="Links/Sources">
						<LinksTab items={self.props.event ? self.props.event.links : []}
						          selectedItem={self.props.selectedLink}
						          hasAddButton={self.state.addLinkEnabled}
						          hasEditButton={self.state.editLinkEnabled}
						          hasDeleteButton={self.state.removeLinkEnabled}
						          onItemClick={this.selectLinkHandler}
						          onAdd={
							          (link) => {
								          this.setState({
									                        showLinkModal: true
								                        });
							          }
						          }
						          onEdit={
							          (link) => {
								          if (!self.props.selectedLink) {
									          alert("You must select item from table");
								          } else {
									          this.props.editLink(this.props.project, this.props.event,
									                              this.props.selectedLink);
								          }
							          }
						          }
						          onDelete={
							          (link) => {
								          if (!self.props.selectedLink) {
									          alert("You must select item from table");
								          } else {
									          if (confirm(`Are you sure you want to delete link: ${this.props.selectedLink.name}?`)) {
										          this.props.deleteLink(this.props.project, this.props.event,
										                                this.props.selectedLink);
									          }
								          }
							          }
						          }
						/>
						{
							this.state.showLinkModal && (
								<LinkForm show={this.state.showLinkModal}
								          onHide={this.cancelLinkHandler}
								          link={this.props.link}
								          onSave={this.saveLinkHandler}
								/>
							)
						}
					</Tab>
				}
				{
					this.props.event && this.props.event.id &&
					<Tab eventKey={3} title="Discussion">
						<CommentsTab comments={this.props.event.comments}
						             isCommentEditable={(comment) => {
							             return self.state.editMessageEnabled || self.props.isEntityOwner(comment,
							                                                                              'EventComment');
						             }}
						             isCommentRemovable={(comment) => {
							             return self.state.deleteMessageEnabled || self.props.isEntityOwner(comment,
							                                                                                'EventComment');
						             }}
						             onAddComment={(comment, cb) => {
							             comment.event = {
								             id: this.props.event.id,
								             retrospectionId: this.props.event.retrospectionId
							             };
							             eventCommentService.create(this.props.project.id, this.props.project.eventsMap.id,
							                                        this.props.event.id, comment)
							                                .then(upComment_promise => {
								                                if (cb) {
									                                cb(upComment_promise.data);
								                                }
							                                });
						             }}
						             onEditComment={(comment, cb) => {
							             comment.event = {
								             id: this.props.event.id,
								             retrospectionId: this.props.event.retrospectionId
							             };
							             eventCommentService.save(this.props.project.id, this.props.project.eventsMap.id,
							                                      this.props.event.id, comment)
							                                .then(upComment_promise => {
								                                if (cb) {
									                                cb(upComment_promise.data);
								                                }
							                                });
						             }}
						             onDeleteComment={(commentId, cb) => {
							             if (confirm(`Are you sure you want to delete comment?`)) {
								             eventCommentService.delete(this.props.project.id, this.props.project.eventsMap.id,
								                                        this.props.event.id, commentId)
								                                .then(upCommentId_promise => {
									                                if (cb) {
										                                cb(upCommentId_promise.data);
									                                }
								                                });
							             }
						             }}
						/>
					</Tab>
				}
				{
					this.props.event && this.props.event.id &&
					<Tab eventKey={4} title="Attachments">
						<AttachmentsTab items={this.props.event.attachments}
						                selectedItem={self.props.selectedAttachment}
						                hasAddButton={self.state.addAttachmentEnabled}
						                hasEditButton={self.state.editAttachmentEnabled}
						                hasDeleteButton={self.state.deleteAttachmentEnabled}
						                onItemClick={this.selectAttachmentHandler}
						                onAdd={
							                () => {
								                self.setState({
									                              showAttachmentModal: true
								                              });
							                }
						                }
						                onEdit={
							                (attachment) => {
								                if (!self.props.selectedAttachment) {
									                alert("You must select item from table");
								                } else {
									                this.props.editAttachment(this.props.project, this.props.event,
									                                          this.props.selectedAttachment);
								                }
							                }
						                }
						                onDelete={
							                (attachment) => {
								                if (!self.props.selectedAttachment) {
									                alert("You must select item from table");
								                } else {
									                if (confirm(`Are you sure you want to delete attachment: ${this.props.selectedAttachment.name}?`)) {
										                this.props.deleteAttachment(this.props.project, this.props.event,
										                                            this.props.selectedAttachment);
									                }
								                }
							                }
						                }
						/>
						{
							this.state.showAttachmentModal && (
								<AttachmentForm show={this.state.showAttachmentModal}
								                onHide={this.cancelAttachmentHandler}
								                attachment={this.props.attachment}
								                onSave={this.saveAttachmentHandler}
								/>
							)
						}
					</Tab>
				}
				{
					this.props.event && this.props.event.id &&
					<Tab eventKey={5} title="Relates to">
						<RelationsTab event={this.props.event}
						              relations={this.props.event.source_relations}
						              selected={this.props.selectedRelation}
						              events={this.props.events}
						              hasAddButton={self.state.addRelationEnabled}
						              hasDeleteButton={self.state.deleteRelationEnabled}
						              onItemClick={this.handleRelationSelect}
						              onAdd={this.handleAddEventRelation}
						              onDelete={this.handleDeleteRelation}
						/>
						{
							this.state.eventsRelationFormShown && (
								<EventRelationForm show={this.state.eventsRelationFormShown}
								                   items={this.props.events.sort(Utils.sortDescendingByProperty('create_date'))}
								                   leftEvent={this.props.event}
								                   onSave={this.handleSaveEventRelation}
								                   onCancel={() => this.setState({eventsRelationFormShown: false})}
								/>
							)
						}
					</Tab>
				}
			</Tabs>
		)
	};

	selectLinkHandler = (link) => {
		this.props.selectLink(link);
	};

	saveLinkHandler = (link) => {
		this.props.saveLink(this.props.project, this.props.event, link);
	};

	cancelLinkHandler = () => {
		this.setState({
			              showLinkModal: false
		              });
		this.props.clearLink();
	};

	selectAttachmentHandler = (attachment) => {
		this.props.selectAttachment(attachment);
	};

	saveAttachmentHandler = (attachment) => {
		this.setState({
			              showAttachmentModal: false
		              })
		this.props.saveAttachment(this.props.project, this.props.event, attachment);
	};

	cancelAttachmentHandler = () => {
		this.setState({
			              showAttachmentModal: false
		              });
		this.props.clearAttachment();
	};

	handleRelationSelect = (relation) => {
		this.props.selectEventRelation(relation);
	};

	dateChangeHandler = (value) => {
		if (value && this.state.deadline && value > this.state.deadline) {
			this.setState({
				              date: value,
				              deadline: value
			              });
		} else {
			this.setState({
				              date: value
			              });
		}
	};

	deadlineActiveChangeHandler = (event) => {
		const value = event.target.checked;
		if (value) {
			this.setState({
				              deadlineActive: value,
				              deadline: this.state.deadline ? this.state.deadline : this.state.date
			              })
		} else {
			this.setState({
				              deadlineActive: value,
				              deadline: null
			              })
		}
	};

	tabSelectionHandler = (tabIndex) => {
		this.setState({activeTab: tabIndex});
	};

	acceptParentModal = (parent) => {
		this.setState({
			              parent: parent,
			              showParentModal: false
		              });
	};

	cancelParentModal = () => {
		this.setState({
			              showParentModal: false
		              });
	};

	acceptFactorModal = (factor) => {
		this.setState({
			              factor: factor,
			              showFactorModal: false
		              });
	};

	cancelFactorModal = () => {
		this.setState({
			              showFactorModal: false
		              });
	};

	addImageHandler = () => {
		this.setState({
			              showImageModal: true
		              });
	};

	handleSaveImage = (imageAttachment) => {
		this.props.saveImage(this.props.project, this.props.event, imageAttachment);
		this.setState({
			              showImageModal: false
		              });
	};

	handleDeleteImage = () => {
		if (confirm("Are you sure you want to delete image?")) {
			this.props.deleteImage(this.props.project, this.props.event, this.props.event.image[0]);
		}
	};

	handleAddEventRelation = () => {
		this.setState({
			              eventsRelationFormShown: true
		              });
	};

	handleSaveEventRelation = (eventRelation) => {
		this.props.saveRelation(this.props.project, eventRelation);
		this.setState({
			              eventsRelationFormShown: false
		              });
	};

	handleDeleteRelation = (eventRelation) => {
		if (!this.props.selectedRelation) {
			alert("You must select item from table");
		} else {
			if (confirm("Are you sure you want to delete relation?")) {
				this.props.deleteEventRelation(this.props.project, this.props.selectedRelation);
			}
		}
	};

	selectFormulaRelation = (changeEvent) => {
		const keyCode = changeEvent.keyCode;
		const ctrlKey = changeEvent.ctrlKey;
		if (ctrlKey && keyCode === 32) {
			this.setState({
				              showFormulaModal: true
			              })
		}
	};

	acceptFormulaModal = (object) => {
		let formulaResult = this.state.formulaResult;
		try {
			formulaResult = this.calculateResult(value, {[this.props.event.id]: null});
		} catch (exp) {
			if (exp.name === 'LoopException' || exp.name === 'EvaluateException') {
				formulaResult = exp.message;
			} else {
				formulaResult = "Expression is incorrect";
			}
		}
		this.setState({
			              formula: `${this.state.formula ? this.state.formula : ""}x${object.id}`,
			              formulaResult: formulaResult,
			              showFormulaModal: false
		              });
	};

	cancelFormulaModal = () => {
		this.setState({
			              showFormulaModal: false
		              });
	};

	saveButtonHandler = () => {

		if (this.state.formula) {
			try {
				this.calculateResult(this.state.formula, {[this.props.event.id]: null});
			} catch (exp) {
				alert("Formula must be correct");
				return;
			}
		}

		const event = this.props.event;

		if (this.state.parentId) {

			const possibleParents = this.props.events.filter(element => element.id === this.state.parentId);

			event.parent = possibleParents.length !== 0 ? possibleParents[0] : null;
		} else {
			event.parent = null;
		}

		event.factor = {
			id: this.state.factorId
		};
		event.key = this.state.key;
		event.date = DateUtils.convertToISOUTCDatetime(this.state.date);
		event.dead_line = this.state.deadline ? DateUtils.convertToISOUTCDatetime(this.state.deadline) : null;
		event.category = this.state.category;
		event.source = this.state.source;
		event.type = this.state.type;
		event.source_quality = this.state.quality;
		event.title = this.state.title;
		event.author = this.state.author;
		event.average_probability = this.state.averageProbability;
		event.relative_probability = this.state.relativeProbability;
		event.average_impact = this.state.averageImpact;
		event.state = this.state.state ? this.state.state.code : null;
		event.desired = this.state.desired ? this.state.desired.code : null;
		event.blocked = this.state.blocked;
		event.keywords = this.state.keywords;
		event.formula = this.state.formula;
		event.numeric_unit = this.state.numericUnit;
		event.flag = this.state.flag;
		event.description = this.state.description;
		event.images = this.state.images;

		if (event.id) {
			this.props.saveEvent(event);
		} else {
			this.props.createEvent(event);
		}
	};

	cancelButtonHandler = () => {
		this.props.history.push(`/project/${this.props.project.id}/events-map`);
	};

	formulaChangeHandler = (changeEvent) => {
		const value = changeEvent.target.value;
		if (value) {
			try {
				const res = this.calculateResult(value, {[this.props.event.id]: null});
				this.setState({
					              formula: value,
					              formulaResult: res
				              });
			} catch (exp) {
				if (exp.name === 'LoopException' || exp.name === 'EvaluateException') {
					this.setState({
						              formula: value,
						              formulaResult: exp.message
					              });
				} else {
					this.setState({
						              formula: value,
						              formulaResult: "Expression is incorrect"
					              });
				}
			}
		} else {
			this.setState({
				              formula: value
			              });
		}
	};

	calculateResult = (expression, results) => {
		const variables = {};
		const matches = expression.match(FORMULA_RE);
		if (matches) {
			matches.forEach(x => {
				const id = x.substring(1);
				const event = this.props.events.filter(event => event.id === +id)[0];

				if (!event) {
					throw new EvaluateException(`Object with id = ${id} not found`);
				}

				if (id in results) {
					if (results[id] === null) {
						throw new LoopException(`Object ${event.title} leads to endless loop`);
					} else {
						variables[x] = results[id];
					}
				} else {
					try {
						results[id] = null;
						const res = this.calculateResult(event.formula, results);
						variables[x] = res;
						results[id] = res;
					} catch (exp) {
						throw new EvaluateException(`Object ${event.title} cannot be evaluated`, exp);
					}
				}
			})
		}

		return mathjs.eval(expression, variables);
	};

	setEventValue = (field, event) => {
		//If the input fields were directly within this
		//this component, we could use this.refs.[FIELD].value
		//Instead, we want to save the data for when the form is submitted
		const object = {};
		object[field] = event.target.value;
		this.setState(object);
	};

	setEventChecked = (field, event) => {
		//If the input fields were directly within this
		//this component, we could use this.refs.[FIELD].value
		//Instead, we want to save the data for when the form is submitted
		const object = {};
		object[field] = event.target.checked;
		this.setState(object);
	};

	setValue = (field, value) => {
		//If the input fields were directly within this
		//this component, we could use this.refs.[FIELD].value
		//Instead, we want to save the data for when the form is submitted
		const object = {};
		object[field] = value;
		this.setState(object);
	};
}