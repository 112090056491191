import React from 'react';
import {DateTimePicker, NumberPicker, Multiselect} from 'react-widgets';
import {Button, Col, ControlLabel, Form, FormGroup, Glyphicon, Grid, InputGroup, Navbar, OverlayTrigger, Popover, Row, Tab, Table, Tabs, Tooltip} from 'react-bootstrap';

import {projectCommentService} from '../services';
import {AttachmentsTab, CommentsTab, LinksTab} from '../components/common/tab';
import Summernote from "../components/base/summernote-base";
import EventCategoriesTab from "../components/common/tab/event-categories-tab";
import EventTypesTab from "../components/common/tab/event-types-tab";
import EventSourcesTab from "../components/common/tab/event-sources-tab";
import AuthorityForm from "../components/common/authority-form";
import JournalTab from "../components/common/tab/journal-tab";
import KeywordsTab from "../components/common/tab/keywords-tab";
import LinkForm from "../components/common/link-form";
import AttachmentForm from "../components/common/attachment-form";
import DateUtils from "../utils/date";
import {mixinFactory as ChangedStateMixinFactory} from "../mixins/changed-state";
import {mixinFactory as OperationMixinFactory} from "../mixins/operation";


class ProjectInfoView
	extends React.Component {

	constructor(props) {

		super(props);

		const now = new Date();

		Object.assign(ProjectInfoView.prototype, OperationMixinFactory({
			                                                           operationPropName: 'projectOperation',
			                                                           operationStateName: 'operationStatus'
		                                                           }))

		Object.assign(ProjectInfoView.prototype, ChangedStateMixinFactory({
			                                                              scope: 'change_detector',
			                                                              observable: 'project',
			                                                              excludedFields: [
				                                                              'operationStatus',
				                                                              'showAuthorityModal',
				                                                              'showLinkModal',
				                                                              'showAttachmentModal',
				                                                              'saveEnabled',
				                                                              'addLinkEnabled',
				                                                              'editLinkEnabled',
				                                                              'removeLinkEnabled',
				                                                              'addMessageEnabled',
				                                                              'editMessageEnabled',
				                                                              'deleteMessageEnabled',
				                                                              'addAttachmentEnabled',
				                                                              'editAttachmentEnabled',
				                                                              'deleteAttachmentEnabled',
				                                                              'selectEventCategoriesEnabled',
				                                                              'selectEventTypesEnabled',
				                                                              'selectEventSourcesEnabled',
				                                                              'authoritiesTabVisible',
				                                                              'addAuthoritiesEnabled',
				                                                              'deleteAuthoritiesEnabled',
				                                                              'author',
				                                                              'activeTab'
			                                                              ],
			                                                              stateField: 'dirty'
		                                                              }))

		let alerts = []

		if (props.project && props.project.first_notification != null) {
			alerts.push(props.project.first_notification)
		}

		if (props.project && props.project.second_notification != null) {
			alerts.push(props.project.second_notification)
		}

		if (props.project && props.project.third_notification != null) {
			alerts.push(props.project.third_notification)
		}

		this.state = {
			name: props.project && props.project.id ? props.project.name : "",
			keywords: props.project && props.project.id ? props.project.keywords : "",
			start: props.project && props.project.id ? new Date(props.project.start_date) : now,
			end: props.project && props.project.id ? new Date(props.project.end_date) : now,
			currentDay: props.project && props.project.id && props.project.day_d ? new Date(props.project.day_d) : null,
			dynamic: props.project && props.project.id ? props.project.autoincrement_day_d : false,
			rubrics: props.project && props.project.rubrics && props.project.rubrics.length > 0 ? JSON.parse(props.project.rubrics) : [],
			description: props.project && props.project.id ? props.project.description : "",
			activeTab: 2,
			showAuthorityModal: false,
			showLinkModal: false,
			showAttachmentModal: false,
			ban_notifications: props.project && props.project.ban_notification != null ? props.project.ban_notification : false,
			alerts: alerts,
			...this.setPermissions(props)
		};

		this.rubrics = [
			'Рубрика: Hi-Tech',
			'Рубрика: Lifestyle',
			'Рубрика: Авто & Мото',
			'Рубрика: В мире',
			'Рубрика: Главное',
			'Рубрика: Здоровье',
			'Рубрика: Культура',
			'Рубрика: Наука',
			'Рубрика: Общество',
			'Рубрика: Политика',
			'Рубрика: Проишествия',
			'Рубрика: Путешествия',
			'Рубрика: Развлечения',
			'Рубрика: Регионы',
			'Рубрика: Спорт',
			'Рубрика: Фото & Дизайн',
			'Рубрика: Экономика',
			'Hi-Tech: Гаджеты',
			'Hi-Tech: Игры',
			'Hi-Tech: Интернет',
			'Hi-Tech: Техника',
			'Hi-Tech: Другое',
			'Lifestyle: Бодибилдинг',
			'Lifestyle: Еда',
			'Lifestyle: Красота',
			'Lifestyle: Лайфхак',
			'Lifestyle: Мода',
			'Lifestyle: Светская жизнь',
			'Lifestyle: Фитнес',
			'Lifestyle: Другое',
			'Авто & Мото: Автомобили',
			'Авто & Мото: Мотоциклы',
			'Авто & Мото: Другое',
			'Культура: Выставки',
			'Культура: Кино',
			'Культура: Книги',
			'Культура: Концерты',
			'Культура: Музыка',
			'Культура: Театр',
			'Культура: Другое',
			'Общество: Безопасность',
			'Общество: ЖКХ',
			'Общество: Образование',
			'Общество: Право',
			'Общество: Религия',
			'Общество: Социальная политика',
			'Общество: Экология',
			'Общество: Другое',
			'Регионы: Дальневосточный федеральный округ',
			'Регионы: Приволжский федеральный округ',
			'Регионы: Северо-Западный федеральный округ',
			'Регионы: Северо-Кавказский федеральный округ',
			'Регионы: Сибирский федеральный округ',
			'Регионы: Уральский федеральный округ',
			'Регионы: Центральный федеральный округ',
			'Регионы: Южный федеральный округ',
			'Спорт: Автоспорт',
			'Спорт: Баскетбол',
			'Спорт: Биатлон',
			'Спорт: Бокс',
			'Спорт: Борьба и единоборства',
			'Спорт: Велоспорт',
			'Спорт: Волейбол',
			'Спорт: Керлинг',
			'Спорт: Конькобежный спорт',
			'Спорт: Легкая атлетика',
			'Спорт: Лыжные гонки',
			'Спорт: Теннис',
			'Спорт: Фигурное катание',
			'Спорт: Футбол',
			'Спорт: Хоккей',
			'Спорт: Другое',
			'Фото & Дизайн: Фото',
			'Фото & Дизайн: Дизайн',
			'Экономика: Крупный бизнес',
			'Экономика: Недвижимость',
			'Экономика: Предпринимательство',
			'Экономика: Промышленность',
			'Экономика: Сельское хозяйство',
			'Экономика: Страхование',
			'Экономика: Транспорт',
			'Экономика: Финансы & Рынки',
			'Экономика: Другое',
		]

		this['change_detector'].setInitialState(this.state);
	}

	componentDidMount() {

		if (this.props.project.id) {
			this.props.loadInitialData();
		}

		this.operationState(null, null);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.project !== this.props.project) {

			let alerts = []

			if (this.props.project && this.props.project.first_notification != null) {
				alerts.push(this.props.project.first_notification)
			}

			if (this.props.project && this.props.project.second_notification != null) {
				alerts.push(this.props.project.second_notification)
			}

			if (this.props.project && this.props.project.third_notification != null) {
				alerts.push(this.props.project.third_notification)
			}

			this.setState({
				              name: this.props.project && this.props.project.id ? this.props.project.name : "",
				              keywords: this.props.project && this.props.project.id ? this.props.project.keywords : "",
				              start: this.props.project && this.props.project.id ? new Date(this.props.project.start_date) : now,
				              end: this.props.project && this.props.project.id ? new Date(this.props.project.end_date) : now,
				              currentDay: this.props.project && this.props.project.id && this.props.project.day_d ? new Date(this.props.project.day_d) : null,
				              dynamic: this.props.project && this.props.project.id ? this.props.project.autoincrement_day_d : false,
				              rubrics: this.props.project && this.props.project.rubrics && this.props.project.rubrics.length > 0 ? JSON.parse(this.props.project.rubrics) : [],
				              description: this.props.project && this.props.project.id ? this.props.project.description : "",
				              showAuthorityModal: false,
				              showLinkModal: false,
				              showAttachmentModal: false,
				              ban_notifications: this.props.project && this.props.project.ban_notification != null ? this.props.project.ban_notification : false,
				              alerts: alerts,
				              ...this.setPermissions(this.props)
			              },
			              () => {
				              this.change_detector.setInitialState(this.state);
			              })
		}

		if (prevProps.attachment !== this.props.attachment && this.props.attachment != null) {
			this.setState({
				              showAttachmentModal: true
			              })
		}


		if (prevProps.link !== this.props.link && this.props.link != null) {
			this.setState({
				              showLinkModal: true
			              })
		}

		this.operationState(prevProps, prevState);
		this.detectChanged(prevProps, prevState);
	}

	setPermissions = (props) => {
		if (props.project && props.isAdministrator(props.project)) {
			return {
				saveEnabled: true,
				addLinkEnabled: true,
				editLinkEnabled: true,
				removeLinkEnabled: true,
				addMessageEnabled: true,
				editMessageEnabled: true,
				deleteMessageEnabled: true,
				addAttachmentEnabled: true,
				editAttachmentEnabled: true,
				deleteAttachmentEnabled: true,
				selectEventCategoriesEnabled: true,
				selectEventTypesEnabled: true,
				selectEventSourcesEnabled: true,
				authoritiesTabVisible: true,
				addAuthoritiesEnabled: true,
				deleteAuthoritiesEnabled: true
			}
		} else if (props.project && props.isAnalyst(props.project)) {
			return {
				saveEnabled: true,
				addLinkEnabled: true,
				editLinkEnabled: true,
				removeLinkEnabled: true,
				addMessageEnabled: true,
				editMessageEnabled: false,
				deleteMessageEnabled: false,
				addAttachmentEnabled: true,
				editAttachmentEnabled: true,
				deleteAttachmentEnabled: true,
				selectEventCategoriesEnabled: true,
				selectEventTypesEnabled: true,
				selectEventSourcesEnabled: true,
				authoritiesTabVisible: true,
				addAuthoritiesEnabled: true,
				deleteAuthoritiesEnabled: true
			}
		} else if (props.project && props.isManager(props.project)) {
			return {
				saveEnabled: true,
				addLinkEnabled: true,
				editLinkEnabled: true,
				removeLinkEnabled: true,
				addMessageEnabled: true,
				editMessageEnabled: false,
				deleteMessageEnabled: false,
				addAttachmentEnabled: true,
				editAttachmentEnabled: true,
				deleteAttachmentEnabled: true,
				selectEventCategoriesEnabled: true,
				selectEventTypesEnabled: true,
				selectEventSourcesEnabled: true,
				authoritiesTabVisible: true,
				addAuthoritiesEnabled: true,
				deleteAuthoritiesEnabled: true
			}
		} else {
			return {
				saveEnabled: false,
				addLinkEnabled: false,
				editLinkEnabled: false,
				removeLinkEnabled: false,
				addMessageEnabled: true,
				editMessageEnabled: false,
				deleteMessageEnabled: false,
				addAttachmentEnabled: false,
				editAttachmentEnabled: false,
				deleteAttachmentEnabled: false,
				selectEventCategoriesEnabled: false,
				selectEventTypesEnabled: false,
				selectEventSourcesEnabled: false,
				authoritiesTabVisible: false,
				addAuthoritiesEnabled: false,
				deleteAuthoritiesEnabled: false
			}
		}
	};

	componentDidCatch(error, errorInfo) {
		this.setState({error: true})
	}

	render() {

		let self = this;

		const dateFormat = "DD.MM.YYYY";

		let saveButtonClass = "btn action-button pull-right";

		if (this.state.operationStatus === "IN_PROGRESS") {
			saveButtonClass = saveButtonClass + " pending";
		} else {
			if (this.state.operationStatus && this.state.operationStatus !== "NOT_PENDING") {
				saveButtonClass = this.state.operationStatus === "SUCCESS" ? saveButtonClass + " btn-success" : saveButtonClass + " btn-danger"
			}
		}

		let changes_indicator;

		if (this.props.project && this.props.project.id && (this.state.dirty || this['change_detector'].initialization_counter > 0)) {
			changes_indicator = this.state.dirty ? <div className="dirty-indicator">Changes unsaved</div> : <div className="dirty-indicator success"><Glyphicon glyph={"ok"}/>Changes saved</div>;
		}


		let tabs = null;

		if (this.props.project && this.props.project.id) {
			tabs = this.renderTabs();
		}

		return (
			<Form horizontal={true} className="h-100 project-form">
				<FormGroup>
					<Col md={1}>
						<ControlLabel>Name:</ControlLabel>
					</Col>
					<Col md={9}>
						<input id="project-name"
						       className="form-control"
						       type="text"
						       onChange={this.handleNameChange}
						       value={this.state.name ? this.state.name : ""}/>
					</Col>
				</FormGroup>
				<FormGroup>
					<Col md={1}>
						<ControlLabel>Project start:</ControlLabel>
					</Col>
					<Col md={2}>
						<DateTimePicker id="project-start"
						                value={this.state.start}
						                onChange={this.handleStartDateChange}
						                time={false}
						                format={dateFormat}/>
					</Col>
					<Col md={1} mdOffset={1}>
						<ControlLabel>Project end:</ControlLabel>
					</Col>
					<Col md={2}>
						<DateTimePicker id="project-end"
						                value={this.state.end}
						                onChange={this.handleEndDateChange}
						                time={false}
						                format={dateFormat}/>
					</Col>
				</FormGroup>
				<FormGroup>
					<Col md={1}>
						<ControlLabel>Current date:</ControlLabel>
					</Col>
					<Col md={2}>
						<DateTimePicker id="project-current-day"
						                value={this.state.currentDay}
						                onChange={this.handleCurrentDateChange}
						                time={false}
						                format={dateFormat}/>
					</Col>
					<Col md={1} mdOffset={1}>
						<ControlLabel>Dynamic:</ControlLabel>
					</Col>
					<Col md={1}>
						<input id="project-dynamic" type="checkbox" checked={this.state.dynamic}
						       onChange={this.handleDynamicChange}/>
					</Col>
				</FormGroup>
				<FormGroup>
					<Col md={1}>
						<ControlLabel>Rubrics</ControlLabel>
					</Col>
					<Col md={9}>
						<Multiselect data={this.rubrics}
						             /*dataKey={"id"}
						             textField={"name"}
						             groupBy={"parent_name"}*/
						             value={this.state.rubrics}
						             onChange={this.handleRubricsChange}/>
					</Col>
				</FormGroup>
				<FormGroup>
					<Col md={1}>
						<ControlLabel>Description:</ControlLabel>
					</Col>
					<Col md={9}>
						<Summernote value={this.state.description} height={150}
						            onChange={this.handleDescriptionChange}/>
					</Col>
				</FormGroup>
				<FormGroup className="tabs-row">
					{tabs}
				</FormGroup>
				{
					(this.state.saveEnabled || this.props.match.params.projectId === "0") &&
					<FormGroup className="form-buttons-bar">
						<Col>
							<Button bsClass={saveButtonClass} onClick={this.state.operationStatus !== "IN_PROGRESS" ? this.saveButtonHandler : null}>
								{this.props.project.id ? "Save" : "Create"}
							</Button>
							{changes_indicator}
						</Col>
					</FormGroup>
				}
			</Form>
		);
	}

	renderTabs = () => {
		let self = this;
		return (
			<Tabs id="project-info-tabs" activeKey={this.state.activeTab} onSelect={this.tabSelectionHandler}
			      className="h-100">
				<Tab eventKey={1} title="Links/Sources">
					<LinksTab items={self.props.project.links}
					          selectedItem={self.props.selectedLink}
					          hasAddButton={self.state.addLinkEnabled}
					          hasEditButton={self.state.editLinkEnabled}
					          hasDeleteButton={self.state.removeLinkEnabled}
					          onItemClick={this.selectLinkHandler}
					          onAdd={
						          (link) => {
							          this.setState({
								                        showLinkModal: true
							                        });
						          }
					          }
					          onEdit={
						          (link) => {
							          if (!self.props.selectedLink) {
								          alert("You must select item from table");
							          } else {
								          this.props.editLink(this.props.project, this.props.selectedLink);
							          }
						          }
					          }
					          onDelete={
						          (link) => {
							          if (!self.props.selectedLink) {
								          alert("You must select item from table");
							          } else {
								          if (confirm(`Are you sure you want to delete link: ${this.props.selectedLink.name}?`)) {
									          this.props.deleteLink(this.props.project, this.props.selectedLink);
								          }
							          }
						          }
					          }
					/>
					{
						this.state.showLinkModal && (
							<LinkForm show={this.state.showLinkModal}
							          onHide={this.cancelLinkHandler}
							          link={this.props.link}
							          onSave={this.saveLinkHandler}
							/>
						)
					}
				</Tab>
				<Tab eventKey={2} title="Discussion">
					<CommentsTab comments={this.props.project.comments}
					             isCommentEditable={(comment) => {
						             return self.state.editMessageEnabled || self.props.isEntityOwner(comment,
						                                                                              'ProjectComment');
					             }}
					             isCommentRemovable={(comment) => {
						             return self.state.deleteMessageEnabled || self.props.isEntityOwner(comment,
						                                                                                'ProjectComment');
					             }}
					             onAddComment={(comment, callback) => {
						             comment.project = {
							             id: this.props.project.id,
							             retrospectionId: this.props.project.retrospectionId
						             };
						             projectCommentService.create(this.props.project.id, comment)
						                                  .then(promise => {
							                                  if (callback) {
								                                  callback(promise.data);
							                                  }
						                                  });
					             }}
					             onEditComment={(comment, callback) => {
						             comment.project = {
							             id: this.props.project.id,
							             retrospectionId: this.props.project.retrospectionId
						             };
						             projectCommentService.save(this.props.project.id, comment)
						                                  .then(promise => {
							                                  if (callback) {
								                                  callback(promise.data);
							                                  }
						                                  });
					             }}
					             onDeleteComment={(commentId, callback) => {
						             if (confirm(`Are you sure you want to delete comment?`)) {
							             projectCommentService.delete(this.props.project.id, commentId)
							                                  .then(promise => {
								                                  if (callback) {
									                                  callback(promise.data);
								                                  }
							                                  });
						             }
					             }}
					/>
				</Tab>
				<Tab eventKey={3} title="Attachments">
					<AttachmentsTab items={this.props.project.attachments}
					                selectedItem={self.props.selectedAttachment}
					                hasAddButton={self.state.addAttachmentEnabled}
					                hasEditButton={self.state.editAttachmentEnabled}
					                hasDeleteButton={self.state.deleteAttachmentEnabled}
					                onItemClick={this.selectAttachmentHandler}
					                onAdd={
						                () => {
							                self.setState({
								                              showAttachmentModal: true
							                              });
						                }
					                }
					                onEdit={
						                (attachment) => {
							                if (!self.props.selectedAttachment) {
								                alert("You must select item from table");
							                } else {
								                this.props.editAttachment(this.props.project,
								                                          this.props.selectedAttachment);
							                }
						                }
					                }
					                onDelete={
						                (attachment) => {
							                if (!self.props.selectedAttachment) {
								                alert("You must select item from table");
							                } else {
								                if (confirm(`Are you sure you want to delete attachment: ${this.props.selectedAttachment.name}?`)) {
									                this.props.deleteAttachment(this.props.project,
									                                            this.props.selectedAttachment);
								                }
							                }
						                }
					                }
					/>
					{
						this.state.showAttachmentModal && (
							<AttachmentForm show={this.state.showAttachmentModal}
							                onHide={this.cancelAttachmentHandler}
							                attachment={this.props.attachment}
							                onSave={this.saveAttachmentHandler}
							/>
						)
					}
				</Tab>
				<Tab eventKey={4} title="Event Categories">
					<EventCategoriesTab eventCategories={this.props.projectEventCategories}
					                    availableEventCategories={this.props.eventCategories}
					                    hasSelectButton={this.state.selectEventCategoriesEnabled}
					                    orphans={this.props.orphans}
					                    seletedOrphan={this.props.selectedOrphan}
					                    selectOrphan={(item) => this.props.selectOrphan(item)}
					                    clearOrphans={() => this.props.clearOrphans()}
					                    itemInfoProvider={
						                    (type, path) => {
							                    return this.props.loadOrphanItemInfo(type, path);
						                    }
					                    }
					                    onAddCategories={(eventCategories) => {
						                    this.props.createEventCategories(eventCategories)

					                    }}
					/>
				</Tab>
				<Tab eventKey={5} title="Event Types">
					<EventTypesTab eventTypes={this.props.projectEventTypes}
					               availableEventTypes={this.props.eventTypes}
					               hasSelectButton={this.state.selectEventTypesEnabled}
					               orphans={this.props.orphans}
					               seletedOrphan={this.props.selectedOrphan}
					               selectOrphan={(item) => this.props.selectOrphan(item)}
					               clearOrphans={() => this.props.clearOrphans()}
					               itemInfoProvider={
						               (type, path) => {
							               return this.props.loadOrphanItemInfo(type, path);
						               }
					               }
					               onAddTypes={(eventTypes) => {
						               this.props.createEventTypes(eventTypes)
					               }}
					/>
				</Tab>
				<Tab eventKey={6} title="Event Sources">
					<EventSourcesTab eventSources={this.props.projectEventSources}
					                 availableEventSources={this.props.eventSources}
					                 hasSelectButton={self.state.selectEventSourcesEnabled}
					                 orphans={this.props.orphans}
					                 seletedOrphan={this.props.selectedOrphan}
					                 selectOrphan={(item) => this.props.selectOrphan(item)}
					                 clearOrphans={() => this.props.clearOrphans()}
					                 itemInfoProvider={
						                 (type, path) => {
							                 return this.props.loadOrphanItemInfo(type, path);
						                 }
					                 }
					                 onAdd={
						                 (eventSources) => {
							                 this.props.createEventSources(eventSources)
						                 }
					                 }
					/>
				</Tab>
				<Tab eventKey={7} title="Journal">
					<JournalTab items={this.props.journalEntries}
					            selectedItem={this.props.selectedJournalEntry ? this.props.selectedJournalEntry : null}
					            onSelectionChange={this.journalSelectionChangeHandler}
					/>
				</Tab>
				<Tab eventKey={8} title="Alerts">
					<div className="tab-content">
						<Navbar fluid={true} bsClass="c-navbar navbar-default">
						</Navbar>
						<Grid fluid={true} style={{
							marginTop: '15px'
						}}>
							<Row>
								<Col md={1}>
									<ControlLabel>Disable alerts:</ControlLabel>
								</Col>
								<Col md={2}>
									<input type="checkbox"
									       onChange={(e) => {
										       this.setState({ban_notifications: e.target.checked})
									       }}
									       checked={this.state.ban_notifications}
									/>
								</Col>
							</Row>
						</Grid>
						{
							!this.state.ban_notifications &&
							<Grid fluid={true} style={{
								marginTop: '15px'
							}}>
								<h4>Upcoming event alerts</h4>
								{
									this.state.alerts && this.state.alerts.map((item, index) => {
										return <Row key={index}
										            className="alert-item"
										            style={{
											            marginTop: '15px',
											            marginBottom: '15px'
										            }}>
											<Col md={1}>
												<ControlLabel>{index + 1} alert: </ControlLabel>
											</Col>
											<Col md={2}>
												<InputGroup>
													<NumberPicker onChange={
														(value) => {
															let new_alerts = [...this.state.alerts];
															new_alerts[index] = value;
															this.setState({alerts: new_alerts})
														}
													}
													              min={1}
													              max={100}
													              value={this.state.alerts[index]}/>
													<InputGroup.Button>
														<Button onClick={(e) => {
															let new_alerts = [...this.state.alerts];

															new_alerts.splice(index, 1)

															this.setState({alerts: new_alerts})
														}}>
															<Glyphicon glyph="remove"/>
														</Button>
													</InputGroup.Button>
												</InputGroup>
											</Col>
											<Col>
												<OverlayTrigger trigger="click"
												                placement="right" rootClose
												                overlay={
													                <Popover id="formula-help-popover" title="Information">
														                Days before event date or deadline.
													                </Popover>
												                }>
													<Button className="help-button">
														<Glyphicon glyph="question-sign"/>
													</Button>
												</OverlayTrigger>
											</Col>
										</Row>
									})
								}
								{
									this.state.alerts && this.state.alerts.length < 3 &&
									<Row>
										<Col>
											<Button onClick={(e) => {
												let new_alerts = [...this.state.alerts];

												if (new_alerts.length > 2) {
													return;
												}

												if (new_alerts.length > 0) {
													new_alerts.push(new_alerts[new_alerts.length - 1] + 1);
												} else {
													new_alerts.push(1)
												}
												this.setState({alerts: new_alerts})
											}}><Glyphicon glyph="plus"/> Add</Button>
										</Col>
									</Row>
								}
							</Grid>
						}
					</div>
				</Tab>
				{
					self.state.authoritiesTabVisible &&
					<Tab eventKey={9} title="Authorities">
						<div className="tab-content">
							<Navbar fluid={true} bsClass="c-navbar navbar-default">
								<div className="tab-buttons-group">
									<Button bsClass="btn icon action-button" onClick={this.addAuthorityButtonHandler}>
										<Glyphicon glyph="plus" className="c-glyph-icon"/>
										<div className="label-wrapper">
											<label>Add</label>
										</div>
									</Button>
									<Button bsClass="btn icon action-button" onClick={this.editAuthorityButtonHandler}>
										<Glyphicon glyph="edit" className="c-glyph-icon"/>
										<div className="label-wrapper">
											<label>Edit</label>
										</div>
									</Button>
									<Button bsClass="btn icon action-button"
									        onClick={this.deleteAuthorityButtonHandler}>
										<Glyphicon glyph="remove" className="c-glyph-icon"/>
										<div className="label-wrapper">
											<label>Delete</label>
										</div>
									</Button>
								</div>
							</Navbar>
							<div className="table-wrapper">
								<Table>
									<thead>
									<tr>
										<th>User</th>
										<th>Role</th>
									</tr>
									</thead>
									<tbody>
									{
										this.props.project.user_roles.map(item => {
											return (
												<tr key={item.id}
												    onClick={() => this.selectAuthorityHandler(item)}
												    className={this.props.selectedUserAuthority && this.props.selectedUserAuthority.id === item.id ? 'selected' : ''}>
													<td>{item.user ? item.user.user_name : ""}</td>
													<td>{item.authority}</td>
												</tr>
											);
										})
									}
									</tbody>
								</Table>
							</div>
							{
								this.state.showAuthorityModal &&
								this.props.users &&
								<AuthorityForm show={this.state.showAuthorityModal}
								               onHide={this.cancelAuthorityHandler}
								               users={this.props.users}
								               authorities={this.props.authorities}
								               userAuthority={this.props.userAuthority}
								               onSave={this.saveAuthorityHandler}
								               onCancel={this.cancelAuthorityHandler}/>
							}
						</div>
					</Tab>
				}
				<Tab eventKey={10} title="Keywords">
					<KeywordsTab keywords={this.state.keywords}
								 handleTextAreaChanged = {(event, keywords) => {
									 this.setState({
										 keywords: keywords,
									 });
								 }}
					/>
				</Tab>
			</Tabs>
		)
	};

	journalSelectionChangeHandler = (item) => {
		this.props.selectJournalEntry(item);
	};

	selectLinkHandler = (link) => {
		this.props.selectLink(link);
	};

	saveLinkHandler = (link) => {
		this.props.saveLink(this.props.project, link);
	};

	cancelLinkHandler = () => {
		this.setState({
			              showLinkModal: false
		              });
		this.props.clearLink();
	};

	selectAttachmentHandler = (attachment) => {
		this.props.selectAttachment(attachment);
	};

	saveAttachmentHandler = (attachment) => {
		this.setState({
			              showAttachmentModal: false
		              })
		this.props.saveAttachment(this.props.project, attachment);
	};

	cancelAttachmentHandler = () => {
		this.setState({
			              showAttachmentModal: false
		              });
		this.props.clearAttachment();
	};


	addAuthorityButtonHandler = (event) => {
		this.props.loadUsers()
		this.setState({
			              showAuthorityModal: true
		              });
	};

	editAuthorityButtonHandler = (event) => {
		if (!this.props.selectedUserAuthority) {
			alert("You must select item from table");
		} else {
			this.props.editUserAuthority(this.props.project, this.props.selectedUserAuthority);
		}
	};

	selectAuthorityHandler = (item) => {
		this.props.selectUserAuthority(item);
	};

	cancelAuthorityHandler = () => {
		this.setState({
			              showAuthorityModal: false
		              });
		this.props.clearAuthority();
	};

	saveAuthorityHandler = (userAuthority) => {
		this.setState({
			              showAuthorityModal: false
		              });
		this.props.saveAuthority(this.props.project, userAuthority);
	};

	deleteAuthorityButtonHandler = () => {
		if (!this.props.selectedUserAuthority) {
			alert("You must select item from table");
		} else {
			if (confirm("Are you sure you want to delete authority?")) {
				this.props.deleteAuthority(this.props.project, this.props.selectedUserAuthority);
			}
		}
	};

	saveButtonHandler = (event) => {

		event.preventDefault();

		const project = {...this.props.project};

		project.name = this.state.name;
		project.keywords = this.state.keywords;
		project.start_date = DateUtils.convertToISOUTCDatetime(DateUtils.startOfDay(this.state.start));
		project.end_date = DateUtils.convertToISOUTCDatetime(DateUtils.endOfDay(this.state.end));
		project.day_d = this.state.currentDay ? DateUtils.convertToISOUTCDatetime(this.state.currentDay) : project.start_date;
		project.autoincrement_day_d = this.state.dynamic;
		project.rubrics = JSON.stringify(this.state.rubrics);
		project.description = this.state.description;

		if (this.state.alerts) {

			let alerts = this.state.alerts.sort();

			if (alerts.length > 0) {
				project.first_notification = this.state.alerts[0];
			} else {
				project.first_notification = null;
			}

			if (alerts.length > 1) {
				project.second_notification = this.state.alerts[1];
			} else {
				project.second_notification = null;
			}

			if (alerts.length > 2) {
				project.third_notification = this.state.alerts[2];
			} else {
				project.third_notification = null;
			}
		}


		project.ban_notification = this.state.ban_notifications;

		if (project.id) {
			this.props.saveProject(project);
		} else {
			this.props.createProject(project);
		}
	};

	tabSelectionHandler = (tabIndex) => {
		this.setState({activeTab: tabIndex});
	};

	handleNameChange = (event) => {
		this.setState({
			              name: event.target.value
		              });
	};

	handleStartDateChange = (date) => {
		this.setState({
			              start: date
		              });
	};

	handleEndDateChange = (date) => {
		this.setState({
			              end: date
		              });
	};

	handleCurrentDateChange = (date) => {
		this.setState({
			              currentDay: date
		              });
	};

	handleDynamicChange = (event) => {
		this.setState({
			              dynamic: event.target.checked
		              });
	};

	handleRubricsChange = (dataItems) => {
		this.setState({
			              rubrics: dataItems
		              })
	}

	handleDescriptionChange = (content) => {
		this.setState({
			              description: content
		              });
	}
}

export default ProjectInfoView;