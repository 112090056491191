import React from 'react';

import {withRouter} from "react-router";
import * as authenticationActions from "../actions/authentication";
import {connect} from "react-redux";
import {API} from "../api/api";


class LogoutPage
	extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			error: false
		};

        this.setFormRef = (element) => {
        	this.formRef = element;
        };
	}

	componentDidMount() {
		this.formRef.submit();
	}

	render() {
		return (
			<form ref={this.setFormRef} method={API.auth.logout.method} action={API.auth.logout.url}>
				<input name={this.props.csrf_token.parameterName} value={this.props.csrf_token.token}/>
			</form>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		csrf_token: state.servers.csrf_tokens['auth']
	}
};

const mapDispatchToProps = (dispatch, state) => {
	return {

		logout: () => {
			dispatch(authenticationActions.logout());
		}
	}
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LogoutPage));