import React from 'react';
import {Combobox, DateTimePicker} from 'react-widgets';

import {Button, Col, ControlLabel, Form, FormControl, FormGroup} from 'react-bootstrap';
import DateUtils from "../utils/date";

class FactorView
	extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			name: props.user ? props.user.name : "",
			patronymic: props.user ? props.user.patronymic : "",
			surname: props.user ? props.user.secondname : "",
			birthdate: props.user ? new Date(props.user.birthdate) : new Date(),
			address: props.user ? props.user.address : "",
			phone: props.user ? props.user.phone : "",
			user_name: props.user ? props.user.user_name : "",
			password: props.user ? props.user.password : "",
			email: props.user ? props.user.email : "",
			role: props.user ? props.user.role : ""
		};
	}

	componentDidMount() {
		if (!this.props.user && this.props.match.params.userId !== "0") {
			this.props.loadUser(this.props.match.params.userId);
		}
	}

	componentDidUpdate() {
		if (!this.props.user && this.props.match.params.userId !== "0") {
			this.props.loadUser(this.props.match.params.userId);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
		this.setState({
			              name: nextProps.user ? nextProps.user.name : "",
			              patronymic: nextProps.user ? nextProps.user.patronymic : "",
			              surname: nextProps.user ? nextProps.user.secondname : "",
			              birthdate: nextProps.user ? new Date(nextProps.user.birthdate) : new Date(),
			              address: nextProps.user ? nextProps.user.address : "",
			              phone: nextProps.user ? nextProps.user.phone : "",
			              user_name: nextProps.user ? nextProps.user.user_name : "",
			              email: nextProps.user ? nextProps.user.email : "",
			              role: nextProps.user ? nextProps.user.role : "",
			              password: nextProps.user ? nextProps.user.password : "",
		              });
	}

	render() {
		const dateFormat = "DD.MM.YYYY";
		return (
			<Form horizontal={true} className="h-100 user-form">
				<FormGroup>
					<Col md={1}>
						<ControlLabel>Name:</ControlLabel>
					</Col>
					<Col md={11}>
						<FormControl
							type="text"
							onChange={this.handleNameChange}
							value={this.state.name}/>
					</Col>
				</FormGroup>
				<FormGroup>
					<Col md={1}>
						<ControlLabel>Patronymic:</ControlLabel>
					</Col>
					<Col md={11}>
						<FormControl className="form-control"
						             type="text"
						             onChange={this.handlePatronymicChange}
						             value={this.state.patronymic}/>
					</Col>
				</FormGroup>
				<FormGroup>
					<Col md={1}>
						<ControlLabel>Last name:</ControlLabel>
					</Col>
					<Col md={11}>
						<FormControl className="form-control"
						             type="text"
						             onChange={this.handleSecondnameChange}
						             value={this.state.secondname}/>
					</Col>
				</FormGroup>
				<FormGroup>
					<Col md={1}>
						<ControlLabel>Birthdate:</ControlLabel>
					</Col>
					<Col md={11}>
						<DateTimePicker id="user-birthday"
						                value={this.state.birthdate}
						                onChange={this.handleBirthdateChange}
						                time={false}
						                format={dateFormat}/>
					</Col>
				</FormGroup>
				<FormGroup>
					<Col md={1}>
						<ControlLabel>Address:</ControlLabel>
					</Col>
					<Col md={11}>
						<FormControl type="text"
						             onChange={this.handleAddressChange}
						             value={this.state.address}/>
					</Col>
				</FormGroup>
				<FormGroup>
					<Col md={1}>
						<ControlLabel>Phone:</ControlLabel>
					</Col>
					<Col md={11}>
						<FormControl type="text"
						             onChange={this.handlePhoneChange}
						             value={this.state.phone}/>
					</Col>
				</FormGroup>
				<FormGroup>
					<Col md={1}>
						<ControlLabel>E-mail:</ControlLabel>
					</Col>
					<Col md={11}>
						<FormControl type="text"
						             onChange={this.handleEmailChange}
						             value={this.state.email}/>
					</Col>
				</FormGroup>
				<FormGroup>
					<Col md={1}>
						<ControlLabel>Username:</ControlLabel>
					</Col>
					<Col md={11}>
						<FormControl type="text"
						             onChange={this.handleUsernameChange}
						             value={this.state.user_name}/>
					</Col>
				</FormGroup>
				<FormGroup>
					<Col md={1}>
						<ControlLabel>Password:</ControlLabel>
					</Col>
					<Col md={11}>
						<FormControl type="password"
						             onChange={this.handlePasswordChange}
						             value={this.state.password}/>
					</Col>
				</FormGroup>
				<FormGroup>
					<Col md={1}>
						<ControlLabel>Role:</ControlLabel>
					</Col>
					<Col md={11}>
						<Combobox onChange={this.handleRoleChange}
						          defaultValue={this.state.role ? this.state.role : ''}
						          data={['ADMIN', 'USER']}
						          caseSensitive={false}
						          value={this.state.role}/>
					</Col>
				</FormGroup>
				<FormGroup>
					<Col md={12}>
						<Button bsClass="btn action-button pull-right" onClick={this.cancelButtonHandler}>
							Cancel
						</Button>
						<Button bsClass="btn action-button pull-right" onClick={this.saveButtonHandler}>
							Save
						</Button>
					</Col>
				</FormGroup>
			</Form>
		);
	}

	tabSelectionHandler = (tabIndex) => {
		this.setState({activeTab: tabIndex});
	};


	handleNameChange = (event) => {
		this.setState({
			              name: event.target.value
		              });
	};

	handlePatronymicChange = (event) => {
		this.setState({
			              patronymic: event.target.value
		              });
	};

	handleSecondnameChange = (event) => {
		this.setState({
			              secondname: event.target.value
		              });
	};

	handleBirthdateChange = (date) => {
		this.setState({
			              birthdate: date
		              });
	};

	handleEmailChange = (event) => {
		this.setState({
			              email: event.target.value
		              });
	};

	handleAddressChange = (event) => {
		this.setState({
			              address: event.target.value
		              });
	};

	handlePhoneChange = (event) => {
		this.setState({
			              phone: event.target.value
		              });
	};

	handleUsernameChange = (event) => {
		this.setState({
			              user_name: event.target.value
		              });
	};

	handlePasswordChange = (event) => {
		this.setState({
			              password: event.target.value
		              });
	};

	handleRoleChange = (role) => {
		this.setState({
			              role: role
		              });
	};

	saveButtonHandler = () => {

		const user = this.props.user != null ? this.props.user : {};

		user.name = this.state.name;
		user.patronymic = this.state.patronymic;
		user.surname = this.state.secondname;
		user.birthdate = DateUtils.convertToISOUTCDatetime(this.state.birthdate);
		user.address = this.state.address;
		user.phone = this.state.phone;
		user.user_name = this.state.user_name;
		user.email = this.state.email;
		user.password = this.state.password;
		user.role = this.state.role;

		this.props.saveUser(user);
	};

	cancelButtonHandler = () => {
		this.props.history.goBack();
	};
}

export default FactorView;