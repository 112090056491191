import {actionTypes} from "../actions/factors";

const initialState = {
    isPending: false,
    invalidated: true,
    factors: null,
    factor: null,
    selectedFactor: null,
    isJournalEntriesPending: false,
    operation: null
};

function factorReducer(state = initialState, action) {

    let newState = null;

    switch (action.type) {
        case actionTypes.get(actionTypes.START_OPERATION):
            return {
                ...state,
                operation: action.data
            };
        case actionTypes.get(actionTypes.END_OPERATION):
            return {
                ...state,
                operation: action.data
            };
        case actionTypes.get('REQUEST_FACTORS'):
            return {
                ...state,
                isPending: true
            };
        case actionTypes.get('RECEIVE_FACTORS'):
            return {
                ...state,
                isPending: false,
                factors: action.data
            };
        case actionTypes.get('SELECT_FACTOR'):
            return {
                ...state,
                selectedFactor: action.data
            };
        case actionTypes.get('REQUEST_FACTOR'):
            return {
                ...state,
                factor: action.data,
                isPending: true
            };
        case actionTypes.get('RECEIVE_FACTOR'):
            return {
                ...state,
                factor: action.data,
                isPending: false
            };
        case actionTypes.get('RECEIVE_LINK'):

            newState = {
                ...state
            };
            newState.factor.link = action.data;

            return newState;
        case actionTypes.get('SELECT_LINK'):

            newState = {
                ...state
            };
            newState.factor.selectedLink = action.data;

            return newState;
        case actionTypes.get('RECEIVE_ATTACHMENT'):

            newState = {
                ...state
            };
            newState.factor.attachment = action.data;

            return newState;
        case actionTypes.get('SELECT_ATTACHMENT'):

            newState = {
                ...state
            };
            newState.factor.selectedAttachment = action.data;

            return newState;
        case actionTypes.get('REQUEST_JOURNAL_ENTRIES'):
            return {
                ...state,
                isJournalEntriesPending: true
            };
        case actionTypes.get('RECEIVE_JOURNAL_ENTRIES'):

            newState = {
                ...state,
                isJournalEntriesPending: false
            };

            newState.factor.journal_entries = action.data;

            return newState;
        case actionTypes.get('SELECT_JOURNAL_ENTRY'):

            newState = {
                ...state
            };

            newState.factor.selected_journal_entry = action.data;

            return newState;
        default:
            return state
    }
}

export default factorReducer