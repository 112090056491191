import clientFactory from "../factories/http_client";
import {API} from "../api/api";

class TrendService {

	/** @return  Promise<AxiosResponse<T>> */
	get = (projectId, futureMapId, trendId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.trends.id.method,
			                             url: API.data.projects.id.event_maps.id.trends.id.url(projectId, futureMapId, trendId)
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	create = (projectId, futureMapId, trend) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.trends.create.method,
			                             url: API.data.projects.id.event_maps.id.trends.create.url(projectId, futureMapId),
			                             data: API.data.projects.id.event_maps.id.trends.create.build(trend),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	save = (projectId, futureMapId, trend) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.trends.save.method,
			                             url: API.data.projects.id.event_maps.id.trends.save.url(projectId, futureMapId),
			                             data: API.data.projects.id.event_maps.id.trends.save.build(trend),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	remove = (projectId, futureMapId, trendId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.trends.delete.method,
			                             url: API.data.projects.id.event_maps.id.trends.delete.url(projectId, futureMapId),
			                             data: API.data.projects.id.event_maps.id.trends.delete.build(trendId),
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	addEvent = (projectId, futureMapId, trendId, eventId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.trends.id.events.add.method,
			                             url: API.data.projects.id.event_maps.id.trends.id.events.add.url(projectId, futureMapId, trendId),
			                             data: API.data.projects.id.event_maps.id.trends.id.events.add.build(eventId),
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	removeEvent = (projectId, futureMapId, trendId, eventId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.trends.id.events.remove.method,
			                             url: API.data.projects.id.event_maps.id.trends.id.events.remove.url(projectId, futureMapId, trendId),
			                             data: API.data.projects.id.event_maps.id.trends.id.events.remove.build(eventId),
		                             });
	}
}

export default TrendService;