import React from 'react';
import {Button, Col, Glyphicon, Grid, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import ReactScrollableList from 'react-scrollable-list';

import BaseForm from 'components/base/base-form';
import BaseComponent from 'components/base/base-component';
import Utils from 'utils/utils';
import DateUtils from "../../../utils/date";


const SEND_MODE = {
    ADD: 0,
    EDIT: 1
};

class CommentItem extends BaseComponent {

    render() {
        const dateFormat = "d mmmm yyyy  HH:MM:ss";
        const comment = this.props.comment;
        return (
            <Grid bsClass="container comment-item-grid" className="comment-item" fluid={true} style={{height: this.props.height}}>
                <Row bsClass="row comment-item-row">
                    <Col sm={6} className="text-left">
                        <div className="author-label">
                            {comment.author.user_name}
                        </div>
                    </Col>
                    <Col sm={5} className="text-right">
                        <div className="date-label">
                            {Utils.convertDatetime(comment.create_date, dateFormat)}
                        </div>
                    </Col>
                    <Col sm={1}>
                        {
                            this.props.editable &&
                            <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip">Edit comment</Tooltip>}>
                                <Button bsClass="icon-button pull-right"
                                        onClick={() => this.props.handleEditBtn(comment)}>
                                    <Glyphicon glyph="pencil" className="c-glyph-icon"/>
                                </Button>
                            </OverlayTrigger>
                        }
                    </Col>
                </Row>
                <Row bsClass="row comment-item-row">
                    <Col sm={11}>
                        <div className="comment-label">
                            {comment.text}
                        </div>
                    </Col>
                    <Col sm={1}>
                        <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip">Delete comment</Tooltip>}>
                            <Button bsClass="icon-button pull-right"
                                    onClick={() => this.props.handleDeleteBtn(comment)}>
                                <Glyphicon glyph="remove" className="c-glyph-icon"/>
                            </Button>
                        </OverlayTrigger>
                    </Col>
                </Row>
                {
                    comment.last_edit && (
                        <Row bsClass="row comment-item-row">
                            <Col sm={12}>
                                <div className="last-edit-label">
                                    Edited: {Utils.convertDatetime(comment.last_edit, dateFormat)}
                                </div>
                            </Col>
                        </Row>
                    )
                }
            </Grid>
        );
    }
}


class CommentsTab extends BaseForm {

    constructor(props) {
        super(props);

        this.state = {
            comments: this.props.comments ? this.props.comments : [],
            commentText: null,
            sendMode: SEND_MODE.ADD,
            editableComment: null
        };
    }

    editButtonHandler(item) {
        this.setState({
            commentText: item.text,
            sendMode: SEND_MODE.EDIT,
            editableComment: item
        });
    }

    deleteButtonHandler(item) {
        this.props.onDeleteComment(item.id, () => {
            this.setState({
                comments: this.state.comments.filter(comment => comment.id !== item.id)
            });
        });
    }

    handleSendButton() {
        if (this.state.sendMode === SEND_MODE.ADD) {
            if (this.state.commentText) {
                this.props.onAddComment({text: this.state.commentText}, (upComment) => {
                    this.state.comments.push(upComment);
                    this.setState({
                        commentText: null
                    });
                });
            }
        } else {
            if (this.state.commentText) {
                const comment = this.state.editableComment;
                comment.text = this.state.commentText;
                comment.last_edit = DateUtils.convertToISOUTCDatetime(new Date());

                this.props.onEditComment(comment, (upComment) => {
                    let index = -1;
                    for (let i = 0; i < this.state.comments.length; i++) {
                        if (this.state.comments[i].id === upComment.id) {
                            index = i;
                            break;
                        }
                    }
                    this.state.comments[index] = upComment;
                    this.setState({
                        commentText: null,
                        sendMode: SEND_MODE.ADD,
                        editableComment: null
                    });
                });
            }
        }
    }

    handleCancelButton() {
        this.setState({
            commentText: null,
            sendMode: SEND_MODE.ADD,
            editableComment: null
        });
    }

    render() {
        let self = this;
        return (
            <Grid id="comments-tab-component" fluid={true} className="w-50 h-100 ml-0 tab-content comments-tab">
                <Row className="c-row discussion-row">
                    <Col md={12} className="h-100">
                        <ReactScrollableList
                            listItems={
                                this.state.comments
                                    .sort((a, b) => {
                                        a = a.create_date;
                                        b = b.create_date;
                                        if (a < b) return 1;
                                        else if (b > a) return -1;
                                        else return 0;
                                    })
                                    .map(comment => {
                                        return {
                                            id: comment.id,
                                            content: <CommentItem key={comment.id}
                                                                  height="70px"
                                                                  comment={comment}
                                                                  editable={self.props.isCommentEditable(comment)}
                                                                  removable={self.props.isCommentRemovable(comment)}
                                                                  handleEditBtn={this.editButtonHandler}
                                                                  handleDeleteBtn={this.deleteButtonHandler}
                                            />
                                        };
                                    })
                            }
                            heightOfItem={70}
                            maxItemsToRender={10}
                        />
                    </Col>
                </Row>
                <Row bsClass="row c-row">
                    <Col md={12} className="c-col">
                        <textarea className="form-control"
                                  rows="3"
                                  onChange={this.setEventValue.bind(this, 'commentText')}
                                  value={this.state.commentText ? this.state.commentText : ""}/>
                    </Col>
                </Row>
                <Row bsClass="row c-row">
                    <Col md={12} className="c-col">
                        <Button bsClass="btn pull-right action-button" onClick={this.handleSendButton}>Send</Button>
                        {
                            this.state.sendMode === SEND_MODE.EDIT && (
                                <Button bsClass="btn pull-right action-button"
                                        onClick={this.handleCancelButton}>Cancel</Button>
                            )
                        }
                    </Col>
                </Row>
            </Grid>
        );
    }
}

export default CommentsTab;
export {CommentsTab};
