import React from "react";

class UserItem extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <tr onClick={() => this.props.onItemClick(this.props.item)} className={this.props.selected ? "clickable selected" : "clickable"}>
                <td> {this.props.item.user_name} </td>
                <td> {this.props.item.secondname} </td>
                <td> {this.props.item.name} </td>
                <td> {this.props.item.patronymic} </td>
                <td> {this.props.item.email} </td>
                <td> {this.props.item.role} </td>
            </tr>
        );
    }
}

export default UserItem