import {actionTypes} from "../actions/scenarios";

const initialState = {
    isPending: false,
    invalidated: true,
    scenarios: null,
    scenario: null,
    selectedScenario: null,
    operation: null
};

function scenariosReducer(state = initialState, action) {

    let newState = null;

    switch (action.type) {
        case actionTypes.get('REQUEST_SCENARIOS'):
            return {
                ...state,
                isPending: true
            };
        case actionTypes.get('RECEIVE_SCENARIOS'):
            return {
                ...state,
                isPending: false,
                scenarios: action.data
            };
        case actionTypes.get('SELECT_SCENARIO'):
            return {
                ...state,
                selectedScenario: action.data
            };
        case actionTypes.get('REQUEST_SCENARIO'):
            return {
                ...state,
                scenario: action.data,
                isPending: true
            };
        case actionTypes.get('RECEIVE_SCENARIO'):
            return {
                ...state,
                scenario: action.data,
                isPending: false
            };
        case actionTypes.get('RECEIVE_LINK'):

            newState = {
                ...state
            };
            newState.scenario.link = action.data;

            return newState;
        case actionTypes.get('SELECT_LINK'):

            newState = {
                ...state
            };
            newState.scenario.selectedLink = action.data;

            return newState;
        case actionTypes.get('RECEIVE_ATTACHMENT'):

            newState = {
                ...state
            };
            newState.scenario.attachment = action.data;

            return newState;
        case actionTypes.get('SELECT_ATTACHMENT'):

            newState = {
                ...state
            };
            newState.scenario.selectedAttachment = action.data;

            return newState;
	    case actionTypes.get('SELECT_EVENT'):

		    newState = {
			    ...state
		    };
		    newState.scenario.selectedEvent = action.data;

		    return newState;
        case actionTypes.get(actionTypes.START_OPERATION):
            return {
                ...state,
                operation: action.data
            };
        case actionTypes.get(actionTypes.END_OPERATION):
            return {
                ...state,
                operation: action.data
            };
        default:
            return state
    }
}

export default scenariosReducer