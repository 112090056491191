import * as eventActions from "./events";
import {eventRelationsService} from "../services";

export const actionTypes = {

	prefix: 'EVENT_RELATION',

	REQUEST_EVENT_RELATIONS: 'REQUEST_EVENT_RELATIONS',
	RECEIVE_EVENT_RELATIONS: 'RECEIVE_EVENT_RELATIONS',
	SELECT_EVENT_RELATION: 'SELECT_EVENT_RELATION',
	START_OPERATION: 'START_OPERATION',
	END_OPERATION: 'END_OPERATION',

	get: function (key) {
		return this.prefix + '_' + this[key];
	}
};

export const requestEventRelations = () => {
	return {
		type: actionTypes.get(actionTypes.REQUEST_EVENT_RELATIONS)
	}
};

export const receiveEventRelations = (relations) => {
	return {
		type: actionTypes.get(actionTypes.RECEIVE_EVENT_RELATIONS),
		data: relations
	}
};

export const fetchEventRelations = (projectId, eventsMapId) => {
	return (dispatch, getState) => {
		if (!getState().eventRelationsReducer.isPending) {
			dispatch(requestEventRelations());
			eventRelationsService.getRelations(projectId, eventsMapId)
			                     .then(relations_proxy => {
				                     dispatch(receiveEventRelations(relations_proxy.data));
			                     });
		}
	};
};

export const selectEventRelation = (relation) => {
	return {
		type: actionTypes.get(actionTypes.SELECT_EVENT_RELATION),
		data: relation
	}
};

export const deleteEventRelation = (projectId, eventsMapId, relation) => {
	return (dispatch, getState) => {
		eventRelationsService.remove(projectId, eventsMapId, relation)
		                     .then(relations_proxy => {
			                     dispatch(eventActions.fetchEvent(projectId, eventsMapId, relation.left_event.id));
		                     });
	};
};

export const startOperation = (operation) => {
	return {
		type: actionTypes.get(actionTypes.START_OPERATION),
		data: operation
	}
};

export const endOperation = (operation) => {
	return {
		type: actionTypes.get(actionTypes.END_OPERATION),
		data: operation
	}
};