import {Col, Grid, Popover, Row} from "react-bootstrap";
import React from "react";

class EventsListPopover
	extends React.PureComponent {

	constructor(props) {
		super(props);
	}

	render() {

		let selectionIds = [];

		if (this.props.selectedEvents) {
			selectionIds = this.props.selectedEvents.map(function (element) {
				return element.id;
			})
		}

		let options = {
			year: 'numeric',
			month: 'numeric',
			'day': 'numeric'
		};

		return (
			<Popover title={"Events List"}
			         id={
				         this.props.events && this.props.events.reduce(function (accumulator, element) {
					         return accumulator + "" + element.id;
				         }, "")
			         }
			         placement={this.props.placement}
			         className={"events-list-popover " + this.props.placement}
			>
				<Grid fluid={true} className="content">
					<Row>
						<Col md={12}>
							<ul className="items-list">
								{
									this.props.events &&
									this.props.events
									    .map(event => {

										    let backgroundMode = this.props.eventsRelations && this.props.eventsRelations.length > 0 && this.props.eventsRelations.filter((item) => {
											    return item.right_event.id === event.id || item.left_event.id === event.id
										    }).length === 0;

										    return (
											    <li key={event.id}
											        className={"item" + (selectionIds.indexOf(event.id) !== -1 ? " selected" : "")}
											        draggable="true"
											        onDragStart={
												        (e) => {
														        this.props.onEventDragStart(event, e);
												        }
											        }
											        onClick={
												        (e) => {
													        this.props.onItemClick(event, e)
												        }
											        }
											        onDoubleClick={
												        (e) => {
													        this.props.onItemDoubleClick(event, e)
												        }
											        }
											    >
												    <Grid fluid={true}
												          style={
													          {
														          backgroundColor: !backgroundMode ? this.props.eventBackgroundColorProvider(
															          event) : 'lightgrey',
														          borderColor: !backgroundMode ? this.props.eventBorderColorProvider(
															          event) : 'lightgrey',
													          }
												          }>
													    <Row>
														    <Col md={12}>
															    <div className="color-label"
															         style={
																         {
																	         backgroundColor: this.props.eventColorProvider(event),
																	         padding: 0
																         }
															         }/>
														    </Col>
													    </Row>
													    <Row>
														    <Col md={3}>
															    <label className="date-label">
																    {new Date(event.date).toLocaleDateString('en-US', options)}
															    </label>
														    </Col>
														    <Col md={6}>
															    <label
																    className="title-label">{event.title}</label>
														    </Col>
														    <Col md={1}>
															    <label className="impact-label"
															           style={
																           {
																	           color: this.props.eventColorProvider(event)
																           }
															           }>
																    {event.average_impact}
															    </label>
														    </Col>
														    <Col md={2}>
															    <label className="probability-label">
																    {`${event.average_probability}%`}
															    </label>
														    </Col>
													    </Row>
												    </Grid>
											    </li>
										    )
									    })
								}
							</ul>
						</Col>
					</Row>
				</Grid>
			</Popover>
		)
	}
}

export default EventsListPopover;