import clientFactory from "../factories/http_client";
import {API} from "../api/api";


class EventsMapService {

	/** @return  Promise<AxiosResponse<T>> */
	getFactors = (projectId, eventsMapId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.factors.method,
			                             url: API.data.projects.id.event_maps.id.factors.url(projectId, eventsMapId)
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	getEvents = (projectId, eventsMapId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.events.method,
			                             url: API.data.projects.id.event_maps.id.events.url(projectId, eventsMapId)
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	getCompactEvents = (projectId, futureMapId, filter, fields) => {

		const compactFilter = {};

		if (filter) {
			compactFilter.filter = filter;
		}

		if (fields) {
			compactFilter.fields = fields;
		}

		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.events.compact.method,
			                             url: API.data.projects.id.event_maps.id.events.compact.url(projectId, futureMapId),
			                             data: API.data.projects.id.event_maps.id.events.compact.build(compactFilter),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	getCompactFactors = (projectId, futureMapId, fields) => {

		const compactFilter = {};

		if (fields) {
			compactFilter.fields = fields;
		}

		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.factors.compact.method,
			                             url: API.data.projects.id.event_maps.id.factors.compact.url(projectId, futureMapId),
			                             data: API.data.projects.id.event_maps.id.factors.compact.build(compactFilter),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	getTrends = (projectId, eventsMapId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.trends.method,
			                             url: API.data.projects.id.event_maps.id.trends.url(projectId, eventsMapId)
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	getFilters = (projectId, eventsMapId, userId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.filters.method,
			                             url: API.data.projects.id.event_maps.id.filters.url(projectId, eventsMapId),
			                             params: API.data.projects.id.event_maps.id.filters.build(userId)
		                             });
	}
}

export default EventsMapService;