import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import * as event_sources_actions from "../actions/event-sources";
import {receiveEventSources, requestEventSources} from "../actions/event-sources";
import EventSourcesView from "../views/event-sources";
import {authorities} from "../mixins/authorities";
import {eventSourceService} from "../services";
import * as orphans_actions from "../actions/orphans";

const mapStateToProps = (state, ownProps) => {
	return {
		eventSources: state.eventSourcesReducer.eventSources,
		selectedEventSource: state.eventSourcesReducer.selectedEventSource ? state.eventSourcesReducer.selectedEventSource : null,
		eventSource: state.eventSourcesReducer.eventSource ? state.eventSourcesReducer.eventSource : null,
		orphans: state.orphans.items,
		selectedOrphan: state.orphans.selectedItem,
		eventSourceOperation: state.eventSourcesReducer.operation,
		...authorities(state.authenticationReducer.authentication)
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		loadInitialData: () => {
			dispatch(loadInitialDataActionChain());
		},
		createItem: (item) => {
			dispatch(createItemActionChain(item));
		},
		saveItem: (item) => {
			dispatch(saveItemActionChain(item));
		},
		selectItem: (item) => {
			dispatch(event_sources_actions.selectEventSource(item));
		},
		add: () => {
			dispatch(event_sources_actions.receiveEventSource({}));
		},
		edit: (item) => {
			dispatch(event_sources_actions.fetchEventSource(item.id));
		},
		delete: (item) => {
			dispatch(deleteItemActionChain(item));
		},
		selectOrphan: (item) => {
			dispatch(orphans_actions.selectItem(item))
		},
		clearOrphans: () => {
			dispatch(orphans_actions.receiveItems(null));
		},
		loadOrphanItemInfo: (type, path) => {

		},
		clearOperation: () => {
			dispatch(event_sources_actions.startOperation(null));
		}
	}
};

const loadInitialDataActionChain = () => {
	return (dispatch, getState) => {
		if (!getState().eventSourcesReducer.isPending) {
			dispatch(requestEventSources());
			eventSourceService.getAll()
			                  .then(sources_proxy => {
				                  dispatch(receiveEventSources(sources_proxy.data));
			                  })
		}
	}
}

const createItemActionChain = (item) => {
	return (dispatch, getState) => {
		dispatch(event_sources_actions.requestEventSources());
		eventSourceService.create(item)
		                  .then(event_source_proxy => {
			                  dispatch(event_sources_actions.appendEventSource(event_source_proxy.data));
			                  dispatch(event_sources_actions.endOperation({
				                                                              name: 'CREATION',
				                                                              status: "SUCCESS"
			                                                              }));
		                  })
		                  .catch((error) => {
			                  dispatch(event_sources_actions.endOperation({
				                                                              name: 'CREATION',
				                                                              status: "ERROR"
			                                                              }));
		                  })
	}
}

const saveItemActionChain = (item) => {
	return (dispatch, getState) => {
		dispatch(event_sources_actions.requestEventSource());
		eventSourceService.save(item)
		                  .then(sources_proxy => {
			                  dispatch(event_sources_actions.insertEventSource(sources_proxy.data));
			                  dispatch(event_sources_actions.endOperation({
				                                                              name: 'SAVING',
				                                                              status: "SUCCESS"
			                                                              }));
		                  })
		                  .catch((error) => {
			                  dispatch(event_sources_actions.endOperation({
				                                                              name: 'SAVING',
				                                                              status: "ERROR"
			                                                              }));
		                  })
	}
}

const deleteItemActionChain = (item) => {
	return (dispatch, getState) => {
		eventSourceService.delete(item.id)
		                  .then(item_proxy => {
			                  dispatch(event_sources_actions.fetchEventSources());
			                  dispatch(orphans_actions.receiveItems(null));
		                  })
		                  .catch((error) => {
			                  if (error.response.status === 409) {
				                  dispatch(orphans_actions.receiveItems(error.response.data));
			                  }
		                  });
	}
}

const EventSourcesContainer = connect(mapStateToProps, mapDispatchToProps)(EventSourcesView);

export default withRouter(EventSourcesContainer)