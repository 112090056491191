import {authorities} from "../mixins/authorities";
import * as scenariosActions from "../actions/scenarios";
import {browserHistory} from "../app";
import {CLIENT_URI_CONSTANTS as URI} from "../utils/uri-constants";
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import ScenariosView from "../views/scenarios";

const mapStateToProps = (state, ownProps) => {
	return {
		project: state.projectReducer.project,
		scenarios: state.scenariosReducer.scenarios,
		selectedScenario: state.scenariosReducer.selectedScenario,
		...authorities(state.authenticationReducer.authentication)
	}
};

const mapDispatchToProps = (dispatch, state) => {
	return {
		loadScenarios: (project) => {
			dispatch(scenariosActions.fetchScenarios(project.id, project.eventsMap.id))
		},
		selectScenario: (scenario) => {
			dispatch(scenariosActions.selectScenario(scenario));
		},
		deleteScenario: (project, scenario) => {
			dispatch(scenariosActions.deleteScenario(project.id, scenario.id));
		},
		editScenario: (project, scenario) => {
			dispatch(scenariosActions.receiveScenario(null))
			dispatch(scenariosActions.editScenario(project, scenario));
		},
		openEventMap: (project, scenario) => {
			browserHistory.push(`/${URI.PROJECT}/${project.id}/${URI.SCENARIOS}/${scenario.id}/${URI.SCENARIO_MAP}`);
		}
	}
};

const ScenariosContainer = connect(mapStateToProps, mapDispatchToProps)(ScenariosView);

export default withRouter(ScenariosContainer);