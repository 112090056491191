import React from 'react';
import {Button, Col, ControlLabel, Form, FormGroup, Modal} from 'react-bootstrap';
import {DropdownList} from "react-widgets";

class AuthorityForm
	extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			user: props.userAuthority ? props.userAuthority.user : null,
			authority: props.userAuthority ? props.userAuthority.authority : null
		};
	}

	handleSaveButton = () => {

		const userAuthority = this.props.userAuthority ? this.props.userAuthority : {};
		userAuthority.user = this.state.user;
		userAuthority.authority = this.state.authority;

		this.props.onSave(userAuthority);
	};

	UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
		this.setState({
			              user: nextProps.userAuthority ? nextProps.userAuthority.user : null,
			              authority: nextProps.userAuthority ? nextProps.userAuthority.authority : null
		              });
	}

	render() {
		return (
			<Modal show={this.props.show} onHide={this.props.onHide}>
				<Modal.Header closeButton>
					<Modal.Title>Authority Form</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form horizontal>
						<FormGroup>
							<Col componentClass={ControlLabel} sm={2}>
								User:
							</Col>
							<Col sm={10}>
								<DropdownList data={this.props.users}
								              onChange={this.setValue.bind(this, 'user')}
								              value={this.state.user}
								              textField='user_name'
								/>
							</Col>
						</FormGroup>
						<FormGroup>
							<Col componentClass={ControlLabel} sm={2}>
								Authority:
							</Col>
							<Col sm={10}>
								<DropdownList data={this.props.authorities}
								              onChange={this.setValue.bind(this, 'authority')}
								              value={this.state.authority}
								              textField='authority'
								/>
							</Col>
						</FormGroup>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button bsClass="btn action-button" onClick={this.handleSaveButton}>Save</Button>
				</Modal.Footer>
			</Modal>
		);
	}

	setEventValue(field, event) {
		//If the input fields were directly within this
		//this component, we could use this.refs.[FIELD].value
		//Instead, we want to save the data for when the form is submitted
		const object = {};
		object[field] = event.target.value;
		this.setState(object);
	}

	setEventChecked(field, event) {
		//If the input fields were directly within this
		//this component, we could use this.refs.[FIELD].value
		//Instead, we want to save the data for when the form is submitted
		const object = {};
		object[field] = event.target.checked;
		this.setState(object);
	}

	setValue(field, value) {
		//If the input fields were directly within this
		//this component, we could use this.refs.[FIELD].value
		//Instead, we want to save the data for when the form is submitted
		const object = {};
		object[field] = value;
		this.setState(object);
	}
}

export default AuthorityForm;