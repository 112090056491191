import {auth} from "../utils";

export const authorities = (authentication) => {
    let authorities = {
        authentication: authentication,
        isEntityOwner: (entity, type) => {
            return auth.hasEntityAuthority('ADMIN', entity.id, type, authorities.principal());
        },
        isSystemAdministrator: () => {
            return auth.hasRole('ADMIN', authorities.principal());
        },
        isAdministrator: (project) => {
            return authorities.isSystemAdministrator() || (project && auth.hasEntityAuthority('ADMIN', project.id, 'Project', authorities.principal()));
        },
        isAnalyst: (project) => {
            return project && auth.hasEntityAuthority('ANALYST', project.id, 'Project', authorities.principal());
        },
        isManager: (project) => {
            return  project && auth.hasEntityAuthority('MANAGER', project.id, 'Project', authorities.principal());
        },
        principal: () => {
            let authentication = authorities.authentication;
            if (authentication) {
                return authentication.principal;
            } else {
                return null;
            }
        }
    };
    return authorities;
};