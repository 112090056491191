import React from 'react';
import {Col, Grid, Image, Modal, Row} from 'react-bootstrap';

import Utils from '../../utils/utils';
import dateFormatter from 'dateformat';

class TrendPreview
	extends React.Component {

	constructor(props) {

		super(props);

		this.state = {};
	}


	componentDidCatch(error, errorInfo) {
		this.setState({
			              error: true
		              })
	}

	render() {

		const self = this;

		return (
			<Modal show={this.props.show}
			       onHide={this.props.onHide}
			       id="preview-modal"
			       className="preview-modal">
				<Modal.Body id="preview-modal-body"
				            className="body">
					<div id="preview-modal-body-content"
					     className="content">
						{
							!this.state.error &&
							<Grid bsClass="container-fluid c-grid">
								<Row>
									<Col md={1}>
										<div className="color-label"
										     style={{
											     backgroundColor: this.getTrendColor(this.props.trend.color)
										     }}/>
									</Col>
									<Col md={11}>
										<label className="title-label">{this.props.trend.name}</label>
									</Col>
								</Row>
								<Row>
									<Col md={12}>
										<div className="horizontal-separator"/>
									</Col>
								</Row>
								<Row>
									<Col md={5}>
										<Row bsClass="row c-row caption">
											<Col md={6}>
												<label className="caption-text">Factor:</label>
											</Col>
											<Col md={6}>
												<label className="caption-text">Author:</label>
											</Col>
										</Row>
										<Row bsClass="row c-row">
											<Col md={6}>
												<label className="value-text">{this.props.trend.factor.title}</label>
											</Col>
											<Col md={6}>
												<label className="value-text">{this.props.trend.author.user_name}</label>
											</Col>
										</Row>
										<Row bsClass="row c-row caption">
											<Col md={6}>
												<label className="caption-text">Start:</label>
											</Col>
											<Col md={6}>
												<label className="caption-text">End:</label>
											</Col>
										</Row>
										<Row bsClass="row c-row">
											<Col md={6}>
												<label className="value-text">{dateFormatter(this.props.trend.start, 'dd.mm.yyyy')}</label>
											</Col>
											<Col md={6}>
												<label
													className="value-text">{dateFormatter(this.props.trend.end, 'dd.mm.yyyy')}</label>
											</Col>
										</Row>
										<Row bsClass="row c-row caption">
											<Col md={6}>
												<label className="caption-text">Desription:</label>
											</Col>
										</Row>

									</Col>
								</Row>
								<Row bsClass="row c-row">
									<Col md={12}>
										{
											Utils.getPlainTextFromHtml(this.props.trend.description)
										}
									</Col>
								</Row>
							</Grid>
						}
					</div>
				</Modal.Body>
			</Modal>
		)
	}

	getTrendColor = (color) => {
		return `rgba(${color.red_addition}, ${color.green_addition}, ${color.blue_addition}, 0.4)`;
	};

}

export default TrendPreview;
