import React from 'react';

class LoadingBar
	extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			show: false
		};

		this.animationInProgress = false;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!this.state.show) {
			this.stopAnimation()
		} else {
			this.startAnimation()
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
		this.setState({
			              show: nextProps.show
		              });
	}

	shouldComponentUpdate(nextProps, nextState) {
		return this.state.show !== nextProps.show;
	}

	render() {
		return (
			<div className="loading-bar"
			     style={{display: (this.state.show ? 'block' : 'none')}}>
				<div ref={
					(element) => {
						this.progressRef = element;
					}
				}
				     className="progress"/>
			</div>
		);
	}

	startAnimation = () => {

		if (this.animationInProgress) {
			return;
		}

		const self = this;

		this.iteration = 1;

		if (self.progressRef) {
			self.progressRef.style.width = self.calculateWidth(self.iteration) + '%';
		}

		self.iteration++;

		this.animationTimerId = setInterval(function () {
			if (self.iteration > 50) {
				self.stopAnimation();
			} else {

				if (self.progressRef) {
					self.progressRef.style.width = self.calculateWidth(self.iteration) + '%';
				}

				self.iteration++;
			}
		}, 200);

		this.animationInProgress = true;
	};

	calculateWidth = (iteration) => {
		if (iteration > 0) {
			return 100 - (100 / (2 * iteration));
		} else {
			return 0;
		}
	};

	stopAnimation = () => {

		clearInterval(this.animationTimerId);

		this.animationInProgress = false;
	}
}

export default LoadingBar;