import {API} from "../api/api";
import clientFactory from "../factories/http_client";

class AuthenticationService {

	/** @return  Promise<AxiosResponse<T>> */
	getAuthentication = () => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.authentication.method,
			                             url: API.data.authentication.url
		                             })

	};

	/** @return  Promise<AxiosResponse<T>> */
	getAuthorities = (data) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.authentication.method,
			                             url: API.data.authentication.url
		                             })
	};
}

export default AuthenticationService;