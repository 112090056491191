import React from 'react';
import {Col, Glyphicon, Grid, Row} from 'react-bootstrap';

import {BOUNDS} from './future-map-models';

const GROUP_NAME = "header-switcher";
const CONTENT_MARGIN = 5;

class TimeScaleSwitcher
	extends React.PureComponent {

	constructor(props) {

		super(props);

		this.state = {
			timeScale: props.timeScale,
			emptyRowsHidden: props.emptyRowsHidden
		};
	}

	componentDidMount() {
		this.setState({
			              timeScale: this.props.timeScale,
			              emptyRowsHidden: this.props.emptyRowsHidden,
			              childFactorsHidden: this.props.childFactorsHidden
		              });
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (prevProps.timeScale !== this.props.timeScale) {
			this.setState({
				              timeScale: this.props.timeScale,
			              });
		}

		if (prevProps.emptyRowsHidden !== this.props.emptyRowsHidden) {
			this.setState({
				              emptyRowsHidden: this.props.emptyRowsHidden,
			              });
		}

		if (prevProps.childFactorsHidden !== this.props.childFactorsHidden) {
			this.setState({
				              childFactorsHidden: this.props.childFactorsHidden
			              });
		}
	}

	render() {

		const height = BOUNDS.VERTICAL_HEADER_SWITCHER_HEIGHT - (CONTENT_MARGIN * 2);

		const width = BOUNDS.VERTICAL_HEADER_SWITCHER_WIDTH - (CONTENT_MARGIN * 2);

		return (
			<div id="fm-vertical-headers-switcher-content"
			     style={
				     {
					     height: height + 'px',
					     width: width + 'px',
					     margin: CONTENT_MARGIN + 'px'
				     }
			     }>
				<Grid style={
					{
						height: height + 'px',
						width: width + 'px'
					}
				}>
					<Row>
						<Col md={2} bsClass="c-col col">
							<Glyphicon glyph="calendar" style={{marginTop: '23px'}}/>
						</Col>
						<Col md={10} bsClass="c-col col" className='time-scale-switch'>
							<Row>
								<Col md={12}>
									<input type="radio"
									       name={GROUP_NAME}
									       value="years"
									       checked={this.state.timeScale === 'years'}
									       onChange={() => this.onTimeScaleChange('years')}
									/>
									Years
								</Col>
							</Row>
							<Row>
								<Col md={12}>
									<input type="radio"
									       name={GROUP_NAME}
									       value="months"
									       checked={this.state.timeScale === 'months'}
									       onChange={() => this.onTimeScaleChange('months')}
									/>
									Months
								</Col>
							</Row>
							<Row>
								<Col md={12}>
									<input type="radio"
									       name={GROUP_NAME}
									       value="days"
									       checked={this.state.timeScale === 'days'}
									       onChange={() => this.onTimeScaleChange('days')}
									/>
									Days
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col md={12} className="m-0 p-0">
							<Grid fluid={true} className="m-0 p-0">
								<Row className="m-0 p-0">
									<Col md={12} className="p-0"
									     style={
										     {
											     padding: '0 3px'
										     }
									     }
									>
										<input type="checkbox"
										       onChange={this.props.onEmptyHideChange}
										/>
										Hide empty
									</Col>
								</Row>
								<Row className="m-0 p-0">
									<Col md={12}
									     className="p-0"
									     style={
										     {
											     padding: '0 3px'
										     }
									     }
									>
										<input type="checkbox"
										       onChange={this.props.onChildFactorsHideChange}
										/>
										Hide child factors
									</Col>
								</Row>
							</Grid>
						</Col>
					</Row>
				</Grid>
			</div>
		)
	}

	onTimeScaleChange = (scale) => {
		this.props.onSwitch(scale);
	};
}

export default TimeScaleSwitcher;