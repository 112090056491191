import React from 'react'

class InfiniteContainer
	extends React.PureComponent {

	constructor(props) {

		super(props);

		this.state = {
			startIndex: this.calculateStartIndex(0),
			endIndex: this.calculateEndIndex(0),
			invalidated: true
		};

		this.itemsMap = {};
	}

	componentDidMount() {
		if (this.container) {
			this.container.addEventListener('scroll', this.updateScrollPosition);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (this.props.onRangeChange && (prevState.startIndex !== this.state.startIndex || prevState.endIndex !== this.state.endIndex)) {
			this.props.onRangeChange(this.state.startIndex, this.state.endIndex);
		}

		if (prevProps.lazy !== this.props.lazy ||
			prevProps.itemsCount !== this.props.itemsCount ||
			prevProps.items !== this.props.items ||
			prevProps.itemHeight !== this.props.itemHeight) {

			let oldContainerHeight;

			if (this.props.lazy) {
				oldContainerHeight = prevProps.itemsCount * prevProps.itemHeight;
			} else {
				oldContainerHeight = prevProps.items.length * prevProps.itemHeight;
			}

			const scrollPositionRelation = oldContainerHeight !== 0 ? this.container.scrollTop / oldContainerHeight : 0;

			let newContainerHeight;

			if (this.props.lazy) {
				newContainerHeight = this.props.itemsCount * this.props.itemHeight;
			} else {
				newContainerHeight = this.props.items.length * this.props.itemHeight;
			}

			const newScrollPosition = newContainerHeight * scrollPositionRelation;

			let start_index = this.calculateStartIndex(newScrollPosition, this.props.itemsCount);

			let end_index = this.calculateEndIndex(newScrollPosition, this.props.itemsCount);

			this.setState({
				              startIndex: start_index,
				              endIndex: end_index
			              }, () => {
				this.container.scrollTop = newScrollPosition;
			});
		}
	}

	componentWillUnmount() {
		if (this.container) {
			this.container.removeEventListener('scroll', this.updateScrollPosition);
		}
	}

	render() {

		let items = [];

		if (this.props.lazy) {

			let endIndex = (this.state.endIndex < this.props.itemsCount) ? this.state.endIndex : this.props.itemsCount - 1;

			for (let i = this.state.startIndex; i <= endIndex; i++) {

				let item = this.props.itemFactory(i);

				items.push(item);
			}
		} else {
			items = this.props.items.slice(this.state.startIndex, this.state.endIndex);
		}

		return (
			<div className="infinite-container" ref={this.containerRef} style={this.props.style}>
				<div className="spacer top"
				     style={
					     {
						     height: this.state.startIndex * this.props.itemHeight
					     }
				     }
				/>
				{items}
				<div className="spacer bottom"
				     style={
					     {
						     height: ((this.props.lazy ? (this.props.itemsCount - 1) : this.props.items.length) - this.state.endIndex) * this.props.itemHeight
					     }
				     }
				/>
			</div>
		)
	}

	containerRef = (element) => {

		this.container = element;

		if (this.props.containerRef) {
			this.props.containerRef(element);
		}
	};

	updateScrollPosition = (event) => {

		if (this.scheduledAnimationFrame) {
			return;
		}

		this.scheduledAnimationFrame = true;

		window.requestAnimationFrame(() => {

			const startPosition = this.calculateStartIndex(this.container.scrollTop);

			const endPosition = this.calculateEndIndex(this.container.scrollTop);

			this.setState({
				              startIndex: startPosition,
				              endIndex: endPosition
			              },
			              () => {
				              this.scheduledAnimationFrame = false;
			              });
		})
	};

	calculateStartIndex = (scrollPosition, itemsCount) => {
		const itemPosition = scrollPosition / this.props.itemHeight;
		return Math.floor((itemPosition - this.props.maxItemsToRender) >= 0 ? (itemPosition - this.props.maxItemsToRender) : 0);
	};

	calculateEndIndex = (scrollPosition, itemsCount) => {

		const itemPosition = scrollPosition / this.props.itemHeight;

		const realItemsCount = this.props.lazy ? (itemsCount ? itemsCount : this.props.itemsCount) : this.props.items.length;

		if (realItemsCount > 0) {

			const endIndex = (itemPosition + this.props.maxItemsToRender) < realItemsCount ? (itemPosition + this.props.maxItemsToRender) : realItemsCount - 1;

			return Math.floor(endIndex);
		} else {
			return 0;
		}
	}
}

export default InfiniteContainer