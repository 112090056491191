import {actionTypes} from "../actions/events";

const initialState = {
	isPending: false,
	invalidated: false,
	events: null,
	event: null,
	selectedEvents: null,
	operation: null
};

const events_comparator = (o1, o2) => {
	if (o1.factor_id > o2.factor_id) {
		return 1
	} else if (o1.factor_id < o2.factor_id) {
		return -1;
	} else {
		if (o1.date > o2.date) {
			return 1
		} else if (o1.date < o2.date) {
			return -1
		} else {
			return 0;
		}
	}
}

function eventsReducer(state = initialState, action) {

	let newState = null;

	switch (action.type) {
		case actionTypes.get(actionTypes.REQUEST_EVENTS):
			return {
				...state,
				isPending: true
			};
		case actionTypes.get(actionTypes.REQUEST_EVENT):
			return {
				...state,
				isPending: true
			};
		case actionTypes.get(actionTypes.RECEIVE_EVENTS):

			let events = action.data;

			return {
				...state,
				isPending: false,
				events: events && Array.isArray(events) ? events.sort(events_comparator) : events
			};
		case actionTypes.get(actionTypes.SELECT_EVENTS):
			return {
				...state,
				selectedEvents: action.data
			};
		case actionTypes.get(actionTypes.RECEIVE_EVENT):
			return {
				...state,
				isPending: false,
				event: action.data
			};
		case actionTypes.get(actionTypes.APPEND_EVENT):
			return {
				...state,
				isPending: false,
				events: state.events && Array.isArray(state.events) ? state.events.concat([action.data]).sort(events_comparator) : [].concat(action.data).sort(events_comparator)
			};
		case actionTypes.get(actionTypes.INSERT_EVENT):

			let index = state.events.findIndex((item) => {
				return item.id === action.data.id;
			});

			if (index !== -1) {
				state.events.splice(index, 1, action.data)
			} else {
				state.events.push(action.data);
			}

			return {
				...state,
				isPending: false,
				events:  [...state.events]
			};
		case actionTypes.get(actionTypes.RECEIVE_LINK):

			newState = {
				...state
			};
			newState.event.link = action.data;

			return newState;
		case actionTypes.get(actionTypes.SELECT_LINK):

			newState = {
				...state
			};
			newState.event.selectedLink = action.data;

			return newState;
		case actionTypes.get(actionTypes.RECEIVE_ATTACHMENT):

			newState = {
				...state
			};
			newState.event.attachment = action.data;

			return newState;
		case actionTypes.get(actionTypes.SELECT_ATTACHMENT):

			newState = {
				...state
			};
			newState.event.selectedAttachment = action.data;

			return newState;
		case actionTypes.get(actionTypes.START_OPERATION):
			return {
				...state,
				operation: action.data
			};
		case actionTypes.get(actionTypes.END_OPERATION):
			return {
				...state,
				operation: action.data
			};
		default:
			return state
	}
}

export default eventsReducer