import clientFactory from "../factories/http_client";
import {API} from "../api/api";

class EventTypeService {

	/** @return  Promise<AxiosResponse<T>> */
	getById = (id) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.event_types.id.method,
			                             url: API.data.event_types.id.url(id)
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	getAll = () => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.event_types.method,
			                             url: API.data.event_types.url
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	create = (type) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.event_types.create.method,
			                             url: API.data.event_types.create.url,
			                             data: API.data.event_types.create.build(type),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	save = (type) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.event_types.save.method,
			                             url: API.data.event_types.save.url,
			                             data: API.data.event_types.save.build(type),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	delete = (typeId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.event_types.delete.method,
			                             url: API.data.event_types.delete.url,
			                             data: API.data.event_types.delete.build(typeId),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};
}

export default EventTypeService