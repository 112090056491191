import React from "react";
import {Button, Glyphicon, Navbar, Table} from 'react-bootstrap';

import BaseComponent from 'components/base/base-component';
import Utils from 'utils/utils';
import {EventRelationType} from "./event-models";


class RelationsTab extends BaseComponent {

	constructor(props) {

		super(props);

		this.event = props.event;
	}

	render() {
		return (
			<div>
				<Navbar fluid={true} bsClass="c-navbar navbar-default">
					<div className="tab-buttons-group">
						{
							this.props.hasAddButton &&
							<Button bsClass="btn icon action-button" onClick={this.props.onAdd}>
								<Glyphicon glyph="plus" className="c-glyph-icon"/>
								<div className="label-wrapper">
									<label>Add</label>
								</div>
							</Button>
						}
						{
							this.props.hasDeleteButton &&
							<Button bsClass="btn icon action-button" onClick={this.props.onDelete}>
								<Glyphicon glyph="remove" className="c-glyph-icon"/>
								<div className="label-wrapper">
									<label>Delete</label>
								</div>
							</Button>
						}
					</div>
				</Navbar>
				<Table>
					<thead>
					<tr>
						<th style={{width: '10%', maxWidth: '15%'}}>Key</th>
						<th>Title</th>
						<th style={{width: '10%', maxWidth: '15%'}}>Relation type</th>
						<th style={{width: '10%', maxWidth: '10%'}}>Rel. prob.</th>
						<th style={{width: '15%', maxWidth: '15%'}}>Date</th>
						<th>Comment</th>
					</tr>
					</thead>
					<tbody>
					{
						this.props.relations.map(relation => {
							if (relation.right_event) {
								return (
									<tr key={relation.id}
									    onClick={() => this.props.onItemClick(relation)}
									    className={this.props.selected && this.props.selected.id === relation.id ? 'selected' : ''}>
										<td>{relation.right_event.key}</td>
										<td>{relation.right_event.title}</td>
										<td>{EventRelationType.fromCode(relation.relation_kind).text}</td>
										<td>{relation.right_event.relative_probability ? "true" : "false"}</td>
										<td>{Utils.convertDatetime(relation.right_event.date, "d mmmm yyyy")}</td>
										<td>{relation.comment}</td>
									</tr>
								);
							} else {
								return null;
							}
						})
					}
					</tbody>
				</Table>
			</div>
		);
	}
}

export default RelationsTab;