import {connect} from "react-redux";
import {withRouter} from "react-router";
import * as factorsActions from "../actions/factors";
import * as flagsActions from "../actions/flags";
import FactorView from "../views/factor";
import {authorities} from "../mixins/authorities";

const mapStateToProps = (state, ownProps) => {
	return {
		project: state.projectReducer.project,
		factor: state.factorReducer.factor,
		factors: state.factorReducer.factors,
		selectedFactor: state.factorReducer.selectedFactor,
		selectedLink: state.factorReducer.factor ? state.factorReducer.factor.selectedLink : null,
		link: state.factorReducer.factor ? state.factorReducer.factor.link : null,
		selectedAttachment: state.factorReducer.factor ? state.factorReducer.factor.selectedAttachment : null,
		attachment: state.factorReducer.factor ? state.factorReducer.factor.attachment : null,
		flags: state.flagsReducer.flags,
		journalEntries: state.factorReducer.factor ? (state.factorReducer.factor.journal_entries ? state.factorReducer.factor.journal_entries : null) : null,
		selectedJournalEntry: state.factorReducer.factor ? (state.factorReducer.factor.selected_journal_entry ? state.factorReducer.factor.selected_journal_entry : null) : null,
		factorOperation: state.factorReducer.operation,
		...authorities(state.authenticationReducer.authentication)
	}
};

const mapDispatchToProps = (dispatch, state) => {
	return {
		loadFactors: (project) => {
			dispatch(factorsActions.fetchFactors(project.id, project.eventsMap.id))
		},
		loadFactor: (project, id) => {
			dispatch(factorsActions.fetchFactor(project.id, project.eventsMap.id, id))
		},
		saveFactor: (project, factor) => {
			if (!factor.id) {
				factor.future_map = project.eventsMap;
				dispatch(factorsActions.createFactor(project.id, project.eventsMap.id, factor));
			} else {
				factor.future_map = project.eventsMap;
				dispatch(factorsActions.saveFactor(project.id, project.eventsMap.id, factor));
			}
		},
		selectLink: (link) => {
			dispatch(factorsActions.selectLink(link));
		},
		editLink: (project, factor, link) => {
			dispatch(factorsActions.fetchLink(project.id, project.eventsMap.id, factor.id, link.id));
		},
		saveLink: (project, factor, link) => {
			if (link.id) {
				dispatch(factorsActions.saveLink(project.id, project.eventsMap.id, factor.id, link));
			} else {
				link.factor = {
					id: factor.id,
					retrospectionId: factor.retrospectionId
				};
				dispatch(factorsActions.createLink(project.id, project.eventsMap.id, factor.id, link));
			}
		},
		deleteLink: (project, factor, link) => {
			dispatch(factorsActions.deleteLink(project.id, project.eventsMap.id, factor.id, link.id));
		},
		clearLink: () => {
			dispatch(factorsActions.receiveLink(null));
		},
		selectAttachment: (attachment) => {
			dispatch(factorsActions.selectAttachment(attachment));
		},
		editAttachment: (project, factor, attachment) => {
			dispatch(factorsActions.fetchAttachment(project.id, project.eventsMap.id, factor.id, attachment.id));
		},
		saveAttachment: (project, factor, attachment) => {
			if (attachment.id) {
				dispatch(factorsActions.saveAttachment(project.id, project.eventsMap.id, factor.id, attachment));
			} else {
				attachment.factor = {
					id: factor.id,
					retrospectionId: factor.retrospectionId
				};
				dispatch(factorsActions.createAttachment(project.id, project.eventsMap.id, factor.id, attachment));
			}
		},
		deleteAttachment: (project, factor, attachment) => {
			dispatch(factorsActions.deleteAttachment(project.id, project.eventsMap.id, factor.id, attachment.id));
		},
		clearAttachment: () => {
			dispatch(factorsActions.receiveAttachment(null));
		},
		loadFlags: () => {
			dispatch(flagsActions.fetchFlags());
		},
		loadJournalEntries: (project, factor) => {
			dispatch(factorsActions.fetchJournalEntries(project.id, project.eventsMap.id, factor.id));
		},
		selectJournalEntry: (entry) => {
			dispatch(factorsActions.selectJournalEntry(entry));
		},
		clearOperation: () => {
			dispatch(factorsActions.startOperation(null));
		}
	}
};

const FactorContainer = connect(mapStateToProps, mapDispatchToProps)(FactorView);

export default withRouter(FactorContainer)