import React from 'react';
import {Col, Glyphicon, Grid, Image, Row} from 'react-bootstrap';
import SideNav, {Nav, NavIcon, NavText} from 'react-sidenav';

import {CLIENT_URI_CONSTANTS as URI} from 'utils/uri-constants';
import {auth} from "../utils";
import Utils from "../utils/utils";
import LoadingBar from "../components/common/loading-bar";

const ROUTES = {
	"login": `/${URI.LOGIN}`,

	"application/lang": `/${URI.HOME}`,
	"application/logout": `/${URI.LOGOUT}`,

	"dictionaries/event-categories": `/${URI.EVENT_CATEGORIES}`,
	"dictionaries/event-sources": `/${URI.EVENT_SOURCES}`,
	"dictionaries/event-types": `/${URI.EVENT_TYPES}`,
	"dictionaries/project-categories": `/${URI.PROJECT_CATEGORIES}`,
	"dictionaries/numeric-units": `/${URI.NUMERIC_UNITS}`,

	"security/permissions": `/${URI.USERS}`,
	"security/logs": `/${URI.HOME}`,
	"home": `/${URI.HOME}`,
	"services/autofill": `/autofill-service`
};

const SeparatorTitle = (props) => {
	return (
		<div className="separator-title-container">
			{props.children}
			<hr style={{
				border: 0,
				borderTop: '1px solid #5f6d85',
				margin: "2px 0"
			}}/>
		</div>
	);
};

class AppView
	extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			projectNewsToggleBarOpened: false,
		}
	}

	render() {

		let dictionariesNav;
		let securityNav;

		if (this.props.authentication && auth.hasRole('ADMIN', this.props.authentication.principal)) {
			dictionariesNav = (
				<Nav id="dictionaries">
					<NavIcon><Glyphicon glyph="book"/></NavIcon>
					<NavText> Dictionaries </NavText>
					<Nav id="event-categories">
						<NavText> Event categories </NavText>
					</Nav>
					<Nav id="event-sources">
						<NavText> Event sources </NavText>
					</Nav>
					<Nav id="event-types">
						<NavText> Event types </NavText>
					</Nav>
					<Nav id="project-categories">
						<NavText> Project categories </NavText>
					</Nav>
					<Nav id="numeric-units">
						<NavText> Numeric units </NavText>
					</Nav>
				</Nav>
			);

			securityNav = (
				<Nav id="security">
					<NavIcon><Glyphicon glyph="flash"/></NavIcon>
					<NavText> Security </NavText>
					<Nav id="permissions">
						<NavText> Permissions </NavText>
					</Nav>
					{/*<Nav id="logs">
						<NavText> Authorization logs </NavText>
					</Nav>*/}
				</Nav>
			);
		}

		let defaultSelected = 'home';

		if (this.props.location.pathname.match(/^\/project\/\d*\/info$/i)) {
			defaultSelected = 'project-info';
		} else if (this.props.location.pathname.match(/^\/project\/\d*\/factors-map$/i)) {
			defaultSelected = 'project-factors-map';
		} else if (this.props.location.pathname.match(/^\/project\/\d*\/events-map$/i)) {
			defaultSelected = 'project-events-map';
		} else if (this.props.location.pathname.match(/^\/user\/\d*\/change-password$/i)) {
			defaultSelected = 'project-events-map';
		} else {
			defaultSelected = Object.keys(ROUTES)
			                        .find(key => ROUTES[key] === this.props.location.pathname)
		}



		return (
			<Grid fluid={true} className="align-items-stretch w-100 h-100 p-0 m-0">
				<Row className="h-100 m-0 p-0 display-flex">
					<Col id="menu-block" md={2} className="h-100 application-menu">
						<div className="logo-placeholder">
							<Image src={Utils.getResourceUri('/icons/future_logo.png')} className="logo"/>
						</div>
						<SideNav className="application-navigation"
						         highlightBgColor='#252f3f'
						         highlightColor='#337ab7'
						         defaultSelected={defaultSelected}
						         onItemSelection={
							         (id, parent) => {
								         if (id === "project-info") {
									         this.props.history.push(
										         `/${URI.PROJECT}/${this.props.project.id}/${URI.INFO}`);
								         } else if (id === "project-factors-map") {
									         this.props.history.push(
										         `/${URI.PROJECT}/${this.props.project.id}/${URI.FACTORS_MAP}`);
								         } else if (id === "project-events-map") {
									         this.props.history.push(
										         `/${URI.PROJECT}/${this.props.project.id}/${URI.FUTURE_MAP}`);
								         } else if (id === "settings/password") {
									         this.props.history.push(
										         `/${URI.USERS}/${this.props.authentication.principal.id}/${URI.CHANGE_PASSWORD}`);
								         } else if (id === "services/autofill") {
									         window.location.replace("/autofill-client/projects");
								         } else {
									         ROUTES[id] && this.props.history.push(ROUTES[id]);
								         }
							         }
						         }>
							<SeparatorTitle>
								<div> Home</div>
							</SeparatorTitle>
							<Nav id="home">
								<NavIcon><Glyphicon glyph="home"/></NavIcon>
								<NavText> Start page </NavText>
							</Nav>
							{
								this.props.project &&
								<SeparatorTitle>
									<div> Project: {this.props.project.name}</div>
								</SeparatorTitle>
							}
							{
								this.props.project &&
								<Nav id="project-info">
									<NavIcon><Glyphicon glyph="home"/></NavIcon>
									<NavText> Info </NavText>
								</Nav>
							}
							{
								this.props.project && this.props.project.id &&
								<Nav id="project-factors-map">
									<NavIcon><Glyphicon glyph="random"/></NavIcon>
									<NavText> Factor Map </NavText>
								</Nav>
							}
							{
								this.props.project && this.props.project.id &&
								<Nav id="project-events-map">
									<NavIcon><Glyphicon glyph="th"/></NavIcon>
									<NavText> Events Map </NavText>
								</Nav>
							}
							<SeparatorTitle>
								<div> Menu</div>
							</SeparatorTitle>
							<Nav id="application" className="application">
								<NavIcon><Glyphicon glyph="th-large"/></NavIcon>
								<NavText> Application </NavText>
								<Nav id="lang">
									<NavText> Ru/En </NavText>
								</Nav>
								<Nav id="logout">
									<NavText> Logout </NavText>
								</Nav>
							</Nav>
							<Nav id="services" className="services">
								<NavIcon><Glyphicon glyph="th-large"/></NavIcon>
								<NavText> Services </NavText>
								<Nav id="autofill">
									<NavText> Autofill </NavText>
								</Nav>
							</Nav>
							{dictionariesNav}
							{securityNav}
							<Nav id="settings">
								<NavIcon><Glyphicon glyph="cog"/></NavIcon>
								<NavText> Settings </NavText>
								<Nav id="password">
									<NavText> Change password </NavText>
								</Nav>
							</Nav>
						</SideNav>
					</Col>
					<Col md={10} className="h-100 display-flex align-items-stretch application-content">
						<LoadingBar show={this.props.pendingData}/>
						<Grid fluid={true} className="display-flex align-items-stretch flex-direction-column w-100 view">
							<Row className="view-content">
								<Col md={12} className="view-content">
									{this.props.children}
								</Col>
							</Row>
						</Grid>
					</Col>
				</Row>
			</Grid>
		);
	}

	handleLoadProjectNews = (project, period) => {
		this.props.loadNews(project, period);
	}
}

const Item = (props) => {
	const context = React.useContext(NavContext);
	return (
		<div style={{color: context.selected ? 'pink' : 'inherit'}}>{props.children}</div>
	)
}


export default AppView