import React from "react";

class SelectBox extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            opened: false,
            value: props.value ? props.value : []
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            value: nextProps.value ? nextProps.value : []
        });
    };

    componentDidMount() {
        if (this.state.opened) {
            this.refs.selectContainer.focus();
        }
    };

    componentDidUpdate() {
        if (this.state.opened) {
            this.refs.selectContainer.focus();
        }
    };

    componentWillUpdate(nextProps, nextState) {
        return nextState.value.length === this.state.value.length;
    }

    open = () => {
        const self = this;
        this.setState({opened: true});
    };

    close = () => {
        const self = this;
        this.setState({opened: false}, () => {
            if (self.state.opened) {
                self.refs.selectContainer.focus();
            }
        });
    };

    clear = () => {
        const self = this;
        this.setState({opened: false, value: []}, () => {
            self.props.onChange(self.state.value)
        });
    };

    isSelected = (element) => {
        const self = this;
        return this.state.value.filter(function (value) {
            return self.isEqual(value, element);
        }).length !== 0;
    };

    isEqual = (element1, element2) => {
        if (this.props.valueProperty) {
            return element1[this.props.valueProperty] === element2[this.props.valueProperty];
        } else {
            return element1 === element2;
        }
    };

    render() {

        const self = this;

        var className = 'react-select-box container';

        if (this.props.className) {
            className += ' ' + this.props.className
        }

        if (this.state.value.length === 0) {
            className += ' empty'
        }

        return (
            <div className={className}>
                <div className="output-container">
                    <input className="output"
                           readOnly={true}
                           value={
                               this.state.value
                                   .map(function (element) {
                                       return element[self.props.textProperty];
                                   }).join(', ')
                           }
                           onClick={this.state.opened ? this.close : this.open}
                    />
                    <div className="controls">
                        {
                            this.state.value.length !== 0 &&
                            <button type="button" className="clear no-select"
                                    onClick={this.clear}>
                                <i></i>
                            </button>
                        }
                        <button type="button" className="toggle no-select"
                                onClick={this.state.opened ? this.close : this.open}>
                            <i></i>
                        </button>
                    </div>
                </div>
                {this.renderSelect()}
            </div>
        )
    };

    handleNativeChange = (event) => {

        const self = this;

        if (event.target.checked) {
            let value = this.props.data.filter(function (element) {
                return element[self.props.valueProperty] == event.target.value;
            })[0];
            let currentValue = this.state.value;
            currentValue.push(value);
            this.setState(
                {
                    value: currentValue
                },
                () => {
                    if (!self.props.changeOnClose) {
                        self.props.onChange(self.state.value);
                    }
                });
        } else {
            let value = this.state.value.filter(function (element) {
                return element[self.props.valueProperty] != event.target.value;
            });
            this.setState(
                {
                    value: value
                },
                () => {
                    if (!self.props.changeOnClose) {
                        self.props.onChange(self.state.value);
                    }
                });
        }
    };

    handleOutsideClick = (e) => {
        const self = this;
        if (e.type === 'blur') {
            this.timeoutId = setTimeout(function () {
                self.close();
                if (self.props.changeOnClose) {
                    self.props.onChange(self.state.value);
                }
            }, 200);
        } else if (e.type === 'focus') {
            clearTimeout(this.timeoutId);
        }
    };

    renderSelect = () => {
        const self = this;
        let data = self.props.data ? self.props.data : [];
        return React.createElement('div',
            {
                className: 'select-container',
                ref: 'selectContainer',
                style: {
                    display: this.state.opened ? 'block' : 'none'
                },
                onFocus: self.state.opened ? self.handleOutsideClick : undefined,
                onBlur: self.state.opened ? self.handleOutsideClick : undefined,
            },
            React.createElement(
                'fieldset',
                {
                    'data-value': this.state.value.map(function (element) {
                        return element[self.props.valueProperty];
                    })
                },
                React.createElement(
                    'ul',
                    {
                        className: 'list'
                    },
                    data.map(function (element) {
                        return React.createElement(
                            'li',
                            {
                                key: 'li-' + element[self.props.valueProperty],
                                className: 'list-item'
                            },
                            React.createElement(
                                'div',
                                {
                                    className: 'list-item-content' + (self.isSelected(element) ? ' checked' : ''),
                                },
                                React.createElement(
                                    'div',
                                    {
                                        className: 'checkbox-wrapper',
                                    },
                                    React.createElement(
                                        'input',
                                        {
                                            id: self.props.name + '-' + element[self.props.valueProperty],
                                            name: self.props.name,
                                            type: "checkbox",
                                            key: element[self.props.valueProperty],
                                            value: element[self.props.valueProperty],
                                            onChange: self.handleNativeChange,
                                            checked: self.isSelected(element)
                                        }
                                    )
                                ),
                                React.createElement(
                                    'label',
                                    {
                                        htmlFor: self.props.name + '-' + element[self.props.valueProperty],
                                    },
                                    element[self.props.textProperty])
                            )
                        )
                    })
                )
            )
        )
    };
}

export default SelectBox;