import React from "react";

class GridCell
	extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className={`fm-grid-cell ${this.props.selected ? " selected" : ""} ${this.props.highlighted ? " highlighted" : ""}`}
			     data-row-index={this.props.rowIndex}
			     data-column-index={this.props.columnIndex}
			     onClick={this.props.onClick}
			     onContextMenu={this.props.onContextMenu}
			     onDoubleClick={this.props.onDoubleClick}
			     style={
				     {
					     height: '100%',
					     width: this.props.width + 'px',
				     }
			     }
			     onDragOver={
				     (event) => {
					     event.preventDefault();
				     }
			     }
			     onDrop={
				     (event) => {
					     this.props.onDrop(this.props.rowIndex, this.props.columnIndex, event, event)
				     }
			     }>
				{
					this.props.controlFactory && this.props.item && this.props.controlFactory(this.props.item,
					                                                                          this.props.rowIndex,
					                                                                          this.props.columnIndex)
				}
			</div>
		)
	}
}

export default GridCell