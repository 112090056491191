import React from 'react';
import {DropdownList} from 'react-widgets';
import {factorCommentService} from '../../services';

import {Button, Col, ControlLabel, Form, FormGroup, Image, Tab, Tabs, Modal, Glyphicon} from 'react-bootstrap';
import Utils from "../../utils/utils";
import LinksTab from "../common/tab/links-tab";
import CommentsTab from "../common/tab/comments-tab";
import AttachmentsTab from "../common/tab/attachments-tab";
import JournalTab from "../common/tab/journal-tab";
import {TreeNode} from "rc-tree-select";
import TreeSelect from "rc-tree-select";
import TreeSelectionForm from "../base/tree-selection-form";
import Summernote from "../base/summernote-base";
import AttachmentForm from "../common/attachment-form";
import LinkForm from "../common/link-form";
import {mixinFactory as OperationMixinFactory} from "../../mixins/operation";
import {mixinFactory as ChangedStateMixinFactory} from "../../mixins/changed-state";

class FactorDialog
	extends React.Component {

	constructor(props) {

		super(props);

		Object.assign(FactorDialog.prototype, OperationMixinFactory({
			                                                            operationPropName: 'factorOperation',
			                                                            operationStateName: 'operationStatus'
		                                                            }))

		Object.assign(FactorDialog.prototype, ChangedStateMixinFactory({
			                                                             scope: 'change_detector',
			                                                             observable: 'factor',
			                                                             excludedFields: [
				                                                             'activeTab',
				                                                             'operationStatus',
				                                                             'showModal',
				                                                             'factors',
				                                                             'activeTab',
				                                                             'showAuthorityModal',
				                                                             'showLinkModal',
				                                                             'showAttachmentModal',
				                                                             'saveEnabled',
				                                                             'addLinkEnabled',
				                                                             'editLinkEnabled',
				                                                             'removeLinkEnabled',
				                                                             'addMessageEnabled',
				                                                             'editMessageEnabled',
				                                                             'deleteMessageEnabled',
				                                                             'addAttachmentEnabled',
				                                                             'editAttachmentEnabled',
				                                                             'deleteAttachmentEnabled',
				                                                             'author'
			                                                             ],
			                                                             stateField: 'dirty'
		                                                             }))

		this.state = {
			parent: this.getParent(props),
			name: props.factor ? props.factor.title : "",
			flag: props.factor ? props.factor.flag : null,
			note: props.factor ? props.factor.note : "",
			showModal: false,
			factors: props.factors,
			activeTab: 2,
			showAuthorityModal: false,
			showLinkModal: false,
			showAttachmentModal: false,
			...this.setPermissions(props)
		};

		if (props.factor) {
			this.change_detector.setInitialState(this.state);
		}

		this.flagItem = ({item}) => {
			if (item) {
				return (
					<Image src={Utils.getResourceUri(item.path)}/>
				)
			} else {
				return null;
			}
		};

		if (props.factors) {
			this.factors = Utils.getFlatFactors([{
				id: -1,
				title: props.project ? props.project.name : "",
				children: props.factors
			}]);
		} else {
			this.factors = [{
				id: -1,
				title: props.project ? props.project.name : "",
				children: []
			}]
		}
	}

	componentDidMount() {

		if (this.props.project) {
			this.setState({
				              parent: this.getParent(this.props),
				              ...this.setPermissions(this.props)
			              })
		}

		let params = new URLSearchParams(this.props.history.location.search);

		if (this.props.project && params.get("id") !== "0") {
			this.props.loadFactor(this.props.project, params.get("id"));
		}

		if (!this.props.flags) {
			this.props.loadFlags();
		}

		if (this.props.project && !this.props.factors) {
			this.props.loadFactors(this.props.project);
		}

		if (this.props.project && this.props.factor && !this.props.journalEntries) {
			this.props.loadJournalEntries(this.props.project, this.props.factor);
		}

		this.operationState(null, null);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (prevProps.project !== this.props.project) {
			this.setState({
				              parent: this.getParent(this.props),
				              ...this.setPermissions(this.props)
			              })
		}

		if (prevProps.factors !== this.props.factors && this.props.factors) {
			this.factors = Utils.getFlatFactors([{
				id: -1,
				title: this.props.project.name,
				children: this.props.factors
			}]);
		}

		if (prevProps.factor !== this.props.factor && this.props.factor) {

			let parent = this.getParent(this.props);

			this.setState({
				              name: this.props.factor ? this.props.factor.title : "",
				              note: this.props.factor ? this.props.factor.note : "",
				              flag: this.props.factor ? this.props.factor.flag : null,
				              parent: parent,
				              showAuthorityModal: !!this.props.userAuthority,
				              showLinkModal: !!this.props.link,
				              showAttachmentModal: !!this.props.attachment,
				              ...this.setPermissions(this.props)
			              },
			              () => {
				              this.change_detector.setInitialState(this.state);
			              });
		}


		if (prevProps.attachment !== this.props.attachment && this.props.attachment != null) {
			this.setState({
				              showAttachmentModal: true
			              })
		}

		if (prevProps.link !== this.props.link && this.props.link != null) {
			this.setState({
				              showLinkModal: true
			              })
		}

		this.operationState(prevProps, prevState);
		this.detectChanged(prevProps, prevState);
	}

	getParent = (props) => {
		if (props.factor && props.factor.parent) {
			return props.factor.parent;
		} else {
			return {
				id: -1,
				title: props.project.name
			}
		}
	};

	setPermissions = (props) => {
		if (props.project && props.isAdministrator(props.project)) {
			return {
				saveEnabled: true,
				addLinkEnabled: true,
				editLinkEnabled: true,
				removeLinkEnabled: true,
				addMessageEnabled: true,
				editMessageEnabled: true,
				deleteMessageEnabled: true,
				addAttachmentEnabled: true,
				editAttachmentEnabled: true,
				deleteAttachmentEnabled: true,
			}
		} else if (props.project && props.isAnalyst(props.project)) {
			return {
				saveEnabled: true,
				addLinkEnabled: true,
				editLinkEnabled: true,
				removeLinkEnabled: true,
				addMessageEnabled: true,
				editMessageEnabled: false,
				deleteMessageEnabled: false,
				addAttachmentEnabled: true,
				editAttachmentEnabled: true,
				deleteAttachmentEnabled: true,
			}
		} else if (props.project && props.isManager(props.project)) {
			return {
				saveEnabled: false,
				addLinkEnabled: true,
				editLinkEnabled: true,
				removeLinkEnabled: true,
				addMessageEnabled: true,
				editMessageEnabled: false,
				deleteMessageEnabled: false,
				addAttachmentEnabled: true,
				editAttachmentEnabled: true,
				deleteAttachmentEnabled: true,
			}
		} else {
			return {
				saveEnabled: false,
				addLinkEnabled: false,
				editLinkEnabled: false,
				removeLinkEnabled: false,
				addMessageEnabled: true,
				editMessageEnabled: false,
				deleteMessageEnabled: false,
				addAttachmentEnabled: false,
				editAttachmentEnabled: false,
				deleteAttachmentEnabled: false,
			}
		}
	};

	render() {

		let tabs = null;

		if (this.props.factor) {
			tabs = this.renderTabs();
		}

		const factorToTreeNodeMapper = (element) => {
			return (!this.props.factor || element.id !== this.props.factor.id) ? (
				<TreeNode key={element.id}
				          title={element.children.length + " | " + element.title}
				          value={element.id}
				          isLeaf={!element.children || element.children
				                                              .filter((element) => {
					                                              return !this.props.factor || element.id !== this.props.factor.id;
				                                              }).length === 0
				          }
				          disabled={false}
				>
					{
						element.children
						       .filter((element) => {
							       return !this.props.factor || element.id !== this.props.factor.id;
						       })
						       .map(factorToTreeNodeMapper)
					}
				</TreeNode>
			) : null;
		};

		let saveButtonClass = "btn action-button pull-right";

		if (this.state.operationStatus === "IN_PROGRESS") {
			saveButtonClass = saveButtonClass + " pending";
		} else {
			if (this.state.operationStatus && this.state.operationStatus !== "NOT_PENDING") {
				saveButtonClass = this.state.operationStatus === "SUCCESS" ? saveButtonClass + " btn-success" : saveButtonClass + " btn-danger"
			}
		}

		let changes_indicator;

		if (this.props.factor && this.props.factor.id && (this.state.dirty || this['change_detector'].initialization_counter > 0)) {
			changes_indicator = this.state.dirty ? <div className="dirty-indicator">Changes unsaved</div> : <div className="dirty-indicator success"><Glyphicon glyph={"ok"}/>Changes saved</div>;
		}

		return (
			<Modal.Dialog bsSize="large" className="factor-dialog">
				<Modal.Header>
					<Modal.Title>{this.state.name}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form horizontal={true} className="h-100 dialog-form">
						<FormGroup>
							<Col componentClass={ControlLabel} md={1}>
								Parent:
							</Col>
							<Col md={7}>
								{
									<TreeSelect style={{width: 300}}
									            transitionName="rc-tree-select-dropdown-slide-up"
									            choiceTransitionName="rc-tree-select-selection__choice-zoom"
									            dropdownStyle={{
										            maxHeight: 200,
										            overflow: 'auto'
									            }}
									            placeholder={"Select parent"}
									            searchPlaceholder="please search"
									            showSearch={true}
									            allowClear={true}
									            treeLine={true}
									            searchValue={this.state.searchValue}
									            value={this.state.parent ? this.state.parent.id : null}
									            treeNodeFilterProp="title"
									            filterTreeNode={false}
									            treeDefaultExpandedKeys={['-1']}
									            onSearch={(value) => {
										            this.setState({searchValue: value});
									            }}
									            open={this.state.tsOpen}
									            onChange={(value, ...args) => {
										            this.setState({
											                          parent: this.factors
											                                      .filter((element) => {
												                                      return element.id === value;
											                                      })[0]
										                          });
									            }}
									            onDropdownVisibleChange={(v, info) => {
										            this.setState({
											                          tsOpen: v,
										                          });
										            return true;
									            }}
									>
										{
											this.factors && factorToTreeNodeMapper(this.factors[0])
										}
									</TreeSelect>
								}
							</Col>
						</FormGroup>
						<FormGroup>
							<Col componentClass={ControlLabel} md={1}>
								Name:
							</Col>
							<Col md={10}>
								<input className="form-control"
								       type="text"
								       onChange={this.handleNameChange}
								       value={this.state.name}/>
							</Col>
						</FormGroup>
						<FormGroup>
							<Col componentClass={ControlLabel} md={1}>
								Flag:
							</Col>
							<Col md={1}>
								<DropdownList data={this.props.flags ? this.props.flags : []}
								              onChange={this.handleFlagChange}
								              value={this.state.flag}
								              itemComponent={this.flagItem}
								              valueComponent={this.flagItem}
								/>
							</Col>
						</FormGroup>
						<FormGroup>
							<Col componentClass={ControlLabel} md={1}>
								Description:
							</Col>
							<Col md={10}>
								<Summernote value={this.state.note}
								            height={150}
								            onChange={this.handleDescriptionChange}
								/>
							</Col>
						</FormGroup>
						<FormGroup className="tabs-row">
							{tabs}
						</FormGroup>
						<FormGroup className="form-buttons-bar">
							<Col md={12}>
								<Button bsClass="btn action-button pull-right"
								        onClick={this.cancelButtonHandler}>
									Close
								</Button>
								{
									this.state.saveEnabled &&
									<Button bsClass={saveButtonClass}
									        onClick={this.state.operationStatus !== "IN_PROGRESS" ? this.saveButtonHandler : null}>
										{this.props.factor && this.props.factor.id ? "Save" : "Create"}
									</Button>
								}
								{changes_indicator}
							</Col>
						</FormGroup>
						{
							this.state.showModal && (
								<TreeSelectionForm show={this.state.showModal}
								                   onHide={() => this.setState({showModal: false})}
								                   items={this.factors}
								                   default={this.state.parent}
								                   field='title'
								                   onSave={this.acceptModal}
								                   onCancel={this.cancelModal}/>
							)
						}
					</Form>
				</Modal.Body>
			</Modal.Dialog>
		);
	}

	renderTabs = () => {
		let self = this;
		return (
			<Tabs activeKey={this.state.activeTab} onSelect={this.tabSelectionHandler} id="factor-edit-tabs"
			      className="h-100">
				<Tab eventKey={1} title="Links/Sources">
					<LinksTab items={self.props.factor ? self.props.factor.links : []}
					          selectedItem={self.props.selectedLink}
					          hasAddButton={self.state.addLinkEnabled}
					          hasEditButton={self.state.editLinkEnabled}
					          hasDeleteButton={self.state.removeLinkEnabled}
					          onItemClick={this.selectLinkHandler}
					          onAdd={
						          (link) => {
							          this.setState({
								                        showLinkModal: true
							                        });
						          }
					          }
					          onEdit={
						          (link) => {
							          if (!self.props.selectedLink) {
								          alert("You must select item from table");
							          } else {
								          this.props.editLink(this.props.project, this.props.factor,
								                              this.props.selectedLink);
							          }
						          }
					          }
					          onDelete={
						          (link) => {
							          if (!self.props.selectedLink) {
								          alert("You must select item from table");
							          } else {
								          if (confirm(`Are you sure you want to delete link: ${this.props.selectedLink.name}?`)) {
									          this.props.deleteLink(this.props.project, this.props.factor,
									                                this.props.selectedLink);
								          }
							          }
						          }
					          }
					/>
					{
						this.state.showLinkModal && (
							<LinkForm show={this.state.showLinkModal}
							          onHide={this.cancelLinkHandler}
							          link={this.props.link}
							          onSave={this.saveLinkHandler}
							/>
						)
					}
				</Tab>
				<Tab eventKey={2} title="Discussion">
					<CommentsTab comments={this.props.factor.comments}
					             isCommentEditable={(comment) => {
						             return this.state.editMessageEnabled || self.props.isEntityOwner(comment,
						                                                                              'FactorComment');
					             }}
					             isCommentRemovable={(comment) => {
						             return self.state.deleteMessageEnabled || self.props.isEntityOwner(comment,
						                                                                                'FactorComment');
					             }}
					             onAddComment={(comment, cb) => {
						             comment.factor = {
							             id: this.props.factor.id,
							             retrospectionId: this.props.factor.retrospectionId
						             };
						             factorCommentService.createComment(this.props.project.id, this.props.project.eventsMap.id,
						                                                this.props.factor.id, comment)
						                                 .then(promise => {
							                                 if (cb) {
								                                 cb(promise.data);
							                                 }
						                                 });
					             }}
					             onEditComment={(comment, cb) => {
						             comment.factor = {
							             id: this.props.factor.id,
							             retrospectionId: this.props.factor.retrospectionId
						             };
						             factorCommentService.saveComment(this.props.project.id, this.props.project.eventsMap.id,
						                                              this.props.factor.id, comment)
						                                 .then(promise => {
							                                 if (cb) {
								                                 cb(promise.data);
							                                 }
						                                 });
					             }}
					             onDeleteComment={(commentId, cb) => {
						             if (confirm(`Are you sure you want to delete comment?`)) {
							             factorCommentService.deleteComment(this.props.project.id, this.props.project.eventsMap.id,
							                                                this.props.factor.id, commentId)
							                                 .then(promise => {
								                                 if (cb) {
									                                 cb(promise.data);
								                                 }
							                                 });
						             }
					             }}
					/>
				</Tab>
				<Tab eventKey={3} title="Attachments">
					<AttachmentsTab items={this.props.factor.attachments}
					                selectedItem={self.props.selectedAttachment}
					                hasAddButton={self.state.addAttachmentEnabled}
					                hasEditButton={self.state.editAttachmentEnabled}
					                hasDeleteButton={self.state.deleteAttachmentEnabled}
					                onItemClick={this.selectAttachmentHandler}
					                onAdd={
						                () => {
							                self.setState({
								                              showAttachmentModal: true
							                              });
						                }
					                }
					                onEdit={
						                (attachment) => {
							                if (!self.props.selectedAttachment) {
								                alert("You must select item from table");
							                } else {
								                this.props.editAttachment(this.props.project, this.props.factor,
								                                          this.props.selectedAttachment);
							                }
						                }
					                }
					                onDelete={
						                (attachment) => {
							                if (!self.props.selectedAttachment) {
								                alert("You must select item from table");
							                } else {
								                if (confirm(`Are you sure you want to delete attachment: ${this.props.selectedAttachment.name}?`)) {
									                this.props.deleteAttachment(this.props.project, this.props.factor,
									                                            this.props.selectedAttachment);
								                }
							                }
						                }
					                }
					/>
					{
						this.state.showAttachmentModal && (
							<AttachmentForm show={this.state.showAttachmentModal}
							                onHide={this.cancelAttachmentHandler}
							                attachment={this.props.attachment}
							                onSave={this.saveAttachmentHandler}
							/>
						)
					}
				</Tab>
				<Tab eventKey={4} title="Journal">
					<JournalTab items={this.props.journalEntries}
					            selectedItem={this.props.selectedJournalEntry ? this.props.selectedJournalEntry : null}
					            onSelectionChange={this.journalSelectionChangeHandler}
					/>
				</Tab>
			</Tabs>
		)
	};

	journalSelectionChangeHandler = (item) => {
		this.props.selectJournalEntry(item);
	};

	tabSelectionHandler = (tabIndex) => {
		this.setState({activeTab: tabIndex});
	};

	selectLinkHandler = (link) => {
		this.props.selectLink(link);
	};

	saveLinkHandler = (link) => {
		this.setState({
			              showLinkModal: false
		              })
		this.props.saveLink(this.props.project, this.props.factor, link);
	};

	cancelLinkHandler = () => {
		this.setState({
			              showLinkModal: false
		              });
		this.props.clearLink();
	};

	selectAttachmentHandler = (attachment) => {
		this.props.selectAttachment(attachment);
	};

	saveAttachmentHandler = (attachment) => {
		this.setState({
			              showAttachmentModal: false
		              })
		this.props.saveAttachment(this.props.project, this.props.factor, attachment);
	};

	cancelAttachmentHandler = () => {
		this.setState({
			              showAttachmentModal: false
		              });
		this.props.clearAttachment();
	};

	handleNameChange = (event) => {
		this.setState({
			              name: event.target.value
		              });
	};

	handleFlagChange = (flag) => {
		this.setState({
			              flag: flag
		              })
	};

	handleDescriptionChange = (content) => {
		this.setState({
			              note: content
		              });
	};

	acceptModal = (parent) => {
		this.setState({
			              parent: parent,
			              showModal: false
		              });
	};

	cancelModal = () => {
		this.setState({
			              showModal: false
		              });
	};

	selectParentHandler = () => {
		this.setState({
			              showModal: true
		              });
	};

	clearParentHandler = () => {
		this.setState({parent: null});
	};

	saveButtonHandler = () => {

		const factor = this.props.factor != null ? this.props.factor : {};

		const parents = Utils.getFlatFactors(this.props.factors)
		                     .filter((element) => {
			                     return !this.props.factor || element.id !== this.props.factor.id;
		                     })
		                     .filter((element) => {
			                     return element.id == this.state.parent.id;
		                     });

		factor.parent = this.state.parent ? (this.state.parent.id === -1 ? null : {id: this.state.parent.id}) : null;
		factor.title = this.state.name;
		factor.note = this.state.note;
		factor.flag = this.state.flag;

		this.props.saveFactor(this.props.project, factor);
	};

	cancelButtonHandler = () => {
		this.props.history.push(`/project/${this.props.project.id}/factors-map`);
	};
}

export default FactorDialog;