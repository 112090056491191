import dateFormatter from 'dateformat';
import {Modal} from 'react-bootstrap';
import $ from 'jquery';
import {BASE_URL} from "../constants";
import {TapeItemType} from "./event-bus";
import {CLIENT_URI_CONSTANTS as URI} from "./uri-constants";

const CLIENT_PATH = BASE_URL;

const DT_PREFIX = "UTC:";

class Utils {

	static getFlatFactors(factors) {
		const factorsAll = [];

		factors.forEach(factor => {
			factorsAll.push(factor);
			collectFactors(factor, factorsAll);
		});

		return factorsAll;
	}

	static b64toBlob(b64Data, contentType, sliceSize) {

		contentType = contentType || '';

		sliceSize = sliceSize || 512;

		let byteCharacters = atob(b64Data);

		let byteArrays = [];

		for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			var slice = byteCharacters.slice(offset, offset + sliceSize);

			var byteNumbers = new Array(slice.length);
			for (var i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			var byteArray = new Uint8Array(byteNumbers);

			byteArrays.push(byteArray);
		}

		return new Blob(byteArrays, {type: contentType});
	};

	static saveByteArray(data, name) {

		const a = document.createElement("a");

		document.body.appendChild(a);

		a.style = "display: none";

		const blob = new Blob(data, {type: "octet/stream"});

		const url = window.URL.createObjectURL(blob);

		a.href = url;
		a.download = name;
		a.click();

		window.URL.revokeObjectURL(url);
		a.remove();
	};

	static bypassTree(root, getChildren, preOrderOperation, postOrderOperation) {

		if (!root) {
			return;
		}

		if (!getChildren) {
			return;
		}

		const parentStack = [root];

		const childIndexStack = [];

		let nodeIndex = 0;

		let interrupt = false;

		if (preOrderOperation) {
			interrupt = !preOrderOperation(parentStack[parentStack.length - 1], {
				parents: parentStack
			});
		}

		while (parentStack.length !== 0 && !interrupt) {

			const children = getChildren(parentStack[parentStack.length - 1]);

			if (children != null && children.length !== 0) {

				let index = null;

				if (childIndexStack.length !== 0 && childIndexStack.length === parentStack.length) {

					index = childIndexStack.pop();

					childIndexStack.push(++index);
				} else {

					index = 0;

					childIndexStack.push(index);
				}

				if (index >= children.length) {

					let element = parentStack.pop();

					childIndexStack.pop();

					if (postOrderOperation) {
						interrupt = !postOrderOperation(element, {
							parents: parentStack
						});
					}

					continue;
				}

				parentStack.push(children[childIndexStack[childIndexStack.length - 1]]);

				nodeIndex++;

				if (preOrderOperation) {
					interrupt = !preOrderOperation(parentStack[parentStack.length - 1], {
						parents: parentStack
					});
				}
			} else {

				let element = parentStack.pop();

				if (postOrderOperation) {
					interrupt = !postOrderOperation(element, {
						parents: parentStack
					});
				}
			}
		}
	};

	/**
	 * Аргументы:

	 name
	 название cookie

	 value
	 значение cookie (строка)

	 options
	 Объект с дополнительными свойствами для установки cookie:

	 expires
	 Время истечения cookie. Интерпретируется по-разному, в зависимости от типа:

	 Число – количество секунд до истечения. Например, expires: 3600 – кука на час.
	 Объект типа Date – дата истечения.
	 Если expires в прошлом, то cookie будет удалено.
	 Если expires отсутствует или 0, то cookie будет установлено как сессионное и исчезнет при закрытии браузера.

	 path
	 Путь для cookie.

	 domain
	 Домен для cookie.

	 secure
	 Если true, то пересылать cookie только по защищенному соединению.
	 */
	static setCookie(name, value, options) {
		options = options || {};

		let expires = options.expires;

		if (typeof expires == "number" && expires) {
			const d = new Date();
			d.setTime(d.getTime() + expires * 1000);
			expires = options.expires = d;
		}
		if (expires && expires.toUTCString) {
			options.expires = expires.toUTCString();
		}

		value = encodeURIComponent(value);

		let updatedCookie = name + "=" + value;

		for (let propName in options) {
			updatedCookie += "; " + propName;
			const propValue = options[propName];
			if (propValue !== true) {
				updatedCookie += "=" + propValue;
			}
		}

		document.cookie = updatedCookie;
	}

	static getCookie(name) {
		const matches = document.cookie.match(new RegExp(
			"(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
		));
		return matches ? decodeURIComponent(matches[1]) : undefined;
	}

	static deleteCookie(name) {
		this.setCookie(name, "", {
			expires: -1
		})
	}

	/* Разбирает строку и приводит к указанному формату в виде строки */
	static convertDatetime(str, format) {
		if (!str) return "";
		const dateFormat = (format ? format : "d mmmm yyyy  HH:MM:ss");
		return dateFormatter(new Date(str), dateFormat);
	}

	static singleDoubleClick(element, item, singleClickCallback, doubleClickCallback, ctrlKey, timeout) {
		if (element.getAttribute("data-dblclick") == null) {
			element.setAttribute("data-dblclick", 1);
			setTimeout(function () {
				if (element.getAttribute("data-dblclick") == 1) {
					singleClickCallback(element, item, ctrlKey);
				}
				element.removeAttribute("data-dblclick");
			}, timeout || 300);
		} else {
			element.removeAttribute("data-dblclick");
			doubleClickCallback(element, item, ctrlKey);
		}
	}

	static getModalProps(props) {
		const propTypes = [...Object.keys(Modal.propTypes)];
		const modalProps = {};

		for (const key in props) {
			if (propTypes.includes(key)) {
				modalProps[key] = props[key];
			}
		}

		return modalProps;
	}

	static getResourceUri(uri) {
		return `${CLIENT_PATH}/img${uri}`;
	}

	static collectTriggerProps(props) {
		return props;
	}

	static getPlainTextFromHtml(html) {
		try {
			return $(html)
				.text();
		} catch (e) {
			return html;
		}
	}

	static rgbToHex(r, g, b) {
		return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
	}

	static hexToRgb(hex) {
		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result ? {
			r: parseInt(result[1], 16),
			g: parseInt(result[2], 16),
			b: parseInt(result[3], 16)
		} : null;
	}

	static userName(user) {
		if (user) {

			const humanName = (user.name ? user.name : "") + (user.patronymic ? " " + user.patronymic : "") + (user.secondname ? " " + user.secondname : "");

			if (humanName === "") {
				return user.user_name;
			} else {
				return humanName;
			}
		} else {
			return "";
		}
	}

	static sortDescendingByProperty(property) {
		return (o1, o2) => {
			return o1[property] > o2[property] ? 1 : (o1[property] < o2[property] ? -1 : 0)
		}
	}

	static sortAscendingByProperty(property) {
		return (o1, o2) => {
			return o1[property] < o2[property] ? 1 : (o1[property] > o2[property] ? -1 : 0)
		}
	}

	static sortDescendingById() {
		return Utils.sortDescendingByProperty('id')
	}

	static sortAscendingById() {
		return Utils.sortAscendingByProperty('id')
	}

	static hrefFromPath = (type, path) => {
		switch (type) {
			case 'FACTOR':
				return Array.isArray(path) && path.length === 2 ? `${BASE_URL}/project/${path[0]}/factors-map/?id${path[1]}#edit-factor` : "#";
			case 'SCENARIO':
				return Array.isArray(path) && path.length === 2 ? `${BASE_URL}/project/${path[0]}/scenarios/${path[1]}` : "#";
			case 'TREND':
				return Array.isArray(path) && path.length === 2 ? `${BASE_URL}/project/${path[0]}/trends/${path[1]}` : "#";
			case 'PROJECT':
				return Array.isArray(path) && path.length === 1 ? `${BASE_URL}/project/${path[0]}/info` : "#";
			case 'EVENT_COMMENT':
				return Array.isArray(path) && path.length === 2 ? `${BASE_URL}/project/${path[0]}/events/${path[1]}` : "#";
			case 'PROJECT_COMMENT':
				return Array.isArray(path) && path.length === 2 ? `${BASE_URL}/project/${path[0]}/info` : "#";
			case 'FACTOR_COMMENT':
				return Array.isArray(path) && path.length === 2 ? `${BASE_URL}/project/${path[0]}/factors-map/?id${path[1]}#edit-factor` : "#";
			case 'SCENARIO_COMMENT':
				return Array.isArray(path) && path.length === 2 ? `${BASE_URL}/project/${path[0]}scenarios/${path[1]}` : "#";
			case 'EVENT_LINK':
				return Array.isArray(path) && path.length === 2 ? `${BASE_URL}/project/${path[0]}/events/${path[1]}` : "#";
			case 'PROJECT_LINK':
				return Array.isArray(path) && path.length === 2 ? `${BASE_URL}/project/${path[0]}/info` : "#";
			case 'FACTOR_LINK':
				return Array.isArray(path) && path.length === 2 ? `${BASE_URL}/project/${path[0]}/factors-map/?id${path[1]}#edit-factor` : "#";
			case 'SCENARIO_LINK':
				return Array.isArray(path) && path.length === 2 ? `${BASE_URL}/project/${path[0]}/scenarios/${path[1]}` : "#";
			case 'EVENT':
				return Array.isArray(path) && path.length === 3 ? `${BASE_URL}/project/${path[0]}/event/${path[2]}` : "#";
			default:
				return "";
		}
	}

	static objectToQuerystring (obj) {
		return Object.keys(obj).reduce(function (str, key, i) {
			var delimiter, val;
			delimiter = (i === 0) ? '?' : '&';
			key = encodeURIComponent(key);
			val = encodeURIComponent(obj[key]);
			return [str, delimiter, key, '=', val].join('');
		}, '');
	}
}

function componentToHex(c) {
	const hex = c.toString(16);
	return hex.length === 1 ? "0" + hex : hex;
}

function collectFactors(parent, factors) {

	for (let i = 0; i < parent.children.length; i++) {
		factors.push(parent.children[i]);

		collectFactors(parent.children[i], factors);
	}
}


export default Utils;

