export const mixinFactory = function (config) {
	return {
		operationState: function(prevProps, prevState) {
			if (!this.props[config.operationPropName]) {
				if (this.state[config.operationStateName] !== "NOT_PENDING") {
					this.setState({
						              [config.operationStateName]: "NOT_PENDING"
					              })
				}
			} else if (!this.props[config.operationPropName].status) {
				if (this.state[config.operationStateName] !== "IN_PROGRESS") {
					this.setState({
						              operationStatus: "IN_PROGRESS"
					              })
				}
			} else if (this.props[config.operationPropName].status) {
				if (this.state[config.operationStateName] !== "SUCCESS" && this.state[config.operationStateName] !== "ERROR") {
					this.setState({
						              [config.operationStateName]: this.props[config.operationPropName].status
					              },
					              () => {
						              setTimeout(() => {
							              this.props.clearOperation();
						              }, 1000)
					              })
				}
			}
		}
	}
}