import {Button, Col, Glyphicon, Grid, Nav, Navbar, NavItem, Row, Table} from "react-bootstrap";
import React from "react";
import Utils from "../utils/utils";
import DictionaryItemDialog from "../components/dialogs/dictionary-item";
import DeleteConflictOrphansDialog from "../components/dialogs/delete-conflict-orphans";

class ProjectCategoriesView
	extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			...this.setPermissions(props)
		};
	}

	componentDidMount() {
		this.props.loadInitialData()
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.projectCategory && prevProps.projectCategory !== this.props.projectCategory) {
			this.setState({
				              showModal: true
			              })
		}
	}

	componentDidCatch(error, errorInfo) {
		this.setState({
			              error: true
		              })
	}

	render() {

		if (this.state.error) {
			return <div>Error occurred...</div>
		}

		return (
			<Grid fluid={true} className="h-100">
				<Row className="toolbar">
					<Col md={12}>
						<Navbar fluid={true} className="toolbar">
							<Nav>
								{
									<NavItem>
										<Button bsClass="btn bar-button bar-button-default" onClick={this.handleRefreshButtonClick}>
											<Glyphicon glyph="refresh"/>
										</Button>
									</NavItem>
								}
								{
									this.state.createEnabled &&
									<NavItem>
										<Button bsClass="btn bar-button" onClick={this.handleAddButtonClick}>
											<Glyphicon glyph="plus" className="c-glyph-icon"/>
											ADD
										</Button>
									</NavItem>
								}
								{
									this.state.editEnabled &&
									<NavItem>
										<Button bsClass="btn bar-button" onClick={this.handleEditButtonClick}>
											<Glyphicon glyph="edit" className="c-glyph-icon"/>
											EDIT
										</Button>
									</NavItem>
								}
								{
									this.state.deleteEnabled &&
									<NavItem>
										<Button bsClass="btn bar-button" onClick={this.handleDeleteButtonClick}>
											<Glyphicon glyph="remove" className="c-glyph-icon"/>
											DELETE
										</Button>
									</NavItem>
								}
							</Nav>
						</Navbar>
					</Col>
				</Row>
				<Row className="content">
					<Col md={12} className="h-100 table-wrapper">
						<Table>
							<thead>
							<tr>
								<th style={{
									width: '30%',
									maxWidth: '50%'
								}}>Title
								</th>
								<th>Description</th>
							</tr>
							</thead>
							<tbody>
							{
								this.props.projectCategories && this.props.projectCategories
								                                    .sort(Utils.sortDescendingById())
								                                    .map(item => {
									                                    return (
										                                    <tr key={item.id}
										                                        onClick={
											                                        () => this.handleOnItemClick(item)
										                                        }
										                                        className={this.props.selectedProjectCategory && this.props.selectedProjectCategory.id === item.id ? 'selected' : ''}>
											                                    <td>{item.title}</td>
											                                    <td>{item.description}</td>
										                                    </tr>
									                                    );
								                                    })
							}
							</tbody>
						</Table>
					</Col>
				</Row>
				<DictionaryItemDialog show={this.state.showModal}
				                      title={"Project Category"}
				                      onHide={
					                      () => {
						                      this.setState({
							                                    showModal: false
						                                    });
					                      }
				                      }
				                      item={this.props.projectCategory}
				                      onCreate={this.handleItemCreation}
				                      onSave={this.handleItemSaving}
				/>
				{
					this.props.orphans && this.props.orphans.length !== 0 &&
					<DeleteConflictOrphansDialog show={this.props.orphans && this.props.orphans.length !== 0}
					                             title={"Orphans"}
					                             items={this.props.orphans}
					                             selectedItem={this.props.selectedOrphan}
					                             itemInfoProvider={
						                             (type, path) => {
							                             return this.props.loadOrphanItemInfo(type, path);
						                             }
					                             }
					                             onItemClicked={(item) => {
						                             this.props.selectOrphan(item);
					                             }
					                             }
					                             onHide={
						                             () => {
							                             this.props.clearOrphans();
						                             }
					                             }
					                             onProceed={this.handleDeleteButtonClick}/>
				}
			</Grid>
		)
	}

	handleOnItemClick = (item) => {
		this.props.selectItem(item);
	};

	handleAddButtonClick = () => {
		this.props.add();
	};

	handleEditButtonClick = () => {
		if (!this.props.selectedProjectCategory) {
			alert("You must select item from table");
		} else {
			this.props.edit(this.props.selectedProjectCategory);
		}
	};

	handleDeleteButtonClick = () => {
		if (!this.props.selectedProjectCategory) {
			alert("You must select item from table");
		} else {
			if (confirm(`Are you sure you want to delete project category: ${this.props.selectedProjectCategory.title}?`)) {
				this.props.delete(this.props.selectedProjectCategory);
			}
		}
	};

	handleItemCreation = (item) => {

		this.props.createItem(item);

		this.setState({
			              showModal: false
		              })
	};

	handleItemSaving = (item) => {

		this.props.saveItem(item);

		this.setState({
			              showModal: false
		              })
	};

	handleRefreshButtonClick = () => {
		this.props.loadInitialData();
	}

	setPermissions = (props) => {
		if (props.isSystemAdministrator()) {
			return {
				createEnabled: true,
				editEnabled: true,
				deleteEnabled: true
			}
		} else if (props.isAdministrator(props.selectedProject)) {
			return {
				createEnabled: true,
				editEnabled: true,
				deleteEnabled: true
			}
		} else if (props.isAnalyst(props.selectedProject)) {
			return {
				createEnabled: true,
				editEnabled: true,
				deleteEnabled: true
			}
		} else if (props.isManager(props.selectedProject)) {
			return {
				createEnabled: true,
				editEnabled: true,
				deleteEnabled: true
			}
		} else {
			return {
				createEnabled: true,
				editEnabled: true,
				deleteEnabled: true
			}
		}
	};
}

export default ProjectCategoriesView