import React from 'react';
import {Col, Grid, Row} from 'react-bootstrap';
import {DateTimePicker, DropdownList, SelectList} from 'react-widgets';

import BaseForm from 'components/base/base-form';
import {PredicateRelation as PR, PredicateValueType as PVT} from './filter-models';
import DateUtils from "../../utils/date";


class Relation {
    constructor(value, text) {
        this.text = text;
        this.value = value;
    }
}

const STRING_RELATIONS = [
    new Relation(PR.LIKE, "like")
];

const NUMBER_RELATIONS = [
    new Relation(PR.GREATER, ">"),
    new Relation(PR.GREATER_OR_EQUAL, ">="),
    new Relation(PR.EQUAL, "="),
    new Relation(PR.LESS, "<"),
    new Relation(PR.LESS_OR_EQUAL, "<=")
];

const DATE_RELATIONS = [
    new Relation(PR.GREATER, ">"),
    new Relation(PR.GREATER_OR_EQUAL, ">="),
    new Relation(PR.EQUAL, "="),
    new Relation(PR.LESS, "<"),
    new Relation(PR.LESS_OR_EQUAL, "<=")
];

const OBJECT_RELATIONS = [
    new Relation(PR.EQUAL, "="),
    new Relation(PR.IN, "in")
];

const VALUE_TYPE = {
    TEXT: "text",
    DATE: "date",
    LIST: "list"
};


class FilterPredicate extends BaseForm {

    constructor(props) {
        super(props);

        this.predicate = props.predicate;

        const property = this.predicate.property ? this._convertProperty(this.predicate.property, props.properties) : props.properties[0];
        let {relations, valueType, relation} = this._propertyOnChange(property);

        this.state = {
            property: property,
            value: this.predicate.value ? this.predicate.value.value : null,
            values: this.predicate.values ? this.predicate.values.map(x => x.value) : [],
            children: this.predicate.children,
            relation: this.predicate.relation ? this._convertRelation(property, this.predicate.relation) : relation,

            properties: props.properties,
            relations: relations,
            valueType: valueType
        };

        this.getDataByType = props.getDataByType;

        this.propertyText = ({item}) => (
            <span>
                {item.display_name}
            </span>
        );

        this.relationText = ({item}) => (
            <span>
                {item ? item.text : ""}
            </span>
        );

        this.objectText = ({item}) => {
            let text = "";
            if (item) {
                switch (this.state.property.type) {
                    case PVT.FACTOR: {
                        text = item.title;
                        break;
                    }
                    case PVT.EVENT: {
                        text = item.title;
                        break;
                    }
                    case PVT.EVENT_CATEGORY:
                    case PVT.EVENT_TYPE:
                    case PVT.EVENT_SOURCE: {
                        text = item.title;
                        break;
                    }
                }
            }
            return (
                <span>
                    {text}
                </span>
            );
        }
    }

    componentWillUpdate(nextProps, nextState) {
        this.predicate.property = nextState.property.name;
        this.predicate.value = nextState.value ? {value: nextState.value, type: nextState.property.type} : null;
        this.predicate.values = nextState.values.map(value => {return {value: value, type: nextState.property.type}});
        this.predicate.children = nextState.children;
        this.predicate.relation = nextState.relation.value;
    }

    render() {
        const dateFormat = "DD.MM.YYYY";
        return (
            <Grid bsClass="grid removable-predicate">
                <Row>
                    <Col md={4}>
                        <DropdownList data={this.state.properties}
                                      value={this.state.property}
                                      onChange={this.propertyOnChangeHandler}
                                      itemComponent={this.propertyText}
                                      valueComponent={this.propertyText}
                        />
                    </Col>
                    <Col md={2}>
                        <DropdownList data={this.state.relations}
                                      value={this.state.relation}
                                      onChange={this.relationOnChangeHandler}
                                      itemComponent={this.relationText}
                                      valueComponent={this.relationText}
                        />
                    </Col>
                    <Col md={6}>
                        {
                            this.state.valueType === VALUE_TYPE.TEXT && (
                                <input className="form-control"
                                       type="text"
                                       onChange={this.setEventValue.bind(this, 'value')}
                                       value={this.state.value ? this.state.value : ""}
                                />
                            )
                        }
                        {
                            this.state.valueType === VALUE_TYPE.DATE && (
                                <DateTimePicker onChange={this.datePickerOnChangeHandler}
                                                value={new Date(this.state.value)}
                                                time={false}
                                                format={dateFormat}
                                />
                            )
                        }
                        {
                            this.state.valueType === VALUE_TYPE.LIST && (
                                <SelectList data={this.getDataByType(this.state.property.type)}
                                            itemComponent={this.objectText}
                                            multiple={this.state.relation.value === PR.IN}
                                            value={this.state.relation.value === PR.IN ?
                                                this.state.values.map(x => {
                                                    return this._getEqualObject(
                                                        this.state.property.type,
                                                        x,
                                                        this.getDataByType(this.state.property.type))
                                                })
                                                :
                                                this._getEqualObject(
                                                    this.state.property.type,
                                                    this.state.value,
                                                    this.getDataByType(this.state.property.type))}
                                            onChange={this.objectsListOnChangeHandler}
                                />
                            )
                        }
                    </Col>
                </Row>
            </Grid>
        )
    }

    _propertyOnChange(property) {
        if (property) {
            let relations = [];
            let valueType;
            switch (property.type) {
                case PVT.STRING: {
                    relations = STRING_RELATIONS;
                    valueType = VALUE_TYPE.TEXT;
                    break;
                }
                case PVT.INTEGER:
                case PVT.FLOAT:
                case PVT.DOUBLE: {
                    relations = NUMBER_RELATIONS;
                    valueType = VALUE_TYPE.TEXT;
                    break;
                }
                case PVT.DATE: {
                    relations = DATE_RELATIONS;
                    valueType = VALUE_TYPE.DATE;
                    break;
                }
                default: {
                    relations = OBJECT_RELATIONS;
                    valueType = VALUE_TYPE.LIST;
                    break;
                }
            }
            return {
                property: property,
                relations: relations,
                valueType: valueType,
                relation: relations[0],
                value: null,
                values: []

            };
        } else {
            return {
                property: null,
                relations: [],
                relation: null,
                value: null,
                values: []
            };
        }
    }

    _convertProperty(name, properties) {
        for (let i = 0; i < properties.length; i++) {
            if (properties[i].name === name) {
                return properties[i];
            }
        }
        return null;  // this.state.properties[0]
    }

    _convertRelation(property, relation) {
        let relations = [];
        switch (property.type) {
            case PVT.STRING: {
                relations = STRING_RELATIONS;
                break;
            }
            case PVT.INTEGER:
            case PVT.FLOAT:
            case PVT.DOUBLE: {
                relations = NUMBER_RELATIONS;
                break;
            }
            case PVT.DATE: {
                relations = DATE_RELATIONS;
                break;
            }
            default: {
                relations = OBJECT_RELATIONS;
                break;
            }
        }
        for (let i = 0; i < relations.length; i++) {
            if (relations[i].value === relation) {
                return relations[i];
            }
        }
        return null;
    }

    _getEqualObject(type, original, objects) {
        if (original) {
            let key;
            switch (type) {
                case PVT.FACTOR: {
                    key = "id";
                    break;
                }
                case PVT.EVENT: {
                    key = "id";
                    break;
                }
                case PVT.EVENT_CATEGORY:
                case PVT.EVENT_TYPE:
                case PVT.EVENT_SOURCE: {
                    key = "id";
                    break;
                }
                default:
                    key = "id";
                    break;
            }
            for (let i = 0; i < objects.length; i++) {
                if (original[key] === objects[i][key]) {
                    return objects[i];
                }
            }
        }
        return null;
    }

    propertyOnChangeHandler(property) {
        this.setState(this._propertyOnChange(property));
    }

    relationOnChangeHandler(relation) {
        if (this.state.relation !== relation) {
            this.setState({
                relation: relation,
                value: null,
                values: []
            });
        }
    }

    objectsListOnChangeHandler(value) {
        if (this.state.relation.value === PR.IN) {
            this.setState({
                values: value,
                value: null
            });
        } else {
            this.setState({
                value: value,
                values: []
            });
        }
    }

    datePickerOnChangeHandler(value) {
        if (value) {
            this.setState({
                value: DateUtils.convertToISOUTCDatetime(value)
            });
        } else {
            this.setState({
                value: null
            });
        }
    }
}

export { FilterPredicate };