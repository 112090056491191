import {authorities} from "../mixins/authorities";
import * as trendsActions from "../actions/trends";
import {browserHistory} from "../app";
import {CLIENT_URI_CONSTANTS as URI} from "../utils/uri-constants";
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import TrendsView from "../views/trends";

const mapStateToProps = (state, ownProps) => {
	return {
		project: state.projectReducer.project,
		trends: state.trendsReducer.trends,
		selectedTrend: state.trendsReducer.selectedTrend,
		...authorities(state.authenticationReducer.authentication)
	}
};

const mapDispatchToProps = (dispatch, state) => {
	return {
		loadTrends: (project) => {
			dispatch(trendsActions.fetchTrends(project.id, project.eventsMap.id))
		},
		selectTrend: (trend) => {
			dispatch(trendsActions.selectTrend(trend));
		},
		deleteTrend: (project, trend) => {
			dispatch(trendsActions.deleteTrend(project.id, project.eventsMap.id, trend.id));
		},
		addTrend: (project) => {
			dispatch(trendsActions.receiveTrend(null));
			browserHistory.push(`/${URI.PROJECT}/${project.id}/${URI.TRENDS}/0`);
		},
		editTrend: (project, trend) => {
			dispatch(trendsActions.receiveTrend(null));
			browserHistory.push(`/${URI.PROJECT}/${project.id}/${URI.TRENDS}/${trend.id}`);
		}
	}
};

const TrendsContainer = connect(mapStateToProps, mapDispatchToProps)(TrendsView);

export default withRouter(TrendsContainer);