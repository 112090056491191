import {Button, Col, ControlLabel, Form, FormGroup, Glyphicon, Grid, Nav, Navbar, NavItem, Overlay, OverlayTrigger, Popover, Row, Tooltip} from "react-bootstrap";
import ReactScrollableList from "react-scrollable-list";
import Utils from "../../../utils/utils";
import NotificationItem from "../../common/notification-item";
import React from "react";
import {Combobox} from "react-widgets";
import Period from "../../common/news-period";
import NotificationsFeed from "../../common/notifications-feed";
import SelectBox from "../select-box/select-box";

class ProjectNewsOverlay
	extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			period: Period.All
		}
	}

	render() {
		return (
			<Overlay show={this.props.show}
			         container={this.props.containerRef}
			         placement="right"
			>
				<Grid fluid={true}
				      className="overlay content h-100"
				>
					<Row className="toolbar">
						<Col md={12}>
							<Navbar className="toolbar" fluid={true}>
								<Nav>
									{
										<NavItem>
											<OverlayTrigger placement="bottom"
											                overlay={<Tooltip id="tooltip">Refresh</Tooltip>}>
												<Button bsClass="btn bar-button"
												        onClick={this.handleRefreshButtonClick}>
													<Glyphicon glyph="refresh"/>
												</Button>
											</OverlayTrigger>
										</NavItem>
									}
									{
										<NavItem>
											<NotificationsFilter data={this.props.notificationsFilter}
											                     onFilter={this.props.onFilter} />
										</NavItem>
									}
								</Nav>
							</Navbar>
						</Col>
					</Row>
					<Row className="content">
						<Col md={12} className="h-100">
							<NotificationsFeed notifications={this.props.notifications ? this.props.notifications : []}
							                   projects={[this.props.project]}
							                   itemsProvider={this.props.itemsProvider}/>
						</Col>
					</Row>
				</Grid>
			</Overlay>
		)
	}

	handleRefreshButtonClick = (e) => {
		this.props.itemsLoader()
	};
}

class NotificationsFilter
	extends React.Component {

	constructor(props) {

		super(props);

		this.periods = [
			{
				code: 0,
				text: "Today"
			},
			{
				code: 1,
				text: "Three days"
			},
			{
				code: 2,
				text: "One week"
			},
			{
				code: 3,
				text: "One month"
			},
			{
				code: 4,
				text: "All time"
			}
		]

		this.state = {
			period: props.period_code ? this.periods.find((item) => {
				return item.code === props.period_code;
			}) : this.periods[this.periods.length - 1]
		}
	}

	componentDidMount() {
		this.setState({
			              period: this.props.data && this.props.data.period_code != null ? this.periods.find((item) => {
				              return item.code === this.props.data.period_code;
			              }) : this.periods[this.periods.length - 1]
		              })
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps !== this.props) {
			this.setState({
				              period: this.props.data && this.props.data.period_code != null ? this.periods.find((item) => {
					              return item.code === this.props.data.period_code;
				              }) : this.periods[this.periods.length - 1]
			              })
		}
	}

	componentDidCatch(error, errorInfo) {
		this.setState({
			              error: true
		              })
	}

	render() {

		if (this.state.error) {
			return (
				<Button bsClass="btn bar-button btn-danger">
					<Glyphicon glyph="filter"/>
				</Button>
			)
		}

		const filterPopover = (
			<Popover className="news-filter-popover" id={"notificaitons-filter-popover"}>
				<Form horizontal={true}>
					<FormGroup>
						<Col md={3}>
							<ControlLabel>Period:</ControlLabel>
						</Col>
						<Col md={9}>
							<Combobox id="tape-filter"
							          onChange={(e) => {
								          this.setState({period: e})
							          }}
							          defaultValue={this.periods[this.periods.length - 1]}
							          value={this.state.period}
							          data={this.periods}
							          textField='text'
							          style={{display: 'inline-block'}}
							/>
						</Col>
					</FormGroup>
				</Form>
			</Popover>
		);

		return (
			<OverlayTrigger trigger="click"
			                rootClose
			                placement="bottom"
			                overlay={filterPopover}
			                onExited={(e) => {
				                this.props.onFilter({
					                                    period: this.state.period
				                                    })
			                }}>
				<OverlayTrigger placement="bottom"
				                overlay={
					                <Tooltip id="tooltip">Filters</Tooltip>
				                }>
					<Button bsClass="btn bar-button">
						<Glyphicon glyph="filter"/>
					</Button>
				</OverlayTrigger>
			</OverlayTrigger>
		)
	}
}

export default ProjectNewsOverlay