import {connect} from "react-redux";
import {withRouter} from "react-router";
import {authorities} from "../mixins/authorities";
import EventRelationsMapView from "../views/event-relations-map";

const mapStateToProps = (state, ownProps) => {
	return {
		project: state.projectReducer.project,
		...authorities(state.authenticationReducer.authentication)
	}
};

const mapDispatchToProps = (dispatch, state) => {
	return {}
};

const EventRelationsMapContainer = connect(mapStateToProps, mapDispatchToProps)(EventRelationsMapView);

export default withRouter(EventRelationsMapContainer)