import DateUtils from "../utils/date";
import {AUTH_URL, DATA_URL} from "../constants";

export const data = {
	scope: DATA_URL.replace('/', ''),
	projects: {
		url: (page_index, page_size) => {
			return `${DATA_URL}/projects/?page_index=${page_index}&page_size=${page_size}`;
		},
		method: 'get',
		build: (title, period_start_date, period_end_date) => {
			return {
				title: title ? title : undefined,
				period_start_date: period_start_date ? DateUtils.convertToISOUTCDatetime(period_start_date) : undefined,
				period_end_date: period_end_date ? DateUtils.convertToISOUTCDatetime(period_end_date) : undefined,
			};
		},
		retrospection: {
			url: DATA_URL + `/projects/retrospection/`,
			method: 'get',
			build: (project_id) => {
				return {
					project: project_id
				}
			},
			load: {
				url: () => DATA_URL + `/projects/retrospection/load`,
				method: 'post',
				build: (data) => {
					return data
				}
			},
		},
		filter: {
			url: `${DATA_URL}/projects/filters/`,
			method: 'get',
			create: {
				url: `${DATA_URL}/projects/filters/create`,
				method: 'post',
				build: (filter) => {
					return filter;
				}
			},
		},
		id: {
			url: (id) => {
				return DATA_URL + '/projects/' + id
			},
			method: 'get',
			event_maps: {
				url: (project_id) => DATA_URL + `/projects/${project_id}/future-maps/`,
				method: 'get',
				id: {
					url: (project_id, events_map_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}`,
					method: 'get',
					events: {
						url: (project_id, events_map_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/events/`,
						method: 'get',
						compact: {
							url: (project_id, events_map_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/events/compact`,
							method: 'post',
							build: (data) => {
								return data
							}
						},
						id: {
							url: (project_id, events_map_id, event_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/events/${event_id}`,
							method: 'get',
							attachments: {
								url: (project_id, events_map_id, event_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/events/${event_id}/attachments/`,
								method: 'get',
								id: {
									url: (project_id, events_map_id, event_id, attachment_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/events/${event_id}/attachments/${attachment_id}`,
									method: 'get'
								},
								create: {
									url: (project_id, events_map_id, event_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/events/${event_id}/attachments/create`,
									method: 'post',
									build: (data) => {
										return data;
									}
								},
								save: {
									url: (project_id, events_map_id, event_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/events/${event_id}/attachments/save`,
									method: 'post',
									build: (data) => {
										return data;
									}
								},
								delete: {
									url: (project_id, events_map_id, event_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/events/${event_id}/attachments/delete`,
									method: 'post',
									build: (data) => {
										return data;
									}
								}
							},
							images: {
								url: (project_id, events_map_id, event_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/events/${event_id}/images/`,
								method: 'get',
								id: {
									url: (project_id, events_map_id, event_id, attachment_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/events/${event_id}/images/${attachment_id}`,
									method: 'get'
								},
								create: {
									url: (project_id, events_map_id, event_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/events/${event_id}/images/create`,
									method: 'post',
									build: (data) => {
										return data;
									}
								},
								save: {
									url: (project_id, events_map_id, event_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/events/${event_id}/images/save`,
									method: 'post',
									build: (data) => {
										return data;
									}
								},
								delete: {
									url: (project_id, events_map_id, event_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/events/${event_id}/images/delete`,
									method: 'post',
									build: (data) => {
										return data;
									}
								}
							},
							comments: {
								url: (project_id, events_map_id, event_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/events/${event_id}/comments/`,
								method: 'get',
								id: {
									url: (project_id, events_map_id, event_id, comment_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/events/${event_id}/comments/${comment_id}`,
									method: 'get'
								},
								create: {
									url: (project_id, events_map_id, event_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/events/${event_id}/comments/create`,
									method: 'post',
									build: (data) => {
										return data;
									}
								},
								save: {
									url: (project_id, events_map_id, event_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/events/${event_id}/comments/save`,
									method: 'post',
									build: (data) => {
										return data;
									}
								},
								delete: {
									url: (project_id, events_map_id, event_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/events/${event_id}/comments/delete`,
									method: 'post',
									build: (data) => {
										return data;
									}
								}
							},
							links: {
								url: (project_id, events_map_id, event_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/events/${event_id}/links/`,
								method: 'get',
								id: {
									url: (project_id, events_map_id, event_id, link_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/events/${event_id}/links/${link_id}`,
									method: 'get'
								},
								create: {
									url: (project_id, events_map_id, event_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/events/${event_id}/links/create`,
									method: 'post',
									build: (data) => {
										return data;
									}
								},
								save: {
									url: (project_id, events_map_id, event_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/events/${event_id}/links/save`,
									method: 'post',
									build: (data) => {
										return data;
									}
								},
								delete: {
									url: (project_id, events_map_id, event_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/events/${event_id}/links/delete`,
									method: 'post',
									build: (data) => {
										return data;
									}
								}
							},
						},
						create: {
							url: (project_id, events_map_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/events/create`,
							method: 'post',
							build: (data) => {
								return data;
							}
						},
						save: {
							url: (project_id, events_map_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/events/save`,
							method: 'post',
							build: (data) => {
								return data;
							}
						},
						copy: {
							url: (project_id, events_map_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/events/copy`,
							method: 'post',
							build: (data) => {
								return data;
							}
						},
						delete: {
							url: (project_id, events_map_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/events/delete`,
							method: 'post',
							build: (eventId) => {
								return eventId;
							}
						},
						import: {
							url: (project_id, events_map_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/events/import`,
							method: 'post',
							build: (data) => {
								return data;
							}
						},
						export: {
							url: (project_id, events_map_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/events/export`,
							method: 'post',
							build: (data) => {
								return data;
							}
						}
					},
					factors: {
						url: (project_id, events_map_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/factors/`,
						method: 'get',
						compact: {
							url: (project_id, events_map_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/factors/compact`,
							method: 'post',
							build: (data) => {
								return data
							}
						},
						id: {
							url: (project_id, events_map_id, factor_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/factors/${factor_id}`,
							method: 'get',
							attachments: {
								url: (project_id, events_map_id, factor_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/factors/${factor_id}/attachments/`,
								method: 'get',
								id: {
									url: (project_id, events_map_id, factor_id, attachment_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/factors/${factor_id}/attachments/${attachment_id}`,
									method: 'get'
								},
								create: {
									url: (project_id, events_map_id, factor_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/factors/${factor_id}/attachments/create`,
									method: 'post',
									build: (data) => {
										return data;
									}
								},
								save: {
									url: (project_id, events_map_id, factor_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/factors/${factor_id}/attachments/save`,
									method: 'post',
									build: (data) => {
										return data;
									}
								},
								delete: {
									url: (project_id, events_map_id, factor_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/factors/${factor_id}/attachments/delete`,
									method: 'post',
									build: (data) => {
										return data;
									}
								}
							},
							images: {
								url: (project_id, events_map_id, factor_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/factors/${factor_id}/images/`,
								method: 'get',
								id: {
									url: (project_id, events_map_id, factor_id, attachment_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/factors/${factor_id}/images/${attachment_id}`,
									method: 'get'
								},
								create: {
									url: (project_id, events_map_id, factor_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/factors/${factor_id}/images/create`,
									method: 'post',
									build: (data) => {
										return data;
									}
								},
								save: {
									url: (project_id, events_map_id, factor_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/factors/${factor_id}/images/save`,
									method: 'post',
									build: (data) => {
										return data;
									}
								},
								delete: {
									url: (project_id, events_map_id, factor_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/factors/${factor_id}/images/delete`,
									method: 'post',
									build: (data) => {
										return data;
									}
								}
							},
							comments: {
								url: (project_id, events_map_id, factor_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/factors/${factor_id}/comments/`,
								method: 'get',
								id: {
									url: (project_id, events_map_id, factor_id, comment_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/factors/${factor_id}/comments/${comment_id}`,
									method: 'get'
								},
								create: {
									url: (project_id, events_map_id, factor_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/factors/${factor_id}/comments/create`,
									method: 'post',
									build: (data) => {
										return data;
									}
								},
								save: {
									url: (project_id, events_map_id, factor_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/factors/${factor_id}/comments/save`,
									method: 'post',
									build: (data) => {
										return data;
									}
								},
								delete: {
									url: (project_id, events_map_id, factor_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/factors/${factor_id}/comments/delete`,
									method: 'post',
									build: (data) => {
										return data;
									}
								}
							},
							links: {
								url: (project_id, events_map_id, factor_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/factors/${factor_id}/links/`,
								method: 'get',
								id: {
									url: (project_id, events_map_id, factor_id, link_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/factors/${factor_id}/links/${link_id}`,
									method: 'get'
								},
								create: {
									url: (project_id, events_map_id, factor_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/factors/${factor_id}/links/create`,
									method: 'post',
									build: (data) => {
										return data;
									}
								},
								save: {
									url: (project_id, events_map_id, factor_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/factors/${factor_id}/links/save`,
									method: 'post',
									build: (data) => {
										return data;
									}
								},
								delete: {
									url: (project_id, events_map_id, factor_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/factors/${factor_id}/links/delete`,
									method: 'post',
									build: (data) => {
										return data;
									}
								}
							},
							journal_entries: {
								url: (project_id, events_map_id, factor_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/factors/${factor_id}/journal-entries/`,
								method: 'get'
							}
						},
						create: {
							url: (project_id, events_map_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/factors/create`,
							method: 'post',
							build: (data) => {
								return data;
							}
						},
						save: {
							url: (project_id, events_map_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/factors/save`,
							method: 'post',
							build: (data) => {
								return data;
							}
						},
						copy: {
							url: (project_id, events_map_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/factors/copy`,
							method: 'post',
							build: (factor_id, parent_factor_id, copy_with_events) => {
								return {
									id: factor_id,
									parent_id: parent_factor_id,
									with_events: copy_with_events
								};
							}
						},
						delete: {
							url: (project_id, events_map_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/factors/delete`,
							method: 'post',
							build: (factorId, remove_orphan) => {
								return {
									id: factorId,
									orphan_removal: true
								};
							}
						},
						import: {
							url: (project_id, events_map_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/factors/import`,
							method: 'post',
							build: (data) => {
								return data;
							}
						},
						export: {
							url: (project_id, events_map_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/factors/export`,
							method: 'post',
							build: (data) => {
								return data;
							}
						}
					},
					trends: {
						url: (project_id, events_map_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/trends/`,
						method: 'get',
						id: {
							url: (project_id, events_map_id, trend_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/trends/${trend_id}`,
							method: 'get',
							attachments: {
								url: (project_id, events_map_id, trend_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/trends/${trend_id}/attachments/`,
								method: 'get',
								id: {
									url: (project_id, events_map_id, trend_id, attachment_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/trends/${trend_id}/attachments/${attachment_id}`,
									method: 'get'
								},
								create: {
									url: (project_id, events_map_id, trend_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/trends/${trend_id}/attachments/create`,
									method: 'post',
									build: (data) => {
										return data;
									}
								},
								save: {
									url: (project_id, events_map_id, trend_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/trends/${trend_id}/attachments/save`,
									method: 'post',
									build: (data) => {
										return data;
									}
								},
								delete: {
									url: (project_id, events_map_id, trend_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/trends/${trend_id}/attachments/delete`,
									method: 'post',
									build: (data) => {
										return data;
									}
								}
							},
							images: {
								url: (project_id, events_map_id, trend_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/trends/${trend_id}/images/`,
								method: 'get',
								id: {
									url: (project_id, events_map_id, trend_id, attachment_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/trends/${trend_id}/images/${attachment_id}`,
									method: 'get'
								},
								create: {
									url: (project_id, events_map_id, trend_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/trends/${trend_id}/images/create`,
									method: 'post',
									build: (data) => {
										return data;
									}
								},
								save: {
									url: (project_id, events_map_id, trend_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/trends/${trend_id}/images/save`,
									method: 'post',
									build: (data) => {
										return data;
									}
								},
								delete: {
									url: (project_id, events_map_id, trend_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/trends/${trend_id}/images/delete`,
									method: 'post',
									build: (data) => {
										return data;
									}
								}
							},
							comments: {
								url: (project_id, events_map_id, trend_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/trends/${trend_id}/comments/`,
								method: 'get',
								id: {
									url: (project_id, events_map_id, trend_id, comment_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/trends/${trend_id}/comments/${comment_id}`,
									method: 'get'
								},
								create: {
									url: (project_id, events_map_id, trend_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/trends/${trend_id}/comments/create`,
									method: 'post',
									build: (data) => {
										return data;
									}
								},
								save: {
									url: (project_id, events_map_id, trend_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/trends/${trend_id}/comments/save`,
									method: 'post',
									build: (data) => {
										return data;
									}
								},
								delete: {
									url: (project_id, events_map_id, trend_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/trends/${trend_id}/comments/delete`,
									method: 'post',
									build: (data) => {
										return data;
									}
								}
							},
							links: {
								url: (project_id, events_map_id, trend_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/trends/${trend_id}/links/`,
								method: 'get',
								id: {
									url: (project_id, events_map_id, trend_id, link_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/trends/${trend_id}/links/${link_id}`,
									method: 'get'
								},
								create: {
									url: (project_id, events_map_id, trend_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/trends/${trend_id}/links/create`,
									method: 'post',
									build: (data) => {
										return data;
									}
								},
								save: {
									url: (project_id, events_map_id, trend_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/trends/${trend_id}/links/save`,
									method: 'post',
									build: (data) => {
										return data;
									}
								},
								delete: {
									url: (project_id, events_map_id, trend_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/trends/${trend_id}/links/delete`,
									method: 'post',
									build: (data) => {
										return data;
									}
								}
							},
							events: {
								url: (project_id, events_map_id, trend_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/trends/${trend_id}/events/`,
								method: 'get',
								add: {
									url: (project_id, events_map_id, trend_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/trends/${trend_id}/events/add`,
									method: 'post',
									build: (data) => {
										return data;
									}
								},
								remove: {
									url: (project_id, events_map_id, trend_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/trends/${trend_id}/events/remove`,
									method: 'post',
									build: (data) => {
										return data;
									}
								}
							}
						},
						create: {
							url: (project_id, events_map_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/trends/create`,
							method: 'post',
							build: (data) => {
								return data;
							}
						},
						save: {
							url: (project_id, events_map_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/trends/save`,
							method: 'post',
							build: (data) => {
								return data;
							}
						},
						copy: {
							url: (project_id, events_map_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/trends/copy`,
							method: 'post',
							build: (data) => {
								return data;
							}
						},
						delete: {
							url: (project_id, events_map_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/trends/delete`,
							method: 'post',
							build: (trendId) => {
								return trendId;
							}
						},
						import: {
							url: (project_id, events_map_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/trends/import`,
							method: 'post',
							build: (data) => {
								return data;
							}
						},
						export: {
							url: (project_id, events_map_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/trends/export`,
							method: 'post',
							build: (data) => {
								return data;
							}
						}
					},
					filters: {
						url: (project_id, events_map_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/filters/export`,
						method: 'get',
						build: (data) => {
							return {
								userId: data
							}
						},
						id: {
							url: (project_id, events_map_id, filter_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/filters/${filter_id}`,
							method: 'get'
						},
						create: {
							url: (project_id, events_map_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/filters/create`,
							method: 'post',
							build: (data) => {
								return data
							}
						},
						save: {
							url: (project_id, events_map_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/filters/save`,
							method: 'post',
							build: (data) => {
								return data
							}
						},
						delete: {
							url: (project_id, events_map_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/filters/delete`,
							method: 'post',
							build: (id) => {
								return id
							}
						}
					},
					event_relations: {
						url: (project_id, events_map_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/event-relations/`,
						method: 'get',
						build: (eventId) => {
							return {
								eventId: eventId
							}
						},
						create: {
							url: (project_id, events_map_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/event-relations/create`,
							method: 'post',
							build: (data) => {
								return data
							}
						},
						save: {
							url: (project_id, events_map_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/event-relations/save`,
							method: 'post',
							build: (data) => {
								return data
							}
						},
						delete: {
							url: (project_id, events_map_id) => DATA_URL + `/projects/${project_id}/future-maps/${events_map_id}/event-relations/delete`,
							method: 'post',
							build: (data) => {
								return data;
							}
						},
					},
				}
			},
			attachments: {
				url: (project_id) => DATA_URL + `/projects/${project_id}/attachments/`,
				method: 'get',
				id: {
					url: (project_id, attachment_id) => DATA_URL + `/projects/${project_id}/attachments/${attachment_id}`,
					method: 'get'
				},
				create: {
					url: (project_id) => DATA_URL + `/projects/${project_id}/attachments/create`,
					method: 'post',
					build: (data) => {
						return data;
					}
				},
				save: {
					url: (project_id) => DATA_URL + `/projects/${project_id}/attachments/save`,
					method: 'post',
					build: (data) => {
						return data;
					}
				},
				delete: {
					url: (project_id) => DATA_URL + `/projects/${project_id}/attachments/delete`,
					method: 'post',
					build: (data) => {
						return data;
					}
				}
			},
			comments: {
				url: (project_id) => DATA_URL + `/projects/${project_id}/comments/`,
				method: 'get',
				id: {
					url: (project_id, comment_id) => DATA_URL + `/projects/${project_id}/comments/${comment_id}`,
					method: 'get'
				},
				create: {
					url: (project_id) => DATA_URL + `/projects/${project_id}/comments/create`,
					method: 'post',
					build: (data) => {
						return data;
					}
				},
				save: {
					url: (project_id) => DATA_URL + `/projects/${project_id}/comments/save`,
					method: 'post',
					build: (data) => {
						return data;
					}
				},
				delete: {
					url: (project_id) => DATA_URL + `/projects/${project_id}/comments/delete`,
					method: 'post',
					build: (data) => {
						return {id: data};
					}
				}
			},
			links: {
				url: (project_id) => DATA_URL + `/projects/${project_id}/links/`,
				method: 'get',
				id: {
					url: (project_id, link_id) => DATA_URL + `/projects/${project_id}/links/${link_id}`,
					method: 'get'
				},
				create: {
					url: (project_id) => DATA_URL + `/projects/${project_id}/links/create`,
					method: 'post',
					build: (data) => {
						return data;
					}
				},
				save: {
					url: (project_id) => DATA_URL + `/projects/${project_id}/links/save`,
					method: 'post',
					build: (data) => {
						return data;
					}
				},
				delete: {
					url: (project_id) => DATA_URL + `/projects/${project_id}/links/delete`,
					method: 'post',
					build: (data) => {
						return data;
					}
				}
			},
			scenarios: {
				url: (project_id) => DATA_URL + `/projects/${project_id}/scenarios/`,
				method: 'get',
				id: {
					url: (project_id, scenario_id) => DATA_URL + `/projects/${project_id}/scenarios/${scenario_id}`,
					method: 'get',
					attachments: {
						url: (project_id, scenario_id) => DATA_URL + `/projects/${project_id}/scenarios/${scenario_id}/attachments/`,
						method: 'get',
						id: {
							url: (project_id, scenario_id, attachment_id) => DATA_URL + `/projects/${project_id}/scenarios/${scenario_id}/attachments/${attachment_id}`,
							method: 'get'
						},
						create: {
							url: (project_id, scenario_id) => DATA_URL + `/projects/${project_id}/scenarios/${scenario_id}/attachments/create`,
							method: 'post',
							build: (data) => {
								return data;
							}
						},
						save: {
							url: (project_id, scenario_id) => DATA_URL + `/projects/${project_id}/scenarios/${scenario_id}/attachments/save`,
							method: 'post',
							build: (data) => {
								return data;
							}
						},
						delete: {
							url: (project_id, scenario_id) => DATA_URL + `/projects/${project_id}/scenarios/${scenario_id}/attachments/delete`,
							method: 'post',
							build: (data) => {
								return data;
							}
						}
					},
					comments: {
						url: (project_id, scenario_id) => DATA_URL + `/projects/${project_id}/scenarios/${scenario_id}/comments/`,
						method: 'get',
						id: {
							url: (project_id, scenario_id, comment_id) => DATA_URL + `/projects/${project_id}/scenarios/${scenario_id}/comments/${comment_id}`,
							method: 'get'
						},
						create: {
							url: (project_id, scenario_id) => DATA_URL + `/projects/${project_id}/scenarios/${scenario_id}/comments/create`,
							method: 'post',
							build: (data) => {
								return data;
							}
						},
						save: {
							url: (project_id, scenario_id) => DATA_URL + `/projects/${project_id}/scenarios/${scenario_id}/comments/save`,
							method: 'post',
							build: (data) => {
								return data;
							}
						},
						delete: {
							url: (project_id, scenario_id) => DATA_URL + `/projects/${project_id}/scenarios/${scenario_id}/comments/delete`,
							method: 'post',
							build: (data) => {
								return data;
							}
						}
					},
					links: {
						url: (project_id, scenario_id) => DATA_URL + `/projects/${project_id}/scenarios/${scenario_id}/links/`,
						method: 'get',
						id: {
							url: (project_id, scenario_id, link_id) => DATA_URL + `/projects/${project_id}/scenarios/${scenario_id}/links/${link_id}`,
							method: 'get'
						},
						create: {
							url: (project_id, scenario_id) => DATA_URL + `/projects/${project_id}/scenarios/${scenario_id}/links/create`,
							method: 'post',
							build: (data) => {
								return data;
							}
						},
						save: {
							url: (project_id, scenario_id) => DATA_URL + `/projects/${project_id}/scenarios/${scenario_id}/links/save`,
							method: 'post',
							build: (data) => {
								return data;
							}
						},
						delete: {
							url: (project_id, scenario_id) => DATA_URL + `/projects/${project_id}/scenarios/${scenario_id}/links/delete`,
							method: 'post',
							build: (data) => {
								return data;
							}
						}
					}
				},
				create: {
					url: (project_id) => DATA_URL + `/projects/${project_id}/scenarios/create`,
					method: 'post',
					build: (data) => {
						return data;
					}
				},
				save: {
					url: (project_id) => DATA_URL + `/projects/${project_id}/scenarios/save`,
					method: 'post',
					build: (data) => {
						return data;
					}
				},
				delete: {
					url: (project_id) => DATA_URL + `/projects/${project_id}/scenarios/delete`,
					method: 'post',
					build: (id) => {
						return id;
					}
				}
			},
			color_scheme: {
				url: (project_id) => DATA_URL + `/projects/${project_id}/color-scheme`,
				method: 'get',
				save: {
					url: (project_id) => DATA_URL + `/projects/${project_id}/color-scheme/save`,
					method: 'post',
					build: (data) => {
						return data;
					}
				}
			},
			roles: {
				url: (project_id) => DATA_URL + `/projects/${project_id}/roles/`,
				method: 'get',
				id: {
					url: (project_id, role_id) => DATA_URL + `/projects/${project_id}/roles/${role_id}`,
					method: 'get',
				},
				create: {
					url: (project_id) => DATA_URL + `/projects/${project_id}/roles/create`,
					method: 'post',
					build: (data) => {
						return data;
					}
				},
				save: {
					url: (project_id) => DATA_URL + `/projects/${project_id}/roles/save`,
					method: 'post',
					build: (data) => {
						return data;
					}
				},
				delete: {
					url: (project_id) => DATA_URL + `/projects/${project_id}/roles/delete`,
					method: 'post',
					build: (id) => {
						return id;
					}
				}
			},
			journal_entries: {
				url: (project_id) => DATA_URL + `/projects/${project_id}/journal-entries/`,
				method: 'get'
			},
			event_categories: {
				url: (project_id) => DATA_URL + `/projects/${project_id}/event-categories/`,
				method: 'get',
				create: {
					url: (project_id) => DATA_URL + `/projects/${project_id}/event-categories/create`,
					method: 'post',
					build: (data) => {
						return data;
					}
				}
			},
			event_types: {
				url: (project_id) => DATA_URL + `/projects/${project_id}/event-types/`,
				method: 'get',
				create: {
					url: (project_id) => DATA_URL + `/projects/${project_id}/event-types/create`,
					method: 'post',
					build: (data) => {
						return data;
					}
				}
			},
			event_sources: {
				url: (project_id) => DATA_URL + `/projects/${project_id}/event-sources/`,
				method: 'get',
				create: {
					url: (project_id) => DATA_URL + `/projects/${project_id}/event-sources/create`,
					method: 'post',
					build: (data) => {
						return data;
					}
				}
			}
		},
		create: {
			url: DATA_URL + '/projects/create',
			method: 'post',
			build: (project) => {
				return project
			}
		},
		save: {
			url: DATA_URL + '/projects/save',
			method: 'post',
			build: (data) => {
				return data
			}
		},
		copy: {
			url: DATA_URL + '/projects/copy',
			method: 'post',
			build: (projectId, level) => {
				return {
					id: projectId,
					level: level
				}
			}
		},
		delete: {
			url: DATA_URL + '/projects/delete',
			method: 'post',
			build: (id) => {
				return id
			}
		}
	},
	csrf: {
		url: DATA_URL + '/csrf',
		method: 'get'
	},
	flags: {
		url: DATA_URL + '/flags/',
		method: 'get',
		build: (access_token = "") => {
			return {
				access_token: access_token
			};
		}
	},
	event_categories: {
		url: DATA_URL + '/event-categories/',
		method: 'get',
		build: (access_token = "") => {
			return {
				access_token: access_token
			};
		},
		id: {
			url: (id) => DATA_URL + `/event-categories/${id}`,
			method: 'get',
			build: () => {
				return {};
			}
		},
		create: {
			url: DATA_URL + '/event-categories/create',
			method: 'post',
			build: (data) => {
				return data
			}
		},
		save: {
			url: DATA_URL + '/event-categories/save',
			method: 'post',
			build: (data) => {
				return data
			}
		},
		delete: {
			url: DATA_URL + '/event-categories/delete',
			method: 'post',
			build: (data) => {
				return data
			}
		}
	},
	event_sources: {
		url: DATA_URL + '/event-sources/',
		method: 'get',
		id: {
			url: (id) => DATA_URL + `/event-sources/${id}`,
			method: 'get',
			build: () => {
				return {};
			}
		},
		create: {
			url: DATA_URL + '/event-sources/create',
			method: 'post',
			build: (data) => {
				return data
			}
		},
		save: {
			url: DATA_URL + '/event-sources/save',
			method: 'post',
			build: (data) => {
				return data
			}
		},
		delete: {
			url: DATA_URL + '/event-sources/delete',
			method: 'post',
			build: (data) => {
				return data
			}
		}
	},
	event_types: {
		url: DATA_URL + '/event-types/',
		method: 'get',
		id: {
			url: (id) => DATA_URL + `/event-types/${id}`,
			method: 'get',
			build: () => {
				return {};
			}
		},
		create: {
			url: DATA_URL + '/event-types/create',
			method: 'post',
			build: (data) => {
				return data
			}
		},
		save: {
			url: DATA_URL + '/event-types/save',
			method: 'post',
			build: (data) => {
				return data
			}
		},
		delete: {
			url: DATA_URL + '/event-types/delete',
			method: 'post',
			build: (data) => {
				return data
			}
		}
	},
	numeric_units: {
		url: DATA_URL + '/numeric-units/',
		method: 'get',
		id: {
			url: (id) => DATA_URL + `/numeric-units/${id}`,
			method: 'get',
			build: () => {
				return {};
			}
		},
		create: {
			url: DATA_URL + '/numeric-units/create',
			method: 'post',
			build: (data) => {
				return data
			}
		},
		save: {
			url: DATA_URL + '/numeric-units/save',
			method: 'post',
			build: (data) => {
				return data
			}
		},
		delete: {
			url: DATA_URL + '/numeric-units/delete',
			method: 'post',
			build: (data) => {
				return data
			}
		}
	},
	project_categories: {
		url: DATA_URL + '/project-categories/',
		method: 'get',
		id: {
			url: (id) => DATA_URL + `/project-categories/${id}`,
			method: 'get',
			build: () => {
				return {};
			}
		},
		create: {
			url: DATA_URL + '/project-categories/create',
			method: 'post',
			build: (data) => {
				return data
			}
		},
		save: {
			url: DATA_URL + '/project-categories/save',
			method: 'post',
			build: (data) => {
				return data
			}
		},
		delete: {
			url: DATA_URL + '/project-categories/delete',
			method: 'post',
			build: (data) => {
				return data
			}
		}
	},
	entity_properties: {
		url: DATA_URL + '/entity-properties/',
		method: 'get',
		build: (entity_name) => {
			return {
				entity_type: entity_name
			}
		}
	},
	users: {
		url: DATA_URL + '/users/',
		method: 'get',
		id: {
			url: (id) => DATA_URL + `/users/${id}/roles/`,
			method: 'get',
			set_role: {
				url: (id) => DATA_URL + `/users/${id}/set-role`,
				method: 'post',
				build: (role) => {
					return role;
				}
			},
			set_password: {
				url: (id) => DATA_URL + `/users/${id}/change-password`,
				method: 'post',
				build: (password) => {
					return {
						password: password
					};
				}
			},
		},
		create: {
			url: DATA_URL + '/users/create',
			method: 'post',
			build: (data) => {
				return data
			}
		},
		delete: {
			url: DATA_URL + '/users/delete',
			method: 'post',
			build: (data) => {
				return data
			}
		},
	},
	notifications: {
		url: (page_index, page_size) => {
			return `${DATA_URL}/notifications/?page_index=${page_index}&page_size=${page_size}`
		},
		method: 'get',
		filter: {
			url: `${DATA_URL}/notifications/filters/`,
			method: 'get',
			create: {
				url: `${DATA_URL}/notifications/filters/create`,
				method: 'post',
				build: (filter) => {
					return filter;
				}
			},
			delete: {
				url: `${DATA_URL}/notifications/filters/delete`,
				method: 'post'
			},
		},
	},
	authentication: {
		url: DATA_URL + '/authentication',
		method: 'get',
		build: (access_token = "") => {
			return {
				access_token: access_token
			};
		}
	}
};