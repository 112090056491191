import {browserHistory} from '../app';
import {CLIENT_URI_CONSTANTS as URI} from '../utils/uri-constants';
import {userService} from "../services";

export const actionTypes = {

	prefix: 'USER',

	REQUEST_USERS: 'REQUEST_USERS',
	RECEIVE_USERS: 'RECEIVE_USERS',
	REQUEST_USER: 'REQUEST_USER',
	RECEIVE_USER: 'RECEIVE_USER',
	SELECT_USER: 'SELECT_USER',
	ADD_USER: 'ADD_USER',
	EDIT_USER: 'EDIT_USER',
	SAVE_USER: 'SAVE_USER',
	DELETE_USER: 'DELETE_USER',
	START_OPERATION: 'START_OPERATION',
	END_OPERATION: 'END_OPERATION',

	get: function (key) {
		return this.prefix + '_' + this[key];
	}
};

export const fetchUsers = () => {
	return (dispatch, getState) => {
		if (!getState().usersReducer.isPending) {
			dispatch(requestUsers());
			userService.getUsers()
			           .then(users_proxy => {
				           dispatch(receiveUsers(users_proxy.data));
			           });
		}
	}
};

export const requestUsers = () => {
	return {
		type: actionTypes.get(actionTypes.REQUEST_USERS)
	}
};

export const fetchUser = (userId) => {
	return (dispatch, getState) => {
		if (!getState().usersReducer.isPending) {
			dispatch(requestUser());
			userService.getUser(userId)
			           .then(user_proxy => {
				           dispatch(receiveUser(user_proxy.data));
			           });
		}
	}
};

export const requestUser = () => {
	return {
		type: actionTypes.get(actionTypes.REQUEST_USER)
	}
};

export const receiveUser = (response) => {
	return {
		type: actionTypes.get(actionTypes.RECEIVE_USER),
		data: response
	}
};

export const receiveUsers = (response) => {
	return {
		type: actionTypes.get(actionTypes.RECEIVE_USERS),
		data: response
	}
};

export const selectUser = (user) => {
	return {
		type: actionTypes.get(actionTypes.SELECT_USER),
		data: user
	}
};

export const addUser = () => {
	return (dispatch, getState) => {
		dispatch(receiveUser(null));
		browserHistory.push(`/${URI.USERS}/0`);
	};
};

export const editUser = (user) => {
	return (dispatch, getState) => {
		browserHistory.push(`/${URI.USERS}/${user.id}`);
	};
};

export function createUser(user) {
	return (dispatch, getState) => {
		userService.create(user)
		           .then(user_proxy => {
			           dispatch(receiveUser(user_proxy.data));
			           dispatch(endOperation({
				                                 name: 'CREATION',
				                                 status: "SUCCESS"
			                                 }));
			           browserHistory.push(`/${URI.USERS}`)
		           })
		           .catch((error) => {
			           dispatch(endOperation({
				                                 name: 'CREATION',
				                                 status: "ERROR"
			                                 }));
		           });
	};
}

export function saveUser(user) {
	return (dispatch, getState) => {
		userService.save(user)
		           .then(user_proxy => {
			           dispatch(receiveUser(user_proxy.data));
			           dispatch(endOperation({
				                                 name: 'SAVING',
				                                 status: "SUCCESS"
			                                 }));
		           })
		           .catch((error) => {
			           dispatch(endOperation({
				                                 name: 'SAVING',
				                                 status: "ERROR"
			                                 }));
		           });
	}
}

export function deleteUser(user) {
	return (dispatch, getState) => {
		userService.remove(user)
		           .then(() => {
			           dispatch(fetchUsers());
		           });
	}
}

export function changePassword(userId, password) {
	return (dispatch, getState) => {
		userService.changePassword(userId, password)
		           .then((user_proxy) => {
			           dispatch(fetchUsers());
		           });
	}
}

export const startOperation = (operation) => {
	return {
		type: actionTypes.get(actionTypes.START_OPERATION),
		data: operation
	}
};

export const endOperation = (operation) => {
	return {
		type: actionTypes.get(actionTypes.END_OPERATION),
		data: operation
	}
};