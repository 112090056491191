import BaseForm from "../base/base-form";
import {Button, Modal, Table} from "react-bootstrap";
import React from "react";
import Utils from "../../utils/utils";

class ProjectRestoreDialog extends BaseForm {

    constructor(props) {
        super(props);

        this.state = {
            selected: null,
            loading: false
        };
    }

    render() {
        return (
            <Modal show={this.props.show} className="retrospection-modal" onHide={this.handleCancelButton}>
                <Modal.Header closeButton>
                    <Modal.Title>Journal entries</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table>
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th style={{width: '10%', maxWidth: '10%'}}>ID</th>
                            <th style={{width: '25%', maxWidth: '25%'}}>Begin</th>
                            <th style={{width: '25%', maxWidth: '25%'}}>End</th>
                            <th style={{width: '10%', maxWidth: '10%'}}>Active</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.entries.map(entry => {
                                return (
                                    <tr key={entry.id}
                                        onClick={(param) => Utils.singleDoubleClick(
                                            param.target,
                                            entry,
                                            this.handleOnItemClick,
                                            this.handleOnItemDoubleClick,
                                            param.ctrlKey,
                                            200)}
                                        className={`clickable ${this.state.selected == entry ? 'selected' : ''}`}
                                    >
                                        <td>{entry.title}</td>
                                        <td>{entry.retrospection_id}</td>
                                        <td style={{textAlign: 'center'}}> {Utils.convertDatetime(entry.retrospection_start)} </td>
                                        <td style={{textAlign: 'center'}}> {Utils.convertDatetime(entry.retrospection_end)} </td>
                                        <td>{entry.is_active ? "yes" : "no"}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button bsClass="btn pull-right action-button" onClick={this.handleCancelButton}>Cancel</Button>
                    <Button bsClass="btn pull-right action-button" onClick={this.handleCopyButton}>Copy</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    handleOnItemClick = (elem, item, ctrlKey) => {
        this.setState({
            selected: item
        });
    };

    handleOnItemDoubleClick = (elem, item, ctrlKey) => {
        this.props.onRestore(item);
    };

    handleCopyButton = () => {
        this.props.onRestore(this.state.selected);
    };

    handleCancelButton = () => {
        this.props.onCancel();
    };
}

export default ProjectRestoreDialog