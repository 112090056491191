import {userService} from "../services";

export const actionTypes = {
	REQUEST_NEWS: 'REQUEST_NEWS',
	RECEIVE_NEWS: 'RECEIVE_NEWS',
	REQUEST_PROJECT_NEWS: 'REQUEST_PROJECT_NEWS',
	RECEIVE_PROJECT_NEWS: 'RECEIVE_PROJECT_NEWS'
};

export const fetchNews = (projects, period) => {
	return (dispatch, getState) => {
		if (!getState().newsReducer.isPending) {
			dispatch(requestNews(projects, period));
			userService.getNotifications(projects.map(x => x.id), period.code)
			           .then(entries_proxy => {
				           dispatch(receiveNews(entries_proxy.data));
			           });
		}
	}
};

export const requestNews = (projects, period) => {
	return {
		type: actionTypes.REQUEST_NEWS,
		data: {
			projects: projects,
			period: period
		}
	}
};

export function receiveNews(response) {
	return {
		type: actionTypes.RECEIVE_NEWS,
		data: response
	}
}


export function receiveNewsItem(notification) {
	return (dispatch, getState) => {
		const news = getState().newsReducer.news ? getState().newsReducer.news : [];
		news.push(notification);
		dispatch(receiveNews(news))
	}
}

export function fetchProjectNews(project, period) {
	return (dispatch, getState) => {
		if (!getState().newsReducer.isPending) {
			dispatch(requestProjectNews(project, period));
			userService.getNotifications([project.id], period.code)
			           .then(entries_proxy => {
				           dispatch(receiveProjectNews(entries_proxy.data));
			           });
		}
	}
}

export const requestProjectNews = (project, period) => {
	return {
		type: actionTypes.REQUEST_PROJECT_NEWS,
		data: {
			project: project,
			period: period
		}
	}
};

export function receiveProjectNews(response) {
	return {
		type: actionTypes.RECEIVE_PROJECT_NEWS,
		data: response
	}
}

export const receiveProjectNewsItem = (notification) => {
	return (dispatch, getState) => {
		const projectNews = getState().newsReducer.projectNews ? getState().newsReducer.projectNews : [];
		projectNews.push(notification);
		dispatch(receiveProjectNews(projectNews))
	}
};
