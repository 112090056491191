import React from 'react';

class ProcessOverlay
	extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className={this.props.className ? this.props.className + " loading-overlay" : "loading-overlay"}>
				<div className={"container"}>
					{this.props.children}
				</div>
			</div>
		);
	}
}

export default ProcessOverlay;