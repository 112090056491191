import React from "react";
import {Button, Col, ControlLabel, Form, FormControl, FormGroup, Glyphicon, Grid, Nav, Navbar, NavItem, OverlayTrigger, Popover, Row, Table, Tooltip} from "react-bootstrap";
import NotificationItem from "../components/common/notification-item";
import ProjectRestoreDialog from "../components/dialogs/project-restore";
import ProjectCopyLevelDialog from "../components/dialogs/project-copy-level";
import {Combobox, Multiselect} from "react-widgets";
import SelectBox from "../components/controls/select-box/select-box";
import InfiniteScroll from 'react-infinite-scroller';
import Utils from "../utils/utils";
import NotificationsFeed from "../components/common/notifications-feed";

class HomeView
	extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			selected: null,
			showLevelModal: false,
			showRestoreModal: false,
			entries: [],
			...this.setPermissions(props)
		};
	}

	componentDidMount() {
		this.props.loadInitialData();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props !== prevProps) {
			this.setState({
				              ...this.setPermissions(this.props)
			              });
		}
	}

	setPermissions = (props) => {
		if (props.isSystemAdministrator()) {
			return {
				createEnabled: true,
				copyEnabled: true,
				deleteEnabled: true,
				restoreEnabled: true,
			}
		} else if (props.isAdministrator(props.selectedProject)) {
			return {
				createEnabled: true,
				copyEnabled: true,
				deleteEnabled: true,
				restoreEnabled: true,
			}
		} else if (props.isAnalyst(props.selectedProject)) {
			return {
				createEnabled: true,
				copyEnabled: true,
				deleteEnabled: true,
				restoreEnabled: true,
			}
		} else if (props.isManager(props.selectedProject)) {
			return {
				createEnabled: true,
				copyEnabled: true,
				deleteEnabled: true,
				restoreEnabled: true,
			}
		} else {
			return {
				createEnabled: true,
				copyEnabled: false,
				deleteEnabled: false,
				restoreEnabled: false,
			}
		}
	};

	render() {
		const self = this;


		return (
			<Grid fluid={true} className="display-flex align-items-stretch home-view h-100">
				<Row className="display-flex align-items-stretch">
					<Col md={9} className="h-100 projects-list-wrapper">
						<Grid fluid={true} id="projects-list" className="h-100 projects-list">
							<Row className="toolbar">
								<Col md={12}>
									<Navbar className="toolbar" fluid={true}>
										<Nav>
											{
												self.state.createEnabled &&

												<NavItem>
													<OverlayTrigger placement="bottom"
													                overlay={<Tooltip id="tooltip">Add
														                project</Tooltip>}>
														<Button bsClass="btn bar-button"
														        onClick={this.handleCreateButtonClick}>
															<Glyphicon glyph="plus" className="c-glyph-icon"/>
															ADD
														</Button>
													</OverlayTrigger>
												</NavItem>
											}
											{
												self.state.copyEnabled &&

												<NavItem>
													<OverlayTrigger placement="bottom"
													                overlay={<Tooltip id="tooltip">Copy
														                project</Tooltip>}>
														<Button bsClass="btn bar-button"
														        onClick={this.handleCopyButtonClick}>
															<Glyphicon glyph="copy" className="c-glyph-icon"/>
															COPY
														</Button>
													</OverlayTrigger>
												</NavItem>
											}
											{
												self.state.deleteEnabled &&

												<NavItem>
													<OverlayTrigger placement="bottom"
													                overlay={<Tooltip id="tooltip">Delete
														                project</Tooltip>}>
														<Button bsClass="btn bar-button"
														        onClick={this.handleDeleteButtonClick}>
															<Glyphicon glyph="remove" className="c-glyph-icon"/>
															DELETE
														</Button>
													</OverlayTrigger>
												</NavItem>
											}
											{
												self.state.restoreEnabled &&

												<NavItem>
													<OverlayTrigger placement="bottom"
													                overlay={<Tooltip id="tooltip">Restore
														                project</Tooltip>}>
														<Button bsClass="btn bar-button"
														        onClick={this.handleDeleteButtonClick}>
															<Glyphicon glyph="upload" className="c-glyph-icon"/>
															RESTORE
														</Button>
													</OverlayTrigger>
												</NavItem>
											}
										</Nav>
										<Navbar.Form pullRight>
											<ProjectsFilter data={this.props.projectsFilter}
											                onFilter={(title) => this.props.filterProjects(title)}/>
										</Navbar.Form>
									</Navbar>
								</Col>
							</Row>
							<Row className="content">
								<Col md={12} className="h-100 p-0">
									<Grid fluid={true} className="h-100">
										<Row className="table-wrapper h-75">
											<Col md={12}>
												<Table>
													<thead>
													<tr>
														<th>Title</th>
														<th style={{
															width: '25%',
															maxWidth: '25%'
														}}>Created
														</th>
														<th style={{
															width: '25%',
															maxWidth: '25%'
														}}>Last Updated
														</th>
													</tr>
													</thead>
													<tbody>
													{
														this.props.projects &&
														this.props.projects
														    .sort(Utils.sortAscendingByProperty('last_updated_date'))
														    .map(item => {
															    return (
																    <tr key={item.id}
																        onClick={
																	        (event) => Utils.singleDoubleClick(event.target,
																	                                           item,
																	                                           self.handleOnItemClick,
																	                                           self.handleOnItemDoubleClick,
																	                                           event.ctrlKey,
																	                                           200)
																        }
																        className={`clickable ${(this.props.selectedProject && this.props.selectedProject.id === item.id ? 'selected' : '')}`}>
																	    <td> {item.name} </td>
																	    <td style={{textAlign: 'center'}}> {Utils.convertDatetime(
																		    item.create_date)} </td>
																	    <td style={{textAlign: 'center'}}> {Utils.convertDatetime(
																		    item.last_updated_date)} </td>
																    </tr>
															    )
														    })
													}
													</tbody>
												</Table>
											</Col>
										</Row>
										<Row className="h-25">
											<Col md={12} className="h-100">
												<div className="description"
												     ref={
													     (element) => {
														     this.descriptionField = element
													     }
												     }
												>
												</div>
											</Col>
										</Row>
									</Grid>
								</Col>
							</Row>
						</Grid>
					</Col>
					<Col md={3} className="global-tape-widget-wrapper">
						<Grid id={this.props.widgetId} fluid={true} className="h-100">
							<Row className="toolbar">
								<Col md={12}>
									<Navbar className="toolbar" fluid={true}>
										<Nav>
											{
												<NavItem>
													<OverlayTrigger placement="bottom"
													                overlay={<Tooltip id="tooltip">Refresh</Tooltip>}>
														<Button bsClass="btn bar-button"
														        onClick={
															        (e) => {
																        this.props.loadNotifications(0, 20)
															        }
														        }
														>
															<Glyphicon glyph="refresh"/>
														</Button>
													</OverlayTrigger>
												</NavItem>
											}
											{
												<NavItem>
													<NotificationsFilter data={this.props.notificationsFilter}
													                     projects={this.props.projects}
													                     onFilter={(filter) => this.props.filterNotifications(filter)}
													/>
												</NavItem>
											}
										</Nav>
									</Navbar>
								</Col>
							</Row>
							<Row className="content">
								<Col md={12} className="h-100">
									<NotificationsFeed projects={this.props.projects}
									                   notifications={this.props.notifications ? this.props.notifications : []}
									                   itemsProvider={(page) => {
										                   this.props.appendNotifications(page, 50)
									                   }}
									/>
								</Col>
							</Row>
						</Grid>
					</Col>
				</Row>
				<ProjectCopyLevelDialog show={this.props.copyLevelDialogOpened}
				                        onHide={() => this.setState({showLevelModal: false})}
				                        onCopy={this.handleCopyLevelDialogAcceptButtonClick}
				                        onCancel={this.handleCopyLevelDialogCancelButtonClick}
				/>
				<ProjectRestoreDialog show={this.props.restoreDialogOpened}
				                      onHide={() => this.setState({showRestoreModal: false})}
				                      entries={this.props.retrospectionEntries}
				                      onRestore={this.handleRestoreDialogAcceptButtonClick}
				                      onCancel={this.handleRestoreDialogCancelButtonClick}
				/>
			</Grid>
		);
	}

	handleOnItemClick = (elem, item, ctrlKey) => {
		this.props.selectProject(item);
	};

	handleOnItemDoubleClick = (elem, item, ctrlKey) => {
		this.props.editProject(item);
	};

	handleRestoreButtonClick = () => {
		this.props.openRestoreDialog(this.props.selectedProject);
	};

	handleDeleteButtonClick = () => {
		if (!this.props.selectedProject) {
			alert("You must select item from table");
		} else {
			if (confirm(`Are you sure you want to delete project: ${this.props.selectedProject.name}?`)) {
				this.props.deleteProject(this.props.selectedProject.id);
			}
		}
	};

	handleCopyButtonClick = () => {
		if (!this.props.selectedProject) {
			alert("You must select item from table");
		} else {
			this.props.openCopyLevelDialog();
		}
	};

	handleCreateButtonClick = () => {
		this.props.createProject();
	};

	handleCopyLevelDialogAcceptButtonClick = (level) => {
		this.props.copyProject(this.props.selectedProject, level.code);
	};

	handleCopyLevelDialogCancelButtonClick = () => {
		this.props.closeCopyLevelDialog();
	};

	handleRestoreDialogAcceptButtonClick = (entry) => {
		this.props.restoreProject(entry);
	};

	handleRestoreDialogCancelButtonClick = () => {
		this.props.closeRestoreProjectDialog();
	};

}

class NotificationsFilter
	extends React.Component {

	constructor(props) {

		super(props);

		this.periods = [
			{
				code: 0,
				text: "Today"
			},
			{
				code: 1,
				text: "Three days"
			},
			{
				code: 2,
				text: "One week"
			},
			{
				code: 3,
				text: "One month"
			},
			{
				code: 4,
				text: "All time"
			}
		]

		this.state = {
			selected_projects: props.projects,
			period: props.period_code ? this.periods.find((item) => {
				return item.code === props.period_code;
			}) : this.periods[this.periods.length - 1]
		}
	}

	componentDidMount() {
		this.setState({
			              selected_projects: this.props.projects,
			              period: this.props.data && this.props.data.period_code != null ? this.periods.find((item) => {
				              return item.code === this.props.data.period_code;
			              }) : this.periods[this.periods.length - 1]
		              })
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps !== this.props) {

			let selected_projects = this.props.data && this.props.projects ? this.props.projects.filter((item) => {
				return this.props.data.projectIds && this.props.data.projectIds.indexOf(item.id) !== -1
			}) : [];

			this.setState({
				              selected_projects: selected_projects,
				              period: this.props.data && this.props.data.period_code != null ? this.periods.find((item) => {
					              return item.code === this.props.data.period_code;
				              }) : this.periods[this.periods.length - 1]
			              })
		}
	}

	componentDidCatch(error, errorInfo) {
		this.setState({
			              error: true
		              })
	}

	render() {

		if (this.state.error) {
			return (
				<Button bsClass="btn bar-button btn-danger">
					<Glyphicon glyph="filter"/>
				</Button>
			)
		}

		const filterPopover = (
			<Popover className="news-filter-popover" id={"notificaitons-filter-popover"}>
				<Form horizontal={true}>
					<FormGroup>
						<Col md={3}>
							<ControlLabel>Period:</ControlLabel>
						</Col>
						<Col md={9}>
							<Combobox id="tape-filter"
							          onChange={(e) => {
								          this.setState({period: e})
							          }}
							          defaultValue={this.periods[this.periods.length - 1]}
							          value={this.state.period}
							          data={this.periods}
							          textField='text'
							          style={{display: 'inline-block'}}
							/>
						</Col>
					</FormGroup>
					<FormGroup>
						<Col md={3}>
							<ControlLabel>Projects:</ControlLabel>
						</Col>
						<Col md={9}>
							<Multiselect name="tape-projects-filter"
							           className="tape-filter"
							           value={this.state.selected_projects}
							           onChange={(e) => {
								           this.setState({selected_projects: e})
							           }}
							           data={this.props.projects}
							           textField="name"
							           valueProperty="id"
							           changeOnClose={true}
							/>
						</Col>
					</FormGroup>
				</Form>
			</Popover>
		);

		return (
			<OverlayTrigger trigger="click"
			                rootClose
			                placement="bottom"
			                overlay={filterPopover}
			                onExited={(e) => {
				                this.props.onFilter({
					                                    projects: this.state.selected_projects,
					                                    period: this.state.period
				                                    })
			                }}>
				<OverlayTrigger placement="bottom"
				                overlay={
					                <Tooltip id="tooltip">Filters</Tooltip>
				                }>
					<Button bsClass="btn bar-button">
						<Glyphicon glyph="filter"/>
					</Button>
				</OverlayTrigger>
			</OverlayTrigger>
		)
	}
}

class ProjectsFilter
	extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			title: props.data ? props.data.title : ""
		}
	}

	componentDidMount() {
		if (this.props.data) {
			this.setState({
				              title: this.props.data && this.props.data.title ? this.props.data.title : ""
			              })
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.data !== prevProps.data) {
			this.setState({
				              title: this.props.data && this.props.data.title ? this.props.data.title : "",
			              })
		}
	}

	componentDidCatch(error, errorInfo) {
		this.setState({
			              error: true
		              })
	}

	render() {
		return (
			<FormGroup>
				<FormControl type="text"
				             placeholder="Filter"
				             value={this.state.title}
				             onChange={(e) => this.setState({title: e.target.value})}
				             onKeyDown={(e) => {
					             if (e.key === "Enter") {
						             this.props.onFilter(this.state.title)
					             }
				             }}
				/>
				<OverlayTrigger placement="bottom"
				                overlay={<Tooltip id="tooltip">Filter</Tooltip>}>
					<Button bsClass={this.state.error ? "btn bar-button btn-danger" : "btn bar-button"}
					        onClick={this.state.error ? null : (e) => {
						        this.props.onFilter(this.state.title)
					        }}>
						<Glyphicon glyph="filter"/>
					</Button>
				</OverlayTrigger>
			</FormGroup>
		)
	}
}

export default HomeView;