import clientFactory from "../factories/http_client";
import {API} from "../api/api";

class ScenarioService {

	/** @return  Promise<AxiosResponse<T>> */
	get = (projectId, scenarioId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.scenarios.id.method,
			                             url: API.data.projects.id.scenarios.id.url(projectId, scenarioId)
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	create = (projectId, scenario) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.scenarios.create.method,
			                             url: API.data.projects.id.scenarios.create.url(projectId),
			                             data: API.data.projects.id.scenarios.create.build(scenario),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	save = (projectId, scenario) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.scenarios.save.method,
			                             url: API.data.projects.id.scenarios.save.url(projectId),
			                             data: API.data.projects.id.scenarios.save.build(scenario),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	remove = (projectId, scenarioId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.scenarios.delete.method,
			                             url: API.data.projects.id.scenarios.delete.url(projectId),
			                             data: API.data.projects.id.scenarios.delete.build(scenarioId),
		                             });
	};
}

export default ScenarioService;