import React from 'react';
import autoBind from 'react-autobind';

/*
* Базовый React компонент.
* 1) Делает автоматическое связывание всех методов класса со ссылкой на текущий экземпляр этого класса
*      (не нужно это делать вручную в конструкторе)
* 2) Предоставляет базовый функционал для отображения информации о загрузке данных
*   2.1) Если функционал нужен, то переопределяем методы {@link BaseComponent#getCondition() getCondition}
*           и {@link BaseComponent#conditionalRender() conditionalRender}
*   2.2) Если не нужен, то переопределяем метод {@link BaseComponent#render() render}
* */
class BaseComponent extends React.Component {

    constructor(props) {
        super(props);

        autoBind(this);
    }

    getCondition() {
        return null;
    }

    conditionalRender() {
        return null;
    }

    render() {
        if (this.getCondition()) {
            return this.conditionalRender();
        } else {
            return <p>Loading, please wait...</p>
        }
    }
}

export default BaseComponent;