import React from 'react';
import {Button, Col, Glyphicon, Grid, Navbar, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import ScenarioMap from '../../controls/scenario-map';
import {scenarioService} from '../../../services';
import {CLIENT_URI_CONSTANTS as URI} from "../../../utils/uri-constants";
import EventPreview from '../../../components/event/event-preview';
import {REFS} from '../../controls/events-map/future-map-models';
import {authorities} from "../../../mixins/authorities";
import {connect} from "react-redux";
import {withRouter} from "react-router";


class ScenarioMapPage
	extends React.Component {

	constructor(props) {
		super(props);

		this.projectId = this.props.match.params.projectId;
		this.scenarioId = this.props.match.params.scenarioId;

		this.selectedNodeId = null;

		this.state = {};
	}

	componentDidMount() {
		scenarioService.get(this.projectId, this.scenarioId)
		               .then(scenario => {
			               this.setState({scenario: scenario});
		               });
	}

	componentDidCatch(error, errorInfo) {
		this.setState({
			              error: true
		              })
	}


	render() {

		if (this.state.error) {
			return <div>Error occurred...</div>
		}

		return (
			<Grid fluid={true} className="display-flex align-items-stretch flex-direction-column h-100">
				<Row className="toolbar">
					<Col md={12}>
						<Navbar fluid={true} className="toolbar">
							<div className="bar-button-group">
								<OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip">Add Event</Tooltip>}>
									<Button bsClass="btn bar-button" onClick={this.addButtonHandler}>
										<Glyphicon glyph="plus" className="c-glyph-icon"/>
										ADD EVENT
									</Button>
								</OverlayTrigger>
								<OverlayTrigger placement="bottom"
								                overlay={<Tooltip id="tooltip">Delete Event</Tooltip>}>
									<Button bsClass="btn bar-button" onClick={this.deleteButtonHandler}>
										<Glyphicon glyph="remove" className="c-glyph-icon"/>
										DELETE EVENT
									</Button>
								</OverlayTrigger>
							</div>
						</Navbar>
					</Col>
				</Row>
				<Row className="content">
					<Col id="scenario-map" md={12} className="h-100 col-md-12">
						<ScenarioMap scenario={this.props.scenario}
						             onSelectNode={this.handleSelectNode}
						             onDeselectNode={this.handleDeselectNode}
						             onDoubleClick={this.handleDoubleClick}
						/>
					</Col>
				</Row>
				{
					this.state.showPreview &&
					<EventPreview ref={REFS.EVENT_PREVIEW_REF}
					              show={this.state.showPreview}
					              event={this.props.scenario.events.filter(event => event.id === this.selectedNodeId)[0]}
					              onHide={
						              () => {
							              this.setState({
								                            showPreview: false
							                            });
						              }
					              }
					/>
				}
			</Grid>
		)
	}

	handleSelectNode = (eventId) => {
		this.selectedNodeId = eventId;
	};

	handleDeselectNode = () => {
		this.selectedNodeId = null;
	};

	handleDoubleClick = (eventId) => {
		this.setState({
			              showPreview: true
		              });
	};

	addButtonHandler = () => {
		this.props.history.push(`/${URI.PROJECT}/${this.projectId}/${URI.SCENARIOS}/${this.scenarioId}`);
	};

	deleteButtonHandler = () => {
		if (this.selectedNodeId && confirm(`Are you sure you want to delete event?`)) {
			const scenario = this.state.scenario;
			scenario.events = scenario.events.filter(event => event.id !== this.selectedNodeId);

			ScenarioService.save(this.projectId, scenario)
			               .then(upScenario => {
				               this.setState({
					                             scenario: upScenario
				                             });
			               });
		}
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		project: state.projectReducer.project,
		scenario: state.scenariosReducer.selectedScenario,
		...authorities(state.authenticationReducer.authentication)
	}
};

const mapDispatchToProps = (dispatch) => {
	return {}
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScenarioMapPage));