import React from "react";
import {Glyphicon, Image} from "react-bootstrap";
import Utils from "../../../utils/utils";

class EventControl
	extends React.PureComponent {

	constructor(props) {

		super(props);

		this.state = {
			impactEditMode: false,
			probabilityEditMode: false
		}

		this.controlRef = React.createRef()
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
	}

	render() {

		const self = this;

		let className = (this.props.events && this.props.events.length > 1 ? 'control multi' : 'control');

		if (this.props.selected) {
			className += " selected";
		}

		return (
			<div className={className}
			     ref={this.controlRef}
			     style={{
				     height: 84 * (this.props.scaleY >= 1 ? 1 : this.props.scaleY),
				     zIndex: this.props.layer,
				     maxWidth: 180 * this.props.scaleX,
				     boxShadow: this.props.backlighting
			     }}
			     onDoubleClick={(e) => {
				     if (this.props.onDoubleClick) {
					     this.props.onDoubleClick(this.props.event, e)
				     }
			     }}
			     onClick={(e) => {
				     if (this.props.onClick) {
					     this.props.onClick(this.props.event, e)
				     }
			     }}
			     onContextMenu={(e) => {
				     if (this.props.onContextMenu) {
					     this.props.onContextMenu(this.props.event, e)
				     }
			     }}
			>
				{
					this.props.event &&
					(
						<div className="fm-grid-cell-control"
						     draggable="true"
						     onDragStart={
							     (e) => {
								     if (!this.state.impactEditMode && !this.state.probabilityEditMode) {
									     this.props.onEventDragStart(this.props.event, e);
								     } else {
									     e.preventDefault();
									     e.stopPropagation();
								     }
							     }
						     }
						     style={
							     {
								     backgroundColor: !self.props.backgroundMode ? this.props.eventBackgroundColorProvider(
									     this.props.event) : 'lightgrey',
								     borderColor: !self.props.backgroundMode ? this.props.eventBorderColorProvider(
									     this.props.event) : 'lightgrey',
							     }
						     }>
							<div className="h-100 container-fluid">
								<div className="row">
									{
										((this.props.event.source_relations && this.props.event.source_relations.length > 0) || (this.props.event.target_relations && this.props.event.target_relations.length > 0)) &&
										<div className="relations-marker"/>
									}
									<div className="p-0 col-md-12">
										<div className="color-label"
										     style={
											     {
												     backgroundColor: !self.props.backgroundMode ? this.props.eventColorProvider(
													     this.props.event) : 'grey'
											     }
										     }/>
									</div>
								</div>
								<div className="title-row row">
									<div className="h-100 p-0 col-md-11">
										<label className="title-label">
											{this.props.event.title}
										</label>
									</div>
								</div>
								{
									!this.state.impactEditMode && !this.state.probabilityEditMode &&
									<div className={this.props.cellHeight * this.props.scaleY > 70 ? "additional-info-row row" : "additional-info-row hidden row"}>
										<div className="p-0 col-md-2">
											<label className={`impact-label value-${Math.round(this.props.event.average_impact)}`}
											       onDoubleClick={
												       (e) => {
													       e.stopPropagation();
													       this.setState({
														                     impactEditMode: true,
														                     newImpact: this.props.event.average_impact
													                     });
												       }
											       }
											>
												{this.props.event.average_impact}
											</label>
										</div>
										<div className="p-0 col-md-5">
											<span className="probability-label"
											      onDoubleClick={
												      (e) => {
													      e.stopPropagation();
													      this.setState({
														                    probabilityEditMode: true,
														                    newProbability: this.props.event.average_probability
													                    });
												      }
											      }
											>
												{this.props.event.average_probability + '%'}
												</span>
											<span className="probability-label"
											      onDoubleClick={
												      (e) => {
													      e.stopPropagation();
													      this.setState({
														                    probabilityEditMode: true,
														                    newProbability: this.props.event.average_probability
													                    });
												      }
											      }
											>
												({parseInt(this.props.event.relative_probability) + '%'})
											</span>
										</div>
										<div className="p-0 col-md-3">
											<span className="comments-label">
												<Glyphicon glyph="envelope"/>
												<span>{this.props.event.comments.length}</span>
											</span>
										</div>
										<div className="p-0 col-md-2">
											{
												this.props.event.flag &&
												(
													<Image src={Utils.getResourceUri(this.props.event.flag.path)}/>
												)
											}
										</div>
									</div>
								}
								{
									this.state.impactEditMode &&
									<div className={this.props.cellHeight * this.props.scaleY > 70 ? "additional-info-row row" : "additional-info-row hidden row"}>
										<div className="p-1 col-md-7">
											<input className="impact-range" type="range" min="0" max="12"
											       value={this.state.newImpact}
											       step="1"
											       onChange={
												       (e) => {
													       this.setState({
														                     newImpact: e.target.value
													                     });
												       }
											       }/>
										</div>
										<div className="p-0 col-md-2">
											<label className="impact-new-value">{this.state.newImpact}</label>
										</div>
										<div className="p-0 mr-1 col-md-1">
											<button className="impact-edit-button accept"
											        onClick={
												        (e) => {
													        e.stopPropagation();
													        this.props.onEventImpactChange(this.props.event,
													                                       this.props.event.average_impact,
													                                       this.state.newImpact);
													        this.setState({
														                      impactEditMode: false
													                      });
												        }
											        }>
												<Glyphicon glyph="ok" className="c-glyph-icon"/>
											</button>
										</div>
										<div className="p-0 col-md-1">
											<button className="impact-edit-button cancel"
											        onClick={
												        (e) => {
													        e.stopPropagation();
													        this.setState({
														                      impactEditMode: false
													                      });
												        }
											        }>
												<Glyphicon glyph="remove" className="c-glyph-icon"/>
											</button>
										</div>
									</div>
								}
								{
									this.state.probabilityEditMode &&
									<div className={this.props.cellHeight * this.props.scaleY > 70 ? "additional-info-row row" : "additional-info-row hidden row"}>
										<div className="p-1 col-md-7">
											<input className="probability-range" type="range" min="0" max="100"
											       value={this.state.newProbability}
											       step="1"
											       onChange={
												       (e) => {
													       e.stopPropagation();
													       this.setState({
														                     newProbability: e.target.value
													                     });
												       }
											       }/>
										</div>
										<div className="p-0 col-md-2">
											<label className="probability-new-value">{this.state.newProbability}</label>
										</div>
										<div className="p-0 mr-1 col-md-1">
											<button className="probability-edit-button accept"
											        onClick={
												        (e) => {
													        e.stopPropagation();
													        this.props.onEventProbabilityChange(this.props.event,
													                                            this.props.event.average_probability,
													                                            this.state.newProbability)
													        this.setState({
														                      probabilityEditMode: false
													                      });
												        }
											        }>
												<Glyphicon glyph="ok" className="c-glyph-icon"/>
											</button>
										</div>
										<div className="p-0 col-md-1">
											<button className="probability-edit-button cancel"
											        onClick={
												        (e) => {
													        e.stopPropagation();
													        this.setState({
														                      probabilityEditMode: false
													                      });
												        }
											        }>
												<Glyphicon glyph="remove" className="c-glyph-icon"/>
											</button>
										</div>
									</div>
								}
							</div>
						</div>
					)
				}
			</div>
		)
	}
}

export default EventControl