import React from "react";

class GridRow extends React.PureComponent {

    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            if (this.props.invalidate) {
                this.props.invalidate(this.props.rowIndex)
            }
        }
    }

    render() {
        return (
            <div className="grid-row" style={
                {
                    height: this.props.height
                }
            }>
                {this.props.children}
            </div>
        )
    }
}

export default GridRow