import {Enum} from 'enumify';

class EventState extends Enum {

    static fromCode(code) {
        for (let i = 0; i < EventState.enumValues.length; i++) {
            if (EventState.enumValues[i].code === code) {
                return EventState.enumValues[i];
            }
        }
    }
}

EventState.initEnum({
    COMPLETED: {
        code: 0,
        text: "Yes"
    },
    UNCOMPLETED: {
        code: 1,
        text: "No"
    },
    WAITING: {
        code: 2,
        text: "Waiting"
    }
});

class EventDesired extends Enum {

    static fromCode(code) {
        for (let i = 0; i < EventDesired.enumValues.length; i++) {
            if (EventDesired.enumValues[i].code === code) {
                return EventDesired.enumValues[i];
            }
        }
    }
}

EventDesired.initEnum({
    YES: {
        code: true,
        text: "Yes"
    },
    NO: {
        code: false,
        text: "No"
    }
});

class EventRelationType extends Enum {

    static fromCode(code) {
        for (let i = 0; i < EventRelationType.enumValues.length; i++) {
            if (EventRelationType.enumValues[i].code === code) {
                return EventRelationType.enumValues[i];
            }
        }
    }
}

EventRelationType.initEnum({
    INFORMATIVE: {
        code: 0,
        text: "Informative"
    },
    BLOCKING: {
        code: 1,
        text: "Blocking"
    }
});

export {
    EventState,
    EventDesired,
    EventRelationType
}