import React from 'react';
import {Button, Col, ControlLabel, Form, FormGroup, Modal} from 'react-bootstrap';
import DateUtils from "../../utils/date";


class AttachmentForm extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            name: props.attachment ? props.attachment.name : "",
            size: props.attachment ? props.attachment.size : "",
            ext: props.attachment ? props.attachment.extension : "",
            description: props.attachment ? props.attachment.description : "",
            base64String: null,
            showProgress: false,
            editable: !props.attachment
        };
    }

    render() {
        return(
            <Modal show={this.props.show} onHide={this.props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Attachment Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form horizontal={true}>
                        <FormGroup>
                            <Col componentClass={ControlLabel} sm={2}>
                                Name
                            </Col>
                            <Col sm={6}>
                                <input className="form-control"
                                       type="text"
                                       value={this.state.name ? `${this.state.name}  (${this.state.size})` : ""}
                                       readOnly={true}/>
                            </Col>
                            {
                                this.state.editable && !this.state.showProgress && (
                                    <Col sm={4}>
                                        <input type="file"
                                               id="fileInput"
                                               ref="fileInput"
                                               style={{display: "none"}}
                                               onChange={this.handleFiles}/>
                                        <Button bsClass="btn action-button pull-right"
                                                onClick={this.handleFileButton}>
                                            File
                                        </Button>
                                    </Col>
                                )
                            }
                            {
                                this.state.showProgress && (
                                    <Col componentClass={ControlLabel} sm={3} style={{color: "blue"}}>
                                        Uploading...
                                    </Col>
                                )
                            }
                        </FormGroup>
                        <FormGroup>
                            <Col componentClass={ControlLabel} sm={2}>
                                Description
                            </Col>
                            <Col sm={10}>
                                <textarea className="form-control"
                                          rows="5"
                                          onChange={this.setEventValue.bind(this, 'description')}
                                          value={this.state.description}/>
                            </Col>
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button bsClass="btn action-button" onClick={this.handleSaveButton}>Save</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    setEventValue(field, event) {
        //If the input fields were directly within this
        //this component, we could use this.refs.[FIELD].value
        //Instead, we want to save the data for when the form is submitted
        const object = {};
        object[field] = event.target.value;
        this.setState(object);
    }

    setEventChecked(field, event) {
        //If the input fields were directly within this
        //this component, we could use this.refs.[FIELD].value
        //Instead, we want to save the data for when the form is submitted
        const object = {};
        object[field] = event.target.checked;
        this.setState(object);
    }

    setValue(field, value) {
        //If the input fields were directly within this
        //this component, we could use this.refs.[FIELD].value
        //Instead, we want to save the data for when the form is submitted
        const object = {};
        object[field] = value;
        this.setState(object);
    }

    handleSaveButton = () => {

        const attachment = this.props.attachment ? this.props.attachment : {};

        attachment.name = this.state.name;
        attachment.size = this.state.size;
        attachment.extension = this.state.ext;
        attachment.uppload_date = DateUtils.convertToISOUTCDatetime(new Date());
        attachment.description = this.state.description;
        attachment.file_data = this.state.base64String;

        this.props.onSave(attachment);
    };

    handleFileButton = () => {
        this.refs.fileInput.click();
    };

    handleFiles = (event) => {

        const file = event.target.files[0];

        const reader = new FileReader();

        reader.onload = () => {

            const base64String = reader.result.split(',').pop();

            this.setState({
                name: file.name,
                size: file.size,
                ext: file.name.split('.').pop(),
                base64String: base64String
            });
        };

        reader.onloadstart = () => {
            this.setState({
                showProgress: true
            });
        };

        reader.onloadend = () => {
            this.setState({
                showProgress: false
            });
        };

        reader.readAsDataURL(file);
    };
}

export default AttachmentForm;