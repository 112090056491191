import {AUTH_URL} from "../constants";
import Utils from "../utils/utils";

export const auth = {
	scope:  AUTH_URL.replace('/', ''),
	logout: {
		url: AUTH_URL + '/logout',
		method: 'post',
	},
	csrf: {
		url: AUTH_URL + '/csrf',
		method: 'get'
	},
	oauth2: {
		authorize: {
			url: AUTH_URL + '/oauth/authorize',
			method: 'post',
			data: (clientId, redirectURL, state, responseType = 'code') => {

				const data = {};
				data.client_id = clientId;
				data.response_type = responseType;

				if (redirectURL && redirectURL !== '') {
					data.redirect_url = redirectURL;
				}

				if (state) {
					data.state = state;
				}

				return data;
			}
		},
		token: {
			url: AUTH_URL + '/oauth/token',
			method: 'post',
			data: (client_id, redirect_uri, code) => {

				const data = {};
				data.client_id = client_id;
				data.grant_type='authorization_code';
				data.code = code;
				data.redirect_url = redirect_uri

				let querystring = Utils.objectToQuerystring(data);

				return querystring.slice(1, querystring.length);
			}
		},
		refresh: {
			url: AUTH_URL + '/oauth/token',
			method: 'post',
			data: (client_id, client_secret, refresh_token) => {

				const data = {};
				data.client_id = client_id;
				data.grant_type='refresh_token';
				data.client_secret = client_secret;
				data.refresh_token = refresh_token;

				let querystring = Utils.objectToQuerystring(data);

				return querystring.slice(1, querystring.length);
			}
		}
	},
	authentication: {
		url: AUTH_URL + '/authentication',
		method: 'get',
		build: (access_token = "") => {
			return {
				access_token: access_token
			};
		}
	}
};