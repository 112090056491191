import {eventSourceService, projectService} from '../services';

export function appedEventSource(data) {
	return undefined;
}


export const actionTypes = {

	prefix: 'EVENT_SOURCE',

	REQUEST_EVENT_SOURCES: 'REQUEST_EVENT_SOURCES',
	RECEIVE_EVENT_SOURCES: 'RECEIVE_EVENT_SOURCES',
	REQUEST_PROJECT_EVENT_SOURCES: 'REQUEST_PROJECT_EVENT_SOURCES',
	RECEIVE_PROJECT_EVENT_SOURCES: 'RECEIVE_PROJECT_EVENT_SOURCES',
	SELECT_EVENT_SOURCE: 'SELECT_EVENT_SOURCE',
	REQUEST_EVENT_SOURCE: 'REQUEST_EVENT_SOURCE',
	RECEIVE_EVENT_SOURCE: 'RECEIVE_EVENT_SOURCE',
	APPEND_ITEM: 'APPEND_ITEM',
	INSERT_ITEM: 'INSERT_ITEM',
	START_OPERATION: 'START_OPERATION',
	END_OPERATION: 'END_OPERATION',

	get: function (key) {
		return this.prefix + '_' + this[key];
	}
};

export const fetchEventSources = () => {
	return (dispatch, getState) => {
		if (!getState().eventSourcesReducer.isPending) {
			dispatch(requestEventSources());
			eventSourceService.getAll()
			                  .then(sources_proxy => {
				                  dispatch(receiveEventSources(sources_proxy.data));
			                  })
		}
	}
};

export const requestEventSources = () => {
	return {
		type: actionTypes.get(actionTypes.REQUEST_EVENT_SOURCES)
	}
};

export const receiveEventSources = (response) => {
	return {
		type: actionTypes.get(actionTypes.RECEIVE_EVENT_SOURCES),
		data: response
	}
};

export const fetchProjectEventSources = (projectId) => {
	return (dispatch, getState) => {
		dispatch(requestProjectEventSources());
		projectService.getEventSources(projectId)
		              .then(sources_proxy => {
			              dispatch(receiveProjectEventSources(sources_proxy.data));
		              })
	}
};

export const requestProjectEventSources = () => {
	return {
		type: actionTypes.get(actionTypes.REQUEST_PROJECT_EVENT_SOURCES)
	}
};

export const receiveProjectEventSources = (response) => {
	return {
		type: actionTypes.get(actionTypes.RECEIVE_PROJECT_EVENT_SOURCES),
		data: response
	}
};

export const selectEventSource = (eventSource) => {
	return {
		type: actionTypes.get(actionTypes.SELECT_EVENT_SOURCE),
		data: eventSource
	}
};

export const fetchEventSource = (id) => {
	return (dispatch, getState) => {
		if (!getState().eventSourcesReducer.isPending) {
			dispatch(requestEventSource());
			eventSourceService.get(id)
			                  .then(source_proxy => {
				                  dispatch(receiveEventSource(source_proxy.data));
			                  })
		}
	}
};

export const requestEventSource = () => {
	return {
		type: actionTypes.get(actionTypes.REQUEST_EVENT_SOURCE)
	}
};

export const receiveEventSource = (response) => {
	return {
		type: actionTypes.get(actionTypes.RECEIVE_EVENT_SOURCE),
		data: response
	}
};

export const createEventSource = (eventSource) => {
	return (dispatch, getState) => {

		dispatch(receiveEventSource(null));

		eventSourceService.save(eventSource)
		                  .then(source_proxy => {
			                  dispatch(fetchEventSources());
		                  })
	}
};

export const appendEventSource = (data) => {
	return {
		type: actionTypes.get(actionTypes.APPEND_ITEM),
		data: data
	}
};

export const insertEventSource = (data) => {
	return {
		type: actionTypes.get(actionTypes.INSERT_ITEM),
		data: data
	}
};

export const saveEventSource = (eventSource) => {
	return (dispatch, getState) => {

		dispatch(receiveEventSource(null));

		eventSourceService.save(eventSource)
		                  .then(source_proxy => {
			                  dispatch(fetchEventSources());
		                  })
	}
};

export const deleteEventSource = (id) => {
	return (dispatch, getState) => {
		eventSourceService.delete(id)
		                  .then(source_proxy => {
			                  dispatch(fetchEventSources());
		                  })
	}
};

export const startOperation = (operation) => {
	return {
		type: actionTypes.get('START_OPERATION'),
		data: operation
	}
};

export const endOperation = (operation) => {
	return {
		type: actionTypes.get('END_OPERATION'),
		data: operation
	}
};