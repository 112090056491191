import clientFactory from "../factories/http_client";
import {API} from "../api/api";

class EventSourceService {

	/** @return  Promise<AxiosResponse<T>> */
	get = (id) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.event_sources.id.method,
			                             url: API.data.event_sources.id.url(id)
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	getAll = () => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.event_sources.method,
			                             url: API.data.event_sources.url
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	create = (source) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.event_sources.create.method,
			                             url: API.data.event_sources.create.url,
			                             data: API.data.event_sources.create.build(source),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	save = (source) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.event_sources.save.method,
			                             url: API.data.event_sources.save.url,
			                             data: API.data.event_sources.save.build(source),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	delete = (sourceId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.event_sources.delete.method,
			                             url: API.data.event_sources.delete.url,
			                             data: API.data.event_sources.delete.build(sourceId),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};
}

export default EventSourceService;