import React from 'react';
import vis from 'vis';
import Moment from 'moment';

import BaseComponent from 'components/base/base-component';
import Utils from 'utils/utils';

const utc = Moment.utc;

const NODES_IN_LINE = 8;
const HORIZONTAL_SPACE = 240;
const VERTICAL_SPACE = 160;
const NODE_WIDTH = 180;


class ScenarioMap
	extends BaseComponent {

	constructor(props) {
		super(props);

		this.state = {
			network: null
		};
	}

	render() {

		const scenario = this.props.scenario;

		if (this.state.network) {
			this.state.network.setData(this.buildNetwork(scenario));
		}

		return (
			<div id={`scenarioMapAnchor-${scenario.id}`}
			     ref="scenarioMapAnchor"
			     style={{backgroundColor: '#f4f4f4'}}
			     className="h-100">
			</div>
		);
	}

	onSelectNode(params) {
		if (params.nodes[0]) {
			this.props.onSelectNode(params.nodes[0]);
		}
	}

	onDeselectNode(params) {
		this.props.onDeselectNode();
	}

	onDoubleClick(params) {
		if (params.nodes[0]) {
			this.props.onDoubleClick(params.nodes[0]);
		}
	}

	buildNetwork(scenario) {

		const levelCount = Math.ceil(scenario.events.length / 8);
		const offsetX = (NODES_IN_LINE / 2) - 1;
		const offsetY = levelCount / 2;

		const nodes = new vis.DataSet(scenario.events
		                                      .sort((a, b) => {
			                                      a = utc(a.date);
			                                      b = utc(b.date);
			                                      if (a.isBefore(b)) return -1;
			                                      else if (a.isAfter(b)) return 1;
			                                      else {
				                                      a = utc(a.create_date);
				                                      b = utc(b.create_date);
				                                      if (a.isBefore(b)) return -1;
				                                      else if (a.isAfter(b)) return 1;
				                                      else return 0;
			                                      }
		                                      })
		                                      .map(
			                                      (event, index) => {
				                                      let posX = index % NODES_IN_LINE;
				                                      let posY = Math.floor(index / NODES_IN_LINE) + 1;

				                                      const date = Utils.convertDatetime(event.date, "dd.mm.yyyy");
				                                      const impact = event.average_impact ? event.average_impact : 0;
				                                      const probability = event.average_probability ? event.average_probability : 100;

				                                      return {
					                                      id: event.id,
					                                      level: posY,
					                                      x: (posX - offsetX) * HORIZONTAL_SPACE,
					                                      y: (posY - offsetY) * -VERTICAL_SPACE,
					                                      physics: false,
					                                      label: `${event.title}
                            (${event.factor.title.substr(0, 20)}...)
                            ${date}    ${impact}    ${probability}%`,
					                                      title: event.title,
					                                      shape: 'box',
					                                      margin: 10,
					                                      font: {
						                                      color: 'rgb(93, 97, 98)',
						                                      bold: {
							                                      mod: 'bold'
						                                      }
					                                      },
					                                      widthConstraint: {
						                                      minimum: NODE_WIDTH,
						                                      maximum: NODE_WIDTH,
					                                      },
					                                      heightConstraint: {
						                                      minimum: 35
					                                      },
					                                      color: {
						                                      background: 'rgb(235, 244, 249)',
						                                      // border: NODE_LEVEl_COLORS[factor.depth],
						                                      highlight: {
							                                      background: 'rgb(50, 200, 200)'
						                                      },
						                                      hover: {
							                                      background: 'rgb(235, 244, 249)',
							                                      // border: NODE_LEVEl_COLORS[factor.depth],
						                                      }
					                                      }
				                                      };
			                                      }
		                                      ));

		nodes.add({
			          id: 0,
			          level: 0,
			          x: 0,
			          y: offsetY * VERTICAL_SPACE,
			          physics: false,
			          label: scenario.title,
			          title: scenario.narrative,
			          shape: 'box',
			          margin: 10,
			          font: {
				          size: 16,
				          color: 'rgb(93, 97, 98)',
				          bold: {
					          mod: 'bold',
					          size: 16
				          }
			          },
			          widthConstraint: {
				          minimum: 300,
				          maximum: 400,
			          },
			          heightConstraint: {
				          minimum: 35
			          },
			          color: {
				          background: 'rgb(235, 244, 249)',
				          // border: NODE_LEVEl_COLORS[3],
				          highlight: {
					          background: 'rgb(50, 200, 200)'
				          },
				          hover: {
					          background: 'rgb(235, 244, 249)',
					          // border: NODE_LEVEl_COLORS[3],
				          }
			          }
		          });

		let edgesArray = [];
		scenario.events.forEach(event => {
			edgesArray.push({
				                from: 0,
				                to: event.id
			                });
		});

		const edges = new vis.DataSet(edgesArray);

		return {
			nodes: nodes,
			edges: edges
		};
	}

	componentDidMount() {

		const options = {
			interaction: {
				dragNodes: false,
				navigationButtons: true,
				hover: true
			},
			edges: {
				dashes: true
			}
		};

		const network = new vis.Network(this.refs.scenarioMapAnchor, {}, options);

		const networkCanvas = this.refs.scenarioMapAnchor.getElementsByTagName("canvas")[0];

		function changeCursor(newCursorStyle) {
			networkCanvas.style.cursor = newCursorStyle;
		}

		network.on('selectNode', this.onSelectNode);
		network.on('deselectNode', this.onDeselectNode);
		network.on('doubleClick', this.onDoubleClick);
		network.on('hoverNode', function () {
			changeCursor('pointer');
		});
		network.on('blurNode', function () {
			changeCursor('auto');
		});

		this.setState({
			              network: network,
		              });
	}
}

export default ScenarioMap;