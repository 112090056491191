import React from 'react';
import {Button, Checkbox, Col, ControlLabel, Form, FormGroup, Modal, Table} from 'react-bootstrap';
import {Combobox} from 'react-widgets';

import {EventRelationType} from "../event/event-models";

class EventsRelationDialog extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			rightEvent: props.rightEvent,
			comment: "",
			relationType: EventRelationType.INFORMATIVE,
			relativeProbability: false
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
		this.setState({
			              rightEvent: nextProps ? nextProps.rightEvent : null
		              });
	}

	render() {

		let left_event_possible_values;

		if (this.props.items && this.props.leftEvent) {
			left_event_possible_values = this.props.items.filter((item) => {
				return item.id !== this.props.leftEvent.id
			});
		} else {
			left_event_possible_values = this.props.items;
		}

		return (
			<Modal show={this.props.show} onHide={this.props.onCancel}>
				<Modal.Header closeButton={true}>
					<Modal.Title>Events Relation Form</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form horizontal={true}>
						{
							!this.props.rightEvent &&
							<FormGroup>

								<Col componentClass={ControlLabel} sm={2}>
									Event:
								</Col>
								<Col sm={10}>
									<Combobox onChange={this.setValue.bind(this, 'rightEvent')}
									          data={left_event_possible_values}
									          caseSensitive={false}
									          textField='title'
									          value={this.state.rightEvent}
									          groupBy={item => item.factor ? item.factor.title : ""}
									          filter={'contains'}
									/>
								</Col>
							</FormGroup>
						}
						<FormGroup>
							<Col componentClass={ControlLabel} md={3}>
								Comment:
							</Col>
							<Col md={9}>
                                <textarea className="form-control"
                                          onChange={this.setEventValue.bind(this, 'comment')}
                                          value={this.state.comment}
                                />
							</Col>
						</FormGroup>
						<FormGroup>
							<Col componentClass={ControlLabel} md={3}>
								Relation type:
							</Col>
							<Col md={4}>
								<Combobox onChange={this.setValue.bind(this, 'relationType')}
								          defaultValue={this.state.relationType ? this.state.relationType : ''}
								          data={EventRelationType.enumValues}
								          caseSensitive={false}
								          textField='text'
								          value={this.state.relationType}
								/>
							</Col>
						</FormGroup>
						{
							this.state.relationType === EventRelationType.BLOCKING &&
							(
								<FormGroup>
									<Col componentClass={ControlLabel} md={3}>
										Relative probability:
									</Col>
									<Col md={1}>
										<Checkbox onChange={this.setEventChecked.bind(this, 'relativeProbability')}
										          value={this.state.relativeProbability}/>
									</Col>
								</FormGroup>
							)
						}
						<Table>
							<thead>
							<tr>
								<th>Event title</th>
								<th>Event key</th>
							</tr>
							</thead>
							<tbody>
							{
								this.props.leftEvent &&
								(
									<tr key={this.props.leftEvent.id}>
										<td>{this.props.leftEvent.title}</td>
										<td>{this.props.leftEvent.key}</td>
									</tr>
								)
							}
							{
								this.state.rightEvent &&
								(
									<tr key={this.state.rightEvent.id}>
										<td>{this.state.rightEvent.title}</td>
										<td>{this.state.rightEvent.key}</td>
									</tr>
								)
							}
							</tbody>
						</Table>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button bsClass="btn action-button semi pull-right" onClick={this.props.onCancel}>Cancel</Button>
					<Button bsClass="btn action-button semi pull-right" onClick={this.saveButtonHandler}>Save</Button>
				</Modal.Footer>
			</Modal>
		)
	}

	setEventValue(field, event) {
		//If the input fields were directly within this
		//this component, we could use this.refs.[FIELD].value
		//Instead, we want to save the data for when the form is submitted
		const object = {};
		object[field] = event.target.value;

		this.setState(object);
	}

	setEventChecked(field, event) {
		//If the input fields were directly within thiss
		//this component, we could use this.refs.[FIELD].value
		//Instead, we want to save the data for when the form is submitted
		const object = {};
		object[field] = event.target.checked;

		this.setState(object);
	}

	setValue(field, value) {
		//If the input fields were directly within this
		//this component, we could use this.refs.[FIELD].value
		//Instead, we want to save the data for when the form is submitted
		const object = {};
		object[field] = value;

		this.setState(object);
	}

	saveButtonHandler = () => {

		const eventsRelation = this.props.relation ? this.props.relation : {};

		eventsRelation.left_event = this.props.leftEvent;
		eventsRelation.right_event = this.state.rightEvent;
		eventsRelation.comment = this.state.comment;
		eventsRelation.relation_kind = this.state.relationType.code;
		eventsRelation.relative_probability = this.state.relativeProbability;

		this.props.onSave(eventsRelation);
	}
}

export default EventsRelationDialog;