import {authorities} from "../mixins/authorities";
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import * as scenariosActions from "../actions/scenarios";
import ScenarioView from "../views/scenario";
import * as flagsActions from "../actions/flags";
import * as eventActions from "../actions/events";

const mapStateToProps = (state, ownProps) => {
	return {
		project: state.projectReducer.project,
		scenario: state.scenariosReducer.scenario,
		selectedEvent: state.scenariosReducer.scenario ? state.scenariosReducer.scenario.selectedEvent : null,
		selectedLink: state.scenariosReducer.scenario ? state.scenariosReducer.scenario.selectedLink : null,
		link: state.scenariosReducer.scenario ? state.scenariosReducer.scenario.link : null,
		selectedAttachment: state.scenariosReducer.scenario ? state.scenariosReducer.scenario.selectedAttachment : null,
		attachment: state.scenariosReducer.scenario ? state.scenariosReducer.scenario.attachment : null,
		flags: state.flagsReducer.flags,
		events: state.eventsReducer.events,
		scenarioOperation: state.scenariosReducer.operation,
		...authorities(state.authenticationReducer.authentication)
	}
};

const mapDispatchToProps = (dispatch, state) => {
	return {
		loadScenario: (project, scenarioId) => {
			dispatch(scenariosActions.fetchScenario(project.id, scenarioId))
		},
		save: (project, scenario) => {
			if (scenario.id) {
				dispatch(scenariosActions.saveScenario(project.id, scenario));
			} else {
				dispatch(scenariosActions.createScenario(project.id, scenario));
			}
		},
		selectLink: (link) => {
			dispatch(scenariosActions.selectLink(link));
		},
		editLink: (project, scenario, link) => {
			dispatch(scenariosActions.fetchLink(project.id, scenario.id, link.id));
		},
		saveLink: (project, scenario, link) => {
			if (link.id) {
				dispatch(scenariosActions.saveLink(project.id, scenario.id, link));
			} else {
				link.scenario = {
					id: scenario.id,
					retrospectionId: scenario.retrospection_id
				};
				dispatch(scenariosActions.createLink(project.id, scenario.id, link));
			}
		},
		deleteLink: (project, scenario, link) => {
			dispatch(scenariosActions.deleteLink(project.id, scenario.id, link.id));
		},
		clearLink: () => {
			dispatch(scenariosActions.receiveLink(null));
		},
		selectAttachment: (attachment) => {
			dispatch(scenariosActions.selectAttachment(attachment));
		},
		editAttachment: (project, scenario, attachment) => {
			dispatch(scenariosActions.fetchAttachment(project.id, scenario.id, attachment.id));
		},
		saveAttachment: (project, scenario, attachment) => {
			if (attachment.id) {
				dispatch(scenariosActions.saveAttachment(project.id, scenario.id, attachment));
			} else {
				attachment.scenario = {
					id: scenario.id,
					retrospectionId: scenario.retrospection_id
				};
				dispatch(scenariosActions.createAttachment(project.id, scenario.id, attachment));
			}
		},
		deleteAttachment: (project, scenario, attachment) => {
			dispatch(scenariosActions.deleteAttachment(project.id, scenario.id, attachment.id));
		},
		clearAttachment: () => {
			dispatch(scenariosActions.receiveAttachment(null));
		},
		loadEvents: (project) => {
			dispatch(eventActions.fetchEvents(project.id, project.eventsMap.id))
		},
		loadFlags: () => {
			dispatch(flagsActions.fetchFlags());
		},
		addEvent: (project, scenario, event) => {

			if (!scenario.events) {
				scenario.events = [];
			}

			scenario.events.push(event);

			dispatch(scenariosActions.saveScenario(project.id, scenario));
		},
		selectEvent: (event) => {
			dispatch(scenariosActions.selectEvent(event));
		},
		deleteEvent: (project, scenario, event) => {

			scenario.events = scenario.events.filter(element => element.id !== event.id);

			dispatch(scenariosActions.saveScenario(project.id, scenario));
		},
		clearOperation: () => {
			dispatch(scenariosActions.startOperation(null));
		}
	}
};

const ScenarioContainer = connect(mapStateToProps, mapDispatchToProps)(ScenarioView);

export default withRouter(ScenarioContainer);