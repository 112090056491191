import React from 'react';
import {Button, Col, ControlLabel, Form, FormGroup, Modal} from 'react-bootstrap';
import DropdownTreeSelect from 'react-dropdown-tree-select';

import BaseForm from './base-form';
import Utils from 'utils/utils';


class TreeSelectionForm extends BaseForm {

    constructor(props) {
        super(props);

        this.modalProps = Utils.getModalProps(props);

        const items = this.props.items ? this.props.items : [];
        const item = this.props.default ? this.props.default : null;
        const field = this.props.field ? this.props.field : null;

        const convert = (x) => {
            return {
                id: x.id,
                label: field ? x[field] : "untitled",
                value: x,
                checked: item ? x.id == item.id : false,
                children: x.children ? x.children.map(y => convert(y)) : []
            }
        };

        this.state = {
            items: items,
            item: item,
            field: field,
            data: items.map(x => convert(x))
        };
    }

    onChange(currentNode, selectedNodes) {
        const selected = selectedNodes[0] ? currentNode : null;

        const select = (x) => {
            x.checked = selected ? x.id == selected.id : false;
            x.children.forEach(y => select(y));
            return x;
        };

        const data = this.state.data.map(x => select(x));

        this.setState({
            data: data,
            item: selected ? selected.value : null
        });
    }

    render() {
        return(
            <Modal {...this.modalProps}>
                <Modal.Header closeButton>
                    <Modal.Title>Choose item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form horizontal={true}>
                        <FormGroup>
                            <Col componentClass={ControlLabel} sm={2}>
                                Select
                            </Col>
                            <Col sm={10}>
                                <DropdownTreeSelect
                                    data={this.state.data}
                                    onChange={this.onChange}
                                    className="c-dropdown-tree-select"
                                />


                                {/*<Combobox onChange={this.setValue.bind(this, 'item')}*/}
                                          {/*defaultValue={this.state.item ? this.state.item : ''}*/}
                                          {/*data={this.state.items}*/}
                                          {/*caseSensitive={false}*/}
                                          {/*textField={item => {*/}
                                              {/*if (item) {*/}
                                                  {/*if (this.state.field) {*/}
                                                      {/*return item[this.state.field];*/}
                                                  {/*} else {*/}
                                                      {/*return typeof item === 'string' ? item : item.toString();*/}
                                                  {/*}*/}
                                              {/*} else {*/}
                                                  {/*return "";*/}
                                              {/*}*/}
                                          {/*}}*/}
                                          {/*value={this.state.item}/>*/}
                            </Col>
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button bsClass="btn action-button semi pull-right" onClick={this.props.onCancel}>
                        Cancel
                    </Button>
                    <Button bsClass="btn action-button semi pull-right" onClick={this.handleSaveButton}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    handleSaveButton() {
        if (!this.state.item) {
            alert("You must choose item!");
        } else {
            this.props.onSave(this.state.item);
        }
    }
}

export default TreeSelectionForm;