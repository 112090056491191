import {actionTypes} from "../actions/authorities";

const initialState = {
    isPending: false,
    invalidated: true,
    authorities: null
};

function authoritiesReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.REQUEST_AUTHORITIES:
            return {
                ...state,
                isPending: true
            };
        case actionTypes.RECEIVE_AUTHORITIES:
            return {
                ...state,
                authorities: action.data,
                isPending: false
            };
        default:
            return state
    }
}

export default authoritiesReducer