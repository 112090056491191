import React from 'react';
import {Combobox} from 'react-widgets';
import {Button, FormGroup, Glyphicon, Navbar, OverlayTrigger, Tooltip} from 'react-bootstrap';

import BaseComponent from 'components/base/base-component';


class SimpleSearchBar extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = {
            items: props.items ? props.items : [],
            field: props.field,
            value: null
        };
    }

    handleSearch() {
        if (this.state.value instanceof Object) {
            this.props.handleSearch(this.state.value);
        }
    }

    handleSelect(value) {
        this.props.handleSearch(value);
    }

    handleChange(value) {
        this.setState({value: value})
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            items: nextProps.items ? nextProps.items : [],
            field: nextProps.field,
            value: nextProps ? nextProps.value : null
        });
    }

    render() {
        return (
            <Navbar.Form pullRight componentClass="form" className={this.props.className}>
                <FormGroup>
                        <Combobox data={this.state.items}
                                  filter={(item, value) => {
                                      const reg = new RegExp(value, 'i');
                                      const title = item[this.state.field];
                                      return title.search(reg) !== -1;
                                  }}
                                  caseSensitive={false}
                                  value={this.state.value}
                                  onChange={this.handleChange}
                                  onSelect={this.handleSelect}
                                  textField={this.state.field}
                                  groupBy={item => item.factor ? item.factor.title : ""}
                                  placeholder="Search"
                                  style={{width: "200px"}}
                        />

                </FormGroup>
                <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip">Search</Tooltip>}>
                    <Button bsClass="btn bar-button" onClick={this.handleSearch} className="bar-button">
                        <Glyphicon glyph="search"/>
                    </Button>
                </OverlayTrigger>
            </Navbar.Form>
        );
    }
}

export default SimpleSearchBar;
