import {actionTypes} from "../actions/operations";

const initialState = {
    report: null
};

function operation(state = initialState, action) {
    switch (action.type) {
        case actionTypes.get(actionTypes.RECEIVE_REPORT):
            return {
                ...state,
                report: action.data
            };
        default:
            return state
    }
}

export default operation