import React from 'react';
import {connect} from "react-redux";
import EventBusConnector from "./event-bus-connector";
import * as notifications_actions from "../actions/notifications";

class NotificationReceiver
	extends React.Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {

		this.connector = new EventBusConnector();

		if (this.props.csrf_token && this.props.projects && this.props.token_info && !this.props.token_info.error) {
			this.connectAndSubscribe(this.props.projects, this.props.token_info.access_token, this.props.csrf_token);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.csrf_token && this.props.projects && this.props.token_info && !this.props.token_info.error) {
			this.connectAndSubscribe(this.props.projects, this.props.token_info.access_token, this.props.csrf_token);
		}
	}

	componentDidCatch(error, errorInfo) {
		this.setState({
			              error: true
		              })
	}

	connectAndSubscribe = (projects, access_token, csrf_token) => {

		if (!projects) {
			return;
		}

		if (csrf_token) {

			const headers = {};
			headers[csrf_token.headerName] = csrf_token.token;

			this.connector.connect(headers, access_token, () => {
				projects.forEach((project) => {
					this.connector.subscribe(`/topic/project/${project.id}/events/`, (frame, headers) => {
						this.props.receiveNotification(JSON.parse(frame.body));
					}, headers);
				});
			});
		}
	};

	UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
		if (nextProps.csrf_token && nextProps.token_info && !this.props.token_info.error) {
			this.connectAndSubscribe(nextProps.projects, this.props.token_info.access_token, this.props.csrf_token);
		}
	}

	render() {
		return (
			<div/>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		authentication: state.authenticationReducer.authentication,
		token_info: state.servers.token_info,
		csrf_token: state.servers.csrf_tokens['data'],
		projects: state.projectReducer.projects ? state.projectReducer.projects : (state.projectReducer.project ? [state.projectReducer.project] : null)
	}
};

const mapDispatchToProps = (dispatch, state) => {
	return {
		receiveNotification: (notification) => {
			const receiveNotifications = (notification) => {
				return (dispatch, getState) => {

					let project = getState().projectReducer.project;

					if (project) {
						if (notification.project_id == project.id) {
							dispatch(notifications_actions.appendItems([notification]));
						}
					} else {
						dispatch(notifications_actions.appendItems([notification]));
					}
				}
			}
			dispatch(receiveNotifications(notification))
		}
	}
};

const ConnectedWebSocketReceiver = connect(mapStateToProps, mapDispatchToProps)(NotificationReceiver);

export default ConnectedWebSocketReceiver;
