import {Enum} from "enumify";

class Period extends Enum {

    static fromCode(code) {
        for (let i = 0; i < Period.enumValues.length; i++) {
            if (Period.enumValues[i].code === code) {
                return Period.enumValues[i];
            }
        }
    }
}

Period.initEnum({
    NOW: {
        code: 0,
        text: "Today"
    },
    THREE_DAY: {
        code: 1,
        text: "Three days"
    },
    WEEK: {
        code: 2,
        text: "One week"
    },
    MONTH: {
        code: 3,
        text: "One month"
    },
    All: {
        code: 4,
        text: "All time"
    }
});

export default Period