import React from 'react';
import {Button, Glyphicon, OverlayTrigger, Tooltip} from 'react-bootstrap';

import {PredicateRelation as PR} from './filter-models';
import {FilterPredicate} from './filter-predicate-form';
import BaseComponent from 'components/base/base-component';


class FilterGroupPredicate extends BaseComponent {

    constructor(props) {
        super(props);

        this.predicate = props.predicate;
        this.extended = props.extended;

        this.state = {
            children: this.predicate.children ? this.predicate.children : [],
            relation: this.predicate.relation,

            relations: []
        };
    }

    componentWillUpdate(nextProps, nextState) {
        this.predicate.children = nextState.children;
    }

    render() {
        return (
            <div className={`filter-group-predicate ${this.extended ? "removable-group-predicate" : ""}`}>
                <div className="button-bar">
                    <label className="button-bar-item">
                        <strong>{this.state.relation}</strong>
                    </label>
                    <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip">Add predicate</Tooltip>}>
                        <Button bsClass="btn bar-button button-bar-item" onClick={this.addPredicateButtonHandler}>
                            <Glyphicon glyph="plus" />
                        </Button>
                    </OverlayTrigger>
                    {
                        this.extended && (
                            <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip">Add And Group</Tooltip>}>
                                <Button bsClass="btn bar-button button-bar-item" onClick={this.addAndGroupButtonHandler}>
                                    AND Group
                                </Button>
                            </OverlayTrigger>
                        )
                    }
                    {
                        this.extended && (
                            <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip">Add Or Group</Tooltip>}>
                                <Button bsClass="btn bar-button button-bar-item" onClick={this.addOrGroupButtonHandler}>
                                    OR Group
                                </Button>
                            </OverlayTrigger>
                        )
                    }
                </div>
                {
                    this.state.children[0] && this.state.children.map((child, index) => {
                        switch(child.relation) {
                            case PR.AND:
                            case PR.OR: {
                                return this.createPredicateGroupWidget(child, index);
                            }
                            default: {
                                return this.createPredicateWidget(child, index);
                            }
                        }
                    })
                }
            </div>
        )
    }

    addPredicateButtonHandler() {
        const children = this.state.children;
        children.push({relation: null});
        this.setState({
            children: children
        });
    }

    addAndGroupButtonHandler() {
        const children = this.state.children;
        children.push({relation: PR.AND});
        this.setState({
            children: children
        });
    }

    addOrGroupButtonHandler() {
        const children = this.state.children;
        children.push({relation: PR.OR});
        this.setState({
            children: children
        });
    }

    removeButtonHandler(predicate) {
        const children = this.state.children;
        const index = children.indexOf(predicate);
        if (index !== -1) {
            children.splice(index, 1);
            this.setState({
                children: children
            });
        }
    }

    createPredicateWidget(predicate, index) {
        return (
            <div key={index}>
                <FilterPredicate predicate={predicate}
                                 properties={this.props.properties}
                                 getDataByType={this.props.getDataByType}
                />
                <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip">Delete</Tooltip>}>
                    <Button bsClass="btn bar-button remove-btn pull-right"
                            onClick={() => {this.removeButtonHandler(predicate)}}>
                        <Glyphicon glyph="remove" className="c-glyph-icon" />
                    </Button>
                </OverlayTrigger>
            </div>
        )
    }

    createPredicateGroupWidget(predicate, index) {
        return (
            <div key={index}>
                <FilterGroupPredicate predicate={predicate}
                                      extended={this.extended}
                                      properties={this.props.properties}
                                      getDataByType={this.props.getDataByType}
                />
                <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip">Delete</Tooltip>}>
                    <Button bsClass="btn bar-button remove-btn pull-right"
                            onClick={() => {this.removeButtonHandler(predicate)}}>
                        <Glyphicon glyph="remove" className="c-glyph-icon" />
                    </Button>
                </OverlayTrigger>
            </div>
        )
    }
}

export { FilterGroupPredicate };