import React from 'react';

import Utils from '../../../utils/utils';
import DateUtils from '../../../utils/date';
import {BOUNDS, REFS} from './future-map-models';
import TimeScaleSwitcher from './time-scale-switcher';
import Grid from "../grid/grid";
import VerticalHeader from "./vertical-header";
import HorizontalHeader from "./horizontal-header";
import ContextMenu from "./context-menu";
import EventPreview from "../../event/event-preview";
import {Button, Glyphicon} from "react-bootstrap";
import MiniMap from "./mini-map";
import CurrentDateSlider from "../current-date-slider";
import CellItemsPlaceholder from "./cell-items-placeholder";
import TrendPreview from "../../event/trend-preview";

class Map
	extends React.PureComponent {

	constructor(props) {

		super(props);

		this.default_cell_height = 90;

		this.default_cell_width = 180;

		this.state = {
			timeScale: 'months',
			scaleX: 1,
			scaleY: 1,
			gridCellsState: {},
			verticalScrollPosition: 0,
			emptyPeriodsHidden: false,
			childFactorsHidden: false,
			verticalHeaderItems: [],
			cellWidth: this.default_cell_width,
			cellHeight: this.default_cell_height,
			selectedEvents: [],
			highlightedEvents: [],
			selectedTrend: null,
			highlightedTrend: null
		};

		this.objectsPositions = {};
	}

	componentDidMount() {
		if (this.gridContainerRef) {

			if (this.state.childFactorsHidden) {

				this.flattenFactors = this.props.factors;

				this.inheritanceMap = {};

				let currentId = null;

				const childrenProvider = (parent) => {
					return parent.children;
				};

				const preOrderOperation = (treeElement, context) => {

					if (currentId !== treeElement.id) {
						this.inheritanceMap[treeElement.id] = currentId;
					}

					return true;
				};

				this.props.factors.forEach((element) => {
					currentId = element.id;
					Utils.bypassTree(element, childrenProvider, preOrderOperation)
				});
			} else {
				this.flattenFactors = Utils.getFlatFactors(this.props.factors);
			}

			const ranges = this.calculatePeriodRanges(this.props.start,
			                                          this.props.end,
			                                          this.state.timeScale);

			this.objectsPositions = this.calculateObjectPositions(this.props.events,
			                                                      this.props.trends,
			                                                      this.state.timeScale,
			                                                      ranges.visible)

			this.setState({
				              verticalHeaderItems: ranges.visible
			              });

			this.gridContainerRef.removeEventListener('scroll', this.handleScrollChange);
			this.gridContainerRef.addEventListener('scroll', this.handleScrollChange);

			const newMiniMapViewportPosition = this.calculateViewportPosition(this.gridContainerRef.scrollLeft, this.gridContainerRef.scrollTop);

			if (!this.miniMapViewportPosition ||
				(this.miniMapViewportPosition.miniMapViewportX !== newMiniMapViewportPosition.miniMapViewportX ||
					this.miniMapViewportPosition.miniMapViewportY !== newMiniMapViewportPosition.miniMapViewportY)) {

				this.miniMapViewportPosition = newMiniMapViewportPosition;

				this.setState({
					              miniMapViewportX: this.miniMapViewportPosition.miniMapViewportX,
					              miniMapViewportY: this.miniMapViewportPosition.miniMapViewportY
				              });
			}
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		const self = this;

		if (this.gridContainerRef) {

			this.gridContainerRef.removeEventListener('scroll', this.handleScrollChange);
			this.gridContainerRef.addEventListener('scroll', this.handleScrollChange);


			if (prevProps.position !== this.props.position && this.props.position) {
				this.setState({
					              initial_position: {
						              x: this.props.position.left,
						              y: this.props.position.top
					              }
				              })
			}

			if (this.props.factors && this.props.factors.length > 0 &&
				this.state.initial_position &&
				this.gridContainerRef.scrollWidth > this.state.cellWidth * this.props.factors.length) {
				self.gridContainerRef.scrollLeft = this.state.initial_position.x;
				self.gridContainerRef.scrollTop = this.state.initial_position.y;
				this.setState({
					              initial_position: null
				              })
			}

			if (prevProps.centeredEvent !== this.props.centeredEvent && this.props.centeredEvent) {

				const centeredEventDate = new Date(this.props.centeredEvent.date);

				const containingVerticalHeaderItems = this.state.verticalHeaderItems.filter(function (element) {
					return (element.start <= centeredEventDate && centeredEventDate < element.end);
				});

				const containingHorizontalHeaderItems = this.flattenFactors.filter(function (element) {
					return element.id === self.props.centeredEvent.factor.id;
				});

				if (containingVerticalHeaderItems.length > 0 && containingHorizontalHeaderItems.length > 0) {

					const cellRowIndex = this.state.verticalHeaderItems.indexOf(containingVerticalHeaderItems[0]);

					const cellColumnIndex = this.flattenFactors.indexOf(containingHorizontalHeaderItems[0]);

					setTimeout(function () {

						const gridCellsState = self.state.gridCellsState ? self.state.gridCellsState : {};

						if (!gridCellsState[Grid.cellKey(cellRowIndex, cellColumnIndex)]) {
							gridCellsState[Grid.cellKey(cellRowIndex, cellColumnIndex)] = {};
						}
						gridCellsState[Grid.cellKey(cellRowIndex,
						                            cellColumnIndex)] = {
							highlighted: true
						};

						self.gridContainerRef.scrollLeft = cellColumnIndex * self.state.cellWidth * self.state.scaleX;
						self.gridContainerRef.scrollTop = cellRowIndex * self.state.cellHeight * self.state.scaleY;

						self.setState({
							              gridCellsState: gridCellsState,
						              });
					});
				}
			}

			if (prevProps.factors !== this.props.factors ||
				prevProps.events !== this.props.events ||
				prevProps.trends !== this.props.trends ||
				(prevProps.start !== this.props.start || prevProps.end !== this.props.end) ||
				prevState.timeScale !== this.state.timeScale ||
				prevState.emptyPeriodsHidden !== this.state.emptyPeriodsHidden ||
				prevState.childFactorsHidden !== this.state.childFactorsHidden) {

				if (this.state.childFactorsHidden) {

					this.flattenFactors = this.props.factors;

					this.inheritanceMap = {};

					let currentId = null;

					const childrenProvider = (parent) => {
						return parent.children;
					};

					const preOrderOperation = (treeElement, context) => {

						if (currentId !== treeElement.id) {
							this.inheritanceMap[treeElement.id] = currentId;
						}

						return true;
					};

					this.props.factors.forEach((element) => {
						currentId = element.id;
						Utils.bypassTree(element, childrenProvider, preOrderOperation)
					});
				} else {
					this.flattenFactors = Utils.getFlatFactors(this.props.factors);
				}

				const ranges = this.calculatePeriodRanges(this.props.start,
				                                          this.props.end,
				                                          this.state.timeScale);

				this.objectsPositions = this.calculateObjectPositions(this.props.events,
				                                                      this.props.trends,
				                                                      this.state.timeScale,
				                                                      ranges.visible)

				this.trendIndexMap = this.calculateTrendIndexes()

				let mini_map_nodes = this.calculateMiniMapNodes(this.props.events, this);

				this.setState({
					              verticalHeaderItems: ranges.visible,
					              miniMapNodes: mini_map_nodes
				              });
			}

			if (prevState.verticalHeaderItems !== this.state.verticalHeaderItems) {
				if (this.gridContainerRef.clientHeight > this.state.verticalHeaderItems.length * this.default_cell_height) {
					let cellHeight = Math.floor(this.gridContainerRef.clientHeight / this.state.verticalHeaderItems.length);
					this.setState({
						              cellHeight: cellHeight,
						              miniMapViewportHeight: Math.round(this.gridContainerRef.clientHeight / (cellHeight * this.state.scaleY))
					              })
				} else {
					this.setState({
						              cellHeight: this.default_cell_height,
						              miniMapViewportHeight: Math.round(this.gridContainerRef.clientHeight / (this.default_cell_height * this.state.scaleY))
					              })
				}
			}

			if (prevProps.factors !== this.props.factors) {
				if (this.gridContainerRef.clientWidth > this.flattenFactors.length * this.default_cell_width) {
					let cellWidth = Math.floor(this.gridContainerRef.clientWidth / this.flattenFactors.length);
					this.setState({
						              cellWidth: cellWidth,
						              miniMapViewportWidth: Math.round(this.gridContainerRef.clientWidth / (cellWidth * this.state.scaleX)),
					              })
				} else {
					this.setState({
						              cellWidth: this.default_cell_width,
						              miniMapViewportWidth: Math.round(this.gridContainerRef.clientWidth / (this.default_cell_width * this.state.scaleX)),
					              })
				}
			}

			if ((prevState.scaleX !== this.state.scaleX || prevState.scaleY !== this.state.scaleY)) {
				this.setState({
					              miniMapViewportWidth: Math.round(this.gridContainerRef.clientWidth / (this.state.cellWidth * this.state.scaleX)),
					              miniMapViewportHeight: Math.round(this.gridContainerRef.clientHeight / (this.state.cellHeight * this.state.scaleY)),
				              });
			}


			if (this.props.shownEventsRelations && this.props.shownEventsRelations.length > 0) {
				this.redrawRelations(this.gridContainerRef.scrollLeft, this.gridContainerRef.scrollTop);
			}

			/*if (prevProps.currentDate !== this.props.currentDate) {

				const containingVerticalHeaderItems = this.state.verticalHeaderItems.filter((element) => {
					return (element.start <= this.props.currentDate && this.props.currentDate < element.end);
				});

				if (containingVerticalHeaderItems.length > 0) {

					const cellRowIndex = this.state.verticalHeaderItems.indexOf(containingVerticalHeaderItems[0]);

					setTimeout(() => {
						self.gridContainerRef.scrollLeft = 0;
						self.gridContainerRef.scrollTop = cellRowIndex * self.state.cellHeight * self.state.scaleY;
					})
				}
			}*/
		}

		if (prevProps.selectedEvents !== this.props.selectedEvents && this.props.selectedEvents && this.props.selectedEvents !== this.state.selectedEvents) {
			let cell_state = this.props.selectedEvents.reduce((accumulator, element) => {

				const objectPosition = this.objectsPositions[this.eventsPositionsKey(element.factor.id, element.date)];

				if (objectPosition) {

					const gridPosition = this.objectsPositions[this.eventsPositionsKey(element.factor.id, element.date)].coordinate;

					if (gridPosition) {

						let new_cells_state = {...this.state.gridCellsState}

						if (!new_cells_state[Grid.cellKey(gridPosition.rowIndex, gridPosition.columnIndex)]) {
							new_cells_state[Grid.cellKey(gridPosition.rowIndex, gridPosition.columnIndex)] = {};
						}

						accumulator[Grid.cellKey(gridPosition.rowIndex, gridPosition.columnIndex)].selected = true;

						return accumulator;
					}
				}
			}, this.state.gridCellsState);

			this.setState({
				              selectedEvents: this.props.selectedEvents,
				              gridCellsState: {...cell_state}
			              });
		}

		if (prevProps.selectedTrend !== this.props.selectedTrend) {
			this.setState({
				              selectedTrend: this.props.selectedTrend
			              });
		}


	}

	calculateMiniMapNodes(events, context) {
		return events.map((event) => {

			let factorId = null;

			if (this.state.childFactorsHidden) {
				factorId = context.inheritanceMap[event.factor.id] ? context.inheritanceMap[event.factor.id] : event.factor.id;
			} else {
				factorId = event.factor.id;
			}

			let objectsPosition = context.objectsPositions[this.eventsPositionsKey(factorId, event.date)];

			if (!objectsPosition || !objectsPosition.coordinate) {
				return null;
			}

			const coordinate = objectsPosition.coordinate;

			return {
				id: event.id,
				x: coordinate.columnIndex,
				y: coordinate.rowIndex,
				impact: event.average_impact
			}
		});
	}

	componentDidCatch(error, errorInfo) {
		this.setState({
			              error: true
		              })
	}

	componentWillUnmount() {
		if (this.gridContainerRef) {
			this.gridContainerRef.removeEventListener('scroll', this.handleScrollChange);
			this.gridContainerRef.removeEventListener('scroll', this.handleScrollPositionChange);
		}
	}

	render() {

		if (this.state.error) {
			return (
				<div>Error occurred...</div>
			)
		}

		const self = this;

		let contextMenu = null;

		if (this.state.contextMenuOpened) {
			contextMenu = <ContextMenu position={this.state.contextMenuPosition}
			                           addEventEnabled={this.props.addEventEnabled}
			                           editEventEnabled={this.props.editEventEnabled}
			                           deleteEventEnabled={this.props.deleteEventEnabled}
			                           addTrendEnabled={this.props.addTrendEnabled}
			                           showStraightEventRelations={this.props.showStraightEventRelationsEnabled}
			                           showEventRelations={this.props.showEventRelationsEnabled}
			                           hideEventRelations={this.props.shownEventsRelations && this.props.shownEventsRelations.length > 0}
			                           onAddEvent={this.handleAddEvent}
			                           onEditEvent={this.handleEditEvent}
			                           onDeleteEvent={this.handleDeleteEvent}
			                           onAddTrend={this.handleAddTrend}
			                           onShowStraightEventRelations={this.props.onShowStraightEventRelations}
			                           onShowEventRelations={this.props.onShowEventRelations}
			                           onHideEventRelations={this.props.onHideEventsRelations}
			                           onClose={this.handleContextMenuClose}
			/>
		}

		return (
			<div className="events-map">
				<div style={{
					position: "relative",
					height: '100%'
				}} className="content">
					<div className="header-row row-1">
						<div className="fm-vertical-headers-switcher_wrapper">
							<div id="fm-vertical-headers-switcher"
							     className="fm-vertical-headers-switcher"
							     style={
								     {
									     height: BOUNDS.VERTICAL_HEADER_SWITCHER_HEIGHT,
									     width: BOUNDS.VERTICAL_HEADER_SWITCHER_WIDTH
								     }
							     }>
								<TimeScaleSwitcher timeScale={this.state.timeScale}
								                   onSwitch={this.changeTimeScale}
								                   emptyRowsHidden={this.state.emptyPeriodsHidden}
								                   childFactorsHidden={this.state.childFactorsHidden}
								                   onEmptyHideChange={
									                   (e) => {
										                   if (e.target.checked) {
											                   this.setState({
												                                 emptyPeriodsHidden: true
											                                 })
										                   } else {
											                   this.setState({
												                                 emptyPeriodsHidden: false
											                                 })
										                   }
									                   }
								                   }
								                   onChildFactorsHideChange={
									                   (e) => {
										                   if (e.target.checked) {
											                   this.setState({
												                                 childFactorsHidden: true
											                                 })
										                   } else {
											                   this.setState({
												                                 childFactorsHidden: false
											                                 })
										                   }
									                   }
								                   }
								/>
							</div>
						</div>
						<HorizontalHeader items={
							!this.state.childFactorsHidden ? this.props.factors : this.props.factors.map((element) => {
								return {
									...element,
									children: []
								}
							})
						}
						                  columnWidth={self.state.cellWidth * this.state.scaleX}
						                  containerRef={
							                  (element) => {
								                  this.horizontalHeaderContainerRef = element;
							                  }
						                  }
						/>
					</div>
					<div className="grid-row row-2">
						<VerticalHeader timeScale={this.state.timeScale}
						                items={this.state.verticalHeaderItems ? this.state.verticalHeaderItems : []}
						                rowHeight={this.state.cellHeight * this.state.scaleY}
						                containerRef={
							                (element) => {
								                this.verticalHeaderContainerRef = element;
							                }
						                }
						>
							<CurrentDateSlider start={this.props.start}
							                   end={this.props.end}
							                   date={this.props.currentDate ? new Date(this.props.currentDate) : new Date(this.props.start)}
							                   dynamic={this.props.dynamicCurrentDate}
							                   direction="down-up"
							                   onChange={this.props.onCurrentDayChange}
							/>
						</VerticalHeader>
						<div className="grid-wrapper">
							<Grid rowsCount={this.state.verticalHeaderItems ? this.state.verticalHeaderItems.length : 0}
							      columnsCount={this.flattenFactors ? this.flattenFactors.length : 0}
							      rowHeight={this.state.cellHeight * this.state.scaleY}
							      columnWidth={self.state.cellWidth * this.state.scaleX}
							      cellsState={this.state.gridCellsState}
							      containerRef={
								      (element) => {
									      this.gridContainerRef = element;
								      }
							      }
							      onContextMenu={this.handleContextMenu}
							      onSelectionChange={this.handleGridSelectionChange}
							      itemDisposer={
								      (rowIndex, columnIndex) => {

									      const factor = this.flattenFactors[columnIndex];

									      const period = this.state.verticalHeaderItems[rowIndex];

									      const key = this.eventsPositionsKey(factor.id, period.start);

									      return this.objectsPositions[key] ? this.objectsPositions[key] : null;
								      }
							      }
							      cellControlFactory={this.cellControlFactory}
							      onCellDrop={this.handleCellDrop}
							/>
							{
								this.props.shownEventsRelations && this.props.shownEventsRelations.length > 0 &&
								<canvas ref={this.relationsCanvasRef} className="relations-canvas"
								        width={this.gridContainerRef.clientWidth}
								        height={this.gridContainerRef.clientHeight}/>
							}
						</div>
						{
							!this.state.miniMapOpened &&
							<Button className="toggle-mini-map" onClick={this.toggleMiniMap}>
								<Glyphicon glyph="collapse-up"/>
							</Button>
						}
						{
							this.state.miniMapOpened &&
							<MiniMap onHide={this.toggleMiniMap}
							         nodes={this.state.miniMapNodes ? this.state.miniMapNodes : []}
							         width={this.flattenFactors.length}
							         height={this.state.verticalHeaderItems.length}
							         viewportX={this.state.miniMapViewportX}
							         viewportY={this.state.miniMapViewportY}
							         viewportWidth={this.state.miniMapViewportWidth}
							         viewportHeight={this.state.miniMapViewportHeight}
							         onMoveTo={this.handleMiniMapMoveTo}
							/>
						}
					</div>
					<div className="controls-row">
						<Button className="decrease-scale" onClick={this.handleDecreaseScale}>
							<Glyphicon glyph="minus"/>
						</Button>
						<Button className="increase-scale" onClick={this.handleIncreaseScale}>
							<Glyphicon glyph="plus"/>
						</Button>
					</div>
					{
						this.state.eventPreviewOpened &&
						<EventPreview ref={REFS.EVENT_PREVIEW_REF}
						              show={this.state.eventPreviewOpened}
						              onHide={
							              () => {
								              this.setState({
									                            eventPreviewOpened: false,
									                            viewedEvent: null
								                            })
							              }
						              }
						              event={this.state.viewedEvent}
						              trends={this.state.eventPreviewOpened && this.props.trends ? this.props.trends.filter(element => element.event_ids.indexOf(self.state.viewedEvent.id) !== -1) : []}
						              trendIndexProvider={this.getTrendIndex}
						/>
					}
					{
						this.state.trendPreviewOpened &&
						<TrendPreview ref={REFS.EVENT_PREVIEW_REF}
						              show={this.state.trendPreviewOpened}
						              onHide={
							              () => {
								              this.setState({
									                            trendPreviewOpened: false
								                            })
							              }
						              }
						              trend={this.state.selectedTrend}
						/>}
					{contextMenu}
				</div>
			</div>
		);
	}

	calculateViewportPosition = (scrollX, scrollY) => {

		const viewportXPosition = this.calculateViewportXPosition(scrollX);

		const viewportYPosition = this.calculateViewportYPosition(scrollY);

		return {
			miniMapViewportX: viewportXPosition,
			miniMapViewportY: viewportYPosition
		}
	};

	calculateViewportXPosition = (scrollX) => {

		const scaledWidth = this.state.cellWidth * this.state.scaleX;

		return Math.round(scrollX / scaledWidth);
	};

	calculateViewportYPosition = (scrollY) => {

		const scaledHeight = this.state.cellHeight * this.state.scaleY;

		return Math.round(scrollY / scaledHeight);
	};

	relationsCanvasRef = (element) => {
		this.relationsCanvas = element;
	};

	toggleMiniMap = () => {
		if (!this.state.miniMapOpened) {

			const newMiniMapViewportPosition = this.calculateViewportPosition(this.gridContainerRef.scrollLeft, this.gridContainerRef.scrollTop);

			if (!this.miniMapViewportPosition ||
				(this.miniMapViewportPosition.miniMapViewportX !== newMiniMapViewportPosition.miniMapViewportX ||
					this.miniMapViewportPosition.miniMapViewportY !== newMiniMapViewportPosition.miniMapViewportY)) {

				this.miniMapViewportPosition = newMiniMapViewportPosition;

				this.setState({
					              miniMapOpened: true,
					              miniMapViewportX: this.miniMapViewportPosition.miniMapViewportX,
					              miniMapViewportY: this.miniMapViewportPosition.miniMapViewportY
				              });
			} else {
				this.setState({
					              miniMapOpened: true
				              });
			}
		} else {
			this.setState({
				              miniMapOpened: false
			              });
		}
	};

	handleMiniMapMoveTo = (columnIndex, rowIndex) => {
		this.gridContainerRef.scrollLeft = columnIndex * this.state.cellWidth * this.state.scaleX - this.gridContainerRef.clientWidth / 2;
		this.gridContainerRef.scrollTop = rowIndex * this.state.cellHeight * this.state.scaleY - this.gridContainerRef.clientHeight / 2;
	};

	handleIncreaseScale = () => {

		let newScaleX = this.state.scaleX + 0.25;
		let newScaleY = this.state.scaleY + 0.25;

		this.setState({
			              scaleX: newScaleX,
			              scaleY: newScaleY
		              });
	};

	handleDecreaseScale = () => {

		let newScaleX = this.state.scaleX - 0.25;
		let newScaleY = this.state.scaleY - 0.25;

		let cellsFitsByWidth = this.gridContainerRef.clientWidth - 10 <= this.flattenFactors.length * (this.state.cellWidth * newScaleX);
		let cellsFitsByHeight = this.gridContainerRef.clientHeight - 10 <= this.state.verticalHeaderItems.length * (this.state.cellHeight * newScaleY);

		if (cellsFitsByWidth && cellsFitsByHeight) {
			this.setState({
				              scaleX: newScaleX,
				              scaleY: newScaleY
			              });
		} else if (cellsFitsByWidth) {
			this.setState({
				              scaleX: newScaleX,
			              });
		} else if (cellsFitsByHeight) {
			this.setState({
				              scaleY: newScaleY,
			              });

		}
	};

	handleAddEvent = () => {
		if (this.state.gridCellsState) {

			let selected_cells_keys = Object.keys(this.state.gridCellsState)
			                                .filter((key) => {
				                                return this.state.gridCellsState[key] && this.state.gridCellsState[key].selected
			                                });

			const cellKey = selected_cells_keys.length !== 0 ? selected_cells_keys[0] : null;

			const coordinate = Grid.parseCellKey(cellKey);

			const period = this.state.verticalHeaderItems[coordinate.rowIndex];

			const factor = this.flattenFactors[coordinate.columnIndex];

			this.props.onAddEvent(factor, period.start);
		}
	};

	handleEditEvent = () => {
		if (this.state.selectedEvents && this.props.selectedEvents && this.props.selectedEvents.length !== 0) {
			this.props.onEditEvent(this.props.selectedEvents[0]);
		}
	};

	handleDeleteEvent = () => {
		if (this.state.selectedEvents && this.props.selectedEvents && this.props.selectedEvents.length !== 0) {
			this.props.onDeleteEvent(this.props.selectedEvents[0]);
		}
	};

	handleAddTrend = () => {
		if (this.state.gridCellsState) {

			const minColumnIndex = Object.keys(this.state.gridCellsState)
			                             .filter((key) => {
				                             return this.state.gridCellsState[key] && this.state.gridCellsState[key].selected
			                             })
			                             .reduce((previous, current) => {

				                             const coordinate = Grid.parseCellKey(current);

				                             return coordinate.columnIndex < previous ? coordinate.columnIndex : previous;
			                             }, Number.MAX_VALUE);

			const minRowIndex = Object.keys(this.state.gridCellsState)
			                          .filter((key) => {
				                          return this.state.gridCellsState[key] && this.state.gridCellsState[key].selected
			                          })
			                          .reduce((previous, current) => {

				                          const coordinate = Grid.parseCellKey(current);

				                          return coordinate.rowIndex < previous ? coordinate.rowIndex : previous;
			                          }, Number.MAX_VALUE);

			const maxRowIndex = Object.keys(this.state.gridCellsState)
			                          .filter((key) => {
				                          return this.state.gridCellsState[key] && this.state.gridCellsState[key].selected
			                          })
			                          .reduce((previous, current) => {

				                          const coordinate = Grid.parseCellKey(current);

				                          return coordinate.rowIndex > previous ? coordinate.rowIndex : previous;
			                          }, -1);

			const endPeriod = this.state.verticalHeaderItems[minRowIndex];

			const startPeriod = this.state.verticalHeaderItems[maxRowIndex];

			const factor = this.flattenFactors[minColumnIndex];

			this.props.onAddTrend(factor, startPeriod.start, endPeriod.end);
		}
	};

	handleGridSelectionChange = (selection) => {

		let cells_keys = Object.keys(selection);

		let selected_objects = cells_keys
			.reduce((accumulator, key) => {

				        let {rowIndex, columnIndex} = Grid.parseCellKey(key);

				        let factor = this.flattenFactors[columnIndex];

				        let period = this.state.verticalHeaderItems[rowIndex];

				        if (factor && period) {

					        let objectsPosition = this.objectsPositions[this.eventsPositionsKey(factor.id, period.start)];

					        if (objectsPosition) {

						        if (objectsPosition.events) {

							        let items = this.state.selectedEvents.filter((selected_event) => {
								        return objectsPosition.events.find((cell_event) => {
									        return selected_event.id === cell_event.id
								        }) != null;
							        })

							        if (items) {
								        accumulator.events = accumulator.events.concat(items);
							        }
						        }

						        if (objectsPosition.trends && !accumulator.trend && this.state.selectedTrend) {
							        accumulator.trend = objectsPosition.trends.find((cell_trend) => {
								        return cell_trend.id === this.state.selectedTrend.id
							        })
						        }
					        }
				        }

				        return accumulator;
			        },
			        {
				        events: [],
				        trend: null
			        });

		this.setState({
			              gridCellsState: selection,
			              highlightedTrend: null,
			              selectedTrend: selected_objects.trend,
			              selectedEvents: selected_objects.events
		              },
		              () => {
			              if (this.props.onTrendSelectionChange) {
				              this.props.onTrendSelectionChange(this.state.selectedTrend)
			              }
			              if (this.props.onEventsSelectionChange) {
				              this.props.onEventsSelectionChange(this.state.selectedEvents)
			              }
		              })
	};

	handleDoubleClick = (coordinate) => {

		const period = this.state.verticalHeaderItems[coordinate.rowIndex];

		const factor = this.flattenFactors[coordinate.columnIndex];

		const key = this.eventsPositionsKey(factor.id, period.start);

		if (this.objectsPositions[key]) {
			if (this.objectsPositions[key].events && this.objectsPositions[key].events.length > 0) {
				const event = this.objectsPositions[key].events[0];
				this.setState({
					              eventPreviewOpened: true,
					              viewedEvent: event
				              })
			}
		}
	};

	cellControlFactory = (item, rowIndex, columnIndex) => {
		return (
			<CellItemsPlaceholder events={item.events}
			                      trends={item.trends}
			                      rowIndex={rowIndex}
			                      columnIndex={columnIndex}
			                      rowCount={this.state.verticalHeaderItems.length}
			                      columnCount={this.flattenFactors.length}
			                      cellHeight={this.state.cellHeight}
			                      cellWidth={this.state.cellWidth}
			                      selectedEvents={this.state.selectedEvents}
			                      highlightedEvents={this.state.highlightedEvents}
			                      selectedTrend={this.state.selectedTrend}
			                      highlightedTrend={this.state.highlightedTrend}
			                      getEventColor={this.getEventColor}
			                      getEventBackgroundColor={this.getEventBackgroundColor}
			                      getEventBorderColor={this.getEventBorderColor}
			                      getTrendIndex={this.getTrendIndex}
			                      scaleX={this.state.scaleX}
			                      scaleY={this.state.scaleY}
			                      onEventDragStart={this.handleEventDragStart}
			                      onEventImpactChange={this.handleEventImpactChange}
			                      onEventProbabilityChange={this.handleEventProbabilityChange}
			                      onEventClick={this.handleEventClick}
			                      onEventDoubleClick={this.handleEventDoubleClick}
			                      onTrendClick={this.handleTrendClick}
			                      onTrendDoubleClick={this.handleTrendDoubleClick}
			                      onTrendHover={this.handleTrendHover}
			                      eventsRelations={this.props.shownEventsRelations}
			/>
		)
	};

	handleTrendClick = (trend, context) => {
		this.setState({
			              selectedTrend: trend,
			              highlightedTrend: trend
		              })
	}

	handleTrendDoubleClick = (trend, context) => {
		this.setState({
			              selectedTrend: trend,
			              trendPreviewOpened: true
		              })
	}

	handleTrendHover = (trend, context) => {
		this.setState({
			              highlightedTrend: trend
		              })
	}


	handleEventClick = (item, rowIndex, columnIndex, event) => {

		let new_selected_events;

		if (event.ctrlKey || event.metaKey) {


			new_selected_events = [...this.state.selectedEvents];

			if (item) {

				new_selected_events = new_selected_events.filter((element) => {
					return element.id !== item.id;
				});

				if (this.state.selectedEvents.length === new_selected_events.length) {
					new_selected_events.push(item);
				}
			}
		} else {
			new_selected_events = item !== null ? [item] : [];
		}

		this.setState({
			              selectedEvents: new_selected_events
		              },
		              () => {
			              if (this.props.onEventsSelectionChange) {
				              this.props.onEventsSelectionChange(this.state.selectedEvents);
			              }
		              })
	};

	handleEventDoubleClick = (item, rowIndex, columnIndex, event) => {
		this.setState({
			              eventPreviewOpened: true,
			              viewedEvent: item
		              });
	};

	handleEventImpactChange = (event, oldImpact, newImpact) => {
		if (event && oldImpact != newImpact) {
			this.props.onEventImpactChange(event.id, newImpact);
		}
	};

	handleEventProbabilityChange = (event, oldProbability, newProbability) => {
		if (event && oldProbability != newProbability) {
			this.props.onEventProbabilityChange(event.id, newProbability);
		}
	};

	handleEventDragStart = (data, event) => {
		event.nativeEvent.dataTransfer.setData('event_id', data.id);
	};

	handleCellDrop = (rowIndex, columnIndex, data, event) => {

		const factor = this.flattenFactors[columnIndex];

		const period = this.state.verticalHeaderItems[rowIndex];

		this.props.onEventMove(event.nativeEvent.dataTransfer.getData('event_id'), factor, period)
	};

	handleContextMenu = (event) => {
		event.preventDefault();
		this.setState({
			              contextMenuOpened: true,
			              contextMenuPosition: {
				              x: event.pageX,
				              y: event.pageY,
			              }
		              });
	};

	handleContextMenuClose = () => {
		this.setState({
			              contextMenuOpened: false,
			              contextMenuPosition: null
		              });
	};

	changeTimeScale = (scale) => {
		this.setState({
			              timeScale: scale
		              });
	};

	eventsPositionsKey = (factorId, date) => {

		let eventDate = null;

		if (this.state.timeScale === 'years') {
			eventDate = DateUtils.startOfYear(new Date(date));
		} else if (this.state.timeScale === 'months') {
			eventDate = DateUtils.startOfMonth(new Date(date));
		} else {
			eventDate = DateUtils.startOfDay(new Date(date));
		}

		return factorId + "/" + eventDate.getTime();
	};

	calculateObjectPositions = (events, trends, timeScale, verticalHeaderItems) => {

		const objectsPositions = [];

		if (events) {
			for (let i = 0; i < events.length; i++) {

				let event = events[i];

				let factorId = null;

				if (this.state.childFactorsHidden) {
					factorId = this.inheritanceMap[event.factor.id] ? this.inheritanceMap[event.factor.id] : event.factor.id;
				} else {
					factorId = event.factor ? event.factor.id : null;
				}

				if (!factorId) {
					continue;
				}

				const key = this.eventsPositionsKey(factorId, event.date);

				if (!objectsPositions[key]) {
					objectsPositions[key] = {};
				}

				if (!objectsPositions[key].events) {
					objectsPositions[key].events = [];
				}

				if (!objectsPositions[key].coordinate) {
					objectsPositions[key].coordinate = this.eventGridPosition(event, verticalHeaderItems);
				}

				objectsPositions[key].events.push(event);
				objectsPositions[key].events = objectsPositions[key].events.sort(function (e1, e2) {
					if (e1.average_impact > e2.average_impact) {
						return 1;
					} else if (e1.average_impact > e2.average_impact) {
						return -1;
					} else {
						if (e1.average_probability > e2.average_probability) {
							return 1;
						} else if (e1.average_probability > e2.average_probability) {
							return -1;
						} else {
							return 0;
						}
					}
				});
			}
		}

		if (trends) {
			for (let i = 0; i < trends.length; i++) {

				let trend = trends[i];


				let factorId = null;

				if (this.state.childFactorsHidden) {
					factorId = this.inheritanceMap[trend.factor.id] ? this.inheritanceMap[trend.factor.id] : trend.factor.id;
				} else {
					factorId = trend.factor.id;
				}

				let temp = null;
				let start = null;

				if (timeScale === 'years') {
					start = DateUtils.startOfYear(new Date(trend.start));
					temp = DateUtils.endOfYear(new Date(trend.end));
				} else if (timeScale === 'months') {
					start = DateUtils.startOfMonth(new Date(trend.start));
					temp = DateUtils.endOfMonth(new Date(trend.end));
				} else {
					start = DateUtils.startOfDay(new Date(trend.start));
					temp = DateUtils.endOfDay(new Date(trend.end));
				}

				while (temp >= start) {

					const key = this.eventsPositionsKey(factorId, start);

					if (!objectsPositions[key]) {
						objectsPositions[key] = {};
					}

					if (!objectsPositions[key].trends) {
						objectsPositions[key].trends = [];
					}

					objectsPositions[key].trends.push(trend);

					if (timeScale === 'years') {
						start = DateUtils.addYear(start,
						                          1);
					} else if (timeScale === 'months') {
						start = DateUtils.addMonth(start,
						                           1);
					} else {
						start = DateUtils.addDay(start,
						                         1);
					}
				}
			}
		}

		return objectsPositions;
	};

	calculatePeriodRanges = (startDate, endDate, timeScale) => {

		const ranges = [];

		const notEmptyRangeStarts = [];

		if (this.state.emptyPeriodsHidden) {
			for (let i = 0; i < this.props.events.length; i++) {

				let eventDate = null;

				if (timeScale === 'years') {
					eventDate = DateUtils.startOfYear(new Date(this.props.events[i].date));
				} else if (timeScale === 'months') {
					eventDate = DateUtils.startOfMonth(new Date(this.props.events[i].date));
				} else {
					eventDate = DateUtils.startOfDay(new Date(this.props.events[i].date));
				}

				notEmptyRangeStarts[eventDate.toString()] = true;
			}
		}

		let temp = null;

		if (timeScale === 'years') {
			temp = DateUtils.endOfYear(endDate);
		} else if (timeScale === 'months') {
			temp = DateUtils.endOfMonth(endDate);
		} else {
			temp = DateUtils.endOfDay(endDate);
		}

		let start = null;

		if (timeScale === 'years') {
			start = DateUtils.startOfYear(startDate);
		} else if (timeScale === 'months') {
			start = DateUtils.startOfMonth(startDate);
		} else {
			start = DateUtils.startOfDay(startDate);
		}

		ranges.hidden = [];
		ranges.visible = [];

		while (temp >= start) {

			let from = new Date(temp.getTime());
			let to = null;

			if (timeScale === 'years') {
				to = DateUtils.startOfYear(temp);
				temp = DateUtils.subtractYear(temp, 1);
			} else if (timeScale === 'months') {
				to = DateUtils.startOfMonth(temp);
				temp = DateUtils.subtractMonth(temp, 1);
			} else {
				to = DateUtils.startOfDay(temp);
				temp = DateUtils.subtractDay(temp, 1);
			}

			const range = {
				start: to,
				end: from
			};

			if (this.state.emptyPeriodsHidden) {
				if (notEmptyRangeStarts[to.toString()]) {
					ranges.visible.push(range);
				} else {
					ranges.hidden.push(range);
				}
			} else {
				ranges.visible.push(range);
			}
		}

		return ranges;
	};

	findEventVPos = (date, start, end) => {
		const verticalHeadersItems = this.verticalHeaders.getItems();

		const size = end - start;
		if (size < 50) {
			for (let y = start; y <= end; y++) {
				if (verticalHeadersItems[y].contains(date)) {
					return y;
				}
			}
		} else {
			let y = start + Math.floor(size / 2);
			if (verticalHeadersItems[y].contains(date)) {
				return y;
			} else if (date.isAfter(verticalHeadersItems[y].end)) {
				return this.findEventVPos(date,
				                          start,
				                          y);
			} else {
				return this.findEventVPos(date,
				                          y,
				                          end);
			}
		}
	};


	calculateTrendIndexes = () => {

		const indexes = {};

		this.flattenFactors.forEach((factor) => {
			let index = 0;
			const factor_trends = this.props.trends.filter(x => x.factor.id === factor.id);
			for (let i = 0; i < factor_trends.length; i++) {
				indexes[factor_trends[i].id] = i;
			}
		})

		return indexes;
	}

	getTrendIndex = (trend) => {
		return this.trendIndexMap[trend.id] ? this.trendIndexMap[trend.id] : 0;
	};

	getEventColor = (event) => {
		const colors = this.props.eventTypeColors.filter(color => {
			return !!color.event_type && !!event.type && color.event_type.id === event.type.id;
		});
		if (colors[0]) {
			return `rgb(${colors[0].red_addition}, ${colors[0].green_addition}, ${colors[0].blue_addition})`;
		} else {
			return `rgb(122, 186, 232)`;
		}
	};

	getEventBackgroundColor = (event) => {
		if (new Date(event.date) >= this.currentDate()) {
			return '#EDF2F6';
		} else {
			return '#ecb2b2';
		}
	};

	currentDate = () => {
		return this.props.dynamicCurrentDate ? DateUtils.addDay(this.props.currentDate, DateUtils.dateDiffInDays(this.props.currentDateChangeDate, new Date())) : this.props.currentDate;
	};

	getEventBorderColor = (event) => {
		return '#17A4FE';
	};

	handleScrollChange = (event) => {

		let self = this;

		if (self.gridContainerRef) {

			self.updateHeadersPosition(self.gridContainerRef.scrollLeft, self.gridContainerRef.scrollTop);

			if (self.props.scrollPositionChangeHandler) {
				self.props.scrollPositionChangeHandler(self.gridContainerRef.scrollTop,
				                                       self.gridContainerRef.scrollLeft)
			}

			if (self.props.shownEventsRelations && self.props.shownEventsRelations.length > 0) {
				self.redrawRelations(self.gridContainerRef.scrollLeft, self.gridContainerRef.scrollTop);
			}

			if (self.state.miniMapOpened) {

				const newMiniMapViewportPosition = self.calculateViewportPosition(self.gridContainerRef.scrollLeft, self.gridContainerRef.scrollTop);

				if (!self.miniMapViewportPosition ||
					(self.miniMapViewportPosition.miniMapViewportX !== newMiniMapViewportPosition.miniMapViewportX ||
						self.miniMapViewportPosition.miniMapViewportY !== newMiniMapViewportPosition.miniMapViewportY)) {

					self.miniMapViewportPosition = newMiniMapViewportPosition;

					self.setState({
						              miniMapViewportX: self.miniMapViewportPosition.miniMapViewportX,
						              miniMapViewportY: self.miniMapViewportPosition.miniMapViewportY
					              });
				}
			}
		}
	};

	updateHeadersPosition = (x, y) => {

		const verticalHeaderContainer = this.verticalHeaderContainerRef;

		if (verticalHeaderContainer) {
			verticalHeaderContainer.scrollTop = y;
		}

		const horizontalHeaderContainer = this.horizontalHeaderContainerRef();

		if (horizontalHeaderContainer) {
			horizontalHeaderContainer.scrollLeft = x;
		}
	};

	eventGridPosition = (event, verticalHeaderItems) => {

		const eventDate = new Date(event.date);

		const containingVerticalHeaderItems = verticalHeaderItems.filter((element) => {
			return (element.start <= eventDate && eventDate < element.end);
		});

		const containingHorizontalHeaderItems = this.flattenFactors.filter((element) => {
			if (this.state.childFactorsHidden) {
				const parentFactorId = this.inheritanceMap[event.factor.id];
				return element.id === parentFactorId;
			} else {
				return element.id === event.factor.id;
			}
		});

		const cellRowIndex = verticalHeaderItems.indexOf(containingVerticalHeaderItems[0]);

		const cellColumnIndex = this.flattenFactors.indexOf(containingHorizontalHeaderItems[0]);

		return {
			rowIndex: cellRowIndex,
			columnIndex: cellColumnIndex
		}
	};

	redrawRelations = (x, y) => {

		const self = this;

		const eventsRelations = this.props.shownEventsRelations;

		const areaX = x;
		const areaY = y;
		const areaWidth = this.gridContainerRef.clientWidth;
		const areaHeight = this.gridContainerRef.clientHeight;

		const ctx = this.relationsCanvas.getContext('2d');
		ctx.clearRect(0, 0, areaWidth, areaHeight);

		const columnWidth = self.state.cellWidth;
		const rowHeight = self.state.cellHeight;

		eventsRelations.forEach((element) => {

			let sourcePosition = this.objectsPositions[self.eventsPositionsKey(element.left_event.factor.id, element.left_event.date)];
			let targetPosition = this.objectsPositions[self.eventsPositionsKey(element.right_event.factor.id, element.right_event.date)];

			if (!sourcePosition || !targetPosition) {
				return;
			}

			const sourceEventGridPosition = sourcePosition.coordinate;
			const targetEventGridPosition = targetPosition.coordinate;

			ctx.beginPath();

			let x1 = sourceEventGridPosition.columnIndex * columnWidth - 10 * self.state.scaleX;
			let x2 = targetEventGridPosition.columnIndex * columnWidth - 10 * self.state.scaleX;
			let y1 = sourceEventGridPosition.rowIndex * rowHeight - 5 * self.state.scaleY;
			let y2 = targetEventGridPosition.rowIndex * rowHeight - 5 * self.state.scaleY;

			if (sourceEventGridPosition.rowIndex === targetEventGridPosition.rowIndex && sourceEventGridPosition.columnIndex > targetEventGridPosition.columnIndex) {
				x2 += columnWidth;
				y1 += rowHeight / 2;
				y2 += rowHeight / 2;
			} else if (sourceEventGridPosition.rowIndex === targetEventGridPosition.rowIndex && sourceEventGridPosition.columnIndex < targetEventGridPosition.columnIndex) {
				x1 += columnWidth;
				y1 += rowHeight / 2;
				y2 += rowHeight / 2;
			} else if (sourceEventGridPosition.rowIndex > targetEventGridPosition.rowIndex && sourceEventGridPosition.columnIndex > targetEventGridPosition.columnIndex) {
				x1 += columnWidth / 2;
				x2 += columnWidth;
				y2 += rowHeight / 2;
			} else if (sourceEventGridPosition.rowIndex > targetEventGridPosition.rowIndex && sourceEventGridPosition.columnIndex < targetEventGridPosition.columnIndex) {
				x1 += columnWidth / 2;
				y2 += rowHeight / 2;
			} else if (sourceEventGridPosition.rowIndex < targetEventGridPosition.rowIndex && sourceEventGridPosition.columnIndex > targetEventGridPosition.columnIndex) {
				x1 += columnWidth / 2;
				y1 += rowHeight;
				x2 += columnWidth;
				y2 += rowHeight / 2;
			} else if (sourceEventGridPosition.rowIndex < targetEventGridPosition.rowIndex && sourceEventGridPosition.columnIndex < targetEventGridPosition.columnIndex) {
				x1 += columnWidth / 2;
				y1 += rowHeight;
				y2 += rowHeight / 2;
			}

			ctx.moveTo(x1 - areaX, y1 - areaY);
			ctx.lineTo(x2 - areaX, y2 - areaY);
			ctx.strokeStyle = '#ff0000';
			ctx.stroke();
		});

	};

}

export default Map;