import clientFactory from "../factories/http_client";
import {API} from "../api/api";


class FilterService {

	/** @return  Promise<AxiosResponse<T>> */
	getEntityProperties = (entityName) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.entity_properties.method,
			                             url: API.data.entity_properties.url,
			                             params: API.data.entity_properties.build(entityName)
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	get = (projectId, futureMapId, filterId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.filters.id.method,
			                             url: API.data.projects.id.event_maps.id.filters.id.url(projectId, futureMapId, filterId)
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	create = (projectId, futureMapId, filter) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.filters.create.method,
			                             url: API.data.projects.id.event_maps.id.filters.create.url(projectId, futureMapId),
			                             data: API.data.projects.id.event_maps.id.filters.create.build(filter),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	save = (projectId, futureMapId, filter) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.filters.save.method,
			                             url: API.data.projects.id.event_maps.id.filters.save.url(projectId, futureMapId),
			                             data: API.data.projects.id.event_maps.id.filters.save.build(filter),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	remove = (projectId, futureMapId, filterId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.filters.delete.method,
			                             url: API.data.projects.id.event_maps.id.filters.delete.url(projectId, futureMapId),
			                             data: API.data.projects.id.event_maps.id.filters.delete.build(filterId),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	}
}

export default FilterService;