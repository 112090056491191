import {actionTypes} from "../actions/authentication";

const initialState = {
    isPending: false,
    invalidated: true,
    authentication: null
};

function authenticationReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.REQUEST_AUTHENTICATION:
            return {
                ...state,
                isPending: true
            };
        case actionTypes.RECEIVE_AUTHENTICATION:
            if (action.data && action.data.authenticated && action.data.principal) {
                let authorities = action.data.principal.entity_roles;
                let newAuthorities = {};
                if (authorities) {
                    authorities.forEach(function callback(currentValue, index, array) {
                        let type = currentValue.entity_type.replace(/.*\./, "");
                        if (!newAuthorities[type]) {
                            newAuthorities[type] = [];
                        }
                        newAuthorities[type].push(currentValue);
                    });
                }
                action.data.principal.entity_roles = newAuthorities;
            }
            return {
                ...state,
                isPending: false,
                authentication: action.data
            };
        default:
            return state
    }
}

export default authenticationReducer