import clientFactory from "../factories/http_client";
import {API} from "../api/api";

class FactorService {

	/** @return  Promise<AxiosResponse<T>> */
	get = (projectId, futureMapId, factorId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.factors.id.method,
			                             url: API.data.projects.id.event_maps.id.factors.id.url(projectId, futureMapId, factorId)
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	save = (projectId, futureMapId, factor) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.factors.save.method,
			                             url: API.data.projects.id.event_maps.id.factors.save.url(projectId, futureMapId),
			                             data: API.data.projects.id.event_maps.id.factors.save.build(factor),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	create = (projectId, futureMapId, factor) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.factors.create.method,
			                             url: API.data.projects.id.event_maps.id.factors.create.url(projectId, futureMapId),
			                             data: API.data.projects.id.event_maps.id.factors.create.build(factor),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	remove = (projectId, futureMapId, factorId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.factors.delete.method,
			                             url: API.data.projects.id.event_maps.id.factors.delete.url(projectId, futureMapId),
			                             data: API.data.projects.id.event_maps.id.factors.delete.build(factorId, true),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	copy = (projectId, futureMapId, factorId, withEvents, parentFactorId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.factors.copy.method,
			                             url: API.data.projects.id.event_maps.id.factors.copy.url(projectId, futureMapId),
			                             data: API.data.projects.id.event_maps.id.factors.copy.build(factorId, (parentFactorId ? parentFactorId : undefined), withEvents),
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	getJournalEntries = (projectId, futureMapId, factorId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.event_maps.id.factors.id.journal_entries.method,
			                             url: API.data.projects.id.event_maps.id.factors.id.journal_entries.url(projectId, futureMapId, factorId)
		                             });
	}
}

export default FactorService;