import React from "react";
import {connect} from "react-redux";
import {tokenService} from "../services";
import {OAUTH_CLIENT_ID, OAUTH_CLIENT_SECRET} from "../constants";
import clientFactory from "../factories/http_client";
import {API} from "../api/api";
import * as server_actions from "../actions/servers";
import {Alert} from "react-bootstrap";

class OAuthTokenRefresher
	extends React.Component {


	constructor(props, context) {

		super(props, context);

		this.state = {};
		this.current_task_id = null;
	}

	componentDidMount() {
		this.current_task_id = setTimeout(() => {

			this.setState({
				              requesting_token: true
			              },
			              () => {
				              this.current_task_id = setTimeout(() => {
					              this.setState({
						                            requesting_token: false
					                            })
				              }, 3000);
			              })

			this.props.refreshToken();

		}, (this.props.token_info.expires_in - 3) * 1000)
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.token_info !== this.props.token_info) {
			if (!this.props.token_info.error) {
				this.current_task_id = setTimeout(() => {
					this.setState({
						              requesting_token: true
					              },
					              () => {
						              this.current_task_id = setTimeout(() => {
							              this.setState({
								                            requesting_token: false
							                            })
						              }, 3000)
					              })
					this.props.refreshToken();
				}, (this.props.token_info.expires_in - 3) * 1000)
			} else {
				this.setState({
					              error: true
				              })
			}
		}
	}

	componentDidCatch(error, errorInfo) {
		this.setState({
			              error: true
		              })
	}

	componentWillUnmount() {
		clearTimeout(this.current_task_id)
	}

	render() {

		if (this.state.error) {
			return <Alert bsStyle={"danger"}
			              style={{
				              position: "fixed",
				              top: '2rem',
				              left: '50%',
				              zIndex: 100,
				              transform: 'translateX(-50%)'
			              }}>
				Couldn't refresh access token. Please, refresh the page.
			</Alert>
		}

		if (this.state.requesting_token) {
			return <Alert bsStyle={"info"}
			              style={{
				              position: "fixed",
				              top: '2rem',
				              left: '50%',
				              zIndex: 100,
				              transform: 'translateX(-50%)'
			              }}>
				Requesting token...
			</Alert>
		} else {
			return <div/>
		}

	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		token_info: state.servers.token_info,
	}
};

const mapDispatchToProps = dispatch => {
	return {
		refreshToken: (timeout) => {
			dispatch(refreshTokenActionChain());
		}
	}
};


const refreshTokenActionChain = () => {
	return (dispatch, getState) => {
		tokenService.refreshOAuthToken(OAUTH_CLIENT_ID, OAUTH_CLIENT_SECRET, getState().servers.token_info.refresh_token)
		            .then(token_info_proxy => {

			            let token_info = token_info_proxy.data;

			            clientFactory.remove_client_from_cache('data');

			            clientFactory.set_access_token({
				                                           access_token: token_info.access_token,
				                                           refresh_token: token_info.refresh_token
			                                           }, API.data.scope);
			            clientFactory.set_access_token({
				                                           access_token: token_info.access_token,
				                                           refresh_token: token_info.refresh_token
			                                           }, API.auth.scope);

			            dispatch(server_actions.set_oauth_token_info(token_info));
		            })
		            .catch(error => {
			            if (error.response.status === 401) {

				            clientFactory.remove_client_from_cache('data');

				            dispatch(server_actions.set_oauth_token_info({
					                                                         access_token: 'invalid',
					                                                         error: error.response.status
				                                                         }));
			            }
		            });
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(OAuthTokenRefresher)