import projectReducer from "./projects";
import dialogsReducer from "./dialogs";
import factorReducer from "./factors";
import flagsReducer from "./flags";
import {combineReducers} from "redux";
import eventsReducer from "./events";
import colorSchemaReducer from "./color-schema";
import trendsReducer from "./trends";
import eventCategoriesReducer from "./event-categories";
import eventSourcesReducer from "./event-sources";
import eventTypesReducer from "./event-types";
import authenticationReducer from "./authentication";
import usersReducer from "./users";
import authoritiesReducer from "./authorities";
import scenariosReducer from "./scenarios";
import notificationsReducer from "./notificaitons";
import clipboard from "./clipboard";
import operation from "./operations";
import eventRelationsReducer from "./event-relations";
import projectCategoriesReducer from "./project-categories";
import numericUnitsReducer from "./numeric-units";
import serversReducer from "./servers";
import orphansReducer from "./orphans";

const rootReducer = combineReducers({
	                                    authenticationReducer,
	                                    dialogsReducer,
	                                    projectReducer,
	                                    factorReducer,
	                                    eventsReducer,
	                                    trendsReducer,
	                                    colorSchemaReducer,
	                                    flagsReducer,
	                                    authoritiesReducer,
	                                    eventCategoriesReducer,
	                                    eventTypesReducer,
	                                    eventSourcesReducer,
	                                    projectCategoriesReducer,
	                                    numericUnitsReducer,
	                                    usersReducer,
	                                    scenariosReducer,
	                                    notifications: notificationsReducer,
	                                    clipboardReducer: clipboard,
	                                    operation,
	                                    eventRelationsReducer,
	                                    servers: serversReducer,
	                                    orphans: orphansReducer
                                    });

export default rootReducer