import React from 'react';
import {Button, Modal, ToggleButton, ToggleButtonGroup} from 'react-bootstrap';

import BaseForm from '../../components/base/base-form';

class EventTypesSelectForm
	extends BaseForm {

	constructor(props) {

		super(props);

		this.state = {
			selectedEventTypes: props.selectedEventTypes === undefined ? [] : props.selectedEventTypes
		};
	}

	handleSaveButton = () => {
		this.props.onSave(this.state.selectedEventTypes);
	};

	handleChange = (item) => {

		const newSelectedElements = this.state.selectedEventTypes.filter(function (element) {
			return element.id !== item.id;
		});

		if (this.state.selectedEventTypes.length !== newSelectedElements.length) {
			this.setState({
				              selectedEventTypes: newSelectedElements
			              });
		} else {
			newSelectedElements.push(item);
			this.setState({
				              selectedEventTypes: newSelectedElements
			              });
		}
	};

	render() {
		return (
			<Modal bsSize="sm" show={this.props.show} onHide={this.props.onHide}>
				<Modal.Header closeButton>
					<Modal.Title>Event Types Form</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<ToggleButtonGroup
						vertical
						type="checkbox"
						style={
							{
								width: "100%"
							}
						}
						onChange={(e) => {}}
						value={
							this.state.selectedEventTypes.map(function (element) {
								return element.id;
							})
						}
					>
						{
							this.props.availableEventTypes && this.props.availableEventTypes.map(
								(currentValue, index, array) => {
									return (
										<ToggleButton value={currentValue.id}
										              key={currentValue.id}
										              onClick={
											              (event) => {
												              this.handleChange(currentValue)
											              }
										              }
										>
											{currentValue.title}
										</ToggleButton>
									)
								})
						}
					</ToggleButtonGroup>
				</Modal.Body>
				<Modal.Footer>
					<Button bsClass="btn action-button" onClick={this.handleSaveButton}>Save</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default EventTypesSelectForm;