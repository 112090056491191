export const mixinFactory = function (config) {
	return {
		[config.scope]: {
			_objectWithoutProperties: function _objectWithoutProperties(obj, keys) {
				let target = {};
				for (let i in obj) {
					if (keys.indexOf(i) >= 0) continue;
					if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
					target[i] = obj[i];
				}
				return target;
			},
			setInitialState: function (state) {
				this.initial_state = state;
				this.initialization_counter = this.initialization_counter != null ? this.initialization_counter + 1 : 0;
			}
		},
		detectChanged: function (prevProps, prevState) {
			if (this[config.scope].initial_state) {
				let cleared_initial_state = this[config.scope]._objectWithoutProperties(this[config.scope].initial_state, config.excludedFields.concat([config.stateField]));

				let cleared_state = this[config.scope]._objectWithoutProperties(this.state, config.excludedFields.concat([config.stateField]));

				if (JSON.stringify(cleared_initial_state) === JSON.stringify(cleared_state)) {
					if (this.state[config.stateField]) {
						this.setState({
							              [config.stateField]: false
						              })
					}
				} else {
					if (!this.state[config.stateField]) {
						this.setState({
							              [config.stateField]: true
						              })
					}
				}
			}
		}
	}
}