import React from 'react';
import {Button, Col, FormControl, FormGroup, Glyphicon, Grid, Nav, Navbar, NavItem, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';

import FactorsMap from '../components/controls/factors-map';
import Utils from "../utils/utils";
import FactorDialog from "../components/dialogs/factor-dialog";

class FactorsMapView
	extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			contextMenuAddFactorEnabled: true,
			contextMenuEditFactorEnabled: false,
			contextMenuDeleteFactorEnabled: false,
			contextMenuCopyFactorEnabled: false,
			foundFactors: null,
			factors_map_items: props.factors ? Utils.getFlatFactors([{
				id: 0,
				title: props.project ? props.project.name : "",
				children: props.factors,
				depth: -1
			}]) : [],
			contextMenuInsetFactorEnabled: !!props.clipboardContent,
			...this.setPermissions(props)
		};
	}

	componentDidMount() {
		this.props.loadInitialData();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (prevProps.factors !== this.props.factors) {
			this.setState({
				              factors_map_items: Utils.getFlatFactors([{
					              id: 0,
					              title: this.props.project.name,
					              children: this.props.factors,
					              depth: -1
				              }])
			              })
		}
	}

	componentDidCatch(error, errorInfo) {
		this.setState({
			              error: true
		              })
	}

	UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
		this.setState({
			              contextMenuInsetFactorEnabled: !!nextProps.clipboardContent,
			              factors_map_items: this.props.factors !== nextProps.factors ? Utils.getFlatFactors([{
				              id: 0,
				              title: this.props.project.name,
				              children: nextProps.factors,
				              depth: -1
			              }]) : this.state.factors_map_items,
			              ...this.setPermissions(nextProps)
		              });
	}

	setPermissions = (props) => {
		if (props.project && props.isAdministrator(props.project)) {
			return {
				addFactorEnabled: true,
				editFactorEnabled: true,
				deleteFactorEnabled: true,
				printingEnabled: true
			}
		} else if (props.project && props.isAnalyst(props.project)) {
			return {
				addFactorEnabled: true,
				editFactorEnabled: true,
				deleteFactorEnabled: true,
				printingEnabled: true
			}
		} else if (props.project && props.isManager(props.project)) {
			return {
				addFactorEnabled: false,
				editFactorEnabled: true,
				deleteFactorEnabled: false,
				printingEnabled: true
			}
		} else {
			return {
				addFactorEnabled: false,
				editFactorEnabled: true,
				deleteFactorEnabled: false,
				printingEnabled: true
			}
		}
	};

	render() {
		return (
			<Grid fluid={true} id="factors-map-page"
			      className="display-flex align-items-stretch flex-direction-column h-100">
				<Row className="toolbar">
					<Col md={12}>
						<Navbar id="factors-map-navbar" fluid={true} className="toolbar">
							<Nav>
								<NavItem>
									<OverlayTrigger placement="bottom"
									                overlay={<Tooltip id="tooltip">Refresh</Tooltip>}>
										<Button bsClass="btn bar-button" onClick={this.refreshButtonHandler}>
											<Glyphicon glyph="refresh"/>
										</Button>
									</OverlayTrigger>
								</NavItem>
								{
									(this.state.addFactorEnabled) &&
									<NavItem>
										<OverlayTrigger placement="bottom"
										                overlay={<Tooltip id="tooltip">Add</Tooltip>}>
											<Button bsClass="btn bar-button" onClick={this.addButtonHandler}>
												<Glyphicon glyph="plus" className="c-glyph-icon"/>
												ADD
											</Button>
										</OverlayTrigger>
									</NavItem>
								}
								{
									(this.state.editFactorEnabled) &&
									<NavItem>
										<OverlayTrigger placement="bottom"
										                overlay={<Tooltip id="tooltip">Edit</Tooltip>}>
											<Button bsClass="btn bar-button" onClick={this.editButtonHandler}>
												<Glyphicon glyph="pencil" className="c-glyph-icon"/>
												EDIT
											</Button>
										</OverlayTrigger>
									</NavItem>
								}
								{
									(this.state.deleteFactorEnabled) &&
									<NavItem>
										<OverlayTrigger placement="bottom"
										                overlay={<Tooltip id="tooltip">Delete</Tooltip>}>
											<Button bsClass="btn bar-button" onClick={this.deleteButtonHandler}>
												<Glyphicon glyph="remove" className="c-glyph-icon"/>
												DELETE
											</Button>
										</OverlayTrigger>
									</NavItem>
								}
								{
									(this.state.printingEnabled) &&
									<NavItem>
										<OverlayTrigger placement="bottom"
										                overlay={<Tooltip id="tooltip">Print</Tooltip>}>
											<Button bsClass="btn bar-button" onClick={this.printButtonHandler}>
												<Glyphicon glyph="print" className="c-glyph-icon"/>
												PRINT
											</Button>
										</OverlayTrigger>
									</NavItem>
								}
							</Nav>
							<Navbar.Form pullRight>
								<FormGroup>
									<FormControl type="text" placeholder="Search"/>
								</FormGroup>
								<OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip">Search</Tooltip>}>
									<Button bsClass="btn bar-button" onClick={this.searchFactorsHandler}>
										<Glyphicon glyph="search"/>
									</Button>
								</OverlayTrigger>
							</Navbar.Form>
						</Navbar>
					</Col>
				</Row>
				<Row className="content">
					<Col id="factors-map" md={12} className="h-100">
						{
							!this.state.error &&
							<FactorsMap canvasRef={this.canvasRef}
							            factors={this.state.factors_map_items}
							            onSelectionChange={this.handleSelectChange}
							            onDoubleClick={this.handleDoubleClick}
							            onAdd={this.addButtonHandler}
							            onEdit={this.editButtonHandler}
							            onDelete={this.deleteButtonHandler}
							            onCopyWithoutEvents={this.copyWithoutEventsHandler}
							            onCopyWithEvents={this.copyWithEventsHandler}
							            onInsert={this.insertHandler}
							            addEnabled={this.state.contextMenuAddFactorEnabled}
							            editEnabled={this.state.contextMenuEditFactorEnabled}
							            deleteEnabled={this.state.contextMenuDeleteFactorEnabled}
							            copyEnabled={this.state.contextMenuCopyFactorEnabled}
							            insertEnabled={this.state.contextMenuInsetFactorEnabled}
							            selectedNode={this.props.selectedFactor}
							            highlightedFactors={this.state.foundFactors}
							            storageKey={"tp.wc.factors-map." + this.props.project.id}
							/>
						}

					</Col>
				</Row>
				{
					this.props.history.location.hash !== "" &&
					<FactorDialog {...this.props} />
				}
			</Grid>
		);
	}

	canvasRef = (element) => {
		this.factorsMapCanvasRef = element
	};

	searchFactorsHandler = (event) => {

		let parent = event.target.parentNode;

		let valueField = parent.querySelector('input[type="text"]');

		if (!valueField) {
			valueField = parent.parentNode.querySelector('input[type="text"]')
		}

		let searchString = valueField.value.trim();

		if (searchString === "") {
			this.setState({
				              foundFactors: null
			              });
		} else {

			let foundFactors = Utils.getFlatFactors(this.props.factors)
			                        .filter(function (element) {
				                        return element.title.toLowerCase()
				                                      .includes(searchString.toLowerCase());
			                        });

			this.setState({
				              foundFactors: foundFactors
			              });
		}
	};

	handleSelectChange = (factorId) => {
		if (factorId) {
			let selectedFactors = Utils.getFlatFactors(this.props.factors)
			                           .filter(function (element) {
				                           return element.id === factorId;
			                           });
			this.props.selectFactor(selectedFactors.length === 0 ? null : selectedFactors[0]);
			this.setState({
				              contextMenuAddFactorEnabled: true,
				              contextMenuEditFactorEnabled: true,
				              contextMenuDeleteFactorEnabled: true,
				              contextMenuCopyFactorEnabled: true
			              });
		} else {
			this.setState({
				              contextMenuAddFactorEnabled: true,
				              contextMenuEditFactorEnabled: false,
				              contextMenuDeleteFactorEnabled: false,
				              contextMenuCopyFactorEnabled: false
			              });
			this.props.selectFactor(null);
		}
	};

	handleDoubleClick = (factorId) => {
		this.handleSelectChange(factorId);
		this.editButtonHandler();
	};

	refreshButtonHandler = () => {
		this.props.loadInitialData();
	};

	addButtonHandler = () => {
		this.props.addFactor(this.props.project);
	};

	editButtonHandler = () => {
		if (this.props.selectedFactor) {
			this.props.editFactor(this.props.project, this.props.selectedFactor);
		} else {
			alert("You must select factor on map");
		}
	};

	deleteButtonHandler = () => {
		if (this.props.selectedFactor && confirm(`Are you sure you want to delete factor: ${this.props.selectedFactor.title}?`)) {
			this.props.deleteFactor(this.props.project, this.props.selectedFactor);
		}
	};

	copyWithoutEventsHandler = () => {
		if (this.props.selectedFactor) {
			this.props.copyFactor(this.props.project, this.props.selectedFactor, false);
		} else {
			alert("You must select factor on map");
		}
	};

	copyWithEventsHandler = () => {
		if (this.props.selectedFactor) {
			this.props.copyFactor(this.props.project, this.props.selectedFactor, true);
		} else {
			alert("You must select factor on map");
		}
	};

	insertHandler = () => {
		this.props.insertFactor(this.props.clipboardContent, this.props.selectedFactor);
	};

	printButtonHandler = () => {
		const self = this;

		var windowContent = '<!DOCTYPE html>';
		windowContent += '<html>';
		windowContent += '<head><title>Factors Map</title></head>';
		windowContent += '<body>';
		windowContent += '<img src="' + self.factorsMapCanvasRef.toDataURL() + '" style="width: 100%; height: 100%">';
		windowContent += '</body>';
		windowContent += '</html>';

		var printWin = window.open('', '', '');
		printWin.document.open();
		printWin.document.write(windowContent);
		printWin.document.addEventListener('load', function () {
			printWin.focus();
			printWin.print();
			printWin.document.close();
			printWin.close();
		}, true);
	}
}

export default FactorsMapView;