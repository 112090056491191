import {actionTypes} from "../actions/users";

const initialState = {
    isPending: false,
    invalidated: true,
    users: null,
    user: null,
    selectedUser: null,
    operation: null
};

function usersReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.get(actionTypes.REQUEST_USERS):
            return {
                ...state,
                isPending: true,
                users: action.data
            };
        case actionTypes.get(actionTypes.RECEIVE_USERS):
            return {
                ...state,
                isPending: false,
                users: action.data
            };
        case actionTypes.get(actionTypes.SELECT_USER):
            return {
                ...state,
                selectedUser: action.data
            };
        case actionTypes.get(actionTypes.REQUEST_USER):
            return {
                ...state,
                isPending: true,
                user: action.data
            };
        case actionTypes.get(actionTypes.RECEIVE_USER):
            return {
                ...state,
                isPending: false,
                user: action.data
            };
        case actionTypes.get(actionTypes.get(actionTypes.START_OPERATION)):
            return {
                ...state,
                operation: action.data
            };
        case actionTypes.get(actionTypes.get(actionTypes.END_OPERATION)):
            return {
                ...state,
                operation: action.data
            };
        default:
            return state
    }
}

export default usersReducer