import React from "react";
import {Button, Col, ControlLabel, Form, FormGroup} from "react-bootstrap";
import {SketchPicker} from "react-color";
import Utils from "../utils/utils";

class ColorSchemeView
	extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			...this.setPermissions(props)
		};

		props.colorScheme && props.colorScheme.event_type_colors.forEach((eventTypeColor) => {
			this.state['color_et_' + eventTypeColor.id] = Utils.rgbToHex(eventTypeColor.red_addition, eventTypeColor.green_addition, eventTypeColor.blue_addition);
		});
	}

	componentDidMount() {
		if (this.props.project && !this.props.colorScheme) {
			this.props.loadColorScheme(this.props.project);
		}
	}

	componentDidUpdate() {
		if (this.props.project && !this.props.colorScheme) {
			this.props.loadColorScheme(this.props.project);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps, nextContext) {

		const newState = {
			...this.setPermissions(nextProps)
		};

		if (nextProps.colorScheme && nextProps.colorScheme.event_type_colors) {
			nextProps.colorScheme.event_type_colors.forEach((eventTypeColor) => {
				newState['color_et_' + eventTypeColor.id] = Utils.rgbToHex(eventTypeColor.red_addition, eventTypeColor.green_addition, eventTypeColor.blue_addition);
			})
		}

		this.setState(newState);
	}

	setPermissions = (props) => {
		if (props.project && props.isAdministrator(props.project)) {
			return {
				saveEnabled: true,
				addLinkEnabled: true,
				editLinkEnabled: true,
				removeLinkEnabled: true,
				addMessageEnabled: true,
				editMessageEnabled: true,
				deleteMessageEnabled: true,
				addAttachmentEnabled: true,
				editAttachmentEnabled: true,
				deleteAttachmentEnabled: true,
				addRelationEnabled: true,
				deleteRelationEnabled: true,
				addEventEnabled: true,
				deleteEventEnabled: true
			}
		} else if (props.project && props.isAnalyst(props.project)) {
			return {
				saveEnabled: true,
				addLinkEnabled: true,
				editLinkEnabled: true,
				removeLinkEnabled: true,
				addMessageEnabled: true,
				editMessageEnabled: false,
				deleteMessageEnabled: false,
				addAttachmentEnabled: true,
				editAttachmentEnabled: true,
				deleteAttachmentEnabled: true,
				addRelationEnabled: true,
				deleteRelationEnabled: true,
				addEventEnabled: true,
				deleteEventEnabled: true
			}
		} else if (props.project && props.isManager(props.project)) {
			return {
				saveEnabled: false,
				addLinkEnabled: true,
				editLinkEnabled: true,
				removeLinkEnabled: true,
				addMessageEnabled: true,
				editMessageEnabled: false,
				deleteMessageEnabled: false,
				addAttachmentEnabled: true,
				editAttachmentEnabled: true,
				deleteAttachmentEnabled: true,
				addRelationEnabled: true,
				deleteRelationEnabled: true,
				addEventEnabled: true,
				deleteEventEnabled: true
			}
		} else {
			return {
				saveEnabled: false,
				addLinkEnabled: false,
				editLinkEnabled: false,
				removeLinkEnabled: false,
				addMessageEnabled: true,
				editMessageEnabled: false,
				deleteMessageEnabled: false,
				addAttachmentEnabled: false,
				editAttachmentEnabled: false,
				deleteAttachmentEnabled: false,
				addRelationEnabled: false,
				deleteRelationEnabled: false,
				addEventEnabled: true,
				deleteEventEnabled: true
			}
		}
	};

	render() {

		if (!this.props.colorScheme) {
			return <div>Загрузка данных</div>;
		}

		return (
			<Form horizontal={true} className="h-100 scenario-form">
				{
					this.props.colorScheme.event_type_colors && this.props.colorScheme.event_type_colors.map((eventTypeColor) => {
						return (
							<FormGroup key={eventTypeColor.id}>
								<Col componentClass={ControlLabel} md={2}>
									{
										eventTypeColor.event_type.title
									}
								</Col>
								<Col md={10}>
									<div className="color-swatch"
									     onClick={
										     (e) => {
											     this.selectColorHandler(eventTypeColor.id);
										     }
									     }>
										<div className="color-color"
										     style={
											     {
												     backgroundColor: this.state['color_et_' + eventTypeColor.id]
											     }
										     }
										/>
									</div>
									{
										this.state['showColorPicker_et_' + eventTypeColor.id] ?
											<div className="color-popover">
												<div className="color-cover"
												     onClick={
													     (e) => {
														     this.cancelColorHandler(eventTypeColor.id);
													     }
												     }/>
												<SketchPicker color={this.state['color_et_' + eventTypeColor.id]}
												              onChangeComplete={
													              (color) => {

														              const newState = {};
														              newState['color_et_' + eventTypeColor.id] = color.hex;

														              this.setState(newState)
													              }
												              }
												              disableAlpha={true}
												/>
											</div>
											: null
									}
								</Col>
							</FormGroup>
						);
					})
				}
				<FormGroup className="form-buttons-bar">
					<Col md={12}>
						<Button bsClass="btn action-button pull-right" onClick={this.cancelButtonHandler}>
							Cancel
						</Button>
						<Button bsClass="btn action-button pull-right" onClick={this.saveButtonHandler}>
							Save
						</Button>
					</Col>
				</FormGroup>
			</Form>
		)
	}

	cancelButtonHandler = () => {
		this.props.history.goBack();
	};

	saveButtonHandler = () => {

		const colorScheme = this.props.colorScheme ? this.props.colorScheme : {};

		colorScheme.event_type_colors = colorScheme.event_type_colors.map((element) => {

			const rgb = Utils.hexToRgb(this.state['color_et_' + element.id]);

			if (rgb) {
				element.red_addition = rgb.r;
				element.green_addition = rgb.g;
				element.blue_addition = rgb.b;
			}

			return element;
		});

		this.props.saveColorScheme(this.props.project, colorScheme)
	};

	selectColorHandler = (id) => {

		const newState = {};
		newState['showColorPicker_et_' + id] = !this.state['showColorPicker_et_' + id];

		this.setState(newState);
	};

	cancelColorHandler = (id) => {

		const newState = {};
		newState['showColorPicker_et_' + id] = false;

		this.setState(newState);
	};

	setEventValue = (field, event) => {
		//If the input fields were directly within this
		//this component, we could use this.refs.[FIELD].value
		//Instead, we want to save the data for when the form is submitted
		const object = {};
		object[field] = event.target.value;
		this.setState(object);
	};

	setEventChecked = (field, event) => {
		//If the input fields were directly within this
		//this component, we could use this.refs.[FIELD].value
		//Instead, we want to save the data for when the form is submitted
		const object = {};
		object[field] = event.target.checked;
		this.setState(object);
	};

	setValue = (field, value) => {
		//If the input fields were directly within this
		//this component, we could use this.refs.[FIELD].value
		//Instead, we want to save the data for when the form is submitted
		const object = {};
		object[field] = value;
		this.setState(object);
	};
}


export default ColorSchemeView