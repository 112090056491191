import {browserHistory} from '../app';
import {CLIENT_URI_CONSTANTS as URI} from '../utils/uri-constants';
import {eventsMapService, trendService as TrendService} from "../services";

export const actionTypes = {

	prefix: 'TREND',

	REQUEST_TRENDS: 'REQUEST_TRENDS',
	RECEIVE_TRENDS: 'RECEIVE_TRENDS',
	REQUEST_TREND: 'REQUEST_TREND',
	RECEIVE_TREND: 'RECEIVE_TREND',
	SELECT_TREND: 'SELECT_TREND',
	ADD_TREND: 'ADD_TREND',
	EDIT_TREND: 'EDIT_TREND',
	SAVE_TREND: 'SAVE_TREND',
	DELETE_TREND: 'DELETE_TREND',
	SELECT_EVENT: 'SELECT_EVENT',
	INSERT_TREND: 'INSERT_TREND',
	START_OPERATION: 'START_OPERATION',
	END_OPERATION: 'END_OPERATION',

	get: function (key) {
		return this.prefix + '_' + this[key];
	}
};

export const fetchTrends = (projectId, futureMapId) => {
	return (dispatch, getState) => {
		if (!getState().trendsReducer.isPending) {
			dispatch(requestTrends());
			eventsMapService.getTrends(projectId, futureMapId)
			                .then(trends_proxy => {
				                dispatch(receiveTrends(trends_proxy.data));
			                });
		}
	}
};

export const requestTrends = () => {
	return {
		type: actionTypes.get(actionTypes.REQUEST_TRENDS)
	}
};

export const fetchTrend = (projectId, futureMapId, trendId) => {
	return (dispatch, getState) => {
		if (!getState().trendsReducer.isPending) {
			dispatch(requestTrend());
			TrendService.get(projectId, futureMapId, trendId)
			            .then(trend_proxy => {
				            dispatch(receiveTrend(trend_proxy.data));
			            });
		}
	}
};

export const requestTrend = () => {
	return {
		type: actionTypes.get(actionTypes.REQUEST_TREND)
	}
};

export const receiveTrend = (response) => {
	return {
		type: actionTypes.get(actionTypes.RECEIVE_TREND),
		data: response
	}
};

export const receiveTrends = (response) => {
	return {
		type: actionTypes.get(actionTypes.RECEIVE_TRENDS),
		data: response
	}
};

export const selectTrend = (trend) => {
	return {
		type: actionTypes.get(actionTypes.SELECT_TREND),
		data: trend
	}
};

export const addTrend = (project, trendTemplate) => {
	return (dispatch, getState) => {
		dispatch(receiveTrend(trendTemplate ? trendTemplate : null));
		browserHistory.push(`/${URI.PROJECT}/${project.id}/${URI.TRENDS}/0`);
	};
};

export const editTrend = (project, trend) => {
	return (dispatch, getState) => {
		browserHistory.push(`/${URI.PROJECT}/${project.id}/${URI.TRENDS}/${trend.id}`);
	};
};

export function createTrend(projectId, futureMapId, trend) {
	return (dispatch, getState) => {
		requestTrend()
		TrendService.create(projectId, futureMapId, trend)
		            .then(trend_proxy => {
			            dispatch(receiveTrends(null));
			            dispatch(receiveTrend(trend_proxy.data));
			            dispatch(endOperation({
				                                  name: 'CREATION',
				                                  status: "SUCCESS"
			                                  }));
			            browserHistory.push(`/${URI.PROJECT}/${projectId}/${URI.TRENDS}/${trend_proxy.data.id}`);
		            })
		            .catch((error) => {
			            dispatch(endOperation({
				                                  name: 'CREATION',
				                                  status: "ERROR"
			                                  }));
		            });
	};
}

export function saveTrend(projectId, futureMapId, trend) {
	return (dispatch, getState) => {
		requestTrend()
		TrendService.save(projectId, futureMapId, trend)
		            .then(trend_proxy => {
			            dispatch(receiveTrends(null));
			            dispatch(receiveTrend(trend_proxy.data));
			            dispatch(endOperation({
				                                  name: 'SAVING',
				                                  status: "SUCCESS"
			                                  }));
		            })
		            .catch((error) => {
			            dispatch(endOperation({
				                                  name: 'SAVING',
				                                  status: "ERROR"
			                                  }));
		            });
	}
}

export function deleteTrend(projectId, futureMapId, trendId) {
	return (dispatch, getState) => {
		TrendService.remove(projectId, futureMapId, trendId)
		            .then(trendId => {
			            dispatch(fetchTrends(projectId, futureMapId));
		            });
	}
}

export function addEvent(projectId, futureMapId, trendId, eventId) {
	return (dispatch, getState) => {
		TrendService.addEvent(projectId, futureMapId, trendId, eventId)
		            .then(trend => {
			            dispatch(receiveTrends(null));
			            dispatch(receiveTrend(null));
		            });
	}
}

export function removeEvent(projectId, futureMapId, trendId, eventId) {
	return (dispatch, getState) => {
		TrendService.removeEvent(projectId, futureMapId, trendId, eventId)
		            .then(trend => {
			            dispatch(receiveTrends(null));
			            dispatch(receiveTrend(null));
		            });
	}
}

export const selectEvent = (event) => {
	return {
		type: actionTypes.get(actionTypes.SELECT_EVENT),
		data: event
	}
};

export function insertTrend(trend) {
	return {
		type: actionTypes.get(actionTypes.INSERT_TREND),
		data: trend
	};
}

export const startOperation = (operation) => {
	return {
		type: actionTypes.get(actionTypes.START_OPERATION),
		data: operation
	}
};

export const endOperation = (operation) => {
	return {
		type: actionTypes.get(actionTypes.END_OPERATION),
		data: operation
	}
};