const PredicateValueType = {
    STRING: "STRING",
    INTEGER: "INTEGER",
    FLOAT: "FLOAT",
    DOUBLE: "DOUBLE",
    DATE: "DATE",
    FACTOR: "FACTOR",
    EVENT: "EVENT",
    EVENT_CATEGORY: "EVENT_CATEGORY",
    EVENT_TYPE: "EVENT_TYPE",
    EVENT_SOURCE: "EVENT_SOURCE"
};

const PredicateRelation = {
    EQUAL: "EQUAL",
    LIKE: "LIKE",
    LESS: "LESS",
    LESS_OR_EQUAL: "LESS_OR_EQUAL",
    GREATER: "GREATER",
    GREATER_OR_EQUAL: "GREATER_OR_EQUAL",
    IN: "IN",
    OR: "OR",
    AND: "AND"
};

export {
    PredicateValueType,
    PredicateRelation
};