import {filterService} from "../services";

export const actionTypes = {
    RECEIVE_EVENTS_MAP_FILTER: 'RECEIVE_EVENTS_MAP_FILTER',
    SELECT_EVENTS_MAP_FILTER: 'SELECT_EVENTS_MAP_FILTER',
    ADD_EVENTS_MAP_FILTER: 'ADD_EVENTS_MAP_FILTER',
    EDIT_EVENTS_MAP_FILTER: 'EDIT_EVENTS_MAP_FILTER',
    SAVE_EVENTS_MAP_FILTER: 'SAVE_EVENTS_MAP_FILTER',
    DELETE_EVENTS_MAP_FILTER: 'DELETE_EVENTS_MAP_FILTER'
};

export const requestEventsMapFilters = (projectId, futureMapId) => {
    return dispatch => {

    }
};

export function requestEventsMapFilter(projectId, futureMapId, filterId) {
    return dispatch => {
        filterService.get(projectId, futureMapId, filterId).then(filter_proxy => {
            dispatch(receiveEventsMapFilter(filter_proxy.data));
        });
    }
}

export function receiveEventsMapFilter(response) {
    return {
        type: actionTypes.RECEIVE_FACTOR,
        data: response
    }
}