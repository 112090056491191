import axios from "axios";

class HTTPClientFactory {

	constructor() {
		this.clients = [];
		this.access_tokens = [];
		this.csrf_tokens = [];
	}

	/** @return AxiosInstance */
	client = (scope) => {

		if (!this.clients[scope]) {

			const headers = {};

			if (this.access_tokens[scope]) {
				headers.Authorization = 'Bearer ' + this.access_tokens[scope].access_token;
			}

			if (this.csrf_tokens[scope]) {
				headers[this.csrf_tokens[scope].headerName] = this.csrf_tokens[scope].token;
			}

			this.clients[scope] = axios.create({
				                                   headers: {
					                                   common: headers,
					                                   'Content-Type': 'application/json',
				                                   }
			                                   })
		}

		return this.clients[scope];
	};

	set_access_token = (token_data, scope = 'data') => {
		this.access_tokens[scope] = token_data;
	};

	set_csrf_token = (token_data, scope = 'data') => {
		this.csrf_tokens[scope] = token_data;
	};

	remove_client_from_cache = (scope) => {
		this.clients[scope] = null;
	}
}

const clientFactory = new HTTPClientFactory();

export default clientFactory