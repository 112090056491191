import FlagService from "./services/flag-service";
import EventRelationsService from "./services/event-relations-service";
import EventService from "./services/event-service";
import EventsMapService from "./services/events-map-service";
import FactorService from "./services/factor-service";
import FilterService from "./services/filter-service";
import ProjectService from "./services/project-service";
import ScenarioService from "./services/scenario-service";
import TrendService from "./services/trend-service";
import UserService from "./services/users-service";
import EventCategoryService from "./services/event-category-service";
import EventSourceService from "./services/event-source-service";
import EventTypeService from "./services/event-type-service";
import NumericUnitService from "./services/numeric-unit-service";
import ProjectCategoryService from "./services/project-category-service";
import TokenService from "./services/token-service";
import AuthenticationService from "./services/authentication-service";
import EventLinkService from "./services/event-link-service";
import EventCommentService from "./services/event-comment-service";
import EventAttachmentService from "./services/event-attachment-service";
import FactorLinkService from "./services/factor-link-service";
import FactorCommentService from "./services/factor-comment-service";
import FactorAttachmentService from "./services/factor-attachment-service";
import ProjectLinkService from "./services/project-link-service";
import ProjectAttachmentService from "./services/project-attachment-service";
import ProjectCommentService from "./services/project-comment-service";
import ScenarioLinkService from "./services/scenario-link-service";
import ScenarioCommentService from "./services/scenario-comment-service";
import ScenarioAttachmentService from "./services/scenario-attachment-service";
import ProjectColorSchemeService from "./services/project-color-scheme-service";
import NotificationService from "./services/notification-service";

export const commonService = new FlagService();
export const projectCategoryService = new ProjectCategoryService();
export const eventCategoryService = new EventCategoryService();
export const eventTypeService = new EventTypeService();
export const eventSourceService = new EventSourceService();
export const numericUnitService = new NumericUnitService();
export const eventRelationsService = new EventRelationsService();
export const eventService = new EventService();
export const eventsMapService = new EventsMapService();
export const factorService = new FactorService();
export const filterService = new FilterService();
export const projectService  = new ProjectService();
export const projectLinkService  = new ProjectLinkService();
export const projectCommentService  = new ProjectCommentService();
export const projectAttachmentService  = new ProjectAttachmentService();
export const scenarioService = new ScenarioService();
export const trendService = new TrendService();
export const userService = new UserService();
export const tokenService = new TokenService();
export const authenticationService = new AuthenticationService();
export const eventLinkService = new EventLinkService();
export const eventCommentService = new EventCommentService();
export const eventAttachmentService = new EventAttachmentService();
export const factorLinkService = new FactorLinkService();
export const factorCommentService = new FactorCommentService();
export const factorAttachmentService = new FactorAttachmentService();
export const scenarioLinkService = new ScenarioLinkService();
export const scenarioCommentService = new ScenarioCommentService();
export const scenarioAttachmentService = new ScenarioAttachmentService();
export const projectColorSchemeService = new ProjectColorSchemeService();
export const notificationService = new NotificationService();