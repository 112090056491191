import * as React from "react";
import EventControl from "./event-control";
import Utils from "../../../utils/utils";
import {Overlay, OverlayTrigger, Tooltip} from "react-bootstrap";
import EventsListPopover from "./events-list-popover";
import Trend from "./trend";

export default class CellItemsPlaceholder
	extends React.PureComponent {


	constructor(props, context) {

		super(props, context);

		this.containerRef = React.createRef();

		this.column_width = 180;
		this.row_height = 90;

		this.state = {
			mode: 'stacked'
		};
	}


	componentDidMount() {

		this.containerWidth = this.props.cellWidth * this.props.scaleX;

		this.containerHeight = this.props.cellHeight * this.props.scaleY;

		let grid_column_count = this.calculateColumnCount();

		let grid_row_count = this.calculateRowCount();

		this.setState({
			              mode: this.calculateMode(grid_row_count, grid_column_count),
			              column_count: grid_column_count,
			              row_count: grid_row_count
		              })
	}


	calculateMode(grid_row_count, grid_column_count) {
		if (this.props.events) {
			if (grid_column_count * grid_row_count === 1 && this.props.events.length === 1) {
				return 'single'
			} else if (grid_column_count * grid_row_count === 1 && this.props.events.length > 1) {
				return 'stacked'
			} else if (grid_column_count * grid_row_count >= this.props.events.length) {
				return 'flat'
			} else if (grid_column_count * grid_row_count < this.props.events.length) {
				return 'flat-stacked';
			} else {
				return 'single';
			}
		} else if (grid_column_count > 1) {
			return 'flat'
		} else {
			return 'single';
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (prevProps.cellWidth !== this.props.cellWidth ||
			prevProps.cellHeight !== this.props.cellHeight ||
			prevProps.scaleX !== this.props.scaleX ||
			prevProps.scaleY !== this.props.scaleY) {

			this.containerWidth = this.props.cellWidth * this.props.scaleX;

			this.containerHeight = this.props.cellHeight * this.props.scaleY;

			let grid_column_count = this.calculateColumnCount();

			let grid_row_count = this.calculateRowCount();

			let mode = this.calculateMode(grid_row_count, grid_column_count);

			if (prevState.mode !== mode || prevState.column_count !== grid_column_count || prevState.row_count !== grid_row_count) {
				this.setState({
					              mode: mode,
					              column_count: grid_column_count,
					              row_count: grid_row_count
				              })
			}
		}
	}


	calculateRowCount() {
		let row_count = Math.floor(this.containerHeight / (this.row_height * this.scaleY()));
		return row_count === 0 ? 1 : row_count;
	}

	calculateColumnCount() {
		let column_count = Math.floor(this.containerWidth / (this.column_width * this.scaleX()));
		return column_count === 0 ? 1 : column_count;
	}

	componentDidCatch(error, errorInfo) {
		this.setState({
			              error: true
		              })
	}

	render() {

		if (this.state.error) {
			return <div ref={this.containerRef}>Error occurred...</div>;
		}

		let content = this.renderContent(this.state.mode);

		let className = "cell-placeholder";
		if (this.props.cellHeight * this.props.scaleY < 70) {
			className += " minified"
		}

		return (
			<div ref={this.containerRef}
			     className={className}>
				{content}
			</div>
		)
	}

	renderContent = (mode) => {
		if (mode === 'flat') {
			return this.renderFlatContent();
		} else if (mode === 'flat-stacked') {
			return this.renderFlatStackedContent();
		} else if (mode === 'stacked') {
			return this.renderStackedContent();
		} else {
			return this.renderSingleContent();
		}
	}


	renderFlatContent = () => {

		let sorted_events = this.props.events ? this.props.events.sort(Utils.sortAscendingByProperty('average_impact')) : [];

		let layers_data = this.calculateLayers(sorted_events, this.props.trends, this.state.mode);

		let groups = this.splitEventsByColumns(sorted_events, this.state.row, this.state.column_count);

		return (
			<div className="wrapper flat" style={{
				display: 'flex',
				flexWrap: 'wrap'
			}}>
				{
					this.renderTrends(this.props.trends, {
						layers: layers_data
					})
				}
				{
					groups.map((events, index) => {
						return (
							<div key={"col" + index}
							     className="column"
							     style={{
								     width: this.column_width * this.scaleX(),
								     maxWidth: this.column_width * this.scaleX()
							     }}>
								{
									events
										.map((item, index) => {
											return this.renderEvent(item, {layers: layers_data});

										})
								}
							</div>
						)
					})
				}
			</div>
		);
	}

	scaleX = () => {
		return this.props.scaleX >= 1 ? 1 : this.props.scaleX;
	}

	scaleY = () => {
		return this.props.scaleY >= 1 ? 1 : this.props.scaleY;
	}


	renderFlatStackedContent = () => {

		let sorted_events = this.props.events ? this.props.events.sort(Utils.sortAscendingByProperty('average_impact')) : [];

		let layers_data = this.calculateLayers(sorted_events, this.props.trends, this.state.mode);

		let groups = this.splitEventsByColumns(sorted_events.slice(0, this.state.column_count * this.state.row_count), this.state.row, this.state.column_count);

		let popoverPlacement = 'right';

		if (this.props.columnCount === this.props.columnIndex && this.props.rowCount === this.props.rowIndex) {
			popoverPlacement = 'top-left';
		} else if (this.props.columnCount === this.props.columnIndex) {
			popoverPlacement = 'left';
		} else if (this.props.rowCount === this.props.rowIndex) {
			popoverPlacement = 'top'
		}

		return (
			<div className="wrapper flat-stacked">
				<div className="events-pane">
					{
						this.renderTrends(this.props.trends, {
							layers: layers_data
						})
					}
					{
						groups.map((events, index) => {
							return (
								<div key={"col" + index}
								     className="column"
								     style={{
									     width: this.column_width * this.scaleX(),
									     maxWidth: this.column_width * this.scaleX()
								     }}>
									{
										events
											.map((item, index) => {
												return this.renderEvent(item, {layers: layers_data});

											})
									}
								</div>
							)
						})
					}
				</div>
				<div className="quantity-pane">
					{
						this.props.events && this.props.events.length > 1 &&
						<div className="events-quantity"
						     onClick={this.openEventsList}
						>
							<label onClick={this.openEventsList}>
								{this.props.events.length}
							</label>
						</div>
					}
					{
						this.state.eventListOpened &&
						<Overlay show={this.state.eventListOpened}
						         container={this.containerRef.current}
						         placement={popoverPlacement}
						>
							<EventsListPopover events={this.props.events}
							                   trends={this.props.trends}
							                   selectedEvents={this.props.selectedEvents}
							                   eventsRelations={this.props.eventsRelations}
							                   eventColorProvider={this.props.getEventColor}
							                   eventBorderColorProvider={this.props.getEventBorderColor}
							                   eventBackgroundColorProvider={this.props.getEventBackgroundColor}
							                   onClose={
								                   (e) => {
									                   this.setState({
										                                 eventListOpened: false
									                                 })
								                   }
							                   }
							                   onItemClick={
								                   (event, e) => {
									                   this.props.onEventClick(event, this.props.rowIndex, this.props.columnIndex, e);
								                   }
							                   }
							                   onItemDoubleClick={
								                   (event, e) => {
									                   this.props.onEventDoubleClick(event, this.props.rowIndex, this.props.columnIndex, e);
								                   }
							                   }
							                   onEventDragStart={this.props.onEventDragStart}
							/>
						</Overlay>
					}
				</div>
			</div>
		);
	}

	renderStackedContent = () => {

		let sorted_events = this.props.events ? this.props.events.sort(Utils.sortAscendingByProperty('average_impact')) : [];

		let layers_data = [];

		let column_indexes = [0];

		column_indexes.forEach((event_group, index) => {
			layers_data.push(this.calculateLayers(this.props.events, this.props.trends, this.state.mode));
		})

		let popoverPlacement = 'right';

		if (this.props.columnIndex >= this.props.columnCount - 2 && this.props.rowIndex >= this.props.rowCount - 2) {
			popoverPlacement = 'top-left';
		} else if (this.props.columnIndex >= this.props.columnCount - 2) {
			popoverPlacement = 'left';
		} else if (this.props.rowIndex >= this.props.rowCount - 2) {
			popoverPlacement = 'top'
		}

		return (
			<div className="wrapper stacked">
				<div className="events-pane">
					{
						column_indexes.map((events, index) => {
							return this.renderTrends(this.props.trends, {
								layers: layers_data[index],
								panel_index: index
							})
						})
					}
					{
						sorted_events && sorted_events.length > 0 && this.renderEvent(sorted_events[0], {layers: layers_data[0]})
					}
				</div>
				<div className="quantity-pane">
					{
						this.props.events && this.props.events.length > 1 &&
						<div className="events-quantity"
						     onClick={this.openEventsList}
						>
							<label onClick={this.openEventsList}>
								{this.props.events.length}
							</label>
						</div>
					}
					{
						this.state.eventListOpened &&
						<Overlay show={this.state.eventListOpened}
						         container={this.containerRef.current}
						         placement={popoverPlacement}
						>
							<EventsListPopover events={this.props.events}
							                   trends={this.props.trends}
							                   placement={popoverPlacement}
							                   selectedEvents={this.props.selectedEvents}
							                   eventsRelations={this.props.eventsRelations}
							                   eventColorProvider={this.props.getEventColor}
							                   eventBorderColorProvider={this.props.getEventBorderColor}
							                   eventBackgroundColorProvider={this.props.getEventBackgroundColor}
							                   onClose={
								                   (e) => {
									                   this.setState({
										                                 eventListOpened: false
									                                 })
								                   }
							                   }
							                   onItemClick={
								                   (event, e) => {
									                   this.props.onEventClick(event, this.props.rowIndex, this.props.columnIndex, e);
								                   }
							                   }
							                   onItemDoubleClick={
								                   (event, e) => {
									                   this.props.onEventDoubleClick(event, this.props.rowIndex, this.props.columnIndex, e);
								                   }
							                   }
							                   onEventDragStart={this.props.onEventDragStart}
							/>
						</Overlay>
					}
				</div>
			</div>
		);
	}

	renderSingleContent = () => {

		let sorted_events = this.props.events ? this.props.events.sort(Utils.sortAscendingByProperty('average_impact')) : [];

		let layers_data = this.calculateLayers(sorted_events, this.props.trends, this.state.mode);

		return (
			<div className="wrapper single">
				<div className="events-pane">
					{
						this.renderTrends(this.props.trends, {
							layers: layers_data,
							panel_index: 0
						})
					}
					{
						sorted_events && sorted_events.length > 0 && this.renderEvent(sorted_events[0], {layers: layers_data})
					}
				</div>
			</div>
		)
	}

	openEventsList = (e) => {
		e.stopPropagation();
		this.setState({
			              eventListOpened: !this.state.eventListOpened
		              })
	}

	renderTrend = (data, render_context) => {

		let backgroundMode = this.props.eventsRelations && this.props.eventsRelations.length !== 0;

		return (
			<Trend trend={data}
			       scaleX={this.scaleX()}
			       selectedTrend={this.props.selectedTrend}
			       highlightedTrend={this.props.highlightedTrend}
			       scaleY={this.scaleY()}
			       backgroundMode={backgroundMode}
			       layer={render_context.layers['t' + data.id]}
			       trendIndexProvider={this.props.getTrendIndex}
			       trendColorProvider={this.getTrendColor}
			       onTrendClick={(data, context) => {
				       if (this.props.onTrendClick) {
					       this.props.onTrendClick(data, context)
				       }
			       }}
			       onTrendDoubleClick={(data, context) => {
				       if (this.props.onTrendDoubleClick) {
					       this.props.onTrendDoubleClick(data, context)
				       }
			       }}
			       onTrendHover={(data, context) => {
				       if (this.props.onTrendHover) {
					       this.props.onTrendHover(data, context)
				       }
			       }}
			/>
		)
	};

	renderTrends = (trends, render_context) => {

		if (!trends) {
			return null;
		}

		return trends.map((trend, index) => {
			return this.renderTrend(trend, render_context)
		})
	}


	renderEvent = (event, render_context) => {

		let boxShadow;

		if (this.props.highlightedTrend && this.props.highlightedTrend.event_ids.indexOf(event.id) !== -1) {
			boxShadow = `inset 0 1px 1px #6f7e95, 0 0 8px ${this.getTrendColor(this.props.highlightedTrend.color)}`;
		}

		let backgroundMode = this.props.eventsRelations && this.props.eventsRelations.length > 0 && this.props.eventsRelations.filter((item) => {
			return item.right_event.id === event.id || item.left_event.id === event.id
		}).length === 0;

		return (
			<EventControl key={'ec-' + event.id}
			              event={event}
			              cellHeight={this.props.cellHeight}
			              cellWidth={this.props.cellWidth}
			              selected={this.props.selectedEvents ? this.props.selectedEvents.filter((item) => {
				              return item.id === event.id
			              }).length !== 0 : false}
			              eventColorProvider={this.props.getEventColor}
			              eventBackgroundColorProvider={this.props.getEventBackgroundColor}
			              eventBorderColorProvider={this.props.getEventBorderColor}
			              scaleX={this.props.scaleX}
			              scaleY={this.props.scaleY}
			              backlighting={boxShadow}
			              onEventDragStart={this.props.onEventDragStart}
			              onEventImpactChange={this.props.onEventImpactChange}
			              onEventProbabilityChange={this.props.onEventProbabilityChange}
			              backgroundMode={backgroundMode}
			              onClick={
				              (event, e) => {
					              this.props.onEventClick(event, this.props.rowIndex, this.props.columnIndex, e);
				              }
			              }
			              onDoubleClick={
				              (event, e) => {
					              this.props.onEventDoubleClick(event, this.props.rowIndex, this.props.columnIndex, e);
				              }
			              }
			              onContextMenu={(e) => {
				              this.props.onEventClick(event, this.props.rowIndex, this.props.columnIndex, e);
			              }}
			              layer={render_context.layers ? render_context.layers['e' + event.id] : 1}
			/>
		)
	}

	calculateLayers = (events, trends, mode) => {

		const layer_map = Object.assign(events ? events.reduce((accumulator, item) => {
			                                accumulator['e' + item.id] = 1;
			                                return accumulator;
		                                }, {}) : {},
		                                trends ? trends.reduce((accumulator, item) => {
			                                accumulator['t' + item.id] = 0;
			                                return accumulator;
		                                }, {}) : {})

		if ((mode === 'single' || mode === 'stacked') && events && events.length > 0 && trends) {
			Object.assign(layer_map,
			              trends
				              .filter((item) => {
					              return item.event_ids.indexOf(events[0].id) !== -1;
				              })
				              .reduce((accumulator, item) => {
					              accumulator['t' + item.id] = 2;
					              return accumulator;
				              }, {}))
		}

		return layer_map;
	}

	splitEventsByColumns = (events, row_count, column_count) => {
		let holder = [];
		for (let i = 0; i < events.length; i++) {
			let column_index = i - Math.floor(i / this.state.column_count) * this.state.column_count;
			if (!holder[column_index]) {
				holder[column_index] = [];
			}
			holder[column_index].push(events[i]);
		}
		return holder;
	}

	getTrendColor = (color) => {
		return `rgba(${color.red_addition}, ${color.green_addition}, ${color.blue_addition}, 0.4)`;
	};

}