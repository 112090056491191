import React from "react";
import {Button, Col, ControlLabel, Form, FormGroup, Modal} from "react-bootstrap";

class LinkForm extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            url: props.link ? props.link.url : "",
            name: props.link ? props.link.name : "",
            description: props.link ? props.link.description : ""
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            url: nextProps.link ? nextProps.link.url : "",
            name: nextProps.link ? nextProps.link.name : "",
            description: nextProps.link ? nextProps.link.description : ""
        });
    }

    handleSaveButton = () => {

        const link = this.props.link ? this.props.link : {};
        link.url = this.state.url;
        link.name = this.state.name;
        link.description = this.state.description;

        this.props.onSave(link);
    };

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Link Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form horizontal={true}>
                        <FormGroup>
                            <Col componentClass={ControlLabel} sm={2}>
                                URL
                            </Col>
                            <Col sm={10}>
                                <input className="form-control"
                                       type="text"
                                       onChange={this.setEventValue.bind(this, 'url')}
                                       value={this.state.url}/>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col componentClass={ControlLabel} sm={2}>
                                Name
                            </Col>
                            <Col sm={10}>
                                <input className="form-control"
                                       type="text"
                                       onChange={this.setEventValue.bind(this, 'name')}
                                       value={this.state.name}/>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col componentClass={ControlLabel} sm={2}>
                                Description
                            </Col>
                            <Col sm={10}>
                                <textarea className="form-control"
                                          rows="5"
                                          onChange={this.setEventValue.bind(this, 'description')}
                                          value={this.state.description}/>
                            </Col>
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button bsClass="btn action-button" onClick={this.handleSaveButton}>Save</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    setEventValue(field, event) {
        //If the input fields were directly within this
        //this component, we could use this.refs.[FIELD].value
        //Instead, we want to save the data for when the form is submitted
        const object = {};
        object[field] = event.target.value;
        this.setState(object);
    }

    setEventChecked(field, event) {
        //If the input fields were directly within this
        //this component, we could use this.refs.[FIELD].value
        //Instead, we want to save the data for when the form is submitted
        const object = {};
        object[field] = event.target.checked;
        this.setState(object);
    }

    setValue(field, value) {
        //If the input fields were directly within this
        //this component, we could use this.refs.[FIELD].value
        //Instead, we want to save the data for when the form is submitted
        const object = {};
        object[field] = value;
        this.setState(object);
    }
}

export default LinkForm;