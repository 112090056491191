import 'bootstrap';
import React from 'react';
import ReactDOM from 'react-dom';
import Moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import simpleNumberLocalizer from 'react-widgets-simple-number';

import App from "./app";
import {applyMiddleware, createStore} from "redux";
import rootReducer from "./reducers/root";
import {createLogger} from "redux-logger";
import thunkMiddleware from 'redux-thunk';

momentLocalizer(Moment);
simpleNumberLocalizer();

const loggerMiddleware = createLogger();

export const store = createStore(
	rootReducer,
	applyMiddleware(loggerMiddleware, thunkMiddleware)
);

ReactDOM.render(
	<App store={store}/>,
	document.getElementById('root')
);