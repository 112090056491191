import React from "react";
import {connect} from "react-redux";
import {browserHistory} from "../app";
import {Route, withRouter} from "react-router";
import clientFactory from "../factories/http_client";
import {API} from "../api/api";
import * as server_actions from "../actions/servers";
import {tokenService} from "../services";
import {OAUTH_CLIENT_ID, OAUTH_CLIENT_SECRET, OAUTH_REDIRECT_URI} from "../constants";

class OAuth2AuthorizationProvider
	extends Route {


	constructor(props, context) {

		super(props, context);

		this.state = {};
	}

	componentDidMount() {

		const auth_data = this.extractAuthData(this.props.location.search);

		if (auth_data.code) {
			this.props.requestToken(auth_data.code);
		}
	}

	extractAuthData = (url) => {
		return url.slice(1)
		          .split('&')
		          .reduce((accumulator, x) => {
			          const keyValue = x.split('=');
			          accumulator[keyValue[0]] = keyValue[1];
			          return accumulator;
		          }, {});
	}

	render() {
		if (this.props.token_info && this.props.token_info.access_token) {
			return <div/>;
		} else if (!this.state.error) {
			return <div>Authorizing...</div>;
		} else {
			return (
				<div>
					<div>Error: {this.state.error.code}</div>
					<div>Description: {this.state.error.description}</div>
				</div>
			);
		}
	}
}


const mapStateToProps = (state, ownProps) => {
	return {
		token_info: state.servers.token_info,
	}
};

const mapDispatchToProps = dispatch => {
	return {
		requestToken: (code) => {
			dispatch(requestTokenActionChain(code))
		}
	}
};


const requestTokenActionChain = (code) => {
	return (dispatch, getState) => {
		tokenService.getOAuthToken(OAUTH_CLIENT_ID, OAUTH_CLIENT_SECRET, OAUTH_REDIRECT_URI, code)
		            .then(token_proxy => {

			            let token_info = token_proxy.data;

			            clientFactory.remove_client_from_cache('data');

			            clientFactory.set_access_token({
				                                           access_token: token_info.access_token,
				                                           refresh_token: token_info.refresh_token
			                                           }, API.data.scope);
			            clientFactory.set_access_token({
				                                           access_token: token_info.access_token,
				                                           refresh_token: token_info.refresh_token
			                                           }, API.auth.scope);

			            dispatch(server_actions.set_oauth_token_info(token_info));

			            if (window.sessionStorage.getItem('wc.path')) {
				            browserHistory.push(window.sessionStorage.getItem('wc.path'))
			            } else {
				            browserHistory.push('/home');
			            }
		            })
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OAuth2AuthorizationProvider));