import React from 'react';
import {Button, Col, Glyphicon, Grid, Nav, Navbar, NavItem, Row, Table} from 'react-bootstrap';

import UserItem from "../components/users/user-item";

class UsersView
	extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			...this.setPermissions(props)
		};
	}

	componentDidMount() {
		this.props.loadUsers();
	}

	UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
		this.setState({
			              ...this.setPermissions(nextProps)
		              });
	}

	render() {
		return (
			<Grid fluid={true} className="h-100">
				<Row className="toolbar">
					<Col md={12}>
						<Navbar fluid={true} className="toolbar">
							<Nav>
								{
									this.state.createEnabled &&
									<NavItem>
										<Button bsClass="btn bar-button" onClick={this.handleAddButtonClick}>
											<Glyphicon glyph="plus" className="c-glyph-icon"/>
											ADD
										</Button>
									</NavItem>
								}
								{
									this.state.editEnabled &&
									<NavItem>
										<Button bsClass="btn bar-button" onClick={this.handleEditButtonClick}>
											<Glyphicon glyph="edit" className="c-glyph-icon"/>
											EDIT
										</Button>
									</NavItem>
								}
								{
									this.state.deleteEnabled &&
									<NavItem>
										<Button bsClass="btn bar-button" onClick={this.handleDeleteButtonClick}>
											<Glyphicon glyph="remove" className="c-glyph-icon"/>
											DELETE
										</Button>
									</NavItem>
								}
							</Nav>
						</Navbar>
					</Col>
				</Row>
				<Row className="content">
					<Col md={12} className="h-100 table-wrapper">
						<Table>
							<thead>
							<tr>
								<th>Login</th>
								<th>Last name</th>
								<th>Name</th>
								<th>Patronymic name</th>
								<th>Email</th>
								<th style={{
									width: '8%',
									maxWidth: '15%'
								}}>Role
								</th>
							</tr>
							</thead>
							<tbody>
							{
								this.props.users && this.props.users.map((item) => {
									return <UserItem key={item.id}
									                 item={item}
									                 onItemClick={this.handleItemClick}
									                 selected={this.props.selectedUser && item.id === this.props.selectedUser.id}/>
								})
							}
							</tbody>
						</Table>
					</Col>
				</Row>
			</Grid>
		);
	}

	handleAddButtonClick = () => {
		this.props.addUser();
	};

	handleEditButtonClick = () => {
		if (!this.props.selectedUser) {
			alert("You must select item from table");
		} else {
			this.props.editUser(this.props.selectedUser);
		}
	};

	handleDeleteButtonClick = () => {
		if (!this.props.selectedUser) {
			alert("You must select item from table");
		} else {
			if (confirm(`Are you sure you want to delete user: ${this.props.selectedUser.user_name}?`)) {
				this.props.deleteUser(this.props.selectedUser);
			}
		}
	};

	handleItemClick = (user) => {
		this.props.selectUser(user);
	};

	setPermissions = (props) => {
		if (props.isSystemAdministrator()) {
			return {
				createEnabled: true,
				editEnabled: true,
				deleteEnabled: true
			}
		} else if (props.isAdministrator(props.selectedProject)) {
			return {
				createEnabled: true,
				editEnabled: true,
				deleteEnabled: true
			}
		} else if (props.isAnalyst(props.selectedProject)) {
			return {
				createEnabled: true,
				editEnabled: true,
				deleteEnabled: true
			}
		} else if (props.isManager(props.selectedProject)) {
			return {
				createEnabled: true,
				editEnabled: true,
				deleteEnabled: true
			}
		} else {
			return {
				createEnabled: true,
				editEnabled: true,
				deleteEnabled: true
			}
		}
	};
}

export default UsersView;