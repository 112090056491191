import Utils from '../utils/utils';
import React from "react";
import {DATA_URL} from "../constants";

const baseUrl = DATA_URL;

class Auth {

	csrfToken = null;
	logged_in_cookie_name = 'LOGGED_IN';

	login = (username, password) => {

		const self = this;

		const executor = (resolve, reject) => {

			const logInRequest = new XMLHttpRequest();
			logInRequest.open('post', `${baseUrl}/log_in`);
			logInRequest.setRequestHeader(self.csrfToken.header_name, self.csrfToken.token);
			logInRequest.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
			logInRequest.onload = function () {
				if (this.status === 200) {

					const authentication = JSON.parse(this.responseText);

					if (authentication.csrf_token) {
						self.csrfToken = {
							header_name: authentication.csrf_token.headerName,
							token: authentication.csrf_token.token
						};
					}

					Utils.setCookie(self.logged_in_cookie_name, true);

					resolve(authentication);
				} else {

					const error = new Error();
					error.code = this.status;

					reject(error);
				}
			};
			logInRequest.onerror = () => {

				const error = new Error();
				error.code = this.status;

				reject(error);
			};
			logInRequest.send(encodeURI('username=' + username + '&password=' + password));
		};

		if (!this.csrfToken) {
			return this.refreshCSRFToken()
				.then(result => {
					return new Promise(executor);
				})

		} else {
			return new Promise(executor);
		}
	};

	getAuthentication = () => {

		const self = this;

		const executor = (resolve, reject) => {

			const authenticationRequest = new XMLHttpRequest();
			authenticationRequest.open("post", `${baseUrl}/authentication`, true);
			authenticationRequest.setRequestHeader(self.csrfToken.header_name, self.csrfToken.token);
			authenticationRequest.onload = function () {
				if (this.status === 200) {

					const authentication = JSON.parse(authenticationRequest.responseText);

					if (authentication.csrf_token) {

						self.csrfToken = {
							header_name: authentication.csrf_token.headerName,
							token: authentication.csrf_token.token
						};
					}

					resolve(authentication);
				} else {

					const error = new Error();
					error.code = this.status;

					Utils.setCookie(self.logged_in_cookie_name, false);

					reject(error);
				}
			};
			authenticationRequest.onerror = () => {

				const error = new Error();
				error.code = this.status;

				Utils.setCookie(self.logged_in_cookie_name, false);

				reject(error);
			};
			authenticationRequest.send();
		};

		if (!this.csrfToken) {
			return this.refreshCSRFToken().then(token => {
				return new Promise(executor);
			});
		} else {
			return new Promise(executor);
		}
	};

	logout = () => {

		const self = this;

		const executor = (resolve, reject) => {

			const http = new XMLHttpRequest();

			http.open("post", `${baseUrl}/log_out`, true);
			http.setRequestHeader(self.csrfToken.header_name, self.csrfToken.token);
			http.onload = () => {
				Utils.deleteCookie(this.logged_in_cookie_name);
				resolve();
			};
			http.onerror = () => {

				const error = new Error();
				error.code = this.status;

				reject(error);
			};
			http.send("");
		};

		if (!this.csrfToken) {
			return this.refreshCSRFToken().then(token => {
				return new Promise(executor);
			});
		} else {
			return new Promise(executor);
		}
	};

	isLoggedIn = () => {
		return !!Utils.getCookie(this.logged_in_cookie_name);
	};

	getCSRFToken = () => {
		return this.csrfToken
	};

	refreshCSRFToken = () => {

		const self = this;

		return new Promise((resolve, reject) => {

			const http = new XMLHttpRequest();
			http.open("get", `${baseUrl}/csrf`, true);
			http.onload = function (e) {
				if (this.status === 200) {

					const tokenInfo = JSON.parse(http.responseText);

					self.csrfToken = {
						header_name: tokenInfo.csrf_token.headerName,
						token: tokenInfo.csrf_token.token
					};

					resolve(tokenInfo);
				} else {

					let error = new Error(this.statusText);
					error.code = this.status;

					reject(error);
				}
			};
			http.onerror = () => {

				const error = new Error();
				error.code = this.status;

				reject(error);
			};
			http.send();
		});
	};


	hasRole = (role, principal) => {
		return principal && principal.role === role;
	};

	hasEntityAuthority = (role, entityId, type, principal) => {
		return principal && principal.entity_roles[type] && principal.entity_roles[type].filter(function (element) {
			return element.entity_id === entityId && element.authority === role;
		}).length !== 0;
	};
}

export default Auth;