import React from "react";
import {Glyphicon} from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroller";
import Utils from "../../utils/utils";
import NotificationItem from "./notification-item";

export default class NotificationsFeed
	extends React.Component {

	constructor(props) {

		super(props);

		this.state = {}
	}

	componentDidMount() {
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps !== this.props && this.props.notifications !== prevProps.notifications) {
			this.setState({
				              loading: false,
				              hasMore: this.props.notifications.length !== prevProps.notifications.length
			              })
		}
	}

	componentDidCatch(error, errorInfo) {
		this.setState({
			              error: true
		              })
	}

	render() {
		if (this.state.error) {
			return (
				<Glyphicon glyph="warning-sign"/>
			)
		}
		return (
			<div className={"h-100 react-scrollable-list"}>
				<InfiniteScroll pageStart={0}
				                loadMore={(page) => {
					                this.setState({
						                              loading: true
					                              },
					                              () => {
						                              this.props.itemsProvider(page)
					                              })
				                }}
				                hasMore={this.state.hasMore}
				                useWindow={false}
				                initialLoad={false}
				                threshold={800}
				                loader={
					                <div className={this.state.loading ? "loader loading" : "loader"} key={0}>Loading ...</div>
				                }
				>
					{
						this.props.notifications
						    .sort(Utils.sortAscendingByProperty('changed'))
						    .map(entry => {
							    return (
								    <div className={"react-scrollable-list-item"}>
									    <NotificationItem key={entry.id}
									                      entry={entry}
									                      project={this.props.projects && this.props.projects.find(
										                      x => x.id == entry.project_id)}
									                      height="94px"
									    />
								    </div>
							    )
						    })
					}
				</InfiniteScroll>
			</div>
		)
	}
}