import React from 'react';
import {Button, Glyphicon, Navbar, Table} from 'react-bootstrap';

import BaseComponent from 'components/base/base-component';
import EventTypesSelectForm from "../event-types-select-form";
import AvailableDictionaryItemsSelect from "../../dialogs/available-dictionary-items-select";
import DeleteConflictOrphansDialog from "../../dialogs/delete-conflict-orphans";


const MODAL_MODE = {
	ADD: 0,
	EDIT: 1
};

class EventTypesTab
	extends BaseComponent {

	constructor(props) {

		super(props);

		this.state = {
			eventTypes: this.props.eventTypes ? this.props.eventTypes : [],
			selectedEventTypes: props.eventTypes ? props.eventTypes : [],
			selected: null,
			showModal: false,
			modalMode: null
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.eventTypes !== this.props.eventTypes) {
			this.setState({
				              eventTypes: this.props.eventTypes ? this.props.eventTypes : [],
			              })
		}
	}

	componentDidCatch(error, errorInfo) {
		this.setState({
			              error: true
		              })
	}

	handleOnItemClick = (item) => {
		this.setState({selected: item.id});
	};

	addButtonHandler = () => {
		this.setState({
			              modalMode: MODAL_MODE.ADD,
			              showModal: true
		              });
	};

	handleOnSaveEventTypes = (eventTypes) => {
		if (this.state.modalMode === MODAL_MODE.ADD) {
			this.props.onAddTypes(eventTypes, (eventTypes) => {
				this.setState({
					              eventTypes: eventTypes,
					              modalMode: null,
					              showModal: false
				              });
			});
		}
	};

	render() {

		if (this.state.error) {
			return <div className="tab-content">Error occurred...</div>
		}

		return (
			<div className="tab-content">
				<Navbar fluid={true} bsClass="c-navbar navbar-default">
					<div className="tab-buttons-group">
						{
							this.props.hasSelectButton &&
							<Button bsClass="btn icon action-button" onClick={this.addButtonHandler}>
								<Glyphicon glyph="plus" className="c-glyph-icon"/>
								<div className="label-wrapper">
									<label>Select</label>
								</div>
							</Button>
						}
					</div>
				</Navbar>
				<div className="table-wrapper">
					<Table>
						<thead>
						<tr>
							<th>Name</th>
							<th>Description</th>
						</tr>
						</thead>
						<tbody>
						{
							this.state.eventTypes
							    .map(item => {
								    return (
									    <tr key={item.id}
									        className={this.state.selectedId && this.state.selectedId === item.id ? 'selected' : ''}>
										    <td>{item.title}</td>
										    <td>{item.description}</td>
									    </tr>
								    );
							    })
						}
						</tbody>
					</Table>
				</div>
				{
					<AvailableDictionaryItemsSelect show={this.state.showModal}
					                                onHide={() => this.setState({showModal: false})}
					                                items={this.props.availableEventTypes}
					                                title={"Event Types"}
					                                selectedItems={this.state.eventTypes}
					                                onSave={this.handleOnSaveEventTypes}
					                                onSelectionChange={(items) => {
						                                this.setState({
							                                              selectedEventTypes: items
						                                              })
					                                }}
					/>
				}
				{
					this.props.orphans && this.props.orphans.length !== 0 &&
					<DeleteConflictOrphansDialog show={this.props.orphans && this.props.orphans.length !== 0}
					                             title={"Orphans"}
					                             items={this.props.orphans}
					                             selectedItem={this.props.selectedOrphan}
					                             itemInfoProvider={this.props.itemInfoProvider}
					                             onItemClicked={
						                             (item) => {
							                             this.props.selectOrphan(item);
						                             }
					                             }
					                             onHide={
						                             () => {
							                             this.props.clearOrphans();
						                             }
					                             }
					                             onProceed={
						                             (e) => {
							                             this.handleOnSaveEventTypes(this.state.selectedEventTypes)
						                             }
					                             }/>
				}
			</div>
		);
	}
}

export default EventTypesTab;