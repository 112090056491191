import React from "react";
import {Button, Col, ControlLabel, Form, FormGroup, Modal} from "react-bootstrap";

class DictionaryItemDialog
	extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			title: props.item ? props.item.title : "",
			description: props.item ? props.item.description : ""
		}
	}

	componentDidMount() {
		this.setState({
			              title: this.props.item ? this.props.item.title : "",
			              description: this.props.item ? this.props.item.description : ""
		              });
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.item !== this.props.item) {
			this.setState({
				              title: this.props.item ? this.props.item.title : "",
				              description: this.props.item ? this.props.item.description : ""
			              });
		}
	}

	render() {
		return (
			<Modal show={this.props.show} onHide={this.props.onHide}>
				<Modal.Header closeButton>
					<Modal.Title>{this.props.title ? this.props.title : ""}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form horizontal={true}>
						<FormGroup>
							<Col componentClass={ControlLabel} sm={2}>
								Title
							</Col>
							<Col sm={10}>
								<input className="form-control"
								       type="text"
								       onChange={this.setEventValue.bind(this, 'title')}
								       value={this.state.title}/>
							</Col>
						</FormGroup>
						<FormGroup>
							<Col componentClass={ControlLabel} sm={2}>
								Description
							</Col>
							<Col sm={10}>
                                <textarea className="form-control"
                                          rows="5"
                                          onChange={this.setEventValue.bind(this, 'description')}
                                          value={this.state.description}/>
							</Col>
						</FormGroup>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button bsClass="btn pull-right action-button" onClick={this.props.onHide}>Cancel</Button>
					<Button bsClass="btn pull-right action-button" onClick={this.props.item && this.props.item.id ? this.handleSave : this.handleCreate}>{this.props.item && this.props.item.id ? "Save" : "Create"}</Button>
				</Modal.Footer>
			</Modal>
		);
	}

	setEventValue(field, event) {
		//If the input fields were directly within this
		//this component, we could use this.refs.[FIELD].value
		//Instead, we want to save the data for when the form is submitted
		const object = {};
		object[field] = event.target.value;
		this.setState(object);
	}

	setEventChecked(field, event) {
		//If the input fields were directly within this
		//this component, we could use this.refs.[FIELD].value
		//Instead, we want to save the data for when the form is submitted
		const object = {};
		object[field] = event.target.checked;
		this.setState(object);
	}

	setValue(field, value) {
		//If the input fields were directly within this
		//this component, we could use this.refs.[FIELD].value
		//Instead, we want to save the data for when the form is submitted
		const object = {};
		object[field] = value;
		this.setState(object);
	}

	handleCreate = () => {

		const item = this.props.item ? this.props.item : {};
		item.title = this.state.title;
		item.description = this.state.description;

		this.props.onCreate(item);
	}

	handleSave = () => {

		const item = this.props.item ? this.props.item : {};
		item.title = this.state.title;
		item.description = this.state.description;

		this.props.onSave(item);
	}
}

export default DictionaryItemDialog;