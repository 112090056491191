import React from 'react';
import {Button, Col, ControlLabel, Form, FormGroup, Glyphicon, Image, Tab, Tabs} from 'react-bootstrap';
import {DropdownList, NumberPicker} from 'react-widgets';

import {AttachmentsTab, CommentsTab, LinksTab} from '../components/common/tab';
import {scenarioService} from '../services';
import Utils from '../utils/utils';
import EventsTab from "../components/common/tab/events-tab";
import LinkForm from "../components/common/link-form";
import AttachmentForm from "../components/common/attachment-form";
import SimpleSelectionForm from "../components/base/simple-selection-form";
import {mixinFactory as OperationMixinFactory} from "../mixins/operation";
import {mixinFactory as ChangedStateMixinFactory} from "../mixins/changed-state";

class ScenarioView
	extends React.Component {

	constructor(props) {

		super(props);

		Object.assign(ScenarioView.prototype, OperationMixinFactory({
			                                                            operationPropName: 'scenarioOperation',
			                                                            operationStateName: 'operationStatus'
		                                                            }))

		Object.assign(ScenarioView.prototype, ChangedStateMixinFactory({
			                                                               scope: 'change_detector',
			                                                               observable: 'scenario',
			                                                               excludedFields: [
				                                                               'activeTab',
				                                                               'operationStatus',
				                                                               'showLinkModal',
				                                                               'showAttachmentModal',
				                                                               'author',
				                                                               'saveEnabled',
				                                                               'addLinkEnabled',
				                                                               'editLinkEnabled',
				                                                               'removeLinkEnabled',
				                                                               'addMessageEnabled',
				                                                               'editMessageEnabled',
				                                                               'deleteMessageEnabled',
				                                                               'addAttachmentEnabled',
				                                                               'editAttachmentEnabled',
				                                                               'deleteAttachmentEnabled',
				                                                               'addRelationEnabled',
				                                                               'deleteRelationEnabled',
				                                                               'addEventEnabled',
				                                                               'deleteEventEnabled'
			                                                               ],
			                                                               stateField: 'dirty'
		                                                               }))

		this.state = {
			title: props.scenario ? props.scenario.title : "",
			narrative: props.scenario ? props.scenario.narrative : "",
			probability: props.scenario ? props.scenario.probability : 0,
			impact: props.scenario ? props.scenario.impact : 0,
			flag: props.scenario ? (props.scenario.flag ? props.scenario.flag : null) : null,
			activeTab: 1,
			showLinkModal: !!props.link,
			showAttachmentModal: !!props.attachment,
			...this.setPermissions(props)
		};

		if (props.scenario) {
			this.change_detector.setInitialState(this.state);
		}

		this.flagItem = ({item}) => {
			if (item) {
				return <Image src={Utils.getResourceUri(item.path)}/>
			} else {
				return null;
			}
		};
	}

	componentDidMount() {

		if (this.props.project && this.props.match.params.scenarioId !== "0" && !this.props.scenario) {
			this.props.loadScenario(this.props.project, this.props.match.params.scenarioId);
		}

		if (!this.props.flags) {
			this.props.loadFlags();
		}

		if (this.props.project && !this.props.events) {
			this.props.loadEvents(this.props.project);
		}

		this.operationState(null, null);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (prevProps.scenario !== this.props.scenario && this.props.scenario) {
			this.setState({
				              title: this.props.scenario ? this.props.scenario.title : "",
				              narrative: this.props.scenario ? this.props.scenario.narrative : "",
				              probability: this.props.scenario ? this.props.scenario.probability : 0,
				              impact: this.props.scenario ? this.props.scenario.impact : 0,
				              flag: this.props.scenario ? (this.props.scenario.flag ? this.props.scenario.flag : null) : null,
				              showLinkModal: !!this.props.link,
				              showAttachmentModal: !!this.props.attachment,
				              ...this.setPermissions(this.props)
			              },
			              () => {
				              this.change_detector.setInitialState(this.state);
			              });
		}

		this.operationState(prevProps, prevState);
		this.detectChanged(prevProps, prevState);
	}

	setPermissions = (props) => {
		if (props.project && props.isAdministrator(props.project)) {
			return {
				saveEnabled: true,
				addLinkEnabled: true,
				editLinkEnabled: true,
				removeLinkEnabled: true,
				addMessageEnabled: true,
				editMessageEnabled: true,
				deleteMessageEnabled: true,
				addAttachmentEnabled: true,
				editAttachmentEnabled: true,
				deleteAttachmentEnabled: true,
				addRelationEnabled: true,
				deleteRelationEnabled: true,
				addEventEnabled: true,
				deleteEventEnabled: true
			}
		} else if (props.project && props.isAnalyst(props.project)) {
			return {
				saveEnabled: true,
				addLinkEnabled: true,
				editLinkEnabled: true,
				removeLinkEnabled: true,
				addMessageEnabled: true,
				editMessageEnabled: false,
				deleteMessageEnabled: false,
				addAttachmentEnabled: true,
				editAttachmentEnabled: true,
				deleteAttachmentEnabled: true,
				addRelationEnabled: true,
				deleteRelationEnabled: true,
				addEventEnabled: true,
				deleteEventEnabled: true
			}
		} else if (props.project && props.isManager(props.project)) {
			return {
				saveEnabled: false,
				addLinkEnabled: true,
				editLinkEnabled: true,
				removeLinkEnabled: true,
				addMessageEnabled: true,
				editMessageEnabled: false,
				deleteMessageEnabled: false,
				addAttachmentEnabled: true,
				editAttachmentEnabled: true,
				deleteAttachmentEnabled: true,
				addRelationEnabled: true,
				deleteRelationEnabled: true,
				addEventEnabled: true,
				deleteEventEnabled: true
			}
		} else {
			return {
				saveEnabled: false,
				addLinkEnabled: false,
				editLinkEnabled: false,
				removeLinkEnabled: false,
				addMessageEnabled: true,
				editMessageEnabled: false,
				deleteMessageEnabled: false,
				addAttachmentEnabled: false,
				editAttachmentEnabled: false,
				deleteAttachmentEnabled: false,
				addRelationEnabled: false,
				deleteRelationEnabled: false,
				addEventEnabled: true,
				deleteEventEnabled: true
			}
		}
	};

	render() {

		let saveButtonClass = "btn action-button pull-right";

		if (this.state.operationStatus === "IN_PROGRESS") {
			saveButtonClass = saveButtonClass + " pending";
		} else {
			if (this.state.operationStatus && this.state.operationStatus !== "NOT_PENDING") {
				saveButtonClass = this.state.operationStatus === "SUCCESS" ? saveButtonClass + " btn-success" : saveButtonClass + " btn-danger"
			}
		}

		let changes_indicator;

		if (this.props.scenario && this.props.scenario.id && (this.state.dirty || this['change_detector'].initialization_counter > 0)) {
			changes_indicator = this.state.dirty ? <div className="dirty-indicator">Changes unsaved</div> : <div className="dirty-indicator success"><Glyphicon glyph={"ok"}/>Changes saved</div>;
		}

		let tabs = null;

		if (this.props.scenario && this.props.scenario.id) {
			tabs = this.renderTabs();
		}

		return (
			<Form horizontal={true} className="h-100 scenario-form">
				<FormGroup>
					<Col componentClass={ControlLabel} md={1}>
						Title:
					</Col>
					<Col md={10}>
						<input className="form-control"
						       type="text"
						       onChange={this.setEventValue.bind(this, 'title')}
						       value={this.state.title ? this.state.title : ""}/>
					</Col>
				</FormGroup>
				<FormGroup>
					<Col componentClass={ControlLabel} md={1}>
						Narrative:
					</Col>
					<Col md={10}>
                            <textarea className="form-control"
                                      rows="5"
                                      onChange={this.setEventValue.bind(this, 'narrative')}
                                      value={this.state.narrative}/>
					</Col>
				</FormGroup>
				<FormGroup>
					<Col componentClass={ControlLabel} md={1}>
						Probability:
					</Col>
					<Col md={2}>
						<NumberPicker onChange={this.setValue.bind(this, 'probability')}
						              defaultValue={this.state.probability ? this.state.probability : 0}
						              min={0}
						              max={100}
						              value={this.state.probability}/>
					</Col>
				</FormGroup>
				<FormGroup>
					<Col componentClass={ControlLabel} md={1}>
						Impact:
					</Col>
					<Col md={2}>
						<NumberPicker onChange={this.setValue.bind(this, 'impact')}
						              defaultValue={this.state.impact ? this.state.impact : 0}
						              min={0}
						              max={10}
						              value={this.state.impact}/>
					</Col>
				</FormGroup>
				<FormGroup>
					<Col componentClass={ControlLabel} md={1}>
						Flag:
					</Col>
					<Col md={1}>
						<DropdownList data={this.props.flags ? this.props.flags : []}
						              onChange={this.setValue.bind(this, 'flag')}
						              value={this.state.flag}
						              itemComponent={this.flagItem}
						              valueComponent={this.flagItem}
						/>
					</Col>
				</FormGroup>
				<FormGroup className="tabs-row">
					{tabs}
				</FormGroup>
				<FormGroup className="form-buttons-bar">
					<Col md={12}>
						<Button bsClass="btn action-button pull-right" onClick={this.cancelButtonHandler}>
							Cancel
						</Button>
						{
							this.state.saveEnabled &&
							<Button bsClass={saveButtonClass}
							        onClick={this.state.operationStatus !== "IN_PROGRESS" ? this.saveButtonHandler : null}>
								{this.props.scenario && this.props.scenario.id ? "Save" : "Create"}
							</Button>
						}
						{changes_indicator}
					</Col>
				</FormGroup>
				{
					this.state.showEventModal &&
					(
						<SimpleSelectionForm show={this.state.showEventModal}
						                     onHide={() => this.setState({showEventModal: false})}
						                     items={this.props.events}
						                     default={this.props.events[0] ? this.props.events[0] : null}
						                     field='title'
						                     onSave={this.handleAddEvent}
						/>
					)
				}
			</Form>
		)
	}

	renderTabs = () => {

		const self = this;

		return (
			<Tabs activeKey={this.state.activeTab} onSelect={this.tabSelectionHandler} id="scenario-tabs"
			      className="h-100">
				<Tab eventKey={1} title="Events">
					<EventsTab events={this.props.scenario ? this.props.scenario.events : []}
					           selectedItem={this.props.selectedEvent}
					           onItemClick={this.handleSelectEvent}
					           hasAddButton={self.state.addEventEnabled}
					           hasDeleteButton={self.state.deleteEventEnabled}
					           onAdd={() => {
						           self.setState({
							                         showEventModal: true
						                         });
					           }}
					           onDelete={(event) => {
						           if (!self.props.selectedEvent) {
							           alert("You must select item from table");
						           } else {
							           if (confirm(`Are you sure you want to delete event: ${this.props.selectedEvent.title}?`)) {
								           self.props.deleteEvent(self.props.project, self.props.scenario, self.props.selectedEvent);
							           }
						           }
					           }}
					/>
				</Tab>
				<Tab eventKey={2} title="Attachments">
					<AttachmentsTab items={this.props.scenario.attachments}
					                selectedItem={self.props.selectedAttachment}
					                hasAddButton={self.state.addAttachmentEnabled}
					                hasEditButton={self.state.editAttachmentEnabled}
					                hasDeleteButton={self.state.deleteAttachmentEnabled}
					                onItemClick={this.selectAttachmentHandler}
					                onAdd={
						                () => {
							                self.setState({
								                              showAttachmentModal: true
							                              });
						                }
					                }
					                onEdit={
						                (attachment) => {
							                if (!self.props.selectedAttachment) {
								                alert("You must select item from table");
							                } else {
								                this.props.editAttachment(this.props.project, this.props.scenario,
								                                          this.props.selectedAttachment);
							                }
						                }
					                }
					                onDelete={
						                (attachment) => {
							                if (!self.props.selectedAttachment) {
								                alert("You must select item from table");
							                } else {
								                if (confirm(`Are you sure you want to delete attachment: ${this.props.selectedAttachment.name}?`)) {
									                this.props.delete(this.props.project, this.props.scenario,
									                                  this.props.selectedAttachment);
								                }
							                }
						                }
					                }
					/>
					{
						this.state.showAttachmentModal && (
							<AttachmentForm show={this.state.showAttachmentModal}
							                onHide={this.cancelAttachmentHandler}
							                attachment={this.props.attachment}
							                onSave={this.saveAttachmentHandler}
							/>
						)
					}
				</Tab>
				<Tab eventKey={3} title="Links/Sources">
					<LinksTab items={self.props.scenario ? self.props.scenario.links : []}
					          selectedItem={self.props.selectedLink}
					          hasAddButton={self.state.addLinkEnabled}
					          hasEditButton={self.state.editLinkEnabled}
					          hasDeleteButton={self.state.removeLinkEnabled}
					          onItemClick={this.selectLinkHandler}
					          onAdd={
						          (link) => {
							          this.setState({
								                        showLinkModal: true
							                        });
						          }
					          }
					          onEdit={
						          (link) => {
							          if (!self.props.selectedLink) {
								          alert("You must select item from table");
							          } else {
								          this.props.editLink(this.props.project, this.props.scenario,
								                              this.props.selectedLink);
							          }
						          }
					          }
					          onDelete={
						          (link) => {
							          if (!self.props.selectedLink) {
								          alert("You must select item from table");
							          } else {
								          if (confirm(`Are you sure you want to delete link: ${this.props.selectedLink.name}?`)) {
									          this.props.delete(this.props.project, this.props.scenario,
									                            this.props.selectedLink);
								          }
							          }
						          }
					          }
					/>
					{
						this.state.showLinkModal && (
							<LinkForm show={this.state.showLinkModal}
							          onHide={this.cancelLinkHandler}
							          link={this.props.link}
							          onSave={this.saveLinkHandler}
							/>
						)
					}
				</Tab>
				<Tab eventKey={4} title="Discussion">
					<CommentsTab comments={this.props.scenario.comments}
					             isCommentEditable={(comment) => {
						             return self.props.isAdministrator(this.props.project) || self.props.isEntityOwner(
							             comment, 'ScenarioComment');
					             }}
					             isCommentRemovable={(comment) => {
						             return self.props.isAdministrator(this.props.project) || self.props.isEntityOwner(
							             comment, 'ScenarioComment');
					             }}
					             onAddComment={(comment, cb) => {
						             comment.scenario = {
							             id: this.props.scenario.id,
							             retrospectionId: this.props.scenario.retrospectionId
						             };
						             scenarioService.create(this.props.project.id, this.props.scenario.id, comment)
						                            .then(upComment => {
							                            if (cb) {
								                            cb(upComment);
							                            }
						                            });
					             }}
					             onEditComment={(comment, cb) => {
						             comment.scenario = {
							             id: this.props.scenario.id,
							             retrospectionId: this.props.scenario.retrospectionId
						             };
						             scenarioService.save(this.props.project.id, this.props.scenario.id, comment)
						                            .then(upComment => {
							                            if (cb) {
								                            cb(upComment);
							                            }
						                            });
					             }}
					             onDeleteComment={(commentId, cb) => {
						             if (confirm(`Are you sure you want to delete comment?`)) {
							             scenarioService.delete(this.props.project.id, this.props.scenario.id, commentId)
							                            .then(upCommentId => {
								                            if (cb) {
									                            cb(upCommentId);
								                            }
							                            });
						             }
					             }}
					/>
				</Tab>
			</Tabs>
		)
	};

	tabSelectionHandler = (tabIndex) => {
		this.setState({activeTab: tabIndex});
	};

	selectLinkHandler = (link) => {
		this.props.selectLink(link);
	};

	saveLinkHandler = (link) => {
		this.props.save(this.props.project, this.props.scenario, link);
	};

	cancelLinkHandler = () => {
		this.setState({
			              showLinkModal: false
		              });
		this.props.clearLink();
	};

	selectAttachmentHandler = (attachment) => {
		this.props.selectAttachment(attachment);
	};

	saveAttachmentHandler = (attachment) => {
		this.props.save(this.props.project, this.props.scenario, attachment);
	};

	cancelAttachmentHandler = () => {
		this.setState({
			              showAttachmentModal: false
		              });
		this.props.clearAttachment();
	};

	saveButtonHandler = () => {

		const scenario = this.props.scenario ? this.props.scenario : {};

		scenario.project = scenario.project ? scenario.project : {id: this.props.project.id};
		scenario.title = this.state.title;
		scenario.narrative = this.state.narrative;
		scenario.probability = this.state.probability;
		scenario.impact = this.state.impact;
		scenario.flag = this.state.flag;

		this.props.save(this.props.project, scenario);
	};

	cancelButtonHandler = () => {
		this.props.history.goBack();
	};

	handleAddEvent = (event) => {
		this.props.addEvent(this.props.project, this.props.scenario, event);
		this.setState({
			              showEventModal: false
		              });
	};

	handleSelectEvent = (item) => {
		this.props.selectEvent(item);
	};

	setEventValue = (field, event) => {
		//If the input fields were directly within this
		//this component, we could use this.refs.[FIELD].value
		//Instead, we want to save the data for when the form is submitted
		const object = {};
		object[field] = event.target.value;
		this.setState(object);
	};

	setEventChecked = (field, event) => {
		//If the input fields were directly within this
		//this component, we could use this.refs.[FIELD].value
		//Instead, we want to save the data for when the form is submitted
		const object = {};
		object[field] = event.target.checked;
		this.setState(object);
	};

	setValue = (field, value) => {
		//If the input fields were directly within this
		//this component, we could use this.refs.[FIELD].value
		//Instead, we want to save the data for when the form is submitted
		const object = {};
		object[field] = value;
		this.setState(object);
	};
}

export default ScenarioView;