import {connect} from "react-redux";
import {withRouter} from "react-router";
import * as factorsActions from "../actions/factors";
import EventsMapView from "../views/project-events-map";
import * as filersActions from "../actions/filters";
import * as eventsActions from "../actions/events";
import * as event_actions from "../actions/events";
import {appendEvent, receiveEvent} from "../actions/events";
import * as trendsActions from "../actions/trends";
import * as trendActions from "../actions/trends";
import * as colorSchemaActions from "../actions/color-schema";
import {authorities} from "../mixins/authorities";
import {eventRelationsService, eventService, projectService, trendService} from "../services";
import Utils from "../utils/utils";
import * as operationActions from "../actions/operations";
import * as eventRelationsActions from "../actions/event-relations";
import * as scenariosActions from "../actions/scenarios";
import * as projectsActions from "../actions/projects";
import DateUtils from "../utils/date";
import {browserHistory} from "../app";
import {CLIENT_URI_CONSTANTS as URI} from "../utils/uri-constants";
import * as eventCategoriesAction from "../actions/event-categories";
import * as eventTypesAction from "../actions/event-types";
import * as eventSourcesAction from "../actions/event-sources";

const mapStateToProps = (state, ownProps) => {
	return {
		project: state.projectReducer.project,
		factors: state.factorReducer.factors,
		event: state.eventsReducer.event,
		events: state.eventsReducer.events,
		selectedEvents: state.eventsReducer.selectedEvents,
		trends: state.trendsReducer.trends,
		selectedTrend: state.trendsReducer.selectedTrend,
		colorSchema: state.colorSchemaReducer.schema,
		operationReport: state.operation.report,
		eventsRelations: state.eventRelationsReducer.eventsRelations,
		flags: state.flagsReducer.flags,
		projectEventTypes: state.eventTypesReducer.projectEventTypes,
		eventTypes: state.eventTypesReducer.eventTypes,
		projectEventCategories: state.eventCategoriesReducer.projectEventCategories,
		eventCategories: state.eventCategoriesReducer.eventCategories,
		projectEventSources: state.eventSourcesReducer.projectEventSources,
		eventSources: state.eventSourcesReducer.eventSources,
		numericUnits: state.numericUnitsReducer.numericUnits,
		...authorities(state.authenticationReducer.authentication)
	}
};

function loadInitialDataActionChain() {
	return (dispatch, getState) => {

		let project = getState().projectReducer.project;

		dispatch(factorsActions.receiveFactors(null));
		dispatch(eventsActions.receiveEvents(null));
		dispatch(trendsActions.receiveTrends(null));

		dispatch(factorsActions.fetchFactors(project.id, project.eventsMap.id));
		dispatch(eventsActions.fetchEvents(project.id, project.eventsMap.id, null));
		dispatch(trendsActions.fetchTrends(project.id, project.eventsMap.id));

		let user = getState().authenticationReducer.authentication.principal;

		dispatch(colorSchemaActions.fetchColorSchema(project.id, user.id))
		dispatch(eventRelationsActions.fetchEventRelations(project.id, project.eventsMap.id))
	};
}

function moveEventActionChain(event) {
	return (dispatch, getState) => {

		let project = getState().projectReducer.project;

		eventService
			.save(project.id, project.eventsMap.id, event)
			.then(event_proxy => {
				dispatch(eventRelationsActions.fetchEventRelations(project.id, project.eventsMap.id))
				dispatch(trendsActions.fetchTrends(project.id, project.eventsMap.id))
				dispatch(eventsActions.insertEvent(event_proxy.data));
			})
	};
}

let saveEventActionChain = (event) => {
	return (dispatch, getState) => {
		dispatch(event_actions.requestEvent())
		if (event.id) {

			let project = getState().projectReducer.project;

			eventService.save(project.id, project.eventsMap.id, event)
			            .then(event_proxy => {
				            dispatch(eventsActions.insertEvent(event_proxy.data));
			            });
		}
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		loadInitialData: () => {
			dispatch(loadInitialDataActionChain())
		},
		loadFactors: (project) => {
			dispatch(factorsActions.fetchFactors(project.id, project.eventsMap.id))
		},
		loadEvents: (project, filter) => {
			dispatch(eventsActions.fetchEvents(project.id, project.eventsMap.id, filter))
		},
		loadTrends: (project) => {
			dispatch(trendsActions.fetchTrends(project.id, project.eventsMap.id))
		},
		loadColorSchema: (project, user) => {
			dispatch(colorSchemaActions.fetchColorSchema(project.id, user.id))
		},
		loadFilter: (project, filterId) => {
			dispatch(filersActions.requestEventsMapFilter(project.id, project.eventsMap.id, filterId));
		},
		selectEvents: (events) => {
			dispatch(eventsActions.selectEvents(events));
		},
		selectTrend: (trend) => {
			dispatch(trendsActions.selectTrend(trend));
		},
		addEvent: (project, factor, date) => {
			const neededDate = !date ? (project.current_date ? project.current_date : new Date()) : date;
			dispatch(eventsActions.receiveEvent({
				                                    factor: factor,
				                                    date: neededDate
			                                    }));
			browserHistory.push(`/${URI.PROJECT}/${project.id}/events-map/?event-id=0#add`);
		},
		editEvent: (project, event) => {
			dispatch(event_actions.receiveEvent(null))
			browserHistory.push(`/${URI.PROJECT}/${project.id}/events-map/?event-id=${event.id}#edit`);
		},
		createEvent: (event) => {
			let createEvent = (event) => {
				return (dispatch, getState) => {
					dispatch(event_actions.requestEvent())

					let project = getState().projectReducer.project;

					event.future_map_id = project.eventsMap.id;
					event.author = null;

					eventService.create(project.id, project.eventsMap.id, event)
					            .then(event_proxy => {
						            dispatch(receiveEvent(event_proxy.data));
						            dispatch(appendEvent(event_proxy.data));
						            browserHistory.push(`/${URI.PROJECT}/${project.id}/events-map`)
					            });
				}
			};
			dispatch(createEvent(event));
		},
		saveEvent: (event) => {
			dispatch(saveEventActionChain(event))

		},
		moveEvent: (project, event) => {
			dispatch(moveEventActionChain(event));
		},
		deleteEvent: (project, eventId) => {
			dispatch(eventsActions.deleteEvent(project.id, project.eventsMap.id, eventId));
		},
		addTrend: (project, factor, startDate, endDate) => {
			dispatch(trendsActions.addTrend(project, {
				factor: factor,
				start: startDate,
				end: endDate
			}));
		},
		editTrend: (project, trend) => {
			dispatch(trendsActions.receiveTrend(null));
			browserHistory.push(`/${URI.PROJECT}/${project.id}/${URI.TRENDS}/${trend.id}`);
		},
		exportEvents: (project) => {
			eventService.exportEvents(project.id, project.eventsMap.id)
			            .then((attachment_proxy) => {
				            Utils.saveByteArray([Utils.b64toBlob(attachment_proxy.data.file_data)],
				                                attachment_proxy.data.name + attachment_proxy.data.extension);
			            });
		},
		importEvents: (project, file) => {

			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = function () {
				const attachment = {};

				const nameParts = file.name.split('.');

				attachment.extension = nameParts[nameParts.length - 1];
				attachment.name = nameParts.length > 1 ? nameParts[0] : "";
				attachment.file_data = reader.result.split(',')[1];
				attachment.upload_date = new Date();

				dispatch(eventsActions.importEvents(project.id, project.eventsMap.id, attachment));
			};
			reader.onerror = function (error) {
				console.log('Error: ', error);
			};

		},
		clearOperationReport: () => {
			dispatch(operationActions.receiveReport(null));
		},
		loadEventRelations: (project) => {
			dispatch(eventRelationsActions.fetchEventRelations(project.id, project.eventsMap.id))
		},
		saveEventRelation: (project, relation) => {
			eventRelationsService.create(project.id, project.eventsMap.id, relation)
			                     .then(upRelation => {
				                     dispatch(eventRelationsActions.fetchEventRelations(project.id, project.eventsMap.id))
				                     dispatch(event_actions.fetchEvents(project.id, project.eventsMap.id))
			                     });
		},
		addScenario: (project) => {
			dispatch(scenariosActions.addScenario(project));
		},
		saveCurrentDate: (project, date, dynamic) => {

			project.day_d = DateUtils.convertToISOUTCDatetime(DateUtils.startOfDay(date));
			project.autoincrement_day_d = dynamic;

			dispatch(projectsActions.saveProject(project));
		},
		addProjectEventCategories: (event_categories) => {
			dispatch(addProjectEventCategoriesActionChain(event_categories))
		},
		addProjectEventSources: (event_sources) => {
			dispatch(addProjectEventSourcesActionChain(event_sources))
		},
		addProjectEventTypes: (event_types) => {
			dispatch(addProjectEventTypesActionChain(event_types))
		},
		addEventToTrend: (event, trend) => {
			dispatch(addEventToTrendActionChain(event, trend))

		},
	}
};

function addEventToTrendActionChain(event, trend) {
	return (dispatch, getState) => {

		let project = getState().projectReducer.project;

		trendService.addEvent(project.id, project.eventsMap.id, trend.id, event.id).then(trend_proxy => {
			dispatch(trendActions.fetchTrends(project.id, project.eventsMap.id));
		})
	}
}

function addProjectEventCategoriesActionChain(event_categories) {
	return (dispatch, getState) => {

		let project = getState().projectReducer.project;

		projectService
			.addEventCategories(project.id, event_categories)
			.then(event_categories_proxy => {
				dispatch(eventCategoriesAction.receiveProjectEventCategories(event_categories_proxy.data))
			})
	};
}

function addProjectEventSourcesActionChain(event_sources) {
	return (dispatch, getState) => {

		let project = getState().projectReducer.project;

		projectService
			.addEventSources(project.id, event_sources)
			.then(event_sources_proxy => {
				dispatch(eventSourcesAction.receiveProjectEventSources(event_sources_proxy.data))
			})
	};
}

function addProjectEventTypesActionChain(event_types) {
	return (dispatch, getState) => {

		let project = getState().projectReducer.project;

		projectService
			.addEventTypes(project.id, event_types)
			.then(event_types => {
				dispatch(eventTypesAction.receiveProjectEventTypes(event_types.data))
			})
	};
}

const EventsMapContainer = connect(mapStateToProps, mapDispatchToProps)(EventsMapView);

export default withRouter(EventsMapContainer)