import clientFactory from "../factories/http_client";
import {API} from "../api/api";

class ScenarioAttachmentService {

	/** @return  Promise<AxiosResponse<T>> */
	get = (projectId, scenarioId, attachmentId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.scenarios.id.attachments.id.method,
			                             url: API.data.projects.id.scenarios.id.attachments.id.url(projectId, scenarioId, attachmentId)
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	create = (projectId, scenarioId, attachment) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.scenarios.id.attachments.create.method,
			                             url: API.data.projects.id.scenarios.id.attachments.create.url(projectId, scenarioId),
			                             data: API.data.projects.id.scenarios.id.attachments.create.build(attachment),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	save = (projectId, scenarioId, attachment) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.scenarios.id.attachments.save.method,
			                             url: API.data.projects.id.scenarios.id.attachments.save.url(projectId, scenarioId),
			                             data: API.data.projects.id.scenarios.id.attachments.save.build(attachment),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	delete = (projectId, scenarioId, attachmentId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.scenarios.id.attachments.delete.method,
			                             url: API.data.projects.id.scenarios.id.attachments.delete.url(projectId, scenarioId),
			                             data: API.data.projects.id.scenarios.id.attachments.delete.build(attachmentId),
		                             });
	};
}

export default ScenarioAttachmentService