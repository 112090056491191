import {authorities} from "../mixins/authorities";
import * as trendActions from "../actions/trends";
import * as eventActions from "../actions/events";
import * as factorsActions from "../actions/factors";
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import TrendView from "../views/trend";

const mapStateToProps = (state, ownProps) => {
	return {
		project: state.projectReducer.project,
		factors: state.factorReducer.factors,
		events: state.eventsReducer.events,
		trend: state.trendsReducer.trend,
		selectedEvent: state.trendsReducer.selectedEvent,
		trendOperation: state.trendsReducer.operation,
		...authorities(state.authenticationReducer.authentication)
	}
};

const mapDispatchToProps = (dispatch, state) => {
	return {
		loadTrend: (project, id) => {
			dispatch(trendActions.fetchTrend(project.id, project.eventsMap.id, id))
		},
		loadEvents: (project) => {
			dispatch(eventActions.fetchEvents(project.id, project.eventsMap.id))
		},
		loadFactors: (project) => {
			dispatch(factorsActions.fetchFactors(project.id, project.eventsMap.id))
		},
		saveTrend: (project, trend) => {
			if (!trend.id) {
				trend.future_map_id = project.eventsMap.id;
				dispatch(trendActions.createTrend(project.id, project.eventsMap.id, trend));
			} else {
				dispatch(trendActions.saveTrend(project.id, project.eventsMap.id, trend));
			}
		},
		addEvent: (project, trend, event) => {
			dispatch(trendActions.addEvent(project.id, project.eventsMap.id, trend.id, event.id));
		},
		removeEvent: (project, trend, event) => {
			dispatch(trendActions.removeEvent(project.id, project.eventsMap.id, trend.id, event.id));
		},
		selectEvent: (event) => {
			dispatch(trendActions.selectEvent(event));
		},
		clearOperation: () => {
			dispatch(trendActions.startOperation(null));
		}
	}
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TrendView));