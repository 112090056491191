import clientFactory from "../factories/http_client";
import {API} from "../api/api";

export const actionTypes = {
	REQUEST_AUTHENTICATION: 'REQUEST_AUTHENTICATION',
	RECEIVE_AUTHENTICATION: 'RECEIVE_AUTHENTICATION',
};

export const requestAuthentication = () => {
	return {
		type: actionTypes.REQUEST_AUTHENTICATION
	}
};

export const receiveAuthentication = (response) => {
	return {
		type: actionTypes.RECEIVE_AUTHENTICATION,
		data: response
	}
};

export const logout = () => {
	return (dispatch, getState) => {
		clientFactory
			.client('auth')
			.request({
				         method: API.auth.logout.method,
				         url: API.auth.logout.url,
			         })
			.then(r => {
			})
	}
};