import dateFormatter from "dateformat";

class DateUtils {

	static endOfYear = (initialDate) => {
		const date = new Date(initialDate.getTime());
		date.setFullYear(date.getFullYear(), 11, 31);
		date.setHours(23, 59, 59, 999);
		return date;
	};

	static endOfMonth = (initialDate) => {

		const date = new Date(initialDate.getTime());
		date.setFullYear(date.getFullYear());
		date.setMonth(date.getMonth() + 1);
		date.setDate(0);
		date.setHours(23, 59, 59, 999);
		
		return date;
	};

	static endOfDay = (initialDate) => {
		const date = new Date(initialDate.getTime());
		date.setHours(23, 59, 59, 999);
		return date;
	};

	static startOfYear = (initialDate) => {
		const date = new Date(initialDate);
		date.setFullYear(date.getFullYear(), 0, 1);
		date.setHours(0, 0, 0, 0);
		return date;
	};

	static startOfMonth = (initialDate) => {
		const date = new Date(initialDate);
		date.setFullYear(date.getFullYear(), date.getMonth(), 1);
		date.setHours(0, 0, 0, 0);
		return date;
	};

	static startOfDay = (initialDate) => {
		const date = new Date(initialDate);
		date.setHours(0, 0, 0, 0);
		return date;
	};

	static addDay(initialDate, count) {
		const date = new Date(initialDate.getTime());
		date.setDate(date.getDate() + count);
		return date;
	}

	static addMonth(initialDate, count) {
		const date = new Date(initialDate.getTime());
		date.setMonth(date.getMonth() + count);
		return date;
	}

	static addYear(initialDate, count) {
		const date = new Date(initialDate.getTime());
		date.setFullYear(date.getFullYear() + count);
		return date;
	}

	static subtractDay(initialDate, count) {
		const date = new Date(initialDate.getTime());
		date.setDate(date.getDate() - count);
		return date;
	}

	static subtractMonth(initialDate, count) {
		const date = new Date(initialDate.getTime());
		date.setFullYear(initialDate.getFullYear());

		if (initialDate.getDate() !== 28 && initialDate.getDate() !== 29) {
			date.setMonth(initialDate.getMonth() - 1);
		}

		if (initialDate.getMonth() === date.getMonth())  {
			date.setDate(0);
		}
		return date;
	}

	static subtractYear(initialDate, count) {
		const date = new Date(initialDate.getTime());
		date.setFullYear(date.getFullYear() - count);
		return date;
	}

	static dateDiffInDays(a, b) {

		const _MS_PER_DAY = 1000 * 60 * 60 * 24;

		const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
		const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

		return Math.floor((utc2 - utc1) / _MS_PER_DAY);
	}

	static convertToISOUTCDatetime(date) {
		return dateFormatter(date, dateFormatter.masks['isoUtcDateTime']);
	}

	static formatDate(date) {
		const dateFormat = "yyyy-mm-dd";
		return dateFormatter(date, dateFormat);
	}


}

export default DateUtils