import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import * as event_categories_actions from "../actions/event-categories";
import * as orphans_actions from "../actions/orphans";
import EventCategoriesView from "../views/event-categories";
import {authorities} from "../mixins/authorities";
import {eventCategoryService} from "../services";

const mapStateToProps = (state, ownProps) => {
	return {
		eventCategories: state.eventCategoriesReducer.eventCategories,
		selectedEventCategory: state.eventCategoriesReducer.selectedEventCategory ? state.eventCategoriesReducer.selectedEventCategory : null,
		eventCategory: state.eventCategoriesReducer.eventCategory ? state.eventCategoriesReducer.eventCategory : null,
		orphans: state.orphans.items,
		selectedOrphan: state.orphans.selectedItem,
		eventCategoryOperation: state.eventCategoriesReducer.operation,
		...authorities(state.authenticationReducer.authentication)
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		loadInitialData: () => {
			dispatch(loadInitialDataActionChain());
		},
		selectItem: (item) => {
			dispatch(event_categories_actions.selectEventCategory(item));
		},
		createItem: (item) => {
			dispatch(createItemActionChain(item));
		},
		saveItem: (item) => {
			dispatch(saveItemActionChain(item));
		},
		add: () => {
			dispatch(event_categories_actions.receiveEventCategory({}));
		},
		edit: (item) => {
			dispatch(event_categories_actions.fetchEventCategory(item.id));
		},
		delete: (item) => {
			dispatch(deleteItemActionChain(item.id));
		},
		selectOrphan: (item) => {
			dispatch(orphans_actions.selectItem(item))
		},
		clearOrphans: () => {
			dispatch(orphans_actions.receiveItems(null));
		},
		loadOrphanItemInfo: (type, path) => {

		},
		clearOperation: () => {
			dispatch(event_categories_actions.startOperation(null));
		}
	}
};

const loadInitialDataActionChain = () => {
	return (dispatch, getState) => {
		if (!getState().eventCategoriesReducer.isPending) {
			dispatch(event_categories_actions.requestEventCategories());
			eventCategoryService.getAll()
			                    .then(sources_proxy => {
				                    dispatch(event_categories_actions.receiveEventCategories(sources_proxy.data));
			                    })
		}
	}
}

const createItemActionChain = (item) => {
	return (dispatch, getState) => {
		dispatch(event_categories_actions.requestEventCategories());
		eventCategoryService.create(item)
		                    .then(event_source_proxy => {
			                    dispatch(event_categories_actions.appendEventCategory(event_source_proxy.data));
			                    dispatch(event_categories_actions.endOperation({
				                                                        name: 'CREATION',
				                                                        status: "SUCCESS"
			                                                        }));
		                    })
		                    .catch((error) => {
			                    dispatch(event_categories_actions.endOperation({
				                                                        name: 'CREATION',
				                                                        status: "ERROR"
			                                                        }));
		                    })
	}
}

const saveItemActionChain = (item) => {
	return (dispatch, getState) => {
		dispatch(event_categories_actions.requestEventCategories());
		eventCategoryService.save(item)
		                    .then(sources_proxy => {
			                    dispatch(event_categories_actions.insertEventCategory(sources_proxy.data));
			                    dispatch(event_categories_actions.endOperation({
				                                                                   name: 'SAVING',
				                                                                   status: "SUCCESS"
			                                                                   }));
		                    })
		                    .catch((error) => {
			                    dispatch(event_categories_actions.endOperation({
				                                                                   name: 'SAVING',
				                                                                   status: "ERROR"
			                                                                   }));
		                    })
	}
}

const deleteItemActionChain = (item) => {
	return (dispatch, getState) => {
		eventCategoryService.delete(item)
		                    .then(item_proxy => {
			                    dispatch(event_categories_actions.fetchEventCategories());
			                    dispatch(orphans_actions.receiveItems(null));
		                    })
		                    .catch((error) => {
			                    if (error.response.status === 409) {
				                    dispatch(orphans_actions.receiveItems(error.response.data));
			                    }
		                    });
	}
}

const EventCategoriesContainer = connect(mapStateToProps, mapDispatchToProps)(EventCategoriesView);

export default withRouter(EventCategoriesContainer)