import {API} from "../api/api";
import clientFactory from "../factories/http_client";

class ProjectColorSchemeService {

	/** @return  Promise<AxiosResponse<T>> */
	getColorSchemeByProject = (projectId) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.color_scheme.method,
			                             url: API.data.projects.id.color_scheme.url(projectId)
		                             });
	};

	/** @return  Promise<AxiosResponse<T>> */
	saveColorScheme = (projectId, scheme) => {
		return clientFactory.client(API.data.scope)
		                    .request({
			                             method: API.data.projects.id.color_scheme.save.method,
			                             url: API.data.projects.id.color_scheme.save.url(projectId),
			                             data: API.data.projects.id.color_scheme.save.build(scheme),
			                             headers: {
				                             'Content-Type': 'application/json',
			                             }
		                             });
	};
}

export default ProjectColorSchemeService