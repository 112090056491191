import React from "react";
import {Route, withRouter} from "react-router";
import axios from "axios";
import {API} from "../api/api";
import {connect} from "react-redux";
import {OAUTH_CLIENT_ID} from "../constants";

class PrivateRoute
	extends Route {

	componentDidMount() {
		if (!this.props.token_info) {
			window.location.href = axios.getUri({
				                                    url: API.auth.oauth2.authorize.url,
				                                    params: API.auth.oauth2.authorize.data(
					                                    OAUTH_CLIENT_ID,
					                                    'http://timeplayer.localhost/web-client/auth_code')
			                                    });
		}
	}

	render() {
		if (!this.props.token_info || !this.props.token_info.access_token) {
			return <div/>
		} else {
			return this.props.children;
		}
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		token_info: state.servers.token_info,
	}
};

export default withRouter(connect(mapStateToProps)(PrivateRoute));