import React from 'react';
import {withRouter} from 'react-router';
import {Button, Col, Grid, Row, Table} from 'react-bootstrap';

import {eventsMapService, filterService} from '../../services';
import {CLIENT_URI_CONSTANTS as URI} from '../../utils/uri-constants';
import {FilterUtils} from '../../utils/local-storage';
import Utils from '../../utils/utils';
import BaseComponent from '../../components/base/base-component';
import {connect} from "react-redux";


class FilterItem extends BaseComponent {

    constructor(props) {

        super(props);

        this.onItemClick = this.props.onItemClick;
        this.onItemDoubleClick = this.props.onItemDoubleClick;
        this.isSelected = this.props.isSelected;
    }

    render() {
        const item = this.props.item;
        return (
            <tr onClick={(param) => Utils.singleDoubleClick(
                param.target,
                item,
                this.onItemClick,
                this.onItemDoubleClick,
                param.ctrlKey,
                200)
            }
                className={`clickable ${this.isSelected(item) ? 'selected' : ''}`}
            >
                <td> {item.title ? item.title : "Unnamed"} </td>
            </tr>
        );
    }
}

const FilterItemWithRouter = withRouter(FilterItem);


class FiltersForm extends BaseComponent {

    constructor(props) {
        super(props);

        this.projectId = this.props.match.params.projectId;
        this.futureMapId = this.props.project.eventsMap.id;

        this.state = {
            data: null,
            selected: null
        };

        this.user = this.props.principal();
    }

    componentDidMount() {
        eventsMapService.getFilters(this.projectId, this.futureMapId, this.user.id).then(data => {
            if (data) {
                this.setState({ data: data });
            }
        });
    }

    getCondition() {
        return this.state.data;
    }

    conditionalRender() {
        return (
            <div style={{width: '800px'}}>
                <Grid style={{width: '800px'}}>
                    <Row>
                        <Col md={12}>
                            <Table>
                                <thead>
                                <tr>
                                    <th>Filter Name</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.data.map(item => {
                                        return <FilterItemWithRouter key={item.id}
                                                                     item={item}
                                                                     onItemClick={this.handleOnItemClick}
                                                                     onItemDoubleClick={this.handleOnItemDoubleClick}
                                                                     isSelected={(item) => this.state.selectedId && this.state.selectedId === item}
                                        />
                                    })
                                }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Button bsClass="btn action-button pull-right"
                                    onClick={this.removeButtonHandler}>
                                Delete
                            </Button>
                            <Button bsClass="btn action-button pull-right"
                                    onClick={this.editButtonHandler}>
                                Edit
                            </Button>
                            <Button bsClass="btn action-button pull-right"
                                    onClick={this.addButtonHandler}>
                                Add
                            </Button>
                            <Button bsClass="btn action-button pull-right"
                                    onClick={this.filterButtonHandler}>
                                Filter
                            </Button>
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }

    handleOnItemClick(elem, item, ctrlKey) {
        this.setState({selected: item});
    }

    handleOnItemDoubleClick(elem, item, ctrlKey) {
        const filterId = FilterUtils.addFilter(this.user.id, this.projectId, item);
        this.props.history.push(`/${URI.PROJECT}/${this.projectId}/${this.futureMapId}/${URI.FILTERED_FUTURE_MAP}/${filterId}`);
    }

    removeButtonHandler() {
        if (this.state.selectedId) {
            const data = this.state.data;
            const index = data.indexOf(this.state.selectedId);
            if (index !== -1) {
                filterService.remove(this.projectId, this.futureMapId, this.state.selectedId.id).then(filterId => {
                    data.splice(index, 1);
                    this.setState({
                        data: data,
                        selected: null
                    });
                })
            }
        } else {
            alert("You must select one of the filters");
        }
    }

    editButtonHandler() {
        if (this.state.selectedId) {
            this.props.history.push(`/${URI.PROJECT}/${this.projectId}/${this.futureMapId}/${URI.FILTER}/${this.state.selectedId.id}`);
        } else {
            alert("You must select one of the filters");
        }
    }

    addButtonHandler() {
        this.props.history.push(`/${URI.PROJECT}/${this.projectId}/${this.futureMapId}/${URI.FILTER}`);
    }

    filterButtonHandler() {
        if (this.state.selectedId) {
            const filterId = FilterUtils.addFilter(this.user.id, this.projectId, this.state.selectedId);
            this.props.history.push(`/${URI.PROJECT}/${this.projectId}/${this.futureMapId}/${URI.FILTERED_FUTURE_MAP}/${filterId}`);
        } else {
            alert("You must select one of the filters");
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        project: state.projectReducer.project,
        factors: state.factorReducer.factors,
        events: state.eventsReducer.events,
        trends: state.trendsReducer.trends,
        colorSchema: state.colorSchemaReducer.schema
    }
};

export default withRouter(connect(mapStateToProps, null)(FiltersForm));