import {actionTypes} from "../actions/clipboard";

const initialState = {
    data: null
};

function clipboard(state = initialState, action) {
    switch (action.type) {
        case actionTypes.STORE:
            return {
                data: action.data
            };
        case actionTypes.CLEAR:
            return {
                data: null
            };
        default:
            return state
    }
}

export default clipboard