import React from 'react';
import {CLIENT_URI_CONSTANTS as URI} from 'utils/uri-constants';
import {Route, Switch} from "react-router";
import {Router} from 'react-router-dom'
import AppContainer from 'containers/app';
import HomeContainer from 'containers/home';
import UsersContainer from 'containers/users';
import UserContainer from 'containers/user';
import ProjectInfoContainer from 'containers/project-info';
import ProjectContainer from 'containers/project';
import FactorsMapContainer from 'containers/project-factors-map';
import FactorContainer from 'containers/factor';
import EventsMapContainer from 'containers/project-events-map';
import EventViewContainer from 'containers/event';
import FilterForm from 'components/filter/filter-form';
import FiltersForm from 'components/filter/filters-form';
import ScenarioContainer from 'containers/scenario';
import ScenariosContainer from 'containers/scenarios';
import ScenarioMapPage from 'components/project/scenario-map/scenario-map-page';
import TrendsContainer from 'containers/trends';
import TrendViewContainer from 'containers/trend';
import UserChangePasswordContainer from 'containers/user-change-password';
import EventCategoriesContainer from 'containers/event-categories';
import EventSourcesContainer from 'containers/event-sources';
import EventTypesContainer from 'containers/event-types';
import ProjectCategoriesContainer from 'containers/project-categories';
import NumericUnitsContainer from 'containers/numeric-units';
import EventRelationsMapContainer from 'containers/event-relations-map';
import ColorSchemeContainer from 'containers/color-scheme';
import {Provider} from "react-redux";
import PrivateRoute from "./router/private-route";
import OAuth2AuthorizationProvider from "./providers/authorization";
import AuthenticationProvider from "./providers/authentication";
import CsrfTokenProvider from "./providers/csrf-token";
import InitialRouteProvider from "./providers/initial-route";
import {createBrowserHistory} from "history";
import LogoutPage from "./views/logout";
import ConnectedWebSocketReceiver from "./components/notification-receiver";
import {BASE_URL} from "./constants";
import OAuthTokenRefresher from "./oauth/token-refresher";

export const browserHistory = createBrowserHistory({
	                                                   basename: BASE_URL
                                                   });

const App = ({store}) => (
	<Provider store={store}>
		<Router history={browserHistory}>
			<InitialRouteProvider path={"/auth_code"}>
				<Switch>
					<OAuth2AuthorizationProvider path={"/auth_code"}/>
					<PrivateRoute>
						<OAuthTokenRefresher />
						<CsrfTokenProvider>
							<AuthenticationProvider>
								<AppContainer>
									<ConnectedWebSocketReceiver/>
									<Switch>
										<Route exact path={`/`} component={HomeContainer}/>
										<Route exact path={`/${URI.HOME}`} component={HomeContainer}/>
										<Route exact path={`/${URI.LOGOUT}`} component={LogoutPage}/>
										<Route path={`/${URI.PROJECT}/:projectId`}>
											<ProjectContainer>
												<Switch>
													<Route exact path={`/${URI.PROJECT}/:projectId`}
													       component={ProjectInfoContainer}/>
													<Route path={`/${URI.PROJECT}/:projectId/${URI.INFO}`}
													       component={ProjectInfoContainer}/>
													<Route path={`/${URI.PROJECT}/:projectId/${URI.FACTORS_MAP}`}
													       component={FactorsMapContainer}/>
													<Route path={`/${URI.PROJECT}/:projectId/${URI.FACTOR}/:factorId`}
													       component={FactorContainer}/>
													<Route path={`/${URI.PROJECT}/:projectId/${URI.FUTURE_MAP}`}
													       component={EventsMapContainer}/>
													<Route path={`/${URI.PROJECT}/:projectId/${URI.COLOR_SCHEME}`}
													       component={ColorSchemeContainer}/>
													<Route exact path={`/${URI.PROJECT}/:projectId/${URI.EVENT}/:eventId`}
													       component={EventViewContainer}/>
													<Route path={`/${URI.PROJECT}/:projectId/${URI.FILTER}/:filterId`}
													       component={FilterForm}/>
													<Route path={`/${URI.PROJECT}/:projectId/${URI.FILTERS}`}
													       component={FiltersForm}/>
													<Route exact path={`/${URI.PROJECT}/:projectId/${URI.SCENARIOS}`}
													       component={ScenariosContainer}/>
													<Route exact path={`/${URI.PROJECT}/:projectId/${URI.SCENARIOS}/:scenarioId`}
													       component={ScenarioContainer}/>
													<Route exact path={`/${URI.PROJECT}/:projectId/${URI.SCENARIOS}/:scenarioId/${URI.SCENARIO_MAP}`}
													       component={ScenarioMapPage}/>
													<Route exact path={`/${URI.PROJECT}/:projectId/${URI.TRENDS}`}
													       component={TrendsContainer}/>
													<Route path={`/${URI.PROJECT}/:projectId/${URI.TRENDS}/:trendId`}
													       component={TrendViewContainer}/>
													<Route exact path={`/${URI.PROJECT}/:projectId/${URI.EVENT}/:eventId/${URI.RELATIONS_MAP}`}
													       component={EventRelationsMapContainer}/>
												</Switch>
											</ProjectContainer>
										</Route>
										<Route exact path={`/${URI.USERS}`} component={UsersContainer}/>
										<Route exact path={`/${URI.USERS}/:userId`} component={UserContainer}/>
										<Route path={`/${URI.USERS}/:userId/${URI.CHANGE_PASSWORD}`}
										       component={UserChangePasswordContainer}/>
										<Route path={`/${URI.EVENT_SOURCES}`} component={EventSourcesContainer}/>
										<Route path={`/${URI.EVENT_CATEGORIES}`} component={EventCategoriesContainer}/>
										<Route path={`/${URI.EVENT_TYPES}`} component={EventTypesContainer}/>
										<Route path={`/${URI.NUMERIC_UNITS}`} component={NumericUnitsContainer}/>
										<Route path={`/${URI.PROJECT_CATEGORIES}`} component={ProjectCategoriesContainer}/>
									</Switch>
								</AppContainer>
							</AuthenticationProvider>
						</CsrfTokenProvider>
					</PrivateRoute>
				</Switch>
			</InitialRouteProvider>
		</Router>
	</Provider>

);

export default App;