import React from 'react';
import {Button, Col, Glyphicon, Grid, Nav, Navbar, NavItem, Row, Table} from 'react-bootstrap';
import ScenarioItem from "../components/scenario/scenario-item";


class ScenariosView
	extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			...this.setPermissions(props)
		};
	}

	componentDidMount() {
		if (this.props.project && !this.props.scenarios) {
			this.props.loadScenarios(this.props.project);
		}
	}

	componentDidUpdate() {
		if (this.props.project && !this.props.scenarios) {
			this.props.loadScenarios(this.props.project);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
		this.setState({
			              ...this.setPermissions(nextProps)
		              });
	}

	setPermissions = (props) => {
		if (props.isAdministrator(props.selectedProject)) {
			return {
				editEnabled: true,
				deleteEnabled: true,
				openMapEnabled: true
			}
		} else if (props.isAnalyst(props.selectedProject)) {
			return {
				editEnabled: true,
				deleteEnabled: true,
				openMapEnabled: true
			}
		} else if (props.isManager(props.selectedProject)) {
			return {
				editEnabled: true,
				deleteEnabled: true,
				openMapEnabled: true
			}
		} else {
			return {
				editEnabled: false,
				deleteEnabled: false,
				openMapEnabled: false
			}
		}
	};

	render() {
		return (
			<Grid fluid={true} className="h-100">
				<Row className="toolbar">
					<Col md={12}>
						<Navbar fluid={true} className="toolbar">
							<Nav>
								{
									this.state.editEnabled &&
									<NavItem>
										<Button bsClass="btn bar-button" onClick={this.handleEditButtonClick}>
											<Glyphicon glyph="edit" className="c-glyph-icon"/>
											EDIT
										</Button>
									</NavItem>
								}
								{
									this.state.deleteEnabled &&
									<NavItem>
										<Button bsClass="btn bar-button" onClick={this.handleDeleteButtonClick}>
											<Glyphicon glyph="remove" className="c-glyph-icon"/>
											DELETE
										</Button>
									</NavItem>
								}
								{
									this.state.openMapEnabled &&
									<NavItem>
										<Button bsClass="btn bar-button" onClick={this.handleOpenMapButtonClick}>
											<Glyphicon glyph="plus" className="c-glyph-icon"/>
											OPEN MAP
										</Button>
									</NavItem>
								}
							</Nav>
						</Navbar>
					</Col>
				</Row>
				<Row className="content">
					<Col md={12} className="h-100 table-wrapper">
						<Table>
							<thead>
							<tr>
								<th>Scenario Name</th>
							</tr>
							</thead>
							<tbody>
							{
								this.props.scenarios && this.props.scenarios.map(item => {
									return <ScenarioItem key={item.id}
									                     item={item}
									                     onItemClick={this.handleOnItemClick}
									                     onItemDoubleClick={this.handleOnItemDoubleClick}
									                     isSelected={(item) => this.props.selectedScenario && this.props.selectedScenario.id === item.id}
									/>
								})
							}
							</tbody>
						</Table>
					</Col>
				</Row>
			</Grid>
		);
	}

	handleOnItemClick = (elem, item, ctrlKey) => {
		this.props.selectScenario(item)
	};

	handleOnItemDoubleClick = (elem, item, ctrlKey) => {
		this.props.editScenario(this.props.project, item);
	};

	handleDeleteButtonClick = () => {
		if (this.props.selectedScenario) {
			if (confirm(`Are you sure you want to delete scenario: ${this.props.selectedScenario.title}?`)) {
				this.props.deleteScenario(this.props.project, this.props.selectedScenario);
			}
		} else {
			alert("You must select one of the scenarios");
		}
	};

	handleEditButtonClick = () => {
		if (this.props.selectedScenario) {
			this.props.editScenario(this.props.project, this.props.selectedScenario);
		} else {
			alert("You must select one of the scenarios");
		}
	};

	handleOpenMapButtonClick = () => {
		if (this.props.selectedScenario) {
			this.props.openEventMap(this.props.project, this.props.selectedScenario);
		} else {
			alert("You must select one of the scenarios");
		}
	}
}

export default ScenariosView;