import React from 'react';
import {Button, Modal, ToggleButton, ToggleButtonGroup} from 'react-bootstrap';

class AvailableDictionaryItemsSelect
	extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			selectedItems: props.selectedItems ? props.selectedItems : []
		};
	}

	componentDidMount() {
		this.setState({
			              selectedItems: this.props.selectedItems ? this.props.selectedItems : []
		              });
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.selectedItems !== this.props.selectedItems) {
			this.setState({
				              selectedItems: this.props.selectedItems ? this.props.selectedItems : []
			              });
		}
	}

	componentDidCatch(error, errorInfo) {
		this.setState({
			              error: true
		              })
	}

	render() {

		if (this.state.error) {
			return (
				<Modal show={this.props.show} onHide={this.props.onHide}>
					<Modal.Header closeButton>
						<Modal.Title>{this.props.title ? this.props.title : ""}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
					</Modal.Body>
					<Modal.Footer>
						<Button bsClass="btn action-button"
						        onClick={
							        (e) => {
								        this.props.onSave(this.state.selectedItems);
							        }
						        }>
							Save
						</Button>
					</Modal.Footer>
				</Modal>
			)
		}

		return (
			<Modal show={this.props.show} onHide={this.props.onHide}>
				<Modal.Header closeButton>
					<Modal.Title>{this.props.title ? this.props.title : ""}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<ToggleButtonGroup vertical
					                   type="checkbox"
					                   style={
						                   {
							                   width: "100%"
						                   }
					                   }
					                   onChange={(e) => {
					                   }}
					                   value={
						                   this.state.selectedItems.map(function (element) {
							                   return element.id;
						                   })
					                   }
					>
						{
							this.props.items && this.props.items.map((item, index, array) => {
								return (
									<ToggleButton value={item.id}
									              key={item.id}
									              onClick={
										              (event) => {
											              this.handleChange(item)
										              }
									              }
									>
										{item.title}
									</ToggleButton>
								)
							})
						}
					</ToggleButtonGroup>
				</Modal.Body>
				<Modal.Footer>
					<Button bsClass="btn action-button"
					        onClick={
						        (e) => {
							        this.props.onSave(this.state.selectedItems);
						        }
					        }>
						Save
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}

	handleChange = (item) => {

		const newSelectedItems = this.state.selectedItems.filter(function (element) {
			return element.id !== item.id;
		});

		if (this.state.selectedItems.length === newSelectedItems.length) {
			newSelectedItems.push(item);
		}

		this.setState({
			              selectedItems: newSelectedItems
		              },
		              () => {
			              if (this.props.onSelectionChange) {
				              this.props.onSelectionChange(newSelectedItems)
			              }
		              });
	};
}

export default AvailableDictionaryItemsSelect;