import React from 'react';
import vis from 'vis';

import BaseComponent from 'components/base/base-component';
import Utils from "utils/utils";


class RelationsMap
	extends BaseComponent {

	constructor(props) {
		super(props);

		this.state = {
			network: null
		};
	}

	componentDidMount() {

		const options = {
			interaction: {
				dragNodes: false,
				navigationButtons: true,
				hover: true
			},
			edges: {
				length: 350
			},
			layout: {
				randomSeed: 7009  // 258227
			}
		};

		const network = new vis.Network(this.refs.relationsMapAnchor, {}, options);

		const networkCanvas = this.refs.relationsMapAnchor.getElementsByTagName("canvas")[0];

		function changeCursor(newCursorStyle) {
			networkCanvas.style.cursor = newCursorStyle;
		}

		network.on('selectNode', this.onSelectNode);
		network.on('deselectNode', this.onDeselectNode);
		network.on('doubleClick', this.onDoubleClick);
		network.on('hoverNode', function () {
			changeCursor('pointer');
		});
		network.on('blurNode', function () {
			changeCursor('auto');
		});

		this.setState({
			              network: network,
		              });
	}

	render() {

		if (this.state.network) {
			this.state.network.setData(this.buildNetwork());
		}

		return (
			<div id="relationsMapAnchor"
			     ref="relationsMapAnchor"
			     style={{backgroundColor: '#f4f4f4'}}
			     className="h-100">
			</div>
		);
	}

	buildNetwork() {
		const event = this.props.event;
		const relations = this.props.relations;


		const nodes = new vis.DataSet(relations.map(
			relation => {
				const related = relation.left_event.id === event.id ? relation.right_event : relation.left_event;

				const date = Utils.convertDatetime(related.date, "dd.mm.yyyy");
				const impact = related.average_impact ? related.average_impact : 0;
				const probability = related.average_probability ? related.average_probability : 100;

				const node = {
					id: related.id,
					label: `${event.title}\n` +
						`(${event.factor.title.substr(0, 20)}...)\n` +
						`${date}    ${impact}    ${probability}%`,
					shape: 'box',
					margin: 10,
					font: {
						color: 'rgb(93, 97, 98)',
						bold: {
							mod: 'bold'
						}
					},
					widthConstraint: {
						minimum: 130,
						maximum: 130,
					},
					heightConstraint: {
						minimum: 35
					},
					color: {
						background: 'rgb(235, 244, 249)',
						highlight: {
							background: 'rgb(50, 200, 200)'
						},
						hover: {
							background: 'rgb(235, 244, 249)',
						}
					}
				};

				if (related.description)
					node['title'] = Utils.getPlainTextFromHtml(related.description);

				return node;
			}
		));

		const date = Utils.convertDatetime(event.date, "dd.mm.yyyy");
		const impact = event.average_impact ? event.average_impact : 0;
		const probability = event.average_probability ? event.average_probability : 100;
		const description = Utils.getPlainTextFromHtml(event.description);

		nodes.add({
			          id: 0,
			          label: `${event.title}\n` + (description ?
				          `\n${Utils.getPlainTextFromHtml(description)}\n\n` : "") +
				          `(${event.factor.title.substr(0, 20)}...)\n` +
				          `${date}    ${impact}   ${probability}%`,
			          title: event.title,
			          shape: 'box',
			          margin: 10,
			          font: {
				          size: 16,
				          color: 'rgb(93, 97, 98)',
				          bold: {
					          mod: 'bold',
					          size: 16
				          }
			          },
			          widthConstraint: {
				          minimum: 220,
				          maximum: 300,
			          },
			          heightConstraint: {
				          minimum: 35
			          },
			          color: {
				          border: 'rgb(75, 188, 205)',
				          background: 'rgb(235, 244, 249)',
				          highlight: {
					          background: 'rgb(50, 200, 200)'
				          },
				          hover: {
					          background: 'rgb(235, 244, 249)',
				          }
			          }
		          });

		let edgesArray = [];
		relations.forEach(relation => {
			const related = relation.left_event.id === event.id ? relation.right_event : relation.left_event;
			edgesArray.push({
				                from: 0,
				                to: related.id,
				                title: relation.comment
			                });
		});

		const edges = new vis.DataSet(edgesArray);

		return {
			nodes: nodes,
			edges: edges
		};
	}

	onSelectNode(params) {
		if (params.nodes[0]) {
			this.props.onSelectNode(params.nodes[0]);
		}
	}

	onDeselectNode(params) {
		this.props.onDeselectNode();
	}

	onDoubleClick(params) {
		if (params.nodes[0]) {
			this.props.onDoubleClick(params.nodes[0]);
		}
	}
}

export default RelationsMap;