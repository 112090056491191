import React from 'react';
import {Col, Glyphicon, Grid, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import Utils from "../../utils/utils";
import {TapeItemState, TapeItemType} from "../../utils/event-bus";
import {LinkContainer} from "react-router-bootstrap";
import {CLIENT_URI_CONSTANTS as URI} from "../../utils/uri-constants";

const dateFormat = "d mmmm yyyy  HH:MM:ss";

class NotificationItem
	extends React.Component {

	constructor(props) {

		super(props);

		this.state = {};
	}

	componentDidMount() {
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
	}

	componentDidCatch(error, errorInfo) {
		this.setState({error: true})
	}

	localizeItemType = (tapeItemType) => {
		switch (tapeItemType) {
			case TapeItemType.PROJECT:
				return "Project";
			case TapeItemType.FACTOR:
				return "Factor";
			case TapeItemType.SCENARIO:
				return "Scenario";
			case TapeItemType.TREND:
				return "Trend";
			case TapeItemType.EVENT_COMMENT:
			case TapeItemType.PROJECT_COMMENT:
			case TapeItemType.FACTOR_COMMENT:
			case TapeItemType.SCENARIO_COMMENT:
				return "Comment";
			case TapeItemType.EVENT_LINK:
			case TapeItemType.PROJECT_LINK:
			case TapeItemType.FACTOR_LINK:
			case TapeItemType.SCENARIO_LINK:
				return "Link";
			case TapeItemType.EVENT:
			default:
				return "Event";
		}
	};

	localizeItemState = (tapeItemState) => {
		switch (tapeItemState) {
			case TapeItemState.ALERT_EVENT_OVERDUE:
				return {
					text: "Event has expired deadline",
					glyph: "play-circle"
				};
			case TapeItemState.ALERT_EVENT_STATE:
				return {
					text: "Event completion changed",
					glyph: "info-sign"
				};
			case TapeItemState.ALERT_EVENT_UPCOMING:
				return {
					text: "Event approaching deadline",
					glyph: "warning-sign"
				};
			case TapeItemState.DELETED:
				return {
					text: "Deleted",
					glyph: "remove"
				};
			case TapeItemState.EDITED:
				return {
					text: "Edited",
					glyph: "pencil"
				};
			case TapeItemState.CREATED:
			default:
				return {
					text: "Created",
					glyph: "plus"
				};
		}
	};

	hrefFromItemType = (type, data) => {
		switch (type) {
			case TapeItemType.FACTOR:
				return data.project ? `/${URI.PROJECT}/${data.project.id}/${URI.FACTOR}/${data.entry.object ? data.entry.object.id : ""}` : "#";
			case TapeItemType.SCENARIO:
				return data.project ? `/${URI.PROJECT}/${data.project.id}/${URI.SCENARIOS}/${data.entry.object ? data.entry.object.id : ""}` : "#";
			case TapeItemType.TREND:
				return data.project ? `/${URI.PROJECT}/${data.project.id}/${URI.TRENDS}/${data.entry.object ? data.entry.object.id : ""}` : "#";
			case TapeItemType.PROJECT:
				return data.project ? `/${URI.PROJECT}/${data.project.id}/info` : "#";
			case TapeItemType.EVENT_COMMENT:
				return data.project ? `/${URI.PROJECT}/${data.project.id}/${URI.EVENT}/${data.entry.owner_id}` : "#";
			case TapeItemType.PROJECT_COMMENT:
				return data.project ? `/${URI.PROJECT}/${data.entry.owner_id}/info` : "#";
			case TapeItemType.FACTOR_COMMENT:
				return data.project ? `/${URI.PROJECT}/${data.project.id}/${URI.FACTOR}/${data.entry.owner_id}` : "#";
			case TapeItemType.SCENARIO_COMMENT:
				return data.project ? `/${URI.PROJECT}/${data.project.id}/${URI.FACTOR}/${data.entry.owner_id}` : "#";
			case TapeItemType.EVENT_LINK:
				return data.project ? `/${URI.PROJECT}/${data.project.id}/${URI.EVENT}/${data.entry.owner_id}` : "#";
			case TapeItemType.PROJECT_LINK:
				return data.project ? `/${URI.PROJECT}/${data.entry.owner_id}/info` : "#";
			case TapeItemType.FACTOR_LINK:
				return data.project ? `/${URI.PROJECT}/${data.project.id}/${URI.FACTOR}/${data.entry.owner_id}` : "#";
			case TapeItemType.SCENARIO_LINK:
				return data.project ? `/${URI.PROJECT}/${data.project.id}/${URI.FACTOR}/${data.entry.owner_id}` : "#";
			case TapeItemType.EVENT:
				if (data.entry.object) {
					return data.project ? `/${URI.PROJECT}/${data.project.id}/${URI.EVENT}/${data.entry.object ? data.entry.object.id : ""}` : "#";
				} else {
					return '#';
				}
			default:
				return "";
		}
	};

	render() {

		if (this.state.error) {
			return <div/>;
		}

		const entry = this.props.entry;
		const project = this.props.project;
		const itemType = TapeItemType.enumValueOf(entry.item_type);
		const itemState = TapeItemState.enumValueOf(entry.state);
		const locItemState = this.localizeItemState(itemState);
		let title = "";

		if (itemType === TapeItemType.PROJECT) {
			title = (entry.object ? entry.object.name : "");
		} else {
			title = (entry.object ? entry.object.title : "");
		}

		let additional_classes = "";

		if (itemState === TapeItemState.ALERT_EVENT_OVERDUE) {
			additional_classes = "action"
		} else if (itemState === TapeItemState.ALERT_EVENT_STATE) {
			additional_classes = "info"
		} else if (itemState === TapeItemState.ALERT_EVENT_UPCOMING) {
			additional_classes = "warning"
		}

		return (
			<Grid fluid={true} className={`p-0 news-item ${additional_classes}`} style={{height: this.props.height}}>
				<Row className="m-0 pt-1">
					<Col md={12} className="project-title">
						{project ? project.name : entry.project_id}
					</Col>
				</Row>
				<Row className="m-0 pt-1 data">
					<Col md={1} className="type">
						<OverlayTrigger placement="right"
						                overlay={<Tooltip id="item-state-tooltip">{locItemState.text}</Tooltip>}>
							<Glyphicon glyph={locItemState.glyph} className="c-glyph-icon"/>
						</OverlayTrigger>
					</Col>
					<Col md={3} className="source">
						<LinkContainer to={this.hrefFromItemType(itemType, {
							entry: entry,
							project: project
						})} target="_blank">
							<a>{this.localizeItemType(itemType)}</a>
						</LinkContainer>
					</Col>
					<Col md={8} className="title">
						<OverlayTrigger placement="top"
						                overlay={
							                <Tooltip id="item-state-tooltip">{title}</Tooltip>
						                }
						>
							<span>{title}</span>
						</OverlayTrigger>
					</Col>
				</Row>
				<Row className="m-0 pt-1 additional-data">
					<Col md={5}>
						{entry.changed_by.user_name}
					</Col>
					<Col md={7}>
						{Utils.convertDatetime(entry.changed, dateFormat)}
					</Col>
				</Row>
			</Grid>
		);
	}
}


export default NotificationItem;