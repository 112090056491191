import {Enum} from "enumify";

export class TapeItemState
	extends Enum {
}

TapeItemState.initEnum([
	                       'CREATED',
	                       'EDITED',
	                       'ATTENTION',
	                       'ALERT',
	                       'CONFIRM',
	                       'DELETED',
	                       'ALERT_EVENT_UPCOMING',
                           'ALERT_EVENT_OVERDUE',
                           'ALERT_EVENT_STATE'
                       ]);

export class TapeItemType
	extends Enum {
}

TapeItemType.initEnum([
	                      'EVENT',
	                      'FACTOR',
	                      'TREND',
	                      'SCENARIO',
	                      'PROJECT',

	                      'EVENT_COMMENT',
	                      'PROJECT_COMMENT',
	                      'FACTOR_COMMENT',
	                      'SCENARIO_COMMENT',

	                      'EVENT_LINK',
	                      'PROJECT_LINK',
	                      'FACTOR_LINK',
	                      'SCENARIO_LINK',
	                      'AUTHORITY'
                      ]);
