import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import * as projectCategoriesActions from "../actions/project-categories";
import ProjectCategoriesView from "../views/project-categories";
import {authorities} from "../mixins/authorities";
import {projectCategoryService} from "../services";
import * as orphans_actions from "../actions/orphans";

const mapStateToProps = (state, ownProps) => {
	return {
		projectCategories: state.projectCategoriesReducer.projectCategories,
		selectedProjectCategory: state.projectCategoriesReducer.selectedProjectCategory ? state.projectCategoriesReducer.selectedProjectCategory : null,
		projectCategory: state.projectCategoriesReducer.projectCategory ? state.projectCategoriesReducer.projectCategory : null,
		orphans: state.orphans.items,
		selectedOrphan: state.orphans.selectedItem,
		projectCategoryOperation: state.projectCategoriesReducer.operation,
		...authorities(state.authenticationReducer.authentication)
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		loadInitialData: () => {
			dispatch(loadInitialDataActionChain());
		},
		selectItem: (item) => {
			dispatch(projectCategoriesActions.selectProjectCategory(item));
		},
		createItem: (item) => {
			dispatch(createItemActionChain(item));
		},
		saveItem: (item) => {
			dispatch(saveItemActionChain(item));
		},
		add: () => {
			dispatch(projectCategoriesActions.receiveProjectCategory({}));
		},
		edit: (item) => {
			dispatch(projectCategoriesActions.fetchProjectCategory(item.id));
		},
		delete: (item) => {
			dispatch(deleteItemActionChain(item));
		},
		selectOrphan: (item) => {
			dispatch(orphans_actions.selectItem(item))
		},
		clearOrphans: () => {
			dispatch(orphans_actions.receiveItems(null));
		},
		loadOrphanItemInfo: (type, path) => {

		},
		clearOperation: () => {
			dispatch(projectCategoriesActions.startOperation(null));
		}
	}
};

const loadInitialDataActionChain = () => {
	return (dispatch, getState) => {
		if (!getState().eventCategoriesReducer.isPending) {
			dispatch(projectCategoriesActions.requestProjectCategories());
			projectCategoryService.getAll()
			                      .then(project_categories_proxy => {
				                      dispatch(projectCategoriesActions.receiveProjectCategories(project_categories_proxy.data));
			                      })
		}
	}
}

const createItemActionChain = (item) => {
	return (dispatch, getState) => {
		dispatch(projectCategoriesActions.requestProjectCategories());
		projectCategoryService.create(item)
		                      .then(project_category_proxy => {
			                      dispatch(projectCategoriesActions.appendProjectCategory(project_category_proxy.data));
			                      dispatch(projectCategoriesActions.endOperation({
				                                                                     name: 'CREATION',
				                                                                     status: "SUCCESS"
			                                                                     }));
		                      })
		                      .catch((error) => {
			                      dispatch(projectCategoriesActions.endOperation({
				                                                                     name: 'CREATION',
				                                                                     status: "ERROR"
			                                                                     }));
		                      })
	}
}

const saveItemActionChain = (item) => {
	return (dispatch, getState) => {
		dispatch(projectCategoriesActions.requestProjectCategories());
		projectCategoryService.save(item)
		                      .then(project_category_proxy => {
			                      dispatch(projectCategoriesActions.insertProjectCategory(project_category_proxy.data));
			                      dispatch(projectCategoriesActions.endOperation({
				                                                                     name: 'SAVING',
				                                                                     status: "SUCCESS"
			                                                                     }));
		                      })
		                      .catch((error) => {
			                      dispatch(projectCategoriesActions.endOperation({
				                                                                     name: 'SAVING',
				                                                                     status: "ERROR"
			                                                                     }));
		                      })
	}
}

const deleteItemActionChain = (item) => {
	return (dispatch, getState) => {
		projectCategoryService.delete(item.id)
		                      .then(item_proxy => {
			                      dispatch(projectCategoriesActions.fetchProjectCategories());
			                      dispatch(orphans_actions.receiveItems(null));
		                      })
		                      .catch((error) => {
			                      if (error.response.status === 409) {
				                      dispatch(orphans_actions.receiveItems(error.response.data));
			                      }
		                      });
	}
}

const ProjectCategoriesContainer = connect(mapStateToProps, mapDispatchToProps)(ProjectCategoriesView);

export default withRouter(ProjectCategoriesContainer)