import React from 'react';

import RelationsMap from '../components/project/relations-map/relations-map';
import {eventRelationsService, eventService} from "../services";
import BaseComponent from '../components/base/base-component';
import EventPreview from '../components/event/event-preview';
import {REFS} from '../components/controls/events-map/future-map-models';
import {Col, Grid, Nav, Navbar, Row} from "react-bootstrap";


class EventRelationsMapView
	extends BaseComponent {

	constructor(props) {

		super(props);

		this.eventId = this.props.match.params.eventId;

		this.state = {
			event: null,
			relations: []
		};
	}

	componentDidMount() {
		if (this.props.project && !this.state.event && this.props.match.params.eventId !== "0") {
			eventService.getEvent(this.props.project.id, this.props.project.eventsMap.id, this.eventId)
			            .then(event_proxy => {
				            eventRelationsService.getRelationsByEvent(this.props.project.id,
				                                                      this.props.project.eventsMap.id, this.eventId)
				                                 .then(relations_proxy => {
					                                 this.setState({
						                                               event: event_proxy.data,
						                                               relations: relations_proxy.data ? relations_proxy.data : []
					                                               });
				                                 });
			            });
		}
	}

	componentDidUpdate() {
		if (this.props.project && !this.state.event && this.props.match.params.eventId !== "0") {
			eventService.getEvent(this.props.project.id, this.props.project.eventsMap.id, this.eventId)
			            .then(event_proxy => {
				            eventRelationsService.getRelationsByEvent(this.props.project.id,
				                                                      this.props.project.eventsMap.id, this.eventId)
				                                 .then(relations_proxy => {
					                                 this.setState({
						                                               event: event_proxy.data,
						                                               relations: relations_proxy.data ? relations_proxy.data : []
					                                               });
				                                 });
			            });
		}
	}

	getCondition() {
		return this.state.event;
	}

	conditionalRender() {
		return (
			<Grid id="relations-map-page" fluid={true} className="display-flex align-items-stretch flex-direction-column h-100">
				<Row className="toolbar">
					<Col md={12}>
						<Navbar className="toolbar" fluid={true}>
							<Nav>
							</Nav>
						</Navbar>
					</Col>
				</Row>
				<Row className="content">
					<Col md={12} className="h-100">
						<RelationsMap event={this.state.event}
						              relations={this.state.relations}
						              onSelectNode={this.handleSelectNode}
						              onDeselectNode={this.handleDeselectNode}
						              onDoubleClick={this.handleDoubleClick}
						/>
						<EventPreview ref={REFS.EVENT_PREVIEW_REF}
						              show={false}
						              onHide={() => {
							              this.refs[REFS.EVENT_PREVIEW_REF].show(false)
						              }}/>
					</Col>
				</Row>
			</Grid>
		);
	}

	handleSelectNode(relatedId) {
		this.selectedNodeId = relatedId;
	}

	handleDeselectNode() {
		this.selectedNodeId = null;
	}

	handleDoubleClick(relatedId) {
		let related = null;
		for (let i = 0; i < this.state.relations.length; i++) {
			const relation = this.state.relations[i];
			if (relation.left_event.id === relatedId) {
				related = relation.left_event;
				break;
			} else if (relation.right_event.id === relatedId) {
				related = relation.right_event;
				break;
			}
		}
		this.refs[REFS.EVENT_PREVIEW_REF].show(true, related);
	}
}

export default EventRelationsMapView;