import {projectService} from "../services";

export const actionTypes = {
    OPEN_RESTORE_PROJECT_DIALOG: 'OPEN_RESTORE_PROJECT_DIALOG',
    SHOW_RESTORE_PROJECT_DIALOG: 'SHOW_RESTORE_PROJECT_DIALOG',
    CLOSE_RESTORE_PROJECT_DIALOG: 'CLOSE_RESTORE_PROJECT_DIALOG',
    OPEN_COPY_LEVEL_DIALOG: 'OPEN_COPY_LEVEL_DIALOG',
    SHOW_COPY_LEVEL_DIALOG: 'SHOW_COPY_LEVEL_DIALOG',
    CLOSE_COPY_LEVEL_DIALOG: 'CLOSE_COPY_LEVEL_DIALOG',
};

export function openRestoreProjectDialog(project) {
    return (dispatch) => {
        projectService.getById(project.id).then(entries => {
            dispatch(showRestoreProjectDialog(entries));
        })
    };
}

export function showRestoreProjectDialog(entries) {
    return {
        type: actionTypes.SHOW_RESTORE_PROJECT_DIALOG,
        data: entries
    };
}

export function closeRestoreProjectDialog(project) {
    return {
        type: actionTypes.CLOSE_RESTORE_PROJECT_DIALOG
    };
}

export function openCopyLevelDialog() {
    return {
        type: actionTypes.SHOW_COPY_LEVEL_DIALOG
    };
}

export function closeCopyLevelDialog() {
    return {
        type: actionTypes.CLOSE_COPY_LEVEL_DIALOG
    };
}